import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const LostFoundList = () => {
    const [lostfounds, setLostFounds] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "image", header: t("image") },
        { accessorKey: "roomId", header: t("room") },
        { accessorKey: "timestamp", header: t("created_on") },
        { accessorKey: "userName", header: t("username") },
        { accessorKey: "status", header: t("status") },
        { accessorKey: "pickupLocation", header: t("pickup_location") },
        { accessorKey: "pickupTime", header: t("pickup_time") },
        { accessorKey: "pickupName", header: t("pickup_name") },
        { accessorKey: "description", header: t("description") },
        { accessorKey: "givenBy", header: t("givenBy") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "lostfounds",
            responseData:
                "_id name image roomId timestamp userName status pickupLocation pickupTime pickupName description ",
        });
        if (response?.lostfounds) setLostFounds(response.lostfounds);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteLostFounds" : "deleteLostFound",
            data,
        });
        if (response?.deleteLostFounds === "success" || response?.deleteLostFound === "success") {
            setLostFounds(lostfounds.filter((lostfound) => !rows.some((row) => row._id === lostfound._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("lost_and_found")}
                moduleType="backoffice"
                data={lostfounds}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("lostfounds_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet />
        </div>
    );
};

export default LostFoundList;
