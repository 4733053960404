class CurrencyConverter {
    constructor({ currencies = [], settings = {} }) {
        this.currencies = currencies;
        this.settings = settings;
    }

    /**
     * Find conversion between from currency and to currency
     */
    #conversionRate = (fromCurrencyId, toCurrencyId) => {
        const defaultCurrency = this.currencies?.find((c) => c._id === this.settings?.currency);
        const fromCurrency =
            this.currencies?.find((c) => {
                if (fromCurrencyId?.startsWith("currencies_")) return c._id === fromCurrencyId;
                return c.currency === fromCurrencyId;
            }) || defaultCurrency;
        const toCurrency =
            this.currencies?.find((c) => {
                if (toCurrencyId?.startsWith("currencies_")) return c._id === toCurrencyId;
                return c.currency === toCurrencyId;
            }) || defaultCurrency;
        const fromCurrencyRate = defaultCurrency?.rate / fromCurrency?.rate;
        const toCurrencyRate = defaultCurrency?.rate / toCurrency?.rate;
        return fromCurrencyRate / toCurrencyRate;
    };

    convertAmount = ({ from, to, amount }) => {
        return amount * this.#conversionRate(from, to);
    };
}

export default CurrencyConverter;
