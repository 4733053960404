import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { ViewsStepper } from "./components";
import { CreateReservationContext } from "./components/create/CreateReservationContext";
import {
    ConfirmedStep,
    ExtraDetailsStep,
    ReceiptPreview,
    SelectClientsStep,
    SelectRoomsStep,
} from "./components/create";
import { useApi } from "../../components/hooks";
import { useSearchParams } from "react-router-dom";
import { ReservationHandler } from "../../components/classes";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const CreateReservation = ({ defaultStep = 0, onReservationCreated, ...defaultValues }) => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(defaultStep);
    const [directConfirm, setDirectConfirm] = useState(false);
    const [values, setValues] = useState({
        checkin: DateTime.now().toFormat("yyyy-LL-dd"),
        checkout: DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"),
        arrivalTime: null,
        departureTime: null,
        isSimpleReservation: false,
        checkedRooms: {},
        checkedRoomtypes: {},
        addedClients: [],
        referer: null,
        isGroupReservation: Boolean(searchParams.get("groupId")) ? true : false,
        groupId: Boolean(searchParams.get("groupId")) ? searchParams.get("groupId") : null,
        groupName: null,
        note: null,
        tags: [],
        reservationCustomDetails: [],
        discount: null,
        extraDiscount: null,
        firstRender: true, // Use this value to keep track if state values are default ones

        // Blocked Rooms Reservation Type
        isBlockedRooms: false,
        blockedName: null,
        type: null,
        blockedReason: null,
        blockedExpireDate: null,
        blockedExpireTime: null,

        ...defaultValues,
    });
    const roomsApi = useApi();
    const saveApi = useApi();

    const [data, setData] = useState({
        roomtypes: [],
        discounts: [],
        guestcategories: [],
        groups: [],
        taxes: [],
        reservationCustomDetails: {},
        currency: "",
    });

    useEffect(() => {
        loadRooms();
    }, []);

    const loadRooms = async () => {
        const response = await roomsApi.fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomtypes",
                    responseData: `_id name price amenities meals bedtypes{id name quantity}
                        maxCapacity includedCapacity capacity{id price}`,
                },
                {
                    endpoint: "rooms",
                    responseData: `_id name price roomtypeId 
                        maxCapacity includedCapacity capacity{id price}`,
                },
                {
                    endpoint: "roomrates",
                    responseData: "_id name roomTypes availableTo minimumNights maximumNights",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name priorityOrder",
                },
                {
                    endpoint: "discounts",
                    responseData: "_id name minimalReservations minimalAmount minimalNights discountValue discountType",
                },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate} reservationExtraDetails",
                    data: { _id: "settings_general" },
                },
            ],
        });
        if (response) {
            setData({
                ...data,
                roomtypes: response.roomtypes?.map((rt) => {
                    rt.rooms = response.rooms?.filter((r) => r.roomtypeId === rt._id);
                    rt.rates = response.roomrates?.filter((rate) => rate.roomTypes?.includes(rt._id));
                    return rt;
                }),
                discounts: response.discounts,
                currency: response.settingsgeneral?.currencyData?.currency || "",
                guestcategories: response.guestcategories,
                taxes: response.taxes,
                reservationCustomDetails: response.settingsgeneral?.reservationExtraDetails,
            });
        }
    };

    /**
     * Get daily data such as blocked rooms/roomtypes and daily pricing every time checkin & checkout is changed
     */
    const [dailyData, setDailyData] = useState({
        blockedRoomsInfo: {},
        dailyPricing: {},
    });

    useEffect(() => {
        if (!!values.checkin && !!values.checkout) {
            loadDailyData();
        }
    }, [values.checkin, values.checkout]);

    const loadDailyData = async () => {
        const response = await roomsApi.fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "blockedRooms",
                    data: {
                        checkin: values.checkin,
                        checkout: values.checkout,
                    },
                    responseData: "blockedRooms blockedRoomtypesQuantity {id quantity}",
                },
                {
                    endpoint: "dailyAvailabilities",
                    data: {
                        startDate: values.checkin,
                        endDate: values.checkout,
                    },
                },
            ],
        });
        if (response) {
            setDailyData({
                blockedRoomsInfo: response.blockedRooms || {},
                dailyPricing: response.dailyAvailabilities ? JSON.parse(response.dailyAvailabilities) : {},
            });
            /**
             * Using firstRender value as hack to prevent overriding checked rooms default values
             * when nedded and then set to false!
             * EX: when this module is used inside reservation calendar daily data update must not
             * override checked rooms in the first time
             */
            if (values.firstRender) {
                setValues({ ...values, firstRender: false });
            } else setValues({ ...values, checkedRooms: {}, checkedRoomtypes: {} });
        }
    };

    const guestsViewRef = useRef();
    const addClientAndContinue = () => {
        const response = guestsViewRef?.current?.handleAddClient && guestsViewRef?.current?.handleAddClient();
        if (!!response) setActiveStep(2);
    };

    const onDirectConfirm = (disableConfirmButton) => {
        if (!values?.checkin || !values?.checkout) {
            return;
        }
        if (Object.keys(values?.checkedRooms)?.length === 0 && Object.keys(values?.checkedRoomtypes)?.length === 0) {
            return;
        }

        if (activeStep === 2) {
            disableConfirmButton && disableConfirmButton();
            saveReservation();
            return;
        }

        if (values?.addedClients?.length > 0 || values?.isBlockedRooms) {
            disableConfirmButton && disableConfirmButton();
            saveReservation();
            return;
        }

        const response = guestsViewRef?.current?.handleAddClient && guestsViewRef?.current?.handleAddClient();
        if (!!response) {
            disableConfirmButton && disableConfirmButton();
            setDirectConfirm(true);
        }
    };

    useEffect(() => {
        if (!!directConfirm && !!values?.addedClients?.length > 0) {
            saveReservation();
        }
        return;
    }, [values?.addedClients, directConfirm]);

    const saveReservation = async () => {
        const clientFullName =
            (values.addedClients?.[0]?.firstName || "") + " " + (values.addedClients?.[0]?.lastName || "");
        const roomsArray = Object.values(values.checkedRooms)?.filter((room) => !!room);
        const roomtypesArray = Object.values(values.checkedRoomtypes)?.filter((rt) => !!rt && !!rt.quantity);
        const reservationData = values.isSimpleReservation
            ? ReservationHandler.fromNewSimpleReservation({
                  checkin: values.checkin,
                  checkout: values.checkout,
                  roomtypes: roomtypesArray || [],
                  clients: [],
                  arrivalTime: values.arrivalTime,
                  departureTime: values.departureTime,
                  doNotDisturb: values.doNotDisturb,
                  discount: values.discount,
                  dailyAvailabilities: dailyData.dailyPricing,
                  isGroupReservation: values.isGroupReservation,
                  groupName: values.groupName || clientFullName,
                  note: values.note,
                  isSimpleReservation: true,
                  isBlockedRooms: values?.isBlockedRooms,
                  guestCategories: data.guestcategories,
              }).getReservation()
            : ReservationHandler.fromNewReservation({
                  checkin: values.checkin,
                  checkout: values.checkout,
                  rooms: roomsArray || [],
                  clients: [],
                  arrivalTime: values.arrivalTime,
                  departureTime: values.departureTime,
                  doNotDisturb: values.doNotDisturb,
                  discount: values.discount,
                  dailyAvailabilities: dailyData.dailyPricing,
                  isGroupReservation: values.isGroupReservation,
                  groupName: values.groupName || clientFullName,
                  note: values.note,
                  isBlockedRooms: values?.isBlockedRooms,
                  guestCategories: data.guestcategories,
              }).getReservation();

        if (!!values?.groupId) {
            reservationData.groupId = values?.groupId;
            reservationData.groupName = undefined;
        }

        if (!!values?.isBlockedRooms) {
            reservationData.isBlockedRooms = true;
            reservationData.type = values?.type;
            reservationData.blockedReason = values?.blockedReason;
            reservationData.blockedName = values?.blockedName;
            if (values?.blockedExpireDate) {
                reservationData.blockedExpireDate = values?.blockedExpireDate;
                if (values?.blockedExpireTime) {
                    reservationData.blockedExpireDate += "T" + values?.blockedExpireTime + ":00.000Z";
                }
            }
        }

        const existingClientsIds = values.addedClients.filter((client) => client._id).map((c) => c._id);
        let newClientsIds = [];
        const newClients = values.addedClients
            .filter((client) => !client._id)
            .map((client) => ({
                firstName: client.firstName,
                lastName: client.lastName,
                clientType: client.clientType,
                birthdate: client.birthdate,
                referenceTitle: client.referenceTitle,
                company: client.company,
                country: client.country,
                state: client.state,
                city: client.city,
                zip: client.zip,
                addressLine: client.addressLine,
                email: client.email,
                phone: client.phone,
                IDType: client.IDType,
                IDNumber: client.IDNumber,
                placeOfIssue: client.placeOfIssue,
                dateOfIssue: client.dateOfIssue,
                dateOfExpiry: client.dateOfExpiry,
                nationality: client.nationality,
                placeOfBirth: client.placeOfBirth,
            }));
        if (newClients) {
            const clientsResponse = await saveApi.fetch({
                operation: "mutation",
                endpoint: "createMultipleClients",
                data: {
                    clients: newClients,
                },
            });
            if (clientsResponse.createMultipleClients) newClientsIds = clientsResponse.createMultipleClients;
        }

        reservationData.clients = existingClientsIds.concat(newClientsIds);
        reservationData.tags = values.tags;
        reservationData.reservationCustomDetails = Object.entries(values?.reservationCustomDetails).map((input) => {
            return { type: input[0], value: input[1] };
        });
        reservationData.reservationCustomDetails = reservationData.reservationCustomDetails?.filter(
            (detail) => !!detail.type && !!detail.value
        );
        if (values?.referer?._id) reservationData.referer = values.referer._id;
        const response = await saveApi.fetch({
            operation: "mutation",
            endpoint: "createReservation",
            data: reservationData,
            responseData: "_id groupId",
        });
        if (response?.createReservation?._id || response?.createReservation?.groupId) {
            enqueueSnackbar(t("reservation_saved"), { variant: "success" });
            setValues({
                ...values,
                reservationId: response.createReservation?._id,
                groupId: response?.createReservation?.groupId,
            });
            setActiveStep(3);
            onReservationCreated &&
                onReservationCreated({
                    ...reservationData,
                    status: "APPROVED",
                    clientsData: values?.addedClients,
                    _id: response.createReservation?._id,
                });
        }
    };

    const RenderActiveView = () => {
        switch (activeStep) {
            case 0:
                return <SelectRoomsStep loading={roomsApi.loading} />;
            case 1:
                return <SelectClientsStep ref={guestsViewRef} />;
            case 2:
                return <ExtraDetailsStep />;
            case 3:
                return <ConfirmedStep />;
        }
    };

    return (
        <React.Fragment>
            <ViewsStepper activeStep={activeStep} />
            <CreateReservationContext.Provider
                value={{ values, setValues, activeStep, setActiveStep, dailyData, data, setData }}
            >
                <Grid container spacing={4}>
                    <Grid item sm={12} md={9}>
                        {RenderActiveView()}
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <ReceiptPreview
                            loading={saveApi.loading}
                            onSave={saveReservation}
                            addClientAndContinue={addClientAndContinue}
                            onDirectConfirm={onDirectConfirm}
                        />
                    </Grid>
                </Grid>
            </CreateReservationContext.Provider>
        </React.Fragment>
    );
};

export default CreateReservation;
