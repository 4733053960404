import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { Table } from "../../../../components/table";
import { toCurrency } from "../../../../utils";
const calculateNights = (checkin, checkout) => {
    if (!checkin || !checkout) return 0;
    return DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days;
};
const ReservationsTable = ({ data = [], currency }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const columns = [
        {
            accessorKey: "clientName",
            header: t("client"),
            cell: ({ row: { original } }) =>
                (original?.clientsData?.[0]?.firstName || "") + " " + (original?.clientsData?.[0]?.lastName || ""),
        },
        { accessorKey: "checkin", header: t("checkin") },
        { accessorKey: "checkout", header: t("checkout") },
        {
            accessorKey: "nights",
            header: t("nights"),
            cell: ({ row: { original } }) => calculateNights(original?.checkin, original?.checkout),
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            cell: ({ row: { original } }) => toCurrency(original?.totalPrice, currency),
        },
        // { accessorKey: "amountPaid", header: t("amount_paid") },
        {
            accessorKey: "roomsData",
            header: t("rooms"),
            cell: ({ row: { original } }) =>
                original?.rooms
                    ?.map((room) => {
                        return room?.roomData?.name;
                    })
                    ?.join(","),
        },
        {
            accessorKey: "bookedOn",
            header: t("booked_on"),
            cell: ({ row: { original } }) =>
                DateTime.fromISO(original._id?.split("reservations_")?.[1])?.toFormat("yyyy LLL dd"),
        },
        { accessorKey: "bookingSource", header: t("booking_source") },
        { accessorKey: "status", header: t("status") },
        {
            id: "link",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <Button
                        startIcon={<VisibilityIcon />}
                        fullWidth
                        onClick={() => navigate(`reservations/${original?._id?.split("reservations_")[1]}`)}
                    >
                        {t("view")}
                    </Button>
                );
            },
        },
    ];

    return (
        <Table
            onRowClick={(row) => navigate(`reservations/${row?._id?.split("reservations_")[1]}`)}
            data={data}
            columns={columns}
            disableHeader
            disableSelection
            disableDelete
            disableFooter
        />
    );
};

export default ReservationsTable;
