import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { capitalize } from "../../utils";

const CheckboxControl = ({ value, label, name, size = "medium", labelPlacement = "end", ...otherProps }) => {
    const labelText = label || capitalize(name);
    return (
        <FormControlLabel
            label={labelText}
            labelPlacement={labelPlacement}
            control={<Checkbox checked={value} size={size} {...otherProps} />}
        />
    );
};

export default CheckboxControl;
