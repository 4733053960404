import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { AutoCompleteControl, InputControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EditableTaxesTable, PriceInformationCard } from "../../components/common";
import { calculateTotalTax } from "../../utils";

const rules = {
    name: "string|required",
};

const ServiceForm = () => {
    const [values, setValues] = useState({});
    const [taxes, setTaxes] = useState([]);
    const [posPointsOptions, setPospointsOptions] = useState([]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addRecordService } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "service",
            data: {
                _id: "services_" + params?.id,
            },
            responseData: "_id name price code measureUnit description iCalLinks posPoints taxData{ taxId isIncluded}",
        });
        if (response?.service) setValues(response.service);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                {
                    endpoint: "pospoints",
                    responseData: "_id name",
                },
            ],
        });

        if (!!response?.taxes) setTaxes(response.taxes);
        if (!!response?.pospoints)
            setPospointsOptions(
                response.pospoints.map((pos) => {
                    return {
                        value: pos._id,
                        label: pos.name,
                    };
                })
            );
    };
    const amountInfo = useMemo(() => {
        const { totalAmount, includedAmount } = calculateTotalTax({
            taxData: values.taxData?.map((t) => ({
                isIncluded: t.isIncluded,
                tax: taxes.find((tax) => tax._id === t.taxId),
            })),
            price: parseFloat(values.price),
        });
        const totalPrice = parseFloat(values.price) + totalAmount - includedAmount;
        const subtotal = totalPrice - totalAmount;
        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice.toFixed(2),
            subtotal: isNaN(subtotal) ? 0.0 : subtotal.toFixed(2),
            tax: isNaN(totalAmount) ? 0.0 : totalAmount.toFixed(2),
        };
    }, [values.taxData, values.price, taxes]);

    const saveHandler = async () => {
        const { name, price, code, measureUnit, description, iCalLinks, taxData, department, posPoints, _id } = values;
        const data = {
            name,
            price: parseFloat(price),
            code,
            measureUnit,
            description,
            iCalLinks,
            taxData,
            department,
            posPoints,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateService" : "createService",
            data,
            responseData: "_id name price code measureUnit description iCalLinks posPoints taxData{ taxId isIncluded}",
        });
        if (response?.createService?._id || response?.updateService?._id) {
            enqueueSnackbar(t("service_saved"), { variant: "default" });
            addRecordService && addRecordService(response.createService || response?.updateService);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("service")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
            permission={{ services: params?.id === "create" ? { create: true } : { edit: true } }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                    <InputControl name="price" label={t("price")} type="number" />
                    <InputControl name="code" label={t("service_code")} />
                    <InputControl name="department" label={t("department")} />
                    <InputControl name="measureUnit" label={t("measure_unit")} />
                    <InputControl name="description" label={t("description")} multiline minRows={3} />
                    <AutoCompleteControl
                        multiple
                        freeSolo
                        value={values.iCalLinks || []}
                        onChange={(e) => setValues({ ...values, iCalLinks: e.target.value })}
                        label={t("iCalLink")}
                        size="large"
                    />
                    <AutoCompleteControl
                        multiple
                        options={posPointsOptions}
                        value={values.posPoints || []}
                        onChange={(e) => setValues({ ...values, posPoints: e.target.value })}
                        label={t("pospoints")}
                        size="large"
                    />
                    <EditableTaxesTable
                        taxes={taxes}
                        data={values?.taxData || []}
                        onChange={(taxData) => setValues({ ...values, taxData: taxData })}
                    />
                    <PriceInformationCard
                        tax={amountInfo?.tax}
                        subtotal={amountInfo?.subtotal}
                        total={amountInfo?.totalPrice}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ServiceForm;
