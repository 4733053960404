import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import countryCodes from "../../assests/countryCodes";
import { InputControl } from "../form";
import { useApi } from "../hooks";
import { Modal } from "../modal";

const SearchTaxPayerModal = ({ onSelect }) => {
    const [openModal, setOpenModal] = useState(false);
    const [taxPayers, setTaxPayers] = useState([]);
    const [filterDetails, setFilterDetails] = useState({});

    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const loadTaxPayers = async () => {
        if (!filterDetails?.nipt && !filterDetails.name) {
            enqueueSnackbar(t("name_or_nipt_must_be_filled"), { variant: "warning" });
            return;
        }
        const response = await fetch({
            operation: "query",
            endpoint: "searchTaxPayers",
            data: filterDetails,
            responseData: "IDNumber addressLine firstName city country IDType",
        });
        setTaxPayers(
            response?.searchTaxPayers?.map((taxPayer) => {
                const countryCodeData = countryCodes?.find((c) =>
                    [c.alpha2?.toUpperCase(), c.alpha3?.toUpperCase()].includes(taxPayer.country?.toUpperCase())
                );
                if (!countryCodeData) return taxPayer;
                return { ...taxPayer, country: countryCodeData?.name?.toUpperCase() };
            }) || []
        );
    };

    return (
        <div>
            <Button variant="contained" onClick={() => setOpenModal(true)}>
                {t("search_on_nbc")}
            </Button>
            <Modal
                onClose={() => setOpenModal(false)}
                open={openModal}
                titlelabel={t("buyer_info")}
                yesText={"none"}
                noText={"none"}
                loading={loading}
            >
                <InputControl
                    onChange={(e) => setFilterDetails({ ...filterDetails, nipt: e.target.value })}
                    label={t("nipt")}
                />
                <InputControl
                    onChange={(e) => setFilterDetails({ ...filterDetails, name: e.target.value })}
                    label={t("name")}
                />
                <Button fullWidth variant="text" onClick={loadTaxPayers}>
                    {t("search")}
                </Button>
                {taxPayers.length > 0 && (
                    <Stack marginTop={4} direction="row" justifyContent="space-between" gap={2} flexWrap="wrap">
                        {taxPayers?.map((taxpayer) => {
                            return (
                                <Card variant="elevation" sx={{ width: "250px" }}>
                                    <CardContent>
                                        <Typography variant="subtitle1">{taxpayer.firstName}</Typography>
                                        <Typography variant="subtitle1">{taxpayer.IDNumber}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            onClick={() => {
                                                onSelect && onSelect(taxpayer);
                                                setOpenModal(false);
                                                setTaxPayers([]);
                                            }}
                                            fullWidth
                                        >
                                            {t("use")}
                                        </Button>
                                    </CardActions>
                                </Card>
                            );
                        })}
                    </Stack>
                )}
            </Modal>
        </div>
    );
};

export default SearchTaxPayerModal;
