import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};
const discountTypeOptions = [
    {
        value: "total",
        label: "total",
    },
    {
        value: "percentage",
        label: "percentage",
    },
];

const PromoCodeForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { addRecordPromoCode } = useOutletContext() || {};
    const navigate = useNavigate();

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "promocode",
            data: {
                _id: "promocodes_" + params?.id,
            },
            responseData: "_id name code validFrom validTo discountValue discountType",
        });
        if (response?.promocode) setValues(response.promocode);
    };

    const saveHandler = async () => {
        const { name, code, validFrom, validTo, discountValue, discountType, _id } = values;
        const data = {
            name,
            code: parseInt(code),
            validFrom,
            validTo,
            discountValue: parseFloat(discountValue),
            discountType,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePromoCode" : "createPromoCode",
            data,
            responseData: "_id name code validFrom validTo discountValue discountType",
        });
        if (response?.createPromoCode?._id || response?.updatePromoCode?._id) {
            enqueueSnackbar(t("promo_code_saved"), { variant: "default" });
            addRecordPromoCode && addRecordPromoCode(response.createPromoCode || response?.updatePromoCode);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("promo_code")}
            maxWidth="sm"
            open={true}
            loading={loading}
            onClose={() => navigate(-1)}
            yesDisabled={loading}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="code" label={t("code")} type="number" />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="validFrom" label={t("valid_from")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="validTo" label={t("valid_to")} />
                </Grid>

                <Grid item xs={6}>
                    <InputControl name="discountValue" label={t("discount_value")} type="number" />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl name="discountType" label={t("discount_type")} options={discountTypeOptions} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default PromoCodeForm;
