const POSPOINTS = `
    _id
    name
    hasTables
    isActive
    waiterLogout
    defaultCurrency
    warehouses
    activeMethods
    cashdeskId
    courseManagement
    alwaysFinalize
    differentUserOrder
    liveOrderPrintingEnabled
    saveOptions
    differentTotalCurrencies
    tables{tableView _id tableName tableColor tableBorder tableShape tableSize position{x y}}
    views{_id name description}
    printOptions{
        fiscal{printingOption printer}
        nonFiscal{printingOption printer}
        waiterReport{printingOption printer}
    }
    activeMethodsData{_id name method}
    currencyData{currency rate}
    tablesMoveEnabled
`;

export default POSPOINTS;
