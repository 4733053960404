import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DateRangeControl } from "../../../../components/dates";
import { SwitchControl } from "../../../../components/form";
import { RoomtypeCard, RoomtypeCardLoading } from "./components";
import { CreateReservationContext } from "./CreateReservationContext";

const SelectRoomsStep = ({ loading = false }) => {
    const { t } = useTranslation();
    const { values, setValues, data } = useContext(CreateReservationContext);
    return (
        <React.Fragment>
            <Card>
                <CardHeader
                    title={t("search_for_available_rooms")}
                    titleTypographyProps={{ variant: "h6" }}
                    action={
                        <SwitchControl
                            value={values?.isSimpleReservation}
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    isSimpleReservation: e.target.checked,
                                    checkedRooms: {},
                                    checkedRoomtypes: {},
                                });
                            }}
                            color="primary"
                            label={t("simple_reservation")}
                            labelPlacement="start"
                        />
                    }
                />
                <CardContent>
                    <DateRangeControl
                        values={{ startDate: values?.checkin, endDate: values?.checkout }}
                        onChange={({ startDate, endDate }) => {
                            if (startDate && endDate && startDate === endDate) return;
                            setValues({ ...values, checkin: startDate, checkout: endDate });
                        }}
                        startLabel={t("checkin")}
                        endLabel={t("checkout")}
                    />
                </CardContent>
            </Card>
            {loading ? (
                <RoomtypeCardLoading />
            ) : (
                data?.roomtypes?.map((roomtype) => {
                    return <RoomtypeCard roomtype={roomtype} key={roomtype?._id} />;
                })
            )}
        </React.Fragment>
    );
};

export default SelectRoomsStep;
