import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
// import "./components/dates/react_dates_overrides.css";s

const root = document.getElementById("root");
ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>,
    root
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js").then(
            (registration) => {
                console.log("ServiceWorker registration successful with scope: ", registration.scope);
            },
            (error) => {
                console.log("ServiceWorker registration failed: ", error);
            }
        );
    });
}
