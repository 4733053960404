import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { toCurrency } from "../../utils";

const RoomList = () => {
    const [rooms, setRooms] = useState([]);
    const [settings, setSettings] = useState({});
    const { currency = "" } = settings?.currencyData || {};
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "rooms",
                    responseData: "_id name roomTypeData{name} price",
                },
            ],
        });
        if (response?.settingsgeneral) setSettings(response.settingsgeneral);
        if (response?.rooms) setRooms(response.rooms);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteRooms" : "deleteRoom",
            data,
        });
        if (response?.deleteRooms === "success" || response?.deleteRoom === "success") {
            setRooms(rooms.filter((room) => !rows.some((row) => row._id === room._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = [
        { accessorKey: "name", header: t("name") },
        {
            id: "category",
            header: t("category"),
            accessorFn: (row) => row?.roomTypeData?.name || null,
        },
        {
            accessorKey: "price",
            header: t("price"),
            accessorFn: (row) => toCurrency(row?.price, currency),
        },
    ];
    const addRecordRoom = (data) => {
        if (rooms?.some((r) => r._id === data._id)) {
            setRooms(
                rooms?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setRooms([...rooms, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("rooms_form")}
                moduleType="backoffice"
                data={rooms}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("rooms_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordRoom }} />
        </div>
    );
};

export default RoomList;
