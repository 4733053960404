import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

const InfoComponent = ({ text }) => {
    return (
        <Tooltip
            title={text}
            arrow
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": { fontSize: "14px" },
                },
            }}
        >
            <IconButton sx={{ color: "navbarColor.contrastText" }}>
                <InfoOutlinedIcon />
            </IconButton>
        </Tooltip>
    );
};

export default InfoComponent;
