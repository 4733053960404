import { Switch, FormControlLabel } from "@mui/material";
import React from "react";
import { capitalize } from "../../utils";

const SwitchControl = ({
    value,
    label,
    name,
    size = "medium",
    labelPlacement = "end",
    formProps = {},
    ...otherProps
}) => {
    const labelText = label || capitalize(name);
    return (
        <FormControlLabel
            label={labelText}
            labelPlacement={labelPlacement}
            control={<Switch checked={value} size={size} {...otherProps} />}
            {...formProps}
        />
    );
};

export default SwitchControl;
