import { endpoints } from "./endpoints";
import cacheDB from "./IndexDB";

const addRecords = async ({ records, resource }) => {
    try {
        const table = cacheDB[resource];
        if (Array.isArray(records)) {
            await table.bulkPut(records);
            return;
        }
        await table.put(records);
        return;
    } catch (err) {
        console.log(err);
        return;
    }
};

export const cacheData = async (data) => {
    for (const key in data) {
        if (endpoints[key] && !!endpoints[key].cache) {
            const records = !!endpoints[key].getData ? endpoints[key].getData(data[key]) : data[key];
            await addRecords({ records: records, resource: endpoints[key].resource });
        }
        if (endpoints[key] && !!endpoints[key].customCache) {
            await endpoints[key].customCache(data[key]);
        }
    }
};

const loadEndpointFromCache = async (endpointOptions = {}, data = {}) => {
    const table = cacheDB[endpointOptions.resource];
    switch (endpointOptions.recordsType) {
        case "SINGLE_RECORD":
            return await table.get(data._id);
        case "ALL_RECORDS":
            return await table.toArray();
        case "SAVE_RECORD":
            const record = endpointOptions.getRecord(data);
            await table.add(record);
            return record;
        case "UPDATE_RECORD":
            const updatedRecord = !!endpointOptions.getRecord ? endpointOptions.getRecord(data) : data;
            if (updatedRecord.offlineCreated && !updatedRecord.offlineEdited) {
                await table.add(updatedRecord);
            } else {
                await table.update(updatedRecord._id, updatedRecord);
            }
            return updatedRecord;
        case "RECORDS_BY_FILTER":
            const records = await table.filter(endpointOptions.filterBy).toArray();
            if (!!endpointOptions.formatData) {
                return endpointOptions.formatData(records);
            }
            return records;
        case "CUSTOM":
            return await endpointOptions.customGetData(data);
        default:
            return null;
    }
};

export const loadDataFromCache = async (requestedData) => {
    try {
        const data = {};
        const requestedEndpoints = !!requestedData?.multipleEndpoints
            ? requestedData?.multipleEndpoints
            : [{ ...requestedData }];

        for (const requestedEndpoint of requestedEndpoints) {
            const response = await loadEndpointFromCache(endpoints[requestedEndpoint.endpoint], requestedEndpoint.data);
            data[requestedEndpoint.endpoint] = response;
        }
        return data;
    } catch (err) {
        throw err;
    }
};

export const getOfflineCreatedRecords = async () => {
    const ordersTable = cacheDB["orders"];
    const offlineOrders = await ordersTable.filter((order) => order.offlineCreated || order.offlineEdited).toArray();

    const waiterRevenueTable = cacheDB["waiterrevenue"];
    const offlineWaiterRevenueRecords = await waiterRevenueTable
        .filter((doc) => doc.offlineCreated || doc.offlineEdited)
        .toArray();

    if (!offlineOrders?.length > 0 && !offlineWaiterRevenueRecords?.length > 0) return null;
    return {
        orders: offlineOrders,
        waiterrevenues: offlineWaiterRevenueRecords,
    };
};

export const updateOfflineCreatedRecords = async (previousData, ordersResponse) => {
    const { orders, waiterrevenues } = previousData || {};
    const updatedWaiterRevenues =
        waiterrevenues?.map((doc) => {
            return { ...doc, offlineCreated: undefined, offlineEdited: undefined };
        }) || [];
    const waiterRevenueTable = cacheDB["waiterrevenue"];
    await waiterRevenueTable.bulkPut(updatedWaiterRevenues);

    const newOrders = orders?.filter((doc) => !!doc.offlineCreated);
    const newOrdersPrevIds = newOrders?.map((doc) => doc._id) || [];
    const editedNewOrders =
        newOrders?.map((doc) => {
            const { _id, offlineId, number } = ordersResponse?.find((i) => i.offlineId === doc._id) || {};
            return { ...doc, _id: _id, number, offlineId, offlineCreated: undefined, offlineEdited: undefined };
        }) || [];
    const editedOrders =
        orders
            ?.filter((doc) => !!doc.offlineEdited && !doc.offlineCreated)
            ?.map((doc) => {
                return { ...doc, offlineCreated: undefined, offlineEdited: undefined };
            }) || [];
    const ordersTable = cacheDB["orders"];
    await ordersTable.bulkDelete(newOrdersPrevIds);
    await ordersTable.bulkPut(editedNewOrders);
    await ordersTable.bulkPut(editedOrders);
};

export const cleanOrdersCache = async () => {
    const ordersTable = cacheDB["orders"];
    await ordersTable.clear();
    return;
};
