import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../../../utils";
import { ReportGenerator } from "../../../../components/classes";
import { DateTime } from "luxon";

const calculateNights = (checkin, checkout) => {
    if (!checkin || !checkout) return 0;
    return DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days;
};

const ReservationsTable = ({ data = [], currencyData, reservationTableRef }) => {
    const { t } = useTranslation();
    const [report, setReport] = useState(null);
    const columns = [
        {
            id: "rooms",
            header: t("rooms"),
            displayValue: (row) => {
                const { rooms } = row?.invoice?.reservationData || row?.reservation || {};
                return rooms?.map((room) => room?.roomData?.name || "").join(" , ") || "";
            },
            totalValue: () => t("total"),
        },
        {
            id: "name",
            header: t("name"),
            displayValue: (row) => {
                const { firstName, lastName } =
                    row?.invoice?.reservationData?.clientsData?.[0] || row?.reservation?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
            totalValue: () => "",
        },
        {
            id: "id",
            header: t("reservation"),
            displayValue: (row) => row?.invoice?.reservationData?.uuid || row?.reservation?.uuid || "",
            totalValue: () => "",
        },
        {
            id: "dates",
            header: t("date"),
            displayValue: (row) =>
                row?.invoice?.reservationData
                    ? `${row?.invoice?.reservationData?.checkin} - ${row?.invoice?.reservationData?.checkout}`
                    : `${row?.reservation?.checkin} - ${row?.reservation?.checkout}`,
            totalValue: () => "",
        },
        {
            id: "nights",
            header: t("nights"),
            displayValue: (row) =>
                row?.invoice?.reservationData
                    ? calculateNights(row?.invoice?.reservationData?.checkin, row?.invoice?.reservationData?.checkout)
                    : calculateNights(row?.reservation?.checkin, row?.reservation?.checkout),
            totalValue: () => "",
        },
        {
            id: "guests",
            header: t("guests"),
            displayValue: (row) => {
                if (row?.invoice?.reservationData) {
                    return row?.invoice?.reservationData?.guests?.reduce((acc, roomGuests) => {
                        return acc + roomGuests?.guests?.reduce((total, category) => total + category.number, 0.0);
                    }, 0.0);
                } else {
                    return row?.reservation?.guests?.reduce((acc, roomGuests) => {
                        return acc + roomGuests?.guests?.reduce((total, category) => total + category.number, 0.0);
                    }, 0.0);
                }
            },
            totalValue: () => "",
        },
        {
            id: "totalPrice",
            header: t("total_accommodation"),
            displayValue: (row) => {
                const { invoiceCurrency } = row?.invoice || row?.reservation;
                if (invoiceCurrency) {
                    return toCurrency(
                        row?.invoice?.reservationData?.totalPrice || row?.reservation?.totalPrice,
                        invoiceCurrency
                    );
                }
                if (row?.reservation?.isDailyUse) {
                    const currency = currencyData?.dailyUseCurrency || currencyData?.defaultCurrency || "ALL";
                    return toCurrency(
                        row?.invoice?.reservationData?.totalPrice || row?.reservation?.totalPrice,
                        currency
                    );
                }
                const currency = currencyData?.defaultCurrency || "ALL";
                return toCurrency(row?.invoice?.reservationData?.totalPrice || row?.reservation?.totalPrice, currency);
            },
            totalValue: () => "",
        },
        {
            id: "amount",
            header: t("amount_paid"),
            displayValue: (row) => {
                const { invoiceCurrency } = row?.invoice || row?.reservation;
                if (invoiceCurrency) {
                    return toCurrency(row?.amount, invoiceCurrency);
                }

                if (row?.reservation?.isDailyUse) {
                    const currency = currencyData?.dailyUseCurrency || currencyData?.defaultCurrency || "ALL";
                    return toCurrency(row?.amount, currency);
                }
                const currency = currencyData?.defaultCurrency || "ALL";
                return toCurrency(row?.amount, currency);
            },
            totalValue: (rows) => {
                const totalByCurrencies = {};
                rows?.filter((row) => !row?.isTotal)?.forEach((row) => {
                    const { invoiceCurrency } = row?.invoice || row?.reservation;
                    let currency = !!invoiceCurrency ? invoiceCurrency : currencyData?.defaultCurrency;
                    if (!currency) {
                        if (row?.reservation?.isDailyUse) {
                            currency = currencyData?.dailyUseCurrency || currencyData?.defaultCurrency || "ALL";
                        } else {
                            currency = currencyData?.defaultCurrency || "ALL";
                        }
                    }

                    if (!totalByCurrencies[currency]) {
                        totalByCurrencies[currency] = 0.0;
                    }
                    totalByCurrencies[currency] += row?.amount;
                });

                return Object.entries(totalByCurrencies)
                    ?.map(([currency, amount]) => {
                        return toCurrency(amount, currency);
                    })
                    ?.join(" , ");
            },
        },
        {
            id: "time",
            header: t("payment_time"),
            displayValue: (row) => DateTime.fromISO(row?.timestamp)?.toFormat("dd LLL yyyy HH:mm"),
            totalValue: () => "",
        },
        {
            id: "paymentMethod",
            header: t("payment_method"),
            displayValue: (row) => t(row?.paymentMethodData?.name || ""),
            totalValue: () => "",
        },
    ];

    useEffect(() => {
        const newRaportGenerator = new ReportGenerator({
            data,
            columns,
            displayTotal: true,
        });
        setReport(newRaportGenerator.generateTable({ title: t("accommodation_total"), tableRef: reservationTableRef }));
    }, [data]);

    return report;
};

export default ReservationsTable;
