import { Button, ButtonGroup, CardHeader, Stack } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HotelIcon from "@mui/icons-material/Hotel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Table } from "../../../components/table";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { toCurrency } from "../../../utils";
import { CheckboxControl, SelectControl } from "../../../components/form";
const calculateNights = (checkin, checkout) => {
    if (!checkin || !checkout) return 0;
    return DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days;
};
const ReservationsTable = ({ data = [], removeReservation, currency, changeStatusInBulk }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedReservations, setSelectedReservations] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const columns = [
        {
            id: "select",
            header: "",
            cell: ({ row: { original } }) => (
                <CheckboxControl
                    value={selectedReservations?.includes(original?._id)}
                    onChange={(e) => {
                        if (e.target.checked) setSelectedReservations([...selectedReservations, original?._id]);
                        else {
                            setSelectedReservations(selectedReservations?.filter((i) => i !== original?._id));
                            setSelectedStatus(null);
                        }
                    }}
                />
            ),
        },
        {
            accessorKey: "clientName",
            header: t("client"),
            cell: ({ row: { original } }) =>
                (original?.clientsData?.[0]?.firstName || "") + " " + (original?.clientsData?.[0]?.lastName || ""),
        },
        { accessorKey: "checkin", header: t("checkin") },
        { accessorKey: "checkout", header: t("checkout") },
        {
            accessorKey: "nights",
            header: t("nights"),
            cell: ({ row: { original } }) => calculateNights(original?.checkin, original?.checkout),
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            cell: ({ row: { original } }) => toCurrency(original?.totalPrice, currency),
        },
        // { accessorKey: "amountPaid", header: t("amount_paid") },
        {
            accessorKey: "roomsData",
            header: t("rooms"),
            cell: ({ row: { original } }) =>
                original?.rooms
                    ?.map((room) => {
                        return room?.roomData?.name || "";
                    })
                    ?.join(","),
        },
        {
            accessorKey: "guests",
            header: t("guests_number"),
            accessorFn: (row) => {
                const guestByCategories = {};
                row?.guests?.forEach((guest) => {
                    guest?.guests?.forEach((category) => {
                        if (!guestByCategories[category.name]) {
                            guestByCategories[category.name] = 0;
                        }
                        guestByCategories[category.name] += category.number;
                    });
                });
                return Object.entries(guestByCategories)
                    ?.map(([guestCategory, guestNumber]) => {
                        return guestCategory + " " + guestNumber;
                    })
                    ?.join(",");
            },
        },
        // {
        //     accessorKey: "roomsData",
        //     header: t("rates"),
        //     cell: ({ row: { original } }) => {
        //         return original?.rooms
        //             ?.map((room) => {
        //                 return room?.prices?.map((day) => {
        //                     return ["standart", "standard"].includes(day?.rateId) ? day?.rateId : day?.rateName || "";
        //                 });
        //             })
        //             ?.join(",");
        //     },
        // },

        {
            id: "link",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <Button
                        fullWidth
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeReservation(original?._id);
                        }}
                        color="secondary"
                    >
                        {t("remove")}
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <CardHeader
                avatar={<HotelIcon />}
                title={t("reservations")}
                titleTypographyProps={{ variant: "h5" }}
                action={
                    selectedReservations?.length > 0 && (
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <SelectControl
                                options={[
                                    { value: "CHECKIN", label: t("checkin") },
                                    { value: "CHECKOUT", label: t("checkout") },
                                    { value: "CANCELLED", label: t("cancelled") },
                                ]}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                                label={t("status")}
                            />
                            <Button
                                onClick={() =>
                                    changeStatusInBulk({ reservations: selectedReservations, status: selectedStatus })
                                }
                                variant="contained"
                                disabled={!selectedStatus}
                                sx={{ width: "300px" }}
                            >
                                {t("change_status")}
                            </Button>
                        </Stack>
                    )
                }
            />
            <Table
                onRowClick={(row) => navigate(`/reservations/${row?._id?.split("reservations_")[1]}`)}
                data={data}
                columns={columns}
                disableHeader
                disableSelection
                disableDelete
                disableFooter
            />
        </>
    );
};

export default ReservationsTable;
