import React from "react";

const ImageBackground = ({ image }) => {
    return (
        <div
            style={{
                backgroundImage: `url(${image})`,
                width: "100%",
                height: "100vh",
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: -1,
            }}
            alt="background"
        />
    );
};

export default ImageBackground;
