import { Button, Divider, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useState } from "react";
import currency from "../../assests/currency";
import { DateControl } from "../dates";
import { AutoCompleteControl, InputControl, RadioGroupControl, SelectControl, TagsAutoCompleteControl } from "../form";
import ClientsSearch from "./ClientsSearch";
import SearchTaxPayerModal from "./SearchTaxPayerModal";

const countriesOptions = currency.map((c) => c.CtryNm);

const ClientDetailsInputs = ({ t, alwaysOpen = false, values = {}, setValues, tags = [] }) => {
    const [open, setOpen] = useState(false);

    const typeOptions = [
        { value: "client", label: t("client_person") },
        { value: "partner", label: t("partner_company") },
    ];
    const titleOptions = [
        { value: "Mr.", label: t("mr") },
        { value: "Ms.", label: t("ms") },
        { value: "Mrs.", label: t("mrs") },
        { value: "Dr.", label: t("dr") },
    ];
    const documentOptions = [
        { value: "NUIS", label: t("nuis") },
        { value: "ID", label: t("id_personal_nr") },
        { value: "PASS", label: t("passport_number") },
        { value: "VAT", label: t("registered_vat_number") },
        { value: "TAX", label: t("issued_number") },
        { value: "SOC", label: t("social_security_number") },
    ];
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <InputControl name="firstName" label={values.clientType === "partner" ? t("name") : t("firstname")} />
            </Grid>
            {values.clientType === "client" && (
                <Grid item xs={12} sm={6}>
                    <InputControl name="lastName" label={t("lastname")} />
                </Grid>
            )}
            {alwaysOpen && (
                <>
                    <Grid item xs={12} sm={6}>
                        <ClientsSearch
                            placeholder={t("search_referrer")}
                            onlyPartners
                            onClientSelect={(data) => {
                                setValues && setValues({ ...values, referer: { _id: data._id, name: data.firstName } });
                            }}
                        />
                        {values?.referer && (
                            <Typography>
                                {t("referer")}: {values?.referer?.name}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TagsAutoCompleteControl multiple options={tags} size="large" name="tags" />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <RadioGroupControl options={typeOptions} name="clientType" label={t("client_type")} />
            </Grid>
            {values?.clientType === "partner" && (
                <Grid item xs={12}>
                    <SearchTaxPayerModal
                        onSelect={(newValues) => {
                            setValues && setValues({ ...values, ...newValues });
                        }}
                    />
                </Grid>
            )}
            <Grid container item xs={12} sm={6} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">{t("personal_information")}</Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteControl options={countriesOptions} name="country" label={t("country")} />
                </Grid>
                {values.clientType === "client" && (
                    <Grid item xs={6}>
                        <SelectControl options={titleOptions} name="referenceTitle" label={t("reference_title")} />
                    </Grid>
                )}
                {values.clientType === "client" && (
                    <Grid item xs={6}>
                        <InputControl name="company" label={t("company")} />
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} sm={6} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">{t("contact_information")}</Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="email" label={t("email")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="phone" label={t("phone")} />
                </Grid>
            </Grid>
            {!alwaysOpen && (
                <Grid item xs={12}>
                    <Divider>
                        {open ? (
                            <Button onClick={() => setOpen(false)} endIcon={<ExpandLessIcon />}>
                                {t("show_less")}
                            </Button>
                        ) : (
                            <Button onClick={() => setOpen(true)} endIcon={<ExpandMoreIcon />}>
                                {t("show_more")}
                            </Button>
                        )}
                    </Divider>
                </Grid>
            )}
            {(alwaysOpen ? true : open) && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("address")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl multiline minRows={2} name="addressLine" label={t("addressline")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="city" label={t("city")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="zip" label={t("zip")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="state" label={t("state")} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("document_information")}</Typography>
                        <Divider />
                    </Grid>
                    {values.clientType === "client" && (
                        <Grid item xs={12} sm={4}>
                            <DateControl name="birthdate" label={t("birthdate")} />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                        <SelectControl options={documentOptions} name="IDType" label={t("IDType")} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputControl name="IDNumber" label={t("document_number")} />
                    </Grid>
                    {values.clientType === "client" && (
                        <Grid item xs={12} sm={4}>
                            <InputControl name="placeOfIssue" label={t("place_of_issue")} />
                        </Grid>
                    )}
                    {values.clientType === "client" && (
                        <Grid item xs={12} sm={4}>
                            <DateControl name="dateOfIssue" label={t("date_of_issue")} />
                        </Grid>
                    )}
                    {values.clientType === "client" && (
                        <Grid item xs={12} sm={4}>
                            <DateControl name="dateOfExpiry" label={t("date_of_expiry")} />
                        </Grid>
                    )}
                    {values.clientType === "client" && (
                        <Grid item xs={12} sm={4}>
                            <AutoCompleteControl
                                options={countriesOptions}
                                name="nationality"
                                label={t("nationality")}
                            />
                        </Grid>
                    )}
                    {values.clientType === "client" && (
                        <Grid item xs={12} sm={4}>
                            <InputControl name="placeOfBirth" label={t("place_of_birth")} />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default ClientDetailsInputs;
