import { DateTime, Interval } from "luxon";

const generateDatesBetween = (startDate, endDate, format = "[]") => {
    if (!startDate) throw new Error("startDate required");
    if (!endDate) throw new Error("endDate required");

    const days = Interval.fromDateTimes(
        DateTime.fromISO(startDate).startOf("day"),
        DateTime.fromISO(endDate).endOf("day")
    )
        .splitBy({ day: 1 })
        .map((d) => d.start.toFormat("yyyy-LL-dd"));

    switch (format) {
        case "[]":
            return days;
        case "[)":
            days.pop();
            return days;
        case "(]":
            days.shift();
            return days;
        case "()":
            days.pop();
            days.shift();
            return days;
    }
};

export default generateDatesBetween;
