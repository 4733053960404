import calculateTotalTax from "./calculateTotalTax";

const generateSameTaxes = (items = []) => {
    const fiscalItems = items
        ?.filter((item) => !!item.price && item.price > 0)
        ?.map((item, i) => {
            let price = item.price;
            let rate;
            const taxesAmount = item?.productData?.taxData.reduce((acc, { isIncluded, tax }) => {
                let taxAmount = acc;
                const { totalAmount } = calculateTotalTax({
                    taxData: [{ isIncluded: isIncluded, tax: tax }],
                    price: item.price,
                });
                taxAmount += totalAmount;
                rate = tax.rate;
                if (isIncluded) price -= totalAmount;
                return taxAmount;
            }, 0.0);

            let PA = (price + taxesAmount) * item.quantity;
            let R;

            const itemData = {
                PA: PA,
                PB: price * item.quantity,
                Q: item.quantity,
                UPA: price + taxesAmount,
                UPB: price,
            };

            if (taxesAmount > 0) {
                itemData.VA = taxesAmount * item.quantity;
                itemData.VASpecified = true;
            } else {
                itemData.EXSpecified = true;
                itemData.EX = "TAX_FREE";
            }

            if (rate) {
                itemData.VR = rate;
                itemData.VRSpecified = true;
            }

            if (R) {
                itemData.R = R;
                itemData.RR = true;
                itemData.RSpecified = true;
                itemData.RRSpecified = true;
            }

            return itemData;
        });
    const sameTaxes = fiscalItems
        ?.filter((item) => item.VRSpecified)
        ?.reduce((acc, item) => {
            const sameTaxIndex = acc.findIndex((t) => t.VATRate === item.VR);
            if (sameTaxIndex < 0) {
                acc.push({
                    NumOfItems: 1,
                    PriceBefVAT: item.PB,
                    VATAmt: item.VA,
                    VATAmtSpecified: true,
                    VATRate: item.VR,
                    VATRateSpecified: true,
                });
                return acc;
            }
            acc[sameTaxIndex].NumOfItems += 1;
            acc[sameTaxIndex].PriceBefVAT += item.PB;
            acc[sameTaxIndex].VATAmt += item.VA;
            return acc;
        }, []);
    const nontaxed = fiscalItems
        ?.filter((item) => !item.VRSpecified)
        ?.reduce((initialValue, item) => {
            let nontax = initialValue;
            if (!nontax.NumOfItems) {
                nontax = {
                    NumOfItems: 0,
                    PriceBefVAT: 0.0,
                    VATAmt: 0.0,
                    VATAmtSpecified: false,
                    VATRate: 0.0,
                };
            }
            nontax.NumOfItems += 1;
            nontax.PriceBefVAT += parseFloat(item.PB);
            return nontax;
        }, {});
    if (!!nontaxed?.NumOfItems) {
        sameTaxes.push(nontaxed);
    }
    sameTaxes.forEach((sametax) => {
        sametax.PriceBefVAT = sametax.PriceBefVAT.toFixed(2);
        sametax.VATAmt = sametax.VATAmt.toFixed(2);
        sametax.VATRate = sametax.VATRate.toFixed(2);
    });
    return sameTaxes;
};

export default generateSameTaxes;
