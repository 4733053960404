import { DateTime } from "luxon";
import { getLoggedUserData } from "../../../utils";
import cacheDB from "./IndexDB";

const onOrderSave = (data) => {
    const order = data;
    order.status = "OPEN";
    order.createdAt = DateTime.utc().toISO({ includeOffset: false }) + "Z";
    order._id = "orders_" + order.createdAt;
    order.offlineCreated = true;

    const { _id } = getLoggedUserData() || {};
    order.createdBy = _id;

    return order;
};

const cacheOrderSave = async (data) => {
    const ordersTable = cacheDB["orders"];
    await ordersTable.put(data);
};

const onOrderEdit = (data) => {
    data.offlineEdited = true;
    return data;
};

const onOrderPay = (data) => {
    const timestamp = DateTime.utc().toISO({ includeOffset: false }) + "Z";
    const order = data;
    order.payments.timestamp = timestamp;
    order.payments.paymentStatus =
        order.payments.cashReceived === 0
            ? "unpaid"
            : order.payments.cashReceived < order.totalPrice
            ? "partly_paid"
            : "paid";
    order.status = "PAID";
    if (order?.payments?.paymentStatus === "partly_paid") order.status = "PARTLYPAID";
    if (order?.payments?.paymentStatus === "unpaid") order.status = "UNPAID";

    if (!order._id) {
        order.createdAt = timestamp;
        order._id = "orders_" + order.createdAt;
        const { _id } = getLoggedUserData() || {};
        order.createdBy = _id;
        order.offlineCreated = true;
    } else {
        order.offlineEdited = true;
    }
    return order;
};

const getWaitersOfflineCustomCache = async (data) => {
    const { waiters, orders } = data || {};
    if (waiters.length > 0) {
        for (const waiter of waiters) {
            const { user, waiterRevenue } = waiter || {};
            const usersTable = cacheDB["users"];
            await usersTable.put(user);
            if (!!waiterRevenue) {
                const waiterRevenueTable = cacheDB["waiterrevenue"];
                await waiterRevenueTable.put(waiterRevenue);
            }
        }
    }
    if (orders?.length > 0) {
        const ordersTable = cacheDB["orders"];
        await ordersTable.bulkPut(orders);
    }
};

const getWaitersOfflineCustomData = async () => {
    const usersTable = cacheDB["users"];
    const users = await usersTable.toArray();

    const waiterRevenueTable = cacheDB["waiterrevenue"];
    const waiterRevenues = (await waiterRevenueTable.toArray()) || [];

    const waitersData = users?.map((user) => {
        const waiterRevenueRecords = waiterRevenues?.filter((doc) =>
            doc._id?.startsWith("waiterrevenues_" + user._id?.split("users_")?.[1])
        );
        const lastWaiterRevenueRecord = waiterRevenueRecords?.pop();
        return {
            user: user,
            waiterRevenue: lastWaiterRevenueRecord,
        };
    });
    return { waiters: waitersData };
};

const getWaiterLoginCustomData = async (data) => {
    const { userName, pin } = data || {};

    const usersTable = cacheDB["users"];
    const user = await usersTable.get("users_" + userName);
    if (!user) throw new Error("user_not_found");
    if (!pin || pin !== user.pin) throw new Error("invalid_pin");

    let hasOpenShift = true;
    const waiterRevenueTable = cacheDB["waiterrevenue"];
    const waiterRevenues = (await waiterRevenueTable.toArray()) || [];
    const waiterRevenueRecords = waiterRevenues?.filter((doc) =>
        doc._id?.startsWith("waiterrevenues_" + user._id?.split("users_")?.[1])
    );
    const lastWaiterRevenueRecord = waiterRevenueRecords?.pop();
    if (!lastWaiterRevenueRecord) hasOpenShift = false;
    if (lastWaiterRevenueRecord?.closing?.timestamp) hasOpenShift = false;
    return { user, hasOpenShift };
};

const openUserShift = async (data) => {
    const { userId } = data || {};
    const waiterRevenueTable = cacheDB["waiterrevenue"];

    const timestamp = DateTime.utc().toISO({ includeOffset: false }) + "Z";
    const lastWaiterRevenueRecord = {
        _id: `waiterrevenues_${userId?.split("users_")?.[1]}_${timestamp}`,
        starting: {
            timestamp: timestamp,
            amount: [],
        },
        offlineCreated: true,
    };
    await waiterRevenueTable.add(lastWaiterRevenueRecord);
    return lastWaiterRevenueRecord;
};

const getUserShift = async (data) => {
    const { _id } = data || {};
    const waiterRevenueTable = cacheDB["waiterrevenue"];
    const waiterRevenues = (await waiterRevenueTable.toArray()) || [];
    const waiterRevenueRecords = waiterRevenues?.filter((doc) =>
        doc._id?.startsWith("waiterrevenues_" + _id?.split("users_")?.[1])
    );
    const lastWaiterRevenueRecord = waiterRevenueRecords?.pop();
    const prevClosedLastShift = waiterRevenueRecords?.pop();
    return lastWaiterRevenueRecord
        ? { ...lastWaiterRevenueRecord, lastClosed: prevClosedLastShift?.closing?.timestamp }
        : null;
};

const closeUserShift = async (data) => {
    const { amount, paymentsAmount, _id } = data || {};

    const record = {
        _id,
        closing: {
            timestamp: DateTime.utc().toISO({ includeOffset: false }) + "Z",
            amount,
            paymentsAmount,
        },
        offlineEdited: true,
    };
    const waiterRevenueTable = cacheDB["waiterrevenue"];
    await waiterRevenueTable.update(_id, record);
    return record;
};

const cacheOpenOrders = async (data) => {
    const { orders = [] } = data || {};
    const ordersIds = orders?.map((order) => order._id);
    const ordersTable = cacheDB["orders"];
    const existingOrders = await ordersTable.filter((order) => ordersIds?.includes(order._id)).toArray();
    await ordersTable?.bulkPut(
        orders?.filter((order) => {
            const hasOrderChanged = existingOrders?.some((doc) => doc._id === order._id && doc.offlineEdited);
            return !hasOrderChanged;
        })
    );
};

const cacheOrdersFromSocket = async (data) => {
    const { deletedOrders = [], updatedOrders = [], newOrders = [] } = data || {};

    const ordersTable = cacheDB["orders"];
    if (newOrders?.length > 0) {
        await ordersTable.bulkPut(newOrders);
    }
    if (deletedOrders?.length > 0) {
        await ordersTable.bulkDelete(deletedOrders);
    }
    if (updatedOrders?.length > 0) {
        await ordersTable.bulkPut(updatedOrders);
    }
};

const getOrdersbyTimestamp = async (data) => {
    const { startDate } = data || {};
    const ordersTable = cacheDB["orders"];
    const orders = await ordersTable
        .where("_id")
        .above("orders_" + startDate)
        .toArray();
    if (orders?.length === 0) return [];

    const paymentMethodsTable = cacheDB["paymentmethods"];
    const paymentMethods = await paymentMethodsTable.toArray();

    const pospointsTable = cacheDB["pospoints"];
    const pospoints = await pospointsTable.toArray();

    return orders?.map((order) => {
        if (order.payments?.paymentMethod) {
            const paymentMethodData = paymentMethods?.find((doc) => doc._id === order.payments?.paymentMethod);
            order.paymentMethodData = { name: paymentMethodData?.name, method: paymentMethodData?.method };
        }
        const posData = pospoints?.find((doc) => doc._id === order.pospointId);
        order.pospointData = { _id: posData._id, name: posData.name, defaultCurrency: posData.defaultCurrency };
        return order;
    });
};
const getOrdersPaymentsbyTimestamp = async (data) => {
    const { start } = data || {};
    const ordersTable = cacheDB["orders"];
    const orders = await ordersTable.where("_id").above(start).toArray();
    if (orders?.length === 0) return { orders: [] };

    const paymentMethodsTable = cacheDB["paymentmethods"];
    const paymentMethods = await paymentMethodsTable.toArray();

    const pospointsTable = cacheDB["pospoints"];
    const pospoints = await pospointsTable.toArray();

    const payments = orders
        ?.filter((order) => {
            return (
                !!order?.payments?.paymentMethod &&
                DateTime.fromISO(order?.payments?.timestamp) > DateTime.fromISO(start)
            );
        })
        ?.map((order) => {
            const { timestamp, cashReceived, paymentMethod } = order?.payments;
            const paymentMethodData = paymentMethods?.find((doc) => doc._id === paymentMethod);

            const posData = pospoints?.find((doc) => doc._id === order.pospointId);

            return {
                timestamp,
                amount: cashReceived,
                paymentMethod,
                paymentMethodData: { name: paymentMethodData?.name, method: paymentMethodData?.method },
                userName: order.createdBy,
                order: {
                    totalPrice: order.totalPrice,
                    pospointData: { _id: posData._id, name: posData.name, defaultCurrency: posData?.defaultCurrency },
                    status: order.status,
                },
            };
        });
    return { orders: payments };
};

const getOrderFromCache = async (data) => {
    const { _id } = data || {};
    const ordersTable = cacheDB["orders"];
    const order = await ordersTable.get(_id);

    const productsDataAsString = localStorage.getItem("cacheProducts");
    if (!productsDataAsString) return undefined;
    const productsData = JSON.parse(productsDataAsString);
    const { products } = productsData || {};

    order.products = order?.products?.map((product) => {
        const productData = products?.find((p) => p._id === product?.productId) || {};
        return { ...product, productData };
    });
    return order;
};

export const endpoints = {
    settingsgeneral: { resource: "settings", recordsType: "SINGLE_RECORD", cache: true },
    settingsproperty: { resource: "settings", recordsType: "SINGLE_RECORD", cache: true },
    pospoints: { resource: "pospoints", recordsType: "ALL_RECORDS", cache: true },
    posplaces: { resource: "posplaces", recordsType: "ALL_RECORDS", cache: true },
    posdiscounts: { resource: "posdiscounts", recordsType: "ALL_RECORDS", cache: true },
    pospoint: { resource: "pospoints", recordsType: "SINGLE_RECORD", cache: false },
    currencies: { resource: "currencies", recordsType: "ALL_RECORDS", cache: true },
    paymentmethods: { resource: "paymentmethods", recordsType: "ALL_RECORDS", cache: true },
    order: {
        resource: "orders",
        recordsType: "CUSTOM",
        cache: false,
        customGetData: getOrderFromCache,
    },
    saveOrder: {
        resource: "orders",
        recordsType: "SAVE_RECORD",
        cache: false,
        getRecord: onOrderSave,
        customCache: cacheOrderSave,
    },
    updateOrder: {
        resource: "orders",
        recordsType: "UPDATE_RECORD",
        cache: false,
        getRecord: onOrderEdit,
        customCache: cacheOrderSave,
    },
    payOrder: {
        resource: "orders",
        recordsType: "UPDATE_RECORD",
        cache: false,
        getRecord: onOrderPay,
        customCache: cacheOrderSave,
    },
    openOrders: {
        resource: "orders",
        recordsType: "RECORDS_BY_FILTER",
        cache: false,
        filterBy: (order) => order.status === "OPEN",
        formatData: (orders) => {
            return { orders: orders };
        },
        getData: (data) => {
            if (data.orders) return data.orders;
            return data;
        },
        customCache: cacheOpenOrders,
    },
    getWaitersOfflineData: {
        cache: false,
        recordsType: "CUSTOM",
        customCache: getWaitersOfflineCustomCache,
        customGetData: getWaitersOfflineCustomData,
    },
    waiterLogin: {
        cache: false,
        recordsType: "CUSTOM",
        customGetData: getWaiterLoginCustomData,
    },
    openShift: {
        cache: true,
        recordsType: "CUSTOM",
        customGetData: openUserShift,
        resource: "waiterrevenue",
    },
    closeShift: {
        cache: true,
        recordsType: "CUSTOM",
        customGetData: closeUserShift,
    },
    myShift: {
        cache: false,
        recordsType: "CUSTOM",
        customGetData: getUserShift,
    },
    ordersRefresh: {
        cache: false,
        recordsType: "CUSTOM",
        customCache: cacheOrdersFromSocket,
    },
    orders: {
        cache: false,
        recordsType: "CUSTOM",
        customGetData: getOrdersbyTimestamp,
    },
    paymentsByDates: {
        cache: false,
        recordsType: "CUSTOM",
        customGetData: getOrdersPaymentsbyTimestamp,
    },
};
