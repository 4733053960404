import axios from "axios";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSubdomain } from "../../utils";
import { cacheData, getOfflineCreatedRecords, loadDataFromCache, updateOfflineCreatedRecords } from "./cache/cacheData";
import { getQueryBody } from "./queryGraphQL";

////const API_URL = "http://localhost:4000/";
//const API_URL = "https://jezcorporation.com/api/";
//const API_URL = "https://app.hotelbee.co/api/";

const developmentURL = "http://localhost:4000/";
const productionURL = "https://jezcorporation.com/api/";

const API_URL = process.env.NODE_ENV === "production" ? productionURL : developmentURL;

const useApi = () => {
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");
    const offlinePosAsString = localStorage.getItem("offlinePos") || "false";
    const offlinePos = Boolean(JSON.parse(offlinePosAsString));
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const handleErrorResponse = (error) => {
        const {
            code, // to check for no internet connection
            response, // error thrown by server with status different than 200
            message, // error message when server responds with status 200 with error inside response
        } = error;
        if (code === "ERR_NETWORK") {
            enqueueSnackbar("no internet", { variant: "warning" });
            return undefined;
        }
        if (response && response.data.errors) {
            const errorMessage = response.data.errors[0].message;
            enqueueSnackbar(errorMessage, { variant: "error" });
            if (errorMessage === "session_expired") navigate("/login");
            return undefined;
        }
        enqueueSnackbar(message, { variant: "error" });
        if (message === "session_expired") navigate("/login");
        return undefined;
    };

    /**
     *
     * @param {Object} options
     * @param {string} options.operation - operation name: query | mutation
     * @param {string} options.endpoint - endpoint: Ex createUser
     * @param {Object} options.data - endpoint arguments
     * @param {string} options.responseData - response fields: Ex "_id name description ..."
     * @param {Array=} options.multipleEndpoints - case for multiple endpoint queries or mutations
     */

    const fetch = async (options = {}) => {
        setLoading(true);
        try {
            if (["updateOrder", "payOrder"]?.includes(options?.endpoint)) {
                if (offlinePos && (!!options?.data?.offlineCreated || !!options?.data?.offlineEdited)) {
                    console.log("trying to cache");
                    return await loadDataFromCache(options);
                }
            }
            const apiResponse = await axios.post(API_URL, getQueryBody(options), {
                headers: {
                    "Content-Type": "application/json",
                    hotel: getSubdomain(),
                    token: !!token ? token : undefined,
                },
            });
            setLoading(false);
            const { data = {}, errors } = apiResponse.data;
            if (errors && errors.length > 0) throw new Error(errors[0].message);
            if (offlinePos) {
                cacheData(data);
            }
            return data;
        } catch (error) {
            console.log(error);
            setLoading(false);
            if (offlinePos) {
                if (error?.code === "ERR_NETWORK") {
                    try {
                        return await loadDataFromCache(options);
                    } catch (err) {
                        return handleErrorResponse(err);
                    }
                }
            }
            return handleErrorResponse(error);
        }
    };

    const syncCacheWithServer = async () => {
        if (!offlinePos) return false;
        const offlineData = await getOfflineCreatedRecords();
        if (!offlineData) return false;
        setLoading(true);
        try {
            const options = {
                operation: "mutation",
                endpoint: "saveOfflineData",
                responseData: "_id offlineId number",
                data: offlineData,
            };
            const apiResponse = await axios.post(API_URL, getQueryBody(options), {
                headers: {
                    "Content-Type": "application/json",
                    hotel: getSubdomain(),
                    token: !!token ? token : undefined,
                },
            });
            const { data = {}, errors } = apiResponse.data;
            if (errors && errors.length > 0) throw new Error(errors[0].message);
            await updateOfflineCreatedRecords(offlineData, data?.saveOfflineData);
            setLoading(false);
            return true;
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        return false;
    };

    return { loading, fetch, syncCacheWithServer };
};

export default useApi;
