import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    LinearProgress,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import readXlsxFile from "read-excel-file";
import { FileControl, SelectControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { Table } from "../../../components/table";
import { ImportSteps } from "./components";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const productColumns = [
    {
        label: "name",
        value: "name",
    },
    {
        label: "category",
        value: "categoryId",
    },
    {
        label: "code",
        value: "code",
    },
    {
        label: "price",
        value: "price",
    },
    {
        label: "unit_of_measure",
        value: "measureUnit",
    },
    {
        label: "cost",
        value: "cost",
    },
    {
        label: "purchase_unit_of_measure",
        value: "purchaseUnit",
    },
    {
        label: "warehouses",
        value: "warehouseIds",
    },
    {
        label: "posplaces",
        value: "posplaces",
    },
    {
        label: "pospoints",
        value: "pospoints",
    },
    {
        label: "productType",
        value: "productType",
    },
    {
        label: "alphacode",
        value: "alphacode",
    },
];

const ProductImports = () => {
    const { t } = useTranslation();
    const [fileRows, setFileRows] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [connectedColumns, setConnectedColumns] = useState({});
    const [productsData, setProductsData] = useState();
    const [extraData, setExtraData] = useState({
        productcategories: [],
        taxes: [],
        warehouses: [],
        posplaces: [],
        pospoints: [],
    });

    const { loading, fetch } = useApi();

    useEffect(() => {
        loadExtraData();
    }, []);

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "taxes",
                    responseData: "_id rate",
                },
                { endpoint: "pospoints", responseData: "_id name" },
                { endpoint: "posplaces", responseData: "_id name" },
                { endpoint: "warehouses", responseData: "_id name" },
                { endpoint: "productcategories", responseData: "_id name" },
            ],
        });
        if (response) setExtraData(response);
    };

    const fileColumnsOptions = useMemo(() => {
        return fileRows?.[0]?.map((col) => ({ label: col, value: col }));
    }, [fileRows]);

    const addFile = (rows) => {
        const columns = rows?.[0];
        const toProductColumns = {};
        columns?.forEach((col) => {
            const { value } = productColumns?.find((c) => c.label?.toLowerCase() === col?.toLowerCase()) || {};
            if (value) toProductColumns[value] = col;
        });
        setConnectedColumns(toProductColumns);
        setFileRows(rows);
        setActiveStep(1);
    };

    const transformToProductsData = () => {
        const inputColumns = fileRows[0];
        const inputProducts = fileRows?.slice(1);

        const products = inputProducts?.reduce((acc, product) => {
            const productData = {};
            Object.entries(connectedColumns)?.forEach(([value, inputCol]) => {
                const colIndex = inputColumns.indexOf(inputCol);
                if (colIndex > -1) {
                    productData[value] = product[colIndex];
                }
            });
            acc.push(productData);
            return acc;
        }, []);
        return products;
    };

    const saveProducts = async () => {
        const products = transformToProductsData()?.map((product) => {
            const {
                name,
                categoryId,
                code,
                price,
                measureUnit,
                cost,
                purchaseUnit,
                warehouseIds,
                posplaces,
                pospoints,
                alphacode,
                productType,
            } = product;
            return {
                name,
                categoryId: extraData?.productcategories?.find(
                    (c) => c.name?.toLowerCase() === categoryId?.toLowerCase()
                )?._id,
                code,
                productType: "standard",
                price,
                measureUnit,
                cost,
                purchaseUnit,
                warehouseIds: warehouseIds
                    ?.split(",")
                    ?.map(
                        (warehouse) =>
                            extraData?.warehouses?.find((w) => w.name?.toLowerCase() === warehouse?.toLowerCase())?._id
                    ),
                posplaces: posplaces
                    ?.split(",")
                    ?.map(
                        (posplace) =>
                            extraData?.posplaces?.find((p) => p.name?.toLowerCase() === posplace?.toLowerCase())?._id
                    ),
                pospoints: pospoints?.split(",")?.map((pospoint) => ({
                    posPointId: extraData?.pospoints?.find((p) => p.name?.toLowerCase() === pospoint?.toLowerCase())
                        ?._id,
                })),
                taxData: [
                    {
                        taxId: "taxes_2023-05-05T10:10:19.540Z",
                        isIncluded: true,
                    },
                ],
                isVariant: false,
                ignoreStock: false,
                alphacode,
                productType,
            };
        });
        const response = await fetch({
            operation: "mutation",
            endpoint: "createMultipleProducts",
            data: {
                products,
            },
        });
        if (response?.createMultipleProducts === "success") setActiveStep(2);
    };

    return (
        <>
            <ImportSteps activeStep={activeStep} />
            {activeStep === 0 ? (
                <Card sx={{ marginTop: 2 }}>
                    <CardHeader title={t("choose_file")} titleTypographyProps={{ variant: "h6" }} />
                    <Divider />
                    <CardContent>
                        <FileControl
                            onChange={(file) => {
                                readXlsxFile(file).then((rows, index) => {
                                    addFile(rows);
                                });
                            }}
                        />
                    </CardContent>
                </Card>
            ) : activeStep === 1 ? (
                <Card sx={{ marginTop: 2 }}>
                    <CardHeader title={t("connect_columns")} titleTypographyProps={{ variant: "h6" }} />
                    <Divider />
                    <CardContent>
                        {loading && <LinearProgress color="primary" />}
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">{t("product_column")}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">{t("file_column")}</Typography>
                                    </Grid>
                                </Grid>
                                {productColumns?.map((productColumn) => {
                                    return (
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography>{t(productColumn?.label)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <SelectControl
                                                    value={connectedColumns?.[productColumn.value] || ""}
                                                    onChange={(e) => {
                                                        setConnectedColumns({
                                                            ...connectedColumns,
                                                            [productColumn.value]: e.target.value,
                                                        });
                                                    }}
                                                    label={t("column")}
                                                    options={fileColumnsOptions}
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={() => {
                                        setProductsData(transformToProductsData());
                                    }}
                                >
                                    {t("preview_products")}
                                </Button>
                                {productsData && <ProductsPreviewTable products={productsData} />}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => saveProducts()} variant="contained">
                            {t("save")}
                        </Button>
                    </CardActions>
                </Card>
            ) : (
                <Card>
                    <CardHeader title={t("confirmed")} titleTypographyProps={{ variant: "h6" }} />
                    <Divider />
                    <CardContent>
                        <ListItem>
                            <ListItemIcon>
                                <DoneAllIcon color="success" />
                            </ListItemIcon>
                            <ListItemText
                                primary={t("products_imported_successfully")}
                                primaryTypographyProps={{ variant: "h6" }}
                            />
                        </ListItem>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default ProductImports;

const ProductsPreviewTable = ({ products }) => {
    const { t } = useTranslation();
    const columns = [
        { accessorKey: "name", header: t("product_name") },
        {
            header: t("category"),
            accessorKey: "categoryId",
        },
        {
            header: t("code"),
            accessorKey: "code",
        },
        { accessorKey: "price", header: t("price") },
        { accessorKey: "measureUnit", header: t("measure_unit") },
        { accessorKey: "cost", header: t("cost") },
        { accessorKey: "purchaseUnit", header: t("purchase_unit") },
        { accessorKey: "warehouseIds", header: t("warehouses") },
        { accessorKey: "posplaces", header: t("posplaces") },
        { accessorKey: "pospoints", header: t("pospoints") },
        { accessorKey: "alphacode", header: t("alphacode") },
        { accessorKey: "productType", header: t("productType") },
    ];
    return <Table columns={columns} data={products || []} disableHeader disableSelection />;
};
