const { createTheme } = require("@mui/material");

export const theme = createTheme({
    palette: {
        primary: {
            main: "#264653",
            white: "#ffffff",
        },
        secondary: {
            main: "#d90429",
        },
        success: {
            main: "#1b5e20",
        },
        terciary: {
            main: "#FB153D",
        },
        link: {
            main: "#00A8CC",
            contrastText: "white",
        },
        background: {
            default: "#F1f1f1",
        },
        sideBarColor: {
            default: "#03363D",
        },
        navbarColor: {
            default: "rgba(255,255,255,0.8)",
            contrastText: "#3d586b",
        },
        table: {
            rowColor: "#ffffff",
            backgroundColor: "#ffffff",
            borderColor: "rgba(0, 0, 0, 0.05)",
            headBorderColor: "#ccc",
            text: "black",
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            '"IBM Plex Sans"',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        button: {
            fontWeight: 500,
        },
        subtitle1: {
            fontWeight: 500,
        },
        subtitle2: {
            fontWeight: 600,
        },
        h1: {
            fontWeight: 500,
        },
        h2: {
            fontWeight: 500,
        },
        h3: {
            fontWeight: 500,
        },
        h4: {
            fontWeight: 600,
        },
        h5: {
            fontWeight: 500,
        },
        h6: {
            fontWeight: 600,
        },
        overline: {
            fontWeight: 600,
        },
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontSize: 16,
                },
            },
        },
        MuiCard: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 3,
                },
            },
        },
        MuiMenu: {
            defaultProps: {
                elevation: 1,
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: "secondary",
            },
            styleOverrides: {
                root: {
                    padding: 8,
                },
                thumb: {
                    boxShadow: "none",
                    width: 16,
                    height: 16,
                    margin: 2,
                    color: "white",
                },
                track: {
                    borderRadius: 22 / 2,
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "terciary",
            },
        },
        MuiRadio: {
            defaultProps: {
                color: "terciary",
            },
        },
        MuiDialog: {
            defaultProps: {
                PaperProps: {
                    elevation: 3,
                },
            },
            styleOverrides: {
                paper: {
                    borderRadius: 10,
                },
                paperFullScreen: {
                    borderRadius: 0,
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    marginTop: "30px",
                },
            },
        },
        MuiToggleButton: {
            color: "red",
            backgroundColor: "black",
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#264653",
        },
        secondary: {
            main: "rgba(255,65,54)",
        },
        success: {
            main: "#1b5e20",
        },
        terciary: {
            main: "#FB153D",
        },
        link: {
            main: "#00A8CC",
            contrastText: "white",
        },
        text: {
            primary: "#CCCCCC",
            secondary: "#aaa",
        },
        action: {
            disabledBackground: "rgba(255,255,255, 0.12)",
            disabled: "rgba(255,255,255, 0.38)",
        },
        background: {
            default: "#0A1929",
        },
        sideBarColor: {
            default: "#071B2F",
        },
        navbarColor: {
            default: "rgba(0,0,0,0.8)",
            contrastText: "#fff",
        },
        table: {
            rowColor: "#2d2d2d",
            backgroundColor: "transparent",
            borderColor: "rgba(255, 255, 255, 0.12)",
            headBorderColor: "#3d3d3d",
            text: "#CCCCCC",
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            '"IBM Plex Sans"',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        button: {
            fontWeight: 500,
        },
        subtitle1: {
            fontWeight: 500,
        },
        subtitle2: {
            fontWeight: 600,
        },
        h1: {
            fontWeight: 500,
        },
        h2: {
            fontWeight: 500,
        },
        h3: {
            fontWeight: 500,
        },
        h4: {
            fontWeight: 600,
        },
        h5: {
            fontWeight: 500,
        },
        h6: {
            fontWeight: 600,
        },
        overline: {
            fontWeight: 600,
        },
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontSize: 16,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 3,
                },
            },
        },
        MuiCard: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    borderColor: "#3d3d3d",
                },
            },
        },
        MuiMenu: {
            defaultProps: {
                elevation: 1,
                PaperProps: {
                    sx: {
                        backgroundColor: "#2d2d2d",
                        color: "#fff",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#2d2d2d",
                    borderColor: "rgba(255, 255, 255, 0.1)",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    color: "#fff",
                    "& fieldset": {
                        borderColor: "rgba(255, 255, 255, 0.1)",
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#aaa",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: "#aaa",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                clearIndicator: {
                    color: "#aaa",
                },
                popupIndicator: {
                    color: "#aaa",
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: "secondary",
            },
            styleOverrides: {
                root: {
                    padding: 8,
                },
                thumb: {
                    boxShadow: "none",
                    width: 16,
                    height: 16,
                    margin: 2,
                    color: "white",
                },
                track: {
                    borderRadius: 22 / 2,
                    backgroundColor: "grey",
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "terciary",
            },
            styleOverrides: {
                root: {
                    color: "#aaa",
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                color: "terciary",
            },
            styleOverrides: {
                root: {
                    color: "#aaa",
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                PaperProps: {
                    variant: "outlined",
                },
            },
            styleOverrides: {
                paper: {
                    borderRadius: 10,
                    backgroundColor: "#111315",
                    borderColor: "#3d3d3d",
                },
                paperFullScreen: {
                    borderRadius: 0,
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    marginTop: "30px",
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: "#aaa",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: "#2d2d2d",
                },
            },
        },
        MuiChip: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                barColorPrimary: {
                    backgroundColor: "#00A8CC",
                },
                colorPrimary: {
                    backgroundColor: "white",
                },
            },
        },
    },
});
