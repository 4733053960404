import { Card, CardContent, CardMedia, IconButton, ListItemText, Popover } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const RoomtypeInfoModal = ({ roomtypeData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <div>
            <IconButton
                color="inherit"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick(e);
                }}
            >
                <InfoOutlinedIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                elevation={2}
            >
                <Card sx={{ display: "flex", paddingX: "20px", maxWidth: 500 }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 150 }}
                        image="https://res.cloudinary.com/strowberry-code/image/upload/v1609761045/hb/gummy-bed_p0imby.svg"
                        alt="roomtype-img"
                    />
                    <CardContent>
                        <ListItemText
                            primary={t("description")}
                            primaryTypographyProps={{ variant: "subtitle2" }}
                            secondary={roomtypeData?.description}
                        />
                        <ListItemText
                            primary={t("amenities")}
                            primaryTypographyProps={{ variant: "subtitle2" }}
                            secondary={roomtypeData?.amenities?.join(" , ")}
                        />
                        <ListItemText
                            primary={t("bedtypes")}
                            primaryTypographyProps={{ variant: "subtitle2" }}
                            secondary={roomtypeData?.bedtypes
                                ?.map((bedtype) => (bedtype.name ? `${bedtype.name} ${bedtype.quantity}` : ""))
                                .join(" , ")}
                        />
                    </CardContent>
                </Card>
            </Popover>
        </div>
    );
};

export default RoomtypeInfoModal;
