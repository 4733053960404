import React from "react";
import { Navigate } from "react-router-dom";
import getPermission from "./getPermission";

const PrivateRoute = ({ permission, children }) => {
    const { hasToken, hasRight } = getPermission(permission);
    if (!hasToken) return <Navigate to="/login" />;
    if (!hasRight) return null;
    return children;
};

export default PrivateRoute;
