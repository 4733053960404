import { Button, Card, CardActions, CardHeader, Grid, LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../../components/hooks";
import { DeleteRecordModal } from "../../../components/common";

const SettingsEmailList = () => {
    const [settingsEmails, setSettingsEmails] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "settingsemails",
            responseData: "_id trigger subject body",
        });
        if (response?.settingsemails) setSettingsEmails(response.settingsemails);
    };

    const onDeleteHandler = async (_id) => {
        const data = {};
        data._id = _id;

        const response = await fetch({
            operation: "mutation",
            endpoint: "deleteSettingsEmail",
            data,
        });
        if (response?.deleteSettingsEmail === "success") {
            setSettingsEmails(settingsEmails.filter((email) => email._id !== _id));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addRecordSettingsEmail = (data) => {
        if (settingsEmails?.some((r) => r._id === data._id)) {
            setSettingsEmails(
                settingsEmails?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setSettingsEmails([...setSettingsEmails, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Grid container spacing={2} alignItems="center">
                {settingsEmails?.map((email) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <EmailCard
                            t={t}
                            name={t(email?._id?.split("EmailConfig_")[1]) + " " + t("email")}
                            onClick={() => navigate(`${email._id?.split("EmailConfig_")[1]}`)}
                            onDelete={() => onDeleteHandler(email._id)}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Button onClick={() => navigate("/settings/email/create")}>
                        <AddIcon style={{ fontSize: 200 }} />
                    </Button>
                </Grid>
            </Grid>
            <Outlet context={{ addRecordSettingsEmail }} />
        </div>
    );
};

const EmailCard = ({ name, onClick, onDelete, t }) => {
    return (
        <Card>
            <CardHeader
                style={{ cursor: "pointer" }}
                onClick={onClick}
                avatar={<MailOutlineIcon sx={{ fontSize: 40 }} />}
                title={`${t(name)}`}
                titleTypographyProps={{ variant: "subtitle2", fontSize: 18 }}
            />
            <CardActions>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <DeleteRecordModal onConfirm={onDelete} />
                    </Grid>
                    <Grid item xs={9}>
                        <Button onClick={onClick} variant="outlined" startIcon={<EditIcon />} fullWidth>
                            {t("edit")}
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

export default SettingsEmailList;
