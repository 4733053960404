export const setPermission = (token, userData) => {
    localStorage.setItem("token", token);

    localStorage.setItem("user", JSON.stringify(userData));
};

export const removePermission = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
};
