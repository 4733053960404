import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, RadioGroupControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const TransactionForm = () => {
    const [values, setValues] = useState({});
    const [paymentAccounts, setPaymentaccounts] = useState([]);
    const [paymentMethods, setPaymentmethods] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordTransaction } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "transaction",
            data: {
                _id: "transactions_" + params?.id,
            },
            responseData: "_id type paymentType paymentAccount currency amount username description",
        });
        if (response?.transaction) setValues(response.transaction);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "paymentmethods",
                    responseData: "_id name",
                },
                {
                    endpoint: "paymentaccounts",
                    responseData: "_id name",
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency",
                },
            ],
        });
        if (response?.paymentmethods)
            setPaymentmethods(
                response?.paymentmethods?.map((p) => {
                    return { value: p?._id, label: p?.name };
                })
            );
        if (response?.paymentaccounts)
            setPaymentaccounts(
                response?.paymentaccounts?.map((p) => {
                    return { value: p?._id, label: p?.name };
                })
            );
        if (response?.currencies)
            setCurrencies(
                response?.currencies?.map((c) => {
                    return { value: c?.currency, label: c?.currency };
                })
            );
    };

    const saveHandler = async () => {
        const { type, paymentAccount, paymentType, currency, amount, description, _id } = values;
        const data = {
            type,
            paymentAccount,
            paymentType,
            currency,
            amount: isNaN(amount) ? 0 : parseFloat(amount),
            description,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateTransaction" : "createTransaction",
            data,
            responseData: "_id type paymentType paymentAccount currency amount username description",
        });
        if (response?.createTransaction?._id || response?.updateTransaction?._id) {
            enqueueSnackbar(t("transaction_saved"), { variant: "default" });
            addRecordTransaction && addRecordTransaction(response.createTransaction || response?.updateTransaction);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("transaction")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ transactions: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <RadioGroupControl
                        name="type"
                        label={t("type")}
                        options={[
                            { value: "payment", label: t("payment") },
                            { value: "withdrawal", label: t("withdrawal") },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectControl name="paymentAccount" label={t("payment_account")} options={paymentAccounts} />
                </Grid>
                <Grid item xs={12}>
                    <SelectControl name="paymentType" label={t("payment_type")} options={paymentMethods} />
                </Grid>
                <Grid item xs={12}>
                    <SelectControl name="currency" label={t("currency")} options={currencies} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="amount" label={t("amount")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="description" label={t("description")} multiline minRows={4} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default TransactionForm;
