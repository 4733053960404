import React from "react";
import { useTranslation } from "react-i18next";
import { InputControl, SelectControl } from "../../../../components/form";
import { EditableTable } from "../../../../components/table";

const EditablePospointsTable = ({ data = [], pospoints = [], onChange }) => {
    const { t } = useTranslation();
    const columns = [
        {
            header: t("pospoint"),
            cell: ({ rowIndex }) => (
                <SelectControl
                    value={data[rowIndex]?.posPointId || ""}
                    onChange={(e) => {
                        data[rowIndex] = { posPointId: e.target.value };
                        onChange && onChange(data);
                    }}
                    options={pospoints}
                    label={t("pos")}
                />
            ),
        },
        {
            header: t("price"),
            cell: ({ rowIndex }) => (
                <InputControl
                    value={data[rowIndex]?.price || ""}
                    onChange={(e) => {
                        data[rowIndex].price = e.target.value;
                        onChange && onChange(data);
                    }}
                    label={t("price")}
                />
            ),
        },
    ];
    return (
        <EditableTable
            columns={columns}
            data={data}
            onAddRow={() => onChange([...data, {}])}
            onDeleteRow={(rowIndex) => onChange(data.filter((r, index) => index !== rowIndex))}
        />
    );
};

export default EditablePospointsTable;
