import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReportGenerator } from "../../components/classes";
import { DateRangeControl, TimeControl } from "../../components/dates";
import { AutoCompleteControl, SelectControl } from "../../components/form";
import { useApi, useProductCategories, useProducts } from "../../components/hooks";
import { calculateTotalTax, toCurrency } from "../../utils";
import { ReportHeader } from "./components";

const ProductsReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();

    const [report, setReport] = useState(null);
    const { loading, fetch } = useApi();
    const { products } = useProducts();
    const { productCategories } = useProductCategories();
    const [posOptions, setPosOptions] = useState([]);

    useEffect(() => {
        loadPOS();
    }, []);

    const loadPOS = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "pospoints",
            responseData: "_id name isActive",
        });
        if (response)
            setPosOptions(
                response?.pospoints
                    ?.filter((pos) => pos.isActive)
                    ?.map((pos) => ({ value: pos._id, label: pos.name }))
                    ?.concat([{ value: "all", label: t("all") }])
            );
    };

    const columns = [
        {
            id: "product",
            header: t("product"),
            displayValue: (row) => {
                return row?.name || "";
            },
            totalValue: () => t("total"),
        },
        {
            id: "category",
            header: t("category"),
            displayValue: (row) => {
                return row?.categoryName || "";
            },
            totalValue: () => "",
        },
        {
            id: "gross",
            header: t("gross"),
            displayValue: (row) => {
                return (row.priceSum || 0)?.toFixed(2);
            },
            totalValue: (rows) => rows?.reduce((acc, row) => acc + (row.priceSum || 0), 0.0)?.toFixed(2),
        },
        {
            id: "subtotal",
            header: t("subtotal"),
            displayValue: (row) => {
                const { totalAmount, includedAmount } = calculateTotalTax({
                    taxData: row?.taxData?.map((t) => ({
                        isIncluded: t.isIncluded,
                        tax: t.tax,
                    })),
                    price: parseFloat(row.priceSum),
                });
                const totalPrice = parseFloat(row.priceSum) + totalAmount - includedAmount;
                const subtotal = totalPrice - totalAmount;
                return (subtotal || 0)?.toFixed(2);
            },
            totalValue: (rows) =>
                rows
                    ?.reduce((acc, row) => {
                        const { totalAmount, includedAmount } = calculateTotalTax({
                            taxData: row.taxData?.map((t) => ({
                                isIncluded: t.isIncluded,
                                tax: t.tax,
                            })),
                            price: parseFloat(row.priceSum),
                        });
                        const totalPrice = parseFloat(row.priceSum) + totalAmount - includedAmount;
                        const subtotal = totalPrice - totalAmount;
                        return acc + (subtotal || 0.0);
                    }, 0.0)
                    ?.toFixed(2),
        },
        {
            id: "tax",
            header: t("tax"),
            displayValue: (row) => {
                const { totalAmount } = calculateTotalTax({
                    taxData: row?.taxData?.map((t) => ({
                        isIncluded: t.isIncluded,
                        tax: t.tax,
                    })),
                    price: parseFloat(row.priceSum),
                });
                return (totalAmount || 0)?.toFixed(2);
            },
            totalValue: (rows) =>
                rows
                    ?.reduce((acc, row) => {
                        const { totalAmount } = calculateTotalTax({
                            taxData: row.taxData?.map((t) => ({
                                isIncluded: t.isIncluded,
                                tax: t.tax,
                            })),
                            price: parseFloat(row.priceSum),
                        });
                        return acc + (totalAmount || 0.0);
                    }, 0.0)
                    ?.toFixed(2),
        },
        {
            id: "items",
            header: t("items"),
            displayValue: (row) => {
                return (row.quantity || 0)?.toFixed(2);
            },
            totalValue: (rows) => rows?.reduce((acc, row) => acc + (row.quantity || 0), 0.0)?.toFixed(2),
        },
    ];

    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        const start = params.get("startDate");
        const end = params.get("endDate");
        const selectedPos = params.get("pos");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + start;
        if (!!end) filtersText += ", " + t("end_date") + ": " + end;
        if (!!selectedPos) {
            const posName = posOptions?.find((p) => p.value === selectedPos)?.label;
            if (posName) filtersText += ", " + t("pospoints") + ": " + posName;
        }

        const response = await fetch({
            operation: "query",
            endpoint: "dailySoldProducts",
            data: { start: start, end: end },
            responseData: `
                pospoints{
                    pospointId
                    products{
                        productId
                        quantity
                        priceSum
                    }
                }
            `,
        });
        let data;
        if (!!selectedPos && selectedPos !== "all") {
            data = response?.dailySoldProducts?.pospoints
                ?.find((pos) => pos.pospointId === selectedPos)
                ?.products?.map((product) => {
                    const { name, taxData, categoryId } = products.find((p) => p._id === product.productId) || {};
                    const categoryName = productCategories?.find((c) => c._id === categoryId)?.name;
                    return { ...product, name, taxData, categoryName };
                });
        } else {
            const totalProducts = {};
            response?.dailySoldProducts?.pospoints?.forEach((pos) => {
                pos?.products?.forEach((product) => {
                    if (!totalProducts[product.productId]) {
                        totalProducts[product.productId] = {
                            productId: product.productId,
                            priceSum: 0.0,
                            quantity: 0.0,
                        };
                    }
                    totalProducts[product.productId].priceSum += product?.priceSum;
                    totalProducts[product.productId].quantity += product?.quantity;
                });
            });
            data = Object.values(totalProducts)?.map((product) => {
                const { name, taxData, categoryId } = products.find((p) => p._id === product.productId) || {};
                const categoryName = productCategories?.find((c) => c._id === categoryId)?.name;
                return { ...product, name, taxData, categoryName };
            });
        }
        if (!response?.dailySoldProducts?.pospoints) return;
        const newRaportGenerator = new ReportGenerator({
            data: data,
            columns,
            displayTotal: true,
        });
        setReport(newRaportGenerator.generateTable({ title: t("products"), headerInfo: [filtersText] }));
    };

    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("pos")) data.pos = params.get("pos");

        return data;
    };

    return (
        <div>
            <ReportHeader
                title={t("products_report")}
                loading={loading}
                disableRunInfo={t("loading_products")}
                disableRun={!products?.length > 0 && productCategories?.length > 0}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <SelectControl
                        label={t("pos")}
                        options={posOptions}
                        value={params.get("pos")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                pos: e.target.value || "",
                            })
                        }
                        sx={{ width: "400px" }}
                    />
                </Stack>
            </ReportHeader>
            {report}
        </div>
    );
};

export default ProductsReport;
