import { ButtonBase, Card, CardContent, CardActions, CardHeader, Typography } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

const DiscountCard = ({ title, text, titleIcon, isSelected, onClick, ...containerProps }) => {
    return (
        <ButtonBase onClick={onClick} {...containerProps}>
            <Card sx={isSelected ? { borderColor: "success.main", borderWidth: 2, fontWeight: "bold" } : {}}>
                <CardHeader
                    avatar={titleIcon}
                    title={title}
                    titleTypographyProps={{ variant: "subtitle1", textAlign: "left" }}
                />
                <CardContent sx={{ paddingY: 0 }}>
                    <Typography textAlign="left">{text}</Typography>
                </CardContent>
                {isSelected && (
                    <CardActions sx={{ justifyContent: "flex-end" }}>
                        <CheckCircleOutlinedIcon color="success" />
                    </CardActions>
                )}
            </Card>
        </ButtonBase>
    );
};

export default DiscountCard;
