import { Button, ButtonGroup, Card, CardHeader, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { useTranslation } from "react-i18next";
import { InvoicesTable, ReservationDetailsCard, ReservationsTable } from "./components";
import { useSnackbar } from "notistack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddIcon from "@mui/icons-material/Add";

import ClientProfileCard from "../../components/common/ClientProfileCard";
import { NotesView } from "../../components/common";
import { AutoCompleteControl } from "../../components/form";
import { AddClientToReservationModal } from "../reservations/components";
import { InvoiceHandler } from "../../components/classes";
import { mergeInvoices } from "../../utils";

const GroupProfile = () => {
    const [groupData, setGroupData] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [attachId, setAttachId] = useState(null);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [settings, setSettings] = useState({});
    const [taxes, setTaxes] = useState([]);

    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const params = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "group",
            data: {
                _id: "groups_" + params.id,
            },
            responseData: `
                _id 
                name 
                clients 
                clientsData{
                    _id 
                    firstName 
                    lastName 
                    phone 
                    email 
                    addressLine 
                    state 
                    city 
                    birthdate 
                    nationality 
                    placeOfBirth 
                    IDType 
                    IDNumber
                }
                reservations{
                    _id 
                    checkin 
                    checkout 
                    clients
                    clientsData{_id firstName lastName} 
                    totalPrice 
                    discount{type value}
                    rooms{roomId roomtypeId prices{price rateId rateMeals date discount{type value}} roomtypeData{name _id meals taxData{taxId isIncluded}} roomData{name roomTypeData{name _id meals taxData{taxId isIncluded}}}} 
                    status
                    guests{roomId guests{name number}}
                    invoices{date dueDate _id invoiceCurrency paymentStatus clientsData{firstName lastName _id} totalPrice status payments{amount paymentMethod userName timestamp} tableData{itemId price description quantity unit taxData{taxId isIncluded}}}
                } 
                groupInvoices{
                    date dueDate _id invoiceCurrency paymentStatus clientsData{firstName lastName _id} totalPrice status payments{amount paymentMethod userName timestamp} tableData{itemId price description quantity unit taxData{taxId isIncluded}} reservationIds
                }
                notes{note timestamp}
                
            `,
        });
        if (response?.group?._id)
            setGroupData({
                ...response.group,
                reservations: response.group?.reservations?.filter((res) => res?.status !== "CANCELLED"),
            });
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
            ],
        });

        if (response?.settingsgeneral) setSettings(response.settingsgeneral);
        if (response?.taxes) setTaxes(response.taxes);
    };
    const { currency = "" } = settings?.currencyData || {};

    const generateInvoiceData = (reservation) => {
        const handler = InvoiceHandler.fromReservation({
            _id: reservation?._id,
            checkin: reservation?.checkin,
            checkout: reservation?.checkout,
            clients: reservation?.clients,
            rooms: reservation?.rooms,
            discount: reservation?.discount,
            totalPrice: reservation?.totalPrice,
            guests: reservation?.guests,
            payments: reservation?.payments,
            invoiceCurrency: reservation?.currency || currency || "",
        });
        handler.setTaxes(taxes || []);
        const invoice = handler.getInvoice();
        invoice.clientsData = {
            firstName: reservation?.clientsData?.[0]?.firstName,
            lastName: reservation?.clientsData?.[0]?.lastName,
        };
        return invoice;
    };

    const invoices = useMemo(() => {
        return groupData?.reservations
            ?.map((r) => {
                const rooms = r.rooms?.map((room) => room?.roomData?.name)?.join(" , ");
                if (r?.invoices && r?.invoices?.length > 0)
                    return r.invoices?.map((invoice) => {
                        invoice.rooms = rooms;
                        return invoice;
                    });
                return [generateInvoiceData(r)]?.map((invoice) => {
                    invoice.rooms = rooms;
                    return invoice;
                });
            })
            ?.flat();
    }, [groupData?.reservations]);

    const addNote = (note) => {
        const noteDetails = {
            note,
        };

        fetch({
            operation: "mutation",
            endpoint: "addGroupNote",
            data: { _id: "groups_" + params.id, noteText: noteDetails.note },
            responseData: "note timestamp",
        }).then((response) => {
            if (response?.addGroupNote?.timestamp) {
                setGroupData({ ...groupData, notes: [...(groupData.notes || []), response?.addGroupNote] });
                return;
            }
            setGroupData({
                ...groupData,
                notes: groupData?.notes?.filter((note) => note.timestamp !== noteDetails.timestamp),
            });
            enqueueSnackbar(t("note_not_saved"), { variant: "error" });
        });
    };

    const removeNote = (noteTimestamp) => {
        setGroupData({
            ...groupData,
            notes: groupData?.notes?.filter((note) => note.timestamp !== noteTimestamp),
        });
        fetch({
            operation: "mutation",
            endpoint: "removeGroupNote",
            data: { _id: "groups_" + params.id, noteTimestamp },
        }).then((respone) => {
            if (respone?.removeGroupNote === "success") return;
            enqueueSnackbar(t("note_remove_failed"), { variant: "error" });
        });
    };
    const removeReservation = async (id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: { _id: id, groupId: null },
            responseData: "_id",
        });
        if (response?.updateReservation?._id) {
            enqueueSnackbar(t("reservation_removed_from_group"));
            setGroupData({ ...groupData, reservations: groupData?.reservations?.filter((r) => r._id !== id) });
        }
    };
    const attachReservation = async () => {
        if (!attachId) {
            enqueueSnackbar(t("please_select_reservation"));
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: { _id: attachId, groupId: "groups_" + params.id },
            responseData: "_id",
        });
        if (response?.updateReservation?._id) {
            enqueueSnackbar(t("reservation_attached_to_group"));
            setAttachId(null);
            setOpenModal(false);
        }
    };
    const removeClientId = async (id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "removeReservationClient",
            data: {
                _id: groupData?._id,
                clientId: id,
            },
        });
        if (response?.removeReservationClient) {
            setGroupData({ ...groupData, clientsData: groupData?.clientsData?.filter((c) => c._id !== id) });
            enqueueSnackbar(t("client_removed"), { variant: "default" });
        }
    };

    const addClient = async (clientData) => {
        if (groupData?.clientsData?.some((c) => c._id === clientData?._id)) {
            setOpenSearchModal(false);
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "addReservationClient",
            data: {
                _id: groupData._id,
                clientId: clientData._id,
            },
        });
        if (response?.addReservationClient) {
            setGroupData({ ...groupData, clientsData: [...(groupData?.clientsData || []), clientData] });
            enqueueSnackbar(t("client_added"), { variant: "default" });
            setOpenSearchModal(false);
        }
    };

    const addInvoiceToGroup = async (invoices) => {
        const groupInvoice = groupData?.groupInvoices?.[0];
        for (const invoice of invoices) {
            if (invoice?._id?.startsWith("reservations_")) {
                const { date, dueDate, tableData, reservationIds, discountValue, discountType, totalPrice, customer } =
                    invoice;
                const response = await fetch({
                    operation: "mutation",
                    endpoint: "createInvoice",
                    data: {
                        date,
                        dueDate,
                        tableData,
                        reservationIds,
                        discountValue,
                        discountType,
                        totalPrice,
                        customer,
                        invoiceCurrency: invoice?.invoiceCurrency || "",
                        status: "TRANSFERRED",
                    },
                    responseData: `_id`,
                });
                invoice._id = response?.createInvoice?._id;
            } else {
                const response = await fetch({
                    operation: "mutation",
                    endpoint: "updateInvoiceStatus",
                    data: { _id: invoice._id, status: "TRANSFERRED" },
                    responseData: "_id",
                });
            }

            groupInvoice.tableData = groupInvoice?.tableData?.concat(invoice?.tableData);
            groupInvoice.payments = groupInvoice?.payments?.concat(invoice?.payments);
            groupInvoice.totalPrice += invoice?.totalPrice;
        }
        const { date, dueDate, invoiceCurrency, payments, reservationIds, tableData, totalPrice, _id } =
            groupInvoice || {};
        const groupResponse = await fetch({
            operation: "mutation",
            endpoint: "updateInvoice",
            data: {
                date,
                dueDate,
                invoiceCurrency,
                payments,
                reservationIds,
                tableData,
                totalPrice,
                _id,
            },
            responseData: "_id",
        });
        if (groupResponse?.updateInvoice?._id) {
            enqueueSnackbar(t("invoice_reverted_from_group"), { variant: "default" });
            loadData();
        }
    };

    const createGroupInvoice = async (invoices) => {
        for (const invoice of invoices) {
            if (invoice?._id?.startsWith("reservations_")) {
                const { date, dueDate, tableData, reservationIds, discountValue, discountType, totalPrice, customer } =
                    invoice;
                const response = await fetch({
                    operation: "mutation",
                    endpoint: "createInvoice",
                    data: {
                        date,
                        dueDate,
                        tableData,
                        reservationIds,
                        discountValue,
                        discountType,
                        totalPrice,
                        customer,
                        invoiceCurrency: invoice?.invoiceCurrency || "",
                        status: "TRANSFERRED",
                    },
                    responseData: `_id`,
                });
                invoice._id = response?.createInvoice?._id;
            } else {
                const response = await fetch({
                    operation: "mutation",
                    endpoint: "updateInvoiceStatus",
                    data: { _id: invoice._id, status: "TRANSFERRED" },
                    responseData: "_id",
                });
            }
        }
        const mergedInvoice = mergeInvoices(invoices);
        mergedInvoice.reservationIds = ["groups_" + params.id];

        const response = await fetch({
            operation: "mutation",
            multipleEndpoints: [
                {
                    endpoint: "createInvoice",
                    data: mergedInvoice,
                    responseData: `_id  
                    date 
                    number 
                    invoiceCurrency 
                    clientsData{firstName lastName} 
                    fiscalData{IICRef} 
                    totalPrice 
                    status 
                    payments{amount paymentMethod userName timestamp}
                    paymentStatus
                    tableData{itemId price description quantity unit taxData{taxId isIncluded}}
                    discountType
                    discountValue`,
                },
            ],
        });
        if (!!response?.createInvoice?._id) {
            enqueueSnackbar(t("invoice_merged"), { variant: "info" });
            loadData();
        }
    };

    const removeInvoiceFromGroup = async (invoiceId) => {
        const selectedInvoice = invoices?.find((inv) => inv?._id === invoiceId);
        const groupInvoice = groupData?.groupInvoices?.[0];

        if (!selectedInvoice || !groupInvoice) return;

        selectedInvoice?.tableData?.forEach((item) => {
            let isItemRemoved = false;
            groupInvoice.tableData = groupInvoice?.tableData?.filter((td) => {
                if (isItemRemoved) return true;
                const removeGroupItem = item?.itemId === td?.itemId && item?.quantity === td?.quantity;
                if (removeGroupItem) {
                    isItemRemoved = true;
                    return false;
                }
                return true;
            });
        });
        selectedInvoice?.payments?.forEach((p) => {
            let isPaymentRemoved = false;
            groupInvoice.payments = groupInvoice?.payments?.filter((payment) => {
                if (isPaymentRemoved) return true;
                const removeGroupPayment = p?.amount === payment?.amount && p?.paymentMethod === payment?.paymentMethod;
                if (removeGroupPayment) {
                    isPaymentRemoved = true;
                    return false;
                }
                return true;
            });
        });
        groupInvoice.totalPrice -= selectedInvoice?.totalPrice;
        const { date, dueDate, invoiceCurrency, payments, reservationIds, tableData, totalPrice, _id } =
            groupInvoice || {};

        const response = await fetch({
            operation: "mutation",
            endpoint: "updateInvoiceStatus",
            data: {
                _id: selectedInvoice?._id,
                status: "DRAFT",
            },
            responseData: "_id",
        });
        const groupResponse = await fetch({
            operation: "mutation",
            endpoint: "updateInvoice",
            data: {
                date,
                dueDate,
                invoiceCurrency,
                payments,
                reservationIds,
                tableData,
                totalPrice,
                _id,
            },
            responseData: "_id",
        });
        if (groupResponse?.updateInvoice?._id && response?.updateInvoiceStatus?._id) {
            enqueueSnackbar(t("invoice_reverted_from_group"), { variant: "default" });
            loadData();
        }
    };
    const changeStatusInBulk = async ({ reservations, status }) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservationsStatus",
            data: {
                ids: reservations,
                status: status,
            },
        });
        if (response?.updateReservationsStatus === "success") {
            enqueueSnackbar(t("status_updated"), { variant: "info" });
            return;
        }
        enqueueSnackbar(t("status_update_failed"), { variant: "error" });
    };

    return (
        <>
            <Modal
                titlelabel={t("groups")}
                loading={loading}
                onClose={() => navigate(-1)}
                open
                fullScreen
                yesText="none"
                noText="none"
            >
                <Grid container spacing={4} alignItems="start">
                    <Grid item xs={12} sm={4}>
                        {groupData?.clientsData?.map((client, i) => {
                            if (!client)
                                return (
                                    <Card>
                                        <CardHeader
                                            title={t("client_removed")}
                                            titleTypographyProps={{ variant: "h5" }}
                                        />
                                    </Card>
                                );
                            return (
                                <ClientProfileCard
                                    disableRemove={!(groupData?.clientsData?.length > 0)}
                                    onRemoveClick={removeClientId}
                                    defaultExpanded={i === 0}
                                    loading={loading}
                                    clientData={client || {}}
                                />
                            );
                        })}
                        <Button
                            onClick={() => setOpenSearchModal(true)}
                            startIcon={<AddIcon />}
                            fullWidth
                            sx={{ marginBottom: 1, marginTop: 1 }}
                            variant="outlined"
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                        >
                            {t("add_client")}
                        </Button>
                        <NotesView notes={groupData?.notes || []} addNote={addNote} removeNote={removeNote} />
                    </Grid>
                    <Grid container item xs={12} sm={8} spacing={4}>
                        <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h5">
                                {t("group_name")}: {groupData?.name}
                            </Typography>
                            <ButtonGroup>
                                <Button onClick={() => navigate(`/newreservation?groupId=${groupData?._id}`)}>
                                    {t("new_reservation")}
                                </Button>
                                {/* <Button startIcon={<AttachFileIcon />} onClick={() => setOpenModal(true)}>
                                    {t("attach_reservation")}
                                </Button> */}
                            </ButtonGroup>
                        </Grid>
                        {/*<Grid item xs={12}>
                             <Card>
                            <Tabs variant="fullWidth" value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                                <Tab iconPosition="start" icon={<InfoOutlinedIcon />} label={t("details")} />
                            </Tabs>
                        </Card> 
                        </Grid>*/}

                        {activeTab === 0 ? (
                            <>
                                <Grid item xs={12}>
                                    <ReservationDetailsCard
                                        loading={loading}
                                        reservations={groupData?.reservations}
                                        currency={currency}
                                        groupInvoices={groupData?.groupInvoices || []}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReservationsTable
                                        data={
                                            groupData?.reservations?.filter(
                                                (reservation) => reservation?.status !== "CANCELLED"
                                            ) || []
                                        }
                                        removeReservation={(id) => removeReservation(id)}
                                        currency={currency}
                                        changeStatusInBulk={changeStatusInBulk}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InvoicesTable
                                        data={
                                            invoices
                                                ?.filter((invoice) => invoice?.status !== "CANCELLED")
                                                ?.concat(groupData?.groupInvoices || []) || []
                                        }
                                        createGroupInvoice={
                                            groupData?.groupInvoices?.length > 0
                                                ? addInvoiceToGroup
                                                : createGroupInvoice
                                        }
                                        removeInvoiceFromGroup={removeInvoiceFromGroup}
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
                <AddClientToReservationModal
                    onSave={addClient}
                    open={openSearchModal}
                    onClose={() => setOpenSearchModal(false)}
                />
            </Modal>
            {/* <Modal
                titlelabel={t("search_reservation")}
                onClose={() => setOpenModal(false)}
                maxWidth="xs"
                open={openModal}
                yesText={t("attach")}
                onSave={() => attachReservation()}
            >
                <AutoCompleteControl
                    label={t("reservation")}
                    options={options}
                    value={attachId || ""}
                    onChange={(e) => setAttachId(e.target.value)}
                />
            </Modal> */}
        </>
    );
};

export default GroupProfile;
