import { Button, Chip, LinearProgress, Paper, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { toCurrency } from "../../utils";
import { DateTime } from "luxon";
import { DateRangeControl } from "../../components/dates";

const statusColors = {
    ORDERED: "#1FB6FF", //default status
    DELIVERED: "#59C086", //fully paid
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const ExpenseList = () => {
    const [inventories, setInventories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const { t } = useTranslation();
    const columns = [
        {
            id: "name",
            header: t("name"),
            accessorFn: (row) => row?.supplierData?.name || "",
        },
        { accessorKey: "date", header: t("date") },
        {
            accessorKey: "warehouseTo",
            header: t("warehouse"),
            cell: ({ row: { original } }) => {
                return warehouses?.find((w) => w?._id === original?.warehouseTo)?.name || "";
            },
        },
        {
            accessorKey: "status",
            header: t("status"),
            cell: ({ row: { original } }) => {
                const status = !original?.status || original?.status === "UNPAID" ? original?.status : original?.status;
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={t(`${status}`)} />;
            },
        },
        {
            accessorKey: "number",
            header: t("number"),
            accessorFn: (row) => row?.number || "",
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            accessorFn: (row) => row?.totalPrice && toCurrency(row?.totalPrice, row?.currency || ""),
        },
    ];
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [filters, setFilters] = useState({
        startDate: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().toFormat("yyyy-LL-dd"),
    });

    const loadData = async () => {
        const { startDate, endDate } = filters || {};
        if (!startDate) return;
        let end = "end";
        if (!!endDate) end = endDate + `T23:59:59`;
        const response = await fetch({
            operation: "query",
            endpoint: "inventories",
            data: {
                startDate: startDate,
                endDate: end,
            },
            responseData:
                "_id type date dueDate number warehouseTo status totalPrice currency supplier supplierData{name}",
        });
        if (response?.inventories)
            setInventories(response.inventories?.filter((inv) => inv?.type === "purchase_order"));
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "warehouses",
            responseData: "_id name",
        });
        if (response?.warehouses) setWarehouses(response.warehouses);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteInventories" : "deleteInventory",
            data,
        });
        if (response?.deleteInventories === "success" || response?.deleteInventory === "success") {
            setInventories(inventories.filter((i) => !rows.some((row) => row._id === i._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const addRecord = (data) => {
        if (inventories?.some((r) => r._id === data._id)) {
            setInventories(
                inventories?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setInventories([...inventories, data]);
    };
    useEffect(() => {
        loadExtraData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                <Stack direction="row" gap={2} flexWrap="wrap">
                    <DateRangeControl
                        startLabel={t("start")}
                        endLabel={t("end")}
                        values={{ startDate: filters.startDate, endDate: filters.endDate }}
                        onChange={({ startDate, endDate }) => setFilters({ ...filters, startDate, endDate })}
                    />
                    <Button onClick={loadData} size="small" variant="contained">
                        {t("load_records")}
                    </Button>
                </Stack>
            </Paper>
            <Table
                titleLabel={t("purchase_orders")}
                moduleType="pos"
                data={inventories}
                columns={columns}
                onRowClick={(row) => navigate(`/pos/inventory/${row?._id?.split("inventories_")[1]}`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecord }} />
        </div>
    );
};

export default ExpenseList;
