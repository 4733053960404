import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};
const bookingsourceTypeOptions = [
    {
        value: "offline",
        label: "offline",
    },
    {
        value: "ota",
        label: "ota",
    },
    {
        value: "phone",
        label: "phone",
    },
    {
        value: "agency",
        label: "agency",
    },
];

const commissionTypeOptions = [
    {
        value: "percentage",
        label: "percentage",
    },
    {
        value: "perperson",
        label: "perperson",
    },
    {
        value: "night",
        label: "night",
    },
    {
        value: "fixed",
        label: "fixed",
    },
];

const BookingSourceForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordBookingSource } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "bookingsource",
            data: {
                _id: "bookingsources_" + params?.id,
            },
            responseData: "_id name bookingsourceType commission commissionType description",
        });
        if (response?.bookingsource) setValues(response.bookingsource);
    };

    const saveHandler = async () => {
        const { name, bookingsourceType, commission, commissionType, description, _id } = values;
        const data = {
            name,
            bookingsourceType,
            commission: parseFloat(commission),
            commissionType,
            description,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateBookingSource" : "createBookingSource",
            data,
            responseData: "_id name bookingsourceType commission commissionType description",
        });
        if (response?.createBookingSource?._id || response?.updateBookingSource?._id) {
            enqueueSnackbar(t("bookingsource_saved"), { variant: "default" });
            addRecordBookingSource &&
                addRecordBookingSource(response.createBookingSource || response?.updateBookingSource);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("booking_source")}
            maxWidth="sm"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ bookingsources: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl
                        name="bookingsourceType"
                        label={t("source_type")}
                        options={bookingsourceTypeOptions}
                    />
                </Grid>

                <Grid item xs={6}>
                    <InputControl name="commission" label={t("commission")} type="number" />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl name="commissionType" label={t("type")} options={commissionTypeOptions} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="description" label={t("description")} multiline minRows={4} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default BookingSourceForm;
