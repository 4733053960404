const primaryGuestCategory = (roomCapacity = [], guestcategories = []) => {
    return guestcategories
        ?.filter((category) => roomCapacity?.some((c) => c.id === category._id))
        ?.sort((a, b) => {
            const prevGuestCategoryPriorityOrder = a?.priorityOrder || 0;
            const nextGuestCategoryPriorityOrder = b?.priorityOrder || 0;
            return prevGuestCategoryPriorityOrder - nextGuestCategoryPriorityOrder;
        })?.[0];
};

export default primaryGuestCategory;
