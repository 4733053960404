import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { CheckboxControl, Form, InputControl, SwitchControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { rights } from "../../utils";

const rightsAsArray = Object.entries(rights);

const RoleForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { addRecordRole } = useOutletContext() || {};

    const sectionRightsRefs = useRef([]);

    const renderRights = useMemo(() => {
        return rightsAsArray.map(([sectionId, data], i) => {
            return (
                <Grid item xs={12} sm={6} md={3}>
                    <SectionRights
                        ref={(el) => (sectionRightsRefs.current[i] = el)}
                        sectionId={sectionId}
                        label={data.label}
                        rights={data.rights}
                        t={t}
                    />
                </Grid>
            );
        });
    }, []);

    const applySavedPermissions = (permissionValues) => {
        rightsAsArray.forEach(([sectionId], index) => {
            sectionRightsRefs.current[index].setPermissions(permissionValues[sectionId]);
        });
    };

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "role",
            data: { _id: "roles_" + params?.id },
            responseData: "_id name homeRoute isWaiter description permissions hasShift",
        });

        if (response?.role) {
            const { permissions, ...rest } = response.role;
            setValues(rest);

            if (!!permissions) applySavedPermissions(JSON.parse(permissions));
        }
    };

    const saveHandler = async () => {
        const permissions = rightsAsArray.reduce((acc, el, index) => {
            const { sectionId, sectionPermissions } = sectionRightsRefs.current[index].getPermissions();
            acc[sectionId] = sectionPermissions;
            return acc;
        }, {});

        const { name, homeRoute, isWaiter, description, hasShift, _id } = values;
        const data = { name, homeRoute, isWaiter, hasShift, description, permissions: JSON.stringify(permissions) };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateRole" : "createRole",
            data,
            responseData: "_id name homeRoute isWaiter description permissions hasShift",
        });
        if (response?.createRole?._id || response?.updateRole?._id) {
            enqueueSnackbar(t("role_saved"), { variant: "default" });
            addRecordRole && addRecordRole(response.createRole || response?.updateRole);
            navigate(-1);
        }
    };

    return (
        <div>
            <Modal
                open
                fullScreen
                loading={loading}
                titlelabel={t("roles_form")}
                onSave={saveHandler}
                onClose={() => navigate(-1)}
                permission={{ roles: params?.id === "create" ? { create: true } : { edit: true } }}
            >
                <Form values={values} onValuesChange={setValues}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <SwitchControl name="isWaiter" label={t("waiter")} />
                        </Grid>
                        <Grid item xs={6}>
                            <SwitchControl name="hasShift" label={t("has_shift")} />
                        </Grid>
                        <Grid item xs={12} sm={6} spacing={2}>
                            <Grid item xs={12}>
                                <InputControl name="name" label={t("name")} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControl name="homeRoute" label={t("home_route")} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputControl name="description" label={t("description")} multiline minRows={3} />
                        </Grid>
                    </Grid>
                </Form>
                <Grid container spacing={2} style={{ marginTop: "30px" }}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("role_permissions")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {renderRights}
                </Grid>
            </Modal>
        </div>
    );
};

const SectionRights = forwardRef(({ sectionId, rights, label, t }, ref) => {
    const [values, setValues] = useState({});

    useImperativeHandle(ref, () => ({
        getPermissions: () => {
            return {
                sectionId,
                sectionPermissions: values,
            };
        },
        setPermissions: (permissionValues = {}) => {
            setValues(permissionValues);
        },
    }));

    const hasAllRights = () => {
        return rights.every((right) => !!values[right]);
    };

    const checkAll = () => {
        setValues(
            rights.reduce((acc, r) => {
                acc[r] = true;
                return acc;
            }, {})
        );
    };

    return (
        <Accordion expanded elevation={0}>
            <AccordionSummary>
                <Typography>
                    <SwitchControl
                        value={hasAllRights()}
                        onChange={(e) => {
                            if (e.target.checked) {
                                checkAll();
                                return;
                            }
                            setValues({});
                        }}
                        label={t(label)}
                    />
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: "30px" }}>
                <Form values={values} onValuesChange={setValues}>
                    {rights.map((right) => {
                        return (
                            <Typography>
                                <CheckboxControl name={right} label={t(right)} />
                            </Typography>
                        );
                    })}
                </Form>
            </AccordionDetails>
        </Accordion>
    );
});

export default RoleForm;
