import React, { useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { Button, IconButton, Link, ListItemText, Popover, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChainReservationsMenu = ({ reservations }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <>
            <Button startIcon={<LinkIcon />} onClick={handleClick}>
                {t("reservations")}
            </Button>
            <Popover
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                elevation={3}
                id={"guests-menu"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <Stack padding={2} gap={2}>
                    {reservations?.map((reservation) => (
                        <Link href={`/reservations/${reservation?._id?.split("reservations_")?.[1]}`} underline="hover">
                            <ListItemText
                                primary={`${reservation.checkin} -- ${reservation.checkout}`}
                                secondary={`${t("rooms")}: ${reservation?.rooms
                                    ?.map((r) => r?.roomData?.name)
                                    ?.join(" , ")}`}
                            />
                        </Link>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

export default ChainReservationsMenu;
