import countryCodes from "../../../../assests/countryCodes";

const countryCodeVariants = {
    ALPHA3: "alpha3",
    ALPHA2: "alpha2",
};

const generateCountryCode = (country, variant) => {
    if (!country) {
        return null;
    }
    if (!variant) return null;
    if (!countryCodeVariants[variant]) {
        throw new Error("incorrect_country_code_variant");
    }
    const countryCodeDetails = countryCodes.find((c) => {
        if ([c.name.toUpperCase(), c.alpha3.toUpperCase(), c.alpha2.toUpperCase()].includes(country.toUpperCase())) {
            return true;
        }
        return false;
    });
    if (!countryCodeDetails) {
        return null;
    }
    const code = countryCodeDetails[countryCodeVariants[variant]];
    if (!!code) {
        return code.toUpperCase();
    }
    return null;
};

export default generateCountryCode;
