import { Button, Grid, LinearProgress } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import CachedIcon from "@mui/icons-material/Cached";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useApi, useProductCategories } from "../../../components/hooks";
import { Table } from "../../../components/table";
import { DateTime } from "luxon";
import { ProtectedAction } from "../../../utils";

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const { t } = useTranslation();

    const [columns] = useState([
        { accessorKey: "name", header: t("product_name") },
        {
            id: "category",
            header: t("category"),
            accessorFn: (row) => row?.category?.name || null,
        },
        { accessorKey: "price", header: t("price") },
        { accessorKey: "measureUnit", header: t("measure_unit") },
        {
            accessorKey: "productType",
            header: t("type"),
            accessorFn: (row) => (row?.productType ? t(row.productType) : ""),
        },
    ]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { reloadProductCategories } = useProductCategories();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "products",
            responseData: `_id name categoryId parentProduct modifiers isVariant price posplaces cost purchaseUnit measureUnit deductionFactor category{name} productType
                pospoints{ 
                    posPointId 
                    price 
                }  
                taxData{
                    taxId
                    isIncluded
                    tax{rate taxCategory}
                }
                warehouseIds
                barcode
            `,
        });
        if (response?.products) setProducts(response.products);
    };
    const addRecord = (data) => {
        if (products?.some((r) => r._id === data._id)) {
            setProducts(
                products?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setProducts([...products, data]);
    };

    const updateCacheProducts = () => {
        const productsData = {
            timestamp: DateTime.now().toISO(),
            products,
        };
        localStorage.setItem("cacheProducts", JSON.stringify(productsData));
        reloadProductCategories();
        enqueueSnackbar(t("products_updated"), { variant: "info" });
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteProducts" : "deleteProduct",
            data,
        });
        if (response?.deleteProducts === "success" || response?.deleteProduct === "success") {
            setProducts(products.filter((product) => !rows.some((row) => row._id === product._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    return (
        <div>
            <Grid container style={{ margin: "20px 0" }}>
                <ProtectedAction permission={{ services: { list: true } }}>
                    <Button
                        component={Link}
                        to="/backoffice/services"
                        sx={{ borderRadius: "25px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("services")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ productcategories: { list: true } }}>
                    <Button
                        component={Link}
                        to="/pos/productcategories"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("product_categories")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ productmaincategories: { list: true } }}>
                    <Button
                        component={Link}
                        to="/pos/productmaincategories"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("main_categories")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ warehouses: { list: true } }}>
                    <Button
                        component={Link}
                        to="/pos/warehouses"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("warehouses")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ products: { create: true } }}>
                    <Button
                        component={Link}
                        to="/pos/productimports"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                        startIcon={<UploadIcon />}
                    >
                        {t("products_import")}
                    </Button>
                </ProtectedAction>
                <Button
                    onClick={updateCacheProducts}
                    sx={{ borderRadius: "25px", marginLeft: "auto" }}
                    variant="outlined"
                    startIcon={<CachedIcon />}
                >
                    {t("update_products")}
                </Button>
            </Grid>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("product_form")}
                moduleType="pos"
                columns={columns}
                data={products}
                onRowClick={(row) => navigate(`${row?._id?.split("products_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecord }} />
        </div>
    );
};

export default ProductList;
