import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const BusinessList = () => {
    const [businesses, setBusinesses] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "businessName", header: t("business_name") },
        { accessorKey: "nipt", header: t("nipt") },
        { accessorKey: "businessUnitCode", header: t("business_unit_code") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "businesses",
            responseData: "_id name businessName nipt businessUnitCode",
        });
        if (response?.businesses) setBusinesses(response.businesses);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteBusinesses" : "deleteBusiness",
            data,
        });
        if (response?.deleteBusinesses === "success" || response?.deleteBusiness === "success") {
            setBusinesses(businesses.filter((business) => !rows.some((row) => row._id === business._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("business")}
                moduleType="backoffice"
                data={businesses}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("fiscalbusiness_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet />
        </div>
    );
};

export default BusinessList;
