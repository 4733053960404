import {
    CardHeader,
    Card,
    Grid,
    CardContent,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Divider,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { ProtectedAction } from "../../utils";
import Masonry from "@mui/lab/Masonry";

const ReportsDashboard = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Card sx={{ marginBottom: 2 }}>
                <CardHeader title={t("reports")} titleTypographyProps={{ variant: "h6" }} />
            </Card>
            <Masonry columns={{ xs: 1, sm: 2 }} spacing={3} style={{ width: "auto" }} sequential>
                <Grid>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <img
                                src="https://res.cloudinary.com/strowberry-code/image/upload/v1697653379/hb/assets/icons8-hotel-50_ypiquj.png"
                                alt="hotel"
                                style={{ padding: 10, height: 60 }}
                            />
                            <CardHeader title={t("accommodation")} />
                        </div>
                        <Divider />
                        <CardContent>
                            <List>
                                <ProtectedAction permission={{ generalmanagerreport: { view: true } }}>
                                    <ReportItem
                                        label={t("general_manager_report")}
                                        to={`generalmanagerreport?date=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ forecastreport: { view: true } }}>
                                    <ReportItem
                                        label={t("forecast_report")}
                                        to={`forecastreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ roomtypesalesreport: { view: true } }}>
                                    <ReportItem
                                        label={t("roomtype_sales_report")}
                                        to={`roomtypesalesreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ mealsreport: { view: true } }}>
                                    <ReportItem
                                        label={t("meals_report")}
                                        to={`mealsreport?date=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ paymentsreport: { view: true } }}>
                                    <ReportItem
                                        label={t("payments_report")}
                                        to={`paymentsreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ usersalesreport: { view: true } }}>
                                    <ReportItem
                                        label={t("user_sales_report")}
                                        to={`usersalesreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>

                                <ProtectedAction permission={{ day_overview: { view: true } }}>
                                    <ReportItem label={t("day_overview")} to={`/arrivals`} />
                                </ProtectedAction>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <img
                                src="https://res.cloudinary.com/strowberry-code/image/upload/v1697653669/hb/assets/icons8-pos-60_ya0urx.png"
                                alt="pos"
                                style={{ padding: 10, height: 60 }}
                            />
                            <CardHeader title={t("pos")} />
                        </div>
                        <Divider />
                        <CardContent>
                            <List>
                                <ProtectedAction permission={{ productsreport: { view: true } }}>
                                    <ReportItem
                                        label={t("products_report")}
                                        to={`productsreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ usersalesreport: { view: true } }}>
                                    <ReportItem
                                        label={t("user_sales_report")}
                                        to={`usersalesreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ inventoryreport: { view: true } }}>
                                    <ReportItem
                                        label={t("inventory_report")}
                                        to={`inventoryreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ productsinventoryreport: { view: true } }}>
                                    <ReportItem
                                        label={t("products_inventory_report_now")}
                                        to={`productsinventoryreport`}
                                    />
                                </ProtectedAction>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <img
                                src="https://res.cloudinary.com/strowberry-code/image/upload/v1697663042/hb/assets/icons8-person-50_vgzlla.png"
                                alt="guests"
                                style={{ padding: 10, height: 60 }}
                            />
                            <CardHeader title={t("guests")} />
                        </div>
                        <Divider />
                        <CardContent>
                            <List>
                                <ProtectedAction permission={{ mealsreport: { view: true } }}>
                                    <ReportItem
                                        label={t("meals_report")}
                                        to={`mealsreport?date=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ guestsreport: { view: true } }}>
                                    <ReportItem
                                        label={t("guests_report")}
                                        to={`guestsreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}&type=dateCreated`}
                                    />
                                </ProtectedAction>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <img
                                src="https://res.cloudinary.com/strowberry-code/image/upload/v1697662909/hb/assets/icons8-accounting-50_fsuffh.png"
                                alt="finance"
                                style={{ padding: 10, height: 60 }}
                            />
                            <CardHeader title={t("finance")} />
                        </div>
                        <Divider />
                        <CardContent>
                            <List>
                                <ProtectedAction permission={{ paymentsreport: { view: true } }}>
                                    <ReportItem
                                        label={t("payments_report")}
                                        to={`paymentsreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ usersalesreport: { view: true } }}>
                                    <ReportItem
                                        label={t("user_sales_report")}
                                        to={`usersalesreport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}`}
                                    />
                                </ProtectedAction>
                                <ProtectedAction permission={{ unpaidbalancereport: { view: true } }}>
                                    <ReportItem
                                        label={t("unpaid_balance_report")}
                                        to={`unpaidbalancereport?startDate=${DateTime.now()?.toFormat(
                                            "yyyy-LL-dd"
                                        )}&endDate=${DateTime.now()?.toFormat("yyyy-LL-dd")}&type=dateCreated`}
                                    />
                                </ProtectedAction>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Masonry>
        </div>
    );
};

export default ReportsDashboard;

const ReportItem = ({ label, to }) => {
    const navigate = useNavigate();
    return (
        <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(to)}>
                <ListItemIcon>
                    <TimelineIcon />
                </ListItemIcon>
                <ListItemText primary={label} />
            </ListItemButton>
        </ListItem>
    );
};
