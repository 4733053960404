export const uploadToAzure = async (files, api) => {
    if (!files) return undefined;
    if (!Array.isArray(files)) {
        return uploadSingleFileToAzure({ file: files, api });
    }
    const fileUrls = [];
    for (const file of files) {
        const fileUrl = uploadSingleFileToAzure({ file: file, api });
        if (!!fileUrl) fileUrls.push(fileUrl);
    }
    return fileUrls;
};

const uploadSingleFileToAzure = async ({ api, file }) => {
    try {
        const urlResponse = await api({
            operation: "mutation",
            endpoint: "generateAzurePreSignedUrl",
            data: {
                fileName: file.name,
                fileType: file.type,
            },
            responseData: "presignedUrl accountName containerName",
        });
        const { presignedUrl, accountName, containerName } = urlResponse?.generateAzurePreSignedUrl || {};
        if (!presignedUrl || !accountName || !containerName) return undefined;
        const azure_response = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
                "Content-Type": file.type,
                "x-ms-blob-type": "BlockBlob",
            },
            body: file,
        });
        if (azure_response.ok) {
            return `https://${accountName}.blob.core.windows.net/${containerName}/${file.name}`;
        } else return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
};
