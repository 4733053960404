import { Button, Card, CardActions, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputControl } from "../form";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";

const NotesView = ({ notes = [], addNote, removeNote, disableEdit = false }) => {
    const { t } = useTranslation();
    const [noteText, setNoteText] = useState();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            {notes.map((note) => (
                <Card style={{ marginTop: "20px", backgroundColor: "#faf9f9" }}>
                    <CardHeader
                        title={
                            !!note.timestamp ? DateTime.fromISO(note.timestamp).toFormat("yyyy LLL dd HH:mm:ss") : ""
                        }
                        avatar={<NoteOutlinedIcon />}
                        action={
                            <IconButton
                                onClick={() => {
                                    removeNote && removeNote(note.timestamp);
                                    enqueueSnackbar("note_removed");
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Typography>{note.note || note.text}</Typography>
                    </CardContent>
                </Card>
            ))}
            <Card sx={{ marginTop: 1 }}>
                <CardHeader titleTypographyProps={{ variant: "h5" }} title={t("notes")} avatar={<NoteIcon />} />
                <CardContent>
                    <InputControl
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        label={t("add_note")}
                        multiline
                        minRows={4}
                    />
                </CardContent>
                <CardActions>
                    <Button
                        onClick={() => {
                            if (!noteText) {
                                enqueueSnackbar(t("please_type_a_note"), { variant: "warning" });
                                return;
                            } else {
                                addNote && addNote(noteText);
                                setNoteText("");
                                enqueueSnackbar(t("note_added"), { variant: "default" });
                            }
                        }}
                        variant="outlined"
                        disabled={disableEdit}
                    >
                        {t("add_note")}
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default NotesView;
