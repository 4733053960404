import { Grid, ListItem, ListItemIcon, ListItemText, List, Stack, Divider, Typography } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import TimelineIcon from "@mui/icons-material/Timeline";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardCard, DashboardSalesCard } from "./components";
import { SelectControl } from "../../components/form";
import { BarChartData, PieChartData } from "../../components/charts";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";

const generate24HourInterval = [...Array(24).keys()].reduce((acc, h) => {
    acc[h] = { name: h < 10 ? "0" + h : h.toString(), value: 0 };
    return acc;
}, {});

const PosDashboard = () => {
    const { t } = useTranslation();
    const periodOptions = [
        { value: "today", label: t("today") },
        { value: "yesterday", label: t("yesterday") },
    ];

    const [ordersData, setOrdersData] = useState({
        todayOrders: [],
        yesterdayOrders: [],
    });

    const { loading, fetch } = useApi();
    useEffect(() => {
        loadOrders();
    }, []);

    const loadOrders = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "orders",
            data: {
                startDate: DateTime.now().minus({ days: 1 }).toFormat("yyyy-LL-dd") + "T01:00:00.000Z",
                endDate: "end",
            },
            responseData:
                "_id products{productId quantity productData{name}} createdAt status totalPrice pospointData{_id name} createdBy",
        });

        if (response?.orders) {
            setOrdersData({
                todayOrders: response.orders.filter(
                    (order) =>
                        DateTime.now().hasSame(DateTime.fromISO(order.createdAt), "day") && order.status !== "CANCELLED"
                ),

                yesterdayOrders: response.orders.filter(
                    (order) =>
                        DateTime.now().minus({ days: 1 }).hasSame(DateTime.fromISO(order.createdAt), "day") &&
                        order.status !== "CANCELLED"
                ),
            });
        }
    };
    console.log(ordersData?.todayOrders);

    const ordersCalculations = useMemo(() => {
        const calculations = {
            grossSalesToday: 0,
            totalOrdersToday: 0,
            grossSalesYesterday: 0,
            totalOrdersYesterday: 0,
            posToday: [],
            posYesterday: [],
            userToday: [],
            userYesterday: [],
            productsToday: [],
            productsYesterday: [],
            hourlyCalculations: [],
        };

        const posCalculations = {
            today: {},
            yesterday: {},
        };

        const userCalculations = {
            today: {},
            yesterday: {},
        };

        const productCalculations = {
            today: {},
            yesterday: {},
        };

        const hourlyCalculations = generate24HourInterval;

        ordersData.todayOrders.forEach((order) => {
            calculations.grossSalesToday += order.totalPrice;
            calculations.totalOrdersToday += 1;

            const { ordersNumber = 0, amount = 0 } = userCalculations.today?.[order.createdBy] || {};
            userCalculations.today[order.createdBy] = {
                user: order?.createdBy?.split("users_")[1],
                ordersNumber: ordersNumber + 1,
                amount: amount + order.totalPrice,
            };

            if (order.pospointData?._id) {
                const { ordersNumber = 0, amount = 0 } = posCalculations.today?.[order.pospointData?._id] || {};
                posCalculations.today[order.pospointData?._id] = {
                    pos: order.pospointData.name,
                    ordersNumber: ordersNumber + 1,
                    amount: amount + order.totalPrice,
                };
            }

            order.products.forEach((product) => {
                const { value = 0 } = productCalculations.today[product.productId] || {};
                productCalculations.today[product.productId] = {
                    name: product.productData?.name,
                    value: value + product.quantity,
                };
            });
            if (hourlyCalculations[DateTime.fromISO(order.createdAt).toFormat("HH").toString()]) {
                hourlyCalculations[DateTime.fromISO(order.createdAt).toFormat("HH").toString()].value +=
                    order.totalPrice;
            }
        });
        ordersData.yesterdayOrders.forEach((order) => {
            calculations.grossSalesYesterday += order.totalPrice;
            calculations.totalOrdersYesterday += 1;

            const { ordersNumber = 0, amount = 0 } = userCalculations.yesterday?.[order.createdBy] || {};
            userCalculations.yesterday[order.createdBy] = {
                user: order?.createdBy?.split("users_")[1],
                ordersNumber: ordersNumber + 1,
                amount: amount + order.totalPrice,
            };

            if (order.pospointData?._id) {
                const { ordersNumber = 0, amount = 0 } = posCalculations.yesterday?.[order.pospointData?._id] || {};
                posCalculations.yesterday[order.pospointData?._id] = {
                    pos: order.pospointData.name,
                    ordersNumber: ordersNumber + 1,
                    amount: amount + order.totalPrice,
                };
            }

            order.products.forEach((product) => {
                const { value = 0 } = productCalculations.yesterday[product.productId] || {};
                productCalculations.yesterday[product.productId] = {
                    name: product.productData?.name,
                    value: value + product.quantity,
                };
            });
        });

        calculations.posToday = Object.values(posCalculations.today);
        calculations.posYesterday = Object.values(posCalculations.yesterday);
        calculations.userToday = Object.values(userCalculations.today);
        calculations.userYesterday = Object.values(userCalculations.yesterday);
        calculations.hourlyCalculations = Object.values(hourlyCalculations);
        calculations.productsToday = Object.values(productCalculations.today);
        calculations.productsYesterday = Object.values(productCalculations.yesterday);

        if (calculations.productsToday?.length > 5) {
            calculations.productsToday.sort((a, b) => b.value - a.value);
            const top5Products = calculations.productsToday?.slice(0, 5);
            const otherProducts = calculations.productsToday?.slice(5)?.reduce(
                (acc, product) => {
                    acc.value += product.value;
                    return acc;
                },
                { name: t("other"), value: 0 }
            );
            top5Products.push(otherProducts);
            calculations.productsToday = top5Products;
        }
        return calculations;
    }, [ordersData]);

    return (
        <div>
            <Grid container spacing={2} alignItems="stretch">
                {/* <Grid item xs={12} sm={6}>
                    <DashboardSalesCard
                        variant="gross"
                        todayTotal={ordersCalculations?.grossSalesToday?.toFixed(2)}
                        yesterdayTotal={ordersCalculations?.grossSalesYesterday?.toFixed(2)}
                        trendingUp={false}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DashboardSalesCard
                        variant="total"
                        todayTotal={ordersCalculations?.totalOrdersToday + " " + t("orders")}
                        yesterdayTotal={ordersCalculations?.totalOrdersYesterday + " " + t("orders")}
                        trendingUp
                        loading={loading}
                    />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard
                        title={t("top_products")}
                        titleIcon={<AttachMoneyIcon />}
                        /* action={
                            <div style={{ width: "150px" }}>
                                <SelectControl options={periodOptions} label={t("period")} />
                            </div>
                        } */
                        loading={loading}
                        skeletonVariant="circular"
                    >
                        <PieChartData key="value" data={ordersCalculations.productsToday} />
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard
                        title={t("sales_by_user")}
                        titleIcon={<AttachMoneyIcon />}
                        /* action={
                            <div style={{ width: "150px" }}>
                                <SelectControl options={periodOptions} label={t("period")} />
                            </div>
                        } */
                        loading={loading}
                    >
                        <List>
                            {ordersCalculations.userToday.map((user) => (
                                <ListItem divider={<Divider />}>
                                    <ListItemIcon>
                                        <PersonIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Stack direction="row">
                                            <Grid item xs={4}>
                                                {user.user}
                                            </Grid>
                                            <Grid item xs={4} textAlign="center">
                                                {user.ordersNumber}
                                            </Grid>
                                            <Grid item xs={4} textAlign="right">
                                                {user.amount?.toFixed(2)}
                                            </Grid>
                                        </Stack>
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard
                        title={t("sales_by_location")}
                        titleIcon={<AttachMoneyIcon />}
                        /* action={
                            <div style={{ width: "150px" }}>
                                <SelectControl options={periodOptions} label={t("period")} />
                            </div>
                        }*/
                        loading={loading}
                    >
                        <List>
                            {ordersCalculations.posToday.map((pos) => (
                                <ListItem divider={<Divider />}>
                                    <ListItemIcon>
                                        <PointOfSaleIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Stack direction="row">
                                            <Grid item xs={4}>
                                                {pos.pos}
                                            </Grid>
                                            <Grid item xs={4} textAlign="center">
                                                {pos.ordersNumber}
                                            </Grid>
                                            <Grid item xs={4} textAlign="right">
                                                {pos.amount?.toFixed(2)}
                                            </Grid>
                                        </Stack>
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={8}>
                    <DashboardCard loading={loading} title={t("hourly_sales")} titleIcon={<TimelineIcon />}>
                        <BarChartData key="value" data={ordersCalculations.hourlyCalculations} withBrush />
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard
                        title={t("day_stats")}
                        titleIcon={<AttachMoneyIcon />}
                        /* action={
                            <div style={{ width: "150px" }}>
                                <SelectControl options={periodOptions} label={t("period")} />
                            </div>
                        } */
                        loading={loading}
                    >
                        {/* <Typography variant="subtitle1">
                            {t("negative_inventory")}: - {t("products")}
                        </Typography>
                        <Typography variant="subtitle1">{t("top_revenue")}: --</Typography> */}
                    </DashboardCard>
                </Grid>
            </Grid>
        </div>
    );
};

export default PosDashboard;
