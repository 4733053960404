import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React from "react";

const TimeControl = ({
    label,
    value = null,
    onChange,
    size = "small",
    margin = "dense",
    fullWidth = true,
    variant = "outlined",
    inputFormat = "HH:mm",
    error,
    helperText,
}) => {
    const onChangeHandler = (newValue) => {
        onChange && onChange({ target: { value: !!newValue ? newValue.toFormat(inputFormat) : null } });
    };
    return (
        <TimePicker
            label={label}
            value={!!value ? DateTime.fromFormat(value, inputFormat) : null}
            onChange={onChangeHandler}
            ampm={false}
            OpenPickerButtonProps={{ color: "primary" }}
            renderInput={(params) => (
                <TextField
                    error={error}
                    helperText={helperText}
                    variant={variant}
                    fullWidth={fullWidth}
                    margin={margin}
                    size={size}
                    {...params}
                />
            )}
        />
    );
};

export default TimeControl;
