import {
    Button,
    ButtonBase,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    LinearProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MergeIcon from "@mui/icons-material/Merge";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PersonIcon from "@mui/icons-material/Person";
import ClientsSearch from "../../components/common/ClientsSearch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CheckboxControl, InputControl, SelectControl } from "../../components/form";
import { Table } from "../../components/table";
import { toCurrency } from "../../utils";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { generateMergedInvoice } from "./components/generateMergeInvoice";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid
    TRANSFERRED: "#FFC82C", //transferred to group
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const INVOICE_RESPONSE_DATA = `
    _id 
    date  
    dueDate
    number 
    invoiceCurrency 
    customer
    reservationIds
    clientsData{firstName lastName} 
    fiscalData{nivf IICRef} 
    totalPrice 
    status 
    payments{amount paymentMethod userName timestamp note}
    paymentStatus
    tableData{itemId price description quantity unit taxData{taxId isIncluded}}
`;

const InvoicesMerge = () => {
    const { t } = useTranslation();
    const [invoicesSearch, setInvoicesSearch] = useState({
        searchByClientId: null,
        searchByInvoiceNumber: null,
        invoicesResult: [],
    });

    const [extraData, setExtraData] = useState({
        currencies: [],
        taxes: [],
    });

    const [mergedInvoiceState, setMergeInvoiceState] = useState({
        selectedInvoices: [],
        selectedInvoiceCurrency: null,
        selectedCustomer: null,
    });

    const [disableConfirm, setDisableConfirm] = useState(false);
    const tableColumns = [
        {
            id: "name",
            header: t("name"),
            cell: ({ row: { original } }) => {
                const { firstName = "", lastName = "" } = original?.clientsData || {};
                return (firstName || "") + " " + (lastName || "");
            },
        },
        {
            accessorKey: "number",
            header: t("number"),
            cell: ({ row: { original } }) => {
                return "#" + original.number;
            },
        },
        {
            accessorKey: "totalPrice",
            header: t("amount"),
            cell: ({ row: { original } }) => toCurrency(original?.totalPrice || 0, original?.invoiceCurrency),
        },
        { accessorKey: "date", header: t("date") },
        {
            id: "status",
            header: "",
            cell: ({ row: { original } }) => {
                let status = original?.status || "DRAFT";
                if (original?.paymentStatus === "PAID") {
                    status = "PAID";
                }
                if (["CANCELLED", "TRANSFERRED"].includes(original?.status)) {
                    status = original?.status;
                }
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={status} />;
            },
        },
    ];

    const invoiceTableColumns = [
        {
            id: "select",
            header: "",
            cell: ({ row: { original } }) => (
                <CheckboxControl
                    color="primary"
                    value={mergedInvoiceState?.selectedInvoices?.includes(original._id)}
                    onChange={(e) => {
                        const invoices = e.target.checked
                            ? [...mergedInvoiceState.selectedInvoices, original._id]
                            : mergedInvoiceState.selectedInvoices?.filter((_id) => _id !== original._id);

                        let selectedInvoiceCurrency = mergedInvoiceState.selectedInvoiceCurrency;
                        let selectedCustomer = mergedInvoiceState.selectedCustomer;
                        if (invoices?.length === 0) {
                            selectedInvoiceCurrency = null;
                            selectedCustomer = null;
                        }
                        if (invoices?.length === 1) {
                            const { invoiceCurrency, clientsData, customer } =
                                invoicesSearch?.invoicesResult?.find((inv) => inv._id === invoices?.[0]) || {};
                            selectedInvoiceCurrency = invoiceCurrency;
                            selectedCustomer = { ...clientsData, _id: customer };
                        }
                        setMergeInvoiceState({
                            ...mergedInvoiceState,
                            selectedInvoices: invoices,
                            selectedInvoiceCurrency,
                            selectedCustomer,
                        });
                    }}
                    disabled={
                        original?.status === "TRANSFERRED" ||
                        original?.status === "CANCELLED" ||
                        original?.fiscalData?.IICRef
                    }
                />
            ),
        },
    ].concat(tableColumns);

    const selectedInvoicesTableColumns = [
        {
            id: "select",
            header: "",
            cell: ({ row: { original } }) => (
                <ButtonBase
                    onClick={() => {
                        setMergeInvoiceState({
                            ...mergedInvoiceState,
                            selectedInvoices: mergedInvoiceState.selectedInvoices?.filter(
                                (_id) => _id !== original._id
                            ),
                        });
                    }}
                >
                    <RemoveCircleIcon color="error" />
                </ButtonBase>
            ),
        },
    ].concat(tableColumns);

    const { loading, fetch } = useApi();
    const saveApi = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const location = useLocation();
    const { reservationId, clientId } = location?.state || {};
    useEffect(() => {
        const { invoices } = location?.state || {};
        if (invoices && invoices?.length > 0) {
            setInvoicesSearch({ ...invoicesSearch, invoicesResult: invoices });

            const { invoiceCurrency, clientsData, customer } = invoices?.[0] || {};
            setMergeInvoiceState({
                selectedCustomer: { ...(clientsData || {}), _id: customer },
                selectedInvoices: invoices?.map((inv) => inv._id),
                selectedInvoiceCurrency: invoiceCurrency,
            });
        }
    }, [location.state]);

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "currencies", responseData: "_id active currency currencyname rate" },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
            ],
        });
        if (response) {
            setExtraData({ currencies: response?.currencies, taxes: response?.taxes });
        }
    };

    useEffect(() => {
        loadExtraData();
    }, []);

    const loadInvoices = async () => {
        let queryData;
        if (!!invoicesSearch.searchByClientId) {
            queryData = {
                operation: "query",
                endpoint: "invoicesByClientId",
                data: { clientId: invoicesSearch.searchByClientId },
                responseData: INVOICE_RESPONSE_DATA,
            };
        }
        if (!!invoicesSearch.searchByInvoiceNumber) {
            const number = parseFloat(invoicesSearch.searchByInvoiceNumber);
            if (isNaN(number)) {
                enqueueSnackbar(t("invalid_invoice_number"), { variant: "error" });
                return;
            }
            queryData = {
                operation: "query",
                endpoint: "invoicesByNumber",
                data: { number },
                responseData: INVOICE_RESPONSE_DATA,
            };
        }
        if (!queryData) return;
        const response = await fetch(queryData);
        const invoices =
            queryData?.endpoint === "invoicesByClientId"
                ? response?.invoicesByClientId
                : response?.invoicesByNumber || [];
        if (invoices?.length === 0) {
            enqueueSnackbar(t("no_invoices_found"), { variant: "warning" });
            return;
        }
        // setMergeInvoiceState({ selectedInvoices: [], selectedInvoiceCurrency: null, selectedCustomer: null });
        setInvoicesSearch({
            ...invoicesSearch,
            invoicesResult: invoicesSearch.invoicesResult
                ?.filter((inv) => mergedInvoiceState.selectedInvoices?.includes(inv._id))
                ?.concat(invoices?.filter((inv) => !mergedInvoiceState.selectedInvoices?.includes(inv._id)) || []),
        });
    };

    const selectedInvoicesData = useMemo(() => {
        return (
            mergedInvoiceState.selectedInvoices?.map((invoiceId) => {
                return invoicesSearch.invoicesResult?.find((invoice) => invoice._id === invoiceId);
            }) || []
        );
    }, [mergedInvoiceState.selectedInvoices, invoicesSearch.invoicesResult]);

    const currenciesOptions = useMemo(() => {
        return (
            extraData?.currencies?.map((currency) => {
                return { value: currency.currency, label: currency.currencyname };
            }) || []
        );
    }, [extraData?.currencies]);

    const onConfirm = async () => {
        const newInvoice = generateMergedInvoice({
            invoices: selectedInvoicesData,
            currencies: extraData.currencies,
            taxes: extraData.taxes,
            invoiceCurrency: mergedInvoiceState.selectedInvoiceCurrency,
            customerId: mergedInvoiceState.selectedCustomer?._id,
        });
        const response = await saveApi.fetch({
            operation: "mutation",
            multipleEndpoints: [
                {
                    endpoint: "createInvoice",
                    data: newInvoice,
                    responseData: `_id`,
                },
                {
                    endpoint: "updateInvoicesStatus",
                    data: { ids: selectedInvoicesData?.map((invoice) => invoice._id), status: "TRANSFERRED" },
                },
            ],
        });
        if (response?.createInvoice?._id) {
            enqueueSnackbar(t("invoices_merged"), { variant: "success" });
            setMergeInvoiceState({
                selectedInvoices: [],
                selectedInvoiceCurrency: null,
                selectedCustomer: null,
            });
            setInvoicesSearch({ searchByClientId: null, searchByInvoiceNumber: null, invoicesResult: [] });
            setDisableConfirm(false);
        }
    };
    console.log(mergedInvoiceState.selectedInvoiceCurrency);
    return (
        <div>
            {!!reservationId ? (
                <Button startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
                    {t("back_to_reservation")}
                </Button>
            ) : !!clientId ? (
                <Button startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
                    {t("back_to_client")}
                </Button>
            ) : null}
            <Card sx={{ marginTop: 2 }}>
                <CardHeader
                    avatar={<MergeIcon style={{ transform: "rotate(90deg)" }} />}
                    title={t("merge_invoices")}
                    titleTypographyProps={{ variant: "h5" }}
                />
                <CardContent>
                    <Stack direction="row" gap={2} alignItems="center">
                        <ClientsSearch
                            inputProps={{ style: { maxWidth: "300px" } }}
                            onClientSelect={(clientData) => {
                                setInvoicesSearch({
                                    ...invoicesSearch,
                                    searchByClientId: clientData._id,
                                    searchByInvoiceNumber: null,
                                });
                            }}
                        />
                        <Typography variant="body1" textTransform="uppercase">
                            {t("or")}
                        </Typography>
                        <InputControl
                            value={invoicesSearch.searchByInvoiceNumber || ""}
                            onChange={(e) =>
                                setInvoicesSearch({ ...invoicesSearch, searchByInvoiceNumber: e.target.value })
                            }
                            fullWidth={false}
                            label={t("invoice_number")}
                        />
                        <Button
                            onClick={() => {
                                loadInvoices();
                            }}
                            style={{ marginTop: "2px" }}
                            variant="contained"
                            endIcon={<SearchIcon />}
                        >
                            {t("search_invoices")}
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
            {loading && <LinearProgress color="primary" />}
            <Paper elevation={0} sx={{ padding: 2, marginTop: 2 }}>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item xs={12} md={6}>
                        <Typography textTransform="capitalize" variant="h6">
                            {t("search_result")}
                        </Typography>
                        <Table
                            data={invoicesSearch?.invoicesResult}
                            columns={invoiceTableColumns}
                            disableHeader
                            disableSelection
                            disableDelete
                            disableFooter
                            disableEmptyTableInfo
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography textTransform="capitalize" variant="h6">
                            {t("selected_invoices")}
                        </Typography>
                        <Table
                            data={selectedInvoicesData}
                            columns={selectedInvoicesTableColumns}
                            disableHeader
                            disableSelection
                            disableDelete
                            disableFooter
                            disableEmptyTableInfo
                        />
                        {mergedInvoiceState.selectedInvoices?.length > 0 && (
                            <Grid container spacing={1} marginTop={2}>
                                <Grid item xs={6}>
                                    <ClientsSearch
                                        onClientSelect={(clientData) => {
                                            setMergeInvoiceState({
                                                ...mergedInvoiceState,
                                                selectedCustomer: clientData,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PersonIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                !!mergedInvoiceState?.selectedCustomer
                                                    ? `${mergedInvoiceState?.selectedCustomer?.firstName || ""} ${
                                                          mergedInvoiceState?.selectedCustomer?.lastName || ""
                                                      }`
                                                    : t("select_customer")
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                                {selectedInvoicesData?.length &&
                                    !selectedInvoicesData?.every(
                                        (inv) => inv.invoiceCurrency === selectedInvoicesData?.[0]?.invoiceCurrency
                                    ) && (
                                        <Grid item xs={6}>
                                            <SelectControl
                                                value={mergedInvoiceState.selectedInvoiceCurrency}
                                                onChange={(e) =>
                                                    setMergeInvoiceState({
                                                        ...mergedInvoiceState,
                                                        selectedInvoiceCurrency: e.target.value,
                                                    })
                                                }
                                                label={t("invoice_currency")}
                                                options={currenciesOptions}
                                            />
                                        </Grid>
                                    )}
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setDisableConfirm(true);
                                            onConfirm();
                                        }}
                                        disabled={
                                            disableConfirm ||
                                            mergedInvoiceState?.selectedInvoices?.length < 2 ||
                                            !mergedInvoiceState.selectedCustomer ||
                                            !mergedInvoiceState.selectedInvoiceCurrency
                                        }
                                    >
                                        {t("confirm")}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
            {saveApi?.loading && <LinearProgress color="primary" />}
        </div>
    );
};

export default InvoicesMerge;
