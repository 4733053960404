import React, { useState } from "react";
import { Modal } from "../../../components/modal";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import QRCode from "qrcode";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const QRModal = ({ IICRef, nipt, IssueDateTime, totalPrice }) => {
    const { t } = useTranslation();
    const [QR, setQr] = useState(null);
    const [URL, setURL] = useState(null);

    const generateQRCode = async () => {
        let qrUrl = `https://efiskalizimi-app.tatime.gov.al/invoice-check/#/verify?iic=${IICRef}&tin=${nipt}&crtd=${DateTime.fromISO(
            IssueDateTime
        ).toFormat(`yyyy-MM-dd\'T\'HH:mm:ss`)}%2002:00&prc=${totalPrice}`;
        const qr = await QRCode.toDataURL(qrUrl);
        setQr(qr);
        setURL(qrUrl);
    };
    return (
        <>
            <Button startIcon={<QrCode2Icon />} onClick={() => generateQRCode()}>
                {t("QR_code")}
            </Button>
            <Modal maxWidth="xs" onClose={() => setQr(null)} open={Boolean(QR)} yesText="none" noText="none">
                <Stack alignItems="center" justifyContent="center">
                    <img width={200} height={200} src={QR} />
                    <Typography variant="h6">{t("scan_to_check_fiscal_invoice")}</Typography>
                    <Button href={`${URL}`} target="_blank">
                        {t("check_now")} <OpenInNewIcon />
                    </Button>
                </Stack>
            </Modal>
        </>
    );
};

export default QRModal;
