import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CheckboxControl, InputControl, SelectControl, TagsAutoCompleteControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EditableBedtypesTable } from "../roomtypes/components";
import { EditableTaxesTable, GuestCategoriesCard, PriceInformationCard } from "../../components/common";
import { calculateTotalTax } from "../../utils";

const rules = {
    name: "string|required",
    roomtypeId: "string|required",
};
const mealsOptions = ["breakfast", "lunch", "dinner", "all_inclusive"];

const RoomForm = () => {
    const [values, setValues] = useState({
        bedtypes: [{}, {}],
    });
    const { t } = useTranslation();
    const [bedtypesOptions, setBedtypesOptions] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [facilitesOptions, setFacilitiesOptions] = useState([]);
    const [roomtypeOptions, setRoomtypeOptions] = useState([]);
    const [roomtypes, setRoomtypes] = useState([]);
    const [guestcategories, setGuestcategories] = useState([]);
    const [settings, setSettings] = useState({});
    const [tags, setTags] = useState([]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordRoom } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "room",
            data: {
                _id: "rooms_" + params?.id,
            },
            responseData: `_id name amenities meals price  roomtypeId taxData{taxId isIncluded}
            bedtypes{id quantity} maxCapacity includedCapacity capacity{id price} tags`,
        });
        if (response?.room) setValues(response.room);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "bedtypes", responseData: "_id name" },

                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                {
                    endpoint: "facilities",
                    responseData: "_id name",
                },
                {
                    endpoint: "roomtypes",
                    responseData: `_id name amenities meals price taxData{taxId isIncluded} 
                        bedtypes{id quantity} maxCapacity includedCapacity capacity{id price}`,
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "tags",
                    responseData: "_id name description color",
                },
            ],
        });
        if (!!response?.bedtypes)
            setBedtypesOptions(response.bedtypes.map((bedtype) => ({ value: bedtype._id, label: bedtype.name })));
        if (!!response?.taxes) setTaxes(response.taxes);
        if (response?.facilities) setFacilitiesOptions(response.facilities);
        if (response?.guestcategories) setGuestcategories(response.guestcategories);
        if (response?.roomtypes) {
            setRoomtypeOptions(
                response.roomtypes.map((roomtype) => {
                    return {
                        value: roomtype._id,
                        label: roomtype.name,
                    };
                })
            );
            setRoomtypes(response.roomtypes);
        }
        if (response?.settingsgeneral) setSettings(response.settingsgeneral);
        if (response?.tags) setTags(response.tags.map((tag) => ({ value: tag._id, label: tag.name, data: tag })));
    };
    const currency = settings?.currencyData?.currency;

    const amountInfo = useMemo(() => {
        const { totalAmount, includedAmount } = calculateTotalTax({
            taxData: values.taxData?.map((t) => ({
                isIncluded: t.isIncluded,
                tax: taxes.find((tax) => tax._id === t.taxId),
            })),
            price: parseFloat(values.price),
        });
        const totalPrice = parseFloat(values.price) + totalAmount - includedAmount;
        const subtotal = totalPrice - totalAmount;
        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice.toFixed(2),
            subtotal: isNaN(subtotal) ? 0.0 : subtotal.toFixed(2),
            tax: isNaN(totalAmount) ? 0.0 : totalAmount.toFixed(2),
        };
    }, [values.taxData, values.price, taxes]);

    const saveHandler = async () => {
        const {
            name,
            amenities,
            meals,
            price,
            taxData,
            maxCapacity,
            includedCapacity,
            capacity = [],
            bedtypes,
            roomtypeId,
            _id,
            tags,
        } = values;

        const data = {
            name,
            amenities,
            meals,
            tags,
            price: parseFloat(price),
            taxData,
            maxCapacity: isNaN(parseFloat(maxCapacity)) ? null : parseFloat(maxCapacity),
            includedCapacity: isNaN(parseFloat(includedCapacity)) ? null : parseFloat(includedCapacity),
            capacity: capacity?.map((c) => {
                const price = parseFloat(c.price);
                return { id: c.id, price: isNaN(price) ? null : price };
            }),
            bedtypes,
            roomtypeId,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateRoom" : "createRoom",
            data,
            responseData: `_id name amenities meals price roomTypeData{name}  roomtypeId taxData{taxId isIncluded}
            bedtypes{id quantity} capacity{id price} tags`,
        });
        if (response?.createRoom?._id || response?.updateRoom?._id) {
            enqueueSnackbar(t("room_saved"), { variant: "default" });
            addRecordRoom && addRecordRoom(response.createRoom || response?.updateRoom);
            navigate(-1);
        }
    };

    const capacityChangeHandler = (capacityData) => {
        if (!values.capacity) {
            setValues({ ...values, capacity: [capacityData] });
            return;
        }
        if (values.capacity.some((c) => c.id === capacityData.id)) {
            setValues({
                ...values,
                capacity: values.capacity.map((c) => {
                    if (c.id === capacityData.id) return capacityData;
                    return c;
                }),
            });
            return;
        }
        setValues({ ...values, capacity: [...values.capacity, capacityData] });
    };

    return (
        <Modal
            titlelabel={t("room")}
            fullScreen
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ rooms: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler, rules }}
        >
            <Grid container spacing={4} alignItems="flex-start">
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("room_details")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="price" label={t("price")} type="number" toCurrency={currency} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("roomtype")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectControl
                            label={t("roomtype")}
                            value={values.roomtypeId || ""}
                            onChange={(e) => {
                                const roomtype = roomtypes.find((rt) => rt._id === e.target.value);
                                setValues({
                                    ...values,
                                    roomtypeId: e.target.value,
                                    amenities: roomtype?.amenities,
                                    meals: roomtype?.meals,
                                    price: roomtype?.price,
                                    taxData: roomtype?.taxData,
                                    bedtypes: roomtype?.bedtypes,
                                    maxCapacity: roomtype?.maxCapacity,
                                    includedCapacity: roomtype?.includedCapacity,
                                    capacity: roomtype?.capacity,
                                });
                            }}
                            options={roomtypeOptions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TagsAutoCompleteControl multiple options={tags} size="large" name="tags" />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("taxes")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <EditableTaxesTable
                            taxes={taxes}
                            data={values?.taxData || []}
                            onChange={(taxData) => setValues({ ...values, taxData })}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("bedtypes")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <EditableBedtypesTable
                            bedtypes={bedtypesOptions}
                            data={values?.bedtypes || []}
                            onChange={(bedtypeData) => setValues({ ...values, bedtypes: bedtypeData })}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("included_meals")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {mealsOptions.map((meal) => (
                            <CheckboxControl
                                value={values?.meals?.includes(meal) || false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({ ...values, meals: [...(values.meals || []), meal] });
                                    else setValues({ ...values, meals: values.meals.filter((m) => m !== meal) });
                                }}
                                label={t(meal)}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("amenities")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {facilitesOptions.map((facility) => (
                            <CheckboxControl
                                value={values?.amenities?.includes(facility.name) || false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({
                                            ...values,
                                            amenities: [...(values.amenities || []), facility.name],
                                        });
                                    else
                                        setValues({
                                            ...values,
                                            amenities: values.amenities.filter((m) => m !== facility.name),
                                        });
                                }}
                                label={facility.name}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("guest_categories")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                            <InputControl type="number" name="maxCapacity" label={t("capacity")} />
                        </Grid>
                        <Grid item xs={4}>
                            <InputControl type="number" name="includedCapacity" label={t("included_capacity")} />
                        </Grid>
                    </Grid>
                    {guestcategories.map((category) => {
                        const guestCapacity = values.capacity?.find((c) => c.id === category._id);
                        return (
                            <Grid item xs={4}>
                                <GuestCategoriesCard
                                    values={guestCapacity}
                                    onValuesChange={capacityChangeHandler}
                                    t={t}
                                    id={category._id}
                                    name={category.name}
                                    currency={currency}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid justifyContent="end" container item xs={12}>
                    <Grid item xs={12} sm={6}>
                        <PriceInformationCard
                            tax={amountInfo?.tax}
                            subtotal={amountInfo?.subtotal}
                            total={amountInfo?.totalPrice}
                            currency={currency}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default RoomForm;
