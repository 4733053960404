import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useState } from "react";
import useApi from "./useApi";

const loadCacheProducts = () => {
    const productsDataAsString = localStorage.getItem("cacheProducts");
    if (!productsDataAsString) return undefined;
    const productsData = JSON.parse(productsDataAsString);
    const { timestamp, products } = productsData || {};
    if (DateTime.now().diff(DateTime.fromISO(timestamp), "hours")?.toObject()?.hours > 1) {
        return { products, expired: true };
    }
    return { products, expired: false };
};

const cacheProducts = (products) => {
    const productsData = {
        timestamp: DateTime.now().toISO(),
        products,
    };
    localStorage.setItem("cacheProducts", JSON.stringify(productsData));
};

const useProducts = () => {
    const [products, setProducts] = useState([]);

    const { loading, fetch } = useApi();

    const loadProductsFromApi = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "products",
            responseData: `_id name categoryId parentProduct modifiers isVariant price posplaces cost purchaseUnit measureUnit deductionFactor
                pospoints{ 
                    posPointId 
                    price 
                }  
                taxData{
                    taxId
                    isIncluded
                    tax{rate taxCategory}
                }
                warehouseIds
                barcode
            `,
        });
        if (response?.products) {
            setProducts(response.products);
            cacheProducts(response.products);
            return true;
        }
        return false;
    };

    const loadProducts = async () => {
        const { products, expired } = loadCacheProducts() || {};
        if (!products) {
            loadProductsFromApi();
            return;
        }
        if (!expired) {
            setProducts(products);
            return;
        }
        const hasLoadedOnlineProducts = await loadProductsFromApi();
        if (hasLoadedOnlineProducts) return;
        setProducts(products);
        return;
    };

    useEffect(() => {
        loadProducts();
    }, []);

    return { products, loadingProducts: loading, reloadProducts: loadProductsFromApi };
};

export default useProducts;
