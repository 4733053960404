import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../components/hooks";
import { Modal } from "../../../components/modal";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { TimelineView } from "../../../components/common";
import { NoRecordsFound } from "../../../components/common";

const LogsModal = ({ reservationId }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openModal: false,
        logs: null,
    });

    const { fetch, loading } = useApi();

    useEffect(() => {
        if (!state.openModal) return;
        if (!!state.logs) return;
        loadRecordLogs();
    }, [state.openModal, state.logs]);

    const loadRecordLogs = async () => {
        const respone = await fetch({
            operation: "query",
            endpoint: "getRecordLogs",
            data: { _id: "reservations_" + reservationId },
            responseData: "timestamp userId title updatedFields{field values{previous current}}",
        });
        if (!!respone?.getRecordLogs) {
            setState({ ...state, logs: respone.getRecordLogs });
        }
    };

    return (
        <>
            <Button startIcon={<TextSnippetIcon />} onClick={() => setState({ ...state, openModal: true })}>
                {t("timeline")}
            </Button>
            <Modal
                loading={loading}
                maxWidth="m"
                open={state.openModal}
                onClose={() => setState({ ...state, openModal: false })}
                noText="none"
                yesText="none"
                yesDisabled={loading}
            >
                {state?.logs?.length > 0 ? (
                    <TimelineView logs={state.logs || []} />
                ) : (
                    <div>
                        <NoRecordsFound />
                        <Typography style={{ width: "100%" }} textAlign="center" variant="body1">
                            {t("no_records_available")}
                        </Typography>
                    </div>
                )}
            </Modal>
        </>
    );
};
export default LogsModal;
