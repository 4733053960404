import React from "react";
import { BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, Brush } from "recharts";
import { tooltipProps } from "./utils";

const BarChartData = ({ key = "value", data = [], withBrush = false }) => {
    return (
        <ResponsiveContainer width={"100%"} aspect={3}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip {...tooltipProps} />
                {withBrush && <Brush dataKey="name" height={30} stroke="#00A4E8" />}
                <Bar dataKey={key} fill="#00A4E8" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartData;
