import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../utils";

const PaymentByMethodsTable = ({ data = [] }) => {
    const { t } = useTranslation();
    return (
        <Card>
            <CardHeader title={t("total_payments")} />
            <CardContent style={{ padding: 0 }}>
                <Table>
                    <TableHead style={{ backgroundColor: "#F8F8F8" }}>
                        <TableRow>
                            <TableCell>{t("payment_method")}</TableCell>
                            <TableCell>{t("amount")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(data)?.map(([method, currencies]) => {
                            return (
                                <TableRow>
                                    <TableCell>{t(method)}</TableCell>
                                    <TableCell>
                                        {Object.entries(currencies)
                                            ?.map(([currency, amount]) => toCurrency(amount, currency))
                                            ?.join(" , ")}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default PaymentByMethodsTable;
