const stringifyValue = (value) => {
    if (typeof value === "string") return JSON.stringify(value);
    if (value === undefined || value === null) return null;

    if (Array.isArray(value)) return `[${value.map((v) => stringifyValue(v))}]`;

    if (typeof value === "object") {
        return `{
          ${Object.entries(value)
              .map(([k, v]) => `${k}:${stringifyValue(v)}`)
              .join(",")}
        }`;
    }

    return value;
};

const getQueryArgs = (data = {}) => {
    if (Object.keys(data).length < 1) return "";
    return `(
      ${Object.entries(data)
          .map((field) => {
              return `${field[0]}: ${stringifyValue(field[1])}`;
          })
          .join(", ")}
    )`;
};

export const getQueryBody = (options = {}) => {
    const { operation, multipleEndpoints, endpoint, data, responseData } = options;
    const query = `${operation} {
    ${
        multipleEndpoints
            ? multipleEndpoints
                  .map(
                      (endpointData) =>
                          `${endpointData.endpoint} ${getQueryArgs(endpointData.data)} ${
                              endpointData.responseData ? `{${endpointData.responseData}}` : ""
                          }`
                  )
                  .join(" ")
            : `${endpoint} ${getQueryArgs(data)} ${responseData ? `{${responseData}}` : ""} `
    }
    }`;

    return { query };
};
