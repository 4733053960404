import { Button, Card, CardMedia, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { InputControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import UploadComponent from "../../components/form/UploadComponent";
import CancelIcon from "@mui/icons-material/Cancel";
import { toBase64 } from "../../utils";

const rules = {
    name: "string|required",
    businessName: "string|required",
    nipt: "string|required",
    businessUnitCode: "string|required",
    password: "string|required",
};

const BusinessForm = () => {
    const [values, setValues] = useState({});
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "business",
            data: {
                _id: "fiscalbusiness_" + params?.id,
            },
            responseData: "_id name businessName nipt fileName businessUnitCode password certificate withVat",
        });
        if (response?.business) setValues(response.business);
    };

    const saveHandler = async () => {
        const { name, businessName, nipt, businessUnitCode, certificate, password, withVat, file, fileName, _id } =
            values;
        const data = {
            name,
            businessName,
            nipt,
            businessUnitCode,
            password,
            withVat,
            certificate,
            fileName,
        };
        if (_id) data._id = _id;
        if (!!file) {
            const base64String = await toBase64(file);
            data.certificate = [base64String] || [];
            data.fileName = displayFileName || "";
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateBusiness" : "createBusiness",
            data,
            responseData: "_id",
        });
        if (response?.createBusiness?._id || response?.updateBusiness?._id) {
            enqueueSnackbar(t("business_saved"), { variant: "default" });
            navigate(-1);
        }
    };
    const displayFileName = useMemo(() => {
        if (!!values?.file) {
            return values?.file?.name;
        }
        if (!!values?.fileName) return values.fileName;
        return undefined;
    }, [values?.file, values?.certificate]);

    return (
        <Modal
            titlelabel={t("fiscal_businesses")}
            maxWidth="md"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="businessName" label={t("business_name")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="nipt" label={t("nipt")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="businessUnitCode" label={t("business_unit_code")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="password" label={t("password")} />
                </Grid>

                <Grid item xs={6}>
                    <SwitchControl name="withVat" label={t("with_vat")} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{t("certificate")}</Typography>
                    <Divider />
                    <Stack direction="row" marginY={2} gap={3} alignItems="center">
                        {!!displayFileName ? <Typography>{displayFileName}</Typography> : null}
                        <UploadComponent
                            onFileChange={(e) => {
                                setValues({
                                    ...values,
                                    file: e.target?.files?.[0],
                                    fileName: e.target?.files?.[0]?.name,
                                });
                            }}
                        />
                        <Button
                            onClick={() => {
                                setValues({ ...values, file: null, fileName: null, certificate: null });
                            }}
                            color="secondary"
                            startIcon={<CancelIcon />}
                        >
                            {t("remove")}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default BusinessForm;
