import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";

const rules = {
    name: "string|required",
};
const lostfoundTypeOptions = [
    {
        value: "found",
        label: "found",
    },
    {
        value: "wpick",
        label: "waiting_pickup",
    },
    {
        value: "solved",
        label: "solved",
    },
    {
        value: "archvied",
        label: "archived",
    },
];

const LostFoundForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "lostfound",
            data: {
                _id: "lostfounds_" + params?.id,
            },
            responseData:
                "_id name image roomId timestamp userName status pickupLocation pickupTime pickupName description",
        });
        if (response?.lostfound) setValues(response.lostfound);
    };

    const saveHandler = async () => {
        const {
            name,
            image,
            roomId,
            timestamp,
            userName,
            status,
            pickupLocation,
            pickupTime,
            pickupName,
            description,
            _id,
        } = values;
        const data = {
            name,
            image,
            status,
            roomId,
            timestamp,
            userName,
            pickupLocation,
            pickupTime,
            pickupName,
            description,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateLostFound" : "createLostFound",
            data,
            responseData: "_id",
        });
        if (response?.createLostFound?._id || response?.updateLostFound?._id)
            enqueueSnackbar(t("lostfound_saved"), { variant: "default" });
    };

    return (
        <Modal
            titlelabel={t("lost_and_found")}
            maxWidth="sm"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ lostfounds: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="image" label={t("image")} />
                </Grid>
                <Grid item xs={12}>
                    <SelectControl name="status" label={t("status")} options={lostfoundTypeOptions} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="description" label={t("description")} multiline minRows={3} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default LostFoundForm;
