import { Button, LinearProgress } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Table } from "../../components/table";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";

const ConfirmOrderList = () => {
    const [orders, setOrders] = useState([]);

    const { loading, fetch } = useApi();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();

    const columns = [
        {
            id: "customerName",
            header: t("customer"),
            accessorFn: (row) => row?.customerId,
        },
        {
            id: "dateCreated",
            header: t("date"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("yyyy-LL-dd"),
        },
        {
            id: "timeCreated",
            header: t("time"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("HH:mm:ss"),
        },
        {
            id: "status",
            header: t("status"),
            accessorFn: (row) => {
                return t(`${row?.status}`);
            },
        },

        { accessorKey: "totalPrice", header: t("amount") },
        {
            header: t("order_number"),
            accessorFn: (row) => {
                return row?.number;
            },
        },
        {
            id: "createdBy",
            header: t("created_by"),
            accessorFn: (row) => row?.createdBy?.split("API_")[1],
        },
        {
            id: "id",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <Button
                        startIcon={<StorefrontIcon />}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(
                                `/pos/sell?pos=${original?.pospointId?.split("pospoints_")[1]}&order=${
                                    original?._id?.split("orders_")[1]
                                }`
                            );
                        }}
                        variant="outlined"
                    >
                        {t("confirm")}
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        if (!!params.id) return; //Skip fetching data when single client page is rendered
        loadOrders();
    }, []);
    const onOrderChange = (data) => {
        if (orders?.some((r) => r._id === data._id)) {
            setOrders(
                orders?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setOrders([...orders, data]);
    };

    const loadOrders = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "openOrders",
                    responseData: `orders{
                        _id
                        pospointId
                        customerId
                        status
                        totalPrice  
                        number
                        createdAt
                        createdBy
                    }
                    `,
                },
            ],
        });

        if (response?.openOrders?.orders) {
            setOrders(response.openOrders?.orders?.filter((order) => order?.createdBy?.startsWith("API_")));
        }
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("order_form")}
                moduleType="pos"
                columns={columns}
                data={orders}
                onRowClick={(row) => navigate(`/pos/orders/${row?._id?.split("orders_")[1]}`)}
            />
            <Outlet context={{ onOrderChange }} />
        </div>
    );
};

export default ConfirmOrderList;
