import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { capitalize } from "../../utils";

const InputControl = ({
    label,
    name,
    size = "small",
    margin = "dense",
    fullWidth = true,
    toCurrency,
    inputRef,
    ...otherProps
}) => {
    const labelText = label || capitalize(name);
    return (
        <TextField
            label={labelText}
            size={size}
            margin={margin}
            fullWidth={fullWidth}
            InputProps={
                toCurrency && {
                    startAdornment: <InputAdornment position="start">{toCurrency}</InputAdornment>,
                }
            }
            inputRef={inputRef}
            {...otherProps}
        />
    );
};

export default InputControl;
