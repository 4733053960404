import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import { Button, IconButton, Typography } from "@mui/material";
import { useApi } from "../../../components/hooks";
import { DiscountCard } from "../../reservations/components/create/components";

const SelectDiscountModal = ({ applyDiscount, discount, pospoint }) => {
    const [openModal, setOpenModal] = useState(false);
    const [posdiscounts, setPosDiscounts] = useState([]);
    const { t } = useTranslation();
    const { loading, fetch } = useApi();

    useEffect(() => {
        if (!openModal) return;
        loadData();
    }, [openModal]);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "posdiscounts",
            responseData: "_id name pospoints discountValue discountType",
        });
        if (response?.posdiscounts)
            setPosDiscounts(
                response.posdiscounts?.filter((posdiscount) => posdiscount?.pospoints?.includes(pospoint?._id))
            );
    };
    return (
        <>
            <IconButton
                onClick={() => {
                    setOpenModal(true);
                }}
                color="primary"
            >
                <LocalOfferIcon />
            </IconButton>
            <Modal
                open={openModal}
                titlelabel={t("discount")}
                onClose={() => setOpenModal(false)}
                maxWidth="xs"
                yesText={"none"}
                noText={"none"}
            >
                {posdiscounts.map((posDiscount) => (
                    <DiscountCard
                        title={posDiscount.name}
                        text={posDiscount.discountValue + ((posDiscount.discountType === "percentage" && "%") || "")}
                        titleIcon={<DiscountOutlinedIcon />}
                        isSelected={discount?.discountId === posDiscount._id}
                        onClick={() => {
                            applyDiscount &&
                                applyDiscount({
                                    type: posDiscount.discountType,
                                    value: posDiscount.discountValue,
                                    discountId: posDiscount._id,
                                });
                            setOpenModal(false);
                        }}
                        sx={{ marginRight: 2 }}
                    />
                ))}
            </Modal>
        </>
    );
};

export default SelectDiscountModal;
