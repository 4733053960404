import React from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl, InputControl, SelectControl } from "../../../../components/form";
import { EditableTable } from "../../../../components/table";

const EditableProductsTable = ({ products = [], data = [], onChange }) => {
    const { t } = useTranslation();
    const columns = [
        {
            header: t("product"),
            cell: ({ rowIndex, row }) => (
                <AutoCompleteControl
                    value={row?.productId || ""}
                    onChange={(e) => {
                        const { purchaseUnit, cost } = products.find((o) => o.value === e.target.value)?.data || {};
                        data[rowIndex] = { productId: e.target.value, unit: purchaseUnit, price: cost };
                        onChange && onChange(data);
                    }}
                    options={products}
                    label={t("product")}
                />
            ),
        },
        {
            header: t("price"),
            cell: ({ rowIndex, row }) => (
                <InputControl
                    value={row?.price || ""}
                    onChange={(e) => {
                        if (!data[rowIndex]) data[rowIndex] = {};
                        data[rowIndex].price = e.target.value;
                        onChange && onChange(data);
                    }}
                    label={t("price")}
                />
            ),
        },
        {
            header: t("quantity"),
            cell: ({ rowIndex, row }) => (
                <InputControl
                    type="number"
                    value={row?.quantity || ""}
                    onChange={(e) => {
                        if (!data[rowIndex]) data[rowIndex] = {};
                        data[rowIndex].quantity = e.target.value;
                        onChange && onChange(data);
                    }}
                    label={t("quantity")}
                />
            ),
        },
        {
            header: t("unit"),
            cell: ({ rowIndex, row }) => <InputControl value={row?.unit || ""} disabled label={t("unit")} />,
        },
    ];
    return (
        <EditableTable
            columns={columns}
            data={data}
            onAddRow={() => {
                onChange && onChange([...data, {}]);
            }}
            onDeleteRow={(rowIndex) => {
                onChange && onChange(data.filter((r, index) => index !== rowIndex));
            }}
        />
    );
};

export default EditableProductsTable;
