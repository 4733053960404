const validateSellerDetails = (seller) => {
    if (!seller.Name) {
        throw new Error("seller_missing_Name");
    }
    if (!seller.Country) {
        throw new Error("seller_missing_Country");
    }
};

export default validateSellerDetails;
