import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../utils";

const PriceInformationCard = ({ subtotal, total, tax, extraRows = [], currency, discountAmount, discountLabel }) => {
    const { t } = useTranslation();
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    {subtotal ? (
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography>{t("subtotal")}:</Typography>
                            </Grid>
                            <Grid container item xs={6} justifyContent="end">
                                {toCurrency(subtotal, currency)}
                            </Grid>
                        </Grid>
                    ) : null}
                    {tax ? (
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography>{t("tax")}:</Typography>
                            </Grid>
                            <Grid container item xs={6} justifyContent="end">
                                {toCurrency(tax, currency)}
                            </Grid>
                        </Grid>
                    ) : null}
                    {discountAmount ? (
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography>{t("discount") + (discountLabel ? " " + discountLabel : "")}:</Typography>
                            </Grid>
                            <Grid container item xs={6} justifyContent="end">
                                {toCurrency(discountAmount, currency)}
                            </Grid>
                        </Grid>
                    ) : null}
                    {extraRows.map((row) => (
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography>{t(row.label)}:</Typography>
                            </Grid>
                            <Grid container item xs={6} justifyContent="end">
                                {row.value}
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <Typography variant="h6">{t("total")}:</Typography>
                        </Grid>
                        <Grid container item xs={6} justifyContent="end">
                            <Typography variant="h6">{toCurrency(total, currency)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PriceInformationCard;
