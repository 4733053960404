import { applyDiscount, calculateTotalTax } from "../../utils";

class InvoiceHandler {
    taxes;
    constructor({
        date,
        dueDate,
        tableData,
        customer,
        totalPrice,
        reservationIds,
        discountValue,
        discountType,
        payments,
        invoiceCurrency,
        masterId,
    }) {
        this.date = date;
        this.dueDate = dueDate;
        this.tableData = tableData;
        this.customer = customer;
        this.totalPrice = totalPrice;
        this.reservationIds = reservationIds;
        this.discountValue = discountValue;
        this.discountType = discountType;
        this.payments = payments;
        this.invoiceCurrency = invoiceCurrency;
        this.masterId = masterId;
    }

    static fromReservation = ({
        _id,
        checkin,
        checkout,
        rooms = [],
        clients,
        discount,
        totalPrice,
        guests = [],
        payments = [],
        invoiceCurrency,
        masterId,
    }) => {
        const newData = {
            date: checkin,
            dueDate: checkout,
            customer: clients?.[0],
            discountValue: discount?.value,
            discountType: discount?.type,
            reservationIds: [_id],
            totalPrice,
            payments,
            invoiceCurrency,
        };
        const tableData = [];
        rooms.forEach((room) => {
            const roomGuests =
                guests?.find((g) => {
                    const id = room.roomId === "UNASSIGNED" ? room.roomtypeId : room.roomId;
                    return g.roomId === id;
                })?.guests || [];

            const roomGuestsAmount =
                roomGuests?.reduce((acc, category) => acc + category.price * category.extra, 0) || 0;

            const totalPrice = room?.prices?.reduce(
                (acc, day) =>
                    acc +
                    (room?.customPrice !== undefined && room?.customPrice !== null
                        ? room?.customPrice
                        : day.price + (!!masterId ? 0 : roomGuestsAmount)),
                0.0
            );

            tableData.push({
                itemId: room.roomtypeId,
                quantity: room?.prices?.length,
                price: totalPrice / room?.prices?.length, // save item price in invoice as average room day price
                taxData: room?.roomtypeData?.taxData || [],
                unit: "nights",
                description: "",
            });
        });
        newData.tableData = tableData;
        return new InvoiceHandler(newData);
    };
    static fromExistingInvoice = ({
        date,
        dueDate,
        tableData,
        reservationIds,
        discountType,
        discountValue,
        customer,
        totalPrice,
        payments,
        invoiceCurrency,
    }) => {
        return new InvoiceHandler({
            date,
            dueDate,
            tableData,
            reservationIds,
            discountType,
            discountValue,
            customer,
            totalPrice,
            payments,
            invoiceCurrency,
        });
    };

    setTaxes = (taxData) => {
        this.taxes = taxData;
    };

    addOrderItems = (items = []) => {
        this.tableData = this.tableData?.concat(
            items?.map((item) => {
                return {
                    itemId: item.productId,
                    quantity: item.quantity,
                    price: item.price,
                    taxData: item?.productData?.taxData || [],
                    unit: item?.productData?.measureUnit || "",
                };
            })
        );
    };

    #calculateTotalPrice = () => {
        const totalPrice =
            this.tableData?.reduce((total, item) => {
                const taxInfo =
                    item?.taxData?.map((t) => {
                        return { isIncluded: t.isIncluded, tax: this.taxes.find((tax) => tax._id === t.taxId) };
                    }) || [];

                const { totalAmount, includedAmount } = calculateTotalTax({
                    taxData: taxInfo,
                    price: parseFloat(item.price),
                });
                total += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
                return total;
            }, 0.0) || 0.0;
        if (this.discountType && this.discountValue) {
            return applyDiscount(totalPrice, { type: this.discountType, value: this.discountValue });
        }
        return totalPrice;
    };

    getInvoice = () => {
        return {
            date: this.date,
            dueDate: this.dueDate,
            status: "DRAFT",
            customer: this.customer,
            reservationIds: this.reservationIds,
            tableData: this.tableData?.map((item) => {
                return {
                    itemId: item.itemId,
                    price: item.price,
                    taxData:
                        item.taxData?.map((taxItem) => {
                            return {
                                taxId: taxItem.taxId,
                                isIncluded: taxItem.isIncluded,
                            };
                        }) || [],
                    description: item.description,
                    quantity: item.quantity,
                    unit: item.unit,
                };
            }),
            totalPrice: this.#calculateTotalPrice(),
            discountValue: this.discountValue,
            discountType: this.discountType,
            payments: this.payments,
            invoiceCurrency: this.invoiceCurrency,
        };
    };
}
export default InvoiceHandler;
