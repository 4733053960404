import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};
const accountTypeOptions = [
    {
        value: "cash",
        label: "cash",
    },
    {
        value: "bank",
        label: "bank",
    },
    {
        value: "creditcard",
        label: "credit_card",
    },
    {
        value: "expectedpayments",
        label: "expectedpayments",
    },
];

const PaymentAccountForm = () => {
    const [values, setValues] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordPaymentAccount } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "paymentaccount",
            data: {
                _id: "paymentaccounts_" + params?.id,
            },
            responseData: "_id name accountType accountId bank description currency swift",
        });
        if (response?.paymentaccount) setValues(response.paymentaccount);
    };

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "currencies",
            responseData: "_id currency currencyname",
        });
        if (!!response?.currencies) {
            setCurrencies(
                response?.currencies?.map((currency) => {
                    return { value: currency?.currency, label: currency?.currencyname };
                })
            );
        }
    };

    const saveHandler = async () => {
        const { name, description, accountType, accountId, bank, _id, currency, swift } = values;
        const data = {
            name,
            description,
            accountType,
            accountId,
            bank,
            currency,
            swift,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePaymentAccount" : "createPaymentAccount",
            data,
            responseData: "_id name accountType accountId bank description currency swift",
        });
        if (response?.createPaymentAccount?._id || response?.updatePaymentAccount?._id) {
            enqueueSnackbar(t("payment_account_saved"), { variant: "default" });
            addRecordPaymentAccount &&
                addRecordPaymentAccount(response.createPaymentAccount || response?.updatePaymentAccount);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("payment_account")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ payments: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                    <SelectControl options={accountTypeOptions} name="accountType" label={t("account_type")} />
                    <InputControl name="bank" label={t("bank")} />
                    <InputControl name="accountId" label={t("account_number")} />
                    <InputControl name="swift" label={t("swift")} />
                    <SelectControl name="currency" label={t("currency")} options={currencies} />
                    <InputControl name="description" label={t("description")} multiline minRows={4} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default PaymentAccountForm;
