import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};

const BedTypeForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordBedType } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "bedtype",
            data: {
                _id: "bedtypes_" + params?.id,
            },
            responseData: "_id name capacity",
        });
        if (response?.bedtype) setValues(response.bedtype);
    };

    const saveHandler = async () => {
        const { name, capacity, _id } = values;
        const data = {
            name,
            capacity: parseInt(capacity),
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateBedtype" : "createBedtype",
            data,
            responseData: "_id name capacity",
        });
        if (response?.createBedtype?._id || response?.updateBedtype?._id) {
            enqueueSnackbar(t("bedtype_saved"), { variant: "default" });
            addRecordBedType && addRecordBedType(response.createBedtype || response?.updateBedtype);

            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("bed_type")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ bedtypes: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>

                <Grid item xs={12}>
                    <InputControl name="capacity" label={t("capacity")} type="number" />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default BedTypeForm;
