import { Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GuestCategoriesCard } from "../../../components/common";
import { InputControl } from "../../../components/form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const RoomtypeCard = ({ name, values, onChange, capacity = [], guestCategories = [], isRate = false, currency }) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();

    const capacityChangeHandler = (capacityData) => {
        if (!values.capacity) {
            onChange({ ...values, capacity: [capacityData] });
            return;
        }
        if (values.capacity.some((c) => c.id === capacityData.id)) {
            onChange({
                ...values,
                capacity: values.capacity.map((c) => {
                    if (c.id === capacityData.id) return capacityData;
                    return c;
                }),
            });
            return;
        }
        onChange({ ...values, capacity: [...values.capacity, capacityData] });
    };

    return (
        <Card>
            <CardHeader
                title={name}
                titleTypographyProps={{ variant: "h6" }}
                action={
                    <IconButton onClick={() => setExpanded(!expanded)}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
            />
            <CardContent>
                <InputControl
                    value={values?.price !== undefined ? values.price : ""}
                    onChange={(e) => onChange({ ...values, price: e.target.value })}
                    type="number"
                    label={t("price")}
                    toCurrency={currency}
                />
                {expanded && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputControl
                                    value={values?.maxCapacity !== undefined ? values.maxCapacity : ""}
                                    onChange={(e) => onChange({ ...values, maxCapacity: e.target.value })}
                                    label={t("capacity")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl
                                    value={values?.includedCapacity !== undefined ? values.includedCapacity : ""}
                                    onChange={(e) => onChange({ ...values, includedCapacity: e.target.value })}
                                    label={t("included_capacity")}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt={1} container spacing={2}>
                            {guestCategories
                                .filter((c) => {
                                    if (isRate) return true;
                                    return capacity?.some((category) => category.id === c._id);
                                })
                                .map((category) => (
                                    <Grid item xs={6}>
                                        <GuestCategoriesCard
                                            t={t}
                                            name={category.name}
                                            values={values?.capacity?.find((c) => c.id === category._id)}
                                            id={category._id}
                                            onValuesChange={capacityChangeHandler}
                                            currency={currency}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default RoomtypeCard;
