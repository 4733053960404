import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Chip,
    Grid,
    Tab,
    Tabs,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import StoreIcon from "@mui/icons-material/Store";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import printRegistrationCard from "../../components/pdf/reservation/printRegistrationCard";
import printConfirmationCard from "../../components/pdf/reservation/printConfirmationCard";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RecommendIcon from "@mui/icons-material/Recommend";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { useTranslation } from "react-i18next";
import {
    AccomodationDetailsTable,
    InvoicesTable,
    OrdersTable,
    ReservationDetailsCard,
    AddClientToReservationModal,
    PospointModal,
    CheckoutModal,
} from "./components";
import ClientProfileCard from "../../components/common/ClientProfileCard";
import { ActionBox, ActionsPanel, NotesView, InvoiceSelectModal, EditPaymentsModal } from "../../components/common";
import { useSnackbar } from "notistack";
import InvoiceHandler from "../../components/classes/InvoiceHandler";
import PaymentsView from "../../components/common/PaymentsView";
import { CurrencyConverter } from "../../components/classes";
import PaymentsModal from "../../components/common/PaymentsModal";
import { RoomAssignment } from "./components/view";
import { RadioGroupControl, SelectControl } from "../../components/form";
import languages from "../../assests/languages";
import LogsModal from "./components/LogsModal";
import CheckinModal from "./components/CheckinModal";
import { ProtectedAction } from "../../utils";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    CHECKIN: "#59C086", //checked in
    CHECKOUT: "#FFC82C", //checked out
    CANCELLED: "#FF4949", //canceled
    APPROVED: "#C0CCDA", // on hold
};
const ReservationView = () => {
    const [reservation, setReservation] = useState({});
    const [settingsGeneral, setSettingsGeneral] = useState({});
    const [settingsProperty, setSettingsProperty] = useState({});
    const [taxes, setTaxes] = useState([]);
    const [activeView, setActiveView] = useState("invoices");
    const [openPaymentViewModal, setOpenPaymentViewModal] = useState(false);
    const [openPospointModal, setOpenPospointModal] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
    const [openCheckinModal, setOpenCheckinModal] = useState(false);
    const [pdfLanguage, setPdfLanguage] = useState("al");
    const [openSelectInvoiceModal, setOpenSelectInvoiceModal] = useState({
        open: false,
        orders: [],
    });
    const [openSelectPaymentToinvoice, setOpenSelectPaymentToinvoice] = useState({
        open: false,
        openPaymentModal: false,
        invoiceId: null,
        paymentData: {},
    });
    const [roomAssignmentModal, setRoomAssignmentModal] = useState({
        open: false,
        action: null,
    });

    const [editPaymentModal, setEditPaymentModal] = useState({
        open: false,
        paymentData: null,
    });

    const [currencyConvert, setCurrencyConvert] = useState(new CurrencyConverter({}));
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { t } = useTranslation();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "reservation",
                    data: {
                        _id: "reservations_" + params.id,
                    },
                    responseData: `
                        _id masterId checkin checkout checkinTime checkoutTime isDailyUse status clients totalPrice referer bookingSource groupId uuid invoiceCurrency masterId
                        guests{roomId guests{name number price extra}} 
                        notes{note timestamp userName} 
                        discount{type value}
                        rooms{roomId roomtypeId customPrice prices{price rateId rateName rateMeals date discount{type value}} roomData{name} roomtypeData{name _id meals taxData{taxId isIncluded}}}
                        clientsData{_id firstName lastName clientType birthdate referenceTitle company country state city zip addressLine email phone IDType IDNumber placeOfIssue dateOfIssue dateOfExpiry nationality placeOfBirth}
                        refererData{firstName} 
                        tagsData{_id name description color}
                        payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp note}
                        reservationCustomDetails
                        invoices{
                            date 
                            dueDate
                            number 
                            invoiceCurrency 
                            _id 
                            customer
                            reservationIds
                            cashdesk
                            fiscalCurrency
                            pdfLanguage
                            paymentOption
                            cancelReason
                            invoiceCurrencyRate
                            profileID
                            invoiceTypeCode
                            paymentMeansCode
                            clientsData{firstName lastName} 
                            fiscalData{IICRef nivf SumInvIICRefs{IIC IssueDateTime}} 
                            totalPrice 
                            status 
                            payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp note}
                            paymentStatus
                            tableData{itemId price description quantity unit taxData{taxId isIncluded}}
                            discountType
                            discountValue
                        }
                        orders{
                            _id 
                            customerData{firstName lastName} 
                            payments{paymentStatus paymentMethod cashReceived} 
                            totalPrice 
                            number
                            createdAt
                            status
                            pospointData{defaultCurrency}
                            disableTransfer
                            products{
                                productId 
                                price 
                                quantity 
                                productData{
                                    taxData{taxId isIncluded}
                                }
                            }
                            fiscalData{
                                SumInvIICRefs{IIC IssueDateTime}
                            }
                        }
                        chainReservations{_id checkin checkout totalPrice rooms{roomData{name}}}
                    `,
                },
                {
                    endpoint: "settingsgeneral",
                    responseData:
                        "_id currency currencyData{currency currencyname rate} limitCheckout limitCancel requiredCheckinInfo",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "settingsproperty",
                    responseData:
                        "confirmationMessage{language text} bookingTerms{language text} cancellationTerms{language text}",
                    data: { _id: "settings_property" },
                },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency rate",
                },
            ],
        });
        if (response?.reservation) {
            response.reservation.currency =
                response.reservation.invoiceCurrency || response.settingsgeneral?.currencyData?.currency || "";
            setReservation(response.reservation);
        }
        if (response?.taxes) setTaxes(response.taxes);
        if (response?.settingsgeneral) setSettingsGeneral(response.settingsgeneral);
        if (response?.settingsproperty) setSettingsProperty(response.settingsproperty);
        setCurrencyConvert(
            new CurrencyConverter({
                currencies: response?.currencies,
                settings: response?.settingsgeneral,
            })
        );
    };

    const languageOptions = useMemo(() => {
        const options = [];
        settingsProperty?.confirmationMessage?.forEach((message) => {
            if (!options?.includes(message?.language)) {
                options?.push(message?.language);
            }
        });
        settingsProperty?.cancellationTerms?.forEach((message) => {
            if (!options?.includes(message?.language)) {
                options?.push(message?.language);
            }
        });
        settingsProperty?.bookingTerms?.forEach((message) => {
            if (!options?.includes(message?.language)) {
                options?.push(message?.language);
            }
        });

        const selectOptions = options?.map((op) => {
            const language = languages?.find((language) => language?.value === op) || {};
            if (!!language) return language;
        });
        return selectOptions;
    }, [pdfLanguage, settingsProperty]);

    const generateInvoiceData = (orders) => {
        const handler = InvoiceHandler.fromReservation({
            _id: reservation?._id,
            checkin: reservation?.checkin,
            checkout: reservation?.checkout,
            clients: reservation?.clients,
            rooms: reservation?.rooms,
            discount: reservation?.discount,
            totalPrice: reservation?.totalPrice,
            guests: reservation?.guests,
            payments: reservation?.payments,
            invoiceCurrency: reservation?.currency || "",
            masterId: reservation.masterId,
        });
        handler.setTaxes(taxes || []);
        if (orders && orders?.length > 0) {
            handler.addOrderItems(
                orders
                    ?.map((order) =>
                        order.products?.map((product) => {
                            return {
                                ...product,
                                price: currencyConvert?.convertAmount({
                                    amount: product.price,
                                    from: order?.pospointData?.defaultCurrency,
                                    to: reservation?.currency,
                                }),
                            };
                        })
                    )
                    ?.flat() || []
            );
        }
        const invoiceData = handler.getInvoice();
        if (orders && orders?.length > 0) {
            orders?.map((order) => {
                if (!!order?.fiscalData?.SumInvIICRefs) {
                    if (!invoiceData?.fiscalData)
                        invoiceData.fiscalData = {
                            SumInvIICRefs: [],
                        };
                    invoiceData.fiscalData.SumInvIICRefs.push(...order.fiscalData.SumInvIICRefs);
                }
            });
        }
        return invoiceData;
    };

    const reservationInvoices = () => {
        let invoices = reservation?.invoices;
        if (!invoices?.length > 0)
            invoices = [
                {
                    ...(generateInvoiceData() || {}),
                    reservationData: reservation,
                    isGenerated: true,
                    status: reservation?.status === "CANCELLED" ? "CANCELLED" : "DRAFT",
                    clientsData: {
                        firstName: reservation?.clientsData?.[0]?.firstName,
                        lastName: reservation?.clientsData?.[0]?.lastName,
                        IDNumber: reservation?.clientsData?.[0]?.IDNumber,
                        IDType: reservation?.clientsData?.[0]?.IDType,
                        addressLine: reservation?.clientsData?.[0]?.addressLine,
                        country: reservation?.clientsData?.[0]?.country,
                        city: reservation?.clientsData?.[0]?.city,
                        state: reservation?.clientsData?.[0]?.state,
                        email: reservation?.clientsData?.[0]?.email,
                        phone: reservation?.clientsData?.[0]?.phone,
                    },
                },
            ];
        return invoices;
    };

    const renderView = () => {
        switch (activeView) {
            case "accomodation":
                return (
                    <AccomodationDetailsTable
                        currency={reservation.currency}
                        data={reservation?.rooms || []}
                        guests={reservation?.guests || []}
                        reservation={reservation}
                    />
                );
            case "invoices":
                return <InvoicesTable data={reservationInvoices()} mergeInvoices={merge} reservation={reservation} />;
            case "orders":
                return (
                    <OrdersTable
                        reservation={reservation}
                        data={reservation?.orders}
                        addOrderToInvoice={addOrderToInvoice}
                    />
                );
        }
    };

    const addNote = (note) => {
        const noteDetails = {
            note,
        };

        fetch({
            operation: "mutation",
            endpoint: "addReservationNote",
            data: { _id: "reservations_" + params.id, noteText: noteDetails.note },
            responseData: "note timestamp",
        }).then((response) => {
            if (response?.addReservationNote?.timestamp) {
                setReservation({ ...reservation, notes: [...(reservation.notes || []), response?.addReservationNote] });
                return;
            }
            setReservation({
                ...reservation,
                notes: reservation?.notes?.filter((note) => note.timestamp !== noteDetails.timestamp),
            });
            enqueueSnackbar(t("note_not_saved"), { variant: "error" });
        });
    };

    const removeNote = (noteTimestamp) => {
        setReservation({
            ...reservation,
            notes: reservation?.notes?.filter((note) => note.timestamp !== noteTimestamp),
        });
        fetch({
            operation: "mutation",
            endpoint: "removeReservationNote",
            data: { _id: "reservations_" + params.id, noteTimestamp },
        }).then((respone) => {
            if (respone?.removeReservationNote === "success") return;
            enqueueSnackbar(t("note_remove_failed"), { variant: "error" });
        });
    };

    const updateStatus = async (newStatus, keepInvoice = false) => {
        if (!newStatus) return;
        if (newStatus === "CANCELLED" && reservation?.status === "CHECKIN" && settingsGeneral?.limitCancel) {
            enqueueSnackbar(t("you_can_not_cancel_this_reservation"), { variant: "warning" });
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservationStatus",
            data: {
                _id: reservation?._id,
                status: newStatus.toUpperCase(),
                keepInvoice: keepInvoice,
            },
            responseData: "_id status",
        });
        if (response?.updateReservationStatus?._id) {
            const newReservationData = {
                status: response.updateReservationStatus.status,
            };
            if (response?.updateReservationStatus?.status === "CHECKOUT") {
                if (!reservation?.invoices?.length > 0) {
                    const invoiceData = generateInvoiceData();
                    const createInvoiceResponse = await fetch({
                        operation: "mutation",
                        endpoint: "createInvoice",
                        data: {
                            ...invoiceData,
                            payments:
                                invoiceData?.payments?.map((payment) => {
                                    return {
                                        amount: payment.amount,
                                        paymentMethod: payment.paymentMethod,
                                        timestamp: payment.timestamp,
                                        userName: payment.userName,
                                        note: payment.note,
                                    };
                                }) || [],
                            customer: reservation?.clientsData?.[0]?._id,
                            invoiceCurrency: reservation?.currency || "",
                        },
                        responseData: `
                            date 
                            number 
                            invoiceCurrency 
                            _id 
                            clientsData{firstName lastName} 
                            fiscalData{IICRef} 
                            totalPrice 
                            status 
                            payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp}
                            paymentStatus
                            tableData{itemId price description quantity unit taxData{taxId isIncluded}}
                            discountType
                            discountValue
                        `,
                    });
                    if (createInvoiceResponse?.createInvoice) {
                        const clearPaymentResponse = await fetch({
                            operation: "mutation",
                            endpoint: "clearReservationsPayments",
                            data: { reservationIds: invoiceData?.reservationIds },
                        });
                    }
                    newReservationData.invoices = [createInvoiceResponse?.createInvoice];
                }
            }
            const hasInvoice = reservation?.invoices?.length > 0;
            if (newStatus === "CANCELLED" && !!keepInvoice) {
                if (!hasInvoice) {
                    const issuedInvoice = await issueReservationInvoice();
                    newReservationData.invoices = [issuedInvoice];
                }
            }
            if (newStatus === "CANCELLED" && !keepInvoice) {
                if (hasInvoice) {
                    newReservationData.invoices = reservation.invoices?.map((inv) => {
                        return { ...inv, status: "CANCELLED" };
                    });
                }
            }
            enqueueSnackbar(t("status_updated"), { variant: "default" });
            setReservation({ ...reservation, ...newReservationData });
        }
    };
    const removeClientId = async (id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "removeReservationClient",
            data: {
                _id: reservation._id,
                clientId: id,
            },
        });
        if (response?.removeReservationClient) {
            setReservation({ ...reservation, clientsData: reservation?.clientsData?.filter((c) => c._id !== id) });
            enqueueSnackbar(t("client_removed"), { variant: "default" });
        }
    };

    const addClient = async (clientData) => {
        if (reservation?.clientsData?.some((c) => c?._id === clientData?._id)) {
            setOpenSearchModal(false);
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "addReservationClient",
            data: {
                _id: reservation._id,
                clientId: clientData._id,
            },
        });
        if (response?.addReservationClient) {
            setReservation({ ...reservation, clientsData: [...(reservation?.clientsData || []), clientData] });
            enqueueSnackbar(t("client_added"), { variant: "default" });
            setOpenSearchModal(false);
        }
    };

    const onClientEdited = async (clientData) => {
        setReservation({
            ...reservation,
            clientsData: reservation?.clientsData?.map((client) => {
                if (client._id === clientData._id) {
                    return { ...client, ...clientData };
                }
                return client;
            }),
        });
    };

    const onNewInvoice = (newInvoiceData) => {
        setReservation({ ...reservation, invoices: [...(reservation.invoices || []), newInvoiceData] });
    };
    const onNewOrder = (newOrderData) => {
        setReservation({ ...reservation, orders: [...(reservation.orders || []), newOrderData] });
    };
    const onInvoiceEdited = (editInvoiceData) => {
        setReservation({
            ...reservation,
            invoices: reservation?.invoices?.map((invoice) => {
                if (invoice._id === editInvoiceData._id) {
                    return { ...invoice, ...editInvoiceData };
                }
                return invoice;
            }),
        });
    };

    const onNewReservationPayment = (paymentData) => {
        setReservation({
            ...reservation,
            payments: [...(reservation.payments || []), paymentData],
        });
    };

    const onReservationPaymentsEdit = (paymentsData) => {
        setReservation({
            ...reservation,
            payments: paymentsData || [],
        });
    };

    const onReservationEdit = (editedReservationData) => {
        setReservation({ ...reservation, ...editedReservationData });
    };

    const invoicesOptions = useMemo(() => {
        const options = reservation?.invoices
            ?.filter((invoice) => {
                if (invoice?.fiscalData?.IICRef) return false;
                return true;
            })
            ?.map((invoice) => {
                return {
                    value: invoice._id,
                    label: invoice.number,
                };
            });
        return options || [];
    }, [reservation?.invoices]);

    const transferOrderToNewInvoice = async (orders) => {
        const invoiceHandler = InvoiceHandler.fromReservation({
            _id: reservation?._id,
            checkin: reservation?.checkin,
            checkout: reservation?.checkout,
            clients: reservation?.clients,
            rooms: [],
            discount: null,
            totalPrice: 0.0,
            guests: [],
            payments: [],
            invoiceCurrency: reservation?.currency || "",
        });
        invoiceHandler.setTaxes(taxes || []);
        invoiceHandler.addOrderItems(
            orders
                ?.map((order) =>
                    order.products?.map((product) => {
                        return {
                            ...product,
                            price: currencyConvert?.convertAmount({
                                amount: product.price,
                                from: order?.pospointData?.defaultCurrency,
                                to: reservation?.currency,
                            }),
                        };
                    })
                )
                ?.flat() || []
        );
        const { status, ...data } = invoiceHandler.getInvoice();
        orders?.map((order) => {
            if (!!order?.fiscalData?.SumInvIICRefs) {
                if (!data?.fiscalData)
                    data.fiscalData = {
                        SumInvIICRefs: [],
                    };
                data.fiscalData.SumInvIICRefs.push(...order.fiscalData.SumInvIICRefs);
            }
        });
        const response = await fetch({
            operation: "mutation",
            endpoint: "createInvoice",
            data: {
                ...data,
                customer: reservation?.clientsData?.[0]?._id,
                invoiceCurrency: reservation?.currency || "",
            },
            responseData: `
                        date 
                        number 
                        invoiceCurrency 
                        _id 
                        clientsData{firstName lastName} 
                        fiscalData{IICRef} 
                        totalPrice 
                        status 
                        payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp}
                        paymentStatus
                        tableData{itemId price description quantity unit taxData{taxId isIncluded}}
                        discountType
                        discountValue
                    `,
        });
        if (response?.createInvoice?._id) {
            return response?.createInvoice;
        }
    };
    const issueReservationInvoice = async (orders) => {
        const data = generateInvoiceData(orders);
        const response = await fetch({
            operation: "mutation",
            endpoint: "createInvoice",
            data: {
                ...data,
                customer: reservation?.clientsData?.[0]?._id,
                invoiceCurrency: reservation?.currency || "",
                payments:
                    data?.payments?.map((payment) => {
                        return {
                            amount: payment.amount,
                            paymentMethod: payment.paymentMethod,
                            timestamp: payment.timestamp,
                            userName: payment.userName,
                            note: payment.note,
                        };
                    }) || [],
            },
            responseData: `
                        date 
                        number 
                        invoiceCurrency 
                        _id 
                        clientsData{firstName lastName} 
                        fiscalData{IICRef} 
                        totalPrice 
                        status 
                        payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp}
                        paymentStatus
                        tableData{itemId price description quantity unit taxData{taxId isIncluded}}
                        discountType
                        discountValue
                    `,
        });
        if (response?.createInvoice?._id) {
            return response?.createInvoice;
        }
    };
    const transferToExistingInvoice = async (invoiceId, orders) => {
        const invoice = reservation.invoices?.find((i) => i._id === invoiceId);
        const invoiceHandler = InvoiceHandler.fromExistingInvoice({
            tableData: invoice?.tableData,
            discountType: invoice?.discountType,
            discountValue: invoice?.discountValue,
        });
        invoiceHandler.setTaxes(taxes || []);
        invoiceHandler.addOrderItems(
            orders
                ?.map((order) =>
                    order.products?.map((product) => {
                        return {
                            ...product,
                            price: currencyConvert?.convertAmount({
                                amount: product.price,
                                from: order?.pospointData?.defaultCurrency,
                                to: reservation?.currency,
                            }),
                        };
                    })
                )
                ?.flat() || []
        );
        const { tableData, totalPrice } = invoiceHandler.getInvoice();
        const data = { tableData, totalPrice, _id: invoice._id };
        orders?.map((order) => {
            if (!!order?.fiscalData?.SumInvIICRefs) {
                if (!data?.fiscalData) {
                    data.fiscalData = {
                        SumInvIICRefs: [],
                    };
                } else if (!data.fiscalData.SumInvIICRefs) {
                    data.fiscalData.SumInvIICRefs = [];
                }
                data.fiscalData.SumInvIICRefs.push(...order.fiscalData.SumInvIICRefs);
            }
        });
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateInvoice",
            data,
            responseData: `
                        date 
                        number 
                        invoiceCurrency 
                        _id 
                        clientsData{firstName lastName} 
                        fiscalData{IICRef} 
                        totalPrice 
                        status 
                        payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp}
                        paymentStatus
                        tableData{itemId price description quantity unit taxData{taxId isIncluded}}
                        discountType
                        discountValue
                    `,
        });
        if (response?.updateInvoice?._id) {
            return response?.updateInvoice;
        }
    };

    const addOrderToInvoice = async (invoiceId, orders) => {
        let { invoices } = reservation || {};
        if (!invoices) invoices = [];
        if (invoiceId === "NEW_INVOICE") {
            const newInvoice = await transferOrderToNewInvoice(orders);
            invoices.push(newInvoice);
        } else if (invoiceId === "ISSUE_BOTH_INVOICES") {
            const newInvoice = await transferOrderToNewInvoice(orders);
            const issuedInvoice = await issueReservationInvoice();
            invoices?.push(newInvoice, issuedInvoice);
        } else if (invoiceId === "TO_ISSUED_INVOICE") {
            const issuedInvoice = await issueReservationInvoice(orders);
            invoices?.push(issuedInvoice);
        } else if (invoiceId) {
            const updatedInvoice = await transferToExistingInvoice(invoiceId, orders);
            invoices = invoices?.map((inv) => {
                if (inv._id === updatedInvoice._id) return updatedInvoice;
                return inv;
            });
        }
        await fetch({
            operation: "mutation",
            endpoint: "updateOrdersStatus",
            data: { ids: orders?.map((o) => o._id), status: "TRANSFERRED" },
        });
        setReservation({
            ...reservation,
            invoices: [...invoices],
            orders: reservation.orders?.map((order) => {
                if (orders.some((o) => o._id === order._id)) {
                    order.status = "TRANSFERRED";
                }
                return order;
            }),
        });
    };

    const addPaymentToInvoice = async (payment = {}) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "addInvoicePayment",
            data: {
                _id: openSelectPaymentToinvoice?.invoiceId,
                amount: parseFloat(payment?.amount),
                paymentMethod: payment?.paymentMethod,
                timestamp: payment?.timestamp ? new Date(payment.timestamp).toJSON() : null,
                note: payment.note,
            },
            responseData: "amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note",
        });
        if (response?.addInvoicePayment?.amount) {
            enqueueSnackbar(t("payment_added"), { variant: "default" });
            setReservation({
                ...reservation,
                invoices: reservation?.invoices?.map((inv) => {
                    if (inv._id === openSelectPaymentToinvoice?.invoiceId) {
                        if (!inv.payments) inv.payments = [];
                        inv.payments?.push(response?.addInvoicePayment);
                    }
                    return inv;
                }),
            });
            setOpenSelectPaymentToinvoice({
                openPaymentModal: false,
            });
        }
    };

    const merge = async (invoices) => {
        const selectedInvoices = reservation?.invoices?.filter((inv) => invoices?.includes(inv?._id));
        navigate("/finance/invoices-merge", { state: { reservationId: reservation._id, invoices: selectedInvoices } });
    };

    const addPaymentToReservation = async (payment = {}) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "addReservationPayment",
            data: {
                _id: reservation?._id,
                amount: parseFloat(payment?.amount),
                paymentMethod: payment?.paymentMethod,
                timestamp: payment?.timestamp ? new Date(payment.timestamp).toJSON() : null,
                note: payment.note,
            },
            responseData: "amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note",
        });
        if (response?.addReservationPayment?.paymentMethod) {
            enqueueSnackbar(t("payment_added"), { variant: "default" });
            setReservation({
                ...reservation,
                payments: [...(reservation?.payments || []), response?.addReservationPayment],
            });
            setOpenSelectPaymentToinvoice({
                openPaymentModal: false,
            });
        }
    };

    const reservationPayments = useMemo(() => {
        const payments =
            reservation?.invoices?.length > 0
                ? reservation.invoices
                      ?.filter((invoice) => {
                          return !["CANCELLED", "TRANSFERRED"].includes(invoice?.status);
                      })
                      ?.map((inv) => inv.payments?.map((payment) => ({ ...payment, invoiceId: inv._id })) || [])
                      ?.flat()
                : reservation?.payments?.map((payment) => ({ ...payment, reservationId: reservation._id })) || [];
        return payments?.map((payment, index) => {
            return { ...payment, uniqueId: Date.now() + index };
        });
    }, [reservation]);

    const reservationPaymentStatus = useMemo(() => {
        let totalPrice = 0.0;
        let paid = 0.0;
        const activeInvoices = reservation?.invoices?.filter((invoice) => {
            return !["CANCELLED", "TRANSFERRED"].includes(invoice?.status);
        });
        const orders = reservation?.orders?.filter((order) => !["CANCELLED", "TRANSFERRED"].includes(order?.status));
        if (reservation?.invoices?.length > 0) {
            activeInvoices?.forEach((invoice) => {
                totalPrice += invoice.totalPrice;
                paid += invoice?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
            });
        } else {
            totalPrice += reservation?.totalPrice || 0.0;
            paid += reservation?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
        }
        orders?.forEach((order) => {
            totalPrice += currencyConvert?.convertAmount({
                amount: order.totalPrice,
                from: order?.pospointData?.defaultCurrency,
                to: reservation?.currency,
            });
        });
        if (paid === 0) return "UNPAID";
        if (paid < totalPrice) return "PARTLY_PAID";
        return "PAID";
    }, [reservation]);

    const calculatePaidRecords = useMemo(() => {
        return {
            paidInvoices: reservation?.invoices?.filter((inv) => inv?.paymentStatus === "PAID")?.length || 0,
            unpaidInvoices: reservation?.invoices?.filter((inv) => inv?.paymentStatus !== "PAID")?.length || 0,
            paidOrders:
                reservation?.orders?.filter((order) => ["TRANSFERRED", "PAID"]?.includes(order?.status))?.length || 0,
            unpaidOrders:
                reservation?.orders?.filter((order) => !["TRANSFERRED", "PAID"]?.includes(order?.status))?.length || 0,
        };
    }, [reservation]);

    const { onReservationChange } = useOutletContext() || {};

    const closeHandler = () => {
        onReservationChange && onReservationChange(reservation);
        navigate(-1);
    };
    const disableEdit = useMemo(() => {
        if (reservation?.status === "CHECKOUT") return true;
    }, [reservation]);

    const onOrderChange = (orderData) => {
        if (reservation?.orders?.some((order) => order._id === orderData._id)) {
            setReservation({
                ...reservation,
                orders: reservation?.orders?.map((order) => {
                    if (order._id === orderData?._id) return { ...order, ...orderData };
                    return order;
                }),
            });
            return;
        }
        setReservation({ ...reservation, orders: (reservation?.orders || []).concat([orderData]) });
    };
    const updatePayment = async (updatedPaymentData) => {
        setEditPaymentModal({ open: false, paymentData: null });
        if (!updatedPaymentData) return;
        const recordPayments = reservationPayments
            ?.filter((payment) => {
                if (updatedPaymentData?.invoiceId) return payment.invoiceId === updatedPaymentData?.invoiceId;
                if (updatedPaymentData?.reservationId)
                    return payment.reservationId === updatedPaymentData?.reservationId;
            })
            ?.map((payment) => {
                if (payment.uniqueId === updatedPaymentData.uniqueId) {
                    const amount = parseFloat(updatedPaymentData.amount);
                    return {
                        amount: isNaN(amount) ? 0 : amount,
                        paymentMethod: updatedPaymentData.paymentMethod,
                        timestamp: updatedPaymentData.timestamp,
                        userName: updatedPaymentData.userName,
                        note: updatedPaymentData.note,
                    };
                }
                return {
                    amount: payment.amount,
                    paymentMethod: payment.paymentMethod,
                    timestamp: payment.timestamp,
                    userName: payment.userName,
                    note: payment.note,
                };
            });
        const data = {
            _id: updatedPaymentData?.invoiceId || updatedPaymentData.reservationId,
            payments: recordPayments,
        };
        if (!data._id) return;
        if (!!updatedPaymentData?.reservationId) {
            const response = await fetch({
                operation: "mutation",
                endpoint: "updateReservationPayments",
                data,
                responseData:
                    "_id payments{amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note}",
            });
            if (response?.updateReservationPayments?._id) {
                setReservation({ ...reservation, ...response?.updateReservationPayments });
                enqueueSnackbar(t("payment_updated"), { variant: "info" });
            }
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateInvoice",
            data,
            responseData:
                "_id payments{amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note}",
        });
        if (response?.updateInvoice?._id) {
            setReservation({
                ...reservation,
                invoices: reservation?.invoices?.map((invoice) => {
                    if (invoice._id === response?.updateInvoice?._id) {
                        return { ...invoice, ...response?.updateInvoice };
                    }
                    return invoice;
                }),
            });
            enqueueSnackbar(t("payments_updated"), { variant: "info" });
        }
    };

    const cancelReservation = (type) => {
        if (type === "cancelAll") {
            updateStatus("CANCELLED", false);
        } else if (type === "keepInvoice") {
            updateStatus("CANCELLED", true);
        }
    };

    const onCheckinConfirm = async (clientsData) => {
        setOpenCheckinModal(false);
        const newClientData = [];
        for (const _id in clientsData) {
            const clientData = clientsData[_id];

            const response = await fetch({
                operation: "mutation",
                endpoint: "updateClient",
                data: clientData,
                responseData:
                    "_id firstName lastName clientType birthdate referenceTitle company country state city zip addressLine email phone IDType IDNumber placeOfIssue dateOfIssue dateOfExpiry nationality placeOfBirth",
            });
            if (response?.updateClient?._id) {
                newClientData.push(response?.updateClient);
            }
        }
        await updateStatus("CHECKIN");
        setReservation((prevReservationState) => {
            return { ...prevReservationState, clientsData: newClientData };
        });
    };

    return (
        <div>
            <Modal
                titlelabel={t("reservations_form")}
                onClose={() => closeHandler()}
                open
                fullScreen
                yesText="none"
                noText="none"
            >
                <Grid container spacing={4} alignItems="start">
                    <Grid item xs={12} md={4}>
                        {reservation?.clientsData?.map((client, i) => {
                            if (!client)
                                return (
                                    <Card>
                                        <CardHeader
                                            title={t("client_removed")}
                                            titleTypographyProps={{ variant: "h5" }}
                                        />
                                    </Card>
                                );
                            return (
                                <ClientProfileCard
                                    disableRemove={disableEdit || !(reservation?.clientsData?.length > 0)}
                                    onRemoveClick={removeClientId}
                                    defaultExpanded={i === 0}
                                    loading={loading}
                                    clientData={client || {}}
                                    viewProfileUrl={`clients/profile/${client._id.split("clients_")[1]}`}
                                    editProfileUrl={`clients/${client._id.split("clients_")[1]}/edit`}
                                />
                            );
                        })}
                        <Button
                            onClick={() => setOpenSearchModal(true)}
                            startIcon={<AddIcon />}
                            fullWidth
                            sx={{ marginBottom: 1, marginTop: 1 }}
                            variant="outlined"
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            disabled={disableEdit}
                        >
                            {t("add_client")}
                        </Button>
                        <NotesView notes={reservation?.notes || []} addNote={addNote} removeNote={removeNote} />
                    </Grid>
                    <Grid container item xs={12} md={8} spacing={4}>
                        <Grid item xs={12}>
                            <ActionsPanel>
                                <ActionBox title={t("change_status")}>
                                    <ButtonGroup variant="contained" disableElevation fullWidth>
                                        <Button
                                            disabled={disableEdit || reservation?.status === "CHECKIN"}
                                            onClick={() => {
                                                if (settingsGeneral.requiredCheckinInfo?.length > 0) {
                                                    setOpenCheckinModal(true);
                                                } else {
                                                    updateStatus("CHECKIN");
                                                }
                                            }}
                                        >
                                            {t("checkin")}
                                        </Button>

                                        <Button
                                            disabled={disableEdit || reservation?.status === "CHECKOUT"}
                                            onClick={() => setOpenCheckoutModal(true)}
                                        >
                                            {t("checkout")}
                                        </Button>
                                    </ButtonGroup>
                                    <ButtonGroup fullWidth sx={{ marginY: 1 }}>
                                        <Button
                                            disabled={disableEdit || reservation?.status === "NO_SHOW"}
                                            onClick={() => updateStatus("NO_SHOW")}
                                        >
                                            {t("no_show")}
                                        </Button>
                                        <ProtectedAction permission={{ reservations: { cancel: true } }}>
                                            <Button
                                                disabled={reservation?.status === "APPROVED"}
                                                onClick={() => updateStatus("APPROVED")}
                                            >
                                                {t("approved")}
                                            </Button>
                                        </ProtectedAction>
                                    </ButtonGroup>
                                    <ProtectedAction permission={{ reservations: { cancel: true } }}>
                                        <CancelReservationModal
                                            onConfirm={cancelReservation}
                                            disabled={disableEdit || reservation?.status === "CANCELLED"}
                                        />
                                    </ProtectedAction>
                                </ActionBox>
                                <ActionBox title={t("payment")}>
                                    <Button
                                        variant="outlined"
                                        color="success"
                                        onClick={() => setOpenPospointModal(true)}
                                        startIcon={<StoreIcon />}
                                        style={{ marginBottom: 10 }}
                                    >
                                        {t("add_order")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            if (reservation?.invoices?.length === 1) {
                                                setOpenSelectPaymentToinvoice({
                                                    open: true,
                                                    openPaymentModal: true,
                                                    invoiceId: reservation?.invoices?.[0]?._id,
                                                });
                                                return;
                                            }
                                            if (!reservation?.invoices?.length > 0) {
                                                setOpenSelectPaymentToinvoice({
                                                    open: false,
                                                    openPaymentModal: true,
                                                    invoiceId: null,
                                                });
                                                return;
                                            }
                                            setOpenSelectPaymentToinvoice({ open: true });
                                        }}
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                    >
                                        {t("add_payment")}
                                    </Button>
                                    <Button onClick={() => setOpenPaymentViewModal(true)} startIcon={<OpenInNewIcon />}>
                                        {t("view_payment")}
                                    </Button>
                                </ActionBox>
                                <ActionBox title={t("edit")}>
                                    <Tooltip
                                        placement="top"
                                        title={reservation?.invoices?.length > 0 ? t("invoice_closed") : null}
                                    >
                                        <div style={{ width: "100%" }}>
                                            <Button
                                                onClick={() => navigate("edit")}
                                                variant="outlined"
                                                color="info"
                                                startIcon={<BorderColorIcon />}
                                                disabled={disableEdit}
                                                style={{ marginBottom: 10 }}
                                                fullWidth
                                            >
                                                {t("edit")}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                    <Button
                                        onClick={() => setRoomAssignmentModal({ open: true, action: "assign" })}
                                        startIcon={<MeetingRoomIcon />}
                                        disabled={disableEdit}
                                    >
                                        {t("assign_room")}
                                    </Button>
                                    <Button
                                        onClick={() => setRoomAssignmentModal({ open: true, action: "unassign" })}
                                        startIcon={<NoMeetingRoomIcon />}
                                        disabled={disableEdit}
                                    >
                                        {t("unassign_room")}
                                    </Button>
                                </ActionBox>
                                <ActionBox title={t("status")}>
                                    <Tooltip title={t("reservation_status")}>
                                        <Chip
                                            sx={{ marginTop: 1, backgroundColor: statusColors[reservation?.status] }}
                                            label={t(reservation?.status)}
                                        />
                                    </Tooltip>
                                    <Tooltip title={t("invoice_status")}>
                                        <Chip sx={{ marginTop: 1 }} label={t(reservationPaymentStatus)} />
                                    </Tooltip>
                                    {reservation?.isDailyUse && (
                                        <Tooltip title={t("day_use")}>
                                            <Chip
                                                variant="outlined"
                                                sx={{ marginTop: 1 }}
                                                icon={<AccessTimeIcon />}
                                                label={t("day_use")}
                                            />
                                        </Tooltip>
                                    )}
                                    <LogsModal reservationId={params.id} />
                                </ActionBox>
                                <ActionBox title={t("generate")}>
                                    <Button
                                        variant="outlined"
                                        style={{ marginBottom: 10 }}
                                        onClick={() =>
                                            printRegistrationCard({
                                                reservationId: reservation?._id,
                                                t: t,
                                                fetch: fetch,
                                                language: pdfLanguage,
                                            })
                                        }
                                        disabled={disableEdit}
                                        startIcon={<PictureAsPdfIcon />}
                                    >
                                        {t("registration_card")}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            printConfirmationCard({
                                                reservationId: reservation?._id,
                                                t: t,
                                                fetch: fetch,
                                                language: pdfLanguage,
                                            })
                                        }
                                        disabled={disableEdit}
                                        startIcon={<RecommendIcon />}
                                    >
                                        {t("confirmation")}
                                    </Button>
                                    <SelectControl
                                        label={t("pdf_language")}
                                        options={languageOptions || []}
                                        onChange={(e) => setPdfLanguage(e?.target?.value)}
                                    />
                                    {!!reservation?.masterId ? (
                                        <>
                                            <Button
                                                href={`https://beds24.com/control2.php?ajax=bookedit&id=${reservation?.masterId}&tab=1`}
                                                target="_blank"
                                            >
                                                {t("channel_manager_cc")}
                                            </Button>
                                            <Typography variant="body1">
                                                {t("channel_manager_id")}: {reservation?.masterId}
                                            </Typography>
                                        </>
                                    ) : null}
                                </ActionBox>
                            </ActionsPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <ReservationDetailsCard
                                loading={loading}
                                reservation={reservation}
                                disableEdit={disableEdit}
                                currencyConvert={currencyConvert}
                                setReservationData={(newData) => setReservation({ ...reservation, ...newData })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                variant={isSmallScreen ? "scrollable" : "fullWidth"}
                                value={activeView}
                                onChange={(e, newValue) => setActiveView(newValue)}
                            >
                                <Tab value={"accomodation"} label={t("accomodation")} style={{ fontSize: 20 }} />

                                <Tab
                                    value={"invoices"}
                                    label={t("invoices")}
                                    style={{ fontSize: 20 }}
                                    iconPosition="start"
                                    icon={
                                        <div style={{ marginLeft: 5 }}>
                                            <Chip
                                                label={calculatePaidRecords.unpaidInvoices}
                                                color="error"
                                                size="small"
                                            />
                                            <Chip
                                                label={calculatePaidRecords.paidInvoices}
                                                color="primary"
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>
                                    }
                                />

                                <Tab
                                    value={"orders"}
                                    label={t("orders")}
                                    iconPosition="start"
                                    style={{ fontSize: 20 }}
                                    icon={
                                        <div style={{ marginLeft: 5 }}>
                                            <Chip label={calculatePaidRecords.paidOrders} color="error" size="small" />
                                            <Chip
                                                label={calculatePaidRecords.unpaidOrders}
                                                color="primary"
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>
                                    }
                                />
                            </Tabs>
                            {renderView()}
                        </Grid>
                    </Grid>
                </Grid>
                <PaymentsView
                    open={openPaymentViewModal}
                    data={reservationPayments}
                    onClose={() => setOpenPaymentViewModal(false)}
                    editPayment={(paymentData) => {
                        setOpenPaymentViewModal(false);
                        setEditPaymentModal({
                            open: true,
                            paymentData,
                        });
                    }}
                />
                <AddClientToReservationModal
                    onSave={addClient}
                    open={openSearchModal}
                    onClose={() => setOpenSearchModal(false)}
                />
                <PospointModal
                    open={openPospointModal}
                    onClose={() => setOpenPospointModal(false)}
                    reservationId={reservation._id}
                />
                <InvoiceSelectModal
                    open={openSelectInvoiceModal?.open}
                    onClose={() => setOpenSelectInvoiceModal({ open: false })}
                    invoices={invoicesOptions.concat({ value: "new", label: t("new") })}
                    onInvoiceSelect={(invoiceId) => {
                        setOpenSelectInvoiceModal({ open: false });
                    }}
                />
                <InvoiceSelectModal
                    open={openSelectPaymentToinvoice?.open}
                    onClose={() => setOpenSelectPaymentToinvoice({ open: false })}
                    invoices={invoicesOptions}
                    onInvoiceSelect={(invoiceId) => {
                        setOpenSelectPaymentToinvoice({ open: false, openPaymentModal: true, invoiceId: invoiceId });
                    }}
                />
                <PaymentsModal
                    open={openSelectPaymentToinvoice?.openPaymentModal}
                    onClose={() => setOpenSelectPaymentToinvoice({ invoiceId: null, openPaymentModal: false })}
                    addPayment={(data) => {
                        if (!openSelectPaymentToinvoice?.invoiceId) addPaymentToReservation(data);
                        else addPaymentToInvoice(data);
                    }}
                    t={t}
                    currency={reservation?.currency}
                />
                <RoomAssignment
                    open={roomAssignmentModal?.open}
                    action={roomAssignmentModal?.action}
                    onClose={() => setRoomAssignmentModal({ open: false, action: null })}
                    reservation={reservation}
                    onReservationChange={() => loadData()}
                />
                <CheckoutModal
                    reservation={reservation}
                    open={openCheckoutModal}
                    onCheckoutConfirm={() => {
                        setOpenCheckoutModal(false);
                        updateStatus("CHECKOUT");
                    }}
                    onClose={() => setOpenCheckoutModal(false)}
                    currencyConvert={currencyConvert}
                    limitConfirm={settingsGeneral?.limitCheckout}
                />
                <CheckinModal
                    open={openCheckinModal}
                    reservation={reservation}
                    onCheckinConfirm={onCheckinConfirm}
                    onClose={() => setOpenCheckinModal(false)}
                    requiredCheckinInfo={settingsGeneral?.requiredCheckinInfo}
                />
                <EditPaymentsModal
                    open={editPaymentModal.open}
                    onClose={() => setEditPaymentModal({ open: false, paymentData: null })}
                    paymentData={editPaymentModal.paymentData}
                    onConfirm={updatePayment}
                />
            </Modal>
            <Outlet // Context to share with other screens to trigger reservation data refresh without reloading the screen
                context={{
                    onNewOrder, // add new order to state when order created from sell
                    onOrderChange, // update order data when order edited from order view
                    onNewClient: addClient, // add new client data to reservation when new client created
                    onClientEdited, // update client data when client edited from client profile/client form
                    onNewInvoice, // add new invoice to state when invoice issued from invoice form
                    onInvoiceEdited, // update invoice data when invoice edited from invoice form
                    onNewReservationPayment, // add new payment to state when payment added to reservation from invoice form (not issued invoice)
                    onReservationPaymentsEdit, // update reservation payment when payment edited from invoice form (not issued invoice)
                    onReservationEdit, // update reservation data when reservation edited from edit view
                }}
            />
        </div>
    );
};

const CancelReservationModal = ({ onConfirm, disabled }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openModal: false,
        selectedOption: undefined,
    });
    const typeOptions = [
        { value: "cancelAll", label: t("cancel_reservation_for_free") },
        { value: "keepInvoice", label: t("keep_invoice") },
    ];

    return (
        <>
            <Button
                disabled={disabled}
                onClick={() => setState({ ...state, openModal: true })}
                fullWidth
                variant="outlined"
                color="secondary"
            >
                {t("cancel")}
            </Button>
            <Modal
                maxWidth="xs"
                open={state.openModal}
                onClose={() => setState({ ...state, openModal: false })}
                onSave={() => {
                    onConfirm(state.selectedOption);
                    setState({ ...state, openModal: false });
                }}
                noText={t("cancel")}
                yesText={t("confirm")}
            >
                <RadioGroupControl
                    onChange={(e) => setState({ ...state, selectedOption: e.target.value })}
                    options={typeOptions}
                />
            </Modal>
        </>
    );
};

export default ReservationView;
