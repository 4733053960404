import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "../../../components/modal";
import { Grid, Typography, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import currency from "../../../assests/currency";
import { AutoCompleteControl, Form, InputControl, SelectControl } from "../../../components/form";
import { DateControl } from "../../../components/dates";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const countriesOptions = currency.map((c) => c.CtryNm);

const CheckinModal = ({ reservation, onCheckinConfirm, onClose, open, requiredCheckinInfo }) => {
    const { t } = useTranslation();

    const { clientsData } = reservation || {};

    const [values, setValues] = useState({});

    const titleOptions = [
        { value: "Mr.", label: t("mr") },
        { value: "Ms.", label: t("ms") },
        { value: "Mrs.", label: t("mrs") },
        { value: "Dr.", label: t("dr") },
    ];
    const documentOptions = [
        { value: "NUIS", label: t("nuis") },
        { value: "ID", label: t("id_personal_nr") },
        { value: "PASS", label: t("passport_number") },
        { value: "VAT", label: t("registered_vat_number") },
        { value: "TAX", label: t("issued_number") },
        { value: "SOC", label: t("social_security_number") },
    ];
    const handleValuesChange = (clientId, newValues) => {
        setValues((prevValues) => ({
            ...prevValues,
            [clientId]: newValues,
        }));
    };

    const renderInputs = (type) => {
        switch (type) {
            case "personalInformation":
                return (
                    <Stack>
                        <InputControl name="firstName" label={t("firstname")} />
                        <InputControl name="lastName" label={t("lastname")} />
                        <SelectControl options={titleOptions} name="referenceTitle" label={t("reference_title")} />
                        <AutoCompleteControl options={countriesOptions} name="country" label={t("country")} />
                        <InputControl name="company" label={t("company")} />
                    </Stack>
                );
            case "contactInformation":
                return (
                    <Stack>
                        <InputControl multiline minRows={2} name="addressLine" label={t("addressline")} />
                        <InputControl name="city" label={t("city")} />
                        <InputControl name="zip" label={t("zip")} />
                        <InputControl name="state" label={t("state")} />
                    </Stack>
                );
            case "documentInformation":
                return (
                    <Stack>
                        <SelectControl options={documentOptions} name="IDType" label={t("IDType_required")} />
                        <InputControl name="IDNumber" label={t("document_number_required")} />
                        <DateControl name="birthdate" label={t("birthdate")} />
                        <InputControl name="placeOfIssue" label={t("place_of_issue")} />
                        <DateControl name="dateOfIssue" label={t("date_of_issue")} />
                        <DateControl name="dateOfExpiry" label={t("date_of_expiry")} />
                        <InputControl name="placeOfBirth" label={t("place_of_birth")} />
                        <AutoCompleteControl options={countriesOptions} name="nationality" label={t("nationality")} />
                    </Stack>
                );

            default:
                return null;
        }
    };

    useEffect(() => {
        if (!open) return;
        const values = {};
        clientsData?.map((client) => {
            values[client._id] = client;
        });
        setValues(values);
    }, [open]);

    const enableSave = useMemo(() => {
        return Object.values(values)?.every((value) => !!value?.IDNumber);
    }, [values]);

    return (
        <Modal
            maxWidth="m"
            open={open}
            onClose={onClose}
            onSave={() => onCheckinConfirm(values)}
            yesText={t("confirm_and_checkin")}
            noText={"none"}
            yesDisabled={!enableSave}
        >
            <Stack>
                <Typography variant="h6">{t("you_are_checking_in")}</Typography>
                <Typography variant="subtitle2">{t("please_fill_the_fields_below_before_confirming")}</Typography>
            </Stack>
            {clientsData?.map((client) => {
                const { firstName, lastName, _id } = client;
                return (
                    <Form values={values[_id] || {}} onValuesChange={(newValues) => handleValuesChange(_id, newValues)}>
                        <Card sx={{ marginTop: 2 }}>
                            <CardHeader
                                title={(firstName || "") + " " + (lastName || "")}
                                titleTypographyProps={{ variant: "h5" }}
                                avatar={<PersonOutlineIcon />}
                            />
                            <CardContent>
                                <Grid container xs={12} spacing={2}>
                                    {requiredCheckinInfo?.map((info) => {
                                        return (
                                            <Grid item xs={12} md={4}>
                                                <Card>
                                                    <CardHeader
                                                        title={`${t(info)}`}
                                                        titleTypographyProps={{ variant: "h6" }}
                                                    />
                                                    <CardContent>{renderInputs(info)}</CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Form>
                );
            })}
        </Modal>
    );
};

export default CheckinModal;
