import { ButtonBase, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getLoggedUserData } from "../../../utils";

const disableOpenOrderStyle = {
    cursor: "not-allowed",
    backgroundColor: "#808080",
};

const PospointTable = ({ table = {}, style = {}, order = null, onClick }) => {
    const loggedUser = getLoggedUserData() || {};

    const isDisabledAcces = useMemo(() => {
        if (!order) return false;
        const { _id, role } = loggedUser;
        if (order?.createdBy === _id) return false;
        if (role?.isWaiter) return true;
        if (role?.permissions?.orders?.view) return false;
    }, [order, loggedUser]);

    const size = () => {
        switch (table.tableSize) {
            case "big":
                return { width: 1.5, height: 1.5 };
            case "medium":
            default:
                return { width: 1, height: 1 };
            case "small":
                return { width: 0.8, height: 0.8 };
            case "mini":
                return { width: 0.5, height: 0.5 };
        }
    };

    const { t } = useTranslation();

    const shape = () => {
        switch (table.tableShape) {
            case "square":
            default:
                return { width: 100, height: 100 };
            case "rectangularH":
                return { width: 180, height: 100 };
            case "rectangularV":
                return { width: 100, height: 180 };
        }
    };
    return (
        <Tooltip title={isDisabledAcces && t("different_user_order")}>
            <ButtonBase
                onClick={() => {
                    if (isDisabledAcces) return;
                    onClick && onClick(order?._id);
                }}
                style={{
                    width: size().width * shape().width + "px",
                    height: size().height * shape().height + "px",
                    backgroundColor: !!order ? "#F8D210" : table.tableColor || "grey",
                    borderRadius: table.tableBorder === "rectangular" ? "18px" : "100px",
                    ...style,
                    ...(isDisabledAcces ? disableOpenOrderStyle : {}),
                }}
            >
                <div style={{ fontWeight: "bold", color: "black", fontSize: 24 }}>{table.tableName}</div>
            </ButtonBase>
        </Tooltip>
    );
};

export default PospointTable;
