import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const WarehouseList = () => {
    const [warehouses, setWarehouses] = useState([]);
    const { t } = useTranslation();
    const columns = [
        { accessorKey: "name", header: t("name") },
        { accessorKey: "code", header: t("code") },
    ];

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "warehouses",
            responseData: "_id name code",
        });
        if (response?.warehouses) setWarehouses(response.warehouses);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteWarehouses" : "deleteWarehouse",
            data,
        });
        if (response?.deleteWarehouses === "success" || response?.deleteWarehouse === "success") {
            setWarehouses(warehouses.filter((warehouse) => !rows.some((row) => row._id === warehouse._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addRecordWarehouse = (data) => {
        if (warehouses?.some((r) => r._id === data._id)) {
            setWarehouses(
                warehouses?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setWarehouses([...warehouses, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("warehouse_form")}
                moduleType="pos"
                data={warehouses}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("warehouses_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordWarehouse }} />
        </div>
    );
};

export default WarehouseList;
