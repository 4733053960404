import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutoCompleteControl, InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import currency from "../../assests/currency";
import { NotesView } from "../../components/common";

const countriesOptions = currency.map((c) => c.CtryNm);

const SupplierForm = () => {
    const [values, setValues] = useState({});

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { onSupplierSave } = useOutletContext() || {};
    const documentOptions = [
        { value: "NUIS", label: t("nuis") },
        { value: "ID", label: t("id") },
        { value: "PASS", label: t("passport_number") },
        { value: "VAT", label: t("registered_vat_number") },
        { value: "TAX", label: t("issued_number") },
        { value: "SOC", label: t("social_security_number") },
    ];

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "supplier",
            data: {
                _id: "suppliers_" + params?.id,
            },
            responseData:
                "_id  name IDNumber IDType firstName lastName phone email address city postCode state website country description role", // notes attachments
        });
        if (response?.supplier) setValues(response.supplier);
    };

    const saveHandler = async () => {
        const {
            _id,
            name,
            IDNumber,
            IDType,
            firstName,
            lastName,
            phone,
            email,
            address,
            city,
            postCode,
            state,
            website,
            country,
            description,
            role,
            // notes,
            attachments,
        } = values;
        const data = {
            name,
            IDNumber,
            IDType,
            firstName,
            lastName,
            phone,
            email,
            address,
            city,
            postCode,
            state,
            website,
            country,
            description,
            role,
            // notes,
            attachments,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateSupplier" : "createSupplier",
            data,
            responseData: "_id firstName lastName name phone email address country city",
        });
        if (response?.createSupplier?._id || response?.updateSupplier?._id) {
            enqueueSnackbar(t("supplier_saved"), { variant: "default" });
            onSupplierSave && onSupplierSave(response?.createSupplier || response?.updateSupplier);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("supplier")}
            fullScreen
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler }}
            permission={{ suppliers: params?.id === "create" ? { create: true } : { edit: true } }}
        >
            <Grid container spacing={4} alignItems="flex-start">
                <Grid container item xs={12} sm={6} spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="website" label={t("website")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="IDNumber" label={t("IDNumber")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectControl options={documentOptions} name="IDType" label={t("IDType")} />
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} spacing={4}>
                    <Grid item xs={12}>
                        <InputControl name="description" label={t("description")} multiline minRows={3} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={4} alignItems="flex-start">
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{ marginTop: 2 }} variant="h6">
                            {t("contact")}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="firstName" label={t("firstName")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="lastName" label={t("lastName")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="phone" label={t("phone")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="email" label={t("email")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="role" label={t("role")} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{ marginTop: 2 }} variant="h6">
                            {t("address")}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="address" label={t("address")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="city" label={t("city")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="postCode" label={t("zip")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="state" label={t("state")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutoCompleteControl options={countriesOptions} name="country" label={t("country")} />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SupplierForm;
