import { Button, Grid, LinearProgress, Chip, Paper, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { toCurrency } from "../../utils";
import { DateRangeControl } from "../../components/dates";
import { DateTime } from "luxon";
import { ProtectedAction } from "../../utils";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid
    TRANSFERRED: "#FFC82C", //transferred to group
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const BillList = () => {
    const [bills, setBills] = useState([]);
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        startDate: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().toFormat("yyyy-LL-dd"),
    });
    const [columns] = useState([
        {
            id: "name",
            header: t("name"),
            accessorFn: (row) => row?.supplierData?.name || "",
        },
        { accessorKey: "date", header: t("date") },
        { accessorKey: "number", header: t("Number") },
        { accessorKey: "dueDate", header: t("dueDate") },
        { accessorKey: "description", header: t("description") },
        {
            accessorKey: "status",
            header: t("status"),
            cell: ({ row: { original } }) => {
                let paidAmount = original.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
                let status = original?.status || "DRAFT";
                const totalPrice = parseFloat(original.totalPrice).toFixed(2);
                paidAmount = parseFloat(paidAmount).toFixed(2);
                if (parseFloat(paidAmount) >= parseFloat(totalPrice)) {
                    status = "PAID";
                }
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={t(`${status}`)} />;
            },
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            accessorFn: (row) => row?.totalPrice && toCurrency(row?.totalPrice, row?.invoiceCurrency),
        },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const { startDate, endDate } = filters || {};
        if (!startDate) return;
        let end = "end";
        if (!!endDate) end = endDate + `T23:59:59`;
        const response = await fetch({
            operation: "query",
            endpoint: "bills",
            data: {
                startDate: startDate,
                endDate: end,
            },
            responseData:
                "_id date dueDate description status paymentStatus payments{amount} totalPrice invoiceCurrency supplierData{name} number",
        });
        if (response?.bills) setBills(response.bills);
    };
    const addRecord = (data) => {
        if (bills?.some((r) => r._id === data._id)) {
            setBills(
                bills?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setBills([...bills, data]);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteBills" : "deleteBill",
            data,
        });
        if (response?.deleteBills === "success" || response?.deleteBill === "success") {
            setBills(bills.filter((bill) => !rows.some((row) => row._id === bill._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Grid container style={{ margin: "20px 0" }}>
                <ProtectedAction permission={{ billcategories: { list: true } }}>
                    <Button
                        component={Link}
                        to="/finance/billcategories"
                        sx={{ borderRadius: "25px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("bill_categories")}
                    </Button>
                </ProtectedAction>
            </Grid>
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                <Stack direction="row" gap={2} flexWrap="wrap">
                    <DateRangeControl
                        startLabel={t("start")}
                        endLabel={t("end")}
                        values={{ startDate: filters.startDate, endDate: filters.endDate }}
                        onChange={({ startDate, endDate }) => setFilters({ ...filters, startDate, endDate })}
                    />
                    <Button onClick={loadData} size="small" variant="contained">
                        {t("load_bills")}
                    </Button>
                </Stack>
            </Paper>
            <Table
                titleLabel={t("bills_list")}
                moduleType="finance"
                data={bills}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("bills_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecord }} />
        </div>
    );
};

export default BillList;
