import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportHeader } from "./components";
import { DateControl } from "../../components/dates";
import { useApi } from "../../components/hooks";
import { ReportGenerator } from "../../components/classes";
import { useSearchParams } from "react-router-dom";

const totalGuestsByCategories = (guests) => {
    return guests
        ?.map((room) => room.guests)
        ?.flat()
        ?.reduce((acc, category) => {
            const existingCategory = acc.find((c) => c.name === category.name);
            if (!existingCategory) {
                acc.push({ name: category.name, number: category.number });
                return acc;
            }
            existingCategory.number += category.number;
            return acc;
        }, [])
        ?.map((category) => `${category.number} ${category.name}`)
        ?.join(" , ");
};

const MealsReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [report, setReport] = useState(null);
    const { loading, fetch } = useApi();

    const columns = [
        {
            id: "id",
            header: t("reservation"),
            displayValue: (row) => row?.uuid || "",
            totalValue: () => t("total"),
        },
        {
            id: "dates",
            header: t("date"),
            displayValue: (row) => row?.checkin + " - " + row?.checkout,
            totalValue: () => "",
        },
        {
            id: "name",
            header: t("name"),
            displayValue: (row) => {
                const { firstName, lastName } = row?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
            totalValue: () => "",
        },
        {
            id: "room",
            header: t("room"),
            displayValue: (row) => {
                return row?.rooms?.map((room) => room?.roomData?.name || "").join(" , ");
            },
            totalValue: () => "",
        },
        {
            id: "breakfast",
            header: t("breakfast"),
            displayValue: (row) => {
                if (row?.checkin === params.get("date")) return `0 ${t("persons")}`;
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("breakfast") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    if (row?.checkin === params.get("date")) return;
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("breakfast") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
        {
            id: "lunch",
            header: t("lunch"),
            displayValue: (row) => {
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("lunch") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("lunch") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
        {
            id: "dinner",
            header: t("dinner"),
            displayValue: (row) => {
                if (row?.checkout === params.get("date")) return `0 ${t("persons")}`;
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("dinner") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    if (row?.checkout === params.get("date")) return;
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("dinner") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
        {
            id: "all_inclusive",
            header: t("all_inclusive"),
            displayValue: (row) => {
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("all_inclusive") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("all_inclusive") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
    ];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        if (!params.get("date")) return;
        const response = await fetch({
            operation: "query",
            endpoint: "activeReservations",
            responseData: `_id checkin checkout uuid clientsData{_id firstName lastName} status isBlockedRooms
                rooms{
                    roomId
                    roomtypeId
                    prices{
                        rateId
                        rateData{meals}
                    }
                    roomData{name meals}
                }
                guests{
                    roomId 
                    guests{name number}
                } 
            `,
            data: { date: params.get("date") },
        });
        if (!response?.activeReservations) return;
        const newRaportGenerator = new ReportGenerator({
            data: response?.activeReservations?.filter(
                (reservation) => reservation.status !== "CANCELLED" && !reservation.isBlockedRooms
            ),
            columns,
            displayTotal: true,
        });
        setReport(newRaportGenerator.generateTable({ title: t("meals") }));
    };
    return (
        <div>
            <ReportHeader
                title={t("meals_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <DateControl
                    value={params.get("date")}
                    onChange={(e) => setParams({ date: e.target.value })}
                    label={t("date")}
                    fullWidth={false}
                />
            </ReportHeader>
            {report}
        </div>
    );
};

export default MealsReport;
