import { Card, CardContent, CardHeader, Typography, Skeleton } from "@mui/material";
import React from "react";

const DashboardCard = ({ children, title, titleIcon, action, loading = false, skeletonVariant }) => {
    return (
        <Card style={{ height: "100%" }} variant="outlined">
            <CardHeader
                avatar={titleIcon}
                title={title}
                titleTypographyProps={{ variant: "body1", style: { fontWeight: 600 } }}
                action={action}
            />
            {loading ? (
                <SkeletonVariant skeletonVariant={skeletonVariant} />
            ) : (
                <CardContent style={{ maxHeight: 350, overflow: "scroll" }}>{children}</CardContent>
            )}
        </Card>
    );
};

const SkeletonVariant = ({ skeletonVariant }) => {
    return (
        <CardContent>
            {skeletonVariant === "circular" ? (
                <Skeleton style={{ margin: "10px auto" }} variant="circular" width={250} height={250} />
            ) : (
                <>
                    <Typography variant="h4">
                        <Skeleton />
                    </Typography>
                    <Typography variant="h4">
                        <Skeleton />
                    </Typography>
                </>
            )}
        </CardContent>
    );
};

export default DashboardCard;
