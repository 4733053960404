import {
    Button,
    ButtonGroup,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DashboardCard, DashboardSalesCard, ReservationsInsightCard } from "./components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import HelpIcon from "@mui/icons-material/Help";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InsightsIcon from "@mui/icons-material/Insights";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../../components/form";
import { NoRecordsFound } from "../../components/common";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { ClientsSearch } from "./components/home";
import otaIcons from "../../assests/channels";

const FrontdeskDashboard = () => {
    const { loading, fetch } = useApi();
    const [data, setData] = useState({ arrivals: [], departures: [], stayovers: [], rooms: [], reservations: [] });

    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "arrivals",
                    responseData:
                        "_id isBlockedRooms clientsData{_id firstName lastName} checkin checkout status totalPrice rooms{roomData{name}}",
                    data: { date: DateTime.now().toFormat("yyyy-LL-dd") },
                },
                {
                    endpoint: "departures",
                    responseData:
                        "_id isBlockedRooms clientsData{_id firstName lastName} checkin checkout status totalPrice  rooms{roomData{name}}",
                    data: { date: DateTime.now().toFormat("yyyy-LL-dd") },
                },
                {
                    endpoint: "stayovers",
                    responseData:
                        "_id isBlockedRooms clientsData{_id firstName lastName} checkin checkout status totalPrice rooms{roomId}",
                    data: { date: DateTime.now().toFormat("yyyy-LL-dd") },
                },
                {
                    endpoint: "rooms",
                    responseData: "_id",
                },
                {
                    endpoint: "reservations",
                    responseData:
                        "_id isBlockedRooms clientsData{_id firstName lastName} bookingSource referer checkin checkout status totalPrice",
                    data: {
                        startDate: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
                        endDate: DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"),
                    },
                },
            ],
        });
        if (response) {
            setData({
                ...data,
                arrivals: response.arrivals?.filter(
                    (arrival) => arrival.status !== "CANCELLED" && !arrival.isBlockedRooms
                ),
                departures: response.departures?.filter(
                    (departure) => departure.status !== "CANCELLED" && !departure.isBlockedRooms
                ),
                stayovers: response.stayovers?.filter(
                    (stayover) => stayover.status !== "CANCELLED" && !stayover.isBlockedRooms
                ),
                reservations: response.reservations
                    ?.filter((r) => !r.isBlockedRooms)
                    ?.sort((a, b) => {
                        const previousReservationDate = DateTime.fromISO(a._id.split("reservations_")[1]);
                        const nextReservationDate = DateTime.fromISO(b._id.split("reservations_")[1]);
                        if (previousReservationDate < nextReservationDate) return 1;
                        return -1;
                    }),
                rooms: response.rooms,
            });
        }
    };

    const blockedRooms = data?.stayovers?.map((stayover) => stayover.rooms.map((room) => room.roomId)).flat();
    const freeRooms = data?.rooms?.filter((room) => !blockedRooms.includes(room._id));

    const { t } = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const checkClientData = (clientData) => {
        if (!clientData?._id) {
            return t("client_missing");
        }
        return (clientData.firstName || "") + " " + (clientData.lastName || "");
    };
    const getRoomNames = (rooms) => {
        const firstRoomName = rooms[0]?.roomData?.name || "";
        const remainderRoomsNumber = rooms?.length - 1;
        if (remainderRoomsNumber === 0) return firstRoomName;
        return firstRoomName + ", " + `+${remainderRoomsNumber}`;
    };

    const greetingMessage = () => {
        const date = new Date();
        const hour = date.getHours();
        let greeting = t("welcome");

        if (hour < 12) {
            greeting = t("good_morning");
        } else if (hour >= 12 && hour < 17) {
            greeting = t("good_afternoon");
        } else {
            greeting = t("good_evening");
        }
        return greeting;
    };

    return (
        <div>
            <Grid container spacing={2} alignItems="stretch" style={{ marginBottom: 80 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard
                        title={greetingMessage()}
                        titleIcon={<SentimentSatisfiedAltIcon />}
                        // loading={loading}
                        skeletonVariant="circular"
                    >
                        <ClientsSearch />
                        <Typography my={2} variant="subtitle2">
                            {t("what_would_like_to_do")}
                        </Typography>
                        <ButtonGroup aria-label="text button group" fullWidth>
                            <Tooltip title={t("new_reservation")}>
                                <Button onClick={() => navigate("/newreservation")}>
                                    <AddCircleIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title={t("calendar")}>
                                <Button onClick={() => navigate("/calendar")}>
                                    <CalendarTodayIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title={t("pos")}>
                                <Button onClick={() => navigate("/pos")}>
                                    <PointOfSaleIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title={t("invoices")}>
                                <Button onClick={() => navigate("/finance/invoices")}>
                                    <DescriptionIcon />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <ReservationsInsightCard
                        arrivals={data?.arrivals?.length}
                        departures={data?.departures?.length}
                        stayovers={data?.stayovers?.length}
                        freeRooms={freeRooms?.length}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title={t("arrivals_today")}
                        titleIcon={<PersonPinIcon style={{ color: "rgb(255, 73, 73)" }} />}
                        action={
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => navigate("/arrivals", { state: "arrivals" })}
                            >
                                {t("view_all")}
                            </Button>
                        }
                        loading={loading}
                    >
                        <List>
                            {data?.arrivals?.length ? (
                                data?.arrivals?.map((arrival) => (
                                    <ListItem
                                        divider={<Divider />}
                                        onClick={() =>
                                            navigate(`/reservations/${arrival?._id.split("reservations_")[1]}`)
                                        }
                                        sx={{
                                            cursor: "pointer",
                                            backgroundColor: arrival.status === "CHECKIN" ? "#ADF0CC" : "",
                                        }}
                                    >
                                        <ListItemText>
                                            <Stack direction="row">
                                                <Grid item xs={4}>
                                                    {checkClientData(arrival?.clientsData?.[0])}
                                                </Grid>
                                                <Grid item xs={4} textAlign="center">
                                                    {arrival?.checkout}
                                                </Grid>
                                                <Grid item xs={4} textAlign="right">
                                                    {getRoomNames(arrival?.rooms)}
                                                </Grid>
                                            </Stack>
                                        </ListItemText>
                                    </ListItem>
                                ))
                            ) : (
                                <NoRecordsFound />
                            )}
                        </List>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title={t("departures_today")}
                        titleIcon={<PersonPinIcon style={{ color: "rgb(255, 200, 44)" }} />}
                        action={
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => navigate("/arrivals", { state: "departures" })}
                            >
                                {t("view_all")}
                            </Button>
                        }
                        loading={loading}
                    >
                        <List>
                            {data?.departures?.length ? (
                                data.departures.map((departure) => (
                                    <ListItem
                                        divider={<Divider />}
                                        onClick={() =>
                                            navigate(`/reservations/${departure?._id.split("reservations_")[1]}`)
                                        }
                                        sx={{
                                            cursor: "pointer",
                                            backgroundColor: departure.status === "CHECKOUT" ? "#ffab73" : "",
                                        }}
                                    >
                                        <ListItemText>
                                            <Stack direction="row" style={{}}>
                                                <Grid item xs={4}>
                                                    {checkClientData(departure?.clientsData?.[0])}
                                                </Grid>
                                                <Grid item xs={4} textAlign="center">
                                                    {departure?.checkin}
                                                </Grid>
                                                <Grid item xs={4} textAlign="right">
                                                    {getRoomNames(departure?.rooms)}
                                                </Grid>
                                            </Stack>
                                        </ListItemText>
                                    </ListItem>
                                ))
                            ) : (
                                <NoRecordsFound />
                            )}
                        </List>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title={t("recent_bookings")}
                        titleIcon={<PersonPinIcon />}
                        action={
                            <Button size="small" variant="outlined" onClick={() => navigate("/reservations")}>
                                {t("view_all")}
                            </Button>
                        }
                        loading={loading}
                    >
                        <List>
                            {data?.reservations?.length ? (
                                data?.reservations?.map((reservation) => (
                                    <ListItem
                                        divider={<Divider />}
                                        onClick={() =>
                                            navigate(`/reservations/${reservation?._id.split("reservations_")[1]}`)
                                        }
                                        sx={{
                                            cursor: "pointer",
                                            backgroundColor: reservation.status === "CANCELLED" ? "#F38BA0" : "",
                                        }}
                                    >
                                        <ListItemText>
                                            <Stack direction="row">
                                                <Grid item xs={8}>
                                                    {checkClientData(reservation?.clientsData?.[0])}
                                                </Grid>
                                            </Stack>
                                            <Stack direction="row">
                                                <Grid item xs={4} textAlign="left">
                                                    {reservation?.checkin}
                                                </Grid>
                                                <Grid item xs={4} textAlign="right">
                                                    {reservation?.checkout}
                                                </Grid>
                                                <Grid item xs={4} textAlign="right">
                                                    <img
                                                        height={20}
                                                        width={20}
                                                        src={`${
                                                            otaIcons[
                                                                reservation?.bookingSource === "channel_manager"
                                                                    ? reservation?.referer
                                                                    : reservation?.bookingSource
                                                            ]
                                                        }`}
                                                    />
                                                </Grid>
                                            </Stack>
                                        </ListItemText>
                                    </ListItem>
                                ))
                            ) : (
                                <NoRecordsFound />
                            )}
                        </List>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={8}>
                    <DashboardCard
                        title={t("latest_from_hotelbee")}
                        titleIcon={<HelpIcon style={{ color: "rgba(112, 34, 155, 0.87)" }} />}
                    >
                        <div className="row">
                            <div className="col-md-5">
                                <Typography variant="overline">{t("whats_new")}</Typography>
                                <Typography>{t("Improvements on calendar actions")}</Typography>
                                <Typography>{t("Improvements on reservationd modifications")}</Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className="col-md-6">
                                <Typography variant="overline">{t("help")}</Typography>
                                <a href="https://hotelbee.co/help/" target="_blank">
                                    <Typography variant="subtitle2">{t("our_guide")}</Typography>
                                </a>
                                {/* <Link
                                        href="https://hotelbee.co/help/sq/article/property/content/how-to-make-a-new-reservation/"
                                        target="_blank"
                                    >
                                        <Typography>{t("create_a_reservation")}</Typography>
                                    </Link>
                                    <Link
                                        href="https://hotelbee.co/help/sq/article/property/content/how-to-check-in-a-guest/"
                                        target="_blank"
                                    >
                                        <Typography>{t("checkin_guests")}</Typography>
                                    </Link> */}
                                <Typography variant="subtitle2" style={{ marginTop: 15 }}>
                                    {t("contact_our_support_team_using_the_chat")}
                                </Typography>
                            </div>
                        </div>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title={t("day_stats")}
                        titleIcon={<InsightsIcon style={{ color: "rgb(255, 73, 73)" }} />}

                        // loading={loading}
                    >
                        {/* <Typography variant="subtitle1">{t("arrived")}: - Rooms / - Guests</Typography>
                        <Typography variant="subtitle1">{t("departed")}: - Room / - Guests</Typography>
                        <Typography variant="subtitle1">{t("available_rooms")}: -</Typography>
                        <Typography variant="subtitle1">{t("ooo_rooms")}: -</Typography> */}
                    </DashboardCard>
                </Grid>
            </Grid>
        </div>
    );
};

export default FrontdeskDashboard;
