import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { AutoCompleteControl, Form, InputControl, SelectControl } from "../form";
import { Modal } from "../modal";
import { useApi } from "../hooks";
import { useTranslation } from "react-i18next";
import { ProtectedAction } from "../../utils";

const rules = { amount: "required", paymentMethod: "required" };

const EditPaymentsModal = ({ open, paymentData, onClose, onConfirm }) => {
    const [state, setState] = useState({
        values: {},
        disableConfirm: false,
    });
    if (paymentData?.amount) paymentData.amount = Math.round(paymentData?.amount * 100) / 100;
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    const { t } = useTranslation();
    const { fetch } = useApi();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "paymentmethods",
                    responseData: "_id name method fiscalMethod isActive",
                },
                {
                    endpoint: "users",
                    responseData: "_id username displayName status",
                },
            ],
        });
        if (!!response?.paymentmethods)
            setPaymentMethods(
                response.paymentmethods
                    ?.filter((p) => p?.isActive && p?.method !== "roomCharge")
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                        };
                    })
            );
        if (!!response?.users) {
            setUserOptions(
                response.users
                    ?.filter((user) => user.status === "ACTIVE")
                    .map((user) => {
                        return {
                            value: user._id,
                            label: !!user.displayName ? user.displayName : user.username,
                        };
                    })
            );
        }
    };
    useEffect(() => {
        if (!open) return;
        loadData();
    }, [open]);

    useEffect(() => {
        if (!open) return;
        setState({ disableConfirm: false, values: paymentData });
    }, [open]);

    return (
        <Modal
            titlelabel={t("add_payment")}
            maxWidth="xs"
            open={open}
            onClose={() => {
                setState({ disableConfirm: false, values: {} });
                onClose();
            }}
            FormProps={{
                values: state.values,
                rules,
                onValuesChange: (newValues) => setState({ ...state, values: newValues }),
                onSubmit: () => {
                    setState({ ...state, disableConfirm: true });
                    onConfirm(state.values);
                },
            }}
            yesDisabled={state.disableConfirm}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="amount" label={t("amount")} type="number" />
                    <SelectControl name="paymentMethod" label={t("payment_method")} options={paymentMethods} />
                    <InputControl name="note" label={t("note")} multiline minRows={2} />
                    <ProtectedAction permission={{ invoices: { correct: true } }}>
                        <AutoCompleteControl name="userName" label={t("user")} options={userOptions} />
                    </ProtectedAction>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default EditPaymentsModal;
