import { Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DateControl, TimeControl } from "../../../../components/dates";
import { InputControl, RadioGroupControl, SwitchControl } from "../../../../components/form";
import { CreateReservationContext } from "./CreateReservationContext";

const BlockRoomsStep = () => {
    const { t } = useTranslation();
    const blockRoomsOptions = [
        { value: "OUT_OF_ORDER", label: t("out_of_order") },
        { value: "ON_HOLD", label: t("on_hold") },
    ];

    const { values, setValues } = useContext(CreateReservationContext);

    return (
        <Card
            style={{
                transition: "all .7s",
                transitionDelay: ".1s",
                marginBottom: "10px",
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                maxHeight: 70,
                opacity: "100%",
                top: 0,
                zIndex: 0,
                ...(values?.addedClients?.length > 0 && {
                    maxHeight: 30,
                    opacity: "70%",
                    top: 20,
                    zIndex: -10,
                    width: "95%",
                }),
                ...(values.isBlockedRooms && {
                    maxHeight: 350,
                }),
            }}
        >
            <CardHeader
                title={
                    <SwitchControl
                        value={values?.isBlockedRooms}
                        onChange={(e) =>
                            setValues({
                                ...values,
                                isBlockedRooms: e.target.checked,
                                type: !!values?.type ? values?.type : "OUT_OF_ORDER",
                            })
                        }
                        label={t("block_rooms")}
                        labelPlacement="start"
                    />
                }
            />
            <CardContent>
                <Grid item xs={6}>
                    <RadioGroupControl
                        value={values?.type}
                        onChange={(e) => setValues({ ...values, type: e.target.value })}
                        options={blockRoomsOptions}
                        label={t("type")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputControl
                        value={values?.blockedName}
                        onChange={(e) => setValues({ ...values, blockedName: e.target.value })}
                        label={t("name")}
                    />
                </Grid>
                {values?.type === "OUT_OF_ORDER" ? (
                    <Grid item xs={6}>
                        <InputControl
                            label={t("reason")}
                            multiline
                            rows={3}
                            value={values?.blockedReason}
                            onChange={(e) => setValues({ ...values, blockedReason: e.target.value })}
                        />
                    </Grid>
                ) : (
                    <>
                        <Typography variant="body1" marginTop={2}>
                            {t("hold_till")}
                        </Typography>
                        <Divider />
                        <Stack marginTop={2} direction="row" gap={2}>
                            <DateControl
                                label={t("date")}
                                value={values?.blockedExpireDate}
                                onChange={(e) => setValues({ ...values, blockedExpireDate: e.target.value })}
                            />
                            <TimeControl
                                label={t("time")}
                                value={values?.blockedExpireTime}
                                onChange={(e) => setValues({ ...values, blockedExpireTime: e.target.value })}
                            />
                        </Stack>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default BlockRoomsStep;
