import { Button, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import NoteIcon from "@mui/icons-material/Note";
import SellIcon from "@mui/icons-material/Sell";
import TuneIcon from "@mui/icons-material/Tune";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AutoCompleteControl,
    Form,
    InputControl,
    SelectControl,
    SwitchControl,
    TagsAutoCompleteControl,
} from "../../../../components/form";
import { DiscountCard } from "./components";
import { ClientsSearch } from "../../../../components/common";
import { CreateReservationContext } from "./CreateReservationContext";
import { useApi } from "../../../../components/hooks";
import { DateTime } from "luxon";

const timeOptions = [
    { value: "12:00 AM - 1:00 AM", label: "12:00 AM - 1:00 AM" },
    { value: "1:00 AM - 2:00 AM", label: "1:00 AM - 2:00 AM" },
    { value: "2:00 AM - 3:00 AM", label: "2:00 AM - 3:00 AM" },
    { value: "3:00 AM - 4:00 AM", label: "3:00 AM - 4:00 AM" },
    { value: "4:00 AM - 5:00 AM", label: "4:00 AM - 5:00 AM" },
    { value: "5:00 AM - 6:00 AM", label: "5:00 AM - 6:00 AM" },
    { value: "6:00 AM - 7:00 AM", label: "6:00 AM - 7:00 AM" },
    { value: "7:00 AM - 8:00 AM", label: "7:00 AM - 8:00 AM" },
    { value: "8:00 AM - 9:00 AM", label: "8:00 AM - 9:00 AM" },
    { value: "9:00 AM - 10:00 AM", label: "9:00 AM - 10:00 AM" },
    { value: "10:00 AM - 11:00 AM", label: "10:00 AM - 11:00 AM" },
    { value: "11:00 AM - 12:00 PM", label: "11:00 AM - 12:00 PM" },
    { value: "12:00 PM - 1:00 PM", label: "12:00 PM - 1:00 PM" },
    { value: "1:00 PM - 2:00 PM", label: "1:00 PM - 2:00 PM" },
    { value: "2:00 PM - 3:00 PM", label: "2:00 PM - 3:00 PM" },
    { value: "3:00 PM - 4:00 PM", label: "3:00 PM - 4:00 PM" },
    { value: "4:00 PM - 5:00 PM", label: "4:00 PM - 5:00 PM" },
    { value: "5:00 PM - 6:00 PM", label: "5:00 PM - 6:00 PM" },
    { value: "6:00 PM - 7:00 PM", label: "6:00 PM - 7:00 PM" },
    { value: "7:00 PM - 8:00 PM", label: "7:00 PM - 8:00 PM" },
    { value: "8:00 PM - 9:00 PM", label: "8:00 PM - 9:00 PM" },
    { value: "9:00 PM - 10:00 PM", label: "9:00 PM - 10:00 PM" },
    { value: "10:00 PM - 11:00 PM", label: "10:00 PM - 11:00 PM" },
    { value: "11:00 PM - 12:00 AM", label: "11:00 PM - 12:00 AM" },
];
const discountOptions = [
    {
        value: "total",
        label: "total",
    },
    {
        value: "percentage",
        label: "percentage",
    },
];

const ExtraDetailsStep = () => {
    const { t } = useTranslation();
    const [groupInput, setGroupInput] = useState();
    const [eligibleDiscounts, setEligibleDiscounts] = useState([]);
    const [customField, setCustomField] = useState();
    const [tagOptions, setTagOptions] = useState([]);
    const { values, setValues, data, setData, dailyData } = useContext(CreateReservationContext);

    const { fetch } = useApi();

    useEffect(() => {
        if (data?.groups?.length > 0) return;
        getExistingGroups();
    }, []);
    useEffect(() => {
        getEligibleDiscounts();
    }, []);
    useEffect(() => {
        getTags();
    }, []);

    const clientRates = useMemo(() => {
        const selectedRoomtypes = data?.roomtypes?.filter((rt) => {
            if (values?.isSimpleReservation) return Boolean(values?.checkedRoomtypes?.[rt._id]);
            return Object.values(values?.checkedRooms || {}).some((room) => room && room?.roomtypeId === rt._id);
        });
        return (
            selectedRoomtypes
                ?.map((rt) => rt.rates)
                ?.flat()
                ?.filter((rate) => values.addedClients.some((client) => client._id === rate.availableTo))
                ?.map((rate) => {
                    const ratePrice = dailyData?.dailyPricing?.rates?.[rate._id]?.[values?.checkin]?.price;
                    rate.price = ratePrice || 0;
                    return rate;
                }) || []
        );
    }, []);

    const applyRate = (rate = {}) => {
        if (values?.isSimpleReservation) {
            rate?.roomTypes?.forEach((rt) => {
                const checkedRoomtypes = { ...(values?.checkedRoomtypes || {}) };
                if (checkedRoomtypes?.[rt]) {
                    checkedRoomtypes[rt].rate = rate._id;
                    setValues({ ...values, checkedRoomtypes });
                }
            });
            return;
        }
        const checkedRooms = { ...(values?.checkedRooms || {}) };
        Object.entries(checkedRooms).forEach(([roomId, room]) => {
            if (rate?.roomTypes?.includes(room.roomtypeId)) {
                checkedRooms[roomId].rate = rate._id;
            }
        });
        setValues({ ...values, checkedRooms });
    };

    const getExistingGroups = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "groups",
            responseData: "_id name",
        });
        if (response?.groups) {
            setData({
                ...data,
                groups: response.groups?.map((group) => ({ value: group._id, label: group.name })),
            });
        }
    };
    const getTags = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "tags",
            responseData: "_id name color description",
        });
        if (response?.tags) setTagOptions(response.tags.map((tag) => ({ value: tag._id, label: tag.name, data: tag })));
    };

    const getEligibleDiscounts = async () => {
        const existingClients = values?.addedClients?.filter(({ _id }) => !!_id);
        const response = await fetch({
            operation: "query",
            endpoint: "clientsRecords",
            data: {
                _ids: existingClients.map(({ _id }) => _id),
                resource: "reservations",
            },
            responseData: "reservations{_id checkin checkout totalPrice clients}",
        });

        const clientsReservationsInfo = {};
        if (response?.clientsRecords?.reservations) {
            response.clientsRecords.reservations.forEach((reservation) => {
                reservation.clients.forEach((client) => {
                    if (!clientsReservationsInfo[client]) {
                        clientsReservationsInfo[client] = {
                            total: 0,
                            nights: 0,
                            amount: 0.0,
                        };
                    }
                    clientsReservationsInfo[client].total += 1;
                    clientsReservationsInfo[client].nights += DateTime.fromISO(reservation.checkout)
                        .diff(DateTime.fromISO(reservation.checkin), "days")
                        .toObject().days;
                    clientsReservationsInfo[client].amount += reservation.totalPrice;
                });
            });
        }
        const clientsReservationsInfoAsArray = Object.values(clientsReservationsInfo);
        const eligibleDiscounts = data?.discounts?.filter(
            ({ minimalReservations = 0, minimalAmount = 0, minimalNight = 0 }) => {
                return clientsReservationsInfoAsArray.some(
                    ({ total, nights, amount }) =>
                        total >= minimalReservations && nights >= minimalNight && amount >= minimalAmount
                );
            }
        );
        setEligibleDiscounts(eligibleDiscounts || []);
    };

    const handleCloseDropdown = () => {
        const autocompleteInput = document.getElementById("group-autocomplete");
        if (autocompleteInput) {
            autocompleteInput.blur();
        }
    };

    return (
        <>
            <Card>
                <CardHeader avatar={<GroupsIcon />} title={t("group")} titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <SwitchControl
                        value={values?.isGroupReservation}
                        onChange={(e) => setValues({ ...values, isGroupReservation: e.target.checked })}
                        label={t("group_reservation")}
                    />
                    {values?.isGroupReservation && (
                        <>
                            {values?.groupName && (
                                <Typography fontStyle="italic">
                                    {t("new_group")}: {values?.groupName}
                                </Typography>
                            )}
                            <AutoCompleteControl
                                id={"group-autocomplete"}
                                value={values?.groupId}
                                options={data?.groups || []}
                                onChange={(e) => setValues({ ...values, groupId: e.target.value, groupName: null })}
                                onInputChange={(e, input) => setGroupInput(input)}
                                label={t("group")}
                                noOptionsText={
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setValues({ ...values, groupName: groupInput, groupId: null });
                                            handleCloseDropdown();
                                        }}
                                    >
                                        {t("create_new")} {groupInput}
                                    </Button>
                                }
                            />
                        </>
                    )}
                    <ClientsSearch
                        placeholder={t("search_referrer")}
                        onlyPartners
                        onClientSelect={(data) => {
                            setValues({ ...values, referer: { _id: data._id, name: data.firstName } });
                        }}
                    />
                    {values?.referer && (
                        <Typography>
                            {t("referer")}: {values?.referer?.name}
                        </Typography>
                    )}
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 2 }}>
                <CardHeader avatar={<NoteIcon />} title={t("note")} titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <InputControl
                        value={values?.note}
                        onChange={(e) => setValues({ ...values, note: e.target.value })}
                        label={t("note")}
                        multiline={true}
                        minRows={3}
                    />
                </CardContent>
            </Card>

            <Card sx={{ marginTop: 2 }}>
                <CardHeader
                    avatar={<LocalOfferIcon />}
                    title={t("discount")}
                    titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                    <Form
                        values={!!values?.discount ? values?.discount : {}}
                        onValuesChange={(newDiscount) => setValues({ ...values, discount: newDiscount })}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <SelectControl name="type" options={discountOptions} label={t("discount")} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="value" label={t("number")} type="number" />
                            </Grid>
                        </Grid>
                    </Form>
                </CardContent>
                <CardContent>
                    <Typography variant="subtitle1">{t("eligible_discount")}</Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    {eligibleDiscounts.length > 0 ? (
                        eligibleDiscounts.map((discount) => (
                            <DiscountCard
                                title={discount.name}
                                text={discount.discountValue + ((discount.discountType === "percentage" && "%") || "")}
                                titleIcon={<DiscountOutlinedIcon />}
                                isSelected={values?.extraDiscount === discount._id}
                                onClick={() =>
                                    setValues({
                                        ...values,
                                        discount: { type: discount.discountType, value: discount.discountValue },
                                        extraDiscount: discount._id,
                                    })
                                }
                                sx={{ marginRight: 2 }}
                            />
                        ))
                    ) : (
                        <Typography>{t("no_discounts_avaiable")}</Typography>
                    )}
                </CardContent>
                <CardContent>
                    <Typography variant="subtitle1">{t("eligible_rates")}</Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    {clientRates.length === 0 ? (
                        <Typography>{t("no_custom_rates_available")}</Typography>
                    ) : (
                        clientRates.map((r) => (
                            <DiscountCard
                                title={r.name}
                                text={r.price}
                                titleIcon={<PriceChangeOutlinedIcon />}
                                // isSelected={r.isSelected}
                                onClick={() => applyRate(r)}
                            />
                        ))
                    )}
                </CardContent>
            </Card>

            <Card sx={{ marginTop: 2 }}>
                <CardHeader
                    avatar={<AccessTimeIcon />}
                    title={t("arrival_and_departure_time")}
                    titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <SelectControl
                                value={values?.arrivalTime || ""}
                                onChange={(e) => setValues({ ...values, arrivalTime: e.target.value })}
                                options={timeOptions}
                                label={t("arrival_time")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectControl
                                value={values?.departureTime || ""}
                                onChange={(e) => setValues({ ...values, departureTime: e.target.value })}
                                options={timeOptions}
                                label={t("departure_time")}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 2 }}>
                <CardHeader avatar={<SellIcon />} title={t("tags")} titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <TagsAutoCompleteControl
                        multiple
                        options={tagOptions}
                        size="large"
                        value={values?.tags}
                        onChange={(e) => setValues({ ...values, tags: e.target.value })}
                    />
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 2 }}>
                <CardHeader avatar={<TuneIcon />} title={t("custom_fields")} titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <Stack direction="row" gap={2} justifyContent="start" alignItems="center">
                        {data.reservationCustomDetails?.map((field, index) => (
                            <>
                                <InputControl
                                    label={t(field)}
                                    onChange={(e) => {
                                        setValues({
                                            ...values,
                                            reservationCustomDetails: {
                                                ...values.reservationCustomDetails,
                                                [field]: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </>
                        ))}
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};

export default ExtraDetailsStep;
