import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const ClientList = () => {
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const params = useParams();

    const columns = [
        {
            accessorKey: "name",
            header: t("name"),
            accessorFn: (row) => {
                return (row?.firstName || "") + " " + (row?.lastName || "");
            },
        },
        { accessorKey: "clientType", header: t("type") },
        { accessorKey: "email", header: t("email") },
        { accessorKey: "phone", header: t("phone") },
        { accessorKey: "addressLine", header: t("address") },
    ];
    useEffect(() => {
        if (!!params.id || !!params.clientId) return; //Skip fetching data when single client page is rendered
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "clients",
            responseData: "_id firstName lastName clientType email phone addressLine",
        });
        if (response?.clients) setClients(response.clients);
    };
    const addRecord = (data) => {
        if (clients?.some((r) => r._id === data._id)) {
            setClients(
                clients?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setClients([...clients, data]);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteClients" : "deleteClient",
            data,
        });
        if (response?.deleteClients === "success" || response?.deleteClient === "success") {
            setClients(clients.filter((client) => !rows.some((row) => row._id === client._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("clients_form")}
                moduleType="frontdesk"
                data={clients}
                columns={columns}
                onRowClick={(row) => navigate(`profile/${row?._id?.split("clients_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecord }} />
        </div>
    );
};

export default ClientList;
