import React from "react";
import { useTranslation } from "react-i18next";
import { InputControl, SelectControl } from "../../../../components/form";
import { EditableTable } from "../../../../components/table";

const EditableWarehousesTable = ({ warehouses = [], data = [], onChange }) => {
    const { t } = useTranslation();
    const columns = [
        {
            header: t("warehouse"),
            cell: ({ rowIndex, row }) => (
                <SelectControl
                    disabled={!onChange}
                    value={row?.warehouseId || ""}
                    onChange={(e) => {
                        data[rowIndex] = { warehouseId: e.target.value };
                        onChange && onChange(data);
                    }}
                    options={warehouses}
                    label={t("warehouse")}
                />
            ),
        },
        {
            header: t("quantity"),
            cell: ({ rowIndex, row }) => (
                <InputControl
                    disabled={!onChange}
                    type="number"
                    value={row?.quantity ? Math.round(row?.quantity * 100) / 100 : ""}
                    onChange={(e) => {
                        if (!data[rowIndex]) data[rowIndex] = {};
                        const quantity = parseFloat(e.target.value);
                        data[rowIndex].quantity = isNaN(quantity) ? 0 : quantity;
                        onChange && onChange(data);
                    }}
                    label={t("quantity")}
                />
            ),
        },
    ];
    return (
        <EditableTable
            columns={columns}
            data={data}
            onAddRow={() => {
                onChange && onChange([...data, {}]);
            }}
            onDeleteRow={(rowIndex) => {
                onChange && onChange(data.filter((r, index) => index !== rowIndex));
            }}
        />
    );
};

export default EditableWarehousesTable;
