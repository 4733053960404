import {
    validateBankAccount,
    validateExistingFiscalData,
    validateFiscalBusinessDetails,
    validateInvoicePeriod,
    validateItemDetails,
    validateSellerDetails,
} from "./validate";
import { calculateItemPrice, generateCountryCode } from "./utils";
import { calculateTotalTax, exchangeCurrency, getLoggedUserData, Number } from "../../../utils";
import { DateTime } from "luxon";
import { paymentMeansCodeOptions } from "../../../modules/invoices/components/fiscalOptions";

const generateFiscalInvoiceBody = ({
    hasEInvoice = false,
    IsReverseCharge = false,
    typeOfSelfIss,
    buyerData,
    items,
    fiscalCurrency,
    invoiceCurrency,
    generateForEInvoice = false,
    isFiscalCurrencyRateSpecified = false,
    fiscalCurrencyRate,
    discount,
    totalPrice,
    paymentMethod,
    payments = [],
    cancelled,
    existingFiscalData,
    invoicePeriod,
    operatorCode,
    settingsproperty,
    currencies = [],
    taxes = [],
    paymentmethods = [],
    businessCashdesk,
    SumInvIICRefs,
}) => {
    const { fiscalBusiness, tcrCode } = businessCashdesk || {};

    validateFiscalBusinessDetails(fiscalBusiness);

    const InvoiceBody = {
        BusinUnitCode: fiscalBusiness.businessUnitCode,
        InvOrdNum: null,
        InvNum: null,
        IsEinvoice: Boolean(hasEInvoice),
        IsIssuerInVAT: Boolean(fiscalBusiness.withVat),
        IsReverseCharge: Boolean(IsReverseCharge),
        IsSimplifiedInv: !Boolean(fiscalBusiness.withVat),
        IIC: null,
        IICSignature: null,
        OperatorCode: operatorCode,
        PayDeadlineSpecified: false,
        TCRCode: tcrCode,
        TotPrice: null,
        TotPriceWoVAT: null,
        TypeOfInv: null,
        Items: [],
        PayMethods: [],
        Seller: null,
    };

    const Seller = {
        Address: settingsproperty.address,
        Country: "ALB",
        CountrySpecified: true,
        IDNum: fiscalBusiness.nipt.toUpperCase(),
        IDType: "NUIS",
        Name: settingsproperty.name,
        Town: settingsproperty.city,
    };
    validateSellerDetails(Seller);
    let Buyer;
    if (buyerData) {
        const buyerCountry = generateCountryCode(buyerData.country, "ALPHA3");
        let buyerName;
        if (!!buyerData?.firstName) {
            buyerName = buyerData?.firstName;
            if (!!buyerData.lastName) {
                buyerName += " " + buyerData.lastName;
            }
        } else {
            buyerName = buyerData?.name;
        }
        if (buyerCountry && buyerName) {
            Buyer = {
                Address: !!buyerData.addressLine ? buyerData.addressLine : null,
                Country: buyerCountry || "ALB",
                CountrySpecified: true,
                IDNum: buyerData.IDNumber,
                Name: buyerName,
                Town: !!buyerData.city ? buyerData.city : null,
            };
            if (!!Buyer.IDNum) {
                Buyer.IDType = !!buyerData.IDType ? buyerData.IDType : "NUIS";
            } else {
                Buyer.IDNum = "00000";
                Buyer.IDType = "ID";
            }
            validateSellerDetails(Buyer);
        }
    }

    if (!!typeOfSelfIss) {
        if (!Buyer) {
            throw new Error("buyer_must_be_provided_on_selfissue");
        }
        InvoiceBody.Seller = Buyer;
        InvoiceBody.Buyer = Seller;
        InvoiceBody.TypeOfSelfIss = typeOfSelfIss;
    } else {
        InvoiceBody.Seller = Seller;
        if (Buyer) {
            InvoiceBody.Buyer = Buyer;
        }
    }
    InvoiceBody.Items = items
        ?.filter((item) => {
            const itemPrice = parseFloat(item.price);
            if (isNaN(itemPrice)) return false;
            if (itemPrice === 0) return false;

            const itemQuantity = parseFloat(item.quantity);
            if (isNaN(itemQuantity)) return false;
            if (itemQuantity <= 0) return false;

            return true;
        })
        ?.map((item, index) => {
            validateItemDetails(item);
            let itemPrice = calculateItemPrice(item.price, {
                fiscalCurrency: fiscalCurrency,
                invoiceCurrency: invoiceCurrency,
                generateForEInvoice: generateForEInvoice,
                isFiscalCurrencyRateSpecified: isFiscalCurrencyRateSpecified,
                fiscalCurrencyRate: fiscalCurrencyRate,
                currencies: currencies,
            });

            let rate;
            let excludedReason;

            let taxAmount = new Number(0); // item total tax
            let UPB = new Number(itemPrice.toFixed(2)); // item price without tax

            const itemTaxes = !!InvoiceBody.IsIssuerInVAT ? item.taxData : [];

            itemTaxes?.forEach((itemTax) => {
                const taxDetails = taxes.find((tax) => tax._id === itemTax?.taxId);
                if (!taxDetails) throw new Error("item_tax_not_found");

                if (taxDetails.isTaxExcluded && !!taxDetails.excludedReason) {
                    excludedReason = taxDetails.excludedReason;
                }
                //TODO:  update for cases when item has multiple taxes applied with different rates
                rate = taxDetails.rate;

                const { totalAmount } = calculateTotalTax({
                    taxData: [{ isIncluded: Boolean(itemTax.isIncluded), tax: taxDetails }],
                    price: itemPrice,
                });

                taxAmount = taxAmount.plus(totalAmount);
                if (Boolean(itemTax.isIncluded)) {
                    UPB = new Number(UPB.toFixed(2)).minus(totalAmount.toFixed(2));
                }
            });

            let UPA = new Number(UPB.toFixed(2)).plus(taxAmount.toFixed(2));
            let PA = new Number(UPA.toFixed(2)).multipliedBy(item.quantity);

            let R;
            if (!!discount?.type && !!discount?.value) {
                let discountAmount = new Number(discount.value);
                if (discount.type === "total") {
                    discountAmount = new Number(discount.value)
                        .dividedBy(new Number(totalPrice).plus(discount.value))
                        .multipliedBy(0.01);
                }
                PA = PA.minus(PA.multipliedBy(discountAmount.toFixed(2)).multipliedBy(0.01));
                R = discountAmount.toNumber();
            }

            const fiscalItemData = {
                C: index.toString(),
                N: item.name,
                PA: PA.toFixed(2),
                PB: new Number(UPB.toFixed(2)).multipliedBy(item.quantity).toFixed(2),
                Q: new Number(item.quantity).toFixed(2),
                U: !!item.unit ? item.unit : "cope",
                UPA: UPA.toFixed(2),
                UPB: UPB.toFixed(2),
            };

            if (taxAmount.toNumber() > 0) {
                fiscalItemData.VA = new Number(taxAmount.toFixed(2)).multipliedBy(item.quantity).toFixed(2);
                fiscalItemData.VASpecified = true;
                if (rate) {
                    fiscalItemData.VR = rate;
                    fiscalItemData.VRSpecified = true;
                }
            } else {
                fiscalItemData.EXSpecified = true;
                fiscalItemData.EX = excludedReason || "TAX_FREE";
            }
            if (R) {
                fiscalItemData.R = R.toFixed(2);
                fiscalItemData.RR = true;
                fiscalItemData.RSpecified = true;
                fiscalItemData.RRSpecified = true;
            }
            return fiscalItemData;
        });

    InvoiceBody.TotPrice = InvoiceBody.Items.reduce((total, item) => {
        return total.plus(item.PA);
    }, new Number(0)).toFixed(2);
    InvoiceBody.TotPriceWoVAT = InvoiceBody.Items.reduce((total, item) => {
        return total.plus(item.PB);
    }, new Number(0)).toFixed(2);

    if (InvoiceBody.IsIssuerInVAT) {
        InvoiceBody.SameTaxes = InvoiceBody.Items.filter((item) => {
            if (item.VRSpecified) return true;
            if (!!item.EX && ["TYPE_1", "TYPE_2", "TAX_FREE"].includes(item.EX)) {
                return true;
            }
            return false;
        })
            .reduce((acc, item) => {
                const addedTaxIndex = item.VRSpecified
                    ? acc.findIndex((tax) => tax.VATRate === item.VR)
                    : acc.findIndex((tax) => tax.ExemptFromVAT === item.excludedReason);

                if (addedTaxIndex < 0) {
                    const sameTaxObject = {
                        NumOfItems: 1,
                        PriceBefVAT: !!item.EX ? new Number(0) : new Number(item.PB),
                        VATAmt: !!item.VA ? new Number(item.VA) : new Number(0),
                        VATAmtSpecified: true,
                        VATRate: item.VR || 0,
                        VATRateSpecified: true,
                    };
                    if (!!item.EX) {
                        sameTaxObject.ExemptFromVAT = item.EX;
                        sameTaxObject.ExemptFromVATSpecified = true;
                    }
                    acc.push(sameTaxObject);
                    return acc;
                }
                acc[addedTaxIndex].NumOfItems += 1;
                acc[addedTaxIndex].PriceBefVAT = acc[addedTaxIndex].PriceBefVAT.plus(
                    !!item.EX ? new Number(0) : new Number(item.PB)
                );
                acc[addedTaxIndex].VATAmt = acc[addedTaxIndex].VATAmt.plus(
                    !!item.VA ? new Number(item.VA) : new Number(0)
                );
                return acc;
            }, [])
            .map((sameTaxObject) => {
                return {
                    ...sameTaxObject,
                    PriceBefVAT: sameTaxObject.PriceBefVAT.toFixed(2),
                    VATAmt: sameTaxObject.VATAmt.toFixed(2),
                    VATRate: sameTaxObject.VATRate.toFixed(2),
                };
            });
        InvoiceBody.TotVATAmt = InvoiceBody.SameTaxes.reduce((total, sameTaxObject) => {
            return total.plus(sameTaxObject.VATAmt);
        }, new Number(0)).toFixed(2);
        InvoiceBody.TotVATAmtSpecified = true;
    }
    if (
        !InvoiceBody.IsIssuerInVAT ||
        InvoiceBody.SameTaxes?.some((sameTaxObject) => sameTaxObject.ExemptFromVATSpecified)
    ) {
        InvoiceBody.TaxFreeAmt = new Number(InvoiceBody.TotPrice).minus(InvoiceBody.TotVATAmt || 0.0).toFixed(2);
        InvoiceBody.TaxFreeAmtSpecified = true;
    }

    if (payments?.length > 0) {
        const paymentsByFiscalMethods = {};
        payments.forEach((payment) => {
            let { fiscalMethod } = paymentmethods.find((doc) => doc._id === payment.paymentMethod) || {};
            if (!fiscalMethod) {
                throw new Error("payment_method_invalid_at_payments");
            }
            if (fiscalMethod.toUpperCase() === "ROOMCHARGE") {
                fiscalMethod = "ACCOUNT";
            }
            if (!paymentsByFiscalMethods[fiscalMethod]) {
                paymentsByFiscalMethods[fiscalMethod] = {
                    Amt: new Number(0),
                    Type: fiscalMethod.toUpperCase(),
                };
            }
            const amount = calculateItemPrice(payment.amount, {
                fiscalCurrency: fiscalCurrency,
                invoiceCurrency: invoiceCurrency,
                generateForEInvoice: generateForEInvoice,
                isFiscalCurrencyRateSpecified: isFiscalCurrencyRateSpecified,
                fiscalCurrencyRate: fiscalCurrencyRate,
                currencies: currencies,
            });
            paymentsByFiscalMethods[fiscalMethod].Amt = paymentsByFiscalMethods[fiscalMethod].Amt.plus(amount);
        });
        let totalUnPaidAmount = new Number(InvoiceBody.TotPrice);
        InvoiceBody.PayMethods = Object.values(paymentsByFiscalMethods).map((payment) => {
            totalUnPaidAmount = totalUnPaidAmount.minus(payment.Amt.toFixed(2));
            return { ...payment, Amt: payment.Amt.toFixed(2) };
        });
        if (!totalUnPaidAmount.isEqualTo(0)) {
            InvoiceBody.PayMethods[0].Amt = new Number(InvoiceBody.PayMethods[0].Amt)
                .plus(totalUnPaidAmount)
                .toFixed(2);
        }
    } else {
        let method;
        if (paymentMethod === "ORDER") {
            method = paymentMethod;
        } else {
            const { fiscalMethod } = paymentmethods.find((doc) => doc._id === paymentMethod) || {};
            method = fiscalMethod;
        }
        if (!method) {
            throw new Error("no_payment_method_provided");
        }
        InvoiceBody.PayMethods = [
            {
                Amt: InvoiceBody.TotPrice,
                Type: method.toUpperCase(),
            },
        ];
    }

    InvoiceBody.TypeOfInv = InvoiceBody.PayMethods.every((payment) => {
        return ["BANKNOTE", "CARD", "CHECK", "SVOUCHER", "COMPANY", "ORDER", "CASH"].includes(payment.Type);
    })
        ? "CASH"
        : "NONCASH";

    /**
     * To send a corrective invoice when cancelled negative required price fields
     */
    if (cancelled) {
        InvoiceBody.Items?.forEach((item) => {
            item.PA = "-" + item.PA;
            item.PB = "-" + item.PB;
            item.Q = "-" + item.Q;
            if (item.VA) item.VA = "-" + item.VA;
        });
        if (InvoiceBody.SameTaxes) {
            InvoiceBody.SameTaxes.forEach((tax) => {
                tax.PriceBefVAT = "-" + tax.PriceBefVAT;
                tax.VATAmt = "-" + tax.VATAmt;
            });
        }
        InvoiceBody.TotPrice = "-" + InvoiceBody.TotPrice;
        InvoiceBody.TotPriceWoVAT = "-" + InvoiceBody.TotPriceWoVAT;
        if (InvoiceBody.TotVATAmt) InvoiceBody.TotVATAmt = "-" + InvoiceBody.TotVATAmt;
        if (InvoiceBody.TaxFreeAmt) InvoiceBody.TaxFreeAmt = "-" + InvoiceBody.TaxFreeAmt;

        if (InvoiceBody.PayMethods) {
            InvoiceBody.PayMethods?.forEach((method) => {
                method.Amt = "-" + method.Amt;
            });
        }
        validateExistingFiscalData(existingFiscalData);
        InvoiceBody.CorrectiveInv = {
            IICRef: existingFiscalData.IICRef,
            IssueDateTime: existingFiscalData.IssueDateTime,
            Type: "CORRECTIVE",
        };
    }

    if (!!invoicePeriod) {
        validateInvoicePeriod(invoicePeriod);
        InvoiceBody.InvoicePeriod = {
            StartDate: invoicePeriod.date,
            StartDateSpecified: true,
            EndDate: invoicePeriod.dueDate,
            EndDateSpecified: true,
        };
    }

    if (InvoiceBody?.SameTaxes?.some((tax) => tax.ExemptFromVAT === "TAX_FREE")) {
        InvoiceBody.SameTaxes = undefined;
    }

    if (fiscalCurrency !== "ALL") {
        InvoiceBody.Currency = {
            Code: fiscalCurrency,
            ExRate: Boolean(isFiscalCurrencyRateSpecified)
                ? parseFloat(fiscalCurrencyRate)
                : exchangeCurrency({
                      amount: 1,
                      currencies: currencies,
                      fromCurrency: fiscalCurrency,
                      toCurrency: "ALL",
                  }),
            IsBuyingSpecified: false,
        };
    }

    if (SumInvIICRefs && Array.isArray(SumInvIICRefs)) InvoiceBody.SumInvIICRefs = SumInvIICRefs;

    return InvoiceBody;
};

export const sendFiscalInvoice = async ({
    fetch,
    fiscalBusinessId,
    hasEInvoice = false,
    IsReverseCharge = false,
    typeOfSelfIss,
    buyerData,
    items = [],
    fiscalCurrency,
    invoiceCurrency,
    isFiscalCurrencyRateSpecified = false,
    fiscalCurrencyRate,
    discount,
    totalPrice,
    paymentMethod,
    payments = [],
    cancelled = false,
    existingFiscalData,
    invoicePeriod,
    profileID,
    invoiceTypeCode,
    dueDate,
    paymentMeansCode,
    account,
    SumInvIICRefs,
}) => {
    if (!invoiceCurrency) invoiceCurrency = "ALL";
    if (!fiscalCurrency) fiscalCurrency = "ALL";
    if (invoiceCurrency === "ALL") fiscalCurrency = "ALL";
    if (!["ALL", invoiceCurrency].includes(fiscalCurrency)) {
        fiscalCurrency = "ALL";
    }

    const loggedUsername = getLoggedUserData()?.username;
    const response = await fetch({
        operation: "query",
        multipleEndpoints: [
            {
                endpoint: "businessCashdesk",
                data: {
                    fiscalBusinessId: fiscalBusinessId,
                },
                responseData: "tcrCode fiscalBusiness{businessUnitCode password withVat nipt businessName certificate}",
            },
            {
                endpoint: "user",
                data: {
                    _id: "users_" + loggedUsername,
                },
                responseData: "_id operatorCode",
            },
            {
                endpoint: "taxes",
                responseData: "_id name excludedReason rate isTaxExcluded isVat taxCategory",
            },
            {
                endpoint: "currencies",
                responseData: "currency rate",
            },
            {
                endpoint: "settingsproperty",
                data: {
                    _id: "settings_property",
                },
                responseData: "name address city",
            },
            {
                endpoint: "paymentaccounts",
                responseData: "_id name bank accountId swift",
            },
            {
                endpoint: "paymentmethods",
                responseData: "_id name fiscalMethod method",
            },
        ],
    });
    const {
        businessCashdesk = {},
        taxes = [],
        currencies = [],
        paymentaccounts = [],
        paymentmethods = [],
        settingsproperty = {},
        user = {},
    } = response || {};

    if (!businessCashdesk || !businessCashdesk.tcrCode) {
        throw new Error("Fiscal business not found");
    }

    if (!response?.user?.operatorCode) {
        throw new Error("Missing operator code from logged user");
    }

    if (hasEInvoice) {
        if (!(!!buyerData?.firstName && !!buyerData.country && !!buyerData.IDNumber)) {
            throw new Error("EInvoice require buyer details");
        }
        const paymentMeansData = paymentMeansCodeOptions.find(
            (paymentMeans) => paymentMeans.value === paymentMeansCode
        );
        if (!paymentMeansData) throw new Error("payment_means_code_invalid");
    }

    const invoiceValues = {
        hasEInvoice: Boolean(hasEInvoice),
        IsReverseCharge: Boolean(IsReverseCharge),
        typeOfSelfIss: typeOfSelfIss,
        buyerData: buyerData,
        items: items,
        fiscalCurrency: fiscalCurrency,
        invoiceCurrency: invoiceCurrency,
        generateForEInvoice: false,
        isFiscalCurrencyRateSpecified: Boolean(isFiscalCurrencyRateSpecified),
        fiscalCurrencyRate: fiscalCurrencyRate,
        discount: discount,
        totalPrice: totalPrice,
        paymentMethod: paymentMethod,
        payments: payments,
        cancelled,
        existingFiscalData,
        invoicePeriod,
        operatorCode: user.operatorCode,
        settingsproperty: settingsproperty,
        currencies: currencies,
        taxes: taxes,
        paymentmethods: paymentmethods,
        businessCashdesk: businessCashdesk,
        SumInvIICRefs,
    };

    const InvoiceBody = generateFiscalInvoiceBody(invoiceValues);
    console.log(InvoiceBody);
    // return;
    let fiscalResponseData;
    let IICSignature;
    let uuid;
    try {
        const fiscalResponse = await fetch({
            operation: "mutation",
            endpoint: "sendFiscalInvoice",
            data: {
                fiscalInvoice: InvoiceBody,
                certificateData: businessCashdesk.fiscalBusiness?.certificate[0]?.split(";base64,")[1],
                certificatePassword: businessCashdesk.fiscalBusiness?.password,
            },
            responseData: `
                IICRef IssueDateTime InvOrdNum TCR operator nivf nslf nipt BusinUnitCode IICSignature uuid
                SameTaxes{ 
                    NumOfItems
                    PriceBefVAT
                    VATAmt
                    VATAmtSpecified
                    VATRate
                }
            `,
        });
        const { IICRef, IssueDateTime, InvOrdNum, TCR, operator, nivf, nslf, nipt, BusinUnitCode, SameTaxes } =
            fiscalResponse?.sendFiscalInvoice || {};
        if (!nivf) throw new Error("invoice_sent_not_finalized");
        fiscalResponseData = {
            IICRef: IICRef,
            IssueDateTime: IssueDateTime,
            InvOrdNum: InvOrdNum,
            TCR: TCR,
            operator: operator,
            nivf: nivf,
            nslf: nslf,
            nipt: nipt,
            BusinUnitCode: BusinUnitCode,
            SameTaxes: SameTaxes,
        };
        IICSignature = fiscalResponse?.sendFiscalInvoice?.IICSignature;
        uuid = fiscalResponse?.sendFiscalInvoice?.uuid;
    } catch (error) {
        console.log(error);
        throw new Error("finalize_failed");
    }
    if (!hasEInvoice) {
        return fiscalResponseData;
    }
    try {
        const InvoiceBodyForEInvoice = generateFiscalInvoiceBody({ ...invoiceValues, generateForEInvoice: true });
        const eInvoiceValues = {
            profileID: profileID,
            invoiceTypeCode: invoiceTypeCode,
            dueDate: dueDate,
            eInvoiceCurrency: fiscalCurrency,
            paymentMeansCode: paymentMeansCode,
            account: account,
            InvoiceBody: InvoiceBodyForEInvoice,
            FiscalInvoice: { ...fiscalResponseData, IICSignature, uuid },
            fiscalBusiness: businessCashdesk.fiscalBusiness,
            paymentaccounts: paymentaccounts,
        };
        const EInvoiceBody = generateEInvoiceBody(eInvoiceValues);
        console.log(EInvoiceBody);
        const eInvoiceResponse = await fetch({
            operation: "mutation",
            endpoint: "sendEInvoice",
            data: {
                eInvoice: EInvoiceBody,
                certificateData: businessCashdesk.fiscalBusiness?.certificate[0]?.split(";base64,")[1],
                certificatePassword: businessCashdesk.fiscalBusiness?.password,
            },
            responseData: `eic`,
        });
        if (eInvoiceResponse?.sendEInvoice?.eic) {
            fiscalResponseData.eic = eInvoiceResponse?.sendEInvoice?.eic;
        }
    } catch (error) {
        console.log(error);
    }
    return fiscalResponseData;
};

const generateEInvoiceBody = ({
    profileID,
    invoiceTypeCode,
    dueDate,
    eInvoiceCurrency,
    paymentMeansCode,
    account,
    InvoiceBody,
    FiscalInvoice,
    fiscalBusiness,
    paymentaccounts,
}) => {
    const einvoiceCode = "#AAI#";
    const buyerCountryCode = generateCountryCode(InvoiceBody?.Buyer?.Country, "ALPHA2");
    const paymentMeansData = paymentMeansCodeOptions.find((paymentMeans) => paymentMeans.value === paymentMeansCode);
    if (!paymentMeansData) throw new Error("payment_means_code_invalid");

    const EInvoiceBody = {
        UblInvoice: {
            ProfileID: InvoiceBody.CorrectiveInv && InvoiceBody.CorrectiveInv === "CORRECTIVE" ? "P10" : profileID,
            ID: `${FiscalInvoice.InvOrdNum}/${DateTime.now().toFormat("yyyy")}`,
            IssueDate: `${new Date().toJSON()}`,
            DueDate: `${new Date(dueDate).toJSON()}`,
            InvoiceTypeCode:
                InvoiceBody.CorrectiveInv && InvoiceBody.CorrectiveInv === "CORRECTIVE" ? "384" : invoiceTypeCode,
            Note: [
                `IIC=${FiscalInvoice.IICRef}${einvoiceCode}`,
                `IICSignature=${FiscalInvoice.IICSignature}${einvoiceCode}`,
                `FIC=${FiscalInvoice.nivf}${einvoiceCode}`,
                `IssueDateTime=${DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ssZZ")}${einvoiceCode}`,
                `UUI=${FiscalInvoice.uuid}${einvoiceCode}`,
                `Currency=${eInvoiceCurrency}${einvoiceCode}`,
                `OperatorCode=${InvoiceBody.OperatorCode}${einvoiceCode}`,
                `BusinessUnitCode=${InvoiceBody.BusinUnitCode}${einvoiceCode}`,
                `SoftwareCode=hu644ob082${einvoiceCode}`,
            ],
            DocumentCurrencyCode: eInvoiceCurrency,
            AccountingSupplierParty: {
                Party: {
                    EndpointID: {
                        Text: fiscalBusiness?.nipt,
                    },
                    PartyName: {
                        Name: fiscalBusiness.businessName,
                    },
                    PostalAddress: {
                        StreetName: InvoiceBody?.Seller?.Address,
                        CityName: InvoiceBody?.Seller?.Town,
                        Country: {
                            IdentificationCode: "AL",
                        },
                    },
                    PartyTaxScheme: {
                        CompanyID: `AL${fiscalBusiness?.nipt}`,
                        TaxScheme: {
                            ID: "VAT",
                        },
                    },
                    PartyLegalEntity: {
                        RegistrationName: fiscalBusiness.businessName,
                    },
                },
            },
            AccountingCustomerParty: {
                Party: {
                    EndpointID: {
                        Text: InvoiceBody?.Buyer?.IDNum,
                    },
                    PartyName: {
                        Name: InvoiceBody?.Buyer?.Name,
                    },
                    PostalAddress: {
                        StreetName: InvoiceBody?.Buyer?.Address,
                        CityName: InvoiceBody?.Buyer?.Town,
                        Country: {
                            IdentificationCode: buyerCountryCode,
                        },
                    },
                    PartyTaxScheme: {
                        CompanyID: buyerCountryCode + InvoiceBody?.Buyer?.IDNum,
                        TaxScheme: {
                            ID: "VAT",
                        },
                    },
                    PartyLegalEntity: {
                        RegistrationName: InvoiceBody?.Buyer?.Name,
                        CompanyID: InvoiceBody?.Buyer?.IDNum,
                    },
                },
            },
            PaymentMeans: {
                PaymentMeansCode: paymentMeansData.value,
                InstructionNote: paymentMeansData.label,
            },
            LegalMonetaryTotal: {
                LineExtensionAmount: {
                    currencyID: eInvoiceCurrency,
                    Text: InvoiceBody.TotPrice,
                },
                TaxExclusiveAmount: {
                    currencyID: eInvoiceCurrency,
                    Text: InvoiceBody.TotPrice,
                },
                TaxInclusiveAmount: {
                    currencyID: eInvoiceCurrency,
                    Text: InvoiceBody.TotPrice,
                },
                PayableAmount: {
                    currencyID: eInvoiceCurrency,
                    Text: InvoiceBody.TotPrice,
                },
            },
            InvoiceLine: [],
        },
    };
    if (eInvoiceCurrency !== "ALL") {
        EInvoiceBody.UblInvoice.Note.push(`CurrencyExchangeRate=${InvoiceBody.Currency.ExRate}${einvoiceCode}`);
    }
    if (EInvoiceBody?.UblInvoice?.PaymentMeans?.PaymentMeansCode === "42") {
        const accountData = paymentaccounts.find((acc) => acc._id === account);
        EInvoiceBody.UblInvoice.PaymentMeans.PayeeFinancialAccount = [
            {
                ID: !!accountData && !!accountData.accountId ? accountData.accountId : null,
                Name: !!accountData && !!accountData.name ? accountData.name : null,
                FinancialInstitutionBranch: {
                    ID: !!accountData && !!accountData.swift ? accountData.swift : null,
                },
            },
        ];
    } else if (["54", "55", "48"]?.includes(EInvoiceBody?.UblInvoice?.PaymentMeans?.PaymentMeansCode)) {
        const accountData = paymentaccounts.find((acc) => acc._id === account);
        EInvoiceBody.UblInvoice.PaymentMeans.CardAccount = {
            PrimaryAccountNumberID: !!accountData && !!accountData.accountId ? accountData.accountId : null,
            HolderName: !!accountData && !!accountData.name ? accountData.name : null,
        };
    }

    if (InvoiceBody.TotPriceWoVAT) {
        EInvoiceBody.UblInvoice.LegalMonetaryTotal.LineExtensionAmount.Text = InvoiceBody.TotPriceWoVAT;
        EInvoiceBody.UblInvoice.LegalMonetaryTotal.TaxExclusiveAmount.Text = InvoiceBody.TotPriceWoVAT;
    }
    if (InvoiceBody.TotVATAmt) {
        EInvoiceBody.UblInvoice.LegalMonetaryTotal.TaxInclusiveAmount.Text = InvoiceBody.TotPrice;
    }

    InvoiceBody.Items.forEach((item, i) => {
        EInvoiceBody.UblInvoice.InvoiceLine.push({
            ID: `${i}`,
            InvoicedQuantity: {
                unitCode: "XPP",
                Text: item.Q,
            },
            LineExtensionAmount: {
                currencyID: eInvoiceCurrency,
                Text: item.PB,
            },
            Item: {
                Name: item.N,
                ClassifiedTaxCategory: {
                    ID: item.VR ? "S" : "Z",
                    Percent: item.VR || 0,
                    TaxScheme: {
                        ID: "VAT",
                    },
                },
            },
            Price: {
                PriceAmount: {
                    currencyID: eInvoiceCurrency,
                    Text: (parseFloat(item.PB) < 0 ? parseFloat(item.PB) * -1 : parseFloat(item.PB)).toFixed(2),
                },
            },
        });
    });

    EInvoiceBody.UblInvoice.TaxTotal = [
        {
            TaxAmount: {
                currencyID: eInvoiceCurrency,
                Text: "0.00",
            },
            TaxSubtotal: [],
        },
    ];
    if (InvoiceBody.TotVATAmt) EInvoiceBody.UblInvoice.TaxTotal[0].TaxAmount.Text = InvoiceBody.TotVATAmt;
    if (InvoiceBody.SameTaxes) {
        InvoiceBody.SameTaxes.forEach((taxGroup) => {
            EInvoiceBody.UblInvoice.TaxTotal[0].TaxSubtotal.push({
                TaxableAmount: {
                    currencyID: eInvoiceCurrency,
                    Text: taxGroup.PriceBefVAT,
                },
                TaxAmount: {
                    currencyID: eInvoiceCurrency,
                    Text: taxGroup.VATAmt,
                },
                TaxCategory: {
                    ID: "S",
                    Percent: taxGroup.VATRate,
                    TaxScheme: {
                        ID: "VAT",
                    },
                },
            });
        });
    } else {
        EInvoiceBody.UblInvoice.TaxTotal[0].TaxSubtotal.push({
            TaxableAmount: {
                currencyID: eInvoiceCurrency,
                Text: InvoiceBody.TotPrice,
            },
            TaxAmount: {
                currencyID: eInvoiceCurrency,
                Text: "0.00",
            },
            TaxCategory: {
                ID: "Z",
                Percent: "0.00",
                TaxScheme: {
                    ID: "VAT",
                },
            },
        });
    }
    return EInvoiceBody;
};
