import { Box } from "@mui/material";
import React from "react";

const FileControl = ({ multiple = false, onChange, ...otherProps }) => {
    const handleChange = (event) => {
        const { files } = event.target;

        if (onChange) {
            if (multiple) {
                onChange(files);
            } else {
                onChange(files[0]);
            }
        }
    };
    return (
        <Box
            sx={{
                border: "2px dashed black",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            <input
                {...otherProps}
                type="file"
                onChange={handleChange}
                style={{ height: "100%", padding: "100px 50px" }}
            />
        </Box>
    );
};

export default FileControl;
