import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportHeader } from "./components";
import { DateRangeControl } from "../../components/dates";
import { useApi } from "../../components/hooks";
import { ReportGenerator } from "../../components/classes";
import { useSearchParams } from "react-router-dom";
import { CheckboxControl, SelectControl } from "../../components/form";
import { DateTime } from "luxon";
import { generateDatesBetween, toCurrency } from "../../utils";
import { Button, Stack } from "@mui/material";
import { Table } from "../../components/table";
import jsPDF from "jspdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const totalGuestsByCategories = (guests) => {
    return guests
        ?.map((room) => room.guests)
        ?.flat()
        ?.reduce((acc, category) => {
            const existingCategory = acc.find((c) => c.name === category.name);
            if (!existingCategory) {
                acc.push({ name: category.name, number: category.number });
                return acc;
            }
            existingCategory.number += category.number;
            return acc;
        }, [])
        ?.map((category) => `${category.number} ${category.name}`)
        ?.join(" , ");
};

const availableDays = ({ type, reservation, startDate, endDate }) => {
    switch (type) {
        case "dateCreated":
            return [];
        case "checkin":
            return [];
        case "checkout":
            return [];
        case "stayover":
            const dates = generateDatesBetween(reservation.checkin, reservation.checkout, "[)");
            return dates?.filter((day) => {
                return (
                    DateTime.fromISO(startDate) <= DateTime.fromISO(day) &&
                    DateTime.fromISO(endDate) >= DateTime.fromISO(day)
                );
            });
        default:
            return [];
    }
};

const totalGuestsByMeals = (reservations = [], filters) => {
    const { startDate, endDate, type } = filters || {};
    const totalGuests = {};
    reservations?.forEach((row) => {
        const dates = availableDays({ type, startDate, endDate, reservation: row });
        const totalDays = dates?.length || 0;
        row?.rooms?.map((room) => {
            let roomGuests = [];
            if (room.roomId === "UNASSIGNED") {
                roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId)?.guests || [];
            } else {
                roomGuests =
                    row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId)?.guests || [];
            }
            const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;

            meals?.forEach((meal) => {
                if (!totalGuests[meal]) {
                    totalGuests[meal] = {};
                }
                roomGuests?.forEach((category) => {
                    if (!totalGuests[meal][category.name]) {
                        totalGuests[meal][category.name] = 0;
                    }
                    totalGuests[meal][category.name] += (category.number || 0) * totalDays;
                });
            });
        });
    });
    return Object.entries(totalGuests)?.map(([meal, guests]) => {
        return {
            meal,
            guests: Object.entries(guests || {})
                ?.map(([category, number]) => {
                    return `${category}: ${number}`;
                })
                ?.join(" ,"),
        };
    });
};

const endpointsByFilter = {
    dateCreated: "reservations",
    checkin: "reservationsByCheckin",
    checkout: "reservationsByCheckout",
    stayover: "reservationsByDates",
};

const GuestsReport = () => {
    const { t } = useTranslation();
    const guestTableRef = useRef();

    const filterOptions = [
        { value: "dateCreated", label: t("date_created") },
        { value: "checkin", label: t("checkin") },
        { value: "checkout", label: t("checkout") },
        { value: "stayover", label: t("stayover") },
    ];
    const [params, setParams] = useSearchParams();
    const [report, setReport] = useState(null);
    const [mealsGuestsReportData, setMealsGuestsReportData] = useState([]);
    const { loading, fetch } = useApi();

    const columns = [
        {
            id: "id",
            header: t("reservation"),
            displayValue: (row) => row?.uuid || "",
        },
        // {
        //     id: "groupId",
        //     header: t("group"),
        //     displayValue: (row) => row?.groupUuid || "",
        //     totalValue: () => t("total"),
        // },
        {
            id: "name",
            header: t("guest_name"),
            displayValue: (row) => {
                const { firstName, lastName } = row?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
        },
        {
            id: "pax",
            header: t("pax"),
            displayValue: (row) => totalGuestsByCategories(row?.guests),
        },
        {
            id: "referer",
            header: t("referer"),
            displayValue: (row) => (row?.refererData?.firstName || "") + " " + (row?.refererData?.lastName || "") || "",
        },
        {
            id: "source",
            header: t("source_of_business"),
            displayValue: (row) => t(row?.bookingSource) || "",
        },
        {
            id: "checkin",
            header: t("checkin"),
            displayValue: (row) => row?.checkin || "",
        },
        {
            id: "checkout",
            header: t("checkout"),
            displayValue: (row) => row?.checkout || "",
        },
        {
            id: "los",
            header: t("los"),
            displayValue: (row) => {
                if (!row.isDailyUse) {
                    return (
                        DateTime.fromISO(row.checkout).diff(DateTime.fromISO(row.checkin), "days").toObject().days +
                        " " +
                        t("nights")
                    );
                }
            },
        },
        {
            id: "status",
            header: t("status"),
            displayValue: (row) => t(row?.status) || "",
        },
        {
            id: "roomtype",
            header: t("roomtype"),
            displayValue: (row) => {
                const roomTypes = [];
                row?.rooms?.forEach((room) => {
                    if (!room?.roomtypeData?.name) return;
                    if (!roomTypes?.includes(room?.roomtypeData?.name)) {
                        roomTypes.push(room?.roomtypeData?.name);
                    }
                });
                return roomTypes?.join(",");
            },
        },
        {
            id: "room",
            header: t("room"),
            displayValue: (row) => {
                return row?.rooms?.map((room) => room?.roomData?.name || "").join(" , ");
            },
        },
        {
            id: "rate",
            header: t("rate_name"),
            displayValue: (row) => {
                const rateNames = [];
                row?.rooms?.forEach((room) => {
                    room?.prices?.forEach((day) => {
                        const rate = !!day?.rateData?.name ? day?.rateData?.name : "standard";
                        if (!rateNames?.includes(rate)) rateNames.push(rate);
                    });
                });
                return rateNames?.join(",");
            },
        },
        {
            id: "averageRate",
            header: t("average_rate"),
            displayValue: (row) => {
                if (!row.isDailyUse) {
                    return toCurrency(
                        row?.totalPrice /
                            DateTime.fromISO(row.checkout).diff(DateTime.fromISO(row.checkin), "days").toObject().days,
                        ""
                    );
                }
            },
        },
        {
            id: "phone",
            header: t("phone"),
            displayValue: (row) => {
                const { phone } = row?.clientsData?.[0] || {};
                return phone || "";
            },
        },
        {
            id: "email",
            header: t("email"),
            displayValue: (row) => {
                const { email } = row?.clientsData?.[0] || {};
                return email || "";
            },
        },
        {
            id: "country",
            header: t("country"),
            displayValue: (row) => {
                const { country } = row?.clientsData?.[0] || {};
                return country || "";
            },
        },
    ];

    const mealGuestsColumns = [
        {
            id: "meal",
            header: t("meal"),
            cell: ({ row: { original } }) => {
                return original?.meal || "";
            },
        },
        {
            id: "guests",
            header: t("guests"),
            cell: ({ row: { original } }) => {
                return original?.guests || "";
            },
        },
    ];

    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        const endpoint = endpointsByFilter[params.get("type")];
        let startDate = params.get("startDate");
        let endDate = params.get("endDate");
        if (endpoint === "reservations") {
            startDate += "T00:00:00.000Z";
            endDate += "T23:59:59.999Z";
        }
        const response = await fetch({
            operation: "query",
            endpoint,
            responseData: `_id checkin uuid checkout bookingSource totalPrice status
            clientsData{_id firstName lastName phone email country}  
            rooms{roomId roomtypeId roomData{name meals} roomtypeData{name} prices{rateData{name meals}}}  
            refererData{firstName lastName} type
            guests{roomId guests{name number price extra}}`,
            data: { startDate, endDate },
        });
        if (!response?.[endpoint]) return;

        const newRaportGenerator = new ReportGenerator({
            data: response?.[endpoint]?.filter(
                (res) => !["CANCELLED", "NO_SHOW"]?.includes(res.status) && res.type !== "OUT_OF_ORDER"
            ),
            columns,
            displayTotal: false,
        });
        setReport(newRaportGenerator.generateTable({ title: t("guests"), landscape: true, tableRef: guestTableRef }));
        setMealsGuestsReportData(
            totalGuestsByMeals(
                response?.[endpoint]?.filter(
                    (res) => !["CANCELLED", "NO_SHOW"]?.includes(res.status) && res.type !== "OUT_OF_ORDER"
                ),
                {
                    type: params.get("type"),
                    startDate,
                    endDate,
                }
            )
        );
    };
    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("type")) data.type = params.get("type");
        return data;
    };
    const exportToPdf = () => {
        const doc = new jsPDF("l", "mm", [297, 210]);

        const totalPagesExp = "{total_pages_count_string}";
        const mealGuestsHeaders = mealGuestsColumns?.map((column) => t(column.header));
        const headers = guestTableRef?.current?.getTableData()?.[0]?.map((row) => t(row?.header));
        const rows = guestTableRef?.current?.getTableData()?.map((row) => row?.map((col) => col?.value));

        doc.text(`${t("guests_report")}`, 10, 10, "left");
        doc.autoTable({
            margin: { top: 30 },
            head: [mealGuestsHeaders],
            body: mealsGuestsReportData?.map((data) => [data?.meal, data?.guests]),
        });
        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 3,
            margin: { top: 30 },
            head: [headers],
            body: rows,
            didDrawPage: (data) => {
                // Date
                doc.setFontSize(10);
                doc.setFont("helvetica", "italic");
                doc.text(
                    `${t("time_created")}: ` + DateTime.now().toFormat("dd-LL-yyyy HH:mm"),
                    data.settings.margin.left + 0,
                    28
                );

                // Footer
                var str = "Page " + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === "function") {
                    str = str + " of " + totalPagesExp;
                }
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);

                // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
                var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
                var footerText = "HB";

                doc.setTextColor(100);
                doc.setFontSize(10);
                doc.text(footerText, pageWidth / 2, pageHeight - 10, "center");
            },
        });
        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }
        doc.save("guestsRreport" + DateTime.now().toFormat("dd-LL-yyyy HH:mm"));
    };

    return (
        <div>
            <ReportHeader
                title={t("guests_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <SelectControl
                        label={t("filter_type")}
                        options={filterOptions}
                        value={params.get("type")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                type: e.target.value || "",
                            })
                        }
                        sx={{ width: "400px" }}
                    />
                    <CheckboxControl
                        onChange={() => {
                            setParams({ ...getAllParams(), type: "stayover" });
                        }}
                        label={t("show_meals")}
                    />
                    {params.get("type") === "stayover" && (
                        <Button
                            disabled={mealsGuestsReportData?.length < 0}
                            onClick={() => exportToPdf()}
                            startIcon={<PictureAsPdfIcon />}
                            variant="outlined"
                        >
                            {t("print_pdf")}
                        </Button>
                    )}
                </Stack>
            </ReportHeader>
            {params.get("type") === "stayover" && mealsGuestsReportData?.length > 0 && (
                <div style={{ marginBottom: 10 }}>
                    <Table
                        data={mealsGuestsReportData || []}
                        columns={mealGuestsColumns}
                        disableHeader
                        disableSelection
                        disableDelete
                        disableFooter
                    />
                </div>
            )}
            {report}
        </div>
    );
};

export default GuestsReport;
