import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import StoreIcon from "@mui/icons-material/Store";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HelpIcon from "@mui/icons-material/Help";

const menuItems = [
    {
        name: "frontdesk",
        label: "Frontdesk",
        icon: <DashboardIcon />,
        mainHref: "/",
        href: "/",
        restrict: {
            model: "dashboard",
            right: "view",
        },
        subItems: [
            {
                name: "home",
                label: "Dashboard",
                href: "/",
                restrict: {
                    model: "dashboard",
                    right: "view",
                },
            },
            {
                name: "calendar",
                label: "Calendar",
                href: "/calendar",
                restrict: {
                    model: "calendar",
                    right: "view",
                },
            },
            {
                name: "Reservations",
                label: "Reservations",
                href: "/reservations",
                restrict: {
                    model: "reservations",
                    right: "list",
                },
            },
            {
                name: "new_reservation",
                label: "New Reservation",
                href: "/newreservation",
                restrict: {
                    model: "reservations",
                    right: "create",
                },
            },
            {
                name: "clients",
                label: "Clients",
                href: "/clients",
                restrict: {
                    model: "clients",
                    right: "list",
                },
            },
            {
                name: "groups",
                label: "Groups",
                href: "/groups",
                restrict: {
                    model: "groups",
                    right: "list",
                },
            },
            /*  {
                name: "events",
                label: "Events",
                href: "/events",
                restrict: {
                    model: "events",
                    right: "list",
                },
            }, */
            {
                name: "housekeeping",
                label: "Housekeeping",
                href: "/housekeeping",
                restrict: {
                    model: "housekeeping",
                    right: "list",
                },
            },
        ],
    },
    {
        name: "backoffice",
        icon: <BusinessIcon />,
        label: "Backoffice",
        mainHref: "/backoffice",
        href: "/backoffice/roomtypes",
        restrict: {
            model: "roomtypes",
            right: "list",
        },
        subItems: [
            {
                name: "roomtypes",
                label: "Room Types",
                href: "/backoffice/roomtypes",
                restrict: {
                    model: "roomtypes",
                    right: "list",
                },
            },
            {
                name: "rates",
                label: "Rates",
                href: "/backoffice/roomrates",
                restrict: {
                    model: "roomrates",
                    right: "list",
                },
            },
            {
                name: "prices",
                label: "prices",
                href: "/backoffice/availabilities",
                restrict: {
                    model: "availability",
                    right: "list",
                },
            },
            {
                name: "bookingsources",
                label: "bookingsources",
                href: "/backoffice/bookingsources",
                restrict: {
                    model: "bookingsources",
                    right: "list",
                },
            },
            /*  {
                name: "bookingengine",
                label: "bookingengine",
                href: "/backoffice/bookingengine",
                restrict: {
                    model: "bookingengine",
                    right: "list",
                },
            }, */
            {
                name: "discounts",
                label: "discounts",
                href: "/backoffice/discounts",
                restrict: {
                    model: "discounts",
                    right: "list",
                },
            },
        ],
    },
    {
        name: "pos",
        icon: <StoreIcon />,
        label: "POS",
        mainHref: "/pos",
        href: "/pos",
        restrict: {
            model: "pos",
            right: "view",
        },
        subItems: [
            {
                name: "pos_dashboard",
                label: "POS Dashboard",
                href: "/pos",
                restrict: {
                    model: "pos",
                    right: "view",
                },
            },
            {
                name: "products",
                label: "Products",
                href: "/pos/products",
                restrict: {
                    model: "products",
                    right: "list",
                },
            },
            {
                name: "inventory",
                label: "Inventory",
                href: "/pos/inventory",
                restrict: {
                    model: "products",
                    right: "list",
                },
            },
            {
                name: "pospoints",
                label: "POS Points",
                href: "/pos/pospoints",
                restrict: {
                    model: "pospoints",
                    right: "list",
                },
            },
            {
                name: "orders",
                label: "orders",
                href: "/pos/orders",
                restrict: {
                    model: "orders",
                    right: "list",
                },
            },
            {
                name: "suppliers",
                label: "Suppliers",
                href: "/pos/suppliers",
                restrict: {
                    model: "suppliers",
                    right: "list",
                },
            },
            /*  {
                name: "posplaces",
                label: "posplaces",
                href: "/pos/posplaces",
                restrict: {
                    model: "posplaces",
                    right: "list",
                },
            }, */
            {
                name: "discounts",
                label: "discounts",
                href: "/pos/posdiscounts",
                restrict: {
                    model: "posdiscounts",
                    right: "list",
                },
            },
            {
                name: "customers",
                label: "Customers",
                href: "/clients",
                restrict: {
                    model: "clients",
                    right: "list",
                },
            },
        ],
    },
    {
        name: "finance",
        icon: <BubbleChartIcon />,
        label: "Finance",
        mainHref: "/finance",
        href: "/finance/invoices",
        restrict: {
            model: "invoices",
            right: "view",
        },
        subItems: [
            {
                name: "invoices",
                label: "Invoices",
                href: "/finance/invoices",
                restrict: {
                    model: "invoices",
                    right: "view",
                },
            },
            {
                name: "expenses",
                label: "Expenses",
                href: "/finance/expenses",
                restrict: {
                    model: "expenses",
                    right: "list",
                },
            },
            {
                name: "bills",
                label: "Bills",
                href: "/finance/bills",
                restrict: {
                    model: "bills",
                    right: "list",
                },
            },
            {
                name: "estimates",
                label: "Estimates",
                href: "/finance/estimates",
                restrict: {
                    model: "estimates",
                    right: "list",
                },
            },
            {
                name: "transactions",
                label: "Transactions",
                href: "/finance/transactions",
                restrict: {
                    model: "transactions",
                    right: "list",
                },
            },
            {
                name: "taxes",
                label: "Taxes",
                href: "/finance/taxes",
                restrict: {
                    model: "taxes",
                    right: "list",
                },
            },
            {
                name: "paymentaccounts",
                label: "paymentaccounts",
                href: "/finance/paymentaccounts",
                restrict: {
                    model: "paymentaccounts",
                    right: "list",
                },
            },
        ],
    },
    {
        name: "reports",
        icon: <MultilineChartIcon />,
        label: "Reports",
        mainHref: "/reports",
        href: "/reports",
        restrict: {
            model: "reports",
            right: "view",
        },
        subItems: [
            {
                name: "reports",
                label: "All Reports",
                href: "/reports",
                restrict: {
                    model: "reports",
                    right: "list",
                },
            },
        ],
    },
    {
        name: "settings",
        icon: <SettingsIcon />,
        label: "Settings",
        mainHref: "/settings",
        href: "/settings",
        restrict: {
            model: "settings",
            right: "view",
        },
        subItems: [
            {
                name: "general",
                label: "General",
                href: "/settings",
                restrict: {
                    model: "settings",
                    right: "view",
                },
            },
            {
                name: "property",
                label: "Property",
                href: "/settings/property",
                restrict: {
                    model: "property",
                    right: "view",
                },
            },
            {
                name: "email",
                label: "Email",
                href: "/settings/email",
                restrict: {
                    model: "property",
                    right: "view",
                },
            },
            {
                name: "subscription",
                label: "Subscription",
                href: "/settings/subscription",
                restrict: {
                    model: "subscription",
                    right: "view",
                },
            },
            {
                name: "currencies",
                label: "Currencies",
                href: "/settings/currencies",
                restrict: {
                    model: "currencies",
                    right: "list",
                },
            },
        ],
    },
    {
        type: "divider",
        name: "divider",
    },
    {
        name: "accounts",
        icon: <SupervisorAccountIcon />,
        label: "Accounts",
        mainHref: "/accounts",
        href: "/accounts/users",
        restrict: {
            model: "users",
            right: "list",
        },
        subItems: [
            {
                name: "users",
                label: "Users",
                href: "/accounts/users",
                restrict: {
                    model: "users",
                    right: "list",
                },
            },
            {
                name: "roles",
                label: "Roles",
                href: "/accounts/roles",
                restrict: {
                    model: "roles",
                    right: "list",
                },
            },
        ],
    },
    {
        name: "learn_center",
        icon: <HelpIcon />,
        label: "Learn Center",
        mainHref: "/help",
        href: "/help",
        subItems: [],
    },
];

export default menuItems;
