import { Box, IconButton, Typography } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import React from "react";

const QuantityControl = ({ value = 0, onChange, min, max }) => {
    const changeHandler = (action) => {
        let newValue = value;
        if (action === "+") ++newValue;
        if (action === "-") --newValue;
        onChange(newValue);
    };

    const onInputValueChange = (newValue) => {
        const floatValue = parseFloat(newValue);
        if (isNaN(floatValue)) {
            return;
        }
        if (floatValue > max) {
            return;
        }
        onChange(floatValue);
    };
    return (
        <Box
            sx={{
                display: "inline-flex",
                alignItems: "center",
            }}
        >
            <IconButton
                disabled={min !== undefined && min === value}
                onClick={() => changeHandler("-")}
                size="large"
                color="primary"
            >
                <RemoveCircleOutlineRoundedIcon fontSize="24" />
            </IconButton>
            <input
                style={{ width: 50, fontSize: 18, border: "none" }}
                value={value}
                onChange={(e) => onInputValueChange(e.target.value)}
                min={min}
                max={max}
            />
            <IconButton
                disabled={max !== undefined && max === value}
                onClick={() => changeHandler("+")}
                size="large"
                color="primary"
            >
                <AddCircleOutlineRoundedIcon fontSize="24" />
            </IconButton>
        </Box>
    );
};

export default QuantityControl;
