import { Step, StepLabel, Stepper, StepIcon, IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const STEPS = [
    { label: "search_for_rooms", icon: <MeetingRoomIcon /> },
    { label: "guest_details", icon: <PersonIcon /> },
    { label: "extras", icon: <SettingsIcon /> },
    { label: "confirmation", icon: <CheckCircleOutlineRoundedIcon /> },
];

const ViewsStepper = ({ activeStep }) => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <Stepper activeStep={activeStep} alternativeLabel={isSmallScreen ? true : false}>
            {STEPS.map((step, index) => (
                <Step>
                    <StepLabel
                        componentsProps={{ label: { style: { fontSize: 16 } } }}
                        icon={
                            <IconButton
                                disableRipple
                                disableTouchRipple
                                color={index === activeStep ? "primary" : "default"}
                            >
                                <StepIcon icon={step.icon} />
                            </IconButton>
                        }
                    >
                        {t(step.label)}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default ViewsStepper;
