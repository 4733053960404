import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    LinearProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import readXlsxFile from "read-excel-file";
import { FileControl, SelectControl } from "../../components/form";
import { ImportSteps } from "./components";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useApi } from "../../components/hooks";
import { generateDatesBetween } from "../../utils";
import { InvoiceHandler2 } from "../../components/classes";

const columns = [
    {
        label: "checkin",
        value: "checkin",
    },
    {
        label: "checkout",
        value: "checkout",
    },
    {
        label: "room",
        value: "room",
    },
    {
        label: "price",
        value: "price",
    },
    {
        label: "client",
        value: "client",
    },
    {
        label: "client_type",
        value: "clientType",
    },
    {
        label: "country",
        value: "country",
    },
];

const ReservationsImport = () => {
    const { t } = useTranslation();
    const [fileRows, setFileRows] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [connectedColumns, setConnectedColumns] = useState({});
    const [extraData, setExtraData] = useState({
        guestcategories: [],
        rooms: [],
        taxes: [],
        settingsgeneral: {},
    });

    const { loading, fetch } = useApi();
    const [reservationColumns, setReservationColumns] = useState(columns);

    useEffect(() => {
        loadExtraData();
    }, []);

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "guestcategories",
                    responseData: "_id name priorityOrder",
                },
                {
                    endpoint: "rooms",
                    responseData: "_id name roomtypeId maxCapacity includedCapacity capacity{id price}",
                },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
            ],
        });
        if (response) {
            setExtraData(response);
            setReservationColumns(
                reservationColumns.concat(
                    response?.guestcategories?.map((guests) => ({ label: guests.name, value: guests.name }))
                )
            );
        }
    };

    const fileColumnsOptions = useMemo(() => {
        return fileRows?.[0]?.map((col) => ({ label: col, value: col }));
    }, [fileRows]);

    const addFile = (rows) => {
        const columns = rows?.[0];
        const toReservationColumns = {};
        columns?.forEach((col) => {
            const { value } = reservationColumns?.find((c) => c.label?.toLowerCase() === col?.toLowerCase()) || {};
            if (value) toReservationColumns[value] = col;
        });
        setConnectedColumns(toReservationColumns);
        setFileRows(rows);
        setActiveStep(1);
    };

    const transformToReservationsData = async () => {
        const inputColumns = fileRows[0];
        const inputReservations = fileRows?.slice(1);

        const reservations = [];
        const clients = [];
        const invoices = [];

        inputReservations?.forEach((reservation, index) => {
            const defaultRoomtypeId = "roomtypes_2023-05-05T10:09:52.465Z";
            const roomData = extraData?.rooms?.find(
                (room) =>
                    room?.name?.toLowerCase() ===
                    reservation[inputColumns.indexOf(connectedColumns.room)]?.toLowerCase()
            );
            const price = parseFloat(reservation[inputColumns.indexOf(connectedColumns.price)]);
            const checkin =
                typeof reservation[inputColumns.indexOf(connectedColumns.checkin)] === "object"
                    ? reservation[inputColumns.indexOf(connectedColumns.checkin)]?.toISOString().slice(0, 10)
                    : null;
            const checkout =
                typeof reservation[inputColumns.indexOf(connectedColumns.checkout)] === "object"
                    ? reservation[inputColumns.indexOf(connectedColumns.checkout)]?.toISOString().slice(0, 10)
                    : null;
            const datesBetween = checkin && checkout ? generateDatesBetween(checkin, checkout, "[)") : [];
            const data = {
                checkin,
                checkout,
                clients: [],
                bookingSource: "imported",
                rooms: [
                    {
                        roomId: roomData?._id || "UNASSIGNED",
                        roomtypeId: roomData?.roomtypeId || defaultRoomtypeId,
                        prices: datesBetween?.map((day) => {
                            return {
                                price: isNaN(price) ? 0.0 : price,
                                rateId: "standard",
                                date: day,
                                discount: null,
                            };
                        }),
                    },
                ],
                guests: [
                    {
                        roomId: roomData?._id || defaultRoomtypeId,
                        guests: [],
                    },
                ],
                notes: [],
                discount: null,
                totalPrice: isNaN(price) ? 0.0 : price * datesBetween.length,
            };
            const { includedCapacity } = roomData || {};
            let freeOfChargeCapacity = includedCapacity || 0;

            extraData?.guestcategories
                ?.sort((a, b) => {
                    return a.priorityOrder - b.priorityOrder;
                })
                ?.forEach((category) => {
                    const capacity = roomData?.capacity?.find((c) => c.id === category._id);
                    if (reservation[inputColumns.indexOf(connectedColumns[category.name])] === undefined) return;
                    if (!capacity) return;
                    const guestNumber =
                        parseInt(reservation[inputColumns.indexOf(connectedColumns[category.name])]) || 0;
                    if (guestNumber === 0) return;
                    const extraGuests = guestNumber - freeOfChargeCapacity;
                    freeOfChargeCapacity -= guestNumber;
                    if (freeOfChargeCapacity < 0) freeOfChargeCapacity = 0;

                    data.guests[0].guests.push({
                        name: connectedColumns[category.name],
                        number: guestNumber,
                        price: capacity.price,
                        extra: extraGuests > 0 ? extraGuests : 0,
                    });
                });
            const reservationClients = reservation[inputColumns.indexOf(connectedColumns.client)]?.split(" / ") || [];
            const timestamp = new Date();
            timestamp.setMilliseconds(timestamp.getMilliseconds() + index);
            reservationClients?.forEach((clientName) => {
                const clientType =
                    reservation[inputColumns.indexOf(connectedColumns.clientType)]?.toLowerCase() === "individ"
                        ? "client"
                        : "partner";
                const firstSpaceIndex = clientName.indexOf(" ");
                const clientData = {
                    _id: "clients_" + timestamp?.toJSON(),
                    clientType,
                    firstName: clientType === "client" ? clientName.slice(0, firstSpaceIndex) : clientName,
                    lastName: clientType === "client" ? clientName.slice(firstSpaceIndex + 1) : null,
                    country: reservation[inputColumns.indexOf(connectedColumns.country)],
                    nationality: reservation[inputColumns.indexOf(connectedColumns.country)],
                };
                const createdClient = clients.find(
                    (c) => `${c.firstName} ${c.lastName}` === `${clientData.firstName} ${clientData.lastName}`
                );
                if (createdClient) {
                    data.clients.push(createdClient._id);
                } else {
                    data.clients.push(clientData._id);
                    clients.push(clientData);
                }
            });
            reservations.push(data);
        });
        const clientsResponse = await fetch({
            operation: "mutation",
            endpoint: "createMultipleClients",
            data: {
                clients,
            },
        });
        for (const reservation of reservations?.filter((reservation) => {
            return !!reservation.checkin && !!reservation.checkout;
        })) {
            const reservationResponse = await fetch({
                operation: "mutation",
                endpoint: "createReservation",
                data: reservation,
                responseData: "_id",
            });
        }
        setActiveStep(2);
    };

    return (
        <>
            <ImportSteps activeStep={activeStep} />
            {activeStep === 0 ? (
                <Card sx={{ marginTop: 2 }}>
                    <CardHeader title={t("choose_file")} titleTypographyProps={{ variant: "h6" }} />
                    <Divider />
                    <CardContent>
                        <FileControl
                            onChange={(file) => {
                                readXlsxFile(file).then((rows, index) => {
                                    addFile(rows);
                                });
                            }}
                        />
                    </CardContent>
                </Card>
            ) : activeStep === 1 ? (
                <Card sx={{ marginTop: 2 }}>
                    <CardHeader title={t("connect_columns")} titleTypographyProps={{ variant: "h6" }} />
                    <Divider />
                    <CardContent>
                        {loading && <LinearProgress color="primary" />}
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">{t("reservation_column")}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">{t("file_column")}</Typography>
                                    </Grid>
                                </Grid>
                                {reservationColumns?.map((reservationColumn) => {
                                    return (
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography>{t(reservationColumn?.label)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <SelectControl
                                                    value={connectedColumns?.[reservationColumn.value] || ""}
                                                    onChange={(e) => {
                                                        setConnectedColumns({
                                                            ...connectedColumns,
                                                            [reservationColumn.value]: e.target.value,
                                                        });
                                                    }}
                                                    label={t("column")}
                                                    options={fileColumnsOptions}
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={() => {
                                transformToReservationsData();
                            }}
                            variant="contained"
                        >
                            {t("save")}
                        </Button>
                    </CardActions>
                </Card>
            ) : (
                <Card>
                    <CardHeader title={t("confirmed")} titleTypographyProps={{ variant: "h6" }} />
                    <Divider />
                    <CardContent>
                        <ListItem>
                            <ListItemIcon>
                                <DoneAllIcon color="success" />
                            </ListItemIcon>
                            <ListItemText
                                primary={t("reservations_imported_successfully")}
                                primaryTypographyProps={{ variant: "h6" }}
                            />
                        </ListItem>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default ReservationsImport;
