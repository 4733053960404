import {
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLoggedUserData, toCurrency } from "../../utils";
import { OrdersTable, ReservationsTable } from "./components/nightaudit";
import { Form, InputControl, SelectControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { PaymentByMethodsTable } from "../../components/common";

const NightAudit = () => {
    const { t } = useTranslation();
    const { fetch, loading } = useApi();
    const navigate = useNavigate();

    const reservationTableRef = useRef();

    const [values, setValues] = useState({});
    const [view, setView] = useState("actualBalance");
    const [waiterRevenue, setWaiterRevenue] = useState({});
    const [payments, setPayments] = useState({ invoices: [], reservations: [], orders: [] });

    const [currencies, setCurrencies] = useState([]);
    const [currencyData, setCurrencyData] = useState({});
    const [orders, setOrders] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => loadData(), []);

    const loadData = async () => {
        const myShiftResponse = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "myShift",
                    responseData: `_id 
                        starting{
                            timestamp 
                            amount{currency amount}
                        } 
                        closing{
                            timestamp 
                            amount{currency amount} 
                            paymentsAmount{currency amount}
                        } 
                        lastClosed`,
                },
            ],
        });
        if (!myShiftResponse?.myShift) return;
        if (myShiftResponse?.myShift?._id) setWaiterRevenue(myShiftResponse.myShift);
        const { timestamp } = myShiftResponse.myShift?.starting || {};

        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "paymentsByDates",
                    data: { start: timestamp },
                    responseData: `
                        invoices{
                            timestamp
                            amount
                            paymentMethod
                            paymentMethodData{name method}
                            userName
                            note
                            invoice{
                                invoiceCurrency
                                status
                                reservationData{
                                    rooms{roomData{name}}
                                    clientsData{_id firstName lastName}
                                    guests{roomId guests{name number price extra}} 
                                    uuid
                                    checkin
                                    checkout
                                    totalPrice
                                }
                            }
                        }
                        reservations{
                            timestamp
                            amount
                            paymentMethod
                            paymentMethodData{name method}
                            userName
                            note
                            reservation{
                                rooms{roomData{name}}
                                clientsData{_id firstName lastName}
                                guests{roomId guests{name number price extra}} 
                                uuid
                                checkin
                                checkout
                                totalPrice
                                status
                                isDailyUse
                                invoiceCurrency
                            }
                        }
                       
                        orders{
                            timestamp
                            amount
                            paymentMethod
                            paymentMethodData{name method}
                            userName
                            order{
                                pospointData{defaultCurrency} status
                            }
                        }
                        `,
                },
                {
                    endpoint: "orders",
                    data: {
                        startDate: timestamp,
                        endDate: "end",
                    },
                    responseData: `
                        _id 
                        customerData{firstName lastName} 
                        reservationData{rooms{roomData{name}}}
                        payments{paymentStatus cashReceived paymentMethod paymentMethodData{name method}} 
                        pospointData{name defaultCurrency}
                        totalPrice 
                        number
                        createdAt
                        createdBy
                       status
                    `,
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "currencyData{currency} dailyUseCurrencyData{currency}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency",
                },
            ],
        });
        if (!!response?.paymentsByDates) {
            setPayments({
                invoices: response.paymentsByDates.invoices?.filter((payment) => {
                    if (["TRANSFERRED", "CANCELLED"]?.includes(payment?.invoice?.status)) return false;
                    if (!DateTime.fromISO(payment.timestamp).isValid) return false;
                    return payment.userName == getLoggedUserData()?._id;
                }),
                reservations: response.paymentsByDates.reservations?.filter((payment) => {
                    if (["TRANSFERRED", "CANCELLED"]?.includes(payment?.reservation?.status)) return false;
                    if (!DateTime.fromISO(payment.timestamp).isValid) return false;
                    return payment.userName == getLoggedUserData()?._id;
                }),
                orders: response.paymentsByDates.orders
                    ?.filter((payment) => {
                        if (!DateTime.fromISO(payment.timestamp).isValid) return false;
                        return payment.userName == getLoggedUserData()?._id;
                    })
                    ?.filter((payment) => !["TRANSFERRED", "CANCELLED"]?.includes(payment?.order?.status)),
            });
        }
        if (!!response?.orders)
            setOrders(
                response.orders?.filter((order) => order.createdBy == getLoggedUserData()?._id)
                // ?.filter((order) => !["TRANSFERRED", "CANCELLED"]?.includes(order?.status))
            );
        if (!!response?.settingsgeneral)
            setCurrencyData({
                defaultCurrency: response?.settingsgeneral?.currencyData?.currency,
                dailyUseCurrency: response?.settingsgeneral?.dailyUseCurrencyData?.currency,
            });
        if (!!response?.currencies) setCurrencies(response.currencies);
    };

    const paymentsTotalByMethods = useMemo(() => {
        const paymentsByMethods = {};
        payments?.invoices?.forEach((invoicePayment) => {
            const method = invoicePayment?.paymentMethodData?.method?.toLowerCase() || "cash";
            if (!paymentsByMethods[method]) {
                paymentsByMethods[method] = {};
            }
            const currency = !!invoicePayment?.invoice?.invoiceCurrency
                ? invoicePayment?.invoice?.invoiceCurrency
                : currencyData?.defaultCurrency;
            if (!currency) return;
            if (!paymentsByMethods[method][currency]) {
                paymentsByMethods[method][currency] = 0.0;
            }
            paymentsByMethods[method][currency] += invoicePayment.amount;
        });
        payments?.reservations?.forEach((reservationPayment) => {
            const method = reservationPayment?.paymentMethodData?.method?.toLowerCase() || "cash";
            if (!paymentsByMethods[method]) {
                paymentsByMethods[method] = {};
            }
            let currency = reservationPayment?.reservation?.invoiceCurrency || currencyData?.defaultCurrency;
            if (reservationPayment?.reservation?.isDailyUse) {
                currency = reservationPayment?.reservation?.invoiceCurrency || currencyData?.dailyUseCurrency;
            }

            if (!currency) return;
            if (!paymentsByMethods[method][currency]) {
                paymentsByMethods[method][currency] = 0.0;
            }
            paymentsByMethods[method][currency] += reservationPayment.amount;
        });
        payments?.orders?.forEach((orderPayment) => {
            const method = orderPayment?.paymentMethodData?.method?.toLowerCase() || "cash";
            if (orderPayment?.paymentMethod === "roomcharge") return;
            if (!paymentsByMethods[method]) {
                paymentsByMethods[method] = {};
            }
            const { currency } =
                currencies?.find((currency) => currency._id === orderPayment?.order?.pospointData?.defaultCurrency) ||
                {};
            if (!currency) return;
            if (!paymentsByMethods[method][currency]) {
                paymentsByMethods[method][currency] = 0.0;
            }
            paymentsByMethods[method][currency] += orderPayment.amount;
        });
        return paymentsByMethods;
    }, [payments?.invoices, payments?.orders, payments?.reservations, currencies, currencyData]);

    const closingBalanceAmounts = useMemo(() => {
        const paymentsCashAmounts = paymentsTotalByMethods?.cash ? { ...paymentsTotalByMethods?.cash } : {};
        waiterRevenue?.starting?.amount?.forEach((currency) => {
            if (!paymentsCashAmounts[currency.currency]) paymentsCashAmounts[currency.currency] = 0.0;
            paymentsCashAmounts[currency.currency] += currency.amount;
        });
        return Object.entries(paymentsCashAmounts)?.map(([currency, amount]) => ({ currency, amount }));
    }, [waiterRevenue?.starting, paymentsTotalByMethods]);

    const closeShift = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "closeShift",
            data: {
                _id: waiterRevenue?._id,
                amount: closingBalanceAmounts,
                paymentsAmount: paymentsTotalByMethods?.cash
                    ? Object.entries(paymentsTotalByMethods?.cash).map(([currency, amount]) => ({ currency, amount }))
                    : [],
            },
            responseData: "_id",
        });
        if (!!response?.closeShift?._id) {
            enqueueSnackbar(t("shift_closed"), { variant: "success" });
            navigate("/login");
        }
    };
    const updateBalance = async () => {
        const { amount, currency } = values;
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateShiftStartingBalance",
            data: {
                _id: waiterRevenue?._id,
                amount: [{ amount: parseFloat(amount), currency }],
            },
            responseData: "_id starting{timestamp amount{currency amount}}",
        });
        if (!!response?.updateShiftStartingBalance?._id) {
            enqueueSnackbar(t("balance_updated"), { variant: "success" });
            setWaiterRevenue({ ...waiterRevenue, starting: response?.updateShiftStartingBalance?.starting });
            setView("actualBalance");
            setValues({});
        }
    };
    const exportToPdf = () => {
        const headers = reservationTableRef?.current?.getTableData()?.[0]?.map((row) => row?.header);
        const rows = reservationTableRef?.current?.getTableData()?.map((row) => row?.map((col) => col?.value));
        const doc = new jsPDF("l", "mm", [297, 210]);
        const totalPagesExp = "{total_pages_count_string}";

        doc.text(
            `${t("shift_for")}: ${getLoggedUserData()?.displayName || getLoggedUserData()?._id?.split("users_")[1]}`,
            14,
            10,
            "left"
        );
        doc.setFontSize("11");
        doc.setFont("Helvetica", "bold");
        if (!!waiterRevenue?.lastClosed) {
            doc.text(
                `${t("previous_closing_shift")}: ${DateTime.fromISO(waiterRevenue?.lastClosed).toFormat(
                    "dd-LL-yyyy HH:mm:ss"
                )}`,
                210,
                10,
                "left"
            );
        }
        doc.text(
            `${t("starting_shift")}: ${DateTime.fromISO(waiterRevenue?.starting?.timestamp).toFormat(
                "dd-LL-yyyy HH:mm:ss"
            )}`,
            210,
            15,
            "left"
        );

        doc.autoTable({
            margin: { top: 20 },
            head: [headers],
            body: rows,
        });

        autoTable(doc, {
            startY: doc.autoTable.previous.finalY + 3,
            head: [
                [
                    t("number"),
                    t("name"),
                    t("date"),
                    t("time"),
                    t("payment_status"),
                    t("pospoint"),
                    t("amount"),
                    t("payment_method"),
                ],
            ],
            body: orders?.map((order) => {
                console.log(order);
                const { currency } =
                    currencies?.find((currency) => currency._id === order?.pospointData?.defaultCurrency) || {};

                return [
                    order?.number,
                    (order?.customerData?.firstName || "") + " " + (order?.customerData?.lastName || ""),
                    DateTime.fromISO(order?.createdAt, { zone: "utc" })?.toFormat("dd LLL yyyy"),
                    DateTime.fromISO(order?.createdAt, { zone: "utc" })?.toFormat("HH:mm"),
                    t(`${order?.status || "open"}`),
                    t(`${order?.pospointData?.name || ""}`),
                    toCurrency(order?.totalPrice, currency),
                    `${order?.payments?.paymentMethodData?.name || ""}`,
                ];
            }),
            didDrawPage: (data) => {
                // Date
                doc.setFontSize(10);
                doc.setFont("helvetica", "italic");
                doc.text(
                    `${t("time_created")}: ` + DateTime.now().toFormat("dd-LL-yyyy HH:mm"),
                    data.settings.margin.left + 0,
                    18
                );

                // Footer
                var str = "Page " + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === "function") {
                    str = str + " of " + totalPagesExp;
                }
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);

                // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
                var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
                var footerText = "HB";

                doc.setTextColor(100);
                doc.setFontSize(10);
                doc.text(footerText, pageWidth / 2, pageHeight - 10, "center");
            },
        });
        autoTable(doc, {
            margin: { top: 10 },
            head: [[t("payment_method"), t("amount")]],
            body: Object.entries(paymentsTotalByMethods)?.map(([method, currencies]) => [
                method,
                Object.entries(currencies)
                    ?.map(([currency, amount]) => toCurrency(amount, currency))
                    ?.join(" , "),
            ]),
        });
        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }
        doc.save(`nightaudit_${getLoggedUserData()?._id?.split("users_")[1]}_${new Date().toJSON()}` + ".pdf");
    };

    return (
        <>
            <Card sx={{ marginBottom: 2 }}>
                <CardHeader
                    title={t("night_audit")}
                    titleTypographyProps={{ variant: "h6" }}
                    action={
                        <Button
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => {
                                exportToPdf();
                                closeShift();
                            }}
                            variant="outlined"
                        >
                            {t("close_shift_and_print")}
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={4} alignItems="start">
                        <Grid item xs={3}>
                            <ButtonGroup variant="outlined" orientation="vertical" fullWidth>
                                <Button onClick={() => setView("actualBalance")}>{t("actual_balance")}</Button>
                                <Button onClick={() => setView("updateBalance")}>{t("update_balance")}</Button>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        exportToPdf();
                                        closeShift();
                                    }}
                                >
                                    {t("close_shift_and_print")}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        {view === "actualBalance" ? (
                            <Grid item xs={9}>
                                <Typography variant="h6">{t("actual_balance")}</Typography>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    divider={<Divider flexItem orientation="vertical" />}
                                >
                                    <Grid item xs={4}>
                                        <AmountDetails
                                            title={t("starting_balance")}
                                            currenciesAmount={waiterRevenue?.starting?.amount || []}
                                        />
                                    </Grid>
                                    <Grid item xs={4} paddingLeft={2}>
                                        <AmountDetails
                                            title={t("payments")}
                                            currenciesAmount={
                                                paymentsTotalByMethods?.cash
                                                    ? Object.entries(paymentsTotalByMethods?.cash).map(
                                                          ([currency, amount]) => ({ currency, amount })
                                                      )
                                                    : []
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} paddingLeft={2}>
                                        <AmountDetails
                                            title={t("closing_balance")}
                                            currenciesAmount={closingBalanceAmounts}
                                        />
                                    </Grid>
                                </Stack>
                            </Grid>
                        ) : (
                            <Grid item xs={9}>
                                <Form
                                    values={values}
                                    onValuesChange={setValues}
                                    rules={{
                                        amount: "required",
                                        currency: "required",
                                    }}
                                    onSubmit={updateBalance}
                                >
                                    <Stack direction="row" gap={2} alignItems="start">
                                        <InputControl
                                            type="number"
                                            name="amount"
                                            fullWidth={false}
                                            label={t("amount")}
                                            margin="none"
                                        />
                                        <SelectControl
                                            name="currency"
                                            sx={{ width: 200 }}
                                            label={t("currency")}
                                            margin="none"
                                            options={currencies?.map((currency) => ({
                                                value: currency.currency,
                                                label: currency.currency,
                                            }))}
                                        />
                                        <Button type="submit" variant="contained">
                                            {t("update")}
                                        </Button>
                                    </Stack>
                                </Form>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>

            <ReservationsTable
                data={payments?.invoices
                    ?.filter((invoicePayment) => {
                        return !!invoicePayment?.invoice?.reservationData;
                    })
                    ?.concat(payments?.reservations)}
                currencyData={currencyData}
                reservationTableRef={reservationTableRef}
            />

            <Box marginBottom={2} />
            <OrdersTable data={orders} currencies={currencies} />
            <Box marginBottom={2} />
            <PaymentByMethodsTable data={paymentsTotalByMethods} />
            <Backdrop sx={{ zIndex: 10000 }} open={loading}>
                <CircularProgress size={60} sx={{ color: "white" }} />
            </Backdrop>
        </>
    );
};

export default NightAudit;

const AmountDetails = ({ title, currenciesAmount = [] }) => {
    return (
        <Stack marginTop={2}>
            <Typography variant="subtitle1">{title}</Typography>
            {currenciesAmount?.length === 0 && (
                <Typography variant="h6" color="primary">
                    --
                </Typography>
            )}
            {currenciesAmount?.map((currency) => {
                const amount = parseFloat(currency.amount);
                return (
                    <Typography variant="h6" color="primary">
                        {currency.currency} {isNaN(amount) ? "0.00" : amount.toFixed(2)}
                    </Typography>
                );
            })}
        </Stack>
    );
};
