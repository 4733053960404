import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    LinearProgress,
    Link,
    Stack,
    SwipeableDrawer,
    Typography,
    useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Logo } from "../../components/common";
import { Form, InputControl } from "../../components/form";

const LoginCard = ({ t, handleLogin, loading, waiters }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [credentials, setCredentials] = useState({});
    const [hidePassword, setHidePassword] = useState(true);
    const [hideWaitersDrawer, setHideWaitersDrawer] = useState(false);
    const [isUserClicked, setIsUserClicked] = useState(false);

    const rules = {
        userName: "string|required",
        password: "string|required",
    };

    const passwordFieldRef = useRef(null);

    const handleUserButtonClick = () => {
        if (passwordFieldRef.current) {
            passwordFieldRef.current.focus();
        }
    };

    useEffect(() => {
        if (isUserClicked) handleUserButtonClick();
    }, [isUserClicked]);

    return (
        <>
            <Dialog
                open={true}
                maxWidth="xs"
                fullWidth={true}
                hideBackdrop={true}
                fullScreen={fullScreen}
                PaperProps={{
                    elevation: 1,
                    style: { borderRadius: "10px" },
                }}
            >
                {loading && <LinearProgress color="primary" />}
                <Form
                    values={credentials}
                    onValuesChange={setCredentials}
                    rules={rules}
                    onSubmit={() => {
                        handleLogin(credentials);
                    }}
                >
                    <div
                        style={{
                            padding: "20px 0",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            boxSizing: "border-box",
                        }}
                    >
                        <Logo width="110px" height="110px" />
                        <DialogTitle style={{ alignSelf: "center" }}>{t("welcome_back")}</DialogTitle>
                        <DialogContent
                            style={{
                                width: "100%",
                                boxSizing: "border-box",
                            }}
                        >
                            <InputControl
                                size="medium"
                                name="userName"
                                label={t("username")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccountCircle sx={{ color: "primary.main" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <InputControl
                                size="medium"
                                name="password"
                                label={t("password")}
                                type={hidePassword ? "password" : "text"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setHidePassword(!hidePassword)} edge="end">
                                                {hidePassword ? (
                                                    <Visibility sx={{ color: "primary.main" }} />
                                                ) : (
                                                    <VisibilityOff sx={{ color: "primary.main" }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputRef={passwordFieldRef}
                            />
                            <Link variant="subtitle2" underline="hover">
                                {t("forgot_password_click_here")}
                            </Link>
                            <br />
                            <Link variant="subtitle2" href="mailto:hello@strowberrycode.com" underline="hover">
                                {t("need_an_account_contact_us")}
                            </Link>
                        </DialogContent>
                        <DialogActions
                            style={{
                                width: "100%",
                                boxSizing: "border-box",
                                padding: "0 20px",
                            }}
                        >
                            <Button fullWidth size="large" variant="contained" color="primary" type="submit">
                                {t("login")}
                            </Button>
                        </DialogActions>
                        <DialogActions
                            style={{
                                width: "100%",
                                boxSizing: "border-box",
                                padding: "10px 20px",
                            }}
                        >
                            <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setHideWaitersDrawer(false);
                                    setIsUserClicked(false);
                                }}
                                startIcon={<PersonIcon />}
                            >
                                {t("switch_user")}
                            </Button>
                        </DialogActions>
                    </div>
                </Form>
            </Dialog>
            <SwipeableDrawer
                style={{ zIndex: 999999 }}
                PaperProps={{ sx: { width: 300 } }}
                anchor={"right"}
                open={!hideWaitersDrawer && waiters?.length > 0}
                onClose={() => {
                    setHideWaitersDrawer(true);
                }}
            >
                <Stack padding={2} gap={2}>
                    <Typography variant="h6" color="primary">
                        {t("select_a_user")}
                    </Typography>
                    {waiters?.map((waiter) => (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setCredentials({ userName: waiter });
                                setHideWaitersDrawer(true);
                                setIsUserClicked(true);
                            }}
                        >
                            {waiter}
                        </Button>
                    ))}
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setCredentials({});
                            setHideWaitersDrawer(true);
                        }}
                    >
                        {t("other_user")}
                    </Button>
                </Stack>
            </SwipeableDrawer>
        </>
    );
};

export default withTranslation("translations")(LoginCard);
