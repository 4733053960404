import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { sq, en, it, no, de } from "./assests/locale";

i18n.use(initReactI18next).init({
    resources: {
        sq,
        en,
        it,
        no,
        de,
    },
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ",",
    },
    react: {
        wait: true,
    },
});
export default i18n;
