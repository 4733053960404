import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { Modal } from "../../../../components/modal";
import { ClientsSearch } from "../../../../components/common";
import { useApi } from "../../../../components/hooks";

const EditRefererModal = ({ refererName, reservationId, setReservationData }) => {
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const [state, setState] = useState({
        openModal: false,
    });

    const changeReservationReferer = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: {
                _id: reservationId,
                referer: state.referer._id,
            },
            responseData: "_id referer refererData{firstName}",
        });
        if (response?.updateReservation?._id) {
            setReservationData(response?.updateReservation);
            setState({ openModal: false, referer: null });
        }
    };

    return (
        <>
            <Button
                fullWidth
                size="small"
                endIcon={<EditIcon />}
                onClick={() => setState({ ...state, openModal: true })}
            >
                {t("edit")}
            </Button>
            <Modal
                maxWidth="xs"
                open={state.openModal}
                onClose={() => setState({ ...state, openModal: false, referer: null })}
                onSave={() => {
                    changeReservationReferer();
                }}
                noText="none"
                yesText={t("confirm")}
                titlelabel={t("edit_referrer")}
                yesDisabled={!state.referer?._id || loading}
            >
                <ClientsSearch
                    placeholder={t("search_referrer")}
                    onlyPartners
                    onClientSelect={(data) => {
                        setState({ ...state, referer: { _id: data._id, name: data.firstName } });
                    }}
                />
                {(state?.referer || refererName) && (
                    <Typography>
                        {t("referer")}: {state?.referer?.name || refererName}
                    </Typography>
                )}
            </Modal>
        </>
    );
};

export default EditRefererModal;
