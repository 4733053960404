import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../form";
import { useApi } from "../hooks";
import SearchIcon from "@mui/icons-material/Search";

const ClientsSearch = ({
    onClientSelect,
    inputProps = {},
    extraOptions = [],
    placeholder,
    onlyPartners,
    onNoOptionAction,
    ...otherProps
}) => {
    const [clients, setClients] = useState([]);
    const { t } = useTranslation();
    const { loading, fetch } = useApi();

    const loadData = async (name) => {
        const response = await fetch({
            operation: "query",
            endpoint: "searchClients",
            data: {
                name,
            },
            responseData: `_id firstName lastName clientType birthdate referenceTitle company country state city referer{_id name}
                zip addressLine email phone IDType IDNumber placeOfIssue dateOfIssue dateOfExpiry nationality placeOfBirth`,
        });
        if (response?.searchClients) {
            setClients(
                response.searchClients
                    ?.filter((client) => {
                        if (!onlyPartners) return true;
                        return client.clientType === "partner";
                    })
                    ?.map((client) => {
                        client.value = client._id;
                        client.label = (client.firstName || "") + " " + (client.lastName || "");
                        return client;
                    })
            );
        }
    };
    return (
        <AutoCompleteControl
            label={placeholder || t("search_client")}
            options={clients.concat(extraOptions)}
            loading={loading}
            onInputChange={(e, text) => {
                if (!!text && text.length === 3) loadData(text);
            }}
            onChange={(e) => {
                const clientData = clients.concat(extraOptions).find((c) => c.value === e.target.value);
                if (!clientData) return;
                onClientSelect && onClientSelect(clientData);
            }}
            inputProps={{
                endAdornment: (
                    <>{loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon color="inherit" />}</>
                ),
            }}
            noOptionsText={
                onNoOptionAction ? (
                    <Button onClick={onNoOptionAction} style={{ marginTop: 10 }} variant="outlined">
                        {t("new_client")}
                    </Button>
                ) : (
                    t("no_option")
                )
            }
            {...inputProps}
            {...otherProps}
        />
    );
};

export default ClientsSearch;
