import { Button, Card, CardActions, CardContent, CardMedia, Grid, LinearProgress, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { DeleteRecordModal } from "../../components/common";
import { ProtectedAction } from "../../utils";

const defaultImage =
    "https://res.cloudinary.com/strowberry-code/image/upload/v1609761541/hb/bedroom_default_jtdbff.svg";
const RoomtypeList = () => {
    const [roomtypes, setRoomtypes] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "roomtypes",
            responseData: "_id name images ",
        });
        if (response?.roomtypes) setRoomtypes(response.roomtypes);
    };

    const onDeleteHandler = async (_id) => {
        const data = {};
        data._id = _id;

        const response = await fetch({
            operation: "mutation",
            endpoint: "deleteRoomType",
            data,
        });
        if (response?.deleteRoomType === "success") {
            setRoomtypes(roomtypes.filter((roomtype) => roomtype._id !== _id));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addRecordRoomtype = (data) => {
        if (roomtypes?.some((r) => r._id === data._id)) {
            setRoomtypes(
                roomtypes?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setRoomtypes([...roomtypes, data]);
    };

    return (
        <div>
            <Grid container style={{ margin: "20px 0" }}>
                <ProtectedAction permission={{ rooms: { list: true } }}>
                    <Button
                        component={Link}
                        to="/backoffice/rooms"
                        sx={{ borderRadius: "25px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("rooms")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ facilities: { list: true } }}>
                    <Button
                        component={Link}
                        to="/backoffice/facilities"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("facilities")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ bedtypes: { list: true } }}>
                    <Button
                        component={Link}
                        to="/backoffice/bedtypes"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("bedtypes")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ guestcategories: { list: true } }}>
                    <Button
                        component={Link}
                        to="/backoffice/guestcategories"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("guests")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ tags: { list: true } }}>
                    <Button
                        component={Link}
                        to="/backoffice/tags"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("tags")}
                    </Button>
                </ProtectedAction>
            </Grid>
            {loading && <LinearProgress color="primary" />}
            <Grid container spacing={2} alignItems="center">
                {roomtypes.map((roomtype) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <RoomTypeCard
                            t={t}
                            name={roomtype.name}
                            onClick={() => navigate(`${roomtype._id.split("roomtypes_")[1]}`)}
                            onDelete={() => onDeleteHandler(roomtype._id)}
                            defaultImage={roomtype.images?.[0] || defaultImage}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Button onClick={() => navigate("create")}>
                        <AddIcon style={{ fontSize: 200 }} />
                    </Button>
                </Grid>
            </Grid>
            <Outlet context={{ addRecordRoomtype }} />
        </div>
    );
};
const RoomTypeCard = ({ t, name, onClick, onDelete, defaultImage }) => {
    return (
        <Card>
            <CardMedia
                style={{ cursor: "pointer" }}
                onClick={onClick}
                height="200"
                component="img"
                src={defaultImage}
            />
            <CardContent style={{ cursor: "pointer" }} onClick={onClick}>
                <Typography variant="h5" component="h2">
                    {name}
                </Typography>
            </CardContent>
            <CardActions>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ProtectedAction permission={{ roomtypes: { delete: true } }}>
                            <DeleteRecordModal onConfirm={onDelete} />
                        </ProtectedAction>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

export default RoomtypeList;
