import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutoCompleteControl, InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
    discountValue: "required",
};
const discountTypeOptions = [
    {
        value: "total",
        label: "total",
    },
    {
        value: "percentage",
        label: "percentage",
    },
];

const PosDiscountForm = () => {
    const [values, setValues] = useState({});
    const [pospointsOptions, setPospointsOptions] = useState([]);
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordPosDiscount } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "posdiscount",
                    data: {
                        _id: "posdiscounts_" + params?.id,
                    },
                    responseData: "_id name pospoints discountValue discountType",
                },
                {
                    endpoint: "pospoints",
                    responseData: "_id name",
                },
            ],
        });
        if (response?.posdiscount) setValues(response.posdiscount);
        if (response?.pospoints) {
            setPospointsOptions(
                response?.pospoints?.map((pos) => {
                    return { value: pos?._id, label: pos.name };
                })
            );
        }
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "pospoints",
                    responseData: "_id name",
                },
            ],
        });

        if (response?.pospoints) {
            setPospointsOptions(
                response?.pospoints?.map((pos) => {
                    return { value: pos?._id, label: pos.name };
                })
            );
        }
    };

    const saveHandler = async () => {
        const { name, pospoints, discountValue, discountType, _id } = values;
        const data = {
            name,
            pospoints,
            discountValue: parseFloat(discountValue),
            discountType,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePosDiscount" : "createPosDiscount",
            data,
            responseData: "_id name pospoints discountValue discountType",
        });
        if (response?.createPosDiscount?._id || response?.updatePosDiscount?._id) {
            enqueueSnackbar(t("discount_saved"), { variant: "default" });
            addRecordPosDiscount && addRecordPosDiscount(response.createPosDiscount || response?.updatePosDiscount);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("discount")}
            maxWidth="sm"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ posdiscounts: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="discountValue" label={t("discount_value")} type="number" />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl name="discountType" label={t("discount_type")} options={discountTypeOptions} />
                </Grid>

                <Grid item xs={12}>
                    <AutoCompleteControl name="pospoints" label={t("pospoints")} options={pospointsOptions} multiple />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default PosDiscountForm;
