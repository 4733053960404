import {
    AppBar,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
    Grid,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";
import { Logo } from "../../components/common";
import Masonry from "@mui/lab/Masonry";

const PosplaceDisplay = () => {
    const [receipts, setReceipts] = useState([]);
    const [posplaceData, setPosplaceData] = useState({});
    const [totalUndoneItems, setTotalUndoneItems] = useState(0); // State to hold the total number of undone items

    const params = useParams();
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { t } = useTranslation();

    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        // Calculate the total number of undone items whenever receipts change
        let total = 0;
        receipts.forEach((receipt) => {
            receipt.products.forEach((product) => {
                if (!product.done) {
                    total += product.quantity;
                }
            });
        });
        setTotalUndoneItems(total);
    }, [receipts]);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "posplaceReceipts",
                    data: {
                        posplaceId: "posplaces_" + params.id,
                    },
                    responseData: `_id tableName userId createdAt status number
                        products{productId productName quantity note}
                    `,
                },
                { endpoint: "posplace", data: { _id: "posplaces_" + params.id }, responseData: "_id name" },
            ],
        });
        if (response?.posplaceReceipts) setReceipts(response.posplaceReceipts.filter((r) => r.status === 0));
        if (response?.posplace) setPosplaceData(response.posplace);
    };

    const toggleReceiptItem = (receiptIndex, itemIndex) => {
        const availableReceipts = JSON.parse(JSON.stringify(receipts));
        availableReceipts[receiptIndex].products[itemIndex].done = !Boolean(
            availableReceipts[receiptIndex].products[itemIndex].done
        );
        setReceipts(availableReceipts);
        if (availableReceipts[receiptIndex].products.every((product) => product.done))
            markComplete(availableReceipts[receiptIndex]._id);
    };

    const markComplete = async (_id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "finishPosplaceReceipt",
            data: { _id },
            responseData: "_id",
        });
        if (response?.finishPosplaceReceipt?._id)
            setTimeout(() => setReceipts(receipts.filter((receipt) => receipt._id !== _id)), 1000);
    };

    const calculateTimeDifference = (createdAt) => {
        const createdDateTime = DateTime.fromISO(createdAt);
        const now = DateTime.now();
        const diffInMinutes = now.diff(createdDateTime, "minutes").minutes;
        if (diffInMinutes < 10) return "#2a9d8f";
        else if (diffInMinutes >= 10 && diffInMinutes <= 35) return "#adb5bd";
        else return "#e76f51";
    };

    return (
        <div style={{ padding: 20 }}>
            <AppBar elevation={0} sx={{ backgroundColor: "white" }}>
                <Toolbar>
                    <IconButton onClick={() => navigate(-1)} color="primary">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ marginLeft: "20px" }} color="primary" variant="h6">
                        {posplaceData?.name}
                    </Typography>
                    <Logo width="40px" height="40px" style={{ left: "48%", position: "absolute" }} />
                    <Typography variant="subtitle1" color="primary" sx={{ marginLeft: "auto" }}>
                        {t("undone_items")}: {totalUndoneItems}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2} sx={{ marginTop: "50px" }}>
                {receipts.map((receipt, receiptIndex) => {
                    const headerBackgroundColor = calculateTimeDifference(receipt.createdAt);

                    return (
                        <Grid key={receipt._id}>
                            <Card style={{ borderBottom: 0 }}>
                                <CardHeader
                                    titleTypographyProps={{ variant: "h6" }}
                                    title={"#" + receipt?.number}
                                    subheader={`${DateTime.fromISO(receipt.createdAt).toFormat(
                                        "dd LLL yyyy HH:mm:ss"
                                    )} ${t("from")} ${receipt.userId?.split("users_")[1]}`}
                                    subheaderTypographyProps={{ variant: "subtitle2" }}
                                    action={
                                        <IconButton onClick={() => markComplete(receipt._id)} color="inherit">
                                            <DoneAllIcon />
                                        </IconButton>
                                    }
                                    sx={{ backgroundColor: headerBackgroundColor }}
                                />
                                <CardContent>
                                    <List>
                                        {receipt.products.map((product, productIndex) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton
                                                            onClick={() =>
                                                                toggleReceiptItem(receiptIndex, productIndex)
                                                            }
                                                            color={product.done ? "success" : "inherit"}
                                                        >
                                                            <TaskAltIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        sx={{
                                                            textDecorationLine: product.done ? "line-through" : "none",
                                                        }}
                                                        primary={product.quantity + " X " + product.productName}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </CardContent>
                            </Card>
                            <div
                                style={{
                                    background: "linear-gradient(45deg,#fff,#fff)",
                                    width: "22rem",
                                    padding: "10px",
                                    mask: "conic-gradient(from -45deg at bottom,#0000,#000 1deg 89deg,#0000 90deg) 50%/20px 100%",
                                }}
                            />
                        </Grid>
                    );
                })}
            </Masonry>
        </div>
    );
};

export default PosplaceDisplay;
