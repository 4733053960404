import { Chip, LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const TagList = () => {
    const [tags, setTags] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "description", header: t("description") },
        {
            accessorKey: "color",
            header: t("color"),
            cell: ({ row: { original } }) => {
                return <Chip sx={{ backgroundColor: original?.color, width: "8%" }} />;
            },
        },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "tags",
            responseData: "_id name color description",
        });
        if (response?.tags) setTags(response.tags);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteTags" : "deleteTag",
            data,
        });
        if (response?.deleteTags === "success" || response?.deleteTag === "success") {
            setTags(tags.filter((tag) => !rows.some((row) => row._id === tag._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addRecordTag = (data) => {
        if (tags?.some((r) => r._id === data._id)) {
            setTags(
                tags?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setTags([...tags, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("tags")}
                moduleType="backoffice"
                data={tags}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("tags_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordTag }} />
        </div>
    );
};

export default TagList;
