import { AppBar, Avatar, ListItem, ListItemText, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const BottomBarLegend = () => {
    const { t } = useTranslation();
    return (
        <AppBar elevation={0} position="fixed" sx={{ top: "auto", bottom: 0, backgroundColor: "transparent" }}>
            <Stack direction="row" spacing={2}>
                <ListItem style={{ width: "auto" }}>
                    <Avatar sx={{ backgroundColor: "#F8D210", width: "20px", height: "20px" }}>{""}</Avatar>
                    <ListItemText
                        sx={{ color: "navbarColor.contrastText", marginLeft: "10px" }}
                        primary={t("open_orders")}
                    />
                </ListItem>
                <ListItem style={{ width: "auto" }}>
                    <Avatar sx={{ backgroundColor: "#f74b07", width: "20px", height: "20px" }}>{""}</Avatar>
                    <ListItemText
                        sx={{ color: "navbarColor.contrastText", marginLeft: "10px" }}
                        primary={t("busy_table")}
                    />
                </ListItem>
                <ListItem style={{ width: "auto" }}>
                    <Avatar sx={{ backgroundColor: "#808080", width: "20px", height: "20px" }}>{""}</Avatar>
                    <ListItemText
                        sx={{ color: "navbarColor.contrastText", marginLeft: "10px" }}
                        primary={t("no_access")}
                    />
                </ListItem>
            </Stack>
        </AppBar>
    );
};

export default BottomBarLegend;
