import { Button, ButtonGroup, Divider, Grid, Card, CardContent, Stack, Typography, Chip } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import SendIcon from "@mui/icons-material/Send";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal";
import { ItemsTable, QRModal } from "./components";
import { Form, InputControl, SelectControl, SwitchControl } from "../../components/form";
import { DateRangeControl } from "../../components/dates";
import {
    ActionsPanel,
    ActionBox,
    AmountsCard,
    ClientsSearch,
    CancelRecordConfirm,
    EditPaymentsModal,
} from "../../components/common";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { useApi, useProducts } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { calculateTotalTax, getLoggedUserData, toCurrency, exchangeCurrency, ProtectedAction } from "../../utils";
import {
    invoiceTypeCodeOptions,
    languageOptions,
    paymentMeansCodeOptions,
    profileOptions,
} from "./components/fiscalOptions";
import PaymentsModal from "../../components/common/PaymentsModal";
import { DateTime } from "luxon";
import PaymentsView from "../../components/common/PaymentsView";
import { exportToPdf } from "./components/invoicePdf";
import { sendFiscalInvoice } from "../../components/integrations/fiscal";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid
    TRANSFERRED: "#FFC82C", //transferred to group
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const INVOICE_RESPONSE_DATA = `
    _id date dueDate finalizePeriod status cancelReason customer reservationIds totalPrice discountValue discountType number serial description
    paymentStatus payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp note}  
    fiscalData{nivf nslf eic BusinUnitCode operator TCR InvOrdNum IICRef IssueDateTime nipt SumInvIICRefs{IIC IssueDateTime} }
    tableData{itemId price taxData{taxId isIncluded} description quantity unit} 
    clientsData{_id firstName lastName IDNumber IDType addressLine country city}
    cashdesk invoiceCurrency fiscalCurrency pdfLanguage isEFiscal paymentOption invoiceCurrencyRate profileID invoiceTypeCode paymentMeansCode account
    reservationData{checkin checkout clientsData{firstName lastName}}
`;

const InvoiceForm = () => {
    const params = useParams();
    const location = useLocation();
    const { newInvoiceData } = location?.state || {};
    const [values, setValues] = useState(
        params?.invoiceId !== "create"
            ? { fiscalCurrency: "ALL" }
            : newInvoiceData
            ? { ...newInvoiceData, fiscalCurrency: "ALL" }
            : {
                  fiscalCurrency: "ALL",
                  date: DateTime.now().toFormat("yyyy-LL-dd"),
                  dueDate: DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"),
              }
    );
    const [disableSave, setDisableSave] = useState(false);
    const [cashdesks, setCashdesks] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentAccounts, setPaymentAccounts] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [settings, setSettings] = useState({});
    const [taxes, setTaxes] = useState([]);
    const [roomtypes, setRoomtypes] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openPaymentViewModal, setOpenPaymentViewModal] = useState(false);
    const [editPaymentModal, setEditPaymentModal] = useState({
        open: false,
        paymentData: null,
    });
    const { products, loadingProducts } = useProducts();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { addRecord, onNewInvoice, onInvoiceEdited, onNewReservationPayment, onReservationPaymentsEdit } =
        useOutletContext() || {};

    const [searchParams] = useSearchParams();
    const groupId = searchParams.get("groupId");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "cashdesks",
                responseData: "_id tcrCode fiscalBusinessId",
            },
            {
                endpoint: "paymentmethods",
                responseData: "_id name method fiscalMethod isActive",
            },
            {
                endpoint: "paymentaccounts",
                responseData: "_id name currency bank",
            },
            {
                endpoint: "currencies",
                responseData: "_id active currency currencyname rate",
            },
            {
                endpoint: "settingsgeneral",
                responseData:
                    "_id currencyData{currency currencyname rate} invoiceTypeCode paymentMeansCode profileID currency",
                data: { _id: "settings_general" },
            },
            {
                endpoint: "taxes",
                responseData: "_id name rate taxCategory",
            },
            {
                endpoint: "roomtypes",
                responseData: "_id name price taxData{taxId isIncluded}",
            },
        ];
        if (params?.invoiceId !== "create") {
            endpoints.push({
                endpoint: !!groupId ? "groupInvoice" : "invoice",
                data: {
                    _id: !!groupId
                        ? "groupinvoices_" + groupId + "_" + params?.invoiceId
                        : "invoices_" + params?.invoiceId,
                },
                responseData: INVOICE_RESPONSE_DATA,
            });
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });

        if (!!response?.cashdesks)
            setCashdesks(
                response.cashdesks.map((c) => {
                    return {
                        value: c._id,
                        label: c.tcrCode,
                        fiscalBusinessId: c.fiscalBusinessId,
                    };
                })
            );
        if (!!response?.paymentaccounts)
            setPaymentAccounts(
                response.paymentaccounts.map((a) => {
                    return {
                        value: a._id,
                        label: `${a.bank} - ${a.currency || ""}`,
                    };
                })
            );
        if (!!response?.currencies) {
            setCurrencies(response?.currencies);
        }
        if (!!response?.paymentmethods)
            setPaymentMethods(
                response.paymentmethods
                    ?.filter((p) => p.isActive && p.method !== "roomCharge")
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                            fiscalMethod: c.fiscalMethod,
                        };
                    })
            );
        if (!!response?.taxes) setTaxes(response.taxes);
        if (!!response?.settingsgeneral) {
            setSettings(response.settingsgeneral);
            if (!response.invoice || !response.groupInvoice) {
                const { profileID, invoiceTypeCode, paymentMeansCode, currencyData } = response.settingsgeneral || {};
                setValues({
                    ...values,
                    profileID,
                    invoiceTypeCode,
                    paymentMeansCode,
                    invoiceCurrency: values?.invoiceCurrency || currencyData?.currency,
                    cashdesk: response.invoice?.cashdesk ? response.invoice?.cashdesk : response.cashdesks?.[0]?._id,
                    fiscalCurrency: !!response?.invoice?.fiscalCurrency ? response.invoice?.fiscalCurrency : "ALL",
                });
            }
        }
        if (!!response?.roomtypes)
            setRoomtypes(
                response.roomtypes.map((r) => {
                    return { ...r, unit: "nights" };
                })
            );
        if (response?.invoice) {
            const { profileID, invoiceTypeCode, paymentMeansCode, currencyData } = response.settingsgeneral || {};
            setValues({
                ...values,
                ...response.invoice,
                profileID: response.invoice.profileID || profileID,
                invoiceTypeCode: response.invoice.invoiceTypeCode || invoiceTypeCode,
                paymentMeansCode: response.invoice.paymentMeansCode || paymentMeansCode,
                invoiceCurrency: response.invoice.invoiceCurrency || currencyData?.currency,
                cashdesk: response.invoice?.cashdesk ? response.invoice?.cashdesk : response.cashdesks?.[0]?._id,
                fiscalCurrency: !!response?.invoice?.fiscalCurrency ? response.invoice?.fiscalCurrency : "ALL",
                invoiceCurrencyRate: isNaN(parseFloat(response?.invoice?.invoiceCurrencyRate))
                    ? undefined
                    : response?.invoice?.invoiceCurrencyRate,
                date: !!response.invoice.date ? response.invoice.date : DateTime.now().toFormat("yyyy-LL-dd"),
                dueDate: !!response.invoice.date
                    ? response.invoice.dueDate
                    : DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"),
            });
        } else if (response?.groupInvoice) {
            const { profileID, invoiceTypeCode, paymentMeansCode, currencyData } = response.settingsgeneral || {};
            setValues({
                ...values,
                ...response.groupInvoice,
                profileID: response.groupInvoice.profileID || profileID,
                invoiceTypeCode: response.groupInvoice.invoiceTypeCode || invoiceTypeCode,
                paymentMeansCode: response.groupInvoice.paymentMeansCode || paymentMeansCode,
                invoiceCurrency: response.groupInvoice.invoiceCurrency || currencyData?.currency,
                cashdesk: response.groupInvoice?.cashdesk
                    ? response.groupInvoice?.cashdesk
                    : response.cashdesks?.[0]?._id,
                fiscalCurrency: !!response?.invoice?.fiscalCurrency ? response.invoice?.fiscalCurrency : "ALL",
                date: !!response.invoice.date ? response.invoice.date : DateTime.now().toFormat("yyyy-LL-dd"),
                dueDate: !!response.invoice.date
                    ? response.invoice.dueDate
                    : DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"),
            });
        }
    };

    const invoicePayments = useMemo(() => {
        return values?.payments?.map((payment, index) => {
            return { ...payment, uniqueId: Date.now() + index };
        });
    }, [values?.payments]);

    const allowEdit = useMemo(() => {
        if (values?.status === "CANCELLED") return false;
        if (values?.editable) return true;
        if (values?.fiscalData?.IICRef) return false;
        return true;
    }, [values?.status, values?.fiscalData, values?.editable]);

    const productOptions = useMemo(() => {
        return products.map((p) => {
            return {
                _id: p?._id,
                name: p?.name,
                price: p?.price,
                taxData: p?.taxData,
                unit: p?.measureUnit || p?.purchaseUnit || "",
            };
        });
    }, [products]);
    const options = roomtypes.concat(productOptions);

    const generateEInvoicePdf = async () => {
        if (!values.fiscalData?.eic || !values?.cashdesk) return;
        const { fiscalBusinessId } = cashdesks?.find((c) => c.value === values?.cashdesk) || {};
        if (!fiscalBusinessId) return;

        const businessCashdeskResponse = await fetch({
            operation: "query",
            endpoint: "businessCashdesk",
            data: {
                fiscalBusinessId: fiscalBusinessId,
            },
            responseData: "tcrCode fiscalBusiness{businessUnitCode password withVat nipt businessName certificate}",
        });
        if (businessCashdeskResponse?.businessCashdesk?.fiscalBusiness?.certificate) {
            const response = await fetch({
                operation: "mutation",
                endpoint: "getEInvoice",
                data: {
                    eic: values?.fiscalData?.eic,
                    certificateData:
                        businessCashdeskResponse?.businessCashdesk?.fiscalBusiness?.certificate[0]?.split(
                            ";base64,"
                        )[1],
                    certificatePassword: businessCashdeskResponse?.businessCashdesk?.fiscalBusiness?.password,
                },
                responseData: "eic pdf",
            });
            if (!!response?.getEInvoice?.pdf) {
                const linkSource = "data:application/pdf;base64," + response?.getEInvoice?.pdf;
                const downloadLink = document.createElement("a");
                const fileName = `invoice_${values.fiscalData?.eic}` + ".pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }
    };

    const finalizeInvoice = async (invoiceData, canceled = false) => {
        const items = invoiceData?.tableData?.map((item) => {
            const itemData = options?.find((i) => i._id === item.itemId) || {};
            return {
                name: itemData?.name,
                price: item.price,
                quantity: item.quantity,
                unit: item.unit,
                taxData: itemData?.taxData?.map((t) => {
                    return {
                        isIncluded: t.isIncluded,
                        taxId: t.taxId,
                    };
                }),
            };
        });
        const payments =
            invoiceData?.payments?.map((payment) => ({
                paymentMethod: payment?.paymentMethod,
                amount: payment?.amount,
            })) || [];
        const { fiscalBusinessId } = cashdesks?.find((c) => c.value || values?.cashdesk) || {};
        const data = {
            fetch,
            fiscalBusinessId,
            items: items,
            discount: {
                type: invoiceData?.discountType,
                value: invoiceData?.discountValue,
            },
            totalPrice: invoiceData?.totalPrice,
            payments: payments,
            paymentMethod: values?.paymentOption,
            buyerData: values?.clientsData,
            cancelled: Boolean(canceled),
            existingFiscalData: values?.fiscalData,
            SumInvIICRefs: values?.fiscalData?.SumInvIICRefs || null,
            invoicePeriod: !!values?.finalizePeriod
                ? {
                      date: values.date,
                      dueDate: values.dueDate,
                  }
                : null,
            invoiceCurrency: invoiceData?.invoiceCurrency,
            profileID: values?.profileID,
            dueDate: values?.dueDate,
            invoiceTypeCode: values?.invoiceTypeCode,
            paymentMeansCode: values?.paymentMeansCode,
            account: values?.account,
            invoiceCurrency: values?.invoiceCurrency,
            fiscalCurrency: values?.fiscalCurrency,
            isFiscalCurrencyRateSpecified: false,
            fiscalCurrencyRate: null,
        };
        const invoiceCurrencyRate = parseFloat(values.invoiceCurrencyRate);
        if (!isNaN(invoiceCurrencyRate)) {
            data.fiscalCurrencyRate = parseFloat(values.invoiceCurrencyRate);
            data.isFiscalCurrencyRateSpecified = true;
        }
        if (values?.isEFiscal) data.hasEInvoice = true;
        return await sendFiscalInvoice(data);
    };

    const saveHandler = async (finalize = false) => {
        setDisableSave(true);
        const {
            date,
            dueDate,
            invoiceCurrency,
            cashdesk,
            paymentOption,
            fiscalCurrency,
            invoiceCurrencyRate,
            profileID,
            invoiceTypeCode,
            paymentMeansCode,
            customer,
            serial,
            pdfLanguage,
            description,
            tableData,
            discountValue,
            discountType,
            isEFiscal,
            _id,
            reservationIds,
            account,
            payments,
            finalizePeriod,
        } = values;

        tableData?.forEach((item) => {
            item.quantity = parseFloat(item.quantity);
            item.price = parseFloat(item.price);
            item.taxData = item?.taxData?.map(({ taxId, isIncluded }) => ({ taxId, isIncluded }));
        });
        const totalPrice = parseFloat(amountInfo()?.totalPrice - amountInfo()?.discount);
        const data = {
            date,
            dueDate,
            invoiceCurrency,
            cashdesk,
            paymentOption,
            fiscalCurrency,
            invoiceCurrencyRate,
            profileID,
            invoiceTypeCode,
            paymentMeansCode,
            customer: values?.clientsData?._id || customer,
            serial,
            pdfLanguage,
            description,
            discountValue: discountValue ? parseFloat(discountValue) : null,
            discountType,
            totalPrice: isNaN(totalPrice) ? 0.0 : parseFloat(totalPrice.toFixed(2)),
            isEFiscal,
            account: paymentMeansCode === "42" ? account : null,
            payments:
                payments?.map((payment) => {
                    return {
                        amount: payment.amount,
                        paymentMethod: payment.paymentMethod,
                        timestamp: payment.timestamp,
                        userName: payment.userName,
                        note: payment.note,
                    };
                }) || [],
            finalizePeriod,
        };

        if (_id) data._id = _id;
        if (!_id && newInvoiceData?.isGenerated) {
            data.status = "APPROVED";
        }
        if (!groupId) {
            data.tableData = tableData;
            data.reservationIds = reservationIds;
        }

        if (finalize) {
            try {
                const { SameTaxes, ...fiscalData } =
                    (await finalizeInvoice({
                        ...data,
                        payments: values.payments || [],
                    })) || {};
                if (fiscalData) {
                    data.fiscalData = fiscalData;
                    data.status = "APPROVED";
                    enqueueSnackbar(t("invoice_finalized"), { variant: "success" });
                    if (Boolean(values?.isEFiscal)) {
                        if (fiscalData.eic) {
                            enqueueSnackbar(t("einvoice_finalized"), { variant: "success" });
                        } else {
                            enqueueSnackbar(t("einvoice_failed"), { variant: "error" });
                        }
                    }
                }

                if (!data?.payments?.length > 0) {
                    const { fiscalMethod } = paymentMethods?.find((m) => m.value === data?.paymentOption) || {};
                    if (
                        ["BANKNOTE", "CARD", "CHECK", "SVOUCHER", "COMPANY", "ORDER", "CASH"].includes(
                            fiscalMethod?.toUpperCase()
                        )
                    ) {
                        data.payments = [
                            {
                                amount: data?.totalPrice,
                                paymentMethod: data?.paymentOption,
                                note: "",
                            },
                        ];
                    }
                } else {
                    const correctPaymentDifference =
                        parseFloat(data?.totalPrice) -
                            data?.payments?.reduce((total, method) => {
                                return total + method.amount;
                            }, 0.0) || 0.0;
                    if (correctPaymentDifference !== 0) {
                        const { fiscalMethod } =
                            paymentMethods?.find((m) => m.value === data.payments[0].paymentMethod) || {};
                        if (
                            ["BANKNOTE", "CARD", "CHECK", "SVOUCHER", "COMPANY", "ORDER", "CASH"].includes(
                                fiscalMethod?.toUpperCase()
                            )
                        ) {
                            data.payments[0].amount += correctPaymentDifference;
                        }
                    }
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar(t("finalize_failed"), { variant: "error" });
                if (!!err.message && err.message !== "finalize_failed") {
                    enqueueSnackbar(t(err.message), { variant: "error" });
                }
            }
        }

        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? (!!groupId ? "updateGroupInvoice" : "updateInvoice") : "createInvoice",
            data,
            responseData: INVOICE_RESPONSE_DATA,
        });
        if (response?.createInvoice?._id || response?.updateInvoice?._id || response?.updateGroupInvoice?._id) {
            // if(isReservationGenerated) //clear respayments
            if (isReservationGenerated) {
                const clearPaymentResponse = await fetch({
                    operation: "mutation",
                    endpoint: "clearReservationsPayments",
                    data: { reservationIds },
                });
            }
            setValues(values?._id ? response.updateInvoice || response?.updateGroupInvoice : response.createInvoice);
            enqueueSnackbar(t("invoice_saved"), { variant: "default" });
            addRecord &&
                addRecord(
                    values?._id ? response.updateInvoice || response?.updateGroupInvoice : response.createInvoice
                );
            if (response?.createInvoice?._id) {
                onNewInvoice && onNewInvoice(response?.createInvoice);
            }
            if (response?.updateInvoice?._id) {
                onInvoiceEdited && onInvoiceEdited(response?.updateInvoice);
            }
            if (response?.fiscalData?.nivf) printPdf({ type: "save" });
            return response?.createInvoice?._id || response?.updateInvoice?._id || response?.updateGroupInvoice?._id;
        }
        return undefined;
    };

    const updateStatus = async (newStatus) => {
        if (!values?._id || !newStatus) return;
        const response = await fetch({
            operation: "mutation",
            endpoint: !!groupId ? "updateGroupInvoice" : "updateInvoiceStatus",
            data: {
                _id: values?._id,
                status: newStatus,
            },
            responseData:
                "_id number timestamp date dueDate status totalPrice invoiceCurrency clientsData{firstName lastName} number",
        });
        if (response?.updateInvoiceStatus?.status || response?.updateGroupInvoice?.status) {
            enqueueSnackbar(t("status_updated"), { variant: "default" });
            addRecord && addRecord(response.updateInvoiceStatus || response?.updateGroupInvoice);
            onInvoiceEdited &&
                onInvoiceEdited({
                    _id: response?.updateInvoiceStatus?._id,
                    status: response?.updateInvoiceStatus?.status,
                });
            setValues({
                ...values,
                status: response?.updateInvoiceStatus?.status || response?.updateGroupInvoice?.status,
            });
        }
    };
    const updatePayment = async (updatedPaymentData) => {
        setEditPaymentModal({ open: false, paymentData: null });
        if (!updatedPaymentData) return;
        const recordPayments = invoicePayments?.map((payment) => {
            if (payment.uniqueId === updatedPaymentData.uniqueId) {
                const amount = parseFloat(updatedPaymentData.amount);
                return {
                    amount: isNaN(amount) ? 0 : amount,
                    paymentMethod: updatedPaymentData.paymentMethod,
                    timestamp: updatedPaymentData.timestamp,
                    userName: updatedPaymentData.userName,
                    note: updatedPaymentData.note,
                };
            }
            return {
                amount: payment.amount,
                paymentMethod: payment.paymentMethod,
                timestamp: payment.timestamp,
                userName: payment.userName,
                note: payment.note,
            };
        });
        const data = {
            _id: isReservationGenerated ? values?.reservationIds?.[0] : values._id,
            payments: recordPayments,
        };
        if (!data._id) return;
        if (isReservationGenerated) {
            const response = await fetch({
                operation: "mutation",
                endpoint: "updateReservationPayments",
                data,
                responseData:
                    "_id payments{amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note}",
            });
            if (response?.updateReservationPayments?._id) {
                setValues({
                    ...values,
                    payments: response?.updateReservationPayments?.payments,
                });
                enqueueSnackbar(t("payment_updated"), { variant: "info" });
                onReservationPaymentsEdit && onReservationPaymentsEdit(response?.updateReservationPayments?.payments);
            }
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateInvoice",
            data,
            responseData:
                "_id payments{amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note}",
        });
        if (response?.updateInvoice?._id) {
            setValues({
                ...values,
                ...response?.updateInvoice,
            });
            onInvoiceEdited && onInvoiceEdited(response.updateInvoice);
            enqueueSnackbar(t("payment_updated"), { variant: "info" });
        }
    };
    const calculateTotalDiscount = (totalPrice = 0) => {
        if (!values.discountType || !values.discountValue) return 0;
        const discountAmount =
            values.discountType === "total"
                ? parseFloat(values.discountValue)
                : totalPrice * parseFloat(values.discountValue) * 0.01;
        if (isNaN(discountAmount)) return 0;
        return discountAmount;
    };

    const amountInfo = () => {
        let totalPrice = 0.0;
        let subtotal = 0.0;
        let discount = 0.0;
        let taxTotal = 0.0;

        values?.tableData?.forEach((item) => {
            const { totalAmount, includedAmount } = calculateTotalTax({
                taxData: item.taxData?.map((t) => ({
                    isIncluded: t.isIncluded,
                    tax: taxes.find((tax) => tax._id === t.taxId),
                })),
                price: parseFloat(item.price),
            });
            taxTotal += totalAmount * item.quantity;
            totalPrice += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
        });
        subtotal = totalPrice - taxTotal;
        discount = calculateTotalDiscount(totalPrice);

        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
            subtotal: isNaN(subtotal) ? 0.0 : subtotal,
            taxTotal: isNaN(taxTotal) ? 0.0 : taxTotal,
            discount: isNaN(discount) ? 0.0 : discount,
        };
    };

    const totalPriceByCurrencies = useMemo(() => {
        const amounts = [];
        const invoiceCurrency = values?.invoiceCurrency || "ALL";
        currencies
            ?.filter((currency) => !!currency.active && currency.currency !== invoiceCurrency)
            ?.forEach((currency) => {
                amounts.push({
                    currency: currency.currency,
                    amount: exchangeCurrency({
                        amount: amountInfo()?.totalPrice - amountInfo()?.discount,
                        fromCurrency: invoiceCurrency,
                        toCurrency: currency.currency,
                        currencies,
                    }),
                });
            });
        return amounts;
    }, [amountInfo()?.totalPrice, amountInfo()?.discount, currencies, values?.invoiceCurrency]);

    const addPayment = async (payment) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: !!groupId ? "addGroupInvoicePayment" : "addInvoicePayment",
            data: {
                _id: values._id,
                amount: parseFloat(payment.amount),
                paymentMethod: payment.paymentMethod,
                timestamp: new Date(payment.timestamp).toJSON(),
                note: payment.note,
            },
            responseData: "amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note",
        });
        if (response?.addInvoicePayment?.amount || response?.addGroupInvoicePayment?.amount) {
            enqueueSnackbar(t("payment_added"), { variant: "default" });
            setValues({
                ...values,
                payments: [
                    ...(values?.payments || []),
                    response?.addInvoicePayment ? response?.addInvoicePayment : response?.addGroupInvoicePayment,
                ],
            });
            setOpenModal(false);
            onInvoiceEdited && onInvoiceEdited({ _id: values._id, payments: [response?.addInvoicePayment] });
        } else {
            enqueueSnackbar(t("add_payment_failed"), { variant: "error" });
            setOpenModal(false);
        }
    };
    const addPaymentToReservation = async (payment = {}) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "addReservationPayment",
            data: {
                _id: values?.reservationIds?.[0],
                amount: parseFloat(payment?.amount),
                paymentMethod: payment?.paymentMethod,
                timestamp: payment?.timestamp ? new Date(payment.timestamp).toJSON() : null,
                note: payment.note,
            },
            responseData: "amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note",
        });
        if (response?.addReservationPayment?.paymentMethod) {
            enqueueSnackbar(t("payment_added"), { variant: "default" });
            setValues({
                ...values,
                payments: [...(values?.payments || []), response?.addReservationPayment],
            });
            setOpenModal(false);
            onNewReservationPayment && onNewReservationPayment(response?.addReservationPayment);
        } else {
            enqueueSnackbar(t("add_payment_failed"), { variant: "error" });
            setOpenModal(false);
        }
    };

    const amountPaid = useMemo(() => {
        if (!values?.payments || values?.payments?.length === 0) return 0.0;
        const amount = values?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0);
        if (isNaN(amount)) return 0.0;
        return amount;
    }, [values.payments]);

    const amountUnpaid = () => {
        const amount = amountInfo().totalPrice - amountInfo().discount - amountPaid;
        if (isNaN(amount)) return amountInfo().totalPrice;
        if (amount < 0) return 0.0;
        return amount;
    };
    const printPdf = async (printOptions = {}) => {
        exportToPdf({
            values,
            t,
            fetch,
            resource: values?._id ? "invoice" : "generatedInvoice",
            options: printOptions,
            itemsOptions: options,
            convertedCurrencies: totalPriceByCurrencies,
            hideReservationDetails: values?.hideReservationDetails,
        });
    };

    const displayInvoiceStatus = useMemo(() => {
        if (["CANCELLED", "TRANSFERRED"].includes(values?.status)) {
            return values?.status;
        }
        if (amountUnpaid() <= 0 && values?.payments?.length > 0) {
            return "PAID";
        }
        return values?.status || "DRAFT";
    }, [values?.status, values?.payments]);

    const onInvoiceCancel = async ({ cancelReason }) => {
        const data = {
            _id: values?._id,
            cancelReason,
        };

        if (values?.fiscalData?.IICRef) {
            try {
                const invoiceData = {
                    tableData: values?.tableData,
                    payments: values?.payments,
                    discountType: values?.discountType,
                    discountValue: values?.discountValue,
                    totalPrice: values?.totalPrice,
                };
                const { SameTaxes, ...fiscalData } = await finalizeInvoice(
                    {
                        ...invoiceData,
                        payments: values.payments || [],
                    },
                    true
                );
                if (fiscalData) {
                    data.cancelFiscalData = fiscalData;
                    enqueueSnackbar(t("invoice_finalized"));
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar(t("finalize_failed"));
            }
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "cancelInvoice",
            data,
            responseData: "_id status",
        });
        if (response?.cancelInvoice?._id) {
            enqueueSnackbar(t("invoice_cancelled"), { variant: "success" });
            setValues({ ...values, ...response?.cancelInvoice });
            onInvoiceEdited && onInvoiceEdited(response?.cancelInvoice);
        }
    };

    const isReservationGenerated = useMemo(() => {
        if (values?._id && values._id.startsWith("invoices_")) return false;
        if (params?.invoiceId === "create" && !!newInvoiceData) return true;
        return false;
    }, [newInvoiceData, params?.invoiceId, values?._id]);

    const onNewClient = (newClientData) => {
        setValues({ ...values, clientsData: newClientData });
    };

    const fiscalCurrencyRate = useMemo(() => {
        const fiscalCurrency = values?.fiscalCurrency || "ALL";
        const invoiceCurrency = values?.invoiceCurrency || "ALL";
        if (invoiceCurrency === "ALL") return 1;
        if (fiscalCurrency === "ALL") return 1;
        if (values?.invoiceCurrencyRate || values?.invoiceCurrencyRate === "") return values?.invoiceCurrencyRate;
        return exchangeCurrency({
            amount: 1,
            currencies: currencies,
            fromCurrency: fiscalCurrency,
            toCurrency: "ALL",
        });
    }, [values?.invoiceCurrencyRate, values?.fiscalCurrency, values?.invoiceCurrency]);

    const fiscalCurrenciesOptions = useMemo(() => {
        return currencies
            ?.filter((currency) => {
                if (!values?.invoiceCurrency) return currency.currency === "ALL";
                return ["ALL", values?.invoiceCurrency]?.includes(currency.currency);
            })
            ?.map((currency) => {
                return { value: currency.currency, label: currency.currencyname };
            });
    }, [currencies, values?.invoiceCurrency]);

    const currenciesOptions = useMemo(() => {
        return currencies?.map((currency) => {
            return { value: currency.currency, label: currency.currencyname };
        });
    }, [currencies]);

    const disableActions = useMemo(() => {
        const { invoices } = getLoggedUserData()?.role?.permissions || {};
        const actions = {
            disableStatusChange: false,
            disableVoid: true,
            disableEditableSwitch: true,
            disableDiscount: true,
            disableFullEdit: false,
            disableFinalize: false,
        };

        if (!values._id) {
            actions.disableDiscount = false;
            actions.disableStatusChange = true;
        } else {
            if (!!invoices?.correct) {
                actions.disableVoid = false;
                actions.disableEditableSwitch = false;
                actions.disableDiscount = false;
            }
        }

        if (!!values?.fiscalData?.nivf) {
            actions.disableStatusChange = true;
            actions.disableFullEdit = true;
            actions.disableFinalize = true;
        }
        if (["CANCELLED", "TRANSFERRED"].includes(values?.status)) {
            actions.disableStatusChange = true;
            actions.disableFullEdit = true;
            actions.disableFinalize = true;
        }
        if (!!values?.editable) {
            actions.disableFullEdit = false;
        }
        return actions;
    }, [values?._id, values?.fiscalData, values?.editable, values?.status]);

    const disableAddPayment = useMemo(() => {
        if (values?.fiscalData?.nivf) {
            if (
                values?.payments?.some((payment) =>
                    ["BANKNOTE", "CARD", "CHECK", "SVOUCHER", "COMPANY", "ORDER", "CASH"].includes(
                        payment?.paymentMethodData?.fiscalMethod
                    )
                )
            ) {
                return true;
            }
        }
        return false;
    }, [values?.fiscalData, values?.payments]);

    return (
        <>
            <Modal
                fullScreen
                open
                titlelabel={t("invoices_form")}
                onClose={() => navigate(-1)}
                loading={loading}
                FormProps={{
                    values,
                    // rules,
                    onValuesChange: setValues,
                    onSubmit: () => saveHandler(false),
                }}
                permission={{ invoices: params?.invoiceId === "create" ? { create: true } : { edit: true } }}
                yesText={isReservationGenerated ? t("issue_invoice") : allowEdit ? t("save") : "none"}
                noText={isReservationGenerated ? "none" : t("close")}
                yesDisabled={disableSave}
            >
                <ActionsPanel>
                    <ActionBox width={350} title={t("status")}>
                        <ButtonGroup fullWidth disableElevation>
                            <Button
                                disabled={values?.status === "DRAFT" || disableActions.disableStatusChange}
                                onClick={() => {
                                    updateStatus("DRAFT");
                                }}
                            >
                                {t("draft")}
                            </Button>
                            <Button
                                disabled={values?.status === "APPROVED" || disableActions.disableStatusChange}
                                onClick={() => {
                                    updateStatus("APPROVED");
                                }}
                            >
                                {t("approved")}
                            </Button>
                        </ButtonGroup>

                        <Chip
                            sx={{ marginTop: 1, backgroundColor: statusColors[displayInvoiceStatus] }}
                            label={t(displayInvoiceStatus)}
                        />

                        <Stack mt={2} direction="row" justifyContent="space-between">
                            <Typography fontWeight={700} color="green">
                                {t("paid")}: {toCurrency(amountPaid, values?.invoiceCurrency)}
                            </Typography>
                            <Typography fontWeight={700} color="red">
                                {t("unpaid")}: {toCurrency(amountUnpaid(), values?.invoiceCurrency)}
                            </Typography>
                        </Stack>
                    </ActionBox>
                    <ActionBox title={t("payment")}>
                        <Button
                            variant="outlined"
                            sx={{ marginBottom: 1 }}
                            startIcon={<AddIcon />}
                            onClick={() => setOpenModal(true)}
                            disabled={disableAddPayment}
                        >
                            {t("add_payment")}
                        </Button>
                        <Button
                            startIcon={<AttachMoneyIcon />}
                            onClick={() => setOpenPaymentViewModal(true)}
                            disabled={!values?.payments?.length}
                        >
                            {t("view_payments")}
                        </Button>
                    </ActionBox>
                    <ActionBox title={t("print")}>
                        <Button
                            variant="outlined"
                            sx={{ marginBottom: 1 }}
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => printPdf({ type: "save" })}
                            disabled={loadingProducts}
                        >
                            {t("download")}
                        </Button>
                        <Button
                            disabled={loadingProducts}
                            startIcon={<PrintIcon />}
                            onClick={() => printPdf({ type: "autoprint" })}
                        >
                            {t("print")}
                        </Button>
                        {!values?.fiscalData?.eic ? null : (
                            <Button variant="outlined" onClick={() => generateEInvoicePdf()} sx={{ marginBottom: 1 }}>
                                {t("einvoice_pdf")}
                            </Button>
                        )}
                        <Button startIcon={<SendIcon />} disabled>
                            {t("send")}
                        </Button>
                    </ActionBox>
                    <ActionBox title={t("edit")}>
                        <ProtectedAction permission={{ invoices: { delete: true } }}>
                            <CancelRecordConfirm
                                onConfirm={onInvoiceCancel}
                                disabled={["CANCELLED", "TRANSFERRED"]?.includes(values?.status) || !values?._id}
                            />
                        </ProtectedAction>
                        <Button sx={{ marginTop: 1 }} disabled startIcon={<SplitscreenIcon />}>
                            {t("split")}
                        </Button>
                        {(!disableActions.disableEditableSwitch || values?.editable) && (
                            <ProtectedAction permission={{ invoices: { correct: true } }}>
                                <SwitchControl
                                    value={values?.editable}
                                    onChange={(e) => setValues({ ...values, editable: e.target.checked })}
                                    label={t("edit")}
                                />
                            </ProtectedAction>
                        )}
                    </ActionBox>
                    {cashdesks.length > 0 && (
                        <ActionBox title={t("finalize")}>
                            <Button
                                variant="outlined"
                                onClick={() => saveHandler(true)}
                                sx={{ marginBottom: 1 }}
                                disabled={disableActions.disableFinalize}
                                startIcon={<AccountBalanceIcon />}
                            >
                                {t("finalize")}
                            </Button>
                            {values?.fiscalData?.nivf ? (
                                <QRModal
                                    IICRef={values?.fiscalData?.IICRef}
                                    IssueDateTime={values?.fiscalData?.IssueDateTime}
                                    nipt={values?.fiscalData?.nipt}
                                    totalPrice={values?.totalPrice}
                                />
                            ) : null}
                            <SwitchControl
                                disabled={disableActions.disableFullEdit}
                                name="isEFiscal"
                                label={t("e_invoice")}
                            />
                            {values?.isEFiscal ? null : (
                                <SelectControl
                                    disabled={disableActions.disableFullEdit}
                                    name="cashdesk"
                                    label={t("tcr")}
                                    options={cashdesks}
                                />
                            )}
                        </ActionBox>
                    )}

                    <ActionBox title={t("invoice_details")} width={300}>
                        <Typography variant="h6" sx={{ textAlign: "right", marginBottom: 1 }}>
                            #{values?.number || "AUTO"}
                        </Typography>
                        <DateRangeControl
                            startLabel={t("date")}
                            endLabel={t("due_date")}
                            onChange={({ startDate, endDate }) => {
                                if (disableActions.disableFullEdit) return;
                                setValues({ ...values, date: startDate, dueDate: endDate });
                            }}
                            values={{ startDate: values?.date || null, endDate: values?.dueDate || null }}
                        />
                        {values?.isEFiscal ? (
                            <SwitchControl
                                disabled={disableActions.disableFullEdit}
                                name="finalizePeriod"
                                label={t("finalize_period")}
                            />
                        ) : null}
                        {values?.reservationIds?.length > 0 && (
                            <SwitchControl
                                name="hideReservationDetails"
                                label={t("hide_reservation_details")}
                                labelPlacement="left"
                            />
                        )}

                        <SelectControl
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    invoiceCurrency: e.target.value,
                                    invoiceCurrencyRate: undefined,
                                    fiscalCurrency:
                                        e.target.value === "ALL"
                                            ? "ALL" // when new invoiceCurrency === ALL => fiscalCurrency = ALL
                                            : !["ALL", e.target.value].includes(values?.fiscalCurrency)
                                            ? "ALL" // when new invoiceCurrency !== fiscalCurrency => fiscalCurrency = ALL
                                            : values?.fiscalCurrency || "ALL",
                                });
                            }}
                            value={values?.invoiceCurrency || ""}
                            label={t("invoice_currency")}
                            options={currenciesOptions}
                            disabled={disableActions.disableFullEdit}
                        />
                    </ActionBox>
                </ActionsPanel>
                {cashdesks.length > 0 && (
                    <div>
                        <div style={{ margin: "20px 0" }}>
                            <Typography variant="h6">{t("fiscalization_details")}</Typography>
                            <Divider />
                        </div>

                        <Form values={values} onValuesChange={setValues}>
                            <Grid container spacing={2}>
                                <Grid item md={4} sm={6} xs={12}>
                                    <SelectControl
                                        name="paymentOption"
                                        label={t("payment_type")}
                                        options={paymentMethods}
                                        disabled={disableActions.disableFullEdit}
                                    />
                                </Grid>
                                <Grid item md={2} sm={3} xs={12}>
                                    <SelectControl
                                        name="fiscalCurrency"
                                        label={t("fiscal_currency")}
                                        options={fiscalCurrenciesOptions}
                                        disabled={disableActions.disableFullEdit}
                                    />
                                </Grid>
                                <Grid item md={2} sm={3} xs={12}>
                                    <InputControl
                                        onChange={(e) => setValues({ ...values, invoiceCurrencyRate: e.target.value })}
                                        value={fiscalCurrencyRate}
                                        label={t("currency_rate")}
                                        disabled={disableActions.disableFullEdit || values?.fiscalCurrency === "ALL"}
                                    />
                                </Grid>
                                {values?.isEFiscal ? (
                                    <>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <SelectControl
                                                name="profileID"
                                                label={t("business_process")}
                                                options={profileOptions}
                                                disabled={disableActions.disableFullEdit}
                                            />
                                        </Grid>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <SelectControl
                                                name="invoiceTypeCode"
                                                label={t("invoice_type")}
                                                options={invoiceTypeCodeOptions}
                                                disabled={disableActions.disableFullEdit}
                                            />
                                        </Grid>
                                        <Grid item md={4} sm={6} xs={12}>
                                            <SelectControl
                                                name="paymentMeansCode"
                                                label={t("payment_means_code")}
                                                options={paymentMeansCodeOptions}
                                                disabled={disableActions.disableFullEdit}
                                            />
                                        </Grid>
                                        {values?.paymentMeansCode === "42" && (
                                            <Grid item md={4} sm={6} xs={12}>
                                                <SelectControl
                                                    name="account"
                                                    label={t("account")}
                                                    options={paymentAccounts}
                                                    disabled={disableActions.disableFullEdit}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                ) : null}
                            </Grid>
                        </Form>
                    </div>
                )}
                <div style={{ margin: "20px 0" }}>
                    <Typography variant="h6">{t("invoice_details")}</Typography>
                    <Divider />
                </div>
                <Form values={values} onValuesChange={setValues}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={5} md={4}>
                            <Grid item xs={12}>
                                <ClientsSearch
                                    onClientSelect={(data) => {
                                        setValues({ ...values, clientsData: data });
                                    }}
                                    disabled={disableActions.disableFullEdit}
                                    onNoOptionAction={() => navigate("client/create")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {values?.clientsData?.IDNumber}
                                        </Typography>
                                        <Typography variant="h6" component="div">
                                            {(values?.clientsData?.firstName || "") +
                                                " " +
                                                (values?.clientsData?.lastName || "")}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {values?.clientsData?.country}
                                        </Typography>
                                        <Typography variant="body2">
                                            {(values?.clientsData?.addressLine || "") +
                                                " " +
                                                (values?.clientsData?.city || "")}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={1} md={4}></Grid>
                        <Grid container item xs={12} sm={6} md={4} spacing={2}>
                            <Grid item xs={6}>
                                <InputControl
                                    disabled={disableActions.disableFullEdit}
                                    name="serial"
                                    label={t("serial")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl name="pdfLanguage" label={t("pdf_language")} options={languageOptions} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControl
                                    multiline
                                    minRows={3}
                                    name="description"
                                    label={t("description")}
                                    disabled={disableActions.disableFullEdit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
                <div style={{ margin: "20px 0" }}>
                    <Typography variant="h6">{t("items")}</Typography>
                    <Divider />
                </div>
                <ItemsTable
                    data={values?.tableData}
                    options={options}
                    taxesOptions={taxes}
                    onChange={(tableData) => setValues({ ...values, tableData })}
                    currency={values?.invoiceCurrency}
                    disabled={disableActions.disableFullEdit}
                />
                <Grid mt={5} container justifyContent="flex-end">
                    <Grid item xs={12} md={6}>
                        <AmountsCard
                            total={amountInfo()?.totalPrice - amountInfo()?.discount}
                            tax={amountInfo()?.taxTotal}
                            subtotal={amountInfo()?.subtotal}
                            discount={amountInfo()?.discount}
                            discountInputValue={values?.discountValue}
                            discountType={values?.discountType}
                            onChange={(newData) => setValues({ ...values, ...newData })}
                            currency={values?.invoiceCurrency}
                            convertedCurrencies={totalPriceByCurrencies}
                            disabled={disableActions.disableDiscount}
                        />
                    </Grid>
                </Grid>
            </Modal>
            <PaymentsView
                open={openPaymentViewModal}
                data={invoicePayments || []}
                onClose={() => setOpenPaymentViewModal(false)}
                currency={values?.invoiceCurrency}
                editPayment={(paymentData) => {
                    setOpenPaymentViewModal(false);
                    setEditPaymentModal({
                        open: true,
                        paymentData,
                    });
                }}
            />
            <EditPaymentsModal
                open={editPaymentModal.open}
                onClose={() => setEditPaymentModal({ open: false, paymentData: null })}
                paymentData={editPaymentModal.paymentData}
                onConfirm={updatePayment}
            />
            <PaymentsModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                addPayment={(data) => {
                    if (isReservationGenerated) addPaymentToReservation(data);
                    else addPayment(data);
                }}
                t={t}
                currency={values?.invoiceCurrency}
            />
            <Outlet context={{ onClientSave: onNewClient }} />
        </>
    );
};

export default InvoiceForm;
