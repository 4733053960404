const getPermission = (right) => {
    if (!right) throw new Error("Provide permission value to be compared");

    const token = localStorage.getItem("token");
    if (!token) return {};

    if (right === "*") {
        return {
            hasToken: true,
            hasRight: true,
        };
    }

    const userAsString = localStorage.getItem("user");
    if (!userAsString) {
        return {
            hasToken: true,
            hasRight: false,
        };
    }

    const userData = JSON.parse(userAsString);
    const { permissions } = userData?.role || {};

    let isValid = true;
    /**
     * Iterate over provided section right to validate each required permission
     */
    Object.entries(right).forEach(([section, rights]) => {
        if (!permissions[section]) {
            isValid = false;
        } else {
            Object.keys(rights).forEach((r) => {
                if (!permissions[section][r]) isValid = false;
            });
        }
    });
    return {
        hasToken: true,
        hasRight: isValid,
    };
};

export default getPermission;
