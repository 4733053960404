import {
    Button,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    ListItemText,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { SelectControl } from "../../components/form";
import { Table } from "../../components/table";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { DateTime } from "luxon";
import { DateRangeControl, TimeControl } from "../../components/dates";

const OrderList = () => {
    const [orders, setOrders] = useState([]);
    const [pospointOptions, setPospointsOptions] = useState([]);

    const [dateFilters, setDateFilters] = useState({
        startDate: DateTime.now().toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().toFormat("yyyy-LL-dd"),
        startTime: null,
        endTime: null,
    });
    const [extraFilters, setExtraFilters] = useState({
        status: null,
        pos: null,
    });

    const { loading, fetch } = useApi();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const params = useParams();

    const columns = [
        {
            id: "customerName",
            header: t("customer"),
            accessorFn: (row) => {
                let rowValue = row?.customerData?.firstName + " " + row?.customerData?.lastName;
                let roomNames;
                if (row?.reservationData?.rooms?.length > 0) {
                    roomNames = row?.reservationData?.rooms
                        ?.map((data) => {
                            return data?.roomData?.name;
                        })
                        ?.join(", ");
                    rowValue += ` (${roomNames})`;
                }
                return rowValue;
            },
        },
        {
            id: "dateCreated",
            header: t("date"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("yyyy-LL-dd"),
        },
        {
            id: "timeCreated",
            header: t("time"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("HH:mm:ss"),
        },
        {
            id: "status",
            header: t("status"),
            accessorFn: (row) => {
                if (row.status === "TRANSFERRED") return t("transferred");
                if (row?.payments?.cashReceived === 0) return t("UNPAID");
                return t(`${row?.status}`);
            },
        },
        /*  {
            id: "paymentStatus",
            header: t("payment_status"),
            accessorFn: (row) =>
                t(
                    `${
                        row?.payments?.paymentMethod === "roomCharge"
                            ? "roomCharge"
                            : row?.payments?.paymentStatus || "open"
                    }`
                ) || t("open"),
        }, */
        {
            id: "paymentMethod",
            header: t("payment_method"),
            accessorFn: (row) => t(`${row?.payments?.paymentMethodData?.name || "open"}`),
        },
        { accessorKey: "totalPrice", header: t("amount") },
        {
            header: t("order_number"),
            accessorFn: (row) => {
                if (row?.fiscalData?.InvOrdNum) return `${row?.number || ""} - ${row?.fiscalData?.InvOrdNum || ""}`;
                return row?.number;
            },
        },
        {
            id: "createdBy",
            header: t("created_by"),
            accessorFn: (row) => row.createdBy?.split("users_")[1],
        },
        { id: "pospoint", header: t("pospoint"), accessorFn: (row) => row?.pospointData?.name || "" },
        {
            id: "table",
            header: t("table"),
            accessorFn: (row) => {
                return row?.pospointData?.tables?.find((t) => t._id === row?.tableId)?.tableName || "";
            },
        },
    ];

    useEffect(() => {
        if (!!params.id) return; //Skip fetching data when single client page is rendered
        loadOrders();
    }, []);
    const onOrderChange = (data) => {
        if (orders?.some((r) => r._id === data._id)) {
            setOrders(
                orders?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setOrders([...orders, data]);
    };

    const loadOrders = async () => {
        const { startDate, startTime, endDate, endTime } = dateFilters || {};
        let start = startDate;
        if (!!startTime) start += `T${startTime}`;
        let end = endDate;
        if (!!endTime) end += `T${endTime}`;
        else end += `T23:59:59`;

        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "orders",
                    data: {
                        startDate: start,
                        endDate: end,
                    },
                    responseData: `
                        _id 
                        customerData{firstName lastName} 
                        reservationData{rooms{roomData{name}}}
                        pospointData{_id name tables{_id tableName}}
                        fiscalData{InvOrdNum}
                        payments{paymentStatus paymentMethod paymentMethodData{name method} cashReceived} 
                        status
                        totalPrice 
                        tableId 
                        number
                        createdAt
                        createdBy
                        
                    `,
                },
                { endpoint: "pospoints", responseData: "_id name" },
            ],
        });

        if (response?.orders) {
            setOrders(response.orders);
        }
        if (response?.pospoints) {
            setPospointsOptions(response.pospoints.map((pos) => ({ value: pos._id, label: pos.name })));
        }
    };

    const totalAmount = useMemo(() => {
        const amount = {
            totalOrders: {
                label: t("orders"),
                value: 0,
            },
            total: {
                label: t("revenue"),
                value: 0.0,
            },
            open: {
                label: t("open"),
                value: 0.0,
            },
        };
        orders
            ?.filter((order) => order.status !== "CANCELLED")
            ?.forEach((order) => {
                amount.totalOrders.value += 1;
                if (order.status === "OPEN") {
                    amount.open.value += order.totalPrice;
                    return;
                }
                if (!order.payments?.paymentMethodData?.name) return;
                if (!amount[order.payments?.paymentMethodData?.name]) {
                    amount[order.payments?.paymentMethodData?.name] = {
                        label: t(order.payments?.paymentMethodData?.name),
                        value: 0.0,
                    };
                }
                amount[order.payments?.paymentMethodData?.name].value +=
                    order.payments?.paymentMethodData?.method !== "roomCharge"
                        ? order.payments.cashReceived
                        : order.totalPrice;
                amount.total.value +=
                    order.payments?.paymentMethodData?.method !== "roomCharge"
                        ? order.payments.cashReceived
                        : order.totalPrice;
            });
        const formattedAmount = Object.keys(amount).map((key) => ({
            ...amount[key],
            value: key === "totalOrders" ? amount[key].value : amount[key].value.toFixed(2),
        }));
        return formattedAmount;
    }, [orders]);

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteOrders" : "deleteOrder",
            data,
        });
        if (response?.deleteOrders === "success" || response?.deleteOrder === "success") {
            setOrders(orders.filter((order) => !rows.some((row) => row._id === order._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const filteredOrders = useMemo(() => {
        const { status, pos } = extraFilters || {};
        return orders
            .filter((order) => {
                if (!status) return true;
                return (order.payments?.paymentStatus || "open") === status;
            })
            .filter((order) => {
                if (!pos) return true;
                return order.pospointData?._id === pos;
            });
    }, [extraFilters, orders]);

    return (
        <div>
            <Paper variant="outlined" style={{ margin: "20px 0", padding: 20 }}>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: dateFilters?.startDate, endDate: dateFilters?.endDate }}
                        onChange={({ startDate, endDate }) => {
                            setDateFilters({ ...dateFilters, startDate, endDate });
                        }}
                    />
                    <TimeControl
                        value={dateFilters?.startTime}
                        onChange={(e) => {
                            setDateFilters({ ...dateFilters, startTime: e.target.value });
                        }}
                        margin="none"
                        fullWidth={false}
                        label={t("start_date_time")}
                    />
                    <TimeControl
                        value={dateFilters?.endTime}
                        onChange={(e) => {
                            setDateFilters({ ...dateFilters, endTime: e.target.value });
                        }}
                        margin="none"
                        fullWidth={false}
                        label={t("end_date_time")}
                    />
                    <SelectControl
                        options={pospointOptions}
                        onChange={(e) => setExtraFilters({ ...extraFilters, pos: e.target.value })}
                        value={extraFilters?.pos || ""}
                        label={t("pos")}
                        sx={{ maxWidth: "300px" }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" gap={2} marginTop={2}>
                    <Button variant="contained" onClick={loadOrders}>
                        {t("filter")}
                    </Button>
                    <Button
                        onClick={() => {
                            setExtraFilters({});
                        }}
                        color="secondary"
                        variant="outlined"
                    >
                        {t("clear_filters")}
                    </Button>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" gap={2} marginTop={2}>
                    <ToggleButtonGroup
                        value={extraFilters?.status}
                        onChange={(e, value) => {
                            setExtraFilters({ ...extraFilters, status: value });
                        }}
                        fullWidth
                        exclusive
                        color="primary"
                    >
                        <ToggleButton value={"open"}>{t("open")}</ToggleButton>
                        <ToggleButton value={"paid"}>{t("paid")}</ToggleButton>
                        <ToggleButton value={"partlyPaid"}>{t("partly_paid")}</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </Paper>
            {/* <Paper variant="outlined" style={{ margin: "20px 0", padding: 20 }}>
                <Stack direction="row" gap={2}>
                    {totalAmount?.map((detail) => {
                        return (
                            <ListItemText
                                primary={detail.label}
                                primaryTypographyProps={{ variant: "subtitle1" }}
                                secondary={detail.value}
                                secondaryTypographyProps={{ variant: "h6", color: "primary" }}
                            />
                        );
                    })}
                </Stack>
            </Paper> */}
            <Paper variant="outlined" style={{ margin: "20px 0", padding: 20 }}>
                <Grid container direction="row" spacing={2}>
                    {totalAmount?.map((detail) => {
                        return (
                            <Grid item xs={4} md={2}>
                                <ListItemText
                                    primary={detail.label}
                                    primaryTypographyProps={{ variant: "subtitle1" }}
                                    secondary={detail.value}
                                    secondaryTypographyProps={{ variant: "h6", color: "primary" }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Paper>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("order_form")}
                moduleType="pos"
                columns={columns}
                data={filteredOrders}
                onRowClick={(row) => navigate(`${row?._id?.split("orders_")[1]}`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ onOrderChange }} />
        </div>
    );
};

export default OrderList;
