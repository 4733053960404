import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ClientDetailsInputs } from "../../components/common";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";

const rules = {
    firstName: "string|required",
};

const clientResponse = `_id firstName lastName clientType birthdate referenceTitle company country state city referer{_id name}
zip addressLine email phone IDType IDNumber placeOfIssue dateOfIssue dateOfExpiry nationality placeOfBirth tags`;

const ClientForm = () => {
    const [values, setValues] = useState({
        clientType: "client",
    });

    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const [tags, setTags] = useState([]);

    const { onClientSave, onNewClient, onClientEdited } = useOutletContext() || {};

    useEffect(() => {
        if (!!params?.clientId && params?.clientId !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [{ endpoint: "tags", responseData: "_id name description color" }],
        });
        if (response?.tags) setTags(response.tags.map((tag) => ({ value: tag._id, label: tag.name, data: tag })));
    };
    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "client",
            data: {
                _id: "clients_" + params.clientId,
            },
            responseData: clientResponse,
        });
        if (response?.client?._id) setValues(response.client);
    };

    const saveHandler = async () => {
        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            clientType: values.clientType,
            birthdate: values.birthdate,
            referenceTitle: values.referenceTitle,
            company: values.company,
            country: values.country,
            state: values.state,
            city: values.city,
            zip: values.zip,
            addressLine: values.addressLine,
            email: values.email,
            phone: values.phone,
            IDType: values.IDType,
            IDNumber: values.IDNumber,
            placeOfIssue: values.placeOfIssue,
            dateOfIssue: values.dateOfIssue,
            dateOfExpiry: values.dateOfExpiry,
            nationality: values.nationality,
            placeOfBirth: values.placeOfBirth,
            referer: values.referer,
            tags: values.tags,
        };
        if (values._id) data._id = values._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: values._id ? "updateClient" : "createClient",
            data,
            responseData: clientResponse,
        });
        if (response?.createClient?._id || response?.updateClient?._id) {
            enqueueSnackbar(t("client_saved"), { variant: "default" });
            onClientSave && onClientSave(response.createClient || response?.updateClient);

            if (response?.createClient?._id) {
                onNewClient && onNewClient(response.createClient);
            }
            if (response?.updateClient?._id) {
                onClientEdited && onClientEdited(response.updateClient);
            }
            navigate(-1);
        }
    };

    return (
        <Modal
            loading={loading}
            open
            fullScreen
            titlelabel={t("clients_form")}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler, rules }}
            onClose={() => navigate(-1)}
            permission={{ clients: params?.clientId === "create" ? { create: true } : { edit: true } }}
        >
            {ClientDetailsInputs({ values: values, t, alwaysOpen: true, setValues: setValues, tags: tags })}
        </Modal>
    );
};

export default ClientForm;
