import { LinearProgress, Chip, Paper, Stack, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { InvoicesInsightBar } from "../dashboards/components";
import { toCurrency } from "../../utils";
import { DateTime } from "luxon";
import { DateRangeControl } from "../../components/dates";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid
    TRANSFERRED: "#FFC82C", //transferred to group
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const InvoiceList = () => {
    const [invoices, setInvoices] = useState([]);
    const [filters, setFilters] = useState({
        startDate: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().toFormat("yyyy-LL-dd"),
    });
    const { t } = useTranslation();
    const columns = [
        {
            id: "name",
            header: t("name"),
            accessorFn: (row) => (row?.clientsData?.firstName || "") + " " + (row?.clientsData?.lastName || ""),
        },
        {
            id: "timestamp",
            header: t("createdOn"),
            accessorFn: (row) => {
                let id = "";
                if (row?._id?.startsWith("group")) id = row._id?.split("groupinvoices_")[1]?.split("_")[1];
                else id = row._id?.split("invoices_")[1];
                return DateTime.fromISO(id).toFormat("yyyy LLL dd");
            },
        },
        { accessorKey: "date", header: t("date") },
        { accessorKey: "dueDate", header: t("dueDate") },
        {
            header: t("number"),
            accessorFn: (row) => {
                if (row?.fiscalData?.InvOrdNum) return `${row?.number || ""} - ${row?.fiscalData?.InvOrdNum || ""}`;
                return row?.number;
            },
        },
        {
            accessorKey: "status",
            header: t("status"),
            cell: ({ row: { original } }) => {
                let status = original?.status || "DRAFT";
                if (original?.paymentStatus === "PAID") {
                    status = "PAID";
                }
                if (["CANCELLED", "TRANSFERRED"].includes(original?.status)) {
                    status = original?.status;
                }
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={t(`${status}`)} />;
            },
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            accessorFn: (row) => row?.totalPrice && toCurrency(row?.totalPrice, row?.invoiceCurrency),
        },
    ];
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const loadData = async () => {
        const { startDate, endDate } = filters || {};
        if (!startDate) return;
        let end = "end";
        if (!!endDate) end = endDate + `T23:59:59`;

        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "invoices",
                    data: {
                        startDate: startDate,
                        endDate: end,
                    },
                    responseData:
                        "_id paymentStatus number timestamp date dueDate status totalPrice invoiceCurrency clientsData{firstName lastName} number fiscalData{InvOrdNum}",
                },
            ],
        });
        if (response?.invoices) setInvoices(response?.invoices || []);
    };

    const addRecord = (data) => {
        if (invoices?.some((r) => r._id === data._id)) {
            setInvoices(
                invoices?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setInvoices([...invoices, data]);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteInvoices" : "deleteInvoice",
            data,
        });
        if (response?.deleteInvoices === "success" || response?.deleteInvoice === "success") {
            setInvoices(invoices.filter((invoice) => !rows.some((row) => row._id === invoice._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            {/* <InvoicesInsightBar /> */}
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                <Stack direction="row" gap={2} flexWrap="wrap">
                    <DateRangeControl
                        startLabel={t("start")}
                        endLabel={t("end")}
                        values={{ startDate: filters.startDate, endDate: filters.endDate }}
                        onChange={({ startDate, endDate }) => setFilters({ ...filters, startDate, endDate })}
                    />
                    <Button onClick={loadData} size="small" variant="contained">
                        {t("load_invoices")}
                    </Button>
                </Stack>
            </Paper>
            <Table
                titleLabel={t("invoices_list")}
                moduleType="finance"
                data={invoices}
                columns={columns}
                onRowClick={(row) => {
                    if (row?._id?.startsWith("groupinvoices_")) {
                        const id = row?._id?.split("groupinvoices_")?.[1];
                        const groupId = id?.split("_")?.[0];
                        const invoiceId = id?.split("_")?.[1];
                        navigate(invoiceId + `?groupId=${groupId}`);
                    } else navigate(`${row?._id?.split("invoices_")[1]}`);
                }}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecord }} />
        </div>
    );
};

export default InvoiceList;
