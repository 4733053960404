import { Divider, Grid, InputAdornment, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal";
import { DateRangeControl } from "../../components/dates";
import { SwitchControl, InputControl, SelectControl, CheckboxControl, RadioGroupControl } from "../../components/form";
import { RoomtypeCard } from "./components";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { weekdays } from "../../utils";

const AvailabilityForm = () => {
    const [data, setData] = useState({});
    const [values, setValues] = useState({
        days: weekdays,
    });
    const [originalRecord, setOriginalRecord] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { addRecordAvailability } = useOutletContext() || {};

    useEffect(() => {
        loadExtraData();
    }, []);

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "availability",
            data: {
                _id: "availabilities_" + params?.id,
            },
            responseData: `_id name startDate endDate days
                rates{
                    id 
                    price 
                    maxCapacity 
                    includedCapacity
                    capacity{id price}
                } 
                roomtypes{
                    id 
                    price 
                    maxCapacity 
                    includedCapacity
                    capacity{id price}
                } 
                sendTo
            `,
        });
        if (response?.availability) {
            setValues(response.availability);
            setOriginalRecord(JSON.parse(JSON.stringify(response.availability)));
        }
    };

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomrates",
                    responseData: "_id name roomTypes maxCapacity includedCapacity capacity{id} beds24RoomtypePriceId",
                },
                {
                    endpoint: "roomtypes",
                    responseData: "_id name capacity{id} beds24RoomtypePriceId",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
            ],
        });
        setData({
            rates:
                response?.roomrates?.map((rate) => {
                    return {
                        ...rate,
                        roomtypes: response?.roomtypes
                            ?.filter((rt) => rate.roomTypes.includes(rt._id))
                            ?.map((rt) => rt.name)
                            ?.join(", "),
                    };
                }) || [],
            roomtypes: response?.roomtypes || [],
            guestcategories: response?.guestcategories,
            settingsgeneral: response?.settingsgeneral,
        });
    };

    const ratesOptions = useMemo(() => {
        if (values.sendTo === "channel_manager") {
            return data?.rates?.filter((rate) => !!rate.beds24RoomtypePriceId);
        }
        return data?.rates;
    }, [data.rates, values.sendTo]);
    const roomtypesOptions = useMemo(() => {
        if (values.sendTo === "channel_manager") {
            return data?.roomtypes?.filter((rt) => !!rt.beds24RoomtypePriceId);
        }
        return data?.roomtypes;
    }, [data.roomtypes, values.sendTo]);

    const currency = data?.settingsgeneral?.currencyData?.currency;

    const onChangeRateValues = (rateId, rateValues = {}) => {
        if (!values.rates || values.rates.length === 0) {
            setValues({ ...values, rates: [{ id: rateId, ...rateValues }] });
            return;
        }
        if (!values.rates.some((rate) => rate.id === rateId)) {
            setValues({ ...values, rates: [...values.rates, { id: rateId, ...rateValues }] });
            return;
        }
        setValues({
            ...values,
            rates: values.rates.map((rate) => {
                if (rate.id === rateId) return { id: rateId, ...rateValues };
                return rate;
            }),
        });
    };

    const onChangeRoomtypeValues = (roomtypeId, roomtypeValues = {}) => {
        if (!values.roomtypes || values.roomtypes.length === 0) {
            setValues({ ...values, roomtypes: [{ id: roomtypeId, ...roomtypeValues }] });
            return;
        }
        if (!values.roomtypes.some((roomtype) => roomtype.id === roomtypeId)) {
            setValues({ ...values, roomtypes: [...values.roomtypes, { id: roomtypeId, ...roomtypeValues }] });
            return;
        }
        setValues({
            ...values,
            roomtypes: values.roomtypes.map((roomtype) => {
                if (roomtype.id === roomtypeId) return { id: roomtypeId, ...roomtypeValues };
                return roomtype;
            }),
        });
    };

    const onSaveHandler = async () => {
        const data = {
            name: values.name,
            startDate: values.startDate,
            endDate: values.endDate,
            days: values.days,
            rates: values.rates
                ?.filter((rate) => !!rate.price)
                ?.map((rate) => {
                    rate.capacity?.forEach((capacity) => {
                        if (capacity.price !== undefined) capacity.price = parseFloat(capacity.price);
                    });
                    return {
                        id: rate.id,
                        price: parseFloat(rate.price),
                        maxCapacity: isNaN(parseFloat(rate.maxCapacity)) ? null : parseFloat(rate.maxCapacity),
                        includedCapacity: isNaN(parseFloat(rate.includedCapacity))
                            ? null
                            : parseFloat(rate.includedCapacity),
                        capacity:
                            rate.capacity?.filter(({ price }) => {
                                if (isNaN(price)) return false;
                                return true;
                            }) || [],
                    };
                }),
            roomtypes: values.roomtypes
                ?.filter((roomtype) => !!roomtype.price)
                ?.map((roomtype) => {
                    roomtype.capacity?.forEach((capacity) => {
                        if (capacity.price !== undefined) capacity.price = parseFloat(capacity.price);
                    });
                    return {
                        id: roomtype.id,
                        price: parseFloat(roomtype.price),
                        maxCapacity: isNaN(parseFloat(roomtype.maxCapacity)) ? null : parseFloat(roomtype.maxCapacity),
                        includedCapacity: isNaN(parseFloat(roomtype.includedCapacity))
                            ? null
                            : parseFloat(roomtype.includedCapacity),
                        capacity:
                            roomtype.capacity?.filter(({ price }) => {
                                if (isNaN(price)) return false;
                                return true;
                            }) || [],
                    };
                }),
        };
        if (values._id) data._id = values._id;
        else {
            data.sendTo = values.sendTo;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: values._id ? "updateAvailability" : "createAvailability",
            data,
            responseData: "_id name days startDate endDate sendTo",
        });
        if (response?.createAvailability?._id || response?.updateAvailability) {
            enqueueSnackbar(t("saved"), { variant: "default" });
            addRecordAvailability && addRecordAvailability(response.createAvailability || response?.updateAvailability);
            //navigate(-1);
        }
    };

    const onWeekdayCheck = (day, checked) => {
        if (day === "all") {
            setValues({ ...values, days: checked ? weekdays : [] });
            return;
        }
        setValues({
            ...values,
            days: checked ? [...(values?.days || []), day] : values?.days?.filter((d) => d !== day),
        });
        return;
    };

    const sendToOptions = useMemo(() => {
        return [
            { value: "reception", label: t("reception") },
            { value: "channel_manager", label: t("channel_manager") },
        ];
    }, []);

    const ratesGroupedByRoomtypes = useMemo(() => {
        const groupedRates = {};
        ratesOptions?.forEach((rate) => {
            const roomtypeId = rate.roomTypes?.[0];
            if (!groupedRates[roomtypeId]) groupedRates[roomtypeId] = [];
            groupedRates[roomtypeId].push(rate);
        });
        return groupedRates;
    }, [ratesOptions]);

    return (
        <Modal
            loading={loading}
            open
            maxWidth="md"
            titlelabel={t("availability_form")}
            onSave={onSaveHandler}
            permission={{ availability: params?.id === "create" ? { create: true } : { edit: true } }}
            onClose={() => navigate(-1)}
            yesDisabled={originalRecord?.sendTo === "channel_manager"}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <DateRangeControl
                        values={{ startDate: values?.startDate || null, endDate: values?.endDate || null }}
                        onChange={({ startDate, endDate }) => setValues({ ...values, startDate, endDate })}
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputControl
                        value={values.name || ""}
                        onChange={(e) => setValues({ ...values, name: e.target.value })}
                        label={t("name")}
                        margin="none"
                    />
                </Grid>
                <Grid item xs={12}>
                    <RadioGroupControl
                        label={t("send_to")}
                        options={sendToOptions}
                        value={values.sendTo || ""}
                        onChange={(e) => {
                            if (!!originalRecord?.sendTo) return;
                            setValues({ ...values, sendTo: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{t("available_days")}</Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxControl
                        value={weekdays?.every((day) => values?.days?.includes(day))}
                        onChange={(e) => {
                            onWeekdayCheck("all", e.target.checked);
                        }}
                        label={t("all")}
                    />
                    {weekdays?.map((day) => {
                        return (
                            <CheckboxControl
                                value={values?.days?.includes(day) || false}
                                onChange={(e) => {
                                    onWeekdayCheck(day, e.target.checked);
                                }}
                                label={t(day)}
                            />
                        );
                    })}
                </Grid>
                {roomtypesOptions?.map((rt) => {
                    const roomtypeRates = ratesGroupedByRoomtypes[rt._id] || [];
                    return (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("roomtype")}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <RoomtypeCard
                                    name={rt.name}
                                    values={values?.roomtypes?.find((r) => r.id === rt._id) || {}}
                                    onChange={(newValues) => onChangeRoomtypeValues(rt._id, newValues)}
                                    capacity={rt.capacity}
                                    guestCategories={data?.guestcategories || []}
                                    currency={currency}
                                />
                            </Grid>
                            {roomtypeRates?.length > 0 && (
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t("rate_packages")}</Typography>
                                    <Divider />
                                </Grid>
                            )}
                            {roomtypeRates?.map((rate) => {
                                return (
                                    <Grid item xs={12}>
                                        <RoomtypeCard
                                            name={rate.name + " - " + rate.roomtypes}
                                            values={
                                                values?.rates?.find((r) => r.id === rate._id) || {
                                                    maxCapacity: rate.maxCapacity,
                                                    includedCapacity: rate.includedCapacity,
                                                }
                                            }
                                            onChange={(newValues) => onChangeRateValues(rate._id, newValues)}
                                            capacity={rate.capacity}
                                            guestCategories={data?.guestcategories || []}
                                            currency={currency}
                                            isRate={true}
                                        />
                                    </Grid>
                                );
                            })}
                        </>
                    );
                })}
            </Grid>
        </Modal>
    );
};

export default AvailabilityForm;
