import { Card, CardContent, CardHeader, Grid, ListItem, ListItemText } from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import HotelIcon from "@mui/icons-material/Hotel";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ReservationsInsightCard = ({ arrivals, departures, stayovers, freeRooms }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Card style={{ height: "100%" }}>
            <CardHeader title={t("today")} titleTypographyProps={{ variant: "h6" }} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12} md={3}>
                        <Card
                            variant="outlined"
                            elevation={0}
                            style={{ backgroundColor: "rgb(255, 73, 73,0.2)", borderColor: "rgb(255, 73, 73,0.2)" }}
                            onClick={() => navigate("/arrivals", { state: "arrivals" })}
                            sx={{ cursor: "pointer" }}
                        >
                            <ListItem secondaryAction={<LoginIcon style={{ fontSize: "40px", color: "#FF4949" }} />}>
                                <ListItemText
                                    primary={t("arrivals")}
                                    secondary={t(arrivals)}
                                    primaryTypographyProps={{
                                        fontSize: 19,
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        color: "black",
                                    }}
                                />
                            </ListItem>
                        </Card>
                    </Grid>
                    <Grid item sm={6} xs={12} md={3}>
                        <Card
                            variant="outlined"
                            elevation={0}
                            style={{
                                backgroundColor: "rgb(255, 200, 44,0.2)",
                                borderColor: "rgb(255, 200, 44,0.2)",
                            }}
                            onClick={() => navigate("/arrivals", { state: "departures" })}
                            sx={{ cursor: "pointer" }}
                        >
                            <ListItem secondaryAction={<LogoutIcon style={{ fontSize: "40px", color: "#FFC82C" }} />}>
                                <ListItemText
                                    primary={t("departures")}
                                    secondary={t(departures)}
                                    primaryTypographyProps={{
                                        fontSize: 19,
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        color: "black",
                                    }}
                                />
                            </ListItem>
                        </Card>
                    </Grid>
                    <Grid item sm={6} xs={12} md={3}>
                        <Card
                            variant="outlined"
                            elevation={0}
                            style={{
                                backgroundColor: "rgb(31, 182, 255,0.2)",
                                borderColor: "rgb(31, 182, 255,0.2)",
                            }}
                            onClick={() => navigate("/arrivals", { state: "stayovers" })}
                            sx={{ cursor: "pointer" }}
                        >
                            <ListItem secondaryAction={<HotelIcon style={{ fontSize: "40px", color: "#1FB6FF" }} />}>
                                <ListItemText
                                    primary={t("stayover")}
                                    secondary={t(stayovers)}
                                    primaryTypographyProps={{
                                        fontSize: 19,
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        color: "black",
                                    }}
                                />
                            </ListItem>
                        </Card>
                    </Grid>
                    <Grid item sm={6} xs={12} md={3}>
                        <Card
                            variant="outlined"
                            elevation={0}
                            style={{
                                backgroundColor: "rgb(19, 206, 102,0.2)",
                                borderColor: "rgb(19, 206, 102,0.2)",
                            }}
                            onClick={() => navigate("/newreservation")}
                            sx={{ cursor: "pointer" }}
                        >
                            <ListItem
                                secondaryAction={<MeetingRoomIcon style={{ fontSize: "40px", color: "#13CE66" }} />}
                            >
                                <ListItemText
                                    primary={t("free_rooms")}
                                    secondary={t(freeRooms)}
                                    primaryTypographyProps={{
                                        fontSize: 19,
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        color: "black",
                                    }}
                                />
                            </ListItem>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReservationsInsightCard;
