import { Button, LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const CashdeskList = () => {
    const [cashdesks, setCashdesks] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "tcrIntId", header: t("name") },
        { accessorKey: "tcrCode", header: t("tcr_code") },
        { accessorKey: "validFrom", header: t("valid_from") },
        {
            id: "_id",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(`deposit/${original?._id?.split("fiscalcashdesks_")[1]}`);
                        }}
                        variant="outlined"
                    >
                        {t("cash_deposit")}
                    </Button>
                );
            },
        },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "cashdesks",
            responseData: "_id tcrIntId tcrCode validFrom",
        });
        if (response?.cashdesks) setCashdesks(response.cashdesks);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteCashDesks" : "deleteCashDesk",
            data,
        });
        if (response?.deleteCashDesks === "success" || response?.deleteCashDesk === "success") {
            setCashdesks(cashdesks.filter((cashdesk) => !rows.some((row) => row._id === cashdesk._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("fiscal_cashdesks")}
                moduleType="backoffice"
                data={cashdesks}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("fiscalcashdesks_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet />
        </div>
    );
};

export default CashdeskList;
