import { Button, ButtonGroup, Divider, Grid, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PersonIcon from "@mui/icons-material/Person";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal";
import { ItemsTable } from "./components";
import { AutoCompleteControl, Form, InputControl, SelectControl, SwitchControl } from "../../components/form";
import { DateRangeControl } from "../../components/dates";
import { ActionsPanel, ActionBox, AmountsCard } from "../../components/common";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useApi } from "../../components/hooks";

const ExpenseForm = () => {
    const [values, setValues] = useState({});
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [posOptions, setPosOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "expense",
            data: {
                _id: "expenses_" + params?.id,
            },
            responseData: `_id currency supplier barcode serial pdfLanguage pos warehouse description date dueDate`,
        });
        if (response?.expense) setValues(response.expense);
    };

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "suppliers", responseData: "_id name" },
                { endpoint: "warehouses", responseData: "_id name" },
                { endpoint: "pospoints", responseData: "_id name" },
                { endpoint: "currencies", responseData: "_id currency" },
            ],
        });
        if (!!response?.suppliers)
            setSupplierOptions(response.suppliers.map((supplier) => ({ value: supplier._id, label: supplier.name })));
        if (!!response?.warehouses)
            setWarehouseOptions(
                response.warehouses.map((warehouse) => ({ value: warehouse._id, label: warehouse.name }))
            );
        if (!!response?.pospoints)
            setPosOptions(response.pospoints.map((pos) => ({ value: pos._id, label: pos.name })));
        if (!!response?.currencies)
            setCurrencyOptions(
                response.currencies.map((currency) => ({ value: currency._id, label: currency.currency }))
            );
    };
    const saveHandler = async () => {
        const { currency, date, dueDate, supplier, barcode, serial, pdfLanguage, pos, warehouse, description, _id } =
            values;
        const data = {
            currency,
            supplier,
            barcode,
            serial,
            pdfLanguage,
            pos,
            warehouse,
            description,
            date,
            dueDate,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateExpense" : "createExpense",
            data,
            responseData: "_id",
        });
        if (response?.createExpense?._id || response?.updateExpense?._id)
            enqueueSnackbar(t("expense_saved"), { variant: "default" });
    };
    return (
        <Modal
            fullScreen
            open
            titlelabel={t("expenses_form")}
            onClose={() => navigate(-1)}
            loading={loading}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler }}
        >
            <ActionsPanel>
                <ActionBox title={t("actions")}>
                    <SwitchControl label={t("create_bill")} />
                    <SwitchControl label={t("send_to_alpha")} />
                </ActionBox>
                <ActionBox width={350} title={t("status")}>
                    <ButtonGroup fullWidth disableElevation>
                        <Button disabled>{t("draft")}</Button>
                        <Button>{t("delivered")}</Button>
                        <Button>{t("paid")}</Button>
                    </ButtonGroup>
                </ActionBox>
                <ActionBox title={t("expense_details")} width={300}>
                    <DateRangeControl
                        startLabel={t("date")}
                        endLabel={t("due_date")}
                        values={{ startDate: values.date || null, endDate: values.dueDate || null }}
                        onChange={({ startDate, endDate }) =>
                            setValues({ ...values, date: startDate, dueDate: endDate })
                        }
                    />
                    <SelectControl name={"currency"} label="currency" options={currencyOptions} />
                </ActionBox>
            </ActionsPanel>

            <div style={{ margin: "20px 0" }}>
                <Typography variant="h6">{t("expense_details")}</Typography>
                <Divider />
            </div>
            <Form values={values} onValuesChange={setValues}>
                <Grid container spacing={2}>
                    <Grid container item xs={4}>
                        <Grid item xs={12}>
                            <AutoCompleteControl name={"supplier"} label={t("supplier")} options={supplierOptions} />
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteControl name={"barcode"} label={t("barcode")} />
                        </Grid>
                        <ListItem dense>
                            <ListItemIcon>
                                <AssignmentIndIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="J77777777N" />
                        </ListItem>
                        <ListItem dense>
                            <ListItemIcon>
                                <PersonIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Walkin" />
                        </ListItem>
                        <ListItem dense>
                            <ListItemIcon>
                                <LocationCityIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Rr. 5 Maji" />
                        </ListItem>
                    </Grid>
                    <Grid container item xs={8} spacing={2}>
                        <Grid item xs={6}>
                            <InputControl name="serial" label={t("serial")} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectControl name="pdfLanguage" label={t("pdf_language")} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectControl name="pos" label={t("pos")} options={posOptions} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectControl name="warehouse" label={t("warehouse")} options={warehouseOptions} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputControl multiline minRows={3} name="description" label={t("description")} />
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
            <div style={{ margin: "20px 0" }}>
                <Typography variant="h6">{t("items")}</Typography>
                <Divider />
            </div>
            <ItemsTable data={[{}, {}]} />
            <Grid mt={5} container justifyContent="flex-end">
                <Grid item xs={6}>
                    <AmountsCard />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ExpenseForm;
