import React from "react";

const imgsrc = "https://res.cloudinary.com/strowberry-code/image/upload/v1685653486/hb/icons8-list-80_h6kjfz.png";

const NoRecordsFound = (props) => {
    return (
        <div style={{ textAlign: "center", padding: "60px 0px" }}>
            <img style={{ height: "60px" }} {...props} alt="no records" src={imgsrc} />
        </div>
    );
};

export default NoRecordsFound;
