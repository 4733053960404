import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { capitalize } from "../../utils";

const RadioGroupControl = ({
    options = [],
    label,
    name,
    row = true,
    labelPlacement = "end",
    color,
    error,
    helperText,
    disabled = false,
    ...rest
}) => {
    const labelText = label || capitalize(name);
    return (
        <FormControl error={error}>
            <FormLabel>{labelText}</FormLabel>
            <RadioGroup {...rest} row={row}>
                {options.map((option) => (
                    <FormControlLabel
                        control={<Radio color={color} />}
                        value={option.value}
                        label={option.label}
                        labelPlacement={labelPlacement}
                        disabled={disabled}
                    />
                ))}
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default RadioGroupControl;
