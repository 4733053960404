import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const GroupList = () => {
    const [groups, setGroups] = useState([]);
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const columns = [
        { accessorKey: "name", header: t("group_name") },
        {
            accessorKey: "clientName",
            header: t("client_name"),
            accessorFn: (row) => {
                const { firstName, lastName } = row?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
        },
    ];
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "groups",
            responseData: "_id name clientsData{firstName lastName}",
        });
        if (response?.groups) setGroups(response.groups);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteGroups" : "deleteGroup",
            data,
        });
        if (response?.deleteGroups === "success" || response?.deleteGroup === "success") {
            setGroups(groups.filter((group) => !rows.some((row) => row._id === group._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("groups")}
                moduleType="frontdesk"
                data={groups}
                columns={columns}
                onRowClick={(row) => navigate(`profile/${row?._id?.split("groups_")[1]}`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet />
        </div>
    );
};

export default GroupList;
