import React from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl, InputControl, SelectControl } from "../../../components/form";
import { EditableTable } from "../../../components/table";

const ItemsTable = ({ data = [] }) => {
    const { t } = useTranslation();
    const columns = [
        {
            header: t("product"),
            cell: () => <AutoCompleteControl variant="standard" label={t("product")} />,
        },
        {
            header: t("category"),
            cell: () => <SelectControl variant="standard" label={t("category")} />,
        },
        {
            header: t("description"),
            cell: () => <InputControl variant="standard" label={t("description")} />,
        },
        {
            header: t("quantity"),
            cell: () => <InputControl variant="standard" type="number" label={t("quantity")} />,
        },
        {
            header: t("unit"),
            cell: () => <InputControl variant="standard" label={t("unit")} />,
        },
        {
            header: t("price"),
            cell: () => <InputControl variant="standard" label={t("price")} />,
        },
        {
            header: t("tax"),
            cell: () => <AutoCompleteControl variant="standard" multiple label={t("tax")} />,
        },
        {
            header: t("amount"),
            cell: () => "ALL 100.00",
        },
    ];
    return <EditableTable data={data} columns={columns} />;
};

export default ItemsTable;
