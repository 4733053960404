import { Button, ButtonGroup, DialogActions, Grid, List, ListItemText, Typography } from "@mui/material";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HotelIcon from "@mui/icons-material/Hotel";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientsSearch, PriceInformationCard } from "../../../components/common";
import {
    AutoCompleteControl,
    InputControl,
    RadioGroupControl,
    SelectControl,
    SwitchControl,
} from "../../../components/form";
import { Modal } from "../../../components/modal";

const paymentMethodsIcons = {
    CASH: <LocalAtmIcon />,
    CARD: <CreditCardIcon />,
    ROOMCHARGE: <HotelIcon />,
    BANK: <AccountBalanceIcon />,
};

const PaymentConfirmationModal = ({
    open,
    onCancel,
    amountInfo,
    orderedItems = [],
    onPay,
    pospoint = {},
    currency,
    selectedCustomer,
    onReservationSelect,
    onRoomSelect,
    onClientSelect,
    rooms,
    clientReservations,
    selectedRoom,
    disableRoomCharge,
    isSmallScreen,
}) => {
    const [cashReceived, setCashReceived] = useState(0.0);
    const [discount, setDiscount] = useState({});
    const [finalize, setFinalize] = useState(false);

    const [disablePay, setDisablePay] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (!open) return;
        setCashReceived(amountInfo.totalPrice);
        setFinalize(Boolean(pospoint?.cashdeskData?.fiscalBusinessId && pospoint?.alwaysFinalize));
    }, [open]);

    useEffect(() => {
        if (open) setDisablePay(false);
    }, [open]);

    return (
        <Modal
            onClose={onCancel}
            open={open}
            maxWidth="md"
            noText="none"
            yesText="none"
            titlelabel={t("payment_details")}
        >
            <Grid container spacing={2} alignItems="end">
                <Grid item xs={12}>
                    {pospoint?.cashdeskData?.fiscalBusinessId && (
                        <SwitchControl
                            value={finalize}
                            label={t("finalize_order")}
                            onChange={(e) => {
                                setFinalize(e.target.checked);
                            }}
                        />
                    )}
                </Grid>
                <Grid container item xs={12} md={6} spacing={4}>
                    <Grid item xs={12}>
                        {!!selectedCustomer && (
                            <Typography fontStyle={"italic"}>{selectedCustomer.customerName}</Typography>
                        )}
                        {/* <ClientsSearch onClientSelect={(e) => onClientSelect(e)} /> */}
                        <AutoCompleteControl
                            label={t("choose_by_room")}
                            onChange={(e) => onRoomSelect(e.target.value)}
                            options={rooms}
                            value={selectedRoom}
                        />
                        {!!clientReservations?.length > 0 && (
                            <SelectControl
                                options={clientReservations}
                                label={t("select_reservation")}
                                onChange={(e) => {
                                    onReservationSelect(e.target.value);
                                }}
                            />
                        )}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <RadioGroupControl
                            options={[
                                { value: "total", label: t("total") },
                                { value: "percentage", label: t("percentage") },
                            ]}
                            label={t("discount")}
                            onChange={(e) => {
                                setDiscount({ ...discount, type: e.target.value });
                            }}
                        />
                        <InputControl
                            label={t("value")}
                            type="number"
                            onChange={(e) => {
                                setDiscount({ ...discount, value: e.target.value });
                            }}
                        />
                    </Grid> */}
                    {/* <Grid container item xs={12}>
                        <ButtonGroup fullWidth>
                            <Button onClick={() => setCashReceived(50)}>50</Button>
                            <Button onClick={() => setCashReceived(100)}>100</Button>
                            <Button onClick={() => setCashReceived(200)}>200</Button>
                        </ButtonGroup>
                        <InputControl
                            value={cashReceived}
                            onChange={(e) => setCashReceived(e.target.value)}
                            label={t("cash_received")}
                        />
                    </Grid> */}
                </Grid>
                <Grid container item xs={12} md={6} spacing={1}>
                    {isSmallScreen ? (
                        ""
                    ) : (
                        <Grid container item xs={12} justifyContent="end">
                            <List style={{ overflow: "auto", maxHeight: "150px", paddingRight: "20px" }}>
                                {orderedItems.map((item) => (
                                    <ListItemText primary={`x${item.quantity} ${item.name}`} />
                                ))}
                            </List>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <PriceInformationCard
                            subtotal={(amountInfo.totalPrice - amountInfo.tax).toFixed(2)}
                            tax={amountInfo.tax.toFixed(2)}
                            total={amountInfo.totalPrice.toFixed(2)}
                            extraRows={[
                                {
                                    label: "cash_received",
                                    value: !!cashReceived ? parseFloat(cashReceived).toFixed(2) : 0.0,
                                },
                            ]}
                            currency={currency}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions style={{ marginTop: 10 }}>
                <ButtonGroup
                    color="primary"
                    variant="contained"
                    orientation={isSmallScreen ? "vertical" : "horizontal"}
                    fullWidth
                    size="large"
                    disabled={disablePay}
                >
                    {pospoint?.activeMethodsData?.map((paymentMethod) => {
                        return (
                            <Button
                                onClick={() => {
                                    setDisablePay(true);
                                    onPay({
                                        cashReceived: !!cashReceived ? parseFloat(cashReceived) : 0.0,
                                        paymentMethod: paymentMethod?._id,
                                        discount: discount.type && discount.value ? discount : undefined,
                                        finalize,
                                    });
                                    onCancel();
                                }}
                                style={{ display: "block" }}
                                disabled={paymentMethod?.method === "roomCharge" && disableRoomCharge}
                            >
                                <div style={{ display: isSmallScreen ? "inline-flex" : "block" }}>
                                    <div style={isSmallScreen ? { marginRight: 5 } : { marginBottom: 5 }}>
                                        {paymentMethodsIcons[paymentMethod?.method?.toUpperCase()] ||
                                            paymentMethodsIcons.CASH}
                                    </div>
                                    {t(paymentMethod?.name)}
                                </div>
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </DialogActions>
        </Modal>
    );
};

export default PaymentConfirmationModal;
