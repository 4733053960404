import React from "react";
import { useTranslation } from "react-i18next";
import { InputControl } from "../../../components/form";
import { Modal } from "../../../components/modal";

const rules = {
    name: "string|required",
};

const TableViewForm = ({ open, values, setValues, onSave, onCancel }) => {
    const { t } = useTranslation();
    return (
        <Modal
            open={open}
            yesText={t("add_view")}
            maxWidth="xs"
            onClose={onCancel}
            FormProps={{
                values,
                onValuesChange: setValues,
                rules,
                onSubmit: onSave,
            }}
        >
            <InputControl name="name" label={t("name")} />
            <InputControl multiline minRows={3} name="description" label={t("description")} />
        </Modal>
    );
};

export default TableViewForm;
