import jsPDF from "jspdf";
import systemLanguages from "../../../assests/systemLanguages";

export const printRegistrationCard = async ({ reservationId, fetch, t, language }) => {
    const translate = (text) => t(text, { lngs: [systemLanguages?.includes(language) ? language : "en"] });

    const response = await fetch({
        operation: "query",
        multipleEndpoints: [
            {
                endpoint: "reservation",
                data: {
                    _id: "reservations_" + reservationId?.split("reservations_")[1],
                },
                responseData: `
                        _id checkin checkout status clients totalPrice uuid bookingSource groupId
                        guests{roomId guests{name number price extra}} 
                        notes{note timestamp userName} 
                        discount{type value}
                        rooms{roomId roomtypeId prices{price rateId rateName rateMeals date} roomData{name} roomtypeData{name _id meals taxData{taxId isIncluded}}}
                        clientsData{_id firstName lastName phone email addressLine state city birthdate nationality placeOfBirth IDType IDNumber}
                        refererData{firstName} 
                        invoices{date invoiceCurrency _id clientsData{firstName lastName} totalPrice status payments{amount paymentMethod userName timestamp}}
                    `,
            },
            {
                endpoint: "settingsproperty",
                responseData: "_id logo address name cancellationTerms{language text} signatureposition",
                data: { _id: "settings_property" },
            },
        ],
    });

    const doc = new jsPDF("p", "mm", "a4");
    const pageHeight = doc.internal.pageSize.getHeight();
    const { checkin, checkout, uuid } = response?.reservation || {};
    const { firstName, lastName, phone, email, addressLine } = response?.reservation?.clientsData?.[0] || {};
    const { name, address, cancellationTerms } = response?.settingsproperty || {};
    let selectedLanguageCancellationTerms = cancellationTerms?.find((message) => message?.language === language)?.text;
    if (!selectedLanguageCancellationTerms) {
        selectedLanguageCancellationTerms =
            cancellationTerms?.find((message) => message?.language === "en")?.text || "";
    }
    const propertyPhone = response?.settingsproperty?.phone || "";
    const propertyEmail = response?.settingsproperty?.email || "";
    let { logo } = response?.settingsproperty || {};

    const { rooms, guests } = response?.reservation || {};

    const guestsNumber = guests?.reduce((acc, guest) => {
        guest?.guests?.forEach((g) => (acc += g?.number));
        return acc;
    }, 0);
    const roomNames = rooms?.map((room) => room?.roomData?.name)?.join(",");
    const rateNames = rooms?.map((room) => room?.prices?.[0].rateName || "standart")?.join(",");
    const signatureContent = `${translate("name")}, ${translate("surname")}, ${translate(
        "signature"
    )}\n__________________ `;

    doc.setFontSize(11);
    doc.setFont("Helvetica", "bold");
    doc.text(name, doc.internal.pageSize.getWidth() - 15, 7, "right");
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    doc.text(address || "", doc.internal.pageSize.getWidth() - 15, 11, "right");
    const body = [
        [
            {
                content: translate("registration_form"),
                colSpan: 2,
                styles: { fontSize: 18, fontStyle: "bold" },
            },
            // { content: "Hotel Phone\nHotel Email", styles: { fontSize: 10, fontStyle: "bold" } },
            {
                content: response?.settingsproperty?.signatureposition === "header" ? signatureContent : "",
                colSpan: 2,
                styles: { halign: "right" },
            },
        ],
        [
            {
                content: translate("contact"),
                colSpan: 4,
                styles: { fontSize: 12, fontStyle: "bold" },
            },
        ],
        [
            { content: translate("first_name"), styles: { fontSize: 10, fontStyle: "bold" } },
            firstName,
            { content: translate("last_name"), styles: { fontSize: 10, fontStyle: "bold" } },
            lastName,
        ],
        [
            { content: translate("address"), styles: { fontSize: 10, fontStyle: "bold" } },
            { content: addressLine || "", colSpan: 3 },
        ],
        [
            { content: translate("phone"), styles: { fontSize: 10, fontStyle: "bold" } },
            phone,
            { content: translate("email"), styles: { fontSize: 10, fontStyle: "bold" } },
            email,
        ],
        [
            {
                content: translate("accomodation"),
                colSpan: 4,
                styles: { fontSize: 12, fontStyle: "bold" },
            },
        ],
        [
            { content: translate("checkin"), styles: { fontSize: 10, fontStyle: "bold" } },
            checkin,
            { content: translate("checkout"), styles: { fontSize: 10, fontStyle: "bold" } },
            checkout,
        ],
        [
            { content: translate("rooms"), styles: { fontSize: 10, fontStyle: "bold" } },
            roomNames,
            { content: translate("rates"), styles: { fontSize: 10, fontStyle: "bold" } },
            rateNames,
        ],
        [
            { content: translate("nr_of_guests"), styles: { fontSize: 10, fontStyle: "bold" } },
            { content: guestsNumber, colSpan: 3 },
        ],
        [
            { content: translate("payment_method"), colSpan: 1, styles: { fontSize: 10, fontStyle: "bold" } },
            {
                content: "| | Cash      | | Credit Card      | | Bank Transfer      | | Other",
                colSpan: 3,
                styles: { fontSize: 10, fontStyle: "bold" },
            },
        ],
    ];
    doc.autoTable({
        // margin: { top: 36 },
        body,
        theme: "grid",
        styles: { textColor: "black" },
        startY: 25,
    });

    if (!!selectedLanguageCancellationTerms) {
        doc.autoTable({
            theme: "plain",
            styles: { fontSize: 10, cellPadding: { top: 1 } },
            margin: { left: 15, top: 10, right: 15 },
            startY: doc.autoTable.previous.finalY + 3,
            body: [[translate("terms") + "\n" + selectedLanguageCancellationTerms]],
        });
    }

    if (response?.settingsproperty?.signatureposition === "footer") {
        doc.text(signatureContent, 157, doc.autoTable.previous.finalY + 10);
    }

    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal");

    doc.setPage(1);
    if (!logo) {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(0, 0, 0);
        doc.text(name, 12, 15);

        printPdf({
            pdf: doc,
            type: "save",
            title: `registration-card_${uuid}`,
        });
    } else {
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const imageWidth = img.width;
            const imageHeight = img.height;

            doc.addImage(img, "JPEG", 12, 3, (20 * imageWidth) / imageHeight, 20);

            printPdf({
                pdf: doc,
                type: "save",
                title: `registration-card_${uuid}`,
            });
        };
    }
};
const printPdf = ({ pdf, type, title }) => {
    if (type === "autoprint") {
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
    } else {
        pdf.save(title + ".pdf");
    }
};

export default printRegistrationCard;
