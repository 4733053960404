import React, { useState } from "react";
import { ReportGenerator } from "../../components/classes";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useApi, useProductCategories, useProducts } from "../../components/hooks";
import { ReportHeader } from "./components";
import { Stack } from "@mui/material";
import { DateRangeControl } from "../../components/dates";
import { SelectControl } from "../../components/form";

const InventoryReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();

    const [report, setReport] = useState(null);
    const { loading, fetch } = useApi();
    const { products } = useProducts();
    const { productCategories } = useProductCategories();

    const typeOptions = [
        {
            value: "purchase_order",
            label: t("purchase_order"),
        },
        {
            value: "inventory_transfer",
            label: t("inventory_transfer"),
        },
        {
            value: "inventory_deduction",
            label: t("inventory_deduction"),
        },
    ];

    const columns = [
        {
            id: "type",
            header: t("type"),
            displayValue: (row) => {
                return t(row?.type);
            },
        },
        {
            id: "number",
            header: t("number"),
            displayValue: (row) => {
                return row?.number || "";
            },
        },
        {
            id: "date",
            header: t("date"),
            displayValue: (row) => {
                return row?.date || "";
            },
        },
        {
            id: "item",
            header: t("item"),
            displayValue: (row) => {
                return row?.itemData?.itemName || "";
            },
        },
        {
            id: "category",
            header: t("category"),
            displayValue: (row) => {
                return row?.itemData?.itemCategory || "";
            },
        },
        {
            id: "quantity",
            header: t("quantity"),
            displayValue: (row) => {
                return row?.itemData?.quantity || "";
            },
        },
        {
            id: "pricePerUnit",
            header: t("price_per_unit"),
            displayValue: (row) => {
                if (isNaN(row?.itemData?.price)) return "0.00";
                return row.itemData.price.toFixed(2);
            },
        },
        {
            id: "warehouseFrom",
            header: t("warehouse_from"),
            displayValue: (row) => {
                if (!!row?.warehouseFromName) return row?.warehouseFromName;
                return "--";
            },
        },
        {
            id: "warehouseTo",
            header: t("warehouse_to"),
            displayValue: (row) => {
                if (!!row?.warehouseToName) return row?.warehouseToName;
                return "--";
            },
        },
        {
            id: "total",
            header: t("total"),
            displayValue: (row) => {
                const amount = row?.itemData?.price * row?.itemData?.quantity;
                if (isNaN(amount)) return "0.00";
                return amount.toFixed(2);
            },
        },
    ];
    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        const start = params.get("startDate");
        const end = params.get("endDate");
        const type = params.get("type");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + start;
        if (!!end) filtersText += ", " + t("end_date") + ": " + end;
        if (!!type) filtersText += ", " + t("type") + ": " + type;

        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "inventoriesByDates",
                    data: { start: start, end: end + "T23:59:59" },
                    responseData: `type number serial date tableData{itemId price quantity} warehouseFrom warehouseTo`,
                },
                {
                    endpoint: "warehouses",
                    responseData: `_id name`,
                },
            ],
        });

        if (!response?.inventoriesByDates) return;

        const newRaportGenerator = new ReportGenerator({
            data: response?.inventoriesByDates
                ?.filter((inventory) => {
                    if (!type) return true;
                    return inventory.type === type;
                })
                ?.map((inventory) => {
                    const warehouseFromName = response?.warehouses?.find(
                        (w) => w._id === inventory.warehouseFrom
                    )?.name;
                    const warehouseToName = response?.warehouses?.find((w) => w._id === inventory.warehouseTo)?.name;
                    return (
                        inventory?.tableData?.map((item) => {
                            const { name, categoryId } = products.find((p) => p._id === item.itemId) || {};
                            const categoryName = productCategories.find((c) => c._id === categoryId)?.name || "";
                            return {
                                ...inventory,
                                warehouseFromName,
                                warehouseToName,
                                itemData: { ...item, itemName: name, itemCategory: categoryName },
                            };
                        }) || []
                    );
                })
                ?.flat(),
            columns,
            displayTotal: false,
        });
        setReport(newRaportGenerator.generateTable({ title: t("inventory"), headerInfo: [filtersText] }));
    };

    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("type")) data.type = params.get("type");

        return data;
    };

    return (
        <div>
            <ReportHeader
                title={t("inventory_report")}
                loading={loading}
                disableRunInfo={t("loading_products")}
                disableRun={!products?.length > 0 && !productCategories.length > 0}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <SelectControl
                        label={t("type")}
                        options={typeOptions}
                        values={params.get("type")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                type: e.target.value,
                            })
                        }
                        sx={{ width: "400px" }}
                    />
                </Stack>
            </ReportHeader>
            {report}
        </div>
    );
};

export default InventoryReport;
