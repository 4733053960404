import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientsSearch } from "../../../components/common";
import { Modal } from "../../../components/modal";

const AddClientToReservationModal = ({ open, onSave, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <Modal open={open} yesText="none" onClose={onClose}>
                <ClientsSearch
                    onClientSelect={(client) => {
                        onSave && onSave(client);
                    }}
                />
                <Button onClick={() => navigate("clients/create")} variant="contained" sx={{ marginTop: 1 }}>
                    {t("create_new")}
                </Button>
            </Modal>
        </>
    );
};

export default AddClientToReservationModal;
