import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, QuantityControl, SimpleQuantityControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};

const GuestCategoryForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordGuestCategory } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "guestcategory",
            data: {
                _id: "guestcategories_" + params?.id,
            },
            responseData: "_id name description from to",
        });
        if (response?.guestcategory) setValues(response.guestcategory);
    };

    const saveHandler = async () => {
        const { name, description, _id, from, to } = values;
        const data = {
            name,
            description,
            from: isNaN(parseInt(from)) ? 0 : parseInt(from),
            to: isNaN(parseInt(to)) ? null : parseInt(to),
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateGuestCategory" : "createGuestCategory",
            data,
            responseData: "_id name description from to",
        });
        if (response?.createGuestCategory?._id || response?.updateGuestCategory?._id) {
            enqueueSnackbar(t("guest_category_saved"), { variant: "default" });
            addRecordGuestCategory &&
                addRecordGuestCategory(response.createGuestCategory || response?.updateGuestCategory);

            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("guest_category")}
            maxWidth="xs"
            open={true}
            onClose={() => navigate(-1)}
            loading={loading}
            yesDisabled={loading}
            permission={{ guestcategories: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <Typography>{t("from")}</Typography>
                    <SimpleQuantityControl
                        value={values.from}
                        min={0}
                        onChange={(newValue) => setValues({ ...values, from: newValue })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>{t("to")}</Typography>
                    <SimpleQuantityControl
                        value={values.to}
                        min={0}
                        onChange={(newValue) => setValues({ ...values, to: newValue })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="description" label={t("description")} multiline minRows={4} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default GuestCategoryForm;
