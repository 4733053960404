import { Number } from ".";

const exchangeCurrency = ({ amount = 0, fromCurrency, toCurrency, currencies, fiscalCurrencyRate }) => {
    const value = new Number(amount?.toString());
    if (value.isNaN()) return 0;

    const fromCurrencyRate = currencies?.find((c) => c.currency === fromCurrency)?.rate || 1;
    const toCurrencyRate = currencies?.find((c) => c.currency === toCurrency)?.rate || 1;
    if (fromCurrency !== toCurrency && fiscalCurrencyRate) {
        const exchangeRate = fiscalCurrencyRate / fromCurrencyRate;
        const exchangedAmount = value.multipliedBy(exchangeRate);
        return exchangedAmount.toNumber();
    }
    const exchangeRate = toCurrencyRate / fromCurrencyRate;
    const exchangedAmount = value.multipliedBy(exchangeRate);
    return exchangedAmount.toNumber();
};

export default exchangeCurrency;
