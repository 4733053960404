import { Button, Popover, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleQuantityControl } from "../form";

const GuestSelect = ({ capacity = [], maxCapacity, categories = [], value = {}, onChange, disabled = false }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [guestValue, setGuestValue] = useState({});

    const { t } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        {
            if (Object.keys(guestValue)?.length > 0) {
                onChange && onChange(guestValue);
            }
        }
    };
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (open) {
            setGuestValue(value);
        }
    }, [open]);

    const total = useMemo(() => {
        return Object.values(value).reduce((acc, category) => acc + category.number, 0.0);
    }, [value]);

    return (
        <>
            <Button disabled={disabled} id={"guests-menu"} variant="outlined" onClick={handleClick}>
                {!!total ? total : ""} {t("guests")}
            </Button>
            <Popover
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                elevation={2}
                id={"guests-menu"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {capacity?.map((category) => {
                    const { name = "", _id } = categories.find((c) => c._id === category.id) || {};
                    let availableCapacity = maxCapacity || 0;
                    Object.entries(guestValue)?.forEach((guestCategory) => {
                        if (guestCategory[0] !== category.id) {
                            availableCapacity -= guestCategory[1]?.number || 0;
                        }
                    });
                    if (!_id) return null;
                    return (
                        <Stack
                            padding={1}
                            direction="row"
                            width={250}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="subtitle1">{name}</Typography>
                            <SimpleQuantityControl
                                min={0}
                                max={availableCapacity}
                                value={
                                    guestValue?.[category.id]?.number !== undefined &&
                                    guestValue?.[category.id]?.number !== null
                                        ? guestValue?.[category.id]?.number
                                        : 0
                                }
                                onChange={(newValue) => {
                                    setGuestValue({ ...guestValue, [category.id]: { name, number: newValue } });
                                    // onChange && onChange({ ...value, [category.id]: { name, number: newValue } });
                                }}
                            />
                        </Stack>
                    );
                })}
            </Popover>
        </>
    );
};

export default GuestSelect;
