import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const BillCategoryList = () => {
    const [billCategories, setBillCategories] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "description", header: t("description") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "billcategories",
            responseData: "_id name description",
        });
        if (response?.billcategories) setBillCategories(response.billcategories);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteBillCategories" : "deleteBillCategory",
            data,
        });
        if (response?.deleteBillCategories === "success" || response?.deleteBillCategory === "success") {
            setBillCategories(
                billCategories.filter((billCategory) => !rows.some((row) => row._id === billCategory._id))
            );
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };
    const addRecordBillCategory = (data) => {
        if (billCategories?.some((r) => r._id === data._id)) {
            setBillCategories(
                billCategories?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setBillCategories([...billCategories, data]);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("bill_categories_form")}
                moduleType="finance"
                data={billCategories}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("billcategories_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordBillCategory }} />
        </div>
    );
};

export default BillCategoryList;
