import { Card, CardContent, CardHeader, Grid, ListItemText, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../../components/hooks";
import { Modal } from "../../../../components/modal";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const calculateRoomDailyPrice = (dailyAvailabilities, room, day) => {
    const roomtypeDayPrice = dailyAvailabilities?.roomtypes?.[room?.roomtypeId]?.[day]?.price;
    let defaultPrice = roomtypeDayPrice !== undefined && roomtypeDayPrice !== null ? roomtypeDayPrice : room?.price;
    return defaultPrice;
};

const ReservationEditConfirm = ({
    open = false,
    reservation = {},
    currentRoom,
    newRoom,
    onConfirm,
    onCancel,
    changes = {},
    startDate,
    endDate,
}) => {
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const [selectedPriceOption, setSelectedPriceOption] = useState("keep");
    const [dailyPrices, setDailyPrices] = useState({});

    const { prices = [] } = reservation?.rooms?.find((room) => room.roomId === currentRoom?._id) || {};
    const { guests = [] } = reservation?.guests?.find((roomGuests) => roomGuests.roomId === currentRoom?._id) || {};
    const averageCurrentRoomPricePerNight = useMemo(() => {
        const averagePrice = prices?.reduce((acc, day) => acc + day.price, 0) / prices?.length || 0.0;
        const extraGuestsPrice = guests?.reduce((acc, category) => acc + category.extra * category.price, 0.0) || 0.0;
        return averagePrice + extraGuestsPrice;
    }, [reservation]);
    const averageNewRoomPricePerNightWithoutGuests = useMemo(() => {
        const averagePrice = calculateRoomDailyPrice(dailyPrices, newRoom, startDate) || 0.0;
        return averagePrice;
    }, [reservation, dailyPrices]);
    const averageNewRoomPricePerNight = useMemo(() => {
        const averagePrice = averageNewRoomPricePerNightWithoutGuests;
        const extraGuestsPrice = guests?.reduce((acc, category) => acc + category.extra * category.price, 0.0) || 0.0;
        return averagePrice + extraGuestsPrice;
    }, [averageNewRoomPricePerNightWithoutGuests]);

    useEffect(() => {
        if (open) loadDailyPricing();
    }, [open]);

    const loadDailyPricing = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "dailyAvailabilities",
            data: {
                startDate: startDate,
                endDate: endDate,
            },
        });
        if (response?.dailyAvailabilities) {
            setDailyPrices(response.dailyAvailabilities ? JSON.parse(response.dailyAvailabilities) : {});
        }
    };

    return (
        <Modal
            open={open}
            maxWidth="md"
            titlelabel={t("edit_reservation")}
            yesText={t("confirm")}
            onSave={() => {
                onConfirm &&
                    onConfirm({
                        keepPrice: selectedPriceOption === "keep",
                        updatedPrice: averageNewRoomPricePerNightWithoutGuests,
                    });
            }}
            onClose={onCancel}
        >
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <ReservationDetails reservation={reservation} roomGuests={guests} changes={changes} />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <PriceInfoCard
                        isExisting
                        selected={selectedPriceOption === "keep"}
                        onClick={() => setSelectedPriceOption("keep")}
                        price={averageCurrentRoomPricePerNight}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    {reservation?.invoices?.length > 0 && changes.field === "room" ? (
                        <Typography>
                            {t("closed_invoice")} <br />
                            {t("no_price_change_allowed")}
                        </Typography>
                    ) : loading ? (
                        <Typography>...{t("calculating")}</Typography>
                    ) : (
                        <PriceInfoCard
                            selected={selectedPriceOption === "update"}
                            onClick={() => setSelectedPriceOption("update")}
                            price={averageNewRoomPricePerNight}
                            oldPrice={averageCurrentRoomPricePerNight}
                            loading={loading}
                        />
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ReservationEditConfirm;

const ReservationDetails = ({ reservation = {}, roomGuests = [], changes = {} }) => {
    const { t } = useTranslation();
    const clientName = reservation?.clientsData?.[0]
        ? (reservation?.clientsData?.[0].firstName || "") + " " + (reservation?.clientsData?.[0].lastName || "")
        : "";
    const guestsDetails = () => {
        const guestsCategoriesInfo = roomGuests?.reduce((acc, category) => {
            const existingCategory = acc.find((c) => c.name === category.name);
            if (!existingCategory) {
                acc.push({ name: category.name, number: category.number });
                return acc;
            }
            existingCategory.number += category.number;
            return acc;
        }, []);
        return guestsCategoriesInfo?.map((c) => `${c.number} ${c.name}`)?.join(" , ");
    };

    return (
        <Stack>
            <ListItemText
                primary={clientName}
                primaryTypographyProps={{ variant: "h6", color: "primary" }}
                secondary={`${reservation?.checkin} - ${reservation?.checkout}`}
                secondaryTypographyProps={{ variant: "body1", color: "primary" }}
            />
            <ListItemText
                primary={t("guests")}
                primaryTypographyProps={{ variant: "body" }}
                secondary={guestsDetails() || ""}
                secondaryTypographyProps={{ variant: "h6", color: "primary" }}
            />
            <ListItemText
                primary={t("source")}
                primaryTypographyProps={{ variant: "body" }}
                secondary={reservation?.bookingSource}
                secondaryTypographyProps={{ variant: "h6", color: "primary" }}
            />
            <ListItemText
                primary={t("change_type") + " : " + t(changes?.field)}
                primaryTypographyProps={{ variant: "body" }}
                secondary={`${changes?.currentValue} -> ${changes?.newValue}`}
                secondaryTypographyProps={{ variant: "h6", color: "primary" }}
            />
        </Stack>
    );
};

const PriceInfoCard = ({ isExisting = false, selected = false, onClick, price, oldPrice }) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ cursor: "pointer", border: selected && "3px solid" }} onClick={onClick}>
            <CardHeader
                title={t(isExisting ? "keep_price_calendar" : "update_price")}
                titleTypographyProps={{ color: "primary" }}
            />
            <CardContent sx={{ height: 100 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Typography variant="subtitle1">{t("average_room_night")}</Typography>
                    <Typography variant="h6" color="primary">
                        {price?.toFixed(2)}
                    </Typography>
                </Stack>
                {!isExisting && (
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant="subtitle1">{t("amount_change")}</Typography>
                        <Typography variant="h6" color="primary">
                            {oldPrice > price ? "" : "+"} {(price - oldPrice)?.toFixed(2)}
                        </Typography>
                    </Stack>
                )}
            </CardContent>
            <CardContent>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Typography variant="subtitle1">{t("total")}</Typography>
                    <Typography variant="h6" color="primary">
                        {price?.toFixed(2)}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};
