import { Tabs } from "@mui/material";
import React from "react";

const ProductCategoriesTabs = ({ children, orientation = "horizontal", isSmallScreen, sx = {}, ...rest }) => {
    return (
        <Tabs
            orientation={orientation}
            TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
            sx={{
                "& button": {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: (theme) => theme.palette.primary.main,
                    borderRadius: orientation === "vertical" ? "10px" : 5,
                    marginLeft: isSmallScreen ? "5px" : "20px",
                    marginTop: orientation === "vertical" ? "20px" : "0px",
                },
                "& button.Mui-selected": {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText,
                },
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Tabs>
    );
};

export default ProductCategoriesTabs;
