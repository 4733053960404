import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";

const SettingsCard = ({ title, icon, children, action }) => {
    return (
        <Card>
            <CardHeader title={title} titleTypographyProps={{ variant: "h6" }} avatar={icon} action={action} />
            <CardContent>{children}</CardContent>
        </Card>
    );
};

export default SettingsCard;
