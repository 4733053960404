import { Button, CardHeader, Chip, Stack } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MergeIcon from "@mui/icons-material/Merge";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckboxControl } from "../../../components/form";
import { Table } from "../../../components/table";
import { toCurrency } from "../../../utils";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid
    TRANSFERRED: "#FFC82C", //transferred to group
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const InvoicesTable = ({ data = [], mergeInvoices, reservation }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const columns = [
        {
            id: "select",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <CheckboxControl
                        disabled={
                            !original?._id ||
                            original?.status === "TRANSFERRED" ||
                            original?.status === "CANCELLED" ||
                            original?.fiscalData?.IICRef
                        }
                        value={selectedInvoices?.includes(original?._id)}
                        onChange={(e) => {
                            if (e.target.checked) setSelectedInvoices([...selectedInvoices, original?._id]);
                            else setSelectedInvoices(selectedInvoices?.filter((i) => i !== original?._id));
                        }}
                    />
                );
            },
        },
        {
            id: "name",
            header: t("name"),
            cell: ({ row: { original } }) => {
                const { firstName = "", lastName = "" } = original?.clientsData || {};
                return (firstName || "") + " " + (lastName || "");
            },
        },
        {
            accessorKey: "number",
            header: t("number"),
            cell: ({ row: { original } }) => {
                return original.number || t("AUTO");
            },
        },
        { accessorKey: "date", header: t("date") },
        {
            accessorKey: "totalPrice",
            header: t("amount"),
            cell: ({ row: { original } }) => toCurrency(original?.totalPrice || 0, original?.invoiceCurrency),
        },
        {
            id: "status",
            header: "",
            cell: ({ row: { original } }) => {
                let status = original?.status || "DRAFT";
                if (original?.paymentStatus === "PAID") {
                    status = "PAID";
                }
                if (["CANCELLED", "TRANSFERRED"].includes(original?.status)) {
                    status = original?.status;
                }
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={status} />;
            },
        },
        {
            id: "link",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <Button
                        startIcon={<BorderColorIcon />}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onClick={() =>
                            navigate(
                                "invoices/" + (original?._id ? original?._id?.split("invoices_")?.[1] : "create"),
                                { state: { newInvoiceData: original } }
                            )
                        }
                    >
                        {t("edit")}
                    </Button>
                );
            },
        },
    ];

    const onNewInvoice = () => {
        return navigate("invoices/create", {
            state: {
                newInvoiceData: {
                    reservationIds: [reservation._id],
                    customer: reservation?.clients?.[0],
                    date: reservation?.checkin,
                    dueDate: reservation?.checkout,
                    clientsData: {
                        firstName: reservation?.clientsData?.[0]?.firstName,
                        lastName: reservation?.clientsData?.[0]?.lastName,
                        IDNumber: reservation?.clientsData?.[0]?.IDNumber,
                        IDType: reservation?.clientsData?.[0]?.IDType,
                        addressLine: reservation?.clientsData?.[0]?.addressLine,
                        country: reservation?.clientsData?.[0]?.country,
                        city: reservation?.clientsData?.[0]?.city,
                        state: reservation?.clientsData?.[0]?.state,
                        email: reservation?.clientsData?.[0]?.email,
                        phone: reservation?.clientsData?.[0]?.phone,
                    },
                },
            },
        });
    };

    return (
        <>
            <CardHeader
                avatar={<ReceiptIcon />}
                action={
                    <Stack direction="row" gap={2}>
                        {reservation?.invoices?.length > 0 && (
                            <Button startIcon={<AddIcon />} variant="outlined" onClick={onNewInvoice}>
                                {t("add_new")}
                            </Button>
                        )}
                        {selectedInvoices?.length > 0 && (
                            <Button
                                onClick={() => mergeInvoices(selectedInvoices)}
                                startIcon={<MergeIcon style={{ transform: "rotate(90deg)" }} />}
                                disabled={selectedInvoices?.length < 1}
                                variant="contained"
                            >
                                {t("merge")}
                            </Button>
                        )}
                    </Stack>
                }
                title={t("invoices")}
                titleTypographyProps={{ variant: "h5" }}
            />

            <Table data={data || []} columns={columns} disableHeader disableSelection disableDelete disableFooter />
        </>
    );
};

export default InvoicesTable;
