import { CalendarPicker } from "@mui/x-date-pickers";
import React from "react";

const CalendarContainer = ({ key, onDateChange, onMonthChange, month, renderDay, ...rest }) => {
    return (
        <CalendarPicker
            key={key}
            onChange={onDateChange}
            onMonthChange={onMonthChange}
            defaultCalendarMonth={month}
            views={["day"]}
            renderDay={renderDay}
            {...rest}
        />
    );
};

export default CalendarContainer;
