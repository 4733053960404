import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../components/modal";
import { SelectControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { Button, Stack } from "@mui/material";
import { Height } from "@mui/icons-material";

const PospointModal = ({ open, onClose, reservationId }) => {
    const [pospoints, setPospoints] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { fetch } = useApi();

    useEffect(() => {
        if (open) loadData();
    }, [open]);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "pospoints",
                    responseData: "_id name isActive hasTables",
                },
            ],
        });
        if (response?.pospoints)
            setPospoints(
                response.pospoints
                    .filter((pos) => pos.isActive)
                    .map((pos) => ({ value: pos._id, label: pos.name, hasTables: pos.hasTables }))
            );
    };
    const navigateToPospoint = (posId) => {
        navigate(`/pos/sell?pos=${posId?.split("pospoints_")[1]}&reservationId=${reservationId}`);
    };

    return (
        <>
            <Modal open={open} onClose={onClose} yesText="none" noText="none">
                <Stack direction="row" justifyContent="center" gap={2}>
                    {pospoints?.map((pos) => {
                        return (
                            <Button
                                sx={{
                                    width: 150,
                                    height: 150,
                                }}
                                onClick={() => navigateToPospoint(pos.value)}
                                variant="contained"
                            >
                                {pos.label}
                            </Button>
                        );
                    })}
                </Stack>
            </Modal>
        </>
    );
};

export default PospointModal;
