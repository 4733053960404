import jsPDF from "jspdf";
import "jspdf-autotable";
import { calculateTotalTax, toCurrency } from "../../../utils";
import { DateTime } from "luxon";

const findColumnWidth = (columns, numberOfColumns) => {
    let totalWidth = 0;
    let counter = 0;

    columns.forEach((columnObject) => {
        totalWidth += columnObject.width;
        if (counter == numberOfColumns - 1) {
            return totalWidth;
        }
        counter++;
    });
    return totalWidth;
};

const titles = {
    purchase_order: "purchase_order",
    inventory_transfer: "inventory_transfer",
    inventory_deduction: "inventory_deduction",
};

const calculateAmount = (product, taxes = []) => {
    const { totalAmount, includedAmount } = calculateTotalTax({
        taxData: product.taxData?.map((t) => ({
            isIncluded: t.isIncluded,
            tax: taxes.find((tax) => tax._id === t.taxId),
        })),
        price: parseFloat(product.price),
    });
    const totalPrice = (parseFloat(product.price) + totalAmount - includedAmount) * product.quantity;
    return isNaN(totalPrice) ? 0.0 : totalPrice;
};

const calculateTotalDiscount = ({ totalPrice = 0, discountType, discountValue }) => {
    if (!discountType || !discountValue) return 0;
    const discountAmount =
        discountType === "total" ? parseFloat(discountValue) : totalPrice * parseFloat(discountValue) * 0.01;
    if (isNaN(discountAmount)) return 0;
    return discountAmount;
};
const amountInfo = ({ items = [], taxes = [], discountType, discountValue }) => {
    let totalPrice = 0.0;
    let subtotal = 0.0;
    let discount = 0.0;
    let taxTotal = 0.0;

    items?.forEach((item) => {
        const { totalAmount, includedAmount } = calculateTotalTax({
            taxData: item.taxData?.map((t) => ({
                isIncluded: t.isIncluded,
                tax: taxes.find((tax) => tax._id === t.taxId),
            })),
            price: parseFloat(item.price),
        });
        taxTotal += totalAmount * item.quantity;
        totalPrice += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
    });
    subtotal = totalPrice - taxTotal;
    discount = calculateTotalDiscount({ totalPrice, discountType, discountValue });

    return {
        totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
        subtotal: isNaN(subtotal) ? 0.0 : subtotal,
        taxTotal: isNaN(taxTotal) ? 0.0 : taxTotal,
        discount: isNaN(discount) ? 0.0 : discount,
    };
};

export const exportToPdf = async ({ values = {}, itemsOptions = [], t, options = {}, fetch, convertedCurrencies }) => {
    const data = await fetch({
        operation: "query",
        multipleEndpoints: [
            {
                endpoint: "settingsgeneral",
                responseData: "_id currencyData{currency currencyname rate} language",
                data: { _id: "settings_general" },
            },
            {
                endpoint: "settingsproperty",
                responseData: "_id name logo phone address city zip registrationNumber email website",
                data: { _id: "settings_property" },
            },
            {
                endpoint: "taxes",
                responseData: "_id name rate taxCategory isVat",
            },
            {
                endpoint: "paymentmethods",
                responseData: "_id name",
            },
            {
                endpoint: "paymentaccounts",
                responseData: "_id name currency bank accountId",
            },
            {
                endpoint: "warehouses",
                responseData: "_id name",
            },
        ],
    });

    const propertyData = data?.settingsproperty || {};
    const settingsData = data?.settingsgeneral || {};
    const taxData = data?.taxes || [];
    const translate = (text) => t(text, { lngs: [values?.pdfLanguage || settingsData?.language || "en"] });
    let { logo } = propertyData || {};

    const pdf = new jsPDF();
    pdf.setFont("Helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(20);

    /**
     * Invoice HEADER
     * display property logo or property name
     * show title (invoice,bill,fiscal invoices,...)
     */

    pdf.setFontSize(22);
    pdf.text(translate(titles[values?.type] || "INVENTORY") + ` #${values?.number || "AUTO"}`, 207, 15, "right");

    /**
     * Show property Details
     * Show property address if address specified in header in settings
     */
    pdf.setFontSize(11);
    pdf.text(propertyData?.name || "", 207, 25, "right");
    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "normal");

    /**
     * Business Details
     */
    let businessString = [
        `${translate("serial")}: ${values?.serial || ""}`,
        `${translate("date")}: ${values?.date || ""}`,
        `${translate("dueDate")}: ${values?.dueDate || ""}`,
        `${translate("status")}: ${translate(values?.status) || translate("DRAFT")}`,
    ]?.join(`\n`);

    /**
     * Bill to Details
     */
    const billTo = values?.supplierData || {};
    const billToString = [
        billTo?.name ? billTo?.name : billTo?.firstName ? `${billTo?.firstName} ${billTo.lastName || ""}` : undefined,
        billTo?.address,
        billTo?.city,
        billTo?.country,
        billTo?.state,
        billTo?.email,
        billTo?.phone,
        billTo?.IDNumber,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);

    pdf.autoTable({
        theme: "plain",
        startY: 35,
        styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
        headStyles: {
            minCellWidth: 75,
            fillColor: [220, 233, 241],
            textColor: [13, 24, 33],
            cellPadding: 1,
            lineWidth: 0,
        },
        margin: { left: 7, top: 0, right: 7 },
        tableWidth: "wrap",
        head: [[translate("details"), translate("supplier")]],
        body: [[businessString, billToString]],
    });

    /*Warehouses Table*/
    const head = [];
    const body = [];
    if (values?.type === "purchase_order" && values?.warehouseTo) {
        const warehouseToName = data?.warehouses?.find((w) => w?._id === values?.warehouseTo)?.name || "";

        head.push(translate("warehouse_to"));
        body.push(warehouseToName);
    } else if (values?.type === "inventory_transfer" && values?.warehouseFrom && values?.warehouseTo) {
        const warehouseToName = data?.warehouses?.find((w) => w?._id === values?.warehouseTo)?.name || "";
        const warehouseFromName = data?.warehouses?.find((w) => w?._id === values?.warehouseFrom)?.name || "";

        head.push(translate("warehouse_to"), translate("warehouse_from"));
        body.push(warehouseToName, warehouseFromName);
    } else if (values?.type === "inventory_deduction" && values?.warehouseFrom) {
        const warehouseFromName = data?.warehouses?.find((w) => w?._id === values?.warehouseFrom)?.name || "";

        head.push(translate("warehouse_from"));
        body.push(warehouseFromName);
    }
    pdf.autoTable({
        theme: "plain",
        startY: pdf.autoTable.previous.finalY + 10,
        styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
        headStyles: {
            minCellWidth: 75,
            fillColor: [220, 233, 241],
            textColor: [13, 24, 33],
            cellPadding: 1,
            lineWidth: 0,
        },
        margin: { left: 7, top: 0, right: 7 },
        tableWidth: "wrap",
        head: [head],
        body: [body],
    });

    /**
     * Items Table
     */

    const itemsDetails = values?.tableData?.map((item) => {
        let quantity = parseFloat(item.quantity);
        if (isNaN(quantity)) quantity = 0;
        let price = parseFloat(item.price);
        if (isNaN(price)) price = 0;
        const taxes =
            taxData?.filter((tax) => item.taxData?.some((t) => t.taxId === tax._id))?.map((tax) => tax.name) || [];
        return [
            itemsOptions?.find((option) => option._id === item.itemId)?.name || "",
            item.description || "",
            quantity?.toFixed(2),
            item.unit || "",
            toCurrency(price, values?.currency || settingsData?.currencyData?.currency || ""),
            taxes?.join(","),
            toCurrency(calculateAmount(item), values?.currency || settingsData?.currencyData?.currency || ""),
        ];
    });

    pdf.autoTable({
        theme: "grid",
        startY: pdf.autoTable.previous.finalY + 10,
        styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
        headStyles: {
            fillColor: [220, 233, 241],
            textColor: [13, 24, 33],
            cellPadding: 1,
            lineWidth: 0,
        },
        margin: { left: 7, top: 0, right: 7 },
        head: [
            [
                translate("item"),
                translate("description"),
                translate("quantity"),
                translate("unit"),
                translate("price"),
                translate("tax"),
                translate("amount"),
            ],
        ],
        body: itemsDetails,
        bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
    });

    /**
     * Detailed amounts table
     */

    const detailedAmount = amountInfo({
        items: values?.tableData,
        taxes: taxData,
        discountType: values?.discountType,
        discountValue: values?.discountValue,
    });

    pdf.autoTable({
        theme: "grid",
        tableWidth: "wrap",
        startY: pdf.autoTable.previous.finalY,
        styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
        margin: {
            left:
                findColumnWidth(pdf.autoTable.previous.columns, pdf.autoTable.previous.columns.length) -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 4].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 3].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 2].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 1].width +
                7,
        },
        columnStyles: {
            0: {
                cellWidth:
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 4].width +
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 3].width,
            },
            1: {
                cellWidth:
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 2].width +
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 1].width,
            },
        },
        body: [
            [
                translate("subtotal"),
                toCurrency(detailedAmount?.subtotal, values?.currency || settingsData?.currencyData?.currency || ""),
            ],
            [
                translate("discount"),
                toCurrency(detailedAmount?.discount, values?.currency || settingsData?.currencyData?.currency || ""),
            ],
            [
                translate("tax"),
                toCurrency(detailedAmount?.taxTotal, values?.currency || settingsData?.currencyData?.currency || ""),
            ],
            // ...(fiscalPdf
            //     ? groupTaxes({ tableData: tableDataDetailed, taxes: taxes }).map((tax) => {
            //           return [
            //               `${pdfLang("TVSH")} ${tax.rate}%`,
            //               toCurrency(tax.total, values?.currency || settingsData?.currencyData?.currency || ""),
            //           ];
            //       })
            //     : []),
            [
                translate("total"),
                toCurrency(
                    detailedAmount?.totalPrice - detailedAmount?.discount,
                    values?.currency || settingsData?.currencyData?.currency || ""
                ),
            ],
        ],
        bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
    });
    if (convertedCurrencies && convertedCurrencies?.length > 0) {
        pdf.autoTable({
            theme: "grid",
            tableWidth: "wrap",
            startY: pdf.autoTable.previous.finalY,
            styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
            margin: {
                left:
                    pdf.internal.pageSize.width -
                    (pdf.autoTable.previous.columns[0].width + pdf.autoTable.previous.columns[1].width) -
                    7,
            },
            columnStyles: {
                0: {
                    cellWidth: pdf.autoTable.previous.columns[0].width,
                },
                1: {
                    cellWidth: pdf.autoTable.previous.columns[1].width,
                },
            },
            body: convertedCurrencies.map((currency) => {
                return [translate("total") + " " + currency.currency, toCurrency(currency.amount, currency.currency)];
            }),
            bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
        });
    }

    /**
     * Payments Table
     */

    if (values?.payments?.length > 0) {
        pdf.autoTable({
            theme: "grid",
            startY: pdf.autoTable.previous.finalY + 10,
            styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
            headStyles: {
                fillColor: [220, 233, 241],
                textColor: [13, 24, 33],
                cellPadding: 1,
                lineWidth: 0,
            },
            margin: { left: 7, top: 0, right: 7 },
            head: [[translate("amount"), translate("payment_method"), translate("date")]],
            body: values?.payments?.map((payment) => {
                const paymentMethodName = data?.paymentmethods?.find(
                    (method) => method._id === payment.paymentMethod
                )?.name;
                return [
                    toCurrency(payment.amount, values?.currency || settingsData?.currencyData?.currency || ""),
                    paymentMethodName || "",
                    DateTime.fromISO(payment.timestamp).toFormat("yyyy-LL-dd HH:mm:ss"),
                ];
            }),
            bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
        });
    }
    let paidAmount = values?.payments?.reduce((acc, payment) => (acc += payment.amount), 0.0) || 0.0;
    paidAmount = parseFloat(parseFloat(paidAmount).toFixed(2));
    pdf.text(
        translate("balance_due") +
            ": " +
            toCurrency(
                detailedAmount.totalPrice - paidAmount,
                values?.currency || settingsData?.currencyData?.currency || ""
            ),
        7,
        pdf.autoTable.previous.finalY + 10
    );

    if (values?.description) {
        pdf.autoTable({
            theme: "grid",
            startY: pdf.autoTable.previous.finalY + 15,
            styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
            margin: { left: 7, top: 0, right: 7 },
            body: [[values?.description]],
            bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
        });
    }

    const pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

    /**
     * Inventory Footer
     */
    if (values?.clientsData?.firstName) {
        pdf.setFontSize(11);
        pdf.text(
            `${translate("client")}:\n${values?.clientsData?.firstName} ${
                values?.clientsData?.lastName || ""
            }${"\n\n_____________"}`,
            140,
            pageHeight - 70
        );
    }

    const footerString = [
        propertyData?.email ? `${translate("email")}: ${propertyData?.email}` : undefined,
        propertyData?.website ? `${translate("web")}: ${propertyData?.website}` : undefined,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);
    const addressString = [
        propertyData?.address,
        propertyData?.state,
        propertyData?.zip,
        propertyData?.city,
        propertyData?.country,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`,`);

    pdf.setTextColor(100);
    pdf.setFontSize(10);
    pdf.text(addressString, pageWidth / 2, pageHeight - 15, "center");
    pdf.text(footerString, pageWidth / 2, pageHeight - 10, "center");

    /**
     * Save pdf
     */

    if (!logo) {
        pdf.text(propertyData?.name || "", 7, 15);
        printPdf({
            pdf,
            type: options?.type,
            title: values?._id || `inventories_${new Date().toJSON()}`,
        });
    } else {
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const imageWidth = img.width;
            const imageHeight = img.height;

            pdf.addImage(img, "JPEG", 7, 5, (25 * imageWidth) / imageHeight, 25);
            printPdf({
                pdf,
                type: options?.type,
                title: values?._id || `inventories_${new Date().toJSON()}`,
            });
        };
    }
};

const printPdf = ({ pdf, type, title }) => {
    if (type === "autoprint") {
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
    } else {
        pdf.save(title + ".pdf");
    }
};
