const validateFiscalBusinessDetails = (fiscalBusiness) => {
    if (!fiscalBusiness) {
        throw new Error("missing_fiscal_business");
    }
    if (!fiscalBusiness.businessUnitCode) {
        throw new Error("businessUnitCode_missing_at_fiscal_business");
    }
    if (!fiscalBusiness.nipt) {
        throw new Error("nipt_missing_at_fiscal_business");
    }
    if (!fiscalBusiness.businessName) {
        throw new Error("businessName_missing_at_fiscal_business");
    }
};

export default validateFiscalBusinessDetails;
