import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const CurrencyList = () => {
    const [currencies, setCurrencies] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "country", header: t("country_name") },
        { accessorKey: "currency", header: t("currency") },
        { accessorKey: "currencyname", header: t("currency_name") },
        { accessorKey: "rate", header: t("conversion_rate") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "currencies",
            responseData: "_id country currencyname currency rate",
        });
        if (response?.currencies) setCurrencies(response.currencies);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteCurrencies" : "deleteCurrency",
            data,
        });
        if (response?.deleteCurrencies === "success" || response?.deleteCurrency === "success") {
            setCurrencies(currencies.filter((currency) => !rows.some((row) => row._id === currency._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };
    const addRecordCurrency = (data) => {
        if (currencies?.some((r) => r._id === data._id)) {
            setCurrencies(
                currencies?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setCurrencies([...currencies, data]);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("currencies_form")}
                moduleType="accounting"
                data={currencies}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("currencies_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordCurrency }} />
        </div>
    );
};

export default CurrencyList;
