import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InputControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";

const rules = {
    name: "string|required",
};

const WarehouseForm = () => {
    const [values, setValues] = useState({});

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { addRecordWarehouse } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "warehouse",
            data: {
                _id: "warehouses_" + params?.id,
            },
            responseData: "_id name code description",
        });
        if (response?.warehouse) setValues(response.warehouse);
    };

    const saveHandler = async () => {
        const { name, code, description, _id } = values;
        const data = { name, code, description };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateWarehouse" : "createWarehouse",
            data,
            responseData: "_id name code description",
        });
        if (response?.createWarehouse?._id || response?.updateWarehouse?._id) {
            enqueueSnackbar(t("warehouse_saved"), { variant: "default" });
            addRecordWarehouse && addRecordWarehouse(response.createWarehouse || response?.updateWarehouse);
            navigate(-1);
        }
    };

    return (
        <div>
            <Modal
                titlelabel={t("warehouses")}
                maxWidth="xs"
                open={true}
                loading={loading}
                yesDisabled={loading}
                FormProps={{
                    values,
                    rules,
                    onValuesChange: setValues,
                    onSubmit: saveHandler,
                }}
                onClose={() => navigate(-1)}
                permission={{ warehouses: params?.id === "create" ? { create: true } : { edit: true } }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl name="code" label={t("code")} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl multiline minRows={3} name="description" label={t("description")} />
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
};

export default WarehouseForm;
