import { Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HotelIcon from "@mui/icons-material/Hotel";
import { DateTime } from "luxon";
import { Stack } from "@mui/system";
import { toCurrency } from "../../../utils";

const ReservationDetailsCard = ({ reservations = [], loading, currency, groupInvoices = [] }) => {
    const { t } = useTranslation();

    const guestsDetails = useMemo(() => {
        const guests = reservations?.map((r) => r.guests || [])?.flat();
        const guestsCategoriesInfo = guests
            ?.map((room) => room?.guests)
            ?.flat()
            ?.filter((category) => !!category.name)
            ?.reduce((acc, category) => {
                const existingCategory = acc.find((c) => c.name === category?.name);
                if (!existingCategory) {
                    acc.push({ name: category?.name, number: category?.number });
                    return acc;
                }
                existingCategory.number += category.number;
                return acc;
            }, []);
        return {
            guestsCategoriesInfo,
            totalGuests: guestsCategoriesInfo?.reduce((total, category) => total + category.number, 0),
        };
    }, [reservations]);

    const roomTypeDetails = useMemo(() => {
        const rooms = reservations.map((r) => r.rooms)?.flat();
        return rooms
            ?.map((room) => room?.roomData?.roomTypeData)
            .reduce((acc, roomtype) => {
                const existingRoomtype = acc.find((rt) => rt._id === roomtype?._id);
                if (existingRoomtype) {
                    existingRoomtype.number += 1;
                    return acc;
                }
                acc.push({ ...roomtype, number: 1 });
                return acc;
            }, []);
    }, [reservations]);

    const totalRooms = useMemo(() => {
        return roomTypeDetails?.reduce((acc, rt) => acc + rt.number, 0.0);
    }, [roomTypeDetails]);

    const amountsInfo = useMemo(() => {
        let totalPrice = reservations?.reduce((acc, r) => acc + r.totalPrice, 0.0);
        let paid = 0.0;
        let unpaid = 0.0;
        const invoices = reservations
            .map((r) => r?.invoices || [])
            ?.flat()
            ?.concat(groupInvoices);
        if (invoices?.length > 0) {
            totalPrice = 0.0;
            invoices
                ?.filter((invoice) => !["CANCELLED", "TRANSFERRED"].includes(invoice.status))
                .forEach((invoice) => {
                    totalPrice += invoice.totalPrice;
                    paid += invoice?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0;
                });
        }
        unpaid = totalPrice - paid;
        if (unpaid < 0) unpaid = 0.0;
        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
            paid: isNaN(paid) ? 0.0 : paid,
            unpaid: isNaN(unpaid) ? 0.0 : unpaid,
        };
    }, [reservations]);

    if (loading) return <TemplateCard t={t} />;

    return (
        <Card>
            <CardHeader avatar={<HotelIcon />} title={t("group_details")} titleTypographyProps={{ variant: "h5" }} />
            <CardContent>
                <Grid container>
                    <DetailBoxBig
                        color="#F8F9FD"
                        margin={false}
                        title={t("nr_of_reservations")}
                        bigText={reservations?.length}
                    />
                    <DetailBoxBig
                        color="#F8F9FD"
                        title={t("total")}
                        bigText={guestsDetails?.totalGuests}
                        secondary={t("guests")}
                    />
                    {guestsDetails?.guestsCategoriesInfo?.map((category) => (
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t(category.name)}
                            bigText={category.number}
                            secondary={t("guests")}
                        />
                    ))}
                </Grid>
                <Grid container sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <DetailBoxBig
                        color="#F8F9FD"
                        margin={false}
                        title={t("nr_of_rooms")}
                        bigText={totalRooms}
                        secondary={t("total")}
                    />

                    {roomTypeDetails?.map((rt) => {
                        return (
                            <DetailBoxBig color="#F8F9FD" title={t("rooms")} bigText={rt.number} secondary={rt.name} />
                        );
                    })}
                </Grid>

                <Divider sx={{ marginBottom: "10px" }} />
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <DetailBoxBig
                            color="rgb(64, 72, 92, 0.2)"
                            margin={false}
                            title={t("total_amount")}
                            bigText={toCurrency(amountsInfo?.totalPrice, currency)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DetailBoxBig
                            color="rgba(19, 206, 102, 0.2)"
                            margin={false}
                            title={t("paid_amount")}
                            bigText={toCurrency(amountsInfo?.paid, currency)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DetailBoxBig
                            color="rgba(255, 73, 73, 0.2)"
                            margin={false}
                            title={t("unpaid")}
                            bigText={toCurrency(amountsInfo?.unpaid, currency)}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
const DetailBoxBig = ({ title, secondary, color, margin, bigText }) => {
    return (
        <Card style={{ backgroundColor: color, marginLeft: margin === false ? 0 : 20, border: "none" }}>
            <CardContent>
                <Typography variant="body2">{title}</Typography>
                <Typography variant="h5">{bigText}</Typography>
                <Typography variant="subtitle2">{secondary}</Typography>
            </CardContent>
        </Card>
    );
};

const TemplateCard = ({ t }) => {
    return (
        <Card>
            <CardHeader
                avatar={<HotelIcon />}
                title={t("reservation_details")}
                titleTypographyProps={{ variant: "h5" }}
            />
            <CardContent>
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                </Stack>
                <Stack direction="row" justifyContent="space-between" mt={1} spacing={4}>
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                </Stack>
                <Stack direction="row" justifyContent="space-between" mt={1} spacing={4}>
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ReservationDetailsCard;
