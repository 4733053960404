import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import getPermission from "./getPermission";
import { getLoggedUserData } from "../utils";

const PrivateRoute = ({ permission, children }) => {
    const { hasToken, hasRight } = getPermission(permission);
    const location = useLocation();
    if (!hasToken) return <Navigate to="/login" />;
    if (!hasRight) {
        const { role } = getLoggedUserData();
        const isWaiter = !!role?.isWaiter;
        if (isWaiter) {
            const waiterLoginPOS = localStorage.getItem("waiterLoginPOS");
            if (!!waiterLoginPOS) {
                return <Navigate to={`/pos/sell-login?pos=${waiterLoginPOS?.split("pospoints_")[1]}`} />;
            }
        }
        if (location.pathname === "/") return null;
        return <Navigate to="/" />;
    }
    return children;
};

export default PrivateRoute;
