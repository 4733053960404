import {
    ButtonBase,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Skeleton,
    Typography,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Divider,
} from "@mui/material";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { toCurrency } from "../../../utils";

const defaultImage = "https://res.cloudinary.com/strowberry-code/image/upload/v1702588814/hb/pos-item_yovhsj.png";

const ProductButton = ({ name, price, onClick, currency, product, isSmallScreen }) => {
    const [searchParams] = useSearchParams();
    const posId = searchParams.get("pos");

    const pospointPrice = useMemo(() => {
        const posPrice = product?.pospoints?.find((pos) => pos?.posPointId === `pospoints_${posId}`)?.price;
        if (posPrice !== undefined && posPrice !== null) return posPrice;
        return price;
    }, [product, price]);

    if (isSmallScreen)
        return (
            <>
                <ListItemButton
                    onClick={() => {
                        onClick && onClick(pospointPrice);
                    }}
                    style={{
                        backgroundColor: "#ffffff",
                        borderLeft: "10px solid grey",
                        paddingLeft: 10,
                        paddingTop: 12,
                        paddingBottom: 12,
                    }}
                >
                    <ListItemText
                        primary={
                            <Typography style={{ color: "black" }} variant="body2">
                                {name}
                            </Typography>
                        }
                    />
                    <ListItemIcon>{toCurrency(pospointPrice, currency)}</ListItemIcon>
                </ListItemButton>
                <Divider />
            </>
        );
    return (
        <ButtonBase
            onClick={() => {
                onClick && onClick(pospointPrice);
            }}
            sx={{
                margin: "7px",
                borderRadius: 2,
                alignItems: "start",
                backgroundColor: "transparent",
                ":active": { transform: "scale(0.95)" },
            }}
        >
            <Card variant="elevation" elevation={1} style={{ borderRadius: 10, height: 120, width: 170 }}>
                <CardContent style={{ padding: "0" }}>
                    <Typography
                        style={{
                            textAlign: "left",
                            paddingLeft: 10,
                            paddingTop: 10,
                            lineHeight: 1.2,
                            fontSize: "1.1rem",
                        }}
                        variant="h6"
                    >
                        {name}
                    </Typography>
                </CardContent>
                <CardActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Typography style={{ color: "rgba(0, 0, 0, 0.6)" }} variant="subtitle2">
                        {toCurrency(pospointPrice, currency)}
                    </Typography>
                </CardActions>
            </Card>

            {/* <Card variant="elevation" elevation={1} style={{ borderRadius: 10, height: 150 }}>
                <CardMedia width={150} component="img" height={100} src={defaultImage} />
                <CardContent
                    sx={{ width: 150, position: "absolute", bottom: 0, left: 0, zIndex: 5, maxWidth: 150 }}
                    style={{
                        backgroundColor: "hsla(0,0%,86.3%,.6)",
                        padding: "0",
                        borderBottomRightRadius: 10,
                        borderBottomLeftRadius: 10,
                    }}
                >
                    <Typography style={{ color: "black" }} variant="h6">
                        {name}
                    </Typography>
                    <Typography style={{ color: "black" }} variant="subtitle2">
                        {toCurrency(pospointPrice, currency)}
                    </Typography>
                </CardContent>
            </Card> */}
        </ButtonBase>
    );
};

export const ProductButtonSkeleton = () => {
    return (
        <ButtonBase
            sx={{
                margin: "7px",
                borderRadius: 2,
                alignItems: "start",
                backgroundColor: "transparent",
            }}
        >
            <Card variant="elevation" elevation={1} style={{ borderRadius: 10, height: 120, width: 170 }}>
                <CardContent style={{ padding: "0" }}>
                    <Typography
                        style={{
                            textAlign: "left",
                            paddingLeft: 10,
                            paddingTop: 10,
                            lineHeight: 1.2,
                            fontSize: "1.1rem",
                        }}
                        variant="h6"
                    >
                        <Skeleton width={130} />
                    </Typography>
                </CardContent>
                <CardActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Typography style={{ color: "rgba(0, 0, 0, 0.6)" }} variant="subtitle2">
                        <Skeleton width={70} />
                    </Typography>
                </CardActions>
            </Card>
        </ButtonBase>
    );
};

export default ProductButton;
