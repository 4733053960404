import { Grid, LinearProgress, Paper, Typography, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, InputControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { SubmitButton } from "../general/components";

const rules = {
    ndermarrjaserver: "string|required",
    connectionstringname: "string|required",
    username: "string|required",
    password: "string|required",
};

const SettingsAlphawebForm = () => {
    const [values, setValues] = useState({});

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "settingsalphaweb",
            data: {
                _id: "settings_alphaweb",
            },
            responseData: "_id ndermarrjaserver connectionstringname username password",
        });
        if (response?.settingsalphaweb)
            setValues({
                ...response.settingsalphaweb,
            });
    };

    const saveHandler = async () => {
        const { _id, ndermarrjaserver, connectionstringname, username, password } = values;
        const data = {
            ndermarrjaserver,
            connectionstringname,
            username,
            password,
        };
        if (_id) {
            data._id = _id;
        }

        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateSettingsAlphaweb" : "createSettingsAlphaweb",
            data,
            responseData: "_id",
        });
        if (response?.createSettingsAlphaweb?._id || response?.updateSettingsAlphaweb?._id)
            enqueueSnackbar(t("settings_alphaweb_saved"), { variant: "default" });
    };

    return (
        <Form values={values} onValuesChange={setValues} rules={rules} onSubmit={saveHandler}>
            {loading && <LinearProgress color="primary" />}
            <Paper style={{ minHeight: "calc(100vh - 120px)", padding: "20px" }}>
                <Typography variant="h6">{t("alphaweb_settings")}</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <img
                            src="https://res.cloudinary.com/strowberry-code/image/upload/v1619872450/hb/AlphaWEB_ycinub.png"
                            alt="alpha web logo"
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputControl name="ndermarrjaserver" label={t("ndermarrjaserver")} />
                        <InputControl name="connectionstringname" label={t("connectionstringname")} />
                        <InputControl name="username" label={t("username")} />
                        <InputControl name="password" label={t("password")} />
                    </Grid>

                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={6}></Grid>

                    <Grid container item xs={12} justifyContent="end" style={{ marginTop: 20 }}>
                        <SubmitButton
                            onClick={saveHandler}
                            permission={{ settings: values?._id ? { edit: true } : { create: true } }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Form>
    );
};

export default SettingsAlphawebForm;
