import React from "react";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import DoneIcon from "@mui/icons-material/Done";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import { Avatar, Button, Card, CardContent, Grid, Typography, Stack, Chip } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const statusProps = {
    clean: { color: "#adf0cc", icon: <CleaningServicesOutlinedIcon />, iconBg: "#14713F", iconColor: "#fff" },
    inspected: { color: "#e4c1f9", icon: <DoneIcon />, iconBg: "#644D83", iconColor: "#fff" },
    dirty: { color: "#f67393", icon: <ErrorOutlineIcon />, iconBg: "#F6385A", iconColor: "#fff" },
    occupied: { color: "#b3e4f5", icon: <NotInterestedIcon />, iconBg: "#116389", iconColor: "#fff" },
    roomService: { color: "#b6a61c", icon: <DryCleaningIcon />, iconBg: "#a86200", iconColor: "#fff" },
};

const HousekeepingCard = ({ housekeeping, changeStatus }) => {
    const { t } = useTranslation();
    const renderButton = (_id, status) => {
        switch (status) {
            case "clean":
                return (
                    <Button color="primary" variant="contained" onClick={() => changeStatus(_id, "inspected")}>
                        {t("inspected")}
                    </Button>
                );
            case "dirty":
                return (
                    <Button color="primary" variant="contained" onClick={() => changeStatus(_id, "clean")}>
                        {t("clean")}
                    </Button>
                );
            case "roomService":
                return (
                    <Button color="primary" variant="contained" onClick={() => changeStatus(_id, "occupied")}>
                        {t("occupied")}
                    </Button>
                );

            default:
                return null;
        }
    };
    return (
        <Card elevation={0} style={{ backgroundColor: statusProps[housekeeping.status]?.color }}>
            <CardContent>
                <Grid container xs={12} style={{ display: "block" }}>
                    <Link style={{ color: "#000", textDecoration: "none" }} to="/lostfounds/create">
                        <Stack direction="row" spacing={1}>
                            <Grid item xs={2}>
                                <Avatar
                                    style={{
                                        backgroundColor: statusProps[housekeeping.status]?.iconBg,
                                        color: statusProps[housekeeping.status]?.iconColor,
                                    }}
                                >
                                    {statusProps[housekeeping.status]?.icon}
                                </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                                <Stack
                                    spacing={{ xs: 1, sm: 2 }}
                                    direction="row"
                                    useFlexGaps
                                    flexWrap="wrap"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="h4">{housekeeping.room}</Typography>
                                    <Chip
                                        label={t(`${housekeeping.status}`)}
                                        variant="outlined"
                                        sx={{
                                            color: statusProps[housekeeping.status]?.iconBg,
                                            borderColor: statusProps[housekeeping.status]?.iconBg,
                                            fontWeight: 700,
                                        }}
                                    />
                                </Stack>
                                <Typography variant="caption">{housekeeping.roomtype}</Typography>
                            </Grid>
                        </Stack>
                    </Link>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container item xs={12} style={{ marginBottom: 10 }}>
                            <PersonPinIcon />
                            <Typography style={{ marginLeft: 10 }} variant="subtitle1">
                                {housekeeping.assignedTo}
                            </Typography>
                        </Grid>
                        <Stack item direction="row" useFlexGaps flexWrap="wrap" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                component={Link}
                                size="small"
                                to={housekeeping._id?.split("housekeepings_rooms_")[1]}
                            >
                                {t("edit")}
                            </Button>
                            {renderButton(housekeeping?._id, housekeeping?.status)}
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default HousekeepingCard;
