import { calculateTotalTax, exchangeCurrency } from "../../../utils";

export const generateMergedInvoice = ({ invoices = [], currencies = [], taxes = [], invoiceCurrency, customerId }) => {
    const newInvoice = {
        tableData: [],
        payments: [],
        totalPrice: 0,
        reservationIds: [],
        status: "DRAFT",
        invoiceCurrency,
        customer: customerId,
    };

    if (!invoiceCurrency) throw new Error("invoice currency must be provided");
    if (!customerId) throw new Error("invoice customer must be provided");

    invoices?.forEach((invoice) => {
        if (!invoice.invoiceCurrency) throw new Error("invoice currency missing at " + invoice._id);
        newInvoice.tableData = newInvoice.tableData.concat(
            invoice?.tableData?.map((item) => {
                if (!item.itemId) {
                    throw new Error("item id missing at " + invoice._id);
                }
                if (item.quantity === undefined || item.quantity === null) {
                    throw new Error("item quantity missing at " + invoice._id);
                }
                let price;
                if (invoiceCurrency === invoice.invoiceCurrency) {
                    price = parseFloat(item.price);
                } else {
                    price = exchangeCurrency({
                        amount: item.price,
                        fromCurrency: invoice.invoiceCurrency,
                        toCurrency: invoiceCurrency,
                        currencies,
                    });
                }
                if (isNaN(price)) {
                    price = 0.0;
                }
                const { totalAmount, includedAmount } = calculateTotalTax({
                    taxData: item.taxData?.map((t) => ({
                        isIncluded: t.isIncluded,
                        tax: taxes.find((tax) => tax._id === t.taxId),
                    })),
                    price: price,
                });
                newInvoice.totalPrice += (price + totalAmount - includedAmount) * (item.quantity || 0);

                return {
                    itemId: item.itemId,
                    price: price,
                    taxData: item.taxData?.map((tax) => {
                        return { taxId: tax.taxId, isIncluded: tax.isIncluded };
                    }),
                    description: !!item.description ? item.description : "",
                    quantity: item.quantity,
                    unit: !!item.unit ? item.unit : "",
                };
            })
        );
        newInvoice.payments = newInvoice.payments.concat(
            invoice.payments?.map((payment) => {
                let paymentAmount;
                if (invoiceCurrency === invoice.invoiceCurrency) {
                    paymentAmount = parseFloat(payment.amount);
                } else {
                    paymentAmount = exchangeCurrency({
                        amount: payment.amount,
                        fromCurrency: invoice.invoiceCurrency,
                        toCurrency: invoiceCurrency,
                        currencies,
                    });
                }
                if (isNaN(paymentAmount)) {
                    paymentAmount = 0.0;
                }
                return {
                    amount: paymentAmount,
                    paymentMethod: payment.paymentMethod,
                    timestamp: payment.timestamp,
                    userName: payment.userName,
                    note: payment.note || "",
                };
            }) || []
        );
        newInvoice.reservationIds = newInvoice.reservationIds.concat(
            invoice.reservationIds?.filter((id) => !newInvoice.reservationIds.includes(id)) || []
        );
    });

    return newInvoice;
};
