import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    LinearProgress,
    Stack,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { InputControl, SwitchControl } from "../../components/form";
import { InfoComponent } from "../../components/common";

const GuestCategoryList = () => {
    const [guestCategories, setGuestCategories] = useState([]);
    const [values, setValues] = useState({
        enableSorting: false,
        categoriesOrder: {},
    });
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "from", header: t("from") },
        { accessorKey: "to", header: t("to") },
        { accessorKey: "description", header: t("description") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "guestcategories",
            responseData: "_id name description from to priorityOrder",
        });
        if (response?.guestcategories) setGuestCategories(response.guestcategories);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteGuestCategories" : "deleteGuestCategory",
            data,
        });
        if (response?.deleteGuestCategories === "success" || response?.deleteGuestCategory === "success") {
            setGuestCategories(
                guestCategories.filter((guestcategory) => !rows.some((row) => row._id === guestcategory._id))
            );
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addRecordGuestCategory = (data) => {
        if (guestCategories?.some((r) => r._id === data._id)) {
            setGuestCategories(
                guestCategories?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setGuestCategories([...guestCategories, data]);
    };

    const onPriorityOrderSave = async () => {
        const changedCategories =
            guestCategories?.map((category) => {
                return {
                    _id: category._id,
                    priorityOrder:
                        values?.categoriesOrder[category._id] !== null &&
                        values?.categoriesOrder[category._id] !== undefined
                            ? parseInt(values?.categoriesOrder[category._id])
                            : category.priorityOrder,
                };
            }) || [];

        if (!changedCategories) return;
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateGuestCategoriesOrder",
            data: { guestCategories: changedCategories },
            responseData: "_id name description from to priorityOrder",
        });
        if (response?.updateGuestCategoriesOrder) {
            enqueueSnackbar(t("guest_category_order_saved"), { variant: "default" });
            setGuestCategories(response?.updateGuestCategoriesOrder);
            setValues({ ...values, enableSorting: false, categoriesOrder: {} });
        }
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Stack direction="row" marginBottom={3} alignItems="center" gap={"50px"}>
                <div>
                    <SwitchControl
                        value={values?.enableSorting}
                        onChange={(e) => setValues({ ...values, enableSorting: e.target.checked, categoriesOrder: {} })}
                        label={t("update_sort")}
                    />
                    <InfoComponent text={t("extra_guests_sort_priority")} />
                </div>
                {values?.enableSorting && (
                    <Button
                        onClick={() => {
                            onPriorityOrderSave();
                        }}
                        startIcon={<SaveIcon />}
                        variant="contained"
                    >
                        {t("save")}
                    </Button>
                )}
            </Stack>
            <Stack direction="row" gap={2}>
                {guestCategories
                    ?.sort((a, b) => {
                        return a.priorityOrder - b.priorityOrder;
                    })
                    ?.map((category) => {
                        return (
                            <Card
                                sx={{ minWidth: 250, cursor: "pointer" }}
                                onClick={() => {
                                    if (values?.enableSorting) {
                                        return;
                                    }
                                    navigate(`${category?._id?.split("guestcategories_")[1]}`);
                                }}
                            >
                                <CardHeader
                                    title={category.name}
                                    sx={{ paddingTop: 3, paddingBottom: 0 }}
                                    action={
                                        <IconButton
                                            color="primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onDeleteHandler([category]);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                />
                                <CardContent sx={{ paddingTop: 0, paddingBottom: 3 }}>
                                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                        {t("age")} {t("from")} {category.from || 0} {t("to")}{" "}
                                        {category.to || t("above")}
                                    </Typography>
                                </CardContent>
                                {values?.enableSorting && (
                                    <CardActions sx={{ paddingTop: 3 }}>
                                        <InputControl
                                            value={
                                                values?.categoriesOrder[category._id] !== null &&
                                                values?.categoriesOrder[category._id] !== undefined
                                                    ? values?.categoriesOrder[category._id]
                                                    : category.priorityOrder
                                            }
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    categoriesOrder: {
                                                        ...values.categoriesOrder,
                                                        [category._id]: e.target.value,
                                                    },
                                                })
                                            }
                                            type="number"
                                            label={t("order_postion")}
                                        />
                                    </CardActions>
                                )}
                            </Card>
                        );
                    })}
                <Button onClick={() => navigate("create")}>
                    <AddIcon style={{ fontSize: 100 }} />
                </Button>
            </Stack>
            {/* <Table
                titleLabel={t("guest_category_form")}
                moduleType="backoffice"
                data={guestCategories}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("guestcategories_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            /> */}
            <Outlet context={{ addRecordGuestCategory }} />
        </div>
    );
};

export default GuestCategoryList;
