import React, { useEffect, useState } from "react";
import { ReportGenerator } from "../../components/classes";
import { Stack } from "@mui/material";
import { DateRangeControl } from "../../components/dates";
import { ReportHeader } from "./components";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { toCurrency } from "../../utils";

const REPORT_RESPONSE_DATA = `
        date
        guests{
            totalByCategory{category total}
            arrivalTotalByCategory{category total}
            departureTotalByCategory{category total}
        } 
        rooms{
            totalByRoomtype{roomtype total}
            checkinTotalByRoomtype{roomtype total}
            checkoutTotalByRoomtype{roomtype total}
            cancelledTotalByRoomtype{roomtype total}
            noShowTotalByRoomtype{roomtype total}
        }
        dailyUseReservations{
            totalGuestsByCategory{category total}
            totalRoomsByRoomtype{roomtype total}

        }
        dailyCreatedReservations{
            totalRoomsByRoomtype{roomtype total}
            
        }
       
        revenue{
            dailyTotalByRate{rate total{currency amount}}
            dailyTotalBySource{source total{currency amount}}
            dailyTotalByRoomtype{roomtype total{currency amount}}
            dailyTotal{currency amount}
            departuresNotPaid
        }
        dailyCreatedOrders{
            totalRevenueByPOS{
                pospointId
                amountInfo{gross{currency amount} net{currency amount}}
            }
        }

        payments{
            allPayments{
                currency amount
            }
            hotelPayments{
                currency amount
            }
        }
    `;
const ForecastReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [extraData, setExtraData] = useState({
        rooms: [],
        roomtypes: [],
        rates: [],
        pospoints: [],
        guestCategoires: [],
    });

    const [report, setReport] = useState(null);
    const { loading, fetch } = useApi();
    const columns = [
        {
            id: "date",
            header: t("date"),
            displayValue: (row) => {
                return row.date;
            },
            totalValue: () => t("total"),
        },
        {
            id: "roomsOccupied",
            header: t("rooms_occupied"),
            displayValue: (row) => {
                return row.roomsOccupied;
            },
            totalValue: (rows) => {
                return (
                    rows?.reduce((total, row) => {
                        total += row.roomsOccupied || 0;
                        return total;
                    }, 0) || 0
                );
            },
        },
        {
            id: "arrivalRooms",
            header: t("arrival_rooms"),
            displayValue: (row) => {
                return row.arrivalRooms;
            },
            totalValue: (rows) => {
                return (
                    rows?.reduce((total, row) => {
                        total += row.arrivalRooms || 0;
                        return total;
                    }, 0) || 0
                );
            },
        },
        {
            id: "rooms",
            header: t("rooms"),
            displayValue: (row) => {
                return row?.rooms;
            },
            totalValue: (rows) => {
                return (
                    rows?.reduce((total, row) => {
                        total += row.rooms || 0;
                        return total;
                    }, 0) || 0
                );
            },
        },
        {
            id: "occ",
            header: t("occ_%"),
            displayValue: (row) => {
                return row.occ + " %";
            },
            totalValue: (rows) => {
                const averageOccupancy =
                    (rows?.reduce((total, row) => {
                        total += parseFloat(row.occ) || 0;
                        return total;
                    }, 0) || 0) /
                    (rows?.length - 1);
                return averageOccupancy?.toFixed(2) + " %";
            },
        },
        {
            id: "roomRevenue",
            header: t("room_revenue"),
            displayValue: (row) => {
                return currenciesAsString(row?.roomRevenue);
            },
            totalValue: (rows) => {
                const totalRevenue = {};
                rows?.forEach((row) => {
                    row?.roomRevenue?.forEach((currencyAmount) => {
                        if (!totalRevenue[currencyAmount.currency]) {
                            totalRevenue[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0.0 };
                        }
                        totalRevenue[currencyAmount.currency].amount += currencyAmount.amount;
                    });
                });
                return currenciesAsString(Object.values(totalRevenue));
            },
        },
        {
            id: "revPAR",
            header: t("rev_par"),
            displayValue: (row) => {
                return currenciesAsString(row?.revPAR);
            },
            totalValue: (rows) => {
                const totalRevPAR = {};
                rows?.forEach((row) => {
                    row?.revPAR?.forEach((currencyAmount) => {
                        if (!totalRevPAR[currencyAmount.currency]) {
                            totalRevPAR[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0.0 };
                        }
                        totalRevPAR[currencyAmount.currency].amount += currencyAmount.amount;
                    });
                });
                return currenciesAsString(
                    Object.values(totalRevPAR)?.map((currencyAmount) => {
                        return { ...currencyAmount, amount: currencyAmount.amount / (rows?.length - 1) };
                    })
                );
            },
        },
        {
            id: "averageRate",
            header: t("average_rate"),
            displayValue: (row) => {
                return currenciesAsString(row?.averageRate);
            },
            totalValue: (rows) => {
                const totalADR = {};
                rows?.forEach((row) => {
                    row?.averageRate?.forEach((currencyAmount) => {
                        if (!totalADR[currencyAmount.currency]) {
                            totalADR[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0.0 };
                        }
                        totalADR[currencyAmount.currency].amount += currencyAmount.amount;
                    });
                });
                return currenciesAsString(
                    Object.values(totalADR)?.map((currencyAmount) => {
                        return { ...currencyAmount, amount: currencyAmount.amount / (rows?.length - 1) };
                    })
                );
            },
        },
        {
            id: "departingRooms",
            header: t("departing_rooms"),
            displayValue: (row) => {
                return row?.departingRooms;
            },
            totalValue: (rows) => {
                return (
                    rows?.reduce((total, row) => {
                        total += row.departingRooms || 0;
                        return total;
                    }, 0) || 0
                );
            },
        },
        {
            id: "dayUseRooms",
            header: t("day_use_rooms"),
            displayValue: (row) => {
                return row?.dayUseRooms;
            },
            totalValue: (rows) => {
                return (
                    rows?.reduce((total, row) => {
                        total += row.dayUseRooms || 0;
                        return total;
                    }, 0) || 0
                );
            },
        },
        {
            id: "noShow",
            header: t("no_show"),
            displayValue: (row) => {
                return row?.noShow;
            },
            totalValue: (rows) => {
                return (
                    rows?.reduce((total, row) => {
                        total += row.noShow || 0;
                        return total;
                    }, 0) || 0
                );
            },
        },
        {
            id: "cancelledRooms",
            header: t("cancelled_rooms"),
            displayValue: (row) => {
                return row?.cancelledRooms;
            },
            totalValue: (rows) => {
                return (
                    rows?.reduce((total, row) => {
                        total += row.cancelledRooms || 0;
                        return total;
                    }, 0) || 0
                );
            },
        },
        {
            id: "pax",
            header: t("pax"),
            displayValue: (row) => {
                return (
                    row?.pax
                        ?.map((category) => {
                            if (!category.total) return 0;
                            return `${category.total} ${category.category}`;
                        })
                        ?.join(", ") || 0
                );
            },
            totalValue: (rows) => {
                const totalPAX = {};
                rows?.forEach((row) => {
                    row?.pax?.forEach((category) => {
                        if (!totalPAX[category.category]) {
                            totalPAX[category.category] = { category: category.category, total: 0.0 };
                        }
                        totalPAX[category.category].total += category.total || 0;
                    });
                });
                return (
                    Object.values(totalPAX)
                        ?.map((category) => {
                            if (!category.total) return 0;
                            return `${category.total} ${category.category}`;
                        })
                        ?.join(", ") || 0
                );
            },
        },
    ];
    const currenciesAsString = (amountsByCurrencies = []) => {
        if (amountsByCurrencies?.length === 0) return "0.00";
        return amountsByCurrencies
            ?.map((currencyAmount) => {
                return toCurrency(currencyAmount.amount, currencyAmount.currency);
            })
            ?.join(" , ");
    };
    useEffect(() => {
        loadExtraData();
    }, []);
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomtypes",
                    responseData: "_id name taxData{taxId isIncluded tax{rate taxCategory}}",
                },
                {
                    endpoint: "rooms",
                    responseData: "_id name",
                },
                {
                    endpoint: "roomrates",
                    responseData: "_id name",
                },
                {
                    endpoint: "pospoints",
                    responseData: "_id name",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name",
                },
            ],
        });
        if (!!response?.roomtypes)
            setExtraData({
                roomtypes: response.roomtypes,
                rooms: response.rooms,
                rates: response.roomrates,
                pospoints: response.pospoints,
                guestCategoires: response.guestcategories,
            });
    };
    const loadData = async () => {
        if (!params.get("startDate") || !params.get("endDate")) return;
        const start = params.get("startDate");
        const end = params.get("endDate");
        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + start;
        if (!!end) filtersText += ", " + t("end_date") + ": " + end;
        const response = await fetch({
            operation: "query",
            endpoint: "datesGeneralManagerReport",
            responseData: REPORT_RESPONSE_DATA,
            data: { startDate: params.get("startDate"), endDate: params.get("endDate") },
        });
        if (!response?.datesGeneralManagerReport) return;

        const reportDailyData = response?.datesGeneralManagerReport?.map((report) => {
            const soldUnits =
                report?.rooms?.totalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0) || 0.0;

            const arrivalRooms = report?.rooms?.checkinTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0);
            const departingRooms = report?.rooms?.checkoutTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0);

            const occupancy = ((soldUnits / extraData?.rooms?.length) * 100).toFixed(2);

            const grossAmountByCurrencies = {};
            report?.revenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
                roomtype?.total?.forEach((amount) => {
                    if (!grossAmountByCurrencies[amount.currency]) {
                        grossAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                    }
                    grossAmountByCurrencies[amount.currency].amount += amount.amount;
                });
            });
            const grossREVPAR = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
                const totalUnits = extraData?.rooms?.length;
                const grossADR = currencyAmount.amount / totalUnits;
                return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
            });
            const averageRate = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
                const grossADR = currencyAmount.amount / soldUnits;
                return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
            });

            const dailyUseRooms =
                report?.dailyUseReservations?.totalRoomsByRoomtype.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0) || 0.0;
            const noShowRooms =
                report?.rooms?.noShowTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0) || 0.0;
            const cancelledRooms =
                report?.rooms?.cancelledTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0) || 0.0;

            return {
                date: report.date,
                roomsOccupied: soldUnits,
                arrivalRooms,
                rooms: extraData?.rooms?.length,
                occ: occupancy,
                roomRevenue: Object.values(grossAmountByCurrencies),
                revPAR: grossREVPAR,
                averageRate: averageRate,
                departingRooms,
                dayUseRooms: dailyUseRooms,
                noShow: noShowRooms,
                cancelledRooms,
                pax: report?.guests?.totalByCategory,
            };
        });

        const newRaportGenerator = new ReportGenerator({
            data: reportDailyData,
            columns,
            displayTotal: true,
        });
        setReport(
            newRaportGenerator.generateTable({
                title: t("forecast_report"),
                landscape: true,
                headerInfo: [filtersText],
            })
        );
    };
    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");

        return data;
    };
    return (
        <div>
            <ReportHeader
                title={t("forecast_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                </Stack>
            </ReportHeader>
            {report}
        </div>
    );
};

export default ForecastReport;
