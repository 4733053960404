import { Button, CardHeader, Chip } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MergeIcon from "@mui/icons-material/Merge";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckboxControl } from "../../../components/form";
import { Table } from "../../../components/table";
import { toCurrency } from "../../../utils";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid
    TRANSFERRED: "#FFC82C", //transferred to group
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const InvoicesTable = ({ data = [] }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const columns = [
        {
            id: "name",
            header: t("name"),
            cell: ({ row: { original } }) =>
                (original?.clientsData?.firstName || "") + " " + (original?.clientsData?.lastName || ""),
        },
        // { accessorKey: "number", header: t("number") },
        { accessorKey: "date", header: t("date") },
        {
            accessorKey: "totalPrice",
            header: t("amount"),
            cell: ({ row: { original } }) => toCurrency(original?.totalPrice, original?.invoiceCurrency || ""),
        },
        {
            id: "status",
            header: "",
            cell: ({ row: { original } }) => {
                let status = original?.status || "DRAFT";
                if (original?.paymentStatus === "PAID") {
                    status = "PAID";
                }
                if (["CANCELLED", "TRANSFERRED"].includes(original?.status)) {
                    status = original?.status;
                }
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={status} />;
            },
        },
        {
            id: "link",
            header: "",
            cell: ({ row: { original } }) => (
                <Button
                    startIcon={<BorderColorIcon />}
                    fullWidth
                    onClick={() => navigate("/finance/invoices/" + original?._id?.split("invoices_")?.[1])}
                >
                    {t("edit")}
                </Button>
            ),
        },
        // {
        //     id: "select",
        //     header: "",
        //     cell: ({ row: { original } }) => <CheckboxControl />,
        // },
    ];
    return (
        <>
            <CardHeader
                avatar={<ReceiptIcon />}
                // action={
                //     <Button startIcon={<MergeIcon style={{ transform: "rotate(90deg)" }} />} variant="contained">
                //         {t("merge")}
                //     </Button>
                // }
                title={t("invoices")}
                titleTypographyProps={{ variant: "h5" }}
            />
            <Table data={data} columns={columns} disableHeader disableSelection disableDelete disableFooter />
        </>
    );
};

export default InvoicesTable;
