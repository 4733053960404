import { Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
    AutoCompleteControl,
    CheckboxControl,
    InputControl,
    RadioGroupControl,
    SwitchControl,
    TagsAutoCompleteControl,
} from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EditableTaxesTable, GuestCategoriesCard, PriceInformationCard } from "../../components/common";
import { EditableBedtypesTable } from "./components";
import { calculateTotalTax } from "../../utils";

const rules = {
    name: "string|required",
};
const mealsOptions = ["breakfast", "lunch", "dinner", "all_inclusive"];

const RoomtypeForm = () => {
    const [values, setValues] = useState({
        bedtypes: [{}, {}],
    });
    const { t } = useTranslation();
    const [bedtypesOptions, setBedtypesOptions] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [tags, setTags] = useState([]);
    const [facilitesOptions, setFacilitiesOptions] = useState([]);
    const [guestcategories, setGuestcategories] = useState([]);
    const [settings, setSettings] = useState({});
    const [propertyKeyOptions, setPropertyKeyOptions] = useState([]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { addRecordRoomtype } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "roomtype",
            data: {
                _id: "roomtypes_" + params?.id,
            },
            responseData: `_id name amenities meals price images beds24Id beds24RoomtypePriceId beds24PropertyKey iCalLink roomtypeOrder
                taxData{taxId isIncluded}  bedtypes{id quantity} maxCapacity includedCapacity capacity{id price} tags`,
        });
        if (response?.roomtype) setValues(response.roomtype);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "bedtypes", responseData: "_id name" },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                {
                    endpoint: "facilities",
                    responseData: "_id name",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name",
                },
                {
                    endpoint: "tags",
                    responseData: "_id name description color",
                },
                {
                    endpoint: "beds24PropertyKeys",
                    responseData: "propertyKey propertyName",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
            ],
        });
        if (!!response?.bedtypes)
            setBedtypesOptions(response.bedtypes.map((bedtype) => ({ value: bedtype._id, label: bedtype.name })));
        if (!!response?.taxes) setTaxes(response.taxes);
        if (response?.facilities) setFacilitiesOptions(response.facilities);
        if (response?.guestcategories) setGuestcategories(response.guestcategories);
        if (response?.tags) setTags(response.tags.map((tag) => ({ value: tag._id, label: tag.name, data: tag })));
        if (response?.settingsgeneral) setSettings(response.settingsgeneral);
        if (response?.beds24PropertyKeys)
            setPropertyKeyOptions(
                response?.beds24PropertyKeys?.map((property) => {
                    return { value: property.propertyKey, label: property.propertyName };
                })
            );
    };
    const currency = settings?.currencyData?.currency;

    const amountInfo = useMemo(() => {
        const { totalAmount, includedAmount } = calculateTotalTax({
            taxData: values.taxData?.map((t) => ({
                isIncluded: t.isIncluded,
                tax: taxes.find((tax) => tax._id === t.taxId),
            })),
            price: parseFloat(values.price),
        });
        const totalPrice = parseFloat(values.price) + totalAmount - includedAmount;
        const subtotal = totalPrice - totalAmount;
        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice.toFixed(2),
            subtotal: isNaN(subtotal) ? 0.0 : subtotal.toFixed(2),
            tax: isNaN(totalAmount) ? 0.0 : totalAmount.toFixed(2),
        };
    }, [values.taxData, values.price, taxes]);

    const saveHandler = async () => {
        const {
            name,
            amenities,
            meals,
            price,
            taxData,
            maxCapacity,
            includedCapacity,
            capacity = [],
            images,
            bedtypes,
            beds24Id,
            beds24RoomtypePriceId,
            beds24PropertyKey,
            iCalLink,
            _id,
            roomtypeOrder,
            tags,
        } = values;
        const data = {
            name,
            amenities,
            meals,
            price: parseFloat(price),
            taxData,
            maxCapacity: isNaN(parseFloat(maxCapacity)) ? null : parseFloat(maxCapacity),
            includedCapacity: isNaN(parseFloat(includedCapacity)) ? null : parseFloat(includedCapacity),
            capacity: capacity?.map((c) => {
                const price = parseFloat(c.price);
                return { id: c.id, price: isNaN(price) ? null : price };
            }),
            images,
            tags,
            bedtypes,
            beds24Id,
            beds24RoomtypePriceId,
            beds24PropertyKey,
            iCalLink,
        };
        if (_id) data._id = _id;
        if (!isNaN(parseInt(roomtypeOrder))) data.roomtypeOrder = parseInt(roomtypeOrder);
        else data.roomtypeOrder = null;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateRoomType" : "createRoomType",
            data,
            responseData: `_id name amenities meals price images beds24Id beds24RoomtypePriceId beds24PropertyKey iCalLink roomtypeOrder
            taxData{taxId isIncluded}  bedtypes{id quantity} maxCapacity includedCapacity capacity{id price} tags`,
        });
        if (response?.createRoomType?._id || response?.updateRoomType?._id) {
            enqueueSnackbar(t("roomtype_saved"), { variant: "default" });
            addRecordRoomtype && addRecordRoomtype(response.createRoomType || response?.updateRoomType);
            navigate(-1);
        }
    };

    const capacityChangeHandler = (capacityData) => {
        if (!values.capacity) {
            setValues({ ...values, capacity: [capacityData] });
            return;
        }
        if (values.capacity.some((c) => c.id === capacityData.id)) {
            setValues({
                ...values,
                capacity: values.capacity.map((c) => {
                    if (c.id === capacityData.id) return capacityData;
                    return c;
                }),
            });
            return;
        }
        setValues({ ...values, capacity: [...values.capacity, capacityData] });
    };

    return (
        <Modal
            titlelabel={t("room_type")}
            fullScreen
            open={true}
            loading={loading}
            onClose={() => navigate(-1)}
            yesDisabled={loading}
            permission={{ roomtypes: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler }}
        >
            <Grid container spacing={4} alignItems="flex-start">
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("roomtype_details")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="price" label={t("price")} type="number" toCurrency={currency} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="roomtypeOrder" label={t("roomOrder")} type="number" />
                    </Grid>
                    <Grid item xs={12}>
                        <TagsAutoCompleteControl multiple options={tags} size="large" name="tags" />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("roomtype_integrations_details")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="beds24Id" label={t("beds24Id")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="beds24RoomtypePriceId" label={t("price_id")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RadioGroupControl
                            options={propertyKeyOptions}
                            name="beds24PropertyKey"
                            label={t("multiproperty_id")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteControl
                            multiple
                            freeSolo
                            value={values.iCalLink || []}
                            onChange={(e) => setValues({ ...values, iCalLink: e.target.value })}
                            label={t("iCalLink")}
                            size="large"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12} sm={6}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("taxes")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <EditableTaxesTable
                            taxes={taxes}
                            data={values?.taxData || []}
                            onChange={(taxData) => setValues({ ...values, taxData })}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("bedtypes")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <EditableBedtypesTable
                            bedtypes={bedtypesOptions}
                            data={values?.bedtypes || []}
                            onChange={(bedtypeData) => setValues({ ...values, bedtypes: bedtypeData })}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("included_meals")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {mealsOptions.map((meal) => (
                            <CheckboxControl
                                value={values?.meals?.includes(meal) || false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({ ...values, meals: [...(values.meals || []), meal] });
                                    else setValues({ ...values, meals: values.meals.filter((m) => m !== meal) });
                                }}
                                label={t(meal)}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("amenities")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {facilitesOptions.map((facility) => (
                            <CheckboxControl
                                value={values?.amenities?.includes(facility.name) || false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({
                                            ...values,
                                            amenities: [...(values.amenities || []), facility.name],
                                        });
                                    else
                                        setValues({
                                            ...values,
                                            amenities: values.amenities.filter((m) => m !== facility.name),
                                        });
                                }}
                                label={facility.name}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("guest_categories")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                            <InputControl type="number" name="maxCapacity" label={t("capacity")} />
                        </Grid>
                        <Grid item xs={4}>
                            <InputControl type="number" name="includedCapacity" label={t("included_capacity")} />
                        </Grid>
                    </Grid>
                    {guestcategories.map((category) => {
                        const guestCapacity = values.capacity?.find((c) => c.id === category._id);
                        return (
                            <Grid item xs={4}>
                                <GuestCategoriesCard
                                    values={guestCapacity}
                                    onValuesChange={capacityChangeHandler}
                                    t={t}
                                    id={category._id}
                                    name={category.name}
                                    currency={currency}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid justifyContent="end" container item xs={12}>
                    <Grid item xs={12} sm={6}>
                        <PriceInformationCard
                            tax={amountInfo?.tax}
                            subtotal={amountInfo?.subtotal}
                            total={amountInfo?.totalPrice}
                            currency={currency}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};
export default RoomtypeForm;
