import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../components/modal";
import ReservationsTable from "./ReservationsTable";

const UnassignedReservationsList = ({ open, onClose, reservations = [] }) => {
    const { t } = useTranslation();
    return (
        <Modal open={open} onClose={onClose} titlelabel={t("unassigned_reservations")} maxWidth="lg">
            <ReservationsTable data={reservations} />
        </Modal>
    );
};

export default UnassignedReservationsList;
