import React from "react";
import { useTranslation } from "react-i18next";
import { InputControl, SelectControl } from "../../../components/form";
import { EditableTable } from "../../../components/table";

const EditablePrintersTable = ({ data = [], printers = [], onChange }) => {
    const { t } = useTranslation();
    const columns = [
        {
            header: t("printer"),
            cell: ({ rowIndex, row }) => {
                return (
                    <SelectControl
                        value={row?.printer || ""}
                        options={printers}
                        onChange={(e) => {
                            data[rowIndex] = { printer: e.target.value };
                            onChange && onChange(data);
                        }}
                        label={t("printer")}
                    />
                );
            },
        },
        {
            header: t("device"),
            cell: ({ rowIndex, row }) => {
                return (
                    <InputControl
                        value={row?.device || ""}
                        onChange={(e) => {
                            data[rowIndex].device = e.target.value;
                            onChange && onChange(data);
                        }}
                        label={t("device")}
                    />
                );
            },
        },
    ];
    return (
        <EditableTable
            onAddRow={() => onChange([...data, {}])}
            onDeleteRow={(rowIndex) => onChange(data.filter((r, index) => index !== rowIndex))}
            columns={columns}
            data={data}
        />
    );
};

export default EditablePrintersTable;
