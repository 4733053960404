import {
    AppBar,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    SwipeableDrawer,
    Toolbar,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { withTranslation } from "react-i18next";
import ProfileButton from "./ProfileButton";
import { Link } from "react-router-dom";
import { getLoggedUserData } from "../../../utils";

const softwareLogo = "https://res.cloudinary.com/strowberry-code/image/upload/v1640015211/hb/new-logo_jkj6ii.png";

const NavBar = ({ open = false, toggleOpen, style = {}, children, t }) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const userData = getLoggedUserData();
    return (
        <>
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    backgroundColor: "navbarColor.default",
                    backdropFilter: "blur(10px)",
                    transition: "width .3s ease-out",
                    marginLeft: open ? 240 : 60,
                    width: `calc(100% - ${open ? 240 : 60}px)`,
                    "@media screen and (max-width: 900px)": {
                        marginLeft: open ? 240 : 0,
                        width: `calc(100% - ${open ? 240 : 0}px)`,
                    },
                    zIndex: 2,
                }}
            >
                <Toolbar>
                    <div className="desktop-menu-icon">
                        <IconButton onClick={toggleOpen} sx={{ color: "navbarColor.contrastText" }} size="large">
                            {open ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </div>
                    <img style={{ margin: "auto" }} alt="logo" height="40px" src={softwareLogo} />
                    <div className="desktop-submenu">
                        <div className="desktop-menu">
                            <ul className="nav-all">{children}</ul>
                        </div>
                    </div>
                    <div className="desktop-menu">
                        <ProfileButton />
                    </div>
                    <IconButton
                        sx={{ color: "navbarColor.contrastText" }}
                        onClick={() => setOpenDrawer(true)}
                        size="large"
                    >
                        <FormatListBulletedIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                anchor={"right"}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
            >
                <SideInfo t={t} />
                <List component="nav" className="mobile-nav" style={{ padding: "8px 0px 80px 0" }}>
                    <Typography variant="subtitle2" style={{ margin: 10 }}>
                        Menu
                    </Typography>
                    <ListItem button>
                        <ListItemText onClick={() => setOpenDrawer(false)} primary={children} />
                    </ListItem>
                </List>
                <div className="bottom-wrapper" style={{ position: "fixed" }}>
                    <Typography variant="subtitle1" style={{ marginLeft: 10 }}>
                        {t("logged_in_as")}
                        <strong>{userData?.displayName || ""}</strong>
                    </Typography>

                    <Button component={Link} to={"/login"} sx={{ borderRadius: 0 }} fullWidth variant="outlined">
                        {t("logout")}
                    </Button>
                </div>
            </SwipeableDrawer>
        </>
    );
};

const SideInfo = ({ t }) => {
    return (
        <div>
            <div style={{ width: 250, margin: 10 }}>
                <Typography variant="h5">{t("today")}</Typography>
                <Typography variant="h4"> </Typography>
            </div>
            <Divider />
            <div style={{ width: 250, margin: 10 }}>
                <Typography variant="h6">{t("notifications")}</Typography>
                <Divider />
            </div>
        </div>
    );
};

export default withTranslation("translations")(NavBar);
