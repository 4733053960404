import { Number } from ".";

const calculateTotalTax = ({ taxData = [], price = 0 }) => {
    const taxAmountInformation = {
        includedAmount: new Number(0),
        totalAmount: new Number(0),
    };

    taxData?.forEach(({ isIncluded, tax }) => {
        switch (tax?.taxCategory) {
            case "percentage":
                if (isIncluded) {
                    const amount = new Number(price?.toString())
                        .multipliedBy(tax?.rate?.toString())
                        .multipliedBy(0.01)
                        .div(new Number(tax?.rate?.toString()).multipliedBy(0.01).plus(1));

                    if (!amount.isNaN()) {
                        taxAmountInformation.includedAmount = taxAmountInformation.includedAmount.plus(amount);
                        taxAmountInformation.totalAmount = taxAmountInformation.totalAmount.plus(amount);
                    }
                } else {
                    const amount = new Number(price?.toString()).multipliedBy(tax?.rate?.toString()).multipliedBy(0.01);
                    if (!amount.isNaN()) {
                        taxAmountInformation.totalAmount = taxAmountInformation.totalAmount.plus(amount);
                    }
                }
                break;
        }
    });

    return {
        includedAmount: taxAmountInformation.includedAmount.toNumber(),
        totalAmount: taxAmountInformation.totalAmount.toNumber(),
    };
};

export default calculateTotalTax;
