const mergeInvoices = (invoices) => {
    const mergedInvoice = { tableData: [], payments: [], totalPrice: 0, reservationIds: [], status: "DRAFT" };

    invoices?.forEach((invoice) => {
        mergedInvoice.tableData = mergedInvoice.tableData?.concat(invoice?.tableData);
        mergedInvoice.payments = mergedInvoice.payments?.concat(invoice?.payments);
        invoice?.reservationIds?.forEach((id) => {
            if (!mergedInvoice?.reservationIds?.includes(id)) {
                mergedInvoice?.reservationIds?.push(id);
            }
        });
        mergedInvoice.totalPrice += invoice?.totalPrice;
        if (invoice?.customer) mergedInvoice.customer = invoice.customer;
        if (invoice?.discountValue) mergedInvoice.discountValue = invoice.discountValue;
        if (invoice?.discountType) mergedInvoice.discountType = invoice.discountType;
        if (invoice?.cashdesk) mergedInvoice.cashdesk = invoice.cashdesk;
        if (invoice?.invoiceCurrency) mergedInvoice.invoiceCurrency = invoice.invoiceCurrency;
        if (invoice?.fiscalCurrency) mergedInvoice.fiscalCurrency = invoice.fiscalCurrency;
        if (invoice?.pdfLanguage) mergedInvoice.pdfLanguage = invoice.pdfLanguage;
        if (invoice?.paymentOption) mergedInvoice.paymentOption = invoice.paymentOption;
        if (invoice?.cancelReason) mergedInvoice.cancelReason = invoice.cancelReason;
        if (invoice?.invoiceCurrencyRate) mergedInvoice.invoiceCurrencyRate = invoice.invoiceCurrencyRate;
        if (invoice?.profileID) mergedInvoice.profileID = invoice.profileID;
        if (invoice?.invoiceTypeCode) mergedInvoice.invoiceTypeCode = invoice.invoiceTypeCode;
        if (invoice?.paymentMeansCode) mergedInvoice.paymentMeansCode = invoice.paymentMeansCode;
    });
    return mergedInvoice;
};

export default mergeInvoices;
