import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl } from "../../../components/form";
import { Modal } from "../../../components/modal";
import { useApi } from "../../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};

const ProductCategoryForm = () => {
    const [values, setValues] = useState({});

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addRecordProductCategory } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "productcategory",
            data: {
                _id: "productcategories_" + params?.id,
            },
            responseData: "_id name description",
        });
        if (response?.productcategory) setValues(response.productcategory);
    };

    const saveHandler = async () => {
        const { name, description, _id } = values;
        const data = {
            name,
            description,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateProductCategory" : "createProductCategory",
            data,
            responseData: "_id name description",
        });
        if (response?.createProductCategory?._id || response?.updateProductCategory?._id) {
            enqueueSnackbar(t("product_category_saved"), { variant: "default" });
            addRecordProductCategory &&
                addRecordProductCategory(response.createProductCategory || response?.updateProductCategory);

            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("product_category")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
            permission={{ productcategories: params?.id === "create" ? { create: true } : { edit: true } }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                    <InputControl name="description" label={t("description")} multiline minRows={4} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ProductCategoryForm;
