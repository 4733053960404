import { Button, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import EditIcon from "@mui/icons-material/Edit";
import { EditableTable } from "../../../components/table";
import { SimpleQuantityControl } from "../../../components/form";
import { calculateTotalTax } from "../../../utils";
import { useApi } from "../../../components/hooks";

const EditOrderItemsModal = ({ orderId, items = [], disabled = false, onOrderUpdate }) => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [editedOrderItems, setEditedOrderItems] = useState([]);

    useEffect(() => {
        if (!openModal) return;
        setEditedOrderItems(items);
    }, [openModal]);

    const columns = [
        {
            header: t("product"),
            cell: ({ rowIndex, row }) => <Typography variant="body1">{row?.productData?.name || ""}</Typography>,
        },
        {
            header: t("price"),
            cell: ({ rowIndex, row }) => {
                const price = parseFloat(row?.price);
                return <Typography variant="body1">{isNaN(price) ? 0.0 : price.toFixed(2)}</Typography>;
            },
        },
        {
            header: t("quantity"),
            cell: ({ rowIndex, row }) => {
                return (
                    <SimpleQuantityControl
                        onChange={(value) => {
                            setEditedOrderItems(
                                editedOrderItems?.map((item) => {
                                    if (item.productId === row.productId) {
                                        return { ...item, quantity: value };
                                    }
                                    return item;
                                })
                            );
                        }}
                        value={row?.quantity}
                    />
                );
            },
        },
    ];

    const amountInfo = useMemo(() => {
        return editedOrderItems.reduce(
            (total, item) => {
                const taxAmount = calculateTotalTax({ taxData: item?.productData?.taxData, price: item.price });
                const itemTotalPrice = item.quantity * (taxAmount.totalAmount - taxAmount.includedAmount + item.price);
                total.tax += taxAmount.totalAmount * item.quantity;
                if (isNaN(itemTotalPrice)) return total;
                total.totalPrice += itemTotalPrice;
                return total;
            },
            { totalPrice: 0.0, tax: 0.0 }
        );
    }, [editedOrderItems]);

    const { loading, fetch } = useApi();

    const updateOrder = async () => {
        const orderData = {
            _id: orderId,
            products: editedOrderItems
                ?.filter((item) => {
                    return !!item && !!item.quantity;
                })
                ?.map((item) => {
                    return {
                        productId: item.productId,
                        price: item.price,
                        quantity: item.quantity,
                        note: item.note || "",
                        courses: null,
                    };
                }),
            totalPrice: amountInfo.totalPrice,
            subtotal: amountInfo.totalPrice - amountInfo.tax,
        };

        const response = await fetch({
            operation: "mutation",
            endpoint: "updateOrder",
            data: orderData,
            responseData: `
                _id 
                subtotal
                totalPrice
                products{
                    productId
                    productData{name measureUnit warehouseIds taxData{isIncluded tax{taxCategory rate} }}
                    price
                    quantity
                    note
                } `,
        });
        if (response?.updateOrder?._id) {
            onOrderUpdate(response?.updateOrder);
            setOpenModal(false);
            setEditedOrderItems([]);
        }
    };

    return (
        <>
            <Button variant="outlined" disabled={disabled} startIcon={<EditIcon />} onClick={() => setOpenModal(true)}>
                {t("edit_items")}
            </Button>
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                noText="none"
                yesText={t("confirm")}
                yesDisabled={loading || editedOrderItems?.length === 0}
                onSave={updateOrder}
                loading={loading}
                maxWidth="md"
            >
                <EditableTable
                    columns={columns}
                    data={editedOrderItems}
                    onDeleteRow={(rowIndex) => {
                        setEditedOrderItems(editedOrderItems?.filter((item, i) => i !== rowIndex));
                    }}
                />
            </Modal>
        </>
    );
};

export default EditOrderItemsModal;
