import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";

const ActionBox = ({ children, title, icon, width, height = "100%", containerProps = {} }) => {
    return (
        <Paper elevation={0} variant="outlined" {...containerProps}>
            <Stack minWidth={150} minHeight={150} width={width} height={height} justifyContent="space-between">
                <Stack p={1}>{children}</Stack>
                <Box
                    // sx={{ backgroundColor: "rgb(0,0,0,0.1)" }}
                    p={1}
                    display="flex"
                    justifyContent="center"
                    color={"gray"}
                >
                    {icon}
                    <Typography ml={1} variant="subtitle2">
                        {title}
                    </Typography>
                </Box>
            </Stack>
        </Paper>
    );
};

export default ActionBox;
