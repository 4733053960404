import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const UserList = () => {
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const columns = [
        { accessorKey: "username", header: t("username") },
        { accessorKey: "email", header: t("email") },
        {
            id: "role",
            header: t("role"),
            accessorFn: (row) => row?.role?.name || null,
        },
        { accessorKey: "status", header: t("status") },
    ];
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "users",
            responseData: "_id username email role{name} status",
        });
        if (response?.users) setUsers(response.users);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteUsers" : "deleteUser",
            data,
        });
        if (response?.deleteUsers === "success" || response?.deleteUser === "success") {
            setUsers(users.filter((user) => !rows.some((row) => row._id === user._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const addRecordUser = (data) => {
        if (users?.some((r) => r._id === data._id)) {
            setUsers(
                users?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setUsers([...users, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("users_form")}
                moduleType="accounting"
                data={users}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("users_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
                disableDelete
            />
            <Outlet context={{ addRecordUser }} />
        </div>
    );
};

export default UserList;
