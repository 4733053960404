import { Dialog, Grid, Tab, Tabs, Toolbar } from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import CalendarContainer from "./CalendarContainer";
import CalendarDay from "./CalendarDay";
import CalendarInput from "./CalendarInput";
import { BOTH_DATES, START_DATE, END_DATE } from "./Constants";

const dateToString = (date) => {
    if (!date) return null;
    return date.toFormat("yyyy-LL-dd");
};

const stringToDate = (dateAsString) => {
    if (!dateAsString) return null;
    return DateTime.fromISO(dateAsString);
};

const DateRangePicker = ({
    values: { startDate = null, endDate = null },
    onChange,
    startLabel,
    endLabel,
    onClose,
    fullWidth = false,
}) => {
    const [activeValue, setActiveValue] = useState(null);
    const [activeMonth, setActiveMonth] = useState(DateTime.now());
    const [hoverDay, setHoverdDay] = useState(null);

    const onDatesChange = (date) => {
        switch (activeValue) {
            case BOTH_DATES:
                onChange({ startDate: dateToString(date), endDate: null });
                setActiveValue(END_DATE);
                break;
            case END_DATE:
                if (startDate && date < stringToDate(startDate)) {
                    onChange({ startDate: dateToString(date), endDate: null });
                    break;
                }
                onChange({ startDate, endDate: dateToString(date) });
                setActiveValue(null);
                break;
            case START_DATE: // make end date always null to prevent double onchange when user press multiple times
                // if (endDate && date > stringToDate(endDate)) {
                //     onChange({ startDate: dateToString(date), endDate: null });
                //     setActiveValue(END_DATE);
                //     break;
                // }
                onChange({ startDate: dateToString(date), endDate: null });
                setActiveValue(END_DATE);
                break;
        }
    };

    return (
        <div style={{ display: "inline-block", width: fullWidth && "100%" }}>
            <CalendarInput
                startDate={stringToDate(startDate)}
                endDate={stringToDate(endDate)}
                startLabel={startLabel}
                endLabel={endLabel}
                openCalendarView={(newActiveValue) => {
                    setActiveMonth(Boolean(startDate) ? stringToDate(startDate) : DateTime.now());
                    setActiveValue(newActiveValue);
                }}
                onClear={() => onChange({ startDate: null, endDate: null })}
                fullWidth={fullWidth}
            />
            <Dialog
                maxWidth="md"
                onClose={() => {
                    setActiveValue(null);
                    onClose && onClose();
                }}
                open={Boolean(activeValue)}
            >
                <Toolbar>
                    <Tabs
                        value={activeValue === END_DATE ? 1 : 0}
                        onChange={(e, value) => setActiveValue(value === 0 ? START_DATE : END_DATE)}
                        style={{ margin: "0 auto" }}
                    >
                        <Tab label={startLabel} />
                        <Tab label={endLabel} />
                    </Tabs>
                </Toolbar>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <CalendarContainer
                            key={activeMonth}
                            onMonthChange={setActiveMonth}
                            onDateChange={onDatesChange}
                            month={activeMonth}
                            renderDay={(day, _, pickerDayProps) => (
                                <CalendarDay
                                    hoverDay={hoverDay}
                                    setHoverdDay={setHoverdDay}
                                    day={day}
                                    props={pickerDayProps}
                                    startDate={stringToDate(startDate)}
                                    endDate={stringToDate(endDate)}
                                />
                            )}
                            componentsProps={{
                                leftArrowButton: { color: "primary" },
                                rightArrowButton: { color: "primary" },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CalendarContainer
                            key={activeMonth}
                            month={activeMonth.plus({ month: 1 })}
                            onDateChange={onDatesChange}
                            renderDay={(day, _, pickerDayProps) => (
                                <CalendarDay
                                    hoverDay={hoverDay}
                                    setHoverdDay={setHoverdDay}
                                    day={day}
                                    props={pickerDayProps}
                                    startDate={stringToDate(startDate)}
                                    endDate={stringToDate(endDate)}
                                />
                            )}
                            componentsProps={{
                                leftArrowButton: { style: { display: "none" } },
                                rightArrowButton: { style: { display: "none" } },
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
};

export default DateRangePicker;
