const triggerDetails = {
    newReservationConfirm: [
        { value: "{guestName}", label: "guest_name", ex: "(Ex: John Doe)" },
        { value: "{reservationNumber}", label: "reservation_number", ex: "(Ex: d47hu)" },
        { value: "{checkin}", label: "checkin", ex: "(Ex: 2024 Apr 11)" },
        { value: "{checkout}", label: "checkout", ex: "(Ex: 2024 Apr 12)" },
        { value: "{rooms}", label: "rooms", ex: "(Ex: 201, 202)" },
        { value: "{roomTypes}", label: "roomtypes", ex: "(Ex: Single Room, Double Room)" },
        { value: "{reservationTotal}", label: "totalPrice", ex: "(Ex: 50 ALL)" },
        { value: "{roomRate}", label: "rate", ex: "(Ex: Standard Rate, HB Rate)" },
    ],
    reservationCancellation: [
        { value: "{guestName}", label: "guest_name", ex: "(Ex: John Doe)" },
        { value: "{reservationNumber}", label: "reservation_number", ex: "(Ex: d47hu)" },
        { value: "{checkin}", label: "checkin", ex: "(Ex: 2024 Apr 11)" },
        { value: "{checkout}", label: "checkout", ex: "(Ex: 2024 Apr 12)" },
        { value: "{rooms}", label: "rooms", ex: "(Ex: 201, 202)" },
        { value: "{roomTypes}", label: "roomtypes", ex: "(Ex: Single Room, Double Room)" },
        { value: "{reservationTotal}", label: "totalPrice", ex: "(Ex: 50 ALL)" },
        { value: "{roomRate}", label: "rate", ex: "(Ex: Standard Rate, HB Rate)" },
    ],
};
export default triggerDetails;
