import { MenuItem, Menu, TextField } from "@mui/material";
import React from "react";
import { capitalize } from "../../utils";
import { withTranslation } from "react-i18next";

const SelectControl = ({
    options = [],
    label,
    name,
    t,
    size = "small",
    margin = "dense",
    fullWidth = true,
    ...otherProps
}) => {
    const labelText = label || capitalize(name);
    return (
        <TextField select label={labelText} size={size} margin={margin} fullWidth={fullWidth} {...otherProps}>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {t(`${option.label}`)}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default withTranslation("translations")(SelectControl);
