import {
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    Fab,
    Grid,
    LinearProgress,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReservationHandler } from "../../../../components/classes";
import { toCurrency } from "../../../../utils";
import { CreateReservationContext } from "./CreateReservationContext";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ReceiptPreview = ({ addClientAndContinue, onDirectConfirm, onSave, loading }) => {
    const { t } = useTranslation();
    const [openReceiptModal, setOpenReceiptModal] = useState(false);
    const { values, setValues, activeStep, setActiveStep, dailyData, data } = useContext(CreateReservationContext);
    const [disableConfirm, setDisableConfirm] = useState(false);

    const nights = useMemo(() => {
        if (!values.checkin || !values.checkout) return 0;
        return DateTime.fromISO(values.checkout).diff(DateTime.fromISO(values.checkin), "days").toObject().days;
    }, [values.checkin, values.checkout]);

    const isBackDisabled = useMemo(() => {
        if (activeStep === 0) return true;
        if (activeStep === 3) return true;
        return false;
    }, [activeStep]);

    const isNextDisabled = () => {
        switch (activeStep) {
            case 0:
                if (!values?.checkin || !values?.checkout) return true;
                if (
                    Object.values(
                        values?.isSimpleReservation ? values?.checkedRoomtypes : values?.checkedRooms
                    )?.filter((room) => !!room)?.length === 0
                )
                    return true;
                break;
            case 2:
                return disableConfirm;
        }
        return false;
    };

    const isConfirmDisabled = () => {
        if (!values?.checkin || !values?.checkout) return true;
        if (
            Object.values(values?.isSimpleReservation ? values?.checkedRoomtypes : values?.checkedRooms)?.filter(
                (room) => !!room
            )?.length === 0
        ) {
            return true;
        }
        if (activeStep === 0 && !values?.isBlockedRooms && values?.addedClients?.length === 0) return true;
        return false;
    };

    const totalRoomtypeGuests = useMemo(() => {
        const roomtypesArray = Object.values(values.checkedRoomtypes)?.filter((rt) => !!rt);
        if (!roomtypesArray || roomtypesArray.length === 0) return 0;
        return roomtypesArray?.reduce((acc, rt) => {
            const roomtypeGuestsArray = Object.values(rt.guests || {});
            if (!roomtypeGuestsArray || roomtypeGuestsArray.length === 0) return acc;
            acc += roomtypeGuestsArray.reduce((total, category) => total + category.number, 0) * rt.quantity;
            return acc;
        }, 0);
    }, [values?.checkedRoomtypes]);

    const totalGuests = useMemo(() => {
        const roomsArray = Object.values(values.checkedRooms)?.filter((room) => !!room);
        if (!roomsArray || roomsArray.length === 0) return 0;
        return roomsArray?.reduce((acc, room) => {
            const roomGuestsArray = Object.values(room.guests || {});
            if (!roomGuestsArray || roomGuestsArray.length === 0) return acc;
            acc += roomGuestsArray.reduce((total, category) => total + category.number, 0);
            return acc;
        }, 0);
    }, [values?.checkedRooms]);

    const reservationInfo = useMemo(() => {
        const roomsArray = Object.values(values.checkedRooms)?.filter((room) => !!room);
        const roomtypesArray = Object.values(values.checkedRoomtypes)?.filter((rt) => !!rt && rt.quantity);
        if (values?.isSimpleReservation) {
            if (!roomtypesArray || roomtypesArray.length === 0) return { rooms: [], totalPrice: 0 };
        } else {
            if (!roomsArray || roomsArray.length === 0) return { rooms: [], totalPrice: 0 };
        }
        const reservation = values?.isSimpleReservation
            ? ReservationHandler.fromNewSimpleReservation({
                  checkin: values?.checkin,
                  checkout: values?.checkout,
                  roomtypes: roomtypesArray,
                  discount: values?.discount,
                  dailyAvailabilities: dailyData?.dailyPricing,
                  isSimpleReservation: true,
                  isBlockedRooms: values?.isBlockedRooms,
                  guestCategories: data?.guestcategories,
              })
            : ReservationHandler.fromNewReservation({
                  checkin: values?.checkin,
                  checkout: values?.checkout,
                  rooms: roomsArray,
                  discount: values?.discount,
                  dailyAvailabilities: dailyData?.dailyPricing,
                  isBlockedRooms: values?.isBlockedRooms,
                  guestCategories: data?.guestcategories,
              });

        return {
            rooms: values?.isSimpleReservation ? reservation.getRoomtypesInfo() : reservation.getRoomsInfo(),
            totalPrice: reservation.calculateTotal(),
        };
    }, [
        values?.checkedRooms,
        values?.discount,
        values?.checkedRoomtypes,
        values?.isBlockedRooms,
        dailyData?.dailyPricing,
    ]);

    const onNextClick = () => {
        switch (activeStep) {
            case 0:
                setActiveStep(1);
                break;
            case 1:
                if (!!values?.isBlockedRooms) {
                    setActiveStep(2);
                    return;
                }
                addClientAndContinue();
                break;
            case 2:
                setDisableConfirm(true);
                onSave();
                break;
        }
    };
    const isSmallScreen = useMediaQuery("(max-width:901px)");

    const Receipt = () => (
        <Card variant="elevation" elevation={0} sx={{ position: "sticky", top: "70px" }}>
            {loading && <LinearProgress color="success" />}
            <CardHeader title={t("reservation_information")} titleTypographyProps={{ variant: "h6" }} />
            <CardContent>
                <Grid container marginBottom={2}>
                    <Grid item xs={12} style={{ fontWeight: 700 }}>
                        {t("rooms")}
                    </Grid>
                    {reservationInfo?.rooms?.map((room) => (
                        <>
                            <Grid item xs={6}>
                                {room.roomtypeName} {values?.isSimpleReservation ? room.quantity : room.roomName}
                            </Grid>
                            <Grid container item xs={6} justifyContent="end">
                                {toCurrency(room?.totalPrice, data?.currency)}
                            </Grid>
                        </>
                    ))}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Typography style={{ fontWeight: 700 }}>{t("stay")}</Typography>
                <Stack direction="row">
                    <DetailInfo
                        label={t("checkin")}
                        value={!!values.checkin ? DateTime.fromISO(values.checkin).toFormat("LLL dd") : t("add_date")}
                    />
                    <DetailInfo
                        label={t("checkout")}
                        value={!!values.checkout ? DateTime.fromISO(values.checkout).toFormat("LLL dd") : t("add_date")}
                        align="end"
                    />
                </Stack>
                <Stack direction="row">
                    <DetailInfo label={t("nights")} value={`${nights} ${t(nights > 1 ? "nights" : "night")}`} />
                    <DetailInfo
                        label={t("guests")}
                        value={values?.isSimpleReservation ? totalRoomtypeGuests : totalGuests}
                        align="end"
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" marginTop={2}>
                    <Typography variant="h6">{t("total")}</Typography>
                    <Typography variant="h6">{toCurrency(reservationInfo?.totalPrice, data?.currency)}</Typography>
                </Stack>
            </CardContent>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                    top: -70,
                    opacity: isSmallScreen && 0,
                }}
            >
                <Box
                    style={{
                        width: "10px",
                        height: "20px",
                        borderRadius: "10px 0 0 10px",
                        transform: "rotate(180deg)",
                    }}
                    sx={{ backgroundColor: "background.default" }}
                />
                <div style={{ borderBottom: "1px dashed #b3b3b3", width: "100%" }} />
                <Box
                    style={{
                        width: "10px",
                        height: "20px",
                        borderRadius: "10px 0 0 10px",
                    }}
                    sx={{ backgroundColor: "background.default" }}
                />
            </div>
            {activeStep <= 2 && (
                <CardActions>
                    <Stack direction="row" justifyContent="space-between" width="100%" gap={2}>
                        <ButtonGroup fullWidth variant="outlined">
                            <Button
                                startIcon={<ArrowBackIosIcon />}
                                onClick={() => setActiveStep(activeStep - 1)}
                                disabled={isBackDisabled}
                            ></Button>
                            <Button
                                startIcon={<ArrowForwardIosIcon />}
                                onClick={onNextClick}
                                disabled={disableConfirm || isNextDisabled()}
                            ></Button>
                        </ButtonGroup>
                        <Button
                            onClick={() => {
                                onDirectConfirm(() => {
                                    setDisableConfirm(true);
                                });
                            }}
                            fullWidth
                            variant="contained"
                            disabled={disableConfirm || isConfirmDisabled()}
                        >
                            {t("confirm")}
                        </Button>
                    </Stack>
                </CardActions>
            )}
        </Card>
    );

    if (isSmallScreen)
        return (
            <>
                <Fab
                    onClick={() => setOpenReceiptModal(true)}
                    color="primary"
                    sx={{ position: "fixed", bottom: 20, right: 20 }}
                >
                    <ReceiptIcon />
                </Fab>
                <Dialog
                    sx={{ backdropFilter: "blur(2px)", border: "none" }}
                    PaperProps={{ sx: { border: "none" } }}
                    open={openReceiptModal}
                    onClose={() => setOpenReceiptModal(false)}
                    maxWidth="xs"
                    fullWidth
                >
                    <Receipt />
                </Dialog>
            </>
        );

    return <Receipt />;
};

export default ReceiptPreview;

const DetailInfo = ({ label, value, align = "start" }) => {
    return (
        <ListItemText
            primary={label}
            primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
            secondary={value}
            secondaryTypographyProps={{ variant: "subtitle1", color: "primary" }}
            style={{ textAlign: align }}
        />
    );
};
