import React from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl, InputControl } from "../../../components/form";
import { EditableTable } from "../../../components/table";
import { calculateTotalTax, toCurrency } from "../../../utils";

const calculateAmount = (product, taxes = []) => {
    const { totalAmount, includedAmount } = calculateTotalTax({
        taxData: product.taxData?.map((t) => ({
            isIncluded: t.isIncluded,
            tax: taxes.find((tax) => tax._id === t.taxId),
        })),
        price: parseFloat(product.price),
    });
    const totalPrice = (parseFloat(product.price) + totalAmount - includedAmount) * product.quantity;
    return isNaN(totalPrice) ? 0.0 : totalPrice;
};
const ItemsTable = ({ data = [], options = [], onChange, taxesOptions, currency, disabled, disablePrice }) => {
    const productOptions = options.map((option) => {
        return {
            value: option._id,
            label: option.name,
        };
    });
    const taxes = taxesOptions.map((t) => {
        return {
            value: t._id,
            label: t.name,
        };
    });

    const { t } = useTranslation();
    const columns = [
        {
            header: t("product"),
            cell: ({ rowIndex }) => (
                <AutoCompleteControl
                    variant="standard"
                    value={data[rowIndex]?.itemId}
                    label={t("product")}
                    options={productOptions}
                    disabled={disabled}
                    checkDuplicates={true}
                    onChange={(e) => {
                        const { taxData, unit, price } = options.find((o) => o._id === e.target.value) || {};
                        data[rowIndex] = {
                            itemId: e.target.value,
                            taxData: taxData,
                            unit: unit,
                            price: price,
                            quantity: 1,
                        };
                        onChange && onChange(data);
                    }}
                />
            ),
        },
        {
            header: t("description"),
            cell: ({ rowIndex }) => (
                <InputControl
                    disabled={disabled}
                    value={data[rowIndex]?.description || ""}
                    variant="standard"
                    label={t("description")}
                    onChange={(e) => {
                        data[rowIndex].description = e.target.value;
                        onChange && onChange(data);
                    }}
                />
            ),
        },
        {
            header: t("quantity"),
            cell: ({ rowIndex }) => (
                <InputControl
                    disabled={disabled}
                    value={data[rowIndex]?.quantity || ""}
                    variant="standard"
                    type="number"
                    label={t("quantity")}
                    onChange={(e) => {
                        data[rowIndex].quantity = e.target.value;
                        onChange && onChange(data);
                    }}
                />
            ),
        },
        {
            header: t("unit"),
            cell: ({ rowIndex }) => (
                <InputControl
                    disabled={disabled}
                    variant="standard"
                    value={data[rowIndex]?.unit || ""}
                    onChange={(e) => {
                        data[rowIndex].unit = e.target.value;
                        onChange && onChange(data);
                    }}
                    label={t("unit")}
                />
            ),
        },
        {
            header: t("price"),
            cell: ({ rowIndex }) => (
                <InputControl
                    disabled={disabled || disablePrice}
                    toCurrency={currency}
                    variant="standard"
                    value={data[rowIndex]?.price || ""}
                    label={t("price")}
                    type="number"
                    onChange={(e) => {
                        data[rowIndex].price = e.target.value;
                        onChange && onChange(data);
                    }}
                />
            ),
        },
        {
            header: t("tax"),
            cell: ({ rowIndex }) => (
                <AutoCompleteControl
                    disabled={disabled}
                    variant="standard"
                    multiple
                    label={t("tax")}
                    options={taxes}
                    value={data[rowIndex]?.taxData?.map((t) => t.taxId) || []}
                    onChange={(e) => {
                        if (!e.target.value) {
                            data[rowIndex].taxData = [];
                            onChange && onChange(data);
                            return;
                        }
                        const taxData = e.target.value.map((t) => {
                            return { taxId: t, isIncluded: true };
                        });
                        data[rowIndex].taxData = taxData;
                        onChange && onChange(data);
                    }}
                />
            ),
        },
        {
            header: t("amount"),
            cell: ({ rowIndex }) => toCurrency(calculateAmount(data[rowIndex], taxesOptions), currency || ""),
        },
    ];
    return (
        <EditableTable
            data={data || []}
            disabled={disabled}
            columns={columns}
            onAddRow={() => onChange([...data, {}])}
            onDeleteRow={(rowIndex) => onChange(data.filter((r, index) => index !== rowIndex))}
        />
    );
};

export default ItemsTable;
