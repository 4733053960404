import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};
const taxCategoryOptions = [
    { value: "percentage", label: "percentage_(%)" },
    { value: "perStay", label: "per_stay" },
    { value: "perPerson", label: "per_person" },
    { value: "perNight", label: "per_night" },
    { value: "perPersonNight", label: "per_personnight" },
];
const excludedReasonOptions = [
    { value: "TYPE_1", label: "TYPE_1" },
    { value: "TYPE_2", label: "TYPE_2" },
    { value: "EXPORT_OF_GOODS", label: "EXPORT_OF_GOODS" },
    { value: "MARGIN_SCHEME", label: "MARGIN_SCHEME" },
    { value: "TAX_FREE", label: "TAX_FREE" },
];

const TaxForm = () => {
    const [values, setValues] = useState({});

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addRecordTax } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "tax",
            data: {
                _id: "taxes_" + params?.id,
            },
            responseData: "_id name excludedReason rate isTaxExcluded isVat taxCategory",
        });
        if (response?.tax) setValues(response.tax);
    };

    const saveHandler = async () => {
        const { name, excludedReason, rate, isTaxExcluded, isVat, taxCategory, _id } = values;
        const data = {
            name,
            excludedReason,
            rate: parseFloat(rate),
            isTaxExcluded: Boolean(isTaxExcluded),
            isVat: Boolean(isVat),
            taxCategory,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateTax" : "createTax",
            data,
            responseData: "_id name excludedReason rate isTaxExcluded isVat taxCategory",
        });
        if (response?.createTax?._id || response?.updateTax?._id) {
            enqueueSnackbar(t("tax_saved"), { variant: "default" });
            addRecordTax && addRecordTax(response.createTax || response?.updateTax);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("taxes")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
            permission={{ taxes: params?.id === "create" ? { create: true } : { edit: true } }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl name="taxCategory" label={t("tax_category")} options={taxCategoryOptions} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="rate" label={t("rate")} type="number" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SwitchControl name="isTaxExcluded" label={t("is_tax_excluded")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SwitchControl name="isVat" label={t("is_vat")} />
                </Grid>
                {values?.isTaxExcluded && (
                    <Grid item xs={12}>
                        <SelectControl
                            name="excludedReason"
                            label={t("excluded_reason")}
                            options={excludedReasonOptions}
                        />
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default TaxForm;
