import jsPDF from "jspdf";
import "jspdf-autotable";
import { DateTime } from "luxon";
import { getLoggedUserData } from "../../utils";
import currency from "../../assests/currency";

export const exportDataToPdf = ({ title, tableData = [], landscape = false, headerInfo }) => {
    const { _id, displayName } = getLoggedUserData();
    const doc = landscape ? new jsPDF("landscape") : new jsPDF();
    const totalPagesExp = "{total_pages_count_string}";
    const xPosition = landscape === true ? 186 : 0;
    const headers = tableData?.[0]?.map((row) => row?.header);
    const rows = tableData?.map((row) => row?.map((col) => col?.value));
    doc.autoTable({
        head: [headers],
        body: rows,
        styles: {
            overflow: "linebreak",
            cellWidth: "auto",
        },
        theme: "grid",
        didDrawPage: (data) => {
            // Header
            doc.setFontSize(18);
            doc.setTextColor(40);
            doc.text(
                title?.charAt(0)?.toUpperCase() + title?.slice(1) + " Report",
                200 - xPosition,
                15,
                landscape === true ? "left" : "right"
            );

            // Date
            doc.setFontSize(10);
            doc.setFont("helvetica", "italic");
            doc.text(
                "Time Created: " +
                    DateTime.now().toFormat("yyyy-LL-dd HH:mm") +
                    ` by ${displayName || _id?.split("users_")?.[1]}`,
                data.settings.margin.left + 0,
                21
            );
            if (headerInfo?.length) {
                headerInfo?.forEach((row, i) => {
                    doc.text(row, data.settings.margin.left + 0, i * 5 + 26);
                });
            }

            // Footer
            var str = "Page " + doc.internal.getNumberOfPages();
            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === "function") {
                str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(10);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);

            // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            var footerText = "HB";

            doc.setTextColor(100);
            doc.setFontSize(10);
            doc.text(footerText, pageWidth / 2, pageHeight - 10, "center");
        },
        margin: { top: 25 + (headerInfo?.length || 0) * 5 },
    });

    if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
    }

    doc.save(title + " Export " + new Date().toJSON().substring(0, 10) + ".pdf");
};

export const exportDataToCSV = ({ title, tableData = [] }) => {
    const headers = tableData?.[0]?.map((row) => row?.header);
    const rows = tableData?.map((row) =>
        row?.map((col) => {
            if (typeof col.value === "string" && col.value?.includes(",")) {
                col.value = col?.value?.replace(/,/g, "&");
            }
            if (typeof col.value === "string" && currency?.some((c) => c.Ccy === col.value?.split(" ")?.[1])) {
                col.value = col?.value?.split(" ")?.[0];
            }

            return col?.value;
        })
    );

    const csvData = [headers, ...rows];
    let csv = "data:text/csv;charset=utf-8," + csvData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csv);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${title} Export ${new Date().toJSON().substring(0, 10)}.csv`);
    document.body.appendChild(link);
    link.click();
};
