import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputControl, SelectControl } from "../../../../../components/form";
import { Modal } from "../../../../../components/modal";

const RoomDiscountModal = ({ open, onClose, onSave, data, currency }) => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();

    const discountTypeOptions = [
        { value: "total", label: t("total") },
        { value: "percentage", label: t("percentage") },
    ];

    useEffect(() => {
        if (open) {
            setValues(data);
        }
    }, [open]);

    const returnData = () => {
        if (!isNaN(parseFloat(values.customPrice))) return { customPrice: parseFloat(values.customPrice) };
        if (values.type && !isNaN(parseFloat(values.value)))
            return { discount: { type: values.type, value: parseFloat(values.value) } };
        return {};
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onSave={() => {
                onSave(returnData());
                onClose();
            }}
            yesText={t("confirm")}
            titlelabel={t("room_discount")}
            FormProps={{ values, onValuesChange: setValues }}
        >
            <Typography variant="subtitle1">{t("discount")}</Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectControl options={discountTypeOptions} name="type" label={t("discount_type")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="value" type="number" label={t("discount")} />
                </Grid>
            </Grid>
            <Typography marginTop={2} variant="subtitle1">
                {t("final_price")}
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <InputControl toCurrency={currency} name="customPrice" type="number" label={t("price")} />
        </Modal>
    );
};

export default RoomDiscountModal;
