import { Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReportGenerator } from "../../components/classes";
import { DateControl, DateRangeControl } from "../../components/dates";
import { useApi } from "../../components/hooks";
import { calculateTotalTax, generateDatesBetween, toCurrency } from "../../utils";
import { ReportHeader } from "./components";

const REPORT_RESPONSE_DATA = `
    {
        guests{
            totalByCategory{category total}
            arrivalTotalByCategory{category total}
            departureTotalByCategory{category total}
        } 
        rooms{
            totalByRoomtype{roomtype total}
            checkinTotalByRoomtype{roomtype total}
            checkoutTotalByRoomtype{roomtype total}
            cancelledTotalByRoomtype{roomtype total}
            noShowTotalByRoomtype{roomtype total}
        }
        dailyUseReservations{
            totalGuestsByCategory{category total}
            totalRoomsByRoomtype{roomtype total}

        }
        dailyCreatedReservations{
            totalRoomsByRoomtype{roomtype total}
            
        }
       
        revenue{
            dailyTotalByRate{rate total{currency amount}}
            dailyTotalBySource{source total{currency amount}}
            dailyTotalByRoomtype{roomtype total{currency amount}}
            dailyTotal{currency amount}
            departuresNotPaid
        }
        dailyCreatedOrders{
            totalRevenueByPOS{
                pospointId
                amountInfo{gross{currency amount} net{currency amount}}
            }
        }

        payments{
            allPayments{
                currency amount
            }
            hotelPayments{
                currency amount
            }
        }
        blockedRooms{
            OUT_OF_ORDER
            ON_HOLD
        }
    }`;

const currenciesAsString = (amountsByCurrencies = []) => {
    if (amountsByCurrencies?.length === 0) return "0.00";
    return amountsByCurrencies
        ?.map((currencyAmount) => {
            return toCurrency(currencyAmount.amount, currencyAmount.currency);
        })
        ?.join(" , ");
};

const GeneralManagerReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [report, setReport] = useState({
        report: null,
    });
    const [extraData, setExtraData] = useState({
        rooms: [],
        roomtypes: [],
        rates: [],
        pospoints: [],
        guestCategoires: [],
    });
    const { loading, fetch } = useApi();

    const totalMonthDays = useMemo(() => {
        const date = params.get("date");
        if (!date) return 0;
        const currentMonth = DateTime.fromISO(date).toFormat("LL");
        const currentYear = DateTime.fromISO(date).toFormat("yyyy");
        const firstDayOfMonth = `${currentYear}-${currentMonth}-01`;
        const monthlyDays = generateDatesBetween(firstDayOfMonth, date, "[]");
        return monthlyDays?.length;
    }, [params.get("date")]);

    const totalYearDays = useMemo(() => {
        const date = params.get("date");
        if (!date) return 0;
        const currentYear = DateTime.fromISO(date).toFormat("yyyy");
        const firstDayOfYear = `${currentYear}-01-01`;
        const yearlyDays = generateDatesBetween(firstDayOfYear, date, "[]");
        return yearlyDays?.length;
    }, [params.get("date")]);

    const reportColumns = [
        {
            id: "name",
            header: "",
            displayValue: (row) => row.label,
            cell: ({ row: { original } }) => {
                if (!!original.styledLabel) return <Typography variant="h6">{original.label}</Typography>;
                return original.label;
            },
        },
        {
            id: "date",
            header: t("date"),
            displayValue: (row) => row.D,
        },
        {
            id: "MTD",
            header: t("MTD"),
            displayValue: (row) => row.MTD,
        },
        {
            id: "YTD",
            header: t("YTD"),
            displayValue: (row) => row.YTD,
        },
    ];
    useEffect(() => {
        loadExtraData();
    }, []);
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomtypes",
                    responseData: "_id name taxData{taxId isIncluded tax{rate taxCategory}}",
                },
                {
                    endpoint: "rooms",
                    responseData: "_id name",
                },
                {
                    endpoint: "roomrates",
                    responseData: "_id name",
                },
                {
                    endpoint: "pospoints",
                    responseData: "_id name",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name",
                },
            ],
        });
        if (!!response?.roomtypes)
            setExtraData({
                roomtypes: response.roomtypes,
                rooms: response.rooms,
                rates: response.roomrates,
                pospoints: response.pospoints,
                guestCategoires: response.guestcategories,
            });
    };
    const generateOccupancyReport = (reportData = {}) => {
        const { guests, rooms } = reportData?.D || {};
        const monthlyGuests = reportData?.MTD?.guests;
        const yearlyGuests = reportData?.YTD?.guests;
        const monthlyRooms = reportData?.MTD?.rooms;
        const yearlyRooms = reportData?.YTD?.rooms;

        const totalRooms = extraData?.rooms?.length || 0.0;

        const dailyOutOfOrderRooms = reportData?.D?.blockedRooms?.OUT_OF_ORDER || 0;
        const monthlyOutOfOrderRooms = reportData?.MTD?.blockedRooms?.OUT_OF_ORDER || 0;
        const yearlyOutOfOrderRooms = reportData?.YTD?.blockedRooms?.OUT_OF_ORDER || 0;

        const dailyOnHoldRooms = reportData?.D?.blockedRooms?.ON_HOLD || 0;
        const monthlyOnHoldRooms = reportData?.MTD?.blockedRooms?.ON_HOLD || 0;
        const yearlyOnHoldRooms = reportData?.YTD?.blockedRooms?.ON_HOLD || 0;

        const soldUnits = rooms?.totalByRoomtype?.reduce((acc, roomtype) => {
            return acc + (roomtype?.total || 0.0);
        }, 0.0);
        const monthlySoldUnits = monthlyRooms?.totalByRoomtype?.reduce((acc, roomtype) => {
            return acc + (roomtype?.total || 0.0);
        }, 0.0);
        const yearlySoldUnits = yearlyRooms?.totalByRoomtype?.reduce((acc, roomtype) => {
            return acc + (roomtype?.total || 0.0);
        }, 0.0);
        return [
            {
                label: t("total_guests"),
                D: guests?.totalByCategory?.reduce((acc, category) => {
                    return acc + (category?.total || 0.0);
                }, 0.0),
                MTD: monthlyGuests?.totalByCategory?.reduce((acc, category) => {
                    return acc + (category?.total || 0.0);
                }, 0.0),
                YTD: yearlyGuests?.totalByCategory?.reduce((acc, category) => {
                    return acc + (category?.total || 0.0);
                }, 0.0),
            },
            ...extraData?.guestCategoires?.map((guest) => {
                const dailyGuestsData = guests?.totalByCategory?.find((g) => g.category === guest.name);
                const monthlyGuestsData = monthlyGuests?.totalByCategory?.find((g) => g.category === guest.name);
                const yearlyGuestsData = yearlyGuests?.totalByCategory?.find((g) => g.category === guest.name);
                return {
                    label: t(guest?.name),
                    D: dailyGuestsData?.total || "0",
                    MTD: monthlyGuestsData?.total || "0",
                    YTD: yearlyGuestsData?.total || "0",
                };
            }),

            {
                label: t("total_hotel_rooms"),
                D: totalRooms,
                MTD: totalRooms * totalMonthDays,
                YTD: totalRooms * totalYearDays,
            },
            {
                label: t("OOO"),
                D: dailyOutOfOrderRooms,
                YTD: yearlyOutOfOrderRooms,
                MTD: monthlyOutOfOrderRooms,
            },
            {
                label: t("sold_units"),
                D: soldUnits,
                MTD: monthlySoldUnits,
                YTD: yearlySoldUnits,
            },
            {
                label: t("unsold_units"),
                D: totalRooms - soldUnits - dailyOutOfOrderRooms,
                MTD: totalRooms * totalMonthDays - monthlySoldUnits - monthlyOutOfOrderRooms,
                YTD: totalRooms * totalYearDays - yearlySoldUnits - yearlyOutOfOrderRooms,
            },
            {
                label: t("occupancy"),
                D: ((soldUnits / (totalRooms - dailyOutOfOrderRooms)) * 100).toFixed(2) + "%",
                MTD:
                    ((monthlySoldUnits / (totalRooms * totalMonthDays - monthlyOutOfOrderRooms)) * 100).toFixed(2) +
                    "%",
                YTD: ((yearlySoldUnits / (totalRooms * totalYearDays - yearlyOutOfOrderRooms)) * 100).toFixed(2) + "%",
            },
            {
                label: t("on_hold_rooms"),
                D: dailyOnHoldRooms,
                MTD: monthlyOnHoldRooms,
                YTD: yearlyOnHoldRooms,
            },
            {
                label: t("%_of_available_rooms"),
                D:
                    (
                        ((totalRooms - dailyOutOfOrderRooms - soldUnits) / (totalRooms - dailyOutOfOrderRooms)) *
                        100
                    ).toFixed(2) + "%",
                MTD:
                    (
                        ((totalRooms * totalMonthDays - monthlyOutOfOrderRooms - monthlySoldUnits) /
                            (totalRooms * totalMonthDays - monthlyOutOfOrderRooms)) *
                        100
                    ).toFixed(2) + "%",
                YTD:
                    (
                        ((totalRooms * totalYearDays - yearlyOutOfOrderRooms - yearlySoldUnits) /
                            (totalRooms * totalYearDays - yearlyOutOfOrderRooms)) *
                        100
                    ).toFixed(2) + "%",
            },
        ];
    };
    const generateReservationFlowReport = (reportData = {}) => {
        const { guests, dailyUseReservations, dailyCreatedReservations, rooms, revenue } = reportData?.D || {};
        const departuresNotPaid = revenue?.departuresNotPaid;
        const monthlyDeparturesNotPaid = reportData?.MTD?.revenue?.departuresNotPaid;
        const yearlyDeparturesNotPaid = reportData?.YTD?.revenue?.departuresNotPaid;
        const dailyRoomsReservationsCreated = dailyCreatedReservations?.totalRoomsByRoomtype;
        const monthlyRoomsReservationsCreated = reportData?.MTD?.dailyCreatedReservations?.totalRoomsByRoomtype;
        const yearlyRoomsReservationsCreated = reportData?.YTD?.dailyCreatedReservations?.totalRoomsByRoomtype;
        const dailyUseGuests = dailyUseReservations?.totalGuestsByCategory;
        const dailyUseRooms = dailyUseReservations?.totalRoomsByRoomtype;
        const monthlyUseGuests = reportData?.MTD?.dailyUseReservations?.totalGuestsByCategory;
        const monthlyUseRooms = reportData?.MTD?.dailyUseReservations?.totalRoomsByRoomtype;
        const yearlyUseGuests = reportData?.YTD?.dailyUseReservations?.totalGuestsByCategory;
        const yearlyUseRooms = reportData?.YTD?.dailyUseReservations?.totalRoomsByRoomtype;
        const monthlyGuests = reportData?.MTD?.guests;
        const yearlyGuests = reportData?.YTD?.guests;
        const totalRooms = extraData?.rooms?.length || 0.0;
        const monthlyRooms = reportData?.MTD?.rooms;
        const yearlyRooms = reportData?.YTD?.rooms;
        const soldUnits =
            rooms?.totalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const monthlySoldUnits = monthlyRooms?.totalByRoomtype?.reduce((acc, roomtype) => {
            return acc + (roomtype?.total || 0.0);
        }, 0.0);
        const yearlySoldUnits = yearlyRooms?.totalByRoomtype?.reduce((acc, roomtype) => {
            return acc + (roomtype?.total || 0.0);
        }, 0.0);
        const cancelledRooms =
            rooms?.cancelledTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const cancelledMonthlyRooms =
            monthlyRooms?.cancelledTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const cancelledYearlyRooms =
            yearlyRooms?.cancelledTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const noShowRooms =
            rooms?.noShowTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const noShowMonthlyRooms =
            monthlyRooms?.noShowTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const noShowYearlyRooms =
            yearlyRooms?.noShowTotalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;

        return [
            {
                label: t("arrival_rooms"),
                D: rooms?.checkinTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0),
                MTD: monthlyRooms?.checkinTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0),
                YTD: yearlyRooms?.checkinTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0),
            },
            {
                label: t("departure_rooms"),
                D: rooms?.checkoutTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0),
                MTD: monthlyRooms?.checkoutTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0),
                YTD: yearlyRooms?.checkoutTotalByRoomtype?.reduce((acc, roomtype) => {
                    return acc + (roomtype?.total || 0.0);
                }, 0.0),
            },
            {
                label: t("no_shows"),
                D: noShowRooms,
                MTD: noShowMonthlyRooms,
                YTD: noShowYearlyRooms,
            },
            {
                label: t("cancellations"),
                D: cancelledRooms,
                MTD: cancelledMonthlyRooms,
                YTD: cancelledYearlyRooms,
            },
            {
                label: t("cancellation_rate"),
                D: ((cancelledRooms / totalRooms) * 100).toFixed(2) + "%",
                MTD: ((cancelledMonthlyRooms / totalRooms) * 100).toFixed(2) + "%",
                YTD: ((cancelledYearlyRooms / totalRooms) * 100).toFixed(2) + "%",
            },
            {
                label: t("stayover_rooms"),
                D: soldUnits,
                MTD: monthlySoldUnits,
                YTD: yearlySoldUnits,
            },
            {
                label: t("day_use_rooms"),
                D:
                    dailyUseRooms?.reduce((acc, roomtype) => {
                        return acc + (roomtype?.total || 0.0);
                    }, 0.0) || 0.0,
                MTD:
                    monthlyUseRooms?.reduce((acc, roomtype) => {
                        return acc + (roomtype?.total || 0.0);
                    }, 0.0) || 0.0,
                YTD:
                    yearlyUseRooms?.reduce((acc, roomtype) => {
                        return acc + (roomtype?.total || 0.0);
                    }, 0.0) || 0.0,
            },
            {
                label: t("day_use_guest_categories"),
                D:
                    dailyUseGuests?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
                MTD:
                    monthlyUseGuests?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
                YTD:
                    yearlyUseGuests?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
            },
            {
                label: t("arrivals_guest_categories"),
                D:
                    guests?.arrivalTotalByCategory?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
                MTD:
                    monthlyGuests?.arrivalTotalByCategory?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
                YTD:
                    yearlyGuests?.arrivalTotalByCategory?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
            },
            {
                label: t("departures_guest_categories"),
                D:
                    guests?.departureTotalByCategory?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
                MTD:
                    monthlyGuests?.departureTotalByCategory?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
                YTD:
                    yearlyGuests?.departureTotalByCategory?.reduce((acc, category) => {
                        return acc + (category?.total || 0.0);
                    }, 0.0) || 0.0,
            },
            {
                label: t("guest_tag"),
                D: "0",
                MTD: "0",
                YTD: "0",
            },
            {
                label: t("reservations_created"),
                D:
                    dailyRoomsReservationsCreated?.reduce((acc, roomtype) => {
                        return acc + (roomtype?.total || 0.0);
                    }, 0.0) || 0.0,
                MTD:
                    monthlyRoomsReservationsCreated?.reduce((acc, roomtype) => {
                        return acc + (roomtype?.total || 0.0);
                    }, 0.0) || 0.0,
                YTD:
                    yearlyRoomsReservationsCreated?.reduce((acc, roomtype) => {
                        return acc + (roomtype?.total || 0.0);
                    }, 0.0) || 0.0,
            },
            {
                label: t("cancellations_for_today"),
                D: "0",
                MTD: "0",
                YTD: "0",
            },
            {
                label: t("cancellations_made_today"),
                D: "0",
                MTD: "0",
                YTD: "0",
            },
            {
                label: t("departures_not_paid"),
                D: departuresNotPaid,
                MTD: monthlyDeparturesNotPaid,
                YTD: yearlyDeparturesNotPaid,
            },
        ];
    };
    const generateRoomInformationReport = (reportData = {}) => {
        const { guests, rooms, revenue } = reportData?.D;
        const monthlyRooms = reportData?.MTD?.rooms;
        const yearlyRooms = reportData?.YTD?.rooms;
        const { roomtypes } = extraData;

        return [
            ...roomtypes?.map((rt) => {
                const total = rooms?.totalByRoomtype?.find((roomtype) => roomtype.roomtype === rt._id)?.total || 0;
                const monthlyTotal =
                    monthlyRooms?.totalByRoomtype?.find((roomtype) => roomtype.roomtype === rt._id)?.total || 0;
                const yearlytotal =
                    yearlyRooms?.totalByRoomtype?.find((roomtype) => roomtype.roomtype === rt._id)?.total || 0;
                return { label: rt.name, D: total, MTD: monthlyTotal, YTD: yearlytotal };
            }),
        ];
    };
    const generateRevenuesReport = (reportData = {}) => {
        const { guests, rooms, revenue, dailyCreatedOrders, payments } = reportData?.D;

        const monthlyGuests = reportData?.MTD?.guests;
        const yearlyGuests = reportData?.YTD?.guests;
        const monthlyRooms = reportData?.MTD?.rooms;
        const yearlyRooms = reportData?.YTD?.rooms;
        const monthlyRevenue = reportData?.MTD?.revenue;
        const yearlyRevenue = reportData?.YTD?.revenue;
        const monthlyCreatedOrders = reportData?.MTD?.dailyCreatedOrders;
        const yearlyCreatedOrders = reportData?.YTD?.dailyCreatedOrders;
        const monthlyPayments = reportData?.MTD?.payments;
        const yearlyPayments = reportData?.YTD?.payments;

        const totalRooms = extraData?.rooms?.length || 0.0;

        const grossAmountByCurrencies = {};
        revenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
            roomtype?.total?.forEach((amount) => {
                if (!grossAmountByCurrencies[amount.currency]) {
                    grossAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                }
                grossAmountByCurrencies[amount.currency].amount += amount.amount;
            });
        });
        const monthlyGrossAmountByCurrencies = {};
        monthlyRevenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
            roomtype?.total?.forEach((amount) => {
                if (!monthlyGrossAmountByCurrencies[amount.currency]) {
                    monthlyGrossAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                }
                monthlyGrossAmountByCurrencies[amount.currency].amount += amount.amount;
            });
        });
        const yearlyGrossAmountByCurrencies = {};
        yearlyRevenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
            roomtype?.total?.forEach((amount) => {
                if (!yearlyGrossAmountByCurrencies[amount.currency]) {
                    yearlyGrossAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                }
                yearlyGrossAmountByCurrencies[amount.currency].amount += amount.amount;
            });
        });

        const netAmountByCurrencies = {};
        revenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
            const { taxData = [] } = extraData?.roomtypes?.find((rt) => rt._id === roomtype.roomtype) || {};

            roomtype?.total?.forEach((amount) => {
                if (!netAmountByCurrencies[amount.currency]) {
                    netAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                }
                const { totalAmount } = calculateTotalTax({
                    taxData: taxData || [],
                    price: parseFloat(amount.amount || 0.0),
                });
                const netAmount = (amount.amount || 0.0) - (totalAmount || 0.0);
                netAmountByCurrencies[amount.currency].amount += netAmount;
            });
        });
        const monthlyNetAmountByCurrencies = {};
        monthlyRevenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
            const { taxData = [] } = extraData?.roomtypes?.find((rt) => rt._id === roomtype.roomtype) || {};

            roomtype?.total?.forEach((amount) => {
                if (!monthlyNetAmountByCurrencies[amount.currency]) {
                    monthlyNetAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                }
                const { totalAmount } = calculateTotalTax({
                    taxData: taxData,
                    price: parseFloat(amount.amount || 0.0),
                });
                const netAmount = (amount.amount || 0.0) - (totalAmount || 0.0);
                monthlyNetAmountByCurrencies[amount.currency].amount += netAmount;
            });
        });
        const yearlyNetAmountByCurrencies = {};
        yearlyRevenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
            const { taxData = [] } = extraData?.roomtypes?.find((rt) => rt._id === roomtype.roomtype) || {};

            roomtype?.total?.forEach((amount) => {
                if (!yearlyNetAmountByCurrencies[amount.currency]) {
                    yearlyNetAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                }
                const { totalAmount } = calculateTotalTax({
                    taxData: taxData,
                    price: parseFloat(amount.amount || 0.0),
                });
                const netAmount = (amount.amount || 0.0) - (totalAmount || 0.0);
                yearlyNetAmountByCurrencies[amount.currency].amount += netAmount;
            });
        });

        const posGrossRevenue = {};
        dailyCreatedOrders?.totalRevenueByPOS?.forEach((pos) => {
            pos?.amountInfo?.gross?.forEach((gross) => {
                if (!posGrossRevenue[gross.currency]) {
                    posGrossRevenue[gross.currency] = { currency: gross.currency, amount: 0.0 };
                }
                posGrossRevenue[gross.currency].amount += gross.amount;
            });
        });
        const monthlyPosGrossRevenue = {};
        monthlyCreatedOrders?.totalRevenueByPOS?.forEach((pos) => {
            pos?.amountInfo?.gross?.forEach((gross) => {
                if (!monthlyPosGrossRevenue[gross.currency]) {
                    monthlyPosGrossRevenue[gross.currency] = { currency: gross.currency, amount: 0.0 };
                }
                monthlyPosGrossRevenue[gross.currency].amount += gross.amount;
            });
        });
        const yearlyPosGrossRevenue = {};
        yearlyCreatedOrders?.totalRevenueByPOS?.forEach((pos) => {
            pos?.amountInfo?.gross?.forEach((gross) => {
                if (!yearlyPosGrossRevenue[gross.currency]) {
                    yearlyPosGrossRevenue[gross.currency] = { currency: gross.currency, amount: 0.0 };
                }
                yearlyPosGrossRevenue[gross.currency].amount += gross.amount;
            });
        });

        const totalGuests =
            guests?.totalByCategory?.reduce((acc, category) => {
                return acc + (category?.total || 0.0);
            }, 0.0) || 0.0;
        const monthlyTotalGuests =
            monthlyGuests?.totalByCategory?.reduce((acc, category) => {
                return acc + (category?.total || 0.0);
            }, 0.0) || 0.0;
        const yearlyTotalGuests =
            yearlyGuests?.totalByCategory?.reduce((acc, category) => {
                return acc + (category?.total || 0.0);
            }, 0.0) || 0.0;

        const soldUnits =
            rooms?.totalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const monthlySoldUnits =
            monthlyRooms?.totalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;
        const yearlySoldUnits =
            yearlyRooms?.totalByRoomtype?.reduce((acc, roomtype) => {
                return acc + (roomtype?.total || 0.0);
            }, 0.0) || 0.0;

        const revenuePerGuest = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: currencyAmount.amount / totalGuests,
            };
        });
        const monthlyRevenuePerGuest = Object.values(monthlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: monthlyTotalGuests === 0 ? 0 : currencyAmount.amount / monthlyTotalGuests,
            };
        });
        const yearlyRevenuePerGuest = Object.values(yearlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: yearlyTotalGuests === 0 ? 0 : currencyAmount.amount / yearlyTotalGuests,
            };
        });
        const revenuePerOCP = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: currencyAmount.amount / soldUnits,
            };
        });
        const monthlyRevenuePerOCP = Object.values(monthlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: currencyAmount.amount / monthlySoldUnits,
            };
        });
        const yearlyRevenuePerOCP = Object.values(yearlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: currencyAmount.amount / yearlySoldUnits,
            };
        });
        const revenuePerUnits = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: currencyAmount.amount / totalRooms,
            };
        });
        const monthlyRevenuePerUnits = Object.values(monthlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: currencyAmount.amount / totalRooms,
            };
        });
        const yearlyRevenuePerUnits = Object.values(yearlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            return {
                currency: currencyAmount.currency,
                amount: currencyAmount.amount / totalRooms,
            };
        });

        const rates = [{ _id: "standard", name: "standard" }, ...extraData?.rates];
        const sources = [];
        revenue?.dailyTotalBySource?.forEach((source) => {
            if (sources?.includes(source.source)) return;
            sources?.push(source.source);
        });
        monthlyRevenue?.dailyTotalBySource?.forEach((source) => {
            if (sources?.includes(source.source)) return;
            sources?.push(source.source);
        });
        yearlyRevenue?.dailyTotalBySource?.forEach((source) => {
            if (sources?.includes(source.source)) return;
            sources?.push(source.source);
        });

        const dailyTotal = {};
        Object.values(posGrossRevenue)?.forEach((currencyAmount) => {
            if (!dailyTotal[currencyAmount?.currency]) {
                dailyTotal[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0 };
            }
            dailyTotal[currencyAmount.currency].amount += currencyAmount.amount;
        });
        revenue?.dailyTotal?.forEach((currencyAmount) => {
            if (!dailyTotal[currencyAmount?.currency]) {
                dailyTotal[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0 };
            }
            dailyTotal[currencyAmount.currency].amount += currencyAmount.amount;
        });

        const monthlyDailyTotal = {};
        Object.values(monthlyPosGrossRevenue)?.forEach((currencyAmount) => {
            if (!monthlyDailyTotal[currencyAmount?.currency]) {
                monthlyDailyTotal[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0 };
            }
            monthlyDailyTotal[currencyAmount.currency].amount += currencyAmount.amount;
        });
        reportData?.MTD?.revenue?.dailyTotal?.forEach((currencyAmount) => {
            if (!monthlyDailyTotal[currencyAmount?.currency]) {
                monthlyDailyTotal[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0 };
            }
            monthlyDailyTotal[currencyAmount.currency].amount += currencyAmount.amount;
        });
        const yearlyDailyTotal = {};
        Object.values(yearlyPosGrossRevenue)?.forEach((currencyAmount) => {
            if (!yearlyDailyTotal[currencyAmount?.currency]) {
                yearlyDailyTotal[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0 };
            }
            yearlyDailyTotal[currencyAmount.currency].amount += currencyAmount.amount;
        });
        reportData?.YTD?.revenue?.dailyTotal?.forEach((currencyAmount) => {
            if (!yearlyDailyTotal[currencyAmount?.currency]) {
                yearlyDailyTotal[currencyAmount.currency] = { currency: currencyAmount.currency, amount: 0 };
            }
            yearlyDailyTotal[currencyAmount.currency].amount += currencyAmount.amount;
        });

        const netADR = Object.values(netAmountByCurrencies)?.map((currencyAmount) => {
            const netADR = currencyAmount.amount / soldUnits;
            return { ...currencyAmount, amount: isNaN(netADR) ? 0 : netADR };
        });
        const grossADR = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
            const grossADR = currencyAmount.amount / soldUnits;
            return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
        });
        const monthlyNetADR = Object.values(monthlyNetAmountByCurrencies)?.map((currencyAmount) => {
            const netADR = currencyAmount.amount / monthlySoldUnits;
            return { ...currencyAmount, amount: isNaN(netADR) ? 0 : netADR };
        });
        const monthlyGrossADR = Object.values(monthlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            const grossADR = currencyAmount.amount / monthlySoldUnits;
            return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
        });
        const yearlyNetADR = Object.values(yearlyNetAmountByCurrencies)?.map((currencyAmount) => {
            const netADR = currencyAmount.amount / yearlySoldUnits;
            return { ...currencyAmount, amount: isNaN(netADR) ? 0 : netADR };
        });
        const yearlyGrossADR = Object.values(yearlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            const grossADR = currencyAmount.amount / yearlySoldUnits;
            return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
        });

        const grossREVPAR = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
            const totalUnits = totalRooms;
            const grossADR = currencyAmount.amount / totalUnits;
            return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
        });

        const grossMonthlyREVPAR = Object.values(monthlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            const totalUnits = totalRooms * totalMonthDays;
            const grossADR = currencyAmount.amount / totalUnits;
            return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
        });
        const grossYearlyREVPAR = Object.values(yearlyGrossAmountByCurrencies)?.map((currencyAmount) => {
            const totalUnits = totalRooms * totalYearDays;
            const grossADR = currencyAmount.amount / totalUnits;
            return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
        });

        return [
            {
                label: t("revenue_gross_hotel"),
                D: currenciesAsString(Object.values(grossAmountByCurrencies)),
                MTD: currenciesAsString(Object.values(monthlyGrossAmountByCurrencies)),
                YTD: currenciesAsString(Object.values(yearlyGrossAmountByCurrencies)),
            },
            {
                label: t("revenue_net_hotel"),
                D: currenciesAsString(Object.values(netAmountByCurrencies)),
                MTD: currenciesAsString(Object.values(monthlyNetAmountByCurrencies)),
                YTD: currenciesAsString(Object.values(yearlyNetAmountByCurrencies)),
            },
            {
                label: t("revenue_gross_total"),
                D: currenciesAsString(Object.values(dailyTotal)),
                MTD: currenciesAsString(Object.values(monthlyDailyTotal)),
                YTD: currenciesAsString(Object.values(yearlyDailyTotal)),
            },
            {
                label: t("non_hotel_gross_revenue"),
                D: currenciesAsString(Object.values(posGrossRevenue)),
                MTD: currenciesAsString(Object.values(monthlyPosGrossRevenue)),
                YTD: currenciesAsString(Object.values(yearlyPosGrossRevenue)),
            },
            {
                label: t("revenue_per_guest"),
                D: currenciesAsString(revenuePerGuest),
                MTD: currenciesAsString(monthlyRevenuePerGuest),
                YTD: currenciesAsString(yearlyRevenuePerGuest),
            },
            {
                label: t("revenue_per_occupied_unit"),
                D: currenciesAsString(revenuePerOCP),
                MTD: currenciesAsString(monthlyRevenuePerOCP),
                YTD: currenciesAsString(yearlyRevenuePerOCP),
            },
            {
                label: t("revenue_per_total_units"),
                D: currenciesAsString(revenuePerUnits),
                MTD: currenciesAsString(monthlyRevenuePerUnits),
                YTD: currenciesAsString(yearlyRevenuePerUnits),
            },
            {
                label: t("payments_collected_total"),
                D: currenciesAsString(payments?.allPayments),
                MTD: currenciesAsString(monthlyPayments?.allPayments),
                YTD: currenciesAsString(yearlyPayments?.allPayments),
            },
            {
                label: t("hotel_payments_collected"),
                D: currenciesAsString(payments?.hotelPayments),
                MTD: currenciesAsString(monthlyPayments?.hotelPayments),
                YTD: currenciesAsString(yearlyPayments?.hotelPayments),
            },
            ...extraData?.pospoints
                ?.map((pos) => {
                    const dailyPosRevenues = dailyCreatedOrders?.totalRevenueByPOS?.find(
                        (p) => p.pospointId === pos._id
                    );
                    const monthlyPosRevenues = monthlyCreatedOrders?.totalRevenueByPOS?.find(
                        (p) => p.pospointId === pos._id
                    );
                    const yearlyPosRevenues = yearlyCreatedOrders?.totalRevenueByPOS?.find(
                        (p) => p.pospointId === pos._id
                    );
                    return [
                        {
                            label: pos.name + " " + t("gross_revenue"),
                            D: currenciesAsString(dailyPosRevenues?.amountInfo?.gross),
                            MTD: currenciesAsString(monthlyPosRevenues?.amountInfo?.gross),
                            YTD: currenciesAsString(yearlyPosRevenues?.amountInfo?.gross),
                        },
                        {
                            label: pos.name + " " + t("net_revenue"),
                            D: currenciesAsString(dailyPosRevenues?.amountInfo?.net),
                            MTD: currenciesAsString(monthlyPosRevenues?.amountInfo?.net),
                            YTD: currenciesAsString(yearlyPosRevenues?.amountInfo?.net),
                        },
                    ];
                })
                ?.flat(),
            ...rates?.map((rate) => {
                const dailyRateRevenues = revenue?.dailyTotalByRate?.find((r) => r.rate === rate._id);
                const monthlyRateRevenues = monthlyRevenue?.dailyTotalByRate?.find((r) => r.rate === rate._id);
                const yearlyRateRevenues = yearlyRevenue?.dailyTotalByRate?.find((r) => r.rate === rate._id);
                return {
                    label: t(rate?.name) + " " + t("revenue"),
                    D: currenciesAsString(dailyRateRevenues?.total),
                    MTD: currenciesAsString(monthlyRateRevenues?.total),
                    YTD: currenciesAsString(yearlyRateRevenues?.total),
                };
            }),
            ...sources?.map((source) => {
                const dailySourceRevenues = revenue?.dailyTotalBySource?.find((s) => s.source === source);
                const monthlySourceRevenues = monthlyRevenue?.dailyTotalBySource?.find((s) => s.source === source);
                const yearlySourceRevenues = yearlyRevenue?.dailyTotalBySource?.find((s) => s.source === source);
                return {
                    label: source + " " + t("revenue"),
                    D: currenciesAsString(dailySourceRevenues?.total),
                    MTD: currenciesAsString(monthlySourceRevenues?.total),
                    YTD: currenciesAsString(yearlySourceRevenues?.total),
                };
            }),
            {
                label: t("Net_ADR"),
                D: currenciesAsString(netADR),
                MTD: currenciesAsString(monthlyNetADR),
                YTD: currenciesAsString(yearlyNetADR),
            },
            {
                label: t("Gross_ADR"),
                D: currenciesAsString(grossADR),
                MTD: currenciesAsString(monthlyGrossADR),
                YTD: currenciesAsString(yearlyGrossADR),
            },
            {
                label: t("Rev_PAR"),
                D: currenciesAsString(grossREVPAR),
                MTD: currenciesAsString(grossMonthlyREVPAR),
                YTD: currenciesAsString(grossYearlyREVPAR),
            },
        ];
    };
    const loadData = async () => {
        if (!params.get("date")) return;
        const response = await fetch({
            operation: "query",
            endpoint: "generalManagerReport",
            responseData: `D${REPORT_RESPONSE_DATA} MTD${REPORT_RESPONSE_DATA} YTD${REPORT_RESPONSE_DATA}`,
            data: { date: params.get("date") },
        });

        const data = [
            {
                label: t("occupancy"),
                styledLabel: true,
                D: "",
                YTD: "",
                MTD: "",
            },
            ...generateOccupancyReport(response?.generalManagerReport),
            {
                label: t("reservation_flow"),
                styledLabel: true,
                D: "",
                YTD: "",
                MTD: "",
            },
            ...generateReservationFlowReport(response?.generalManagerReport),
            {
                label: t("room_information"),
                styledLabel: true,
                D: "",
                YTD: "",
                MTD: "",
            },
            ...generateRoomInformationReport(response?.generalManagerReport),
            {
                label: t("revenues"),
                styledLabel: true,
                D: "",
                YTD: "",
                MTD: "",
            },
            ...generateRevenuesReport(response?.generalManagerReport),
        ];

        setReport({
            report: new ReportGenerator({ data, displayTotal: false, columns: reportColumns }).generateTable({
                title: t("general_manager_report"),
            }),
        });
    };

    return (
        <div>
            <ReportHeader
                title={t("general_manager_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateControl
                        value={params.get("date")}
                        onChange={(e) => setParams({ date: e.target.value })}
                        label={t("date")}
                        fullWidth={false}
                    />
                </Stack>
            </ReportHeader>
            {report?.report}
        </div>
    );
};

export default GeneralManagerReport;
