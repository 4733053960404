import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const RoleList = () => {
    const [roles, setRoles] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "homeRoute", header: t("route") },
        { accessorKey: "description", header: t("description") },
    ]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "roles",
            responseData: "_id name homeRoute description",
        });
        if (response?.roles) setRoles(response.roles);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteRoles" : "deleteRole",
            data,
        });
        if (response?.deleteRoles === "success" || response?.deleteRole === "success") {
            setRoles(roles.filter((role) => !rows.some((row) => row._id === role._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const addRecordRole = (data) => {
        if (roles?.some((r) => r._id === data._id)) {
            setRoles(
                roles?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setRoles([...roles, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("roles_form")}
                moduleType="accounting"
                data={roles}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("roles_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordRole }} />
        </div>
    );
};

export default RoleList;
