import React from "react";

const DraggableContainer = ({ children, hasMoveEnabled }) => {
    return (
        <div style={{ position: "relative" }} className="draggable-parent">
            <div
                style={{
                    minHeight: hasMoveEnabled ? "700px" : "auto",
                    width: hasMoveEnabled ? "1500px" : "auto",
                    padding: "10px",
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default DraggableContainer;
