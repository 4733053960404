import { Box, Button, Chip, Divider, Grid, Paper, Stack, Typography } from "@mui/material";

import React, { useEffect, useMemo, useState } from "react";
import { AutoCompleteControl, Form, InputControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import triggerDetails from "./utils/triggerDetails";

const rules = {
    trigger: "string|required",
    subject: "string|required",
    body: "string|required",
};

const SettingsEmailForm = () => {
    const [values, setValues] = useState({});
    const [settingsProperty, setSettingsProperty] = useState({});
    const [activeEmailProperties, setActiveEmailProperties] = useState([]);

    const { loading, fetch } = useApi();
    const params = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const triggerOptions = [
        { value: "newReservationConfirm", label: t("confirmation_on_new_reservation") },
        { value: "reservationCancellation", label: t("reservation_cancellation") },
    ];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "settingsemail",
                    data: {
                        _id: `EmailConfig_${params.id}`,
                    },
                    responseData: "_id trigger subject body",
                },
                {
                    endpoint: "settingsproperty",
                    data: {
                        _id: `settings_property`,
                    },
                    responseData: "_id logo",
                },
                {
                    endpoint: "activeEmailProperties",
                },
            ],
        });
        if (response?.settingsemail?._id)
            setValues({
                ...response.settingsemail,
            });
        if (response?.settingsproperty?._id) setSettingsProperty(response.settingsproperty);
        if (response?.activeEmailProperties) {
            setActiveEmailProperties(response?.activeEmailProperties);
        }
    };

    const triggers = useMemo(() => {
        const filteredTriggers = triggerOptions?.filter((option) => !activeEmailProperties?.includes(option?.value));
        return filteredTriggers;
    }, [activeEmailProperties]);

    const saveHandler = async () => {
        const { _id, trigger, subject, body } = values;
        const data = {
            trigger,
            subject,
            body,
        };

        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateSettingsEmail" : "createSettingsEmail",
            data,
            responseData: "_id",
        });
        if (response?.createSettingsEmail?._id || response?.updateSettingsEmail?._id)
            enqueueSnackbar(t("settings_email_saved"), { variant: "default" });
    };

    const triggerVariablesInfo = useMemo(() => {
        const details = triggerDetails[values?.trigger];
        if (!details) return [];
        return details;
    }, [values?.trigger]);

    const propertyLogo = useMemo(() => {
        let { logo } = settingsProperty || {};
        if (!logo) logo = "https://res.cloudinary.com/strowberry-code/image/upload/v1640015211/hb/new-logo_jkj6ii.png";
        return logo;
    }, [settingsProperty?.logo]);

    return (
        <Form
            values={values}
            onValuesChange={setValues}
            rules={rules}
            onSubmit={() => {
                saveHandler();
                navigate(-1);
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Paper sx={{ padding: "20px" }} variant="outlined">
                        <Typography variant="h6" textAlign="center">
                            {t("email_configuration")}
                        </Typography>
                        <AutoCompleteControl
                            sx={{ marginTop: "15px" }}
                            name="trigger"
                            label={t("trigger")}
                            options={triggers}
                        />
                        <InputControl name="subject" label={t("subject")} />
                        <InputControl name="body" label={t("body")} multiline minRows={4} />
                        <Stack direction="column" marginTop={2}>
                            {params.id !== "create" && (
                                <Typography variant="subtitle2">{t("use_variables_to_customize")}</Typography>
                            )}
                            {triggerVariablesInfo?.map((variable) => {
                                return (
                                    <Typography variant="body2" sx={{ marginTop: "5px" }}>
                                        {variable.value} - {t(variable.label)}: {variable.ex}
                                    </Typography>
                                );
                            })}
                        </Stack>
                        <Grid item xs={12} display="flex" justifyContent="end">
                            <Button
                                onClick={() => navigate(-1)}
                                color="primary"
                                variant="outlined"
                                style={{ marginRight: "10px" }}
                            >
                                {t("back")}
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                {t("save")}
                            </Button>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} alignItems="center">
                    <Paper sx={{ padding: "20px" }} variant="outlined">
                        <Typography textAlign="center" variant="h6">
                            {t("preview")}
                        </Typography>
                        <Typography sx={{ marginTop: 2 }}>
                            <span style={{ textTransform: "capitalize", fontWeight: 600 }}>{t("subject")}</span>:{" "}
                            {values?.subject}
                        </Typography>
                        <Stack
                            justifyContent="start"
                            alignItems="center"
                            marginTop={2}
                            padding={4}
                            gap={4}
                            sx={{ backgroundColor: "#e3e4e6", borderRadius: 1 }}
                        >
                            <img width="25%" src={propertyLogo} alt="logo" />
                            <Box
                                width={"100%"}
                                padding={2}
                                minHeight="200px"
                                component="section"
                                bgcolor="white"
                                borderRadius={1}
                            >
                                <Typography sx={{ overflowWrap: "break-word", whiteSpace: "pre-line" }}>
                                    {values?.body}
                                </Typography>
                            </Box>
                            <Typography>Powered by HotelBee.</Typography>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Form>
    );
};

export default SettingsEmailForm;
