import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../../components/table";

const RoomsTable = ({ rooms = [] }) => {
    const { t } = useTranslation();
    const columns = [
        {
            accessorKey: "room",
            header: t("room"),
            accessorFn: (row) => {
                const { name } = row?.roomData || {};
                if (!!name) return name;
                return "";
            },
        },
        {
            accessorKey: "roomtype",
            header: t("roomtype"),
            accessorFn: (row) => {
                const { name } = row?.roomtypeData || {};
                if (!!name) return name;
                return "";
            },
        },
    ];
    return <Table data={rooms} columns={columns} disableHeader disableSelection disableDelete disableFooter />;
};

export default RoomsTable;
