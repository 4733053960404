import { Button, ButtonGroup, Divider, Grid, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ActionBox, ActionsPanel, AmountsCard } from "../../components/common";
import { DateRangeControl } from "../../components/dates";
import { AutoCompleteControl, CheckboxControl, Form, InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import { useApi, useProducts } from "../../components/hooks";
import ItemsTable from "./components/ItemsTable";
import { calculateTotalTax } from "../../utils";
import { useSnackbar } from "notistack";
import PaymentsView from "../../components/common/PaymentsView";
import PaymentsModal from "../../components/common/PaymentsModal";
import { exportToPdf } from "./components/inventoryPdf";

const languageOptions = [
    { value: "en", label: "english" },
    { value: "sq", label: "albanian" },
    { value: "it", label: "italian" },
    { value: "de", label: "german" },
    { value: "no", label: "norwegian" },
];

const InventoryForm = () => {
    const [values, setValues] = useState({
        type: "purchase_order",
    });
    const [openPaymentViewModal, setOpenPaymentViewModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        suppliersOptions: [],
        currenciesOptions: [],
        warehousesOptions: [],
        paymentMethods: [],
        taxes: [],
    });
    const [disableSave, setDisableSave] = useState(false);
    const { products } = useProducts();
    const productOptions = useMemo(() => {
        return products
            ?.filter((p) => {
                if (p.productType === "composed") return false;
                if (values?.type === "purchase_order") return true;
                if (values?.type === "inventory_transfer") {
                    return p?.warehouseIds?.includes(values?.warehouseFrom);
                }
                if (values?.type === "inventory_deduction") {
                    return p?.warehouseIds?.includes(values?.warehouseFrom);
                }
                return true;
            })
            .map((p) => {
                return {
                    _id: p?._id,
                    name: p?.name,
                    price: p?.cost,
                    taxData: p?.taxData,
                    unit: p?.measureUnit || p?.purchaseUnit || "",
                };
            });
    }, [products, values?.type, values?.warehouseFrom, values?.warehouseTo]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { addRecord } = useOutletContext() || {};

    const typeOptions = [
        {
            value: "purchase_order",
            label: t("purchase_order"),
        },
        {
            value: "inventory_transfer",
            label: t("inventory_transfer"),
        },
        {
            value: "inventory_deduction",
            label: t("inventory_deduction"),
        },
    ];

    useEffect(() => {
        loadData();
    }, []);

    const disabledActions = useMemo(() => {
        const actions = {
            disableDraft: false,
            disableOrdered: false,
            disableDelivered: false,
            disableCancelled: false,
            disableAddPayment: false,
            disableAll: false,
            disableWarehouses: false,
        };
        if (!values?._id) {
            actions.disableCancelled = true;
        }
        if (values?.status === "ORDERED") {
            actions.disableAll = true;
            actions.disableDraft = true;
            actions.disableOrdered = true;
        } else if (values?.status === "CANCELLED") {
            actions.disableAddPayment = true;
            actions.disableAll = true;
            actions.disableCancelled = true;
            actions.disableDelivered = true;
            actions.disableDraft = true;
            actions.disableOrdered = true;
            actions.disableWarehouses = true;
        } else if (values?.status === "DELIVERED") {
            actions.disableDelivered = true;
            actions.disableCancelled = true;
            actions.disableDraft = true;
            actions.disableOrdered = true;
            actions.disableAll = true;
            actions.disableWarehouses = true;
            if (!values._id) actions.disableAll = false;
        } else if (values?.status === "DRAFT") {
            actions.disableDraft = true;
        }

        return actions;
    }, [values?.status, values?._id]);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "suppliers",
                responseData: "_id name address city",
            },
            {
                endpoint: "paymentmethods",
                responseData: "_id name method fiscalMethod isActive",
            },
            {
                endpoint: "currencies",
                responseData: "_id currency currencyname",
            },
            {
                endpoint: "warehouses",
                responseData: "_id name",
            },
            {
                endpoint: "taxes",
                responseData: "_id name rate taxCategory",
            },
        ];
        if (params?.id !== "create") {
            endpoints.push({
                endpoint: "inventory",
                data: {
                    _id: "inventories_" + params?.id,
                },
                responseData: `_id date billId dueDate status supplier supplierData{name address city country email phone state IDNumber} totalPrice discountValue discountType  serial description
                    payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp note} currency pdfLanguage warehouseTo warehouseFrom
                    tableData{itemId price taxData{taxId isIncluded} description quantity unit} type number`,
            });
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        if (response) {
            setData({
                suppliersOptions: response?.suppliers?.map((s) => ({ value: s._id, label: s.name, data: s })) || [],
                currenciesOptions:
                    response?.currencies?.map((c) => ({ value: c.currency, label: c.currencyname })) || [],
                warehousesOptions: response?.warehouses?.map((w) => ({ value: w._id, label: w.name })) || [],
                taxes: response?.taxes || [],
                paymentMethods: response.paymentmethods
                    ?.filter((p) => p.isActive)
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                        };
                    }),
            });
            if (response?.inventory) {
                setValues({ ...values, ...response?.inventory });
            }
        }
    };

    const supplierData = useMemo(() => {
        if (!values?.supplier) return {};
        if (!data?.suppliersOptions?.length > 0) return {};
        return data?.suppliersOptions?.find((s) => s.value === values?.supplier)?.data || {};
    }, [values?.supplier, data?.suppliersOptions]);

    const calculateTotalDiscount = (totalPrice = 0) => {
        if (!values.discountType || !values.discountValue) return 0;
        const discountAmount =
            values.discountType === "total"
                ? parseFloat(values.discountValue)
                : totalPrice * parseFloat(values.discountValue) * 0.01;
        if (isNaN(discountAmount)) return 0;
        return discountAmount;
    };

    const amountInfo = () => {
        let totalPrice = 0.0;
        let subtotal = 0.0;
        let discount = 0.0;
        let taxTotal = 0.0;

        values?.tableData?.forEach((item) => {
            const { totalAmount, includedAmount } = calculateTotalTax({
                taxData: item.taxData?.map((t) => ({
                    isIncluded: t.isIncluded,
                    tax: data?.taxes?.find((tax) => tax._id === t.taxId) || {},
                })),
                price: parseFloat(item.price),
            });
            taxTotal += totalAmount * item.quantity;
            totalPrice += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
        });
        subtotal = totalPrice - taxTotal;
        discount = calculateTotalDiscount(totalPrice);

        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
            subtotal: isNaN(subtotal) ? 0.0 : subtotal,
            taxTotal: isNaN(taxTotal) ? 0.0 : taxTotal,
            discount: isNaN(discount) ? 0.0 : discount,
        };
    };

    const printPdf = async (printOptions = {}) => {
        exportToPdf({
            values,
            t,
            fetch,
            options: printOptions,
            itemsOptions: productOptions,
        });
    };

    const updateStatus = async (newStatus) => {
        if (!newStatus) return;
        if (!values?._id) {
            setValues({ ...values, status: newStatus });
            return;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateInventoryStatus",
            data: {
                _id: values?._id,
                status: newStatus,
            },
            responseData: "_id status",
        });
        if (response?.updateInventoryStatus?.status) {
            enqueueSnackbar(t("status_updated"), { variant: "default" });
            setValues({ ...values, status: response.updateInventoryStatus.status });
        }
    };

    const saveHandler = async () => {
        setDisableSave(true);
        const {
            date,
            dueDate,
            currency,
            type,
            supplier,
            serial,
            pdfLanguage,
            description,
            tableData,
            discountValue,
            discountType,
            warehouseTo,
            warehouseFrom,
            createBill,
            status,
            _id,
        } = values;

        tableData?.forEach((item) => {
            const quantity = parseFloat(item.quantity);
            const price = parseFloat(item.price);
            item.quantity = isNaN(quantity) ? 0.0 : parseFloat(quantity);
            item.price = isNaN(price) ? 0.0 : parseFloat(price);

            item.taxData = item?.taxData?.map(({ taxId, isIncluded }) => ({ taxId, isIncluded }));
        });
        const data = {
            date,
            dueDate,
            currency,
            type,
            supplier,
            serial,
            pdfLanguage,
            description,
            tableData,
            discountValue: discountValue ? parseFloat(discountValue) : null,
            discountType,
            warehouseTo,
            warehouseFrom,
            totalPrice: amountInfo()?.totalPrice - amountInfo()?.discount,
        };

        if (_id) {
            data._id = _id;
        } else {
            data.status = !!status ? status : "DELIVERED";
        }

        if (createBill && type === "purchase_order") {
            values?.payments?.forEach((payment) => {
                payment.amount = parseFloat(payment.amount);
            });
            const billData = {
                invoiceCurrency: currency,
                supplier,
                serial,
                pdfLanguage,
                description,
                date,
                dueDate,
                discountValue: discountValue ? parseFloat(discountValue) : null,
                discountType,
                tableData,
                totalPrice: amountInfo()?.totalPrice - amountInfo()?.discount,
                payments: values?.payments,
            };

            const billResponse = await fetch({
                operation: "mutation",
                endpoint: "createBill",
                data: billData,
                responseData: "_id",
            });
            if (billResponse?.createBill?._id) {
                data.billId = billResponse?.createBill?._id;
                enqueueSnackbar(t("bill_created"), { variant: "default" });
            }
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateInventory" : "createInventory",
            data,
            responseData: "_id date dueDate status type number totalPrice currency supplier supplierData{name}",
        });
        if (response?.createInventory?._id || response?.updateInventory?._id) {
            enqueueSnackbar(t("saved"), { variant: "default" });
            addRecord && addRecord(response.createInventory || response?.updateInventory);
        }
        updateProducts();
    };

    const updateProducts = async () => {
        const { tableData, warehouseTo, warehouseFrom } = values || {};
        if (warehouseTo) {
            const updateProductsWarehouses = tableData?.reduce((acc, item) => {
                const { warehouseIds = [] } = products.find((p) => p._id === item.itemId);
                if (warehouseIds?.includes(warehouseTo)) return acc;
                acc.push({
                    _id: item.itemId,
                    warehouseIds: [...(warehouseIds || []), warehouseTo],
                });
                return acc;
            }, []);
            if (updateProductsWarehouses?.length > 0) {
                await fetch({
                    operation: "mutation",
                    endpoint: "updateProductsBulk",
                    data: { products: updateProductsWarehouses },
                });
            }
        }
        if (warehouseFrom) {
            const updateProductsWarehouses = tableData?.reduce((acc, item) => {
                const { warehouseIds = [] } = products.find((p) => p._id === item.itemId);
                if (warehouseIds?.includes(warehouseFrom)) return acc;
                acc.push({
                    _id: item.itemId,
                    warehouseIds: [...(warehouseIds || []), warehouseFrom],
                });
                return acc;
            }, []);
            if (updateProductsWarehouses?.length > 0) {
                await fetch({
                    operation: "mutation",
                    endpoint: "updateProductsBulk",
                    data: { products: updateProductsWarehouses },
                });
            }
        }
    };
    const disablePrice = useMemo(() => {
        if (["inventory_transfer", "inventory_deduction"].includes(values.type)) return true;
        return false;
    }, [values.type]);

    const addPayment = async (payment) => {
        if (params.id === "create") {
            setValues({
                ...values,
                payments: [...(values?.payments || []), payment],
            });
            setOpenModal(false);
        } else {
            const response = await fetch({
                operation: "mutation",
                endpoint: "addBillPayment",
                data: {
                    _id: values?.billId,
                    amount: parseFloat(payment.amount),
                    paymentMethod: payment.paymentMethod,
                    timestamp: new Date(payment.timestamp).toJSON(),
                    note: payment.note,
                },
                responseData:
                    "amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note",
            });
            if (response?.addBillPayment?.amount) {
                enqueueSnackbar(t("payment_added"), { variant: "default" });
                setValues({
                    ...values,
                    payments: [...(values?.payments || []), response?.addBillPayment],
                });
                setOpenModal(false);
            } else {
                enqueueSnackbar(t("add_payment_failed"), { variant: "error" });
                setOpenModal(false);
            }
        }
    };

    return (
        <>
            <Modal
                fullScreen
                open
                titlelabel={t("inventory_entry")}
                onClose={() => navigate(-1)}
                loading={loading}
                yesDisabled={disableSave}
                FormProps={{
                    values,
                    onValuesChange: setValues,
                    onSubmit: () => {
                        saveHandler();
                    },
                }}
                permission={{ inventories: params?._id === "create" ? { create: true } : { edit: true } }}
            >
                <ActionsPanel>
                    <ActionBox width={200} title={t("actions")}>
                        <SelectControl
                            disabled={disabledActions.disableAll}
                            value={values?.type}
                            label={t("type")}
                            options={typeOptions}
                            onChange={(e) => {
                                const type = e.target.value;
                                setValues({
                                    ...values,
                                    type,
                                    warehouseFrom: type === "purchase_order" ? null : values.warehouseFrom,
                                    warehouseTo: type === "inventory_deduction" ? null : values.warehouseTo,
                                });
                            }}
                        />
                        {values?.type === "purchase_order" ? (
                            <CheckboxControl name="createBill" label={t("create_bill")} />
                        ) : null}
                    </ActionBox>
                    <ActionBox title={t("status")}>
                        <ButtonGroup fullWidth disableElevation>
                            <Button disabled={disabledActions.disableDraft} onClick={() => updateStatus("DRAFT")}>
                                {t("draft")}
                            </Button>
                            <Button disabled={disabledActions.disableOrdered} onClick={() => updateStatus("ORDERED")}>
                                {t("ordered")}
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup sx={{ marginTop: 1 }} fullWidth disableElevation>
                            <Button
                                disabled={disabledActions.disableDelivered}
                                onClick={() => updateStatus("DELIVERED")}
                            >
                                {t("delivered")}
                            </Button>
                            <CancelStatusModal
                                onConfirm={() => updateStatus("CANCELLED")}
                                disabled={disabledActions.disableCancelled}
                            />
                        </ButtonGroup>
                    </ActionBox>
                    <ActionBox title={t("payment")}>
                        {(values?.createBill || values?.billId) && values?.type === "purchase_order" && (
                            <Button
                                variant="outlined"
                                disabled={disabledActions.disableAddPayment}
                                startIcon={<AddIcon />}
                                onClick={() => setOpenModal(true)}
                            >
                                {t("add_payment")}
                            </Button>
                        )}
                        <Button startIcon={<AttachMoneyIcon />} onClick={() => setOpenPaymentViewModal(true)}>
                            {t("view_payment")}
                        </Button>
                    </ActionBox>
                    <ActionBox title={t("print")}>
                        <Button
                            variant="outlined"
                            sx={{ marginBottom: 1 }}
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => printPdf({ type: "save" })}
                        >
                            {t("download")}
                        </Button>
                        <Button startIcon={<PrintIcon />} onClick={() => printPdf({ type: "autoprint" })}>
                            {t("print")}
                        </Button>
                    </ActionBox>
                    <ActionBox title={t("details")} width={300}>
                        <Typography variant="h6" sx={{ textAlign: "right", marginBottom: 1 }}>
                            #{values?.number || "AUTO"}
                        </Typography>
                        <DateRangeControl
                            startLabel={t("date")}
                            endLabel={t("due_date")}
                            values={{ startDate: values.date || null, endDate: values.dueDate || null }}
                            onChange={({ startDate, endDate }) =>
                                setValues({ ...values, date: startDate, dueDate: endDate })
                            }
                        />
                        <SelectControl
                            disabled={disabledActions.disableAll}
                            name={"currency"}
                            label={t("currency")}
                            options={data?.currenciesOptions}
                        />
                    </ActionBox>
                </ActionsPanel>

                <div style={{ margin: "20px 0" }}>
                    <Typography variant="h6">{t("details")}</Typography>
                    <Divider />
                </div>
                <Form values={values} onValuesChange={setValues}>
                    <Grid container spacing={2} alignItems="start">
                        <Grid container item xs={12} sm={5} md={4} spacing={2}>
                            {values?.type === "purchase_order" ? (
                                <>
                                    <Grid item xs={12}>
                                        <AutoCompleteControl
                                            disabled={disabledActions.disableAll}
                                            name="supplier"
                                            label={t("supplier")}
                                            options={data?.suppliersOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    {supplierData?.vat}
                                                </Typography>
                                                <Typography variant="h6" component="div">
                                                    {supplierData?.name}
                                                </Typography>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    {supplierData?.country}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {(supplierData?.address || "") + " " + (supplierData?.city || "")}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={1} md={4}></Grid>
                        <Grid container item xs={12} sm={6} md={4} spacing={2}>
                            <Grid item xs={6}>
                                <InputControl name="serial" label={t("serial")} disabled={disabledActions.disableAll} />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl
                                    name="pdfLanguage"
                                    label={t("pdf_language")}
                                    options={languageOptions}
                                    disabled={disabledActions.disableAll}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControl
                                    multiline
                                    minRows={3}
                                    name="description"
                                    label={t("description")}
                                    disabled={disabledActions.disableAll}
                                />
                            </Grid>
                            {["purchase_order", "inventory_transfer"].includes(values?.type) && (
                                <Grid item xs={6}>
                                    <SelectControl
                                        disabled={disabledActions.disableAll}
                                        name="warehouseTo"
                                        label={t("warehouse_to")}
                                        options={data?.warehousesOptions}
                                    />
                                </Grid>
                            )}
                            {["inventory_deduction", "inventory_transfer"].includes(values?.type) && (
                                <Grid item xs={6}>
                                    <SelectControl
                                        disabled={disabledActions.disableAll}
                                        name="warehouseFrom"
                                        label={t("warehouse_from")}
                                        options={data?.warehousesOptions}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Form>
                <div style={{ margin: "20px 0" }}>
                    <Typography variant="h6">{t("items")}</Typography>
                    <Divider />
                </div>
                <ItemsTable
                    data={values?.tableData || []}
                    options={productOptions || []}
                    taxesOptions={data?.taxes}
                    disabled={disabledActions.disableAll}
                    disablePrice={disablePrice}
                    onChange={(tableData) => {
                        setValues({ ...values, tableData });
                    }}
                    currency={values?.currency}
                />
                <Grid mt={5} container justifyContent="flex-end">
                    <Grid item xs={12} md={6}>
                        <AmountsCard
                            total={amountInfo()?.totalPrice - amountInfo()?.discount}
                            tax={amountInfo()?.taxTotal}
                            subtotal={amountInfo()?.subtotal}
                            discount={amountInfo()?.discount}
                            discountInputValue={values?.discountValue}
                            disabled={disabledActions.disableAll}
                            discountType={values?.discountType}
                            onChange={(newData) => {
                                if (disabledActions.disableAll) return;
                                setValues({ ...values, ...newData });
                            }}
                            currency={values?.currency || ""}
                        />
                    </Grid>
                </Grid>
                <PaymentsView
                    open={openPaymentViewModal}
                    data={values?.payments || []}
                    onClose={() => setOpenPaymentViewModal(false)}
                    currency={values?.currency || ""}
                />
            </Modal>
            <PaymentsModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                addPayment={(data) => addPayment(data)}
                t={t}
                currency={values?.currency || ""}
            />
        </>
    );
};

const CancelStatusModal = ({ onConfirm, disabled }) => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Button variant="outlined" disabled={disabled} onClick={() => setOpenModal(true)}>
                {t("cancel")}
            </Button>
            <Modal
                maxWidth="xs"
                open={openModal}
                onClose={() => setOpenModal(false)}
                onSave={() => {
                    onConfirm();
                    setOpenModal(false);
                }}
                yesText={t("confirm")}
            >
                {t("are_you_sure_you_want_to_cancel?")}
            </Modal>
        </>
    );
};

export default InventoryForm;
