import { Table } from "../table";

/**
 * Column Definitions Rules when initianing a new ReportGenerator object:
 * --Format ==> {
 *  id: (column unique id, required)
 *  header: (column header to display, required)
 *  displayValue: ()=>{} (function which has an argument row to provide the value for every row column)
 *  value: ()=>{} (same as displayValue function but here the function return value is not displayed, use with total for example)
 * }
 *
 */

class ReportGenerator {
    constructor({ data = [], columns = [], displayTotal = false, landscape = false }) {
        this.data = JSON.parse(JSON.stringify(data));
        this.columns = columns;
        if (displayTotal && this.data?.length > 0) this.data?.push({ isTotal: true });
    }

    /**
     * Return column defs for table component (tanstack table) based
     * on columns details provided
     */
    #generateTableColumns = () => {
        return this.columns?.map((column) => {
            const columnDetails = {
                id: column.id,
                header: column.header,
                accessorFn: (row) => (row.isTotal ? column?.totalValue(this.data) : column?.displayValue(row)),
            };
            if (!!column.cell) {
                columnDetails.cell = column.cell;
            }
            if (!!column.columns)
                columnDetails.columns = column.columns?.map((col) => {
                    return {
                        id: col.id,
                        header: col.header,
                        accessorFn: (row) => (row.isTotal ? col?.totalValue(this.data) : col?.displayValue(row)),
                    };
                });
            return columnDetails;
        });
    };

    generateTable = ({ title, tableRef, landscape = false, headerInfo = [] }) => {
        return (
            <Table
                titleLabel={title}
                moduleType="raport"
                data={this.data || []}
                columns={this.#generateTableColumns() || []}
                printAll
                disableSelection
                disableFooter
                disableDelete
                disableColumnsSave
                tableRef={tableRef}
                landscape={landscape}
                headerInfo={headerInfo}
            />
        );
    };
}
export default ReportGenerator;
