import { Button, Card, CardHeader, Step, StepContent, StepIcon, Stepper } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { useTranslation } from "react-i18next";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { DateTime } from "luxon";

const ClientTimeline = ({ actions = [] }) => {
    const { t } = useTranslation();
    return (
        <Timeline position="alternate">
            {actions?.reverse().map((action) => (
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {DateTime.fromISO(action.timestamp)?.toFormat("dd LLL yyyy HH:mm")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <TimelineCard t={t} title={action.title} user={action.userId?.split("users_")?.[1]} />
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default ClientTimeline;

const TimelineCard = ({ title, t, timestamp, user }) => {
    return (
        <Card>
            <CardHeader
                title={t(title)}
                titleTypographyProps={{ variant: "h6" }}
                subheader={` ${t("by")} ${user}`}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                //action={<Button>{t("view")}</Button>}
            />
        </Card>
    );
};
