import { Badge, Button, Card, CardContent, CardHeader, Tooltip } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import React from "react";
import { useTranslation } from "react-i18next";
import { Backdrop, CircularProgress } from "@mui/material";

const ReportHeader = ({ title, onReportRun, children, loading, disableRun, disableRunInfo = "" }) => {
    const { t } = useTranslation();
    return (
        <>
            <Card sx={{ marginBottom: 2 }}>
                <CardHeader
                    title={title}
                    titleTypographyProps={{ variant: "h6" }}
                    action={
                        <Tooltip open={disableRun} title={disableRunInfo}>
                            <Button
                                disabled={disableRun}
                                startIcon={<CachedIcon />}
                                variant="contained"
                                onClick={onReportRun}
                            >
                                {t("run_report")}
                            </Button>
                        </Tooltip>
                    }
                />
                <CardContent>{children}</CardContent>
            </Card>
            <Backdrop sx={{ zIndex: 10000 }} open={loading}>
                <CircularProgress size={60} sx={{ color: "white" }} />
            </Backdrop>
        </>
    );
};

export default ReportHeader;
