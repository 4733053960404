import { Button, LinearProgress, Paper, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { DateRangeControl } from "../../components/dates";
import { DateTime } from "luxon";

const TransactionList = () => {
    const [transactions, setTransactions] = useState([]);
    const [filters, setFilters] = useState({
        startDate: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().toFormat("yyyy-LL-dd"),
    });
    const { t } = useTranslation();
    const [columns] = useState([
        { id: "type", header: t("type"), accessorFn: (row) => t(`${row?.type}`) },
        { accessorKey: "amount", header: t("amount") },
        { accessorKey: "currency", header: t("currency") },
        { id: "username", header: t("created_by"), accessorFn: (row) => row.username?.split("users_")[1] },
        {
            accessorKey: "dateCreated",
            header: t("date"),
        },
        {
            accessorKey: "timeCreated",
            header: t("time"),
        },
        { accessorKey: "description", header: t("description") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const { startDate, endDate } = filters || {};
        if (!startDate) return;
        let end = "end";
        if (!!endDate) end = endDate + `T23:59:59`;
        const response = await fetch({
            operation: "query",
            endpoint: "transactions",
            data: {
                startDate: startDate,
                endDate: end,
            },
            responseData:
                "_id type dateCreated timeCreated paymentType paymentAccount currency amount username description",
        });
        if (response?.transactions) setTransactions(response.transactions);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteTransactions" : "deleteTransaction",
            data,
        });
        if (response?.deleteTransactions === "success" || response?.deleteTransaction === "success") {
            setTransactions(transactions.filter((transaction) => !rows.some((row) => row._id === transaction._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const addRecordTransaction = (data) => {
        if (transactions?.some((r) => r._id === data._id)) {
            setTransactions(
                transactions?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setTransactions([...transactions, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                <Stack direction="row" gap={2} flexWrap="wrap">
                    <DateRangeControl
                        startLabel={t("start")}
                        endLabel={t("end")}
                        values={{ startDate: filters.startDate, endDate: filters.endDate }}
                        onChange={({ startDate, endDate }) => setFilters({ ...filters, startDate, endDate })}
                    />
                    <Button onClick={loadData} size="small" variant="contained">
                        {t("load_transactions")}
                    </Button>
                </Stack>
            </Paper>
            <Table
                titleLabel={t("transactions")}
                moduleType="finance"
                data={transactions}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("transactions_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordTransaction }} />
        </div>
    );
};

export default TransactionList;
