import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import SideBar from "./SideBar";
import { getPermission, menuItems } from "../../../utils";
import MenuLink from "./MenuLink";
import NavBar from "./NavBar";
import SubMenuLink from "./SubMenuLink";
import FooterBottom from "./FooterBottom";
import "./index.css";
import { Outlet, useLocation } from "react-router-dom";

const Menu = () => {
    const [openSidebar, setOpenSidebar] = useState(false);

    let location = useLocation();
    const { pathname } = location || {};

    const propertyName = localStorage.getItem("propertyName");

    const isMenuActive = (menu) => {
        if (menu.name === "frontdesk") {
            if (
                pathname.startsWith("/backoffice") ||
                pathname.startsWith("/pos") ||
                pathname.startsWith("/finance") ||
                pathname.startsWith("/reports") ||
                pathname.startsWith("/settings") ||
                pathname.startsWith("/accounts") ||
                pathname.startsWith("/help")
            )
                return false;

            return true;
        }
        return pathname.startsWith(menu.mainHref);
    };
    return (
        <>
            <NavBar open={openSidebar} toggleOpen={() => setOpenSidebar(!openSidebar)}>
                {menuItems
                    .find(isMenuActive)
                    .subItems?.filter((item) => {
                        const requiredPermissions = {};
                        if (!item.restrict) return true;
                        requiredPermissions[item.restrict.model] = { [item.restrict.right]: true };
                        const { hasRight } = getPermission(requiredPermissions);
                        if (hasRight) return true;
                        return false;
                    })
                    ?.map((subItem) => {
                        return <SubMenuLink key={subItem.name} item={subItem} />;
                    })}
            </NavBar>
            <SideBar open={openSidebar} toggleOpen={() => setOpenSidebar(!openSidebar)} hotelName={propertyName || ""}>
                {menuItems
                    ?.filter((item) => {
                        const requiredPermissions = {};
                        if (!item.restrict) return true;
                        requiredPermissions[item.restrict.model] = { [item.restrict.right]: true };
                        const { hasRight } = getPermission(requiredPermissions);
                        if (hasRight) return true;
                        return false;
                    })
                    ?.map((item) => {
                        if (item.type === "divider") return <Divider />;
                        return (
                            <MenuLink
                                key={item.name}
                                active={isMenuActive(item)}
                                open={openSidebar}
                                item={item}
                                onClose={() => setOpenSidebar(false)}
                            />
                        );
                    })}
            </SideBar>
            <Box
                component="main"
                sx={{
                    //transition: "all .3s ease-out",
                    paddingTop: "20px",
                    paddingX: "20px",
                    boxSizing: "border-box",
                    marginTop: "60px",
                    marginLeft: openSidebar ? "240px" : "60px",
                    "@media screen and (max-width: 900px)": {
                        marginLeft: openSidebar ? "240px" : "0px",
                        opacity: openSidebar ? "20%" : "100%",
                    },
                    minHeight: "calc(100vh - 100px)",
                }}
            >
                <Outlet />
            </Box>

            <FooterBottom
                style={{
                    marginLeft: openSidebar ? "260px" : "80px",
                    "@media screen and (max-width: 900px)": {
                        marginLeft: openSidebar ? "260px" : "0px",
                        opacity: openSidebar ? "20%" : "100%",
                    },
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            />
        </>
    );
};

export default Menu;
