import jsPDF from "jspdf";
import { toCurrency } from "../../../utils";
import systemLanguages from "../../../assests/systemLanguages";

export const printConfirmationCard = async ({ reservationId, fetch, t, language }) => {
    const translate = (text) => t(text, { lngs: [systemLanguages?.includes(language) ? language : "en"] });

    const response = await fetch({
        operation: "query",
        multipleEndpoints: [
            {
                endpoint: "reservation",
                data: {
                    _id: "reservations_" + reservationId?.split("reservations_")[1],
                },
                responseData: `
                        _id checkin checkout status clients totalPrice uuid bookingSource groupId
                        guests{roomId guests{name number price extra}} 
                        notes{note timestamp userName} 
                        discount{type value}
                        rooms{roomId roomtypeId prices{price rateId rateName rateMeals date} roomData{name} roomtypeData{name _id meals taxData{taxId isIncluded}}}
                        clientsData{_id firstName lastName phone email addressLine state city birthdate nationality placeOfBirth IDType IDNumber}
                        refererData{firstName} 
                        invoices{date invoiceCurrency _id clientsData{firstName lastName} totalPrice status payments{amount paymentMethod userName timestamp}}
                    `,
            },
            {
                endpoint: "settingsproperty",
                responseData:
                    "_id logo address name website email confirmationMessage{language text} detailedAccomodation bookingTerms{language text}",
                data: { _id: "settings_property" },
            },
            {
                endpoint: "settingsgeneral",
                responseData: "_id currencyData{currency}",
                data: { _id: "settings_general" },
            },
        ],
    });

    const doc = new jsPDF("p", "mm", "a4");
    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    const { checkin, checkout, invoices, uuid } = response?.reservation || {};
    const { firstName, lastName } = response?.reservation?.clientsData?.[0] || {};
    const { name, confirmationMessage, detailedAccomodation, bookingTerms, website } = response?.settingsproperty || {};

    let selectedLanguageConfirmationMessage = confirmationMessage?.find(
        (message) => message?.language === language
    )?.text;

    if (!selectedLanguageConfirmationMessage) {
        selectedLanguageConfirmationMessage =
            confirmationMessage?.find((message) => message?.language === "en")?.text || "";
    }
    let selectedLanguageBookingTerms = bookingTerms?.find((message) => message?.language === language)?.text;
    if (!selectedLanguageBookingTerms) {
        selectedLanguageBookingTerms = bookingTerms?.find((message) => message?.language === "en")?.text || "";
    }

    let { logo } = response?.settingsproperty || {};
    const { currency } = response?.settingsgeneral?.currencyData || {};
    const propertyEmail = response?.settingsproperty?.email || "";
    const { rooms } = response?.reservation || {};

    let total = invoices.reduce((acc, invoice) => acc + parseFloat(invoice.totalPrice), 0.0);
    const roomNames = rooms?.map((room) => room?.roomData?.name)?.join(",");
    const rateNames = rooms?.map((room) => room?.prices?.[0].rateName || "standart")?.join(",");
    const roomtypeNames = rooms?.map((room) => room?.roomtypeData?.name)?.join(",");

    doc.setFontSize(12);
    doc.text(firstName + " " + lastName, 15, 50);

    if (!!selectedLanguageConfirmationMessage) {
        doc.autoTable({
            theme: "plain",
            styles: { fontSize: 11, cellPadding: { top: 1 } },
            margin: { left: 15, top: 10, right: 15 },
            startY: 60,
            body: [[selectedLanguageConfirmationMessage]],
        });
    }

    let head = [];
    if (detailedAccomodation) {
        head = [translate("name"), translate("arrival"), translate("departure"), translate("room_name")];
    } else {
        head = [
            translate("name"),
            translate("arrival"),
            translate("departure"),
            translate("roomtype"),
            translate("rate"),
        ];
    }

    doc.autoTable({
        head: [head],
        body: [
            [
                firstName + " " + lastName,
                checkin,
                checkout,
                detailedAccomodation ? roomNames : roomtypeNames,
                detailedAccomodation ? "" : rateNames,
            ],
        ],
        startY: !!selectedLanguageConfirmationMessage ? doc.autoTable.previous.finalY + 10 : 60,
    });

    if (detailedAccomodation) {
        doc.autoTable({
            head: [[translate("total")]],
            body: [[toCurrency(total, currency)]],
        });
    }

    doc.setFont("Helvetica", "normal");
    if (!!selectedLanguageBookingTerms) {
        doc.autoTable({
            theme: "plain",
            styles: { fontSize: 11, cellPadding: { top: 1 } },
            margin: { left: 15, top: 10, right: 15 },
            startY: doc.autoTable.previous.finalY + 10,
            body: [[selectedLanguageBookingTerms]],
        });
    }

    // FOOTER

    var pdfFooter = translate("email") + ": " + (propertyEmail || "") + "/" + translate("web") + ": " + (website || "");
    let addressFtr = `${pdfFooter}`;
    doc.setTextColor(100);
    doc.setFontSize(10);
    doc.text(addressFtr, pageWidth / 2, pageHeight - 10, "center");

    doc.setPage(1);
    if (!logo) {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(0, 0, 0);
        doc.text(name, 7, 15);
        printPdf({
            pdf: doc,
            type: "save",
            title: `reservation-confirmation_${uuid}`,
        });
    } else {
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const imageWidth = img.width;
            const imageHeight = img.height;

            doc.addImage(img, "JPEG", 7, 5, (30 * imageWidth) / imageHeight, 30);

            printPdf({
                pdf: doc,
                type: "save",
                title: `reservation-confirmation_${uuid}`,
            });
        };
    }
};
const printPdf = ({ pdf, type, title }) => {
    if (type === "autoprint") {
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
    } else {
        pdf.save(title + ".pdf");
    }
};

export default printConfirmationCard;
