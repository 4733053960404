import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MenuLink = ({ item, open, active = false, onClose, t }) => {
    return (
        <ListItem button component={Link} to={item.href} style={{ whiteSpace: "nowrap" }}>
            <ListItemIcon
                style={{
                    color: `rgba(255, 255, 255, ${active ? 1 : 0.6})`,
                }}
            >
                {item.icon}
            </ListItemIcon>
            <ListItemText
                primary={t(`${item.name}`)}
                disableTypography
                style={{
                    color: `rgba(255, 255, 255, ${active ? 1 : 0.7})`,
                    transition: "all .3s",
                    opacity: open ? "100%" : "0",
                    fontWeight: active ? "bold" : "normal",
                }}
                className="white-font"
            />
        </ListItem>
    );
};

export default withTranslation("translations")(MenuLink);
