import { exchangeCurrency, Number } from "../../../../utils";

const calculateItemPrice = (
    itemPrice,
    {
        fiscalCurrency = "ALL",
        invoiceCurrency = "ALL",
        generateForEInvoice = false,
        isFiscalCurrencyRateSpecified,
        fiscalCurrencyRate,
        currencies = [],
    }
) => {
    let price;
    if (fiscalCurrency === "ALL") {
        price = exchangeCurrency({
            amount: itemPrice,
            fromCurrency: invoiceCurrency,
            toCurrency: "ALL",
            currencies,
        });
    } else if (Boolean(generateForEInvoice)) {
        price = new Number(itemPrice).toNumber();
    } else {
        if (isFiscalCurrencyRateSpecified) {
            price = new Number(itemPrice).multipliedBy(fiscalCurrencyRate).toNumber();
        } else {
            price = exchangeCurrency({
                amount: itemPrice,
                fromCurrency: invoiceCurrency,
                toCurrency: "ALL",
                currencies,
            });
        }
    }
    return price;
};

export default calculateItemPrice;
