import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme, darkTheme } from "./utils/theme";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { SnackbarProvider } from "notistack";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { getLoggedUserData, getSubdomain } from "./utils";
import i18n from "i18next";

const App = () => {
    const mode = localStorage.getItem("mode");
    const language = localStorage.getItem("language");
    const settingsLanguage = localStorage.getItem("settingsLanguage");
    const { displayName, email, _id } = getLoggedUserData() || {};

    const hotel = getSubdomain();
    if (window.tidioChatApi && !!_id) {
        window.tidioChatApi.setVisitorData({
            email: email,
            name: displayName,
            tags: [hotel],
        });
        window.tidioChatApi.setContactProperties({
            company: hotel,
            account: hotel,
        });
    }

    i18n.changeLanguage(language || settingsLanguage || "sq");

    return (
        <ThemeProvider theme={mode && mode === "dark" ? darkTheme : theme}>
            <CssBaseline />
            <IntlProvider locale={language}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <BrowserRouter>
                        <SnackbarProvider maxSnack={5}>
                            <AppRoutes />
                        </SnackbarProvider>
                    </BrowserRouter>
                </LocalizationProvider>
            </IntlProvider>
        </ThemeProvider>
    );
};

export default App;
