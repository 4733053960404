import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interaction from "@fullcalendar/interaction";
import { useApi } from "../../components/hooks";
import { NewReservationConfirm, DailyReservationEditConfirm } from "./components/calendar";
import { DateTime } from "luxon";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Stack, Grid, IconButton } from "@mui/material";
import { DateControl } from "../../components/dates";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { applyDiscount } from "../../utils";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    CHECKIN: "#59C086", //checked in
    CHECKOUT: "#FFC82C", //checked out
    CANCELLED: "#FF4949", //canceled
    ON_HOLD: "#C0CCDA", // on hold
};

const RESERVATION_RESPONSE_DATA = `_id checkin checkinTime checkout checkoutTime isDailyUse bookingSource status clients uuid
clientsData{_id firstName lastName} 
rooms{roomtypeId roomId customPrice prices{price rateId date discount{type value}}} 
guests{roomId guests{name number price extra}} 
invoices{_id}`;

const reservationsToEvents = (reservations = []) => {
    return (
        reservations
            ?.filter((reservation) => reservation.status && reservation.status !== "CANCELLED")
            ?.map((reservation) => {
                const { firstName, lastName } = reservation?.clientsData?.[0] || {};
                const clientName = !!firstName
                    ? (firstName || "") + " " + (lastName || "")
                    : `${reservation.checkinTime} - ${reservation.checkoutTime}`;
                return reservation?.rooms?.map((room, i) => ({
                    id: reservation._id + "R" + i,
                    title: clientName,
                    start: reservation.isDailyUse
                        ? reservation?.checkin + "T" + reservation.checkinTime
                        : reservation?.checkin,
                    end: reservation.isDailyUse
                        ? reservation?.checkout + "T" + reservation.checkoutTime
                        : reservation?.checkout,
                    resourceId: room.roomId,
                    backgroundColor: statusColors[reservation.status],
                    reservationData: reservation,
                    display: reservation.isDailyUse ? "list-item" : "background",
                    overlap: !(!reservation.isDailyUse && reservation.status === "CHECKIN"),
                }));
            })
            ?.flat() || []
    );
};

const DailyReservationsCalendar = () => {
    const [resources, setResources] = useState({
        rooms: [],
        guestcategories: [],
    });

    const [date, setDate] = useState(DateTime.now().toFormat("yyyy-LL-dd"));
    const [events, setEvents] = useState([]);

    const [newReservationData, setNewReservationData] = useState({
        open: false,
        start: null,
        end: null,
        roomData: null,
    });

    const [editedEvent, setEditedEvent] = useState(null);

    useEffect(() => {
        loadRoomData();
    }, []);

    useEffect(() => {
        loadReservations();
    }, [date]);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const roomsFetch = useApi();
    const loadRoomData = async () => {
        const response = await roomsFetch.fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomtypes",
                    responseData: "_id name roomtypeOrder",
                },
                {
                    endpoint: "rooms",
                    responseData: "_id name roomtypeId price maxCapacity includedCapacity capacity{id price}",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "calendarOrder",
                    data: { _id: "settings_general" },
                },
            ],
        });
        if (response?.rooms) {
            setResources({
                rooms: response?.rooms
                    ?.map((room) => {
                        const { name, roomtypeOrder } =
                            response?.roomtypes?.find((rt) => rt._id === room?.roomtypeId) || {};
                        return {
                            id: room._id,
                            roomtypeId: room.roomtypeId,
                            roomtypeName: name,
                            title: room.name,
                            roomData: room,
                            roomtypeOrder: roomtypeOrder,
                        };
                    })
                    ?.sort((a, b) => {
                        return a.title - b.title;
                    })
                    ?.sort((a, b) => {
                        switch (response?.settingsgeneral?.calendarOrder) {
                            case "alphabetical":
                                return a.roomtypeName?.toLowerCase().localeCompare(b.roomtypeName?.toLowerCase());
                            case "roomOrder":
                                return a.roomtypeOrder - b.roomtypeOrder;
                            default:
                                return -1;
                        }
                    }),
                guestcategories: response?.guestcategories,
            });
        }
    };

    const reservationsFetch = useApi();
    const loadReservationsFetch = useApi();
    const loadReservations = async () => {
        const response = await loadReservationsFetch.fetch({
            operation: "query",
            endpoint: "reservationsByDates",
            data: {
                startDate: DateTime.fromISO(date).minus({ days: 1 }).toFormat("yyyy-LL-dd"),
                endDate: DateTime.fromISO(date).plus({ days: 2 }).toFormat("yyyy-LL-dd"),
            },
            responseData: RESERVATION_RESPONSE_DATA,
        });
        if (response?.reservationsByDates) {
            setEvents(reservationsToEvents(response?.reservationsByDates));
        }
    };

    const isRoomMoveAllowed = ({ reservation, newRoom, oldRoom }) => {
        if (!reservation?.invoices?.length > 0) return true;
        if (newRoom.roomtypeId === oldRoom.roomtypeId) return true;
        enqueueSnackbar(`${t("closed_invoice")}\n${t("no_roomtype_change_allowed")}`, { variant: "warning" });
        return false;
    };

    const onRoomMove = async ({ keepPrice = false, updatedPrice }) => {
        const { _id, rooms, guests, discount } = editedEvent?.reservation || {};
        const reservationData = {
            _id,
            rooms,
            guests,
        };
        /**
         * Replace the old room guests with the new room
         */
        reservationData.guests?.forEach((roomGuests) => {
            if (roomGuests.roomId === editedEvent?.currentRoom?._id) {
                roomGuests.roomId = editedEvent?.newRoom?._id;
            }
        });

        /**
         * If user checks keep price in confirm modal only replace current roomId and roomtypeId to the new room values
         * Totalprice stays the same
         */
        if (keepPrice) {
            reservationData?.rooms?.forEach((room) => {
                if (room.roomId === editedEvent?.currentRoom?._id) {
                    room.roomId = editedEvent?.newRoom?._id;
                    room.roomtypeId = editedEvent?.newRoom?.roomtypeId;
                }
            });
            const response = await reservationsFetch.fetch({
                operation: "mutation",
                endpoint: "updateReservation",
                data: reservationData,
                responseData: RESERVATION_RESPONSE_DATA,
            });
            if (response?.updateReservation) {
                setEvents(
                    events
                        ?.filter((event) => event?.reservationData?._id !== response?.updateReservation?._id)
                        ?.concat(reservationsToEvents([response?.updateReservation]))
                );
                setEditedEvent(null);
            }
            return;
        }

        /**
         * If user checks update price in confirm modal replace current roomId and roomtypeId to the new room values
         * Replace room price details
         * Recalculate reservation totalPrice
         */
        reservationData?.rooms?.forEach((room) => {
            if (room.roomId === editedEvent?.currentRoom?._id) {
                room.roomId = editedEvent?.newRoom?._id;
                room.roomtypeId = editedEvent?.newRoom?.roomtypeId;
                room.prices?.forEach((day) => {
                    day.price = isNaN(updatedPrice) ? 0.0 : updatedPrice;
                    day.rateId = "standard";
                    day.discount = null;
                });
                room.customPrice = null;
            }
        });
        const guestsDailyExtraPrice =
            reservationData.guests?.reduce((acc, roomGuests) => {
                const roomGuestsDailyExtraPrice =
                    roomGuests?.guests?.reduce((roomAcc, category) => {
                        return roomAcc + category.price * category.extra;
                    }, 0.0) || 0.0;
                return acc + roomGuestsDailyExtraPrice;
            }, 0.0) || 0.0;

        const totalPrice =
            guestsDailyExtraPrice * reservationData.rooms?.[0]?.prices.length +
                reservationData.rooms?.reduce((acc, room) => {
                    const roomTotalPrice =
                        room.prices?.reduce((roomAcc, day) => {
                            const roomDailyPrice = day.price;
                            return roomAcc + roomDailyPrice;
                        }, 0.0) || 0.0;
                    return acc + roomTotalPrice;
                }, 0.0) || 0.0;

        reservationData.totalPrice = applyDiscount(totalPrice, discount);
        const response = await reservationsFetch.fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: reservationData,
            responseData: RESERVATION_RESPONSE_DATA,
        });
        if (response?.updateReservation) {
            setEvents(
                events
                    ?.filter((event) => event?.reservationData?._id !== response?.updateReservation?._id)
                    ?.concat(reservationsToEvents([response?.updateReservation]))
            );
            setEditedEvent(null);
        }
    };

    const onReservationResize = async ({ keepPrice = false, updatedPrice }) => {
        const { _id, rooms, guests, checkin, checkout, discount, checkinTime, checkoutTime } =
            editedEvent?.reservation || {};

        const reservationData = {
            _id,
            rooms,
            guests,
            checkin:
                editedEvent?.changes?.field === "start"
                    ? DateTime.fromISO(editedEvent?.changes?.newValue).toFormat("yyyy-LL-dd")
                    : checkin,
            checkinTime:
                editedEvent?.changes?.field === "start"
                    ? DateTime.fromISO(editedEvent?.changes?.newValue).toFormat("HH:mm")
                    : checkinTime,
            checkout:
                editedEvent?.changes?.field === "end"
                    ? DateTime.fromISO(editedEvent?.changes?.newValue).toFormat("yyyy-LL-dd")
                    : checkout,
            checkoutTime:
                editedEvent?.changes?.field === "end"
                    ? DateTime.fromISO(editedEvent?.changes?.newValue).toFormat("HH:mm")
                    : checkoutTime,
        };
        reservationData.rooms[0].prices[0].date = reservationData.checkin;
        if (!keepPrice) {
            reservationData.rooms[0].prices[0].price = isNaN(updatedPrice) ? 0.0 : updatedPrice;
        }
        const guestsDailyExtraPrice =
            reservationData.guests?.[0]?.guests?.reduce(
                (acc, category) => acc + category.price * category.extra,
                0.0
            ) || 0.0;
        const totalPrice = reservationData.rooms[0].prices?.reduce((acc, day) => {
            const roomDailyPrice = day.price;
            return acc + roomDailyPrice + guestsDailyExtraPrice;
        }, 0.0);
        reservationData.totalPrice = applyDiscount(totalPrice, discount);
        const response = await reservationsFetch.fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: reservationData,
            responseData: RESERVATION_RESPONSE_DATA,
        });
        if (response?.updateReservation) {
            setEvents(
                events
                    ?.filter((event) => event?.reservationData?._id !== response?.updateReservation?._id)
                    ?.concat(reservationsToEvents([response?.updateReservation]))
            );
            setEditedEvent(null);
        }
    };

    const calendarRef = useRef();

    const onReservationChange = (reservationData) => {
        setEvents(
            events
                ?.filter((event) => event?.reservationData?._id !== reservationData?._id)
                ?.concat(reservationsToEvents([reservationData]))
        );
    };

    return (
        <div className="daily-calendar">
            <Stack direction="row" alignItems="center" style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} gap={0}>
                <IconButton
                    onClick={() => {
                        const newDate = DateTime.fromISO(date).minus({ days: 1 }).toFormat("yyyy-LL-dd");
                        setDate(newDate);
                        calendarRef.current?.getApi()?.gotoDate(newDate);
                    }}
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <DateControl
                    fullWidth={false}
                    label={t("date")}
                    value={date}
                    onChange={(e) => {
                        const newDate = e.target.value;
                        setDate(newDate);
                        calendarRef.current?.getApi()?.gotoDate(newDate);
                    }}
                />
                <IconButton
                    onClick={() => {
                        const newDate = DateTime.fromISO(date).plus({ days: 1 }).toFormat("yyyy-LL-dd");
                        setDate(newDate);
                        calendarRef.current?.getApi()?.gotoDate(newDate);
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                <Button
                    onClick={() => navigate(`/calendar`)}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 10, position: "absolute", right: 30 }}
                >
                    {t("monthly_view")}
                </Button>
            </Stack>
            <FullCalendar
                ref={calendarRef}
                height="auto"
                selectable
                editable
                eventStartEditable={false}
                headerToolbar={false}
                plugins={[resourceTimelinePlugin, interaction]}
                initialView="resourceTimeline"
                initialDate={date}
                duration={{ days: 2 }}
                slotDuration={{ minutes: 10 }}
                nowIndicator
                resourceGroupField="roomtypeName"
                resourceAreaWidth={"200px"}
                slotMinWidth={20}
                resources={resources?.rooms || []}
                resourceOrder={null}
                events={events}
                selectOverlap={(event) =>
                    !(
                        !event?.extendedProps?.reservationData?.isDailyUse &&
                        event?.extendedProps?.reservationData?.status === "CHECKIN"
                    )
                }
                eventClick={({ event }) => {
                    if (event.display === "background") return;
                    const reservationId = event.id.split("R")[0];
                    navigate(`reservations/${reservationId.split("reservations_")[1]}`);
                }}
                select={(data) =>
                    setNewReservationData({
                        open: true,
                        start: data.startStr,
                        end: data.endStr,
                        roomData: data.resource._resource?.extendedProps?.roomData,
                    })
                }
                eventDrop={(event) => {
                    if (!event.newResource) {
                        event.revert();
                        return;
                    }
                    if (
                        !isRoomMoveAllowed({
                            reservation: event?.oldEvent?._def?.extendedProps?.reservationData || {},
                            oldRoom: event?.oldResource?._resource?.extendedProps?.roomData,
                            newRoom: event?.newResource?._resource?.extendedProps?.roomData,
                        })
                    ) {
                        event.revert();
                        return;
                    }
                    const reservation = event?.oldEvent?._def?.extendedProps?.reservationData || {};
                    setEditedEvent({
                        reservation,
                        currentRoom: event?.oldResource?._resource?.extendedProps?.roomData,
                        newRoom: event?.newResource?._resource?.extendedProps?.roomData,
                        changes: {
                            field: "room",
                            currentValue: event?.oldResource?._resource?.title,
                            newValue: event?.newResource?._resource?.title,
                        },
                        revert: event.revert,
                        action: "move",
                        startDate: reservation?.checkin,
                        endDate: reservation?.checkout,
                    });
                }}
                eventResize={(event) => {
                    const oldStart = event?.oldEvent?.startStr;
                    const oldEnd = event?.oldEvent?.endStr;
                    const newStart = event?.event?.startStr;
                    const newEnd = event?.event?.endStr;
                    const hasInvoices = event?.oldEvent?._def?.extendedProps?.reservationData?.invoices?.length > 0;

                    const room = resources?.rooms?.find((r) => r.id === event?.event?._def?.resourceIds?.[0])?.roomData;
                    setEditedEvent({
                        reservation: event?.oldEvent?._def?.extendedProps?.reservationData || {},
                        currentRoom: room,
                        newRoom: room,
                        changes:
                            oldStart !== newStart
                                ? {
                                      field: "start",
                                      currentValue: oldStart,
                                      newValue: newStart,
                                  }
                                : {
                                      field: "end",
                                      currentValue: oldEnd,
                                      newValue: newEnd,
                                  },
                        revert: event.revert,
                        action: "resize",
                        hasInvoices,
                        startDate: newStart,
                        endDate: newEnd,
                    });
                }}
            />
            <NewReservationConfirm
                open={newReservationData.open}
                start={newReservationData.start}
                end={newReservationData.end}
                roomData={newReservationData.roomData || {}}
                onConfirm={(newReservation) => {
                    const newEvents = reservationsToEvents([newReservation]);
                    console.log(newEvents);
                    setEvents(events.concat(newEvents));
                    setNewReservationData({ open: false, start: null, end: null, roomData: null });
                }}
                onClose={() => setNewReservationData({ open: false, start: null, end: null, roomData: null })}
            />
            <DailyReservationEditConfirm
                open={Boolean(editedEvent)}
                reservation={editedEvent?.reservation}
                currentRoom={editedEvent?.currentRoom}
                newRoom={editedEvent?.newRoom}
                changes={editedEvent?.changes}
                onConfirm={(data) => {
                    if (editedEvent?.action === "move") onRoomMove(data);
                    else onReservationResize(data);
                }}
                onCancel={() => {
                    editedEvent?.revert();
                    setEditedEvent(null);
                }}
                startDate={editedEvent?.startDate}
                endDate={editedEvent?.endDate}
            />
            <Outlet context={{ onReservationChange }} />
        </div>
    );
};

export default DailyReservationsCalendar;
