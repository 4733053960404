import { Card, CardContent, CardHeader, Divider, Grid, Skeleton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const RoomtypeCardLoading = () => {
    const { t } = useTranslation();
    return (
        <Card sx={{ marginY: 2 }}>
            <CardHeader title={<Skeleton width={200} />} />
            <CardContent>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    justifyContent="space-between"
                    style={{
                        fontSize: 18,
                        fontWeight: 600,
                        backgroundColor: "#ebeff4",
                        color: "black",
                        textAlign: "center",
                        padding: "5px 0",
                    }}
                >
                    <Grid item xs={2} textAlign="left">
                        {t("room_name")}
                    </Grid>
                    <Grid item xs={2} textAlign="left">
                        {t("price")}
                    </Grid>
                    <Grid item xs={2} textAlign="left">
                        {t("rate")}
                    </Grid>
                    <Grid item xs={2}>
                        {t("guests")}
                    </Grid>
                    <Grid item xs={2}>
                        {t("select")}
                    </Grid>
                </Stack>
                <Stack
                    mt={2}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    style={{
                        textAlign: "center",
                    }}
                >
                    <Grid item xs={2}>
                        <Skeleton height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton height={40} />
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default RoomtypeCardLoading;
