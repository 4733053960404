import {
    AppBar,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { Table } from "../../components/table";
import { getLoggedUserData, toCurrency } from "../../utils";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import { printRevenue } from "../../components/pdf/printRevenue";
import CloseIcon from "@mui/icons-material/Close";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentsIcon from "@mui/icons-material/Payments";

const WaiterShift = () => {
    const [orders, setOrders] = useState(null);
    const [orderPayments, setOrderPayments] = useState([]);
    const [actualBalance, setActualBalance] = useState(null);
    const [startingBalance, setStartingBalance] = useState(0.0);
    const [waiterRevenue, setWaiterRevenue] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [activeView, setActiveView] = useState("ordersView");
    const [hotelName, setHotelName] = useState(null);
    const [silentPrintWaiterShift, setSilentPrintWaiterShift] = useState(false);
    const loggedInUser = getLoggedUserData()?._id;
    const { displayName, _id } = getLoggedUserData() || {};
    const waiterRevenueId = waiterRevenue?._id;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            accessorKey: "number",
            header: t("nr"),
            cell: ({ row: { original } }) => original?.number || t("offline_created"),
        },
        {
            accessorKey: "timestamp",
            header: t("date_time"),
            cell: ({ row: { original } }) => DateTime.fromISO(original?.createdAt).toFormat("yyyy-LL-dd HH:mm:ss"),
        },
        {
            accessorKey: "status",
            header: t("status"),
            cell: ({ row: { original } }) => {
                return t(`${original?.payments?.paymentStatus || ""}`);
            },
        },
        {
            accessorKey: "totalPrice",
            header: t("amount"),
            cell: ({ row: { original } }) => toCurrency(original?.totalPrice, original?.currency),
        },
        {
            accessorKey: "table",
            header: t("table"),
            cell: ({ row: { original } }) => {
                const orderTableId = original?.tableId;
                const posTables = original?.pospointData?.tables || [];
                const tableData = posTables?.find((table) => table._id === orderTableId);
                if (tableData && tableData?.tableName) return tableData?.tableName;
                return "--";
            },
        },
        {
            accessorKey: "customerName",
            header: t("customer"),
            cell: ({ row: { original } }) =>
                (original?.customerData?.firstName || "") + " " + (original?.customerData?.lastName || ""),
        },
    ];
    const columnsPayments = [
        {
            accessorKey: "timestamp",
            header: t("date_time"),
            cell: ({ row: { original } }) => DateTime.fromISO(original?.timestamp).toFormat("yyyy-LL-dd HH:mm:ss"),
        },

        {
            accessorKey: "totalPrice",
            header: t("amount"),
            cell: ({ row: { original } }) => {
                if (original?.paymentMethodData?.method !== "roomCharge") {
                    return toCurrency(original?.amount, original?.currency);
                }
                return toCurrency(original?.order?.totalPrice, original?.currency);
            },
        },
        {
            accessorKey: "paymentMethod",
            header: t("payment_method"),
            cell: ({ row: { original } }) => original?.paymentMethodData?.name || "",
        },
        {
            accessorKey: "user",
            header: t("user"),
            cell: ({ row: { original } }) => original?.userName?.split("users_")[1] || "",
        },
    ];

    const loadOrders = async () => {
        const myShiftResponse = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "currencies",
                    responseData: "_id currency",
                },
                {
                    endpoint: "settingsproperty",
                    responseData: "_id name",
                    data: { _id: "settings_property" },
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id silentPrintWaiterShift",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "myShift",
                    data: { _id: _id },
                    responseData:
                        "_id starting{timestamp amount{amount currency}} closing{timestamp amount{amount currency} paymentsAmount{amount currency}} lastClosed",
                },
            ],
        });
        if (myShiftResponse?.currencies) setCurrencies(myShiftResponse.currencies);

        if (myShiftResponse?.settingsproperty) {
            const { name } = myShiftResponse?.settingsproperty || {};
            setHotelName(name);
        }
        if (myShiftResponse?.settingsgeneral) {
            const { silentPrintWaiterShift } = myShiftResponse?.settingsgeneral || {};
            setSilentPrintWaiterShift(silentPrintWaiterShift);
        }
        if (!myShiftResponse?.myShift) return;
        if (myShiftResponse?.myShift?._id) setWaiterRevenue(myShiftResponse.myShift);

        const { timestamp, amount } = myShiftResponse.myShift?.starting || {};
        if (amount) setStartingBalance(amount);

        if (!timestamp) return;
        const maxPreviousClosed = DateTime.fromISO(DateTime.now().toFormat("yyyy-LL-dd")).minus({ days: 2 });
        const shiftOpeningTimestamp =
            maxPreviousClosed > DateTime.fromISO(timestamp)
                ? maxPreviousClosed.toISO({ includeOffset: false }) + "Z"
                : timestamp;
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "orders",
                    data: {
                        startDate: shiftOpeningTimestamp,
                        endDate: "end",
                    },
                    responseData: `
                    _id 
                    customerData{firstName lastName} 
                    pospointData{_id name defaultCurrency tables{_id tableName}}
                    payments{timestamp paymentStatus paymentMethod paymentMethodData{name method} cashReceived} 
                    products{
                        productData{name _id}
                        price
                        quantity
                    } 
                    totalPrice 
                    createdAt
                    createdBy
                    status
                    number
                    tableId
                `,
                },
                {
                    endpoint: "paymentsByDates",
                    data: { start: shiftOpeningTimestamp },
                    responseData: `
                        orders{
                            timestamp
                            amount
                            paymentMethod
                            paymentMethodData{name method}
                            userName
                            order{
                              totalPrice pospointData{_id name defaultCurrency} status
                            }
                        }
                        `,
                },
            ],
        });
        if (response?.orders) {
            setOrders(
                response.orders
                    ?.filter((order) => {
                        const { currency } =
                            myShiftResponse?.currencies?.find(
                                (currency) => currency?._id === order?.pospointData?.defaultCurrency
                            ) || {};

                        order.currency = currency;
                        return order?.createdBy === loggedInUser;
                    })
                    ?.filter((order) => order.status !== "CANCELLED") || []
            );
        }
        if (response?.paymentsByDates?.orders) {
            setOrderPayments(
                response.paymentsByDates.orders
                    ?.filter((order) => {
                        const { currency } =
                            myShiftResponse?.currencies?.find(
                                (currency) => currency?._id === order?.order?.pospointData?.defaultCurrency
                            ) || {};

                        order.currency = currency;
                        return order?.userName === loggedInUser;
                    })
                    ?.filter((payment) => payment.order.status !== "CANCELLED")
            );
        }
    };
    useEffect(() => loadOrders(), []);

    const paymentsTotalByMethods = useMemo(() => {
        const paymentsByMethods = {};
        orderPayments?.forEach((order) => {
            const paymentMethod = order.paymentMethodData?.method?.toLowerCase() || "cash";
            if (paymentMethod === "roomcharge") return;
            if (!paymentsByMethods[paymentMethod]) {
                paymentsByMethods[paymentMethod] = {};
            }
            const { currency } =
                currencies?.find((currency) => currency._id === order?.order?.pospointData?.defaultCurrency) || {};
            if (!currency) return;
            if (!paymentsByMethods[paymentMethod][currency]) {
                paymentsByMethods[paymentMethod][currency] = 0.0;
            }
            paymentsByMethods[paymentMethod][currency] += order.amount;
        });
        return paymentsByMethods;
    }, [orderPayments]);

    const paymentsTotalByMethodName = useMemo(() => {
        const paymentsByMethods = {};
        orderPayments?.forEach((order) => {
            const paymentMethod = order.paymentMethodData?.name?.toLowerCase() || "cash";
            if (!paymentsByMethods[paymentMethod]) {
                paymentsByMethods[paymentMethod] = {};
            }
            const { currency } =
                currencies?.find((currency) => currency._id === order?.order?.pospointData?.defaultCurrency) || {};
            if (!currency) return;
            if (!paymentsByMethods[paymentMethod][currency]) {
                paymentsByMethods[paymentMethod][currency] = 0.0;
            }
            let orderAmount = order.amount;
            if (order.paymentMethodData?.method === "roomCharge") orderAmount = order?.order?.totalPrice;
            paymentsByMethods[paymentMethod][currency] += orderAmount || 0.0;
        });
        return paymentsByMethods;
    }, [orderPayments]);

    const totalPospointsPayments = useMemo(() => {
        const pospoints =
            orderPayments?.reduce((acc, payment) => {
                if (!acc[payment.order.pospointData?._id]) {
                    const { currency } =
                        currencies?.find((currency) => currency._id === payment.order.pospointData?.defaultCurrency) ||
                        {};
                    acc[payment.order.pospointData?._id] = {
                        amount: 0,
                        pospointName: payment.order.pospointData?.name,
                        currency: currency,
                    };
                }
                const paymentMethod = payment.paymentMethodData?.method?.toLowerCase() || "cash";
                if (paymentMethod === "cash") {
                    acc[payment.order.pospointData._id].amount += payment.amount || 0;
                }
                return acc;
            }, {}) || {};
        return Object.values(pospoints);
    }, [orderPayments]);

    const closingBalanceAmounts = useMemo(() => {
        const paymentsCashAmounts = paymentsTotalByMethods?.cash ? { ...paymentsTotalByMethods?.cash } : {};
        waiterRevenue?.starting?.amount?.forEach((currency) => {
            if (!paymentsCashAmounts[currency.currency]) paymentsCashAmounts[currency.currency] = 0.0;
            paymentsCashAmounts[currency.currency] += currency.amount;
        });
        return Object.entries(paymentsCashAmounts)?.map(([currency, amount]) => ({ currency, amount }));
    }, [paymentsTotalByMethods]);

    const totalOpenAmounts = useMemo(() => {
        const amountsByCurrency = {};
        orders
            ?.filter((order) => order.status === "OPEN")
            ?.forEach((order) => {
                let { currency } =
                    currencies?.find((currency) => currency._id === order.pospointData?.defaultCurrency) || {};
                if (!currency) currency = "ALL";
                if (!amountsByCurrency[currency]) {
                    amountsByCurrency[currency] = 0.0;
                }
                const totalPrice = parseFloat(order.totalPrice);
                amountsByCurrency[currency] += isNaN(totalPrice) ? 0.0 : totalPrice;
            });
        return amountsByCurrency;
    }, [orders]);

    // const calculateActualBalance = useMemo(
    //     () => (actualBalance) => {
    //         if (isNaN(totalPayments - parseFloat(actualBalance))) return 0;
    //         return totalPayments - parseFloat(actualBalance);
    //     },
    //     [actualBalance]
    // );
    const closeShift = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "closeShift",
            data: {
                amount: closingBalanceAmounts,
                paymentsAmount: paymentsTotalByMethods?.cash
                    ? Object.entries(paymentsTotalByMethods?.cash).map(([currency, amount]) => ({ currency, amount }))
                    : [],
                _id: waiterRevenueId,
            },
            responseData: `
                _id 
            `,
        });
        if (response?.closeShift?._id) {
            enqueueSnackbar(t("shift_closed"), { variant: "info" });
            const waiterLoginPOS = localStorage.getItem("waiterLoginPOS");
            if (!!waiterLoginPOS) {
                navigate(`/pos/sell-login?pos=${waiterLoginPOS?.split("pospoints_")[1]}`);
            } else navigate("/login");
            loadOrders();
        }
    };
    const reopenLastShift = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "reopenLastShift",
            data: { _id: waiterRevenueId },
            responseData: `
                _id 
            `,
        });
        if (response?.reopenLastShift?._id) {
            enqueueSnackbar(t("last_shift_reopened"), { variant: "info" });
            loadOrders();
        }
    };

    const print = (withItems) => {
        printRevenue({
            orders,
            user: displayName || _id?.split("users_")?.[1] || "",
            hotel: hotelName,
            currency: "",
            withItems: withItems,
            startingBalance: waiterRevenue?.starting?.amount || [],
            totalPayments: paymentsTotalByMethods?.cash
                ? Object.entries(paymentsTotalByMethods?.cash).map(([currency, amount]) => ({
                      currency,
                      amount,
                  }))
                : [],
            paymentsTotalByMethodName: paymentsTotalByMethodName,
            totalOpenAmounts: totalOpenAmounts,
            totalPospointsPayments: totalPospointsPayments,
            totalUserRevenue: paymentsTotalByMethods,
            closingBalance: closingBalanceAmounts,
            silentPrintWaiterShift,
            startingTimestamp: waiterRevenue?.starting?.timestamp,
            closingTimestamp: waiterRevenue?.lastClosed,
            t,
        });
    };
    return (
        <>
            <AppBar
                position="fixed"
                elevation={2}
                sx={{
                    backgroundColor: "white",
                }}
            >
                <Toolbar>
                    <IconButton onClick={() => navigate(-1)} color="primary">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Paper variant="outlined" sx={{ padding: 2, marginTop: "70px" }}>
                <Typography variant="h6">
                    {`${t("last_close")}: ${
                        waiterRevenue?.lastClosed
                            ? DateTime.fromISO(waiterRevenue.lastClosed).toFormat("dd-LL-yyyy HH:mm:ss")
                            : waiterRevenue?.closing?.timestamp
                            ? DateTime.fromISO(waiterRevenue?.closing?.timestamp).toFormat("dd-LL-yyyy HH:mm:ss")
                            : ""
                    }
                            `}
                </Typography>

                <Divider />
                <Grid container spacing={4} paddingTop={2}>
                    <Grid item xs={12} md={6}>
                        <Tabs
                            value={activeView}
                            onChange={(e, newValue) => setActiveView(newValue)}
                            sx={{ marginBottom: 2 }}
                        >
                            <Tab value={"ordersView"} label={t("orders")} />
                            <Tab value={"paymentsView"} label={t("payments")} />
                        </Tabs>
                        {activeView === "ordersView" ? (
                            <div style={{ maxHeight: "70vh", overflow: "auto" }}>
                                <Table
                                    data={waiterRevenue?.closing?.timestamp ? [] : orders || []}
                                    columns={columns}
                                    onRowClick={(row) => navigate(`order/${row?._id?.split("orders_")[1]}`)}
                                    disableHeader
                                    disableSelection
                                    disableDelete
                                    disableFooter
                                />
                            </div>
                        ) : (
                            <div style={{ maxHeight: "70vh", overflow: "auto" }}>
                                <Table
                                    data={waiterRevenue?.closing?.timestamp ? [] : orderPayments || []}
                                    columns={columnsPayments}
                                    disableHeader
                                    disableSelection
                                    disableDelete
                                    disableFooter
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container xs={12} spacing={2} marginTop="10px">
                            <BalanceCard
                                title={t("starting_balance")}
                                currenciesAmount={waiterRevenue?.starting?.amount || []}
                                icon={<AccountBalanceWalletIcon sx={{ color: "#00B894" }} />}
                            />
                            <BalanceCard
                                title={t("payments")}
                                currenciesAmount={
                                    paymentsTotalByMethods?.cash
                                        ? Object.entries(paymentsTotalByMethods?.cash).map(([currency, amount]) => ({
                                              currency,
                                              amount,
                                          }))
                                        : []
                                }
                                icon={<PaymentsIcon sx={{ color: "#3498DB" }} />}
                            />
                            <BalanceCard
                                title={t("closing_balance")}
                                currenciesAmount={closingBalanceAmounts}
                                icon={<AccountBalanceWalletIcon sx={{ color: "#FFA500" }} />}
                            />
                        </Grid>

                        <Typography variant="caption">*{t("only_cash_payments_are_calculated")}</Typography>
                        <Divider />
                        <PaymentMethodsTable title={t("payment_methods")} payments={paymentsTotalByMethodName} />

                        <Grid container spacing={2} marginTop="10px">
                            <Grid item xs={6}>
                                <Card sx={{ padding: 2, minWidth: 200 }}>
                                    <Typography variant="subtitle1">{t("open_orders")}</Typography>
                                    <Typography variant="h6" color={"primary"}>
                                        {Object.entries(totalOpenAmounts)
                                            ?.map(([currency, amount]) => `${currency} ${amount.toFixed(2)}`)
                                            ?.join(", ")}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginTop: "10px" }} />
                        <PaymentByPOS title={t("payments_by_pospoint")} payments={totalPospointsPayments} />
                        <Typography variant="caption">*{t("only_cash_payments_are_shown")}</Typography>
                        {/* <InputControl
                            label={t("actual_balance")}
                            type="number"
                            value={actualBalance}
                            onChange={(e) => {
                                setActualBalance(e.target.value);
                            }}
                            disabled={waiterRevenue?.closing?.timestamp}
                        />
                        {!!actualBalance && (
                            <Details
                                title={t("difference")}
                                value={toCurrency(startingBalance + calculateActualBalance(actualBalance), "")}
                            />
                        )} */}

                        <ButtonGroup disabled={!orders} fullWidth sx={{ marginTop: 4 }} variant="outlined">
                            <Button
                                disabled={!!waiterRevenue?.closing}
                                onClick={() => {
                                    print(false);
                                    closeShift();
                                }}
                                disableElevation
                                variant="contained"
                                sx={{ paddingTop: 2, paddingBottom: 2 }}
                            >
                                {t("close_and_print")}
                            </Button>
                            <Button
                                disabled={!!waiterRevenue?.closing}
                                onClick={() => {
                                    print(true);
                                    closeShift();
                                }}
                            >
                                {t("close_and_print_detailed_receipt")}
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup
                            disabled={!orders}
                            fullWidth
                            sx={{ marginTop: 2, marginBottom: 5 }}
                            variant="outlined"
                        >
                            <Button color="error" disabled={!!waiterRevenue?.closing} onClick={() => closeShift()}>
                                {t("close_shift_without_print")}
                            </Button>
                            <Button
                                color="success"
                                disabled={!waiterRevenue?.closing}
                                onClick={() => reopenLastShift()}
                            >
                                {t("reopen_last_shift")}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Outlet />
            </Paper>
        </>
    );
};

const BalanceCard = ({ title, currenciesAmount = [], icon }) => {
    return (
        <Grid item xs={12} md={4} spacing={2}>
            <Card>
                <CardHeader avatar={icon} title={title} titleTypographyProps={{ variant: "subtitle1" }} />
                <CardContent>
                    {currenciesAmount?.length === 0 && (
                        <Typography variant="h6" color="primary">
                            --
                        </Typography>
                    )}

                    {currenciesAmount?.map((currency) => {
                        return (
                            <Typography variant="h6">
                                {currency.currency} {parseFloat(currency.amount).toFixed(2)}
                            </Typography>
                        );
                    })}
                </CardContent>
            </Card>
        </Grid>
    );
};

const PaymentMethodsTable = ({ title, payments = {} }) => {
    return (
        <>
            <Typography variant="subtitle1" marginTop={3}>
                {title}
            </Typography>
            {Object.entries(payments)?.length === 0 && (
                <Typography variant="h6" color="primary">
                    --
                </Typography>
            )}
            <Grid container spacing={2}>
                {Object.entries(payments)?.map(([method, amountsByCurrency = {}]) => {
                    return (
                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="subtitle2">{method}</Typography>
                                    <Typography variant="h5">
                                        {Object.entries(amountsByCurrency)
                                            ?.map(([currency, amount]) => {
                                                return `${currency} ${amount?.toFixed(2)}`;
                                            })
                                            ?.join(" , ")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

const PaymentByPOS = ({ title, payments = [] }) => {
    return (
        <>
            <Typography variant="subtitle1" marginTop={3}>
                {title}
            </Typography>
            {payments?.length === 0 && (
                <Typography variant="h6" color="primary">
                    --
                </Typography>
            )}
            <Grid container spacing={2}>
                {payments?.map((pos) => {
                    return (
                        <Grid item xs={6}>
                            <Card sx={{ padding: 2, minWidth: 200 }}>
                                <Typography variant="subtitle1">{pos?.pospointName}</Typography>
                                <Typography variant="h6" color={"primary"}>
                                    {pos?.currency} {pos.amount.toFixed(2)}
                                </Typography>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default WaiterShift;
