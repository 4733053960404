import { Button, Stack } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReportGenerator } from "../../components/classes";
import { DateRangeControl, TimeControl } from "../../components/dates";
import { AutoCompleteControl, SwitchControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { toCurrency } from "../../utils";
import { ReportHeader } from "./components";
import { useRef } from "react";
import jsPDF from "jspdf";

const UserSalesReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [extraData, setExtraData] = useState({
        users: [],
        pospoints: [],
        settingsGeneral: {},
        currencies: [],
    });
    const salesReportRef = useRef();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [hideUsersWithoutPayments, setHideUsersWithoutPayments] = useState(false);
    const [paymentsData, setPaymentsData] = useState(null);
    const { loading, fetch } = useApi();

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "users",
                    responseData: "_id displayName status",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "currencyData{currency} dailyUseCurrencyData{currency}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "pospoints",
                    responseData: "_id name defaultCurrency isActive",
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency",
                },
            ],
        });
        if (response)
            setExtraData({
                users: response?.users
                    ?.filter((user) => user.status === "ACTIVE")
                    ?.map((user) => {
                        return {
                            value: user._id?.split("users_")[1],
                            label: user.displayName || user._id?.split("users_")[1],
                        };
                    }),
                settingsGeneral: response?.settingsgeneral,
                pospoints: response?.pospoints,
                currencies: response?.currencies,
            });
    };

    const generateColumns = ({ pospoints = [], paymentMethods = [] }) => {
        return [
            {
                id: "users",
                header: t("users"),
                columns: [
                    {
                        id: "users",
                        header: "",
                        displayValue: (row) => {
                            return row.userName || "";
                        },
                        totalValue: () => t("total"),
                    },
                ],
                displayValue: (row) => {
                    return row.userName || "";
                },
                totalValue: () => t("total"),
            },
            {
                id: "room_sales",
                header: t("room_sales"),
                displayValue: () => {},
                columns: paymentMethods?.map((paymentMethod) => {
                    return {
                        id: `room_sales_${paymentMethod}`,
                        header: t(paymentMethod),
                        displayValue: (row) => {
                            const paymentsByCurrency = {};
                            const payments = row?.invoicePayments
                                ?.concat(row?.reservationPayments)
                                ?.filter((payment) => payment?.paymentMethodData?.name === paymentMethod);
                            payments?.forEach((payment) => {
                                let currency = extraData?.settingsGeneral?.currencyData?.currency || "ALL";
                                const { invoiceCurrency } = payment?.invoice || payment?.reservation;
                                if (invoiceCurrency) {
                                    currency = invoiceCurrency;
                                } else if (payment?.reservation?.isDailyUse) {
                                    currency =
                                        extraData?.settingsGeneral?.dailyUseCurrencyData?.currency ||
                                        extraData?.settingsGeneral?.currencyData?.currency ||
                                        "ALL";
                                }
                                if (!paymentsByCurrency?.[currency]) {
                                    paymentsByCurrency[currency] = 0.0;
                                }
                                paymentsByCurrency[currency] += isNaN(payment.amount) ? 0.0 : payment.amount;
                            });

                            return (
                                Object.entries(paymentsByCurrency)
                                    ?.map((payment) => `${parseFloat(payment[1])?.toFixed(2)} ${payment[0]}`)
                                    ?.join(", ") || "0.0"
                            );
                        },
                        totalValue: (rows = []) => {
                            const paymentsByCurrency = {};
                            const payments = rows
                                ?.map((row) => {
                                    const { invoicePayments = [], reservationPayments = [] } = row || {};
                                    return (invoicePayments || []).concat(reservationPayments || []);
                                })
                                ?.flat()
                                ?.filter((payment) => payment?.paymentMethodData?.name === paymentMethod);
                            payments?.forEach((payment) => {
                                let currency = extraData?.settingsGeneral?.currencyData?.currency || "ALL";
                                const { invoiceCurrency } = payment?.invoice || payment?.reservation;
                                if (invoiceCurrency) {
                                    currency = invoiceCurrency;
                                } else if (payment?.reservation?.isDailyUse) {
                                    currency =
                                        extraData?.settingsGeneral?.dailyUseCurrencyData?.currency ||
                                        extraData?.settingsGeneral?.currencyData?.currency ||
                                        "ALL";
                                }
                                if (!paymentsByCurrency?.[currency]) {
                                    paymentsByCurrency[currency] = 0.0;
                                }
                                paymentsByCurrency[currency] += isNaN(payment.amount) ? 0.0 : payment.amount;
                            });

                            return (
                                Object.entries(paymentsByCurrency)
                                    ?.map((payment) => `${parseFloat(payment[1])?.toFixed(2)} ${payment[0]}`)
                                    ?.join(", ") || "0.0"
                            );
                        },
                    };
                }),
                totalValue: () => "0.00",
            },
        ]
            .concat(
                pospoints?.map((pos) => {
                    const posCurrency =
                        extraData?.currencies?.find((c) => c._id === pos.defaultCurrency)?.currency || "";
                    return {
                        id: pos._id,
                        header: pos.name,
                        displayValue: () => {},
                        columns: paymentMethods?.map((paymentMethod) => {
                            return {
                                id: pos.name + "_" + paymentMethod,
                                header: t(paymentMethod),
                                displayValue: (row) => {
                                    const posPayments = row?.posPayments?.filter(
                                        (payment) => payment?.order?.pospointId === pos._id
                                    );
                                    const amount = posPayments?.reduce((acc, payment) => {
                                        if (payment?.paymentMethodData?.name === paymentMethod) {
                                            let paymentAmount = payment.amount;
                                            if (payment?.paymentMethodData?.method === "roomCharge") {
                                                paymentAmount = payment?.order?.totalPrice;
                                            }
                                            acc += paymentAmount || 0.0;
                                        }
                                        return acc;
                                    }, 0.0);
                                    return toCurrency(isNaN(amount) ? 0.0?.toFixed(2) : amount.toFixed(2), posCurrency);
                                },
                                totalValue: (rows = []) => {
                                    const posPayments = rows
                                        ?.map((row) => row?.posPayments || [])
                                        ?.flat()
                                        ?.filter((payment) => payment?.order?.pospointId === pos._id);
                                    const amount = posPayments?.reduce((acc, payment) => {
                                        if (payment?.paymentMethodData?.name === paymentMethod) {
                                            let paymentAmount = payment.amount;
                                            if (payment?.paymentMethodData?.method === "roomCharge") {
                                                paymentAmount = payment?.order?.totalPrice;
                                            }
                                            acc += paymentAmount || 0.0;
                                        }
                                        return acc;
                                    }, 0.0);
                                    return (
                                        toCurrency(isNaN(amount) ? 0.0?.toFixed(2) : amount.toFixed(2), posCurrency) ||
                                        "0.0"
                                    );
                                },
                            };
                        }),
                        totalValue: () => "0.00",
                    };
                })
            )
            .concat([
                {
                    id: "total",
                    header: t("total"),
                    displayValue: (row) => {
                        const paymentsByCurrency = {};

                        row?.posPayments?.forEach((posPayment) => {
                            const { currency } =
                                extraData?.currencies?.find(
                                    (currency) => currency._id === posPayment?.order?.pospointData?.defaultCurrency
                                ) || {};

                            if (!currency) return;
                            if (!paymentsByCurrency[currency]) {
                                paymentsByCurrency[currency] = 0.0;
                            }

                            paymentsByCurrency[currency] += isNaN(posPayment.amount) ? 0.0 : posPayment.amount;
                        });
                        row?.invoicePayments?.forEach((invoicePayment) => {
                            let currency = extraData?.settingsGeneral?.currencyData?.currency || "ALL";
                            const { invoiceCurrency } = invoicePayment?.invoice || {};
                            if (invoiceCurrency) {
                                currency = invoiceCurrency;
                            }

                            if (!paymentsByCurrency[currency]) {
                                paymentsByCurrency[currency] = 0.0;
                            }

                            paymentsByCurrency[currency] += isNaN(invoicePayment.amount) ? 0.0 : invoicePayment.amount;
                        });
                        row?.reservationPayments?.forEach((resPayment) => {
                            let currency = extraData?.settingsGeneral?.currencyData?.currency || "ALL";
                            const { invoiceCurrency } = resPayment?.reservation || {};
                            if (invoiceCurrency) {
                                currency = invoiceCurrency;
                            } else if (resPayment?.reservation?.isDailyUse) {
                                currency =
                                    extraData?.settingsGeneral?.dailyUseCurrencyData?.currency ||
                                    extraData?.settingsGeneral?.currencyData?.currency ||
                                    "ALL";
                            }

                            if (!paymentsByCurrency[currency]) {
                                paymentsByCurrency[currency] = 0.0;
                            }

                            paymentsByCurrency[currency] += isNaN(resPayment.amount) ? 0.0 : resPayment.amount;
                        });

                        return (
                            Object.entries(paymentsByCurrency)
                                ?.map((payment) => `${parseFloat(payment[1])?.toFixed(2)} ${payment[0]}`)
                                ?.join(", ") || "0.0"
                        );
                    },
                    totalValue: (rows = []) => {
                        const paymentsByCurrency = {};
                        rows?.forEach((row) => {
                            row?.posPayments?.forEach((posPayment) => {
                                const { currency } =
                                    extraData?.currencies?.find(
                                        (currency) => currency._id === posPayment?.order?.pospointData?.defaultCurrency
                                    ) || {};

                                if (!currency) return;
                                if (!paymentsByCurrency[currency]) {
                                    paymentsByCurrency[currency] = 0.0;
                                }

                                paymentsByCurrency[currency] += isNaN(posPayment.amount) ? 0.0 : posPayment.amount;
                            });
                            row?.invoicePayments?.forEach((invoicePayment) => {
                                let currency = extraData?.settingsGeneral?.currencyData?.currency || "ALL";
                                const { invoiceCurrency } = invoicePayment?.invoice || {};
                                if (invoiceCurrency) {
                                    currency = invoiceCurrency;
                                }

                                if (!paymentsByCurrency[currency]) {
                                    paymentsByCurrency[currency] = 0.0;
                                }

                                paymentsByCurrency[currency] += isNaN(invoicePayment.amount)
                                    ? 0.0
                                    : invoicePayment.amount;
                            });
                            row?.reservationPayments?.forEach((resPayment) => {
                                let currency = extraData?.settingsGeneral?.defaultCurrency || "ALL";
                                const { invoiceCurrency } = resPayment?.reservation || {};
                                if (invoiceCurrency) {
                                    currency = invoiceCurrency;
                                } else if (resPayment?.reservation?.isDailyUse) {
                                    currency =
                                        extraData?.settingsGeneral?.dailyUseCurrencyData?.currency ||
                                        extraData?.settingsGeneral?.currencyData?.currency ||
                                        "ALL";
                                }

                                if (!paymentsByCurrency[currency]) {
                                    paymentsByCurrency[currency] = 0.0;
                                }

                                paymentsByCurrency[currency] += isNaN(resPayment.amount) ? 0.0 : resPayment.amount;
                            });
                        });
                        return (
                            Object.entries(paymentsByCurrency)
                                ?.map((payment) => `${parseFloat(payment[1])?.toFixed(2)} ${payment[0]}`)
                                ?.join(", ") || "0.0"
                        );
                    },
                },
            ]);
    };

    const report = useMemo(() => {
        if (!paymentsData) return null;
        const paymentMethods = [];

        const start = !!params.get("startTime")
            ? new Date(`${params.get("startDate")} ${params.get("startTime")}:00`)
            : new Date(`${params.get("startDate")} 00:00:00`);
        const end = !!params.get("endTime")
            ? new Date(`${params.get("endDate")} ${params.get("endTime")}:59`)
            : new Date(`${params.get("endDate")} 23:59:59`);
        const selectedUser = params.get("user");
        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + DateTime.fromJSDate(start).toFormat("yyyy-LL-dd");
        if (!!end) filtersText += ", " + t("end_date") + ": " + DateTime.fromJSDate(end).toFormat("yyyy-LL-dd");
        if (!!selectedUser) filtersText += ", " + t("user") + ": " + selectedUser;

        (paymentsData?.invoices || [])?.concat(paymentsData?.reservations || [])?.forEach((payment) => {
            if (paymentMethods?.includes(payment?.paymentMethodData?.name)) {
                return;
            }
            paymentMethods.push(payment?.paymentMethodData?.name);
        });

        paymentsData?.orders?.forEach((payment) => {
            if (paymentMethods?.includes(payment?.paymentMethodData?.name)) {
                return;
            }
            paymentMethods.push(payment?.paymentMethodData?.name);
        });

        const columns = generateColumns({
            pospoints: extraData?.pospoints?.filter((pos) => {
                if (pos.isActive) return true;
                if (paymentsData?.orders?.some((payment) => payment?.order?.pospointId === pos._id)) {
                    return true;
                }
                return false;
            }),
            paymentMethods,
        });
        const reportData = extraData?.users
            ?.filter((user) => {
                if (hideUsersWithoutPayments) {
                    const userPayments = [].concat(
                        paymentsData?.invoices || [],
                        paymentsData?.reservations || [],
                        paymentsData?.orders || []
                    );
                    const hasUserPayments = userPayments?.some((payment) => payment.userName === "users_" + user.value);
                    return hasUserPayments;
                }
                return true;
            })
            ?.filter((user) => {
                if (params.get("user")) return user.value === params.get("user");
                return true;
            })
            ?.map((user) => {
                return {
                    userId: "users_" + user.value,
                    userName: user.label,
                    invoicePayments: paymentsData?.invoices?.filter((payment) => {
                        if (["CANCELLED", "TRANSFERRED"]?.includes(payment?.invoice?.status)) {
                            return false;
                        }
                        return payment.userName === "users_" + user.value;
                    }),
                    reservationPayments: paymentsData?.reservations?.filter((payment) => {
                        if (["CANCELLED", "TRANSFERRED"]?.includes(payment?.reservation?.status)) {
                            return false;
                        }
                        return payment.userName === "users_" + user.value;
                    }),
                    posPayments: paymentsData?.orders?.filter((payment) => {
                        if (["CANCELLED", "TRANSFERRED"]?.includes(payment?.order?.status)) {
                            return false;
                        }
                        return payment.userName === "users_" + user.value;
                    }),
                };
            });
        const newRaportGenerator = new ReportGenerator({
            data: reportData,
            columns,
            displayTotal: true,
        });
        setPaymentMethods(paymentMethods);
        return newRaportGenerator.generateTable({
            title: t("payments"),
            landscape: true,
            headerInfo: [filtersText],
            tableRef: salesReportRef,
        });
    }, [paymentsData, hideUsersWithoutPayments]);

    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        const start = !!params.get("startTime")
            ? new Date(`${params.get("startDate")} ${params.get("startTime")}:00`)
            : new Date(`${params.get("startDate")} 00:00:00`);
        const end = !!params.get("endTime")
            ? new Date(`${params.get("endDate")} ${params.get("endTime")}:59`)
            : new Date(`${params.get("endDate")} 23:59:59`);

        const response = await fetch({
            operation: "query",
            endpoint: "paymentsByDates",
            data: { start: start?.toJSON(), end: end?.toJSON() },
            responseData: `
                invoices{
                    timestamp
                    amount
                    paymentMethod
                    paymentMethodData{name}
                    userName
                    note
                    invoice{
                        status
                        invoiceCurrency
                        reservationData{
                            rooms{roomData{name}}
                            clientsData{_id firstName lastName}
                            uuid
                        }
                    }
                }
                reservations{
                    timestamp
                    amount
                    paymentMethod
                    paymentMethodData{name}
                    userName
                    note
                    reservation{
                        status
                        rooms{roomData{name}}
                        clientsData{_id firstName lastName}
                        guests{roomId guests{name number price extra}} 
                        uuid
                        checkin
                        checkout
                        totalPrice
                        isDailyUse
                        invoiceCurrency
                    }
                }
                orders{
                    timestamp
                    amount
                    paymentMethod
                    paymentMethodData{name method}
                    userName
                    note
                    order{
                        status
                        pospointId totalPrice pospointData{defaultCurrency isActive}
                    }
                }
            `,
        });
        if (!response?.paymentsByDates?.invoices || !response?.paymentsByDates?.reservations) return;

        setPaymentsData(response?.paymentsByDates || {});
        return;
    };

    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("startTime")) data.startTime = params.get("startTime");
        if (!!params.get("endTime")) data.endTime = params.get("endTime");
        if (!!params.get("user")) data.user = params.get("user");
        return data;
    };
    const exportToPdf = () => {
        const doc = new jsPDF("l");
        const totalPagesExp = "{total_pages_count_string}";
        if (paymentMethods.length < 1) return;

        let headers = [
            { content: t("users"), colSpan: 1 },
            { content: t("accomodation"), colSpan: paymentMethods?.length },
            ...extraData.pospoints?.map((pos) => {
                return { content: pos.name, colSpan: paymentMethods?.length };
            }),
            { content: "" },
        ];
        const h = salesReportRef.current?.getTableData()?.[0]?.map((row) => t(row?.header));
        const r = salesReportRef.current.getTableData()?.map((row) => row?.map((col) => col?.value));

        let rows = [h];
        r?.forEach((r) => rows.push(r));

        doc.autoTable({
            margin: { top: 30 },
            head: [headers],
            body: rows,
            didDrawPage: (data) => {
                // Date
                doc.setFontSize(10);
                doc.setFont("helvetica", "italic");
                doc.text(
                    `${t("time_created")}: ` + DateTime.now().toFormat("dd-LL-yyyy HH:mm"),
                    data.settings.margin.left + 0,
                    28
                );

                // Footer
                var str = "Page " + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === "function") {
                    str = str + " of " + totalPagesExp;
                }
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);

                // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
                var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
                var footerText = "HB";

                doc.setTextColor(100);
                doc.setFontSize(10);
                doc.text(footerText, pageWidth / 2, pageHeight - 10, "center");
            },
        });
        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }
        doc.save("User Sales Report" + DateTime.now().toFormat("dd-LL-yyyy HH:mm"));
    };

    return (
        <div>
            <ReportHeader
                title={t("user_sales_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <TimeControl
                        value={params.get("startTime")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                startTime: e.target.value || "",
                            })
                        }
                        margin="none"
                        fullWidth={false}
                        label={t("start_date_time")}
                    />
                    <TimeControl
                        value={params.get("endTime")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                endTime: e.target.value || "",
                            })
                        }
                        margin="none"
                        fullWidth={false}
                        label={t("end_date_time")}
                    />
                    <AutoCompleteControl
                        options={extraData?.users}
                        value={params.get("user")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                user: e.target.value || "",
                            })
                        }
                        margin="none"
                        sx={{ width: "200px" }}
                        label={t("user")}
                    />
                    <SwitchControl
                        label={t("hide_users_with_0_total")}
                        value={hideUsersWithoutPayments}
                        onChange={(e) => setHideUsersWithoutPayments(e.target.checked)}
                    />
                    {!!report && (
                        <Button startIcon={<PictureAsPdfIcon />} onClick={() => exportToPdf()} variant="outlined">
                            {t("print")}
                        </Button>
                    )}
                </Stack>
            </ReportHeader>
            {report}
        </div>
    );
};

export default UserSalesReport;
