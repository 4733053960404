import {
    Button,
    Card,
    CardMedia,
    Divider,
    Grid,
    Tab,
    Tabs,
    LinearProgress,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
    AutoCompleteControl,
    Form,
    InputControl,
    SelectControl,
    SwitchControl,
    RadioGroupControl,
} from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import currency from "../../../assests/currency";
import CancelIcon from "@mui/icons-material/Cancel";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import UploadComponent from "../../../components/form/UploadComponent";
import { uploadToAzure } from "../../../utils/uploadToAzure";
import languages from "../../../assests/languages";
import { SettingsCard, SubmitButton } from "../general/components";

const countriesOptions = currency.map((c) => c.CtryNm);
const rules = {
    name: "string|required",
    country: "string|required",
    city: "string|required",
    email: "string|required",
    phone: "string|required",
};

const SettingsPropertyForm = () => {
    const [values, setValues] = useState({ confirmationMessage: [{}], bookingTerms: [{}], cancellationTerms: [{}] });
    const [tabValue, setTabValue] = useState(0);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const positionOptions = [
        { value: "header", label: t("header") },
        { value: "footer", label: t("footer") },
    ];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "settingsproperty",
            data: {
                _id: "settings_property",
            },
            responseData:
                "_id name logo country registrationNumber address city region zip email website phone cancellationTerms{language text} bookingTerms{language text} confirmationMessage{language text} detailedAccomodation signatureposition",
        });
        if (response?.settingsproperty)
            setValues({
                ...values,
                ...response.settingsproperty,
            });
    };

    const saveHandler = async () => {
        const {
            _id,
            name,
            logo,
            country,
            registrationNumber,
            address,
            city,
            region,
            zip,
            email,
            website,
            phone,
            cancellationTerms,
            bookingTerms,
            confirmationMessage,
            detailedAccomodation,
            file,
            signatureposition,
        } = values;
        const data = {
            name,
            logo,
            country,
            registrationNumber,
            address,
            city,
            region,
            zip,
            email,
            website,
            phone,
            cancellationTerms: cancellationTerms?.filter((term) => !!term.language && !!term.text),
            bookingTerms: bookingTerms?.filter((term) => !!term.language && !!term.text),
            confirmationMessage: confirmationMessage?.filter((term) => !!term.language && !!term.text),
            detailedAccomodation,
            signatureposition,
        };
        if (!!file) {
            const logoUrl = await uploadToAzure(file, fetch);
            data.logo = logoUrl;
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateSettingsProperty" : "createSettingsProperty",
            data,
            responseData: "_id",
        });
        if (response?.createSettingsProperty?._id || response?.updateSettingsProperty?._id)
            enqueueSnackbar(t("settings_property_saved"), { variant: "default" });
    };

    const displayAsLogo = useMemo(() => {
        if (!!values?.filePreview) {
            return values.filePreview;
        }
        if (!!values?.logo) return values.logo;
        return undefined;
    }, [values?.filePreview, values?.logo]);

    const handleConfirmationMessageChange = ({ index, language, text }) => {
        const { confirmationMessage } = values;
        const newValue = [...confirmationMessage];
        if (!!language) newValue[index].language = language;
        newValue[index].text = text;
        setValues({ ...values, confirmationMessage: newValue });
    };
    const handleCancellationTermsChange = ({ index, language, text }) => {
        const { cancellationTerms } = values;
        const newValue = [...cancellationTerms];
        if (!!language) newValue[index].language = language;
        newValue[index].text = text;
        setValues({ ...values, cancellationTerms: newValue });
    };
    const handleBookingTermsChange = ({ index, language, text }) => {
        const { bookingTerms } = values;
        const newValue = [...bookingTerms];
        if (!!language) newValue[index].language = language;
        newValue[index].text = text;
        setValues({ ...values, bookingTerms: newValue });
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderTabs = () => {
        switch (tabValue) {
            case 0:
                return (
                    <SettingsCard
                        title={t("property_details")}
                        icon={<MapsHomeWorkIcon />}
                        action={
                            <SubmitButton
                                onClick={saveHandler}
                                size="medium"
                                variant="outlined"
                                permission={{ property: values?._id ? { edit: true } : { create: true } }}
                            />
                        }
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("property_logo")}</Typography>
                                <Divider />
                                <Stack direction="row" marginY={2} gap={3} alignItems="center">
                                    {!!displayAsLogo ? (
                                        <Card
                                            variant="elevation"
                                            elevation={2}
                                            sx={{ width: "150px", position: "relative", borderRadius: "50%" }}
                                        >
                                            <CardMedia
                                                sx={{ width: "100%", height: "150px", objectFit: "cover" }}
                                                image={displayAsLogo}
                                            />
                                        </Card>
                                    ) : null}
                                    <UploadComponent
                                        onFileChange={(e) => {
                                            setValues({
                                                ...values,
                                                file: e.target?.files?.[0],
                                                filePreview: URL.createObjectURL(e.target.files[0]),
                                            });
                                        }}
                                    />
                                    <Button
                                        onClick={() => {
                                            setValues({ ...values, file: null, filePreview: null, logo: null });
                                        }}
                                        color="secondary"
                                        startIcon={<CancelIcon />}
                                    >
                                        {t("remove_logo")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="name" label={t("name")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="registrationNumber" label={t("registration_number")} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h6">{t("address")}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutoCompleteControl options={countriesOptions} name="country" label={t("country")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="city" label={t("city")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="region" label={t("region")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="zip" label={t("zip")} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControl multiline minRows={2} name="address" label={t("address")} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("contacts")}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="email" label={t("email")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="phone" label={t("phone")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="website" label={t("website")} />
                            </Grid>
                        </Grid>
                    </SettingsCard>
                );
            case 1:
                return (
                    <SettingsCard
                        title={t("document_configurations")}
                        icon={<HistoryEduIcon />}
                        action={
                            <SubmitButton
                                onClick={saveHandler}
                                size="medium"
                                variant="outlined"
                                permission={{ property: values?._id ? { edit: true } : { create: true } }}
                            />
                        }
                    >
                        <Grid container spacing={2}>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t("confirmation_message")}</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <SwitchControl name="detailedAccomodation" label={t("detailed_accomodation")} />
                                </Grid>
                                {values?.confirmationMessage?.map((message, index) => {
                                    return (
                                        <Grid item md={6} xs={12}>
                                            <Card sx={{ padding: 2, marginY: 2 }}>
                                                <SelectControl
                                                    value={message?.language || ""}
                                                    label={t("language")}
                                                    options={languages}
                                                    fullWidth={false}
                                                    style={{ minWidth: "200px" }}
                                                    onChange={(e) =>
                                                        handleConfirmationMessageChange({
                                                            index: index,
                                                            language: e.target.value,
                                                        })
                                                    }
                                                />
                                                <InputControl
                                                    value={message?.text || ""}
                                                    label={t("confirmation_message")}
                                                    onChange={(e) =>
                                                        handleConfirmationMessageChange({
                                                            index: index,
                                                            text: e.target.value,
                                                        })
                                                    }
                                                    multiline
                                                    minRows={2}
                                                />
                                            </Card>
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            setValues({
                                                ...values,
                                                confirmationMessage: [...(values?.confirmationMessage || []), {}],
                                            })
                                        }
                                    >
                                        {t("add_new_language")}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t("booking_terms")}</Typography>
                                    <Divider />
                                </Grid>
                                {values?.bookingTerms?.map((message, index) => {
                                    return (
                                        <Grid item md={6} xs={12}>
                                            <Card sx={{ padding: 2, marginY: 2 }}>
                                                <SelectControl
                                                    value={message?.language || ""}
                                                    label={t("language")}
                                                    options={languages}
                                                    onChange={(e) =>
                                                        handleBookingTermsChange({
                                                            index: index,
                                                            language: e.target.value,
                                                        })
                                                    }
                                                />

                                                <InputControl
                                                    value={message?.text || ""}
                                                    label={t("booking_terms")}
                                                    onChange={(e) =>
                                                        handleBookingTermsChange({
                                                            index: index,
                                                            text: e.target.value,
                                                        })
                                                    }
                                                    multiline
                                                    minRows={2}
                                                />
                                            </Card>
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            setValues({
                                                ...values,
                                                bookingTerms: [...(values?.bookingTerms || []), {}],
                                            })
                                        }
                                    >
                                        {t("add_new_language")}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t("cancellation_terms")}</Typography>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <RadioGroupControl
                                        options={positionOptions}
                                        name="signatureposition"
                                        label={t("signature_position")}
                                    />
                                </Grid>
                                {values?.cancellationTerms?.map((message, index) => {
                                    return (
                                        <Grid item md={6} xs={12}>
                                            <Card sx={{ padding: 2, marginY: 2 }}>
                                                <SelectControl
                                                    value={message?.language || ""}
                                                    label={t("language")}
                                                    options={languages}
                                                    onChange={(e) =>
                                                        handleCancellationTermsChange({
                                                            index: index,
                                                            language: e.target.value,
                                                        })
                                                    }
                                                />

                                                <InputControl
                                                    value={message?.text || ""}
                                                    label={t("cancellation_terms")}
                                                    onChange={(e) =>
                                                        handleCancellationTermsChange({
                                                            index: index,
                                                            text: e.target.value,
                                                        })
                                                    }
                                                    multiline
                                                    minRows={2}
                                                />
                                            </Card>
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            setValues({
                                                ...values,
                                                cancellationTerms: [...(values?.cancellationTerms || []), {}],
                                            })
                                        }
                                    >
                                        {t("add_new_language")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SettingsCard>
                );

            default:
                return null;
        }
    };

    const settingsTabs = ["property_details", "document_configurations"];

    return (
        <Form values={values} onValuesChange={setValues} rules={rules} onSubmit={saveHandler}>
            {loading && <LinearProgress color="primary" />}
            <div style={{ minHeight: "calc(100vh - 150px)" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            orientation={isSmallScreen ? "horizontal" : "vertical"}
                            variant="scrollable"
                        >
                            {settingsTabs.map((tab) => (
                                <Tab sx={{ alignItems: "flex-start" }} label={t(tab)} />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {renderTabs(tabValue)}
                    </Grid>
                </Grid>
            </div>

            <Grid container item xs={12} justifyContent="end" style={{ marginTop: 20 }}>
                <SubmitButton
                    onClick={saveHandler}
                    permission={{ property: values?._id ? { edit: true } : { create: true } }}
                />
            </Grid>
        </Form>
    );
};

export default SettingsPropertyForm;
