import { Drawer } from "@mui/material";
import React from "react";
import CreateReservation from "../../CreateReservation";

const CreateReservationDrawer = ({ open = false, onClose, data = {}, onNewReservation }) => {
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: "75vw",
                    backgroundColor: "background.default",
                    padding: 1,
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                },
            }}
        >
            <CreateReservation {...data} onReservationCreated={onNewReservation} />
        </Drawer>
    );
};

export default CreateReservationDrawer;
