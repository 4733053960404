const validateExistingFiscalData = (fiscalData) => {
    if (!fiscalData) {
        throw new Error("corrective_invoice_fiscalData_missing");
    }
    if (!fiscalData.IICRef) {
        throw new Error("corrective_invoice_fiscalData_missing_IICRef");
    }
    if (!fiscalData.IssueDateTime) {
        throw new Error("corrective_invoice_fiscalData_missing_IssueDateTime");
    }
};
export default validateExistingFiscalData;
