import {
    Avatar,
    ButtonBase,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { useTranslation } from "react-i18next";

const OrdersDrawer = ({ open, orders = [], onClose, onOrderClick, onOrderDelete }) => {
    const { t } = useTranslation();
    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <Typography style={{ marginTop: 20, textAlign: "center" }} variant="h6">
                {t("open_orders")}
            </Typography>
            <List sx={{ width: 300 }}>
                {orders.map((order) => (
                    <ListItem
                        secondaryAction={
                            <IconButton onClick={() => onOrderDelete(order._id)} color="primary">
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ReceiptLongIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ButtonBase onClick={() => onOrderClick(order._id.split("orders_")[1])}>
                            <ListItemText
                                primary={order?.customerData?.firstName + order?.customerData?.lastName}
                                secondary={order.totalPrice?.toFixed(2) + " All"}
                            />
                        </ButtonBase>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default OrdersDrawer;
