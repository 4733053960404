import { Button, ButtonGroup, Divider, Grid, Card, CardContent, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal";
import { ItemsTable } from "./components";
import { AutoCompleteControl, Form, InputControl, SelectControl, SwitchControl } from "../../components/form";
import { DateRangeControl } from "../../components/dates";
import { ActionsPanel, ActionBox, AmountsCard, ClientsSearch } from "../../components/common";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useApi } from "../../components/hooks";

const EstimateForm = () => {
    const [values, setValues] = useState({});
    const [posOptions, setPosOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { addRecordEstimate } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "estimate",
            data: {
                _id: "estimates_" + params?.id,
            },
            responseData: `_id currency customer serial pdfLanguage salesUnit description date dueDate`,
        });
        if (response?.estimate) setValues(response.estimate);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "pospoints", responseData: "_id name" },
                { endpoint: "currencies", responseData: "_id currency" },
            ],
        });

        if (!!response?.pospoints)
            setPosOptions(response.pospoints.map((pos) => ({ value: pos._id, label: pos.name })));
        if (!!response?.currencies)
            setCurrencyOptions(
                response.currencies.map((currency) => ({ value: currency._id, label: currency.currency }))
            );
    };

    const saveHandler = async () => {
        const { currency, date, dueDate, customer, serial, pdfLanguage, salesUnit, description, _id } = values;
        const data = {
            currency,
            customer,
            serial,
            pdfLanguage,
            salesUnit,
            description,
            date,
            dueDate,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateEstimate" : "createEstimate",
            data,
            responseData: "_id date dueDate description",
        });
        if (response?.createEstimate?._id || response?.updateEstimate?._id) {
            enqueueSnackbar(t("estimate_saved"), { variant: "default" });
            addRecordEstimate && addRecordEstimate(response.createEstimate || response?.updateEstimate);
            navigate(-1);
        }
    };

    return (
        <Modal
            fullScreen
            open
            titlelabel={t("estimate_form")}
            onClose={() => navigate(-1)}
            loading={loading}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler }}
        >
            <ActionsPanel>
                <ActionBox title={t("actions")} width={350}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Form values={values} onValuesChange={setValues}>
                                <SwitchControl label={t("estimate_for_reservation")} name="forReservation" />
                            </Form>
                        </Grid>
                        <Grid item xs={12}>
                            {values?.forReservation ? (
                                <DateRangeControl
                                    startLabel={t("checkin")}
                                    endLabel={t("checkout")}
                                    values={{ startDate: values.checkin || null, endDate: values.checkout || null }}
                                    onChange={({ startDate, endDate }) =>
                                        setValues({ ...values, checkin: startDate, checkout: endDate })
                                    }
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" fullWidth>
                                {values?.forReservation ? t("create_reservation") : t("create_invoice")}
                            </Button>
                        </Grid>
                    </Grid>
                </ActionBox>
                <ActionBox width={350} title={t("status")}>
                    <ButtonGroup fullWidth disableElevation>
                        <Button disabled>{t("draft")}</Button>
                        <Button>{t("delivered")}</Button>
                        <Button>{t("paid")}</Button>
                    </ButtonGroup>
                </ActionBox>
                <ActionBox title={t("estimate_details")} width={300}>
                    <DateRangeControl
                        startLabel={t("date")}
                        endLabel={t("due_date")}
                        values={{ startDate: values.date || null, endDate: values.dueDate || null }}
                        onChange={({ startDate, endDate }) =>
                            setValues({ ...values, date: startDate, dueDate: endDate })
                        }
                    />
                    <SelectControl name={"currency"} label={t("currency")} options={currencyOptions} />
                </ActionBox>
            </ActionsPanel>

            <div style={{ margin: "20px 0" }}>
                <Typography variant="h6">{t("estimate_details")}</Typography>
                <Divider />
            </div>
            <Form values={values} onValuesChange={setValues}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} sm={5} md={4}>
                        <Grid item xs={12}>
                            <ClientsSearch
                                onClientSelect={(data) => {
                                    setValues({ ...values, clientsData: data });
                                }}
                                onNoOptionAction={() => navigate("addClient/create")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {values?.clientsData?.IDNumber}
                                    </Typography>
                                    <Typography variant="h6" component="div">
                                        {(values?.clientsData?.firstName || "") +
                                            " " +
                                            (values?.clientsData?.lastName || "")}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {values?.clientsData?.country}
                                    </Typography>
                                    <Typography variant="body2">
                                        {(values?.clientsData?.addressLine || "") +
                                            " " +
                                            (values?.clientsData?.city || "")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={1} md={4}></Grid>
                    <Grid container item xs={12} sm={6} md={4} spacing={2}>
                        <Grid item xs={6}>
                            <SelectControl name="pdfLanguage" label={t("pdf_language")} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectControl name="salesUnit" label={t("sales_unit")} options={posOptions} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputControl multiline minRows={3} name="description" label={t("description")} />
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
            <div style={{ margin: "20px 0" }}>
                <Typography variant="h6">{t("items")}</Typography>
                <Divider />
            </div>
            <ItemsTable data={[{}, {}]} />
            <Grid mt={5} container justifyContent="flex-end">
                <Grid item xs={6}>
                    <AmountsCard />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default EstimateForm;
