import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

const options = [
    { value: "initial", label: "initial" },
    { value: "deposit", label: "deposit" },
    { value: "withdraw", label: "withdraw" },
];

const CashdeskDepositForm = () => {
    const [values, setValues] = useState({});
    const [cashDesks, setCashDesks] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "businesses",
                    responseData: "_id name nipt businessUnitCode password certificate",
                },
                {
                    endpoint: "cashdesks",
                    responseData: "_id tcrCode fiscalBusinessId",
                },
            ],
        });
        if (!!response?.businesses) {
            setBusinesses(response.businesses);
        }
        if (!!response?.cashdesks) {
            setCashDesks(response.cashdesks);
        }
    };
    useEffect(() => {
        loadExtraData();
    }, []);

    const registerCashDeposit = async () => {
        const { amount, operation, description } = values;
        const { tcrCode, fiscalBusinessId } =
            cashDesks.find((cashDesk) => cashDesk._id === `fiscalcashdesks_${params.id}`) || {};
        const { nipt, certificate, password } = businesses.find((business) => business._id === fiscalBusinessId) || {};

        const response = await fetch({
            operation: "mutation",
            endpoint: "cashDeposit",
            data: {
                amount: parseFloat(amount),
                operation: operation,
                description: description,
                tcrCode: tcrCode,
                nipt: nipt,
                certificate: certificate?.[0]?.split(";base64,")?.[1],
                certificatePassword: password,
            },
        });

        if (response?.cashDeposit === "success") {
            saveHandler();
            enqueueSnackbar(t("action_done"), { variant: "success" });
        }
    };

    const saveHandler = async () => {
        const { amount, operation, description } = values;

        const data = {
            amount: parseFloat(amount),
            operation,
            description,
            cashdeskId: params.id,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "deposit",
            data,
            responseData: "_id",
        });
        if (response?.deposit?._id) {
            enqueueSnackbar(t("fiscal_cashdesk_deposit_saved"), { variant: "default" });
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("fiscal_cashdesk_deposit")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                // rules,
                onValuesChange: setValues,
                onSubmit: registerCashDeposit,
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputControl name="amount" label={t("amount")} />
                </Grid>
                <Grid item xs={12}>
                    <SelectControl name="operation" label={t("operation")} options={options} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="description" label={t("description")} multiline minRows={2} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CashdeskDepositForm;
