import React from "react";
import { Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SubMenuLink = ({ item, t }) => {
    return (
        <li className="nav-item">
            <Link style={{ textDecoration: "none" }} to={item.href}>
                <Typography className="submenu-items" variant="subtitle1" sx={{ color: "navbarColor.contrastText" }}>
                    {t(`${item.name}`)}
                </Typography>
            </Link>
        </li>
    );
};

export default withTranslation("translations")(SubMenuLink);
