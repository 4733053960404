import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../hooks";

const BackgroundOfflineCacheSync = React.memo(() => {
    const [hasInternet, setHasInternet] = useState(navigator.onLine);

    const { loading, syncCacheWithServer, fetch } = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        const handleOnline = () => setHasInternet(true);
        const handleOffline = () => setHasInternet(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);

    useEffect(() => {
        if (!hasInternet) {
            loadOfflineData();
            return;
        }
        syncOnlineServer();
    }, [hasInternet]);

    const loadOfflineData = async () => {
        await fetch({
            operation: "query",
            endpoint: "pospoints",
            responseData: "_id",
        });
    };

    const syncOnlineServer = async () => {
        const response = await syncCacheWithServer();
        if (!response) return;
        navigate(0);
    };
    return (
        <>
            <Backdrop sx={{ zIndex: 10000 }} open={loading}>
                <CircularProgress size={60} sx={{ color: "white" }} />
            </Backdrop>
        </>
    );
});

export default BackgroundOfflineCacheSync;
