import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { InputControl } from "../form";

const GuestCategoriesCard = ({ t, id, name, values = {}, onValuesChange, currency }) => {
    return (
        <Card>
            <CardHeader title={name} titleTypographyProps={{ variant: "h6" }} />
            <CardContent>
                <InputControl
                    value={values?.price !== undefined ? values.price : ""}
                    onChange={(e) => {
                        onValuesChange &&
                            onValuesChange({
                                ...values,
                                id,
                                price: e.target.value,
                            });
                    }}
                    type="number"
                    label={t("price")}
                    toCurrency={currency}
                />
            </CardContent>
        </Card>
    );
};

export default GuestCategoriesCard;
