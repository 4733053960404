const props = {
    wrapperStyle: { outline: "none" },
    contentStyle: {
        backgroundColor: "rgb(255,255,255,0.7)",
        backdropFilter: "blur(5px)",
        borderRadius: "2px",
    },
    labelStyle: {
        color: "black",
    },
    itemStyle: {
        color: "black",
    },
    isAnimationActive: false,
};

export default props;
