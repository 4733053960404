import { createContext } from "react";

export const CreateReservationContext = createContext({
    activeStep: null,
    setActiveStep: () => {},

    values: {},
    setValues: () => {},

    data: {},
    setData: () => {},

    dailyData: {},
});
