import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { AutoCompleteControl, InputControl, SelectControl } from "../../../components/form";
import { Modal } from "../../../components/modal";
import { useApi } from "../../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};
const colorOptions = [
    { value: "#e63946", label: "red" },
    { value: "#83c5be", label: "green" },
    { value: "#fca311", label: "orange" },
    { value: "#ffffff", label: "white" },
    { value: "#fbc4ab", label: "pink" },
];

const ProductMainCategoryForm = () => {
    const [values, setValues] = useState({});
    const [productCategories, setProductCategories] = useState([]);
    const { t } = useTranslation();
    const { addRecordProductMainCategory } = useOutletContext() || {};

    const productCategoriesOptions = useMemo(
        () => productCategories.map((c) => ({ value: c._id, label: c.name })),
        [productCategories]
    );

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params?.id !== "create") loadData();
        else loadProductCategories();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "productmaincategory",
                    data: {
                        _id: "productmaincategories_" + params?.id,
                    },
                    responseData: "_id name color productCategories",
                },
                {
                    endpoint: "productcategories",
                    responseData: "_id name",
                },
            ],
        });

        if (response?.productmaincategory) setValues(response.productmaincategory);
        if (response?.productcategories) setProductCategories(response.productcategories);
    };
    const loadProductCategories = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "productcategories",
            responseData: "_id name",
        });
        if (response?.productcategories) setProductCategories(response.productcategories);
    };

    const saveHandler = async () => {
        const { name, color, productCategories, _id } = values;
        const data = {
            name,
            color,
            productCategories,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateProductMainCategory" : "createProductMainCategory",
            data,
            responseData: "_id name color productCategories",
        });
        if (response?.createProductMainCategory?._id || response?.updateProductMainCategory?._id) {
            enqueueSnackbar(t("product_main_category_saved"), { variant: "default" });
            addRecordProductMainCategory &&
                addRecordProductMainCategory(response.createProductMainCategory || response?.updateProductMainCategory);

            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("product_main_category")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
            permission={{ productmaincategories: params?.id === "create" ? { create: true } : { edit: true } }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                    <SelectControl name="color" label={t("color")} options={colorOptions} />
                    <AutoCompleteControl
                        size="large"
                        multiple
                        name="productCategories"
                        options={productCategoriesOptions}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ProductMainCategoryForm;
