import { CardHeader } from "@mui/material";
import HotelIcon from "@mui/icons-material/Hotel";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../components/table";
import { toCurrency } from "../../../utils";

const AccomodationDetailsTable = ({ data = [], currency = "", guests = [], reservation }) => {
    const { t } = useTranslation();
    const accomodationData = useMemo(() => {
        return data
            ?.map((room) => {
                let extraGuestsTotal = 0.0;
                const roomGuests = guests?.find((g) => {
                    if (room.roomId === "UNASSIGNED") return g.roomId === room.roomtypeId;
                    return g.roomId === room.roomId;
                });
                if (roomGuests?.guests?.length > 0) {
                    extraGuestsTotal += roomGuests?.guests?.reduce(
                        (acc, category) => category.price * category.extra + acc,
                        0.0
                    );
                }
                return room?.prices?.map((day) => {
                    return {
                        roomtype: room?.roomtypeData?.name || "",
                        roomname: room.roomId === "UNASSIGNED" ? t("unassigned") : room?.roomData?.name,
                        price:
                            room.customPrice !== undefined && room.customPrice !== null
                                ? toCurrency(room.customPrice)
                                : toCurrency(
                                      (day?.price || 0) + (!!reservation?.masterId ? 0 : extraGuestsTotal),
                                      currency
                                  ),
                        date: day?.date,
                        ratePlan: ["standart", "standard"].includes(day?.rateId) ? day?.rateId : day?.rateName,
                        mealPlan: ["standart", "standard"].includes(day?.rateId)
                            ? room?.roomtypeData?.meals?.join(" , ")
                            : day?.rateMeals?.join(" , "),
                    };
                });
            })
            ?.flat();
    }, [data]);

    const columns = [
        { accessorKey: "roomtype", header: t("roomtype") },
        { accessorKey: "roomname", header: t("room_name") },
        { accessorKey: "price", header: t("price") },
        { accessorKey: "date", header: t("date") },
        { accessorKey: "ratePlan", header: t("rate_plan") },
        { accessorKey: "mealPlan", header: t("meal_plan") },
    ];
    return (
        <>
            <CardHeader
                avatar={<HotelIcon />}
                title={t("accomodation_details")}
                titleTypographyProps={{ variant: "h5" }}
            />
            <Table data={accomodationData} columns={columns} disableHeader disableSelection disableDelete />
        </>
    );
};

export default AccomodationDetailsTable;
