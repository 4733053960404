import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";

import React from "react";
import { capitalize } from "../../utils";

const TagsAutoCompleteControl = ({
    options = [],
    label,
    name,
    fullWidth = true,
    size = "small",
    margin = "dense",
    variant = "outlined",
    onChange,
    value,
    error,
    helperText,
    inputProps = {},
    ...otherProps
}) => {
    const getSelectedOption = () => {
        if (otherProps.freeSolo) return value;
        if (!value) return otherProps.multiple ? [] : null;

        if (otherProps.multiple)
            return options.filter((option) => {
                if (typeof option === "object") return value.includes(option.value);
                return value.includes(option);
            });

        return (
            options.find((option) => {
                if (typeof option === "object") return option.value === value;
                return option === value;
            }) || null
        );
    };

    const toEventFormatValue = (result) => {
        return { target: { value: result } };
    };

    const handleChange = (e, newValue) => {
        if (newValue === null || typeof newValue !== "object") {
            onChange && onChange(toEventFormatValue(newValue));
            return;
        }

        if (!otherProps.multiple) {
            onChange && onChange(toEventFormatValue(newValue.value));
            return;
        }

        onChange &&
            onChange(
                toEventFormatValue(
                    newValue.map((v) => {
                        if (typeof v === "object") return v.value;
                        return v;
                    })
                )
            );
        return;
    };

    const labelText = label || capitalize(name);

    return (
        <Autocomplete
            size={size}
            fullWidth={fullWidth}
            options={options}
            value={getSelectedOption()}
            onChange={handleChange}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        <Chip
                            style={{
                                color: option?.data?.color,
                                backgroundColor: option?.data?.color + "20",
                                border: "1px solid",
                                borderColor: option?.data?.color,
                                cursor: "pointer",
                                fontWeight: 600,
                                fontSize: 14,
                            }}
                            icon={<SellIcon color={option?.data?.color} />}
                            label={option.label}
                        />
                    </li>
                );
            }}
            renderTags={(value, getTagProps) => {
                return value?.map((option, index) => (
                    <Chip
                        style={{
                            color: option?.data?.color,
                            backgroundColor: option?.data?.color + "20",
                            border: "1px solid",
                            borderColor: option?.data?.color,
                            fontWeight: 600,
                            fontSize: 14,
                        }}
                        icon={<SellIcon color={option?.data?.color} />}
                        label={option.label}
                        {...getTagProps({ index })}
                    />
                ));
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        ...inputProps,
                    }}
                    margin={margin}
                    variant={variant}
                    error={error}
                    helperText={helperText}
                    label={labelText}
                />
            )}
            {...otherProps}
        />
    );
};

export default TagsAutoCompleteControl;
