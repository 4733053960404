import { Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ReservationHandler } from "../../components/classes";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { SelectRoomsStep } from "./components/create";
import { CreateReservationContext } from "./components/create/CreateReservationContext";

const EditReservationView = () => {
    const [values, setValues] = useState({
        checkin: null,
        checkout: null,
        checkedRooms: {},
        checkedRoomtypes: {},
        isSimpleReservation: false,
        firstRender: true,
    });
    const [reservationRooms, setReservationRooms] = useState("");
    const [data, setData] = useState({
        roomtypes: [],
        currency: "",
        guestcategories: [],
        taxes: [],
    });

    const [dailyData, setDailyData] = useState({
        blockedRoomsInfo: {},
        dailyPricing: {},
    });

    const { loading, fetch } = useApi();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { onReservationEdit } = useOutletContext() || {};

    useEffect(() => {
        loadRooms();
    }, []);

    useEffect(() => {
        if (data?.roomtypes?.length > 0) loadReservationData();
    }, [data]);

    useEffect(() => {
        if (!!values.checkin && !!values.checkout) {
            loadDailyData();
        }
    }, [values.checkin, values.checkout]);

    const loadDailyData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "blockedRooms",
                    data: {
                        checkin: values.checkin,
                        checkout: values.checkout,
                        reservationException: "reservations_" + params?.id,
                    },
                    responseData: "blockedRooms blockedRoomtypesQuantity {id quantity}",
                },
                {
                    endpoint: "dailyAvailabilities",
                    data: {
                        startDate: values.checkin,
                        endDate: values.checkout,
                    },
                },
            ],
        });
        if (response) {
            setDailyData({
                blockedRoomsInfo: response.blockedRooms || {},
                dailyPricing: response.dailyAvailabilities ? JSON.parse(response.dailyAvailabilities) : {},
            });
            /**
             * Using firstRender value as hack to prevent overriding checked rooms default values
             * when nedded and then set to false!
             * EX: when this module is used inside reservation calendar daily data update must not
             * override checked rooms in the first time
             */
            if (values.firstRender) {
                setValues({ ...values, firstRender: false });
            } else setValues({ ...values, checkedRooms: {}, checkedRoomtypes: {} });
        }
    };
    const loadRooms = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomtypes",
                    responseData: `_id name price amenities meals bedtypes{id name quantity}
                        taxData{taxId isIncluded} maxCapacity includedCapacity capacity{id price}`,
                },
                {
                    endpoint: "rooms",
                    responseData: `_id name price roomtypeId taxData{taxId isIncluded}
                        maxCapacity includedCapacity capacity{id price}`,
                },
                {
                    endpoint: "roomrates",
                    responseData: "_id name roomTypes availableTo",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name priorityOrder",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
            ],
        });
        if (response) {
            setData({
                ...data,
                roomtypes: response.roomtypes?.map((rt) => {
                    rt.rooms = response.rooms?.filter((r) => r.roomtypeId === rt._id);
                    rt.rates = response.roomrates?.filter((rate) => rate.roomTypes?.includes(rt._id));
                    return rt;
                }),
                discounts: response.discounts,
                currency: response.settingsgeneral?.currencyData?.currency || "",
                guestcategories: response.guestcategories,
                taxes: response.taxes,
            });
        }
    };

    const loadReservationData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "reservation",
            data: {
                _id: "reservations_" + params.id,
            },
            responseData: `_id checkin checkout discount{type value} guests{roomId guests{number name}}
                rooms{
                    roomId 
                    roomtypeId 
                    customPrice
                    prices{
                        price rateId discount{type value}
                    }
                }
                invoices{_id}`,
        });
        if (!response?.reservation?._id) return;
        const checkedRooms = {};
        const checkedRoomtypes = {};
        let reservationRoomsInfo = [];
        const rooms = data.roomtypes?.map((rt) => rt.rooms)?.flat();
        const isSimpleReservation = response.reservation.rooms?.some((room) => room.roomId === "UNASSIGNED");
        if (isSimpleReservation) {
            response.reservation.rooms?.forEach((room) => {
                const roomtypeData = data.roomtypes.find((rt) => rt._id === room?.roomtypeId);
                let customPrice;
                let discount;
                let rateId;
                room.prices.forEach((day) => {
                    if (day.rateId === "CUSTOM") {
                        customPrice = day.price;
                    } else if (day.rateId !== "standard") {
                        rateId = day.rateId;
                    }
                    if (day.discount) discount = day.discount;
                });
                const guestsData = response.reservation.guests?.find((g) => g.roomId === room.roomtypeId);
                const roomGuests = {};
                if (guestsData) {
                    guestsData?.guests?.forEach((category) => {
                        const categoryId = data?.guestcategories?.find((c) => c.name === category.name)?._id;
                        if (categoryId) {
                            roomGuests[categoryId] = { name: category.name, number: category.number };
                        }
                    });
                }
                if (checkedRoomtypes[room.roomtypeId]) {
                    checkedRoomtypes[room.roomtypeId].quantity += 1;
                } else {
                    checkedRoomtypes[room.roomtypeId] = {
                        _id: room?.roomtypeId,
                        name: roomtypeData?.name,
                        maxCapacity: roomtypeData?.maxCapacity,
                        includedCapacity: roomtypeData?.includedCapacity,
                        capacity: roomtypeData?.capacity,
                        price: roomtypeData?.price,
                        guests: roomGuests,
                        rate: rateId,
                        customPrice,
                        discount,
                        quantity: 1,
                    };
                }
            });
        } else {
            response.reservation.rooms?.forEach((room) => {
                const roomData = rooms?.find((r) => r._id === room.roomId) || {};
                const roomtypeData = data.roomtypes.find((rt) => rt._id === roomData?.roomtypeId);
                let customPrice = room.customPrice;
                let discount;
                let rateId;
                room.prices.forEach((day) => {
                    if (day.rateId !== "standard") {
                        rateId = day.rateId;
                    }
                    if (day.discount) discount = day.discount;
                });
                const guestsData = response.reservation.guests?.find((g) => g.roomId === room.roomId);
                const roomGuests = {};
                if (guestsData) {
                    guestsData?.guests?.forEach((category) => {
                        const categoryId = data?.guestcategories?.find((c) => c.name === category.name)?._id;
                        if (categoryId) {
                            roomGuests[categoryId] = { name: category.name, number: category.number };
                        }
                    });
                }
                checkedRooms[room.roomId] = {
                    _id: room.roomId,
                    name: roomData?.name,
                    maxCapacity: roomData?.maxCapacity,
                    includedCapacity: roomData?.includedCapacity,
                    capacity: roomData?.capacity,
                    price: roomData?.price,
                    roomtypeId: roomData?.roomtypeId,
                    roomtypeName: roomtypeData?.name,
                    guests: roomGuests,
                    rate: rateId,
                    customPrice,
                    discount,
                };
                if (roomData) reservationRoomsInfo.push(roomData?.name);
            });
        }
        setValues({
            ...values,
            _id: response.reservation._id,
            checkedRooms,
            checkedRoomtypes,
            isSimpleReservation,
            discount: response.reservation.discount,
            checkin: response.reservation.checkin,
            checkout: response.reservation.checkout,
        });
        setReservationRooms(
            isSimpleReservation
                ? Object.values(checkedRoomtypes)
                      ?.map((roomtype) => `${roomtype.name} X${roomtype.quantity}`)
                      ?.join(" , ")
                : reservationRoomsInfo.join(" , ")
        );
    };

    const saveReservation = async () => {
        const roomsArray = Object.values(values.checkedRooms)?.filter((room) => !!room);
        const roomtypesArray = Object.values(values.checkedRoomtypes)?.filter((rt) => !!rt && !!rt.quantity);
        const { checkin, checkout, rooms, guests, totalPrice } = values.isSimpleReservation
            ? ReservationHandler.fromNewSimpleReservation({
                  checkin: values.checkin,
                  checkout: values.checkout,
                  roomtypes: roomtypesArray || [],
                  discount: values.discount,
                  dailyAvailabilities: dailyData.dailyPricing,
                  isSimpleReservation: true,
                  guestCategories: data.guestcategories,
              }).getReservation()
            : ReservationHandler.fromNewReservation({
                  checkin: values.checkin,
                  checkout: values.checkout,
                  rooms: roomsArray || [],
                  discount: values.discount,
                  dailyAvailabilities: dailyData.dailyPricing,
                  guestCategories: data.guestcategories,
              }).getReservation();
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: { checkin, checkout, rooms, guests, _id: values._id, totalPrice },
            responseData: `_id checkin, checkout
                rooms{
                    roomId 
                    roomtypeId 
                    customPrice 
                    prices{price rateId rateName rateMeals date discount{type value}} 
                    roomData{name} 
                    roomtypeData{
                        name _id meals taxData{taxId isIncluded}
                    }
                }
                guests{roomId guests{name number price extra}} 
                totalPrice
            `,
        });
        if (response?.updateReservation?._id) {
            enqueueSnackbar(t("reservation_saved"), { variant: "success" });
            onReservationEdit && onReservationEdit(response?.updateReservation);
            navigate(-1);
        }
    };

    const disabledSave = useMemo(() => {
        const hasCheckedRooms = Object.values(values.checkedRooms || {}).filter((r) => !!r);
        if (hasCheckedRooms.length > 0) return false;
        const hasCheckedRoomtypes = Object.values(values.checkedRoomtypes || {}).filter((r) => !!r);
        if (hasCheckedRoomtypes.length > 0) return false;
        return true;
    }, [values.checkedRooms, values.checkedRoomtypes]);

    return (
        <Modal
            titlelabel={t("edit_reservation")}
            onClose={() => navigate(-1)}
            open
            fullScreen
            yesText="none"
            noText="none"
            permission={{ reservations: { edit: true } }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography>
                        {t("rooms")}: {reservationRooms}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CreateReservationContext.Provider
                        value={{ values, setValues, dailyData, data, setData: () => {} }}
                    >
                        <SelectRoomsStep loading={loading} />
                    </CreateReservationContext.Provider>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="end"
                    style={{ position: "fixed", bottom: 17, backgroundColor: "#ffffff" }}
                >
                    <Button variant="outlined" size="large" onClick={() => navigate(-1)}>
                        {t("cancel")}
                    </Button>
                    <Button
                        onClick={saveReservation}
                        sx={{ marginLeft: 2 }}
                        size="large"
                        variant="contained"
                        disabled={disabledSave}
                    >
                        {t("save")}
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default EditReservationView;
