import { Card, CardContent, CardHeader, Chip, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import { useTranslation } from "react-i18next";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { DateTime } from "luxon";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    CHECKIN: "#59C086", //checked in
    CHECKOUT: "#FFC82C", //checked out
    CANCELLED: "#FF4949", //canceled
    APPROVED: "#C0CCDA", // on hold
};

const TimelineView = ({ logs = [] }) => {
    const { t } = useTranslation();
    return (
        <Timeline position="alternate">
            {logs?.reverse().map((log) => (
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {DateTime.fromISO(log.timestamp)?.toFormat("dd LLL yyyy HH:mm")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <TimelineCard
                            t={t}
                            title={log.title}
                            user={log.userId?.split("users_")?.[1]}
                            updatedFields={log?.updatedFields}
                        />
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default TimelineView;

const TimelineCard = ({ title, t, user, updatedFields }) => {
    return (
        <Card>
            <CardHeader
                title={t(title)}
                titleTypographyProps={{ variant: "h6" }}
                subheader={` ${t("by")} ${user}`}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                //action={<Button>{t("view")}</Button>}
            />
            <CardContent>
                {updatedFields &&
                    updatedFields?.map((field) => {
                        if (field?.field === "status") {
                            return (
                                <Stack direction="row" alignItems="center">
                                    <Chip
                                        sx={{ backgroundColor: statusColors[field?.values?.previous] }}
                                        label={t(field?.values?.previous)}
                                    />
                                    <ArrowForwardIcon />
                                    <Chip
                                        sx={{ backgroundColor: statusColors[field?.values?.current] }}
                                        label={t(field?.values?.current)}
                                    />
                                </Stack>
                            );
                        } else {
                            return (
                                <Stack direction="row" alignItems="center">
                                    <Typography>{field?.values?.previous}</Typography>
                                    <ArrowForwardIcon />
                                    <Typography>{field?.values?.current}</Typography>
                                </Stack>
                            );
                        }
                    })}
            </CardContent>
        </Card>
    );
};
