import { Card, CardContent, CardHeader, Grid, ListItemText, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputControl } from "../../../../components/form";
import { useApi } from "../../../../components/hooks";
import { Modal } from "../../../../components/modal";

const DailyReservationEditConfirm = ({
    open = false,
    reservation = {},
    currentRoom,
    newRoom,
    onConfirm,
    onCancel,
    changes = {},
    startDate,
    endDate,
}) => {
    const { t } = useTranslation();
    const [selectedPriceOption, setSelectedPriceOption] = useState("keep");
    const [newPrice, setNewPrice] = useState(null);

    const { prices = [] } = reservation?.rooms?.find((room) => room.roomId === currentRoom?._id) || {};
    const { guests = [] } = reservation?.guests?.find((roomGuests) => roomGuests.roomId === currentRoom?._id) || {};

    return (
        <Modal
            open={open}
            maxWidth="md"
            titlelabel={t("edit_reservation")}
            yesText={t("confirm")}
            onSave={() => {
                onConfirm &&
                    onConfirm({
                        keepPrice: selectedPriceOption === "keep",
                        updatedPrice: parseFloat(newPrice),
                    });
            }}
            onClose={onCancel}
        >
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <ReservationDetails reservation={reservation} roomGuests={guests} changes={changes} />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <PriceInfoCard
                        isExisting
                        selected={selectedPriceOption === "keep"}
                        onClick={() => setSelectedPriceOption("keep")}
                        price={prices?.[0]?.price}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    {reservation?.invoices?.length > 0 ? (
                        <Typography>
                            {t("closed_invoice")} <br />
                            {t("no_price_change_allowed")}
                        </Typography>
                    ) : (
                        <PriceInfoCard
                            selected={selectedPriceOption === "update"}
                            onClick={() => setSelectedPriceOption("update")}
                            oldPrice={prices?.[0]?.price}
                            onPriceChange={setNewPrice}
                            price={newPrice}
                        />
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DailyReservationEditConfirm;

const ReservationDetails = ({ reservation = {}, roomGuests = [], changes = {} }) => {
    const { t } = useTranslation();
    const clientName = reservation?.clientsData?.[0]
        ? (reservation?.clientsData?.[0].firstName || "") + " " + (reservation?.clientsData?.[0].lastName || "")
        : "";
    const guestsDetails = () => {
        const guestsCategoriesInfo = roomGuests?.reduce((acc, category) => {
            const existingCategory = acc.find((c) => c.name === category.name);
            if (!existingCategory) {
                acc.push({ name: category.name, number: category.number });
                return acc;
            }
            existingCategory.number += category.number;
            return acc;
        }, []);
        return guestsCategoriesInfo?.map((c) => `${c.number} ${c.name}`)?.join(" , ");
    };

    return (
        <Stack>
            <ListItemText
                primary={clientName}
                primaryTypographyProps={{ variant: "h6", color: "primary" }}
                secondary={`${reservation?.checkinTime} - ${reservation?.checkoutTime}`}
                secondaryTypographyProps={{ variant: "body1", color: "primary" }}
            />
            <ListItemText
                primary={t("guests")}
                primaryTypographyProps={{ variant: "body" }}
                secondary={guestsDetails() || ""}
                secondaryTypographyProps={{ variant: "h6", color: "primary" }}
            />
            <ListItemText
                primary={t("source")}
                primaryTypographyProps={{ variant: "body" }}
                secondary={reservation?.bookingSource}
                secondaryTypographyProps={{ variant: "h6", color: "primary" }}
            />
            {changes?.field === "room" ? (
                <ListItemText
                    primary={t("change_type") + " : " + t(changes?.field)}
                    primaryTypographyProps={{ variant: "body" }}
                    secondary={`${changes?.currentValue} -> ${changes?.newValue}`}
                    secondaryTypographyProps={{ variant: "h6", color: "primary" }}
                />
            ) : (
                <ListItemText
                    primary={t("change_type") + " : " + t(changes?.field)}
                    primaryTypographyProps={{ variant: "body" }}
                    secondary={`${DateTime.fromISO(changes?.currentValue).toFormat("HH:mm")} -> ${DateTime.fromISO(
                        changes?.newValue
                    ).toFormat("HH:mm")}`}
                    secondaryTypographyProps={{ variant: "h6", color: "primary" }}
                />
            )}
        </Stack>
    );
};

const PriceInfoCard = ({ isExisting = false, selected = false, onClick, price, oldPrice, onPriceChange }) => {
    const { t } = useTranslation();
    const toFloatNewPrice = isNaN(parseFloat(price)) ? 0.0 : parseFloat(price);
    const toFloatOldPrice = isNaN(oldPrice) ? 0.0 : oldPrice;
    return (
        <Card sx={{ cursor: "pointer", border: selected && "3px solid" }} onClick={onClick}>
            <CardHeader
                title={t(isExisting ? "keep_price_calendar" : "update_price")}
                titleTypographyProps={{ color: "primary" }}
            />
            <CardContent sx={{ height: 100 }}>
                {!isExisting && (
                    <InputControl
                        value={price}
                        onChange={(e) => onPriceChange(e.target.value)}
                        label={t("new_price")}
                    />
                )}
                {!isExisting && (
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant="subtitle1">{t("amount_change")}</Typography>
                        <Typography variant="h6" color="primary">
                            {toFloatOldPrice > toFloatNewPrice ? "" : "+"}{" "}
                            {(toFloatNewPrice - toFloatOldPrice)?.toFixed(2)}
                        </Typography>
                    </Stack>
                )}
            </CardContent>
            <CardContent>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Typography variant="subtitle1">{t("total")}</Typography>
                    <Typography variant="h6" color="primary">
                        {toFloatNewPrice?.toFixed(2)}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};
