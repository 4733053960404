import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../components/hooks";
import { TagsAutoCompleteControl } from "../../../components/form";
import { Modal } from "../../../components/modal";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const TagsModal = ({ reservation, onConfirm }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openModal: false,
        tags: [],
        selectedTags: null,
    });

    const { fetch, loading } = useApi();

    useEffect(() => {
        if (!state.openModal) return;
        if (state.tags?.length > 0) return;
        loadTags();
    }, [state.openModal, state.tags]);

    const loadTags = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "tags",
            responseData: "_id name color description",
        });
        setState({
            ...state,
            tags: response.tags.map((tag) => ({ value: tag._id, label: tag.name, data: tag })),
        });
    };

    const changeTag = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: { _id: reservation._id, tags: state.selectedTags },
            responseData: "_id tagsData{_id name color description}",
        });
        if (response?.updateReservation?._id) {
            setState({ ...state, openModal: false });
            onConfirm(response?.updateReservation);
        }
    };

    return (
        <>
            <Button startIcon={<ControlPointIcon />} onClick={() => setState({ ...state, openModal: true })}>
                {reservation?.tagsData?.length > 0 ? t("edit") : t("add_tags")}
            </Button>
            <Modal
                loading={loading}
                maxWidth="xs"
                open={state.openModal}
                onClose={() => setState({ ...state, openModal: false })}
                onSave={changeTag}
                noText="none"
                yesText={t("confirm")}
                yesDisabled={loading}
            >
                <TagsAutoCompleteControl
                    multiple
                    options={state.tags}
                    onChange={(e) => setState({ ...state, selectedTags: e.target.value })}
                    value={
                        !!state.selectedTags ? state.selectedTags : reservation?.tagsData?.map((tag) => tag._id) || []
                    }
                    size="large"
                />
            </Modal>
        </>
    );
};
export default TagsModal;
