import { Avatar, ButtonBase, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { getLoggedUserData } from "../../../utils";
import { ModeSwitcher } from "../../common";

const defaultAvatarImage = "https://img.icons8.com/color/48/000000/user-skin-type-7.png";

const ProfileButton = ({ t }) => {
    const [anchorMenu, setAnchorMenu] = useState(null);

    const userData = getLoggedUserData();

    const handleProfileClick = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleProfileMenuClose = () => {
        setAnchorMenu(null);
    };

    const items = [
        { label: "my_turn", href: "/nightaudit" },
        { label: "my_account", href: `/accounts/users/${userData?.username}` },
        { label: "logout", href: `/login` },
    ];

    return (
        <>
            <ButtonBase
                onClick={handleProfileClick}
                aria-owns={anchorMenu ? "profile-menu" : null}
                style={{ marginRight: 7, padding: 6 }}
            >
                <Typography variant="subtitle1" style={{ marginRight: 10 }} sx={{ color: "navbarColor.contrastText" }}>
                    {userData?.displayName || ""}
                </Typography>
                <Avatar alt="Admin" style={{ width: 30, height: 30 }}>
                    <img src={defaultAvatarImage} alt="profile" width="30px" />
                </Avatar>
            </ButtonBase>
            <Menu id="profile-menu" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={handleProfileMenuClose}>
                {items.map((item) => (
                    <MenuItem key={item.href} onClick={handleProfileMenuClose} component={Link} to={item.href}>
                        {t(item.label)}
                    </MenuItem>
                ))}
                <MenuItem>
                    <ModeSwitcher />
                </MenuItem>
            </Menu>
        </>
    );
};

export default withTranslation("translations")(ProfileButton);
