import { Button, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import HotelIcon from "@mui/icons-material/Hotel";
import { DateTime } from "luxon";
import { Stack } from "@mui/system";
import { toCurrency } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../components/modal";
import { AutoCompleteControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { useSnackbar } from "notistack";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ChainReservationsMenu from "./view/ChainReservationsMenu";
import TagsModal from "./TagsModal";
import TagsChip from "../../../components/common/TagsChip";
import otaIcons from "../../../assests/channels";
import EditRefererModal from "./view/EditRefererModal";

const ReservationDetailsCard = ({ reservation = {}, loading, setReservationData, currencyConvert, disableEdit }) => {
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const diff = useMemo(() => {
        if (!reservation.checkin || !reservation.checkout) return 0;
        if (!reservation.isDailyUse) {
            return DateTime.fromISO(reservation.checkout).diff(DateTime.fromISO(reservation.checkin), "days").toObject()
                .days;
        }
        return parseInt(
            DateTime.fromISO(reservation.checkout + "T" + reservation.checkoutTime)
                .diff(DateTime.fromISO(reservation.checkin + "T" + reservation.checkinTime), "hours")
                .toObject().hours
        );
    }, [reservation.checkin, reservation.checkout, reservation.isDailyUse]);

    const guestsDetails = useMemo(() => {
        const guestsCategoriesInfo = reservation?.guests
            ?.map((room) => room.guests)
            ?.flat()
            ?.reduce((acc, category) => {
                const existingCategory = acc.find((c) => c.name === category.name);
                if (!existingCategory) {
                    acc.push({ name: category.name, number: category.number });
                    return acc;
                }
                existingCategory.number += category.number;
                return acc;
            }, []);
        return {
            guestsCategoriesInfo,
            totalGuests: guestsCategoriesInfo?.reduce((total, category) => total + category.number, 0),
        };
    }, [reservation.guests]);

    const roomTypeDetails = useMemo(() => {
        return reservation.rooms
            ?.map((room) => room?.roomtypeData)
            .reduce((acc, roomtype) => {
                const existingRoomtype = acc.find((rt) => rt._id === roomtype?._id);
                if (existingRoomtype) {
                    existingRoomtype.number += 1;
                    return acc;
                }
                acc.push({ ...roomtype, number: 1 });
                return acc;
            }, []);
    }, [reservation.rooms]);

    const amountsInfo = useMemo(() => {
        let totalPrice = 0.0;
        let paid = 0.0;
        let unpaid = 0.0;
        const activeInvoices = reservation?.invoices?.filter((invoice) => {
            return !["CANCELLED", "TRANSFERRED"].includes(invoice?.status);
        });
        const orders = reservation?.orders?.filter((order) => !["CANCELLED", "TRANSFERRED"].includes(order?.status));
        if (reservation?.invoices?.length > 0) {
            activeInvoices?.forEach((invoice) => {
                totalPrice += currencyConvert?.convertAmount({
                    amount: invoice.totalPrice,
                    from: invoice.invoiceCurrency,
                    to: reservation?.currency,
                });

                paid +=
                    invoice?.payments?.reduce(
                        (acc, payment) =>
                            acc +
                            currencyConvert?.convertAmount({
                                amount: payment.amount,
                                from: invoice.invoiceCurrency,
                                to: reservation?.currency,
                            }),
                        0.0
                    ) || 0.0;
            });
        } else {
            totalPrice += reservation?.status === "CANCELLED" ? 0.0 : reservation?.totalPrice || 0.0;
            paid +=
                reservation?.status === "CANCELLED"
                    ? 0.0
                    : reservation?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
        }
        orders?.forEach((order) => {
            totalPrice += currencyConvert?.convertAmount({
                amount: order.totalPrice,
                from: order?.pospointData?.defaultCurrency,
                to: reservation?.currency,
            });
            if (!!order?.payments?.cashReceived && ["paid", "partly_paid"].includes(order?.payments?.paymentStatus)) {
                paid += currencyConvert?.convertAmount({
                    amount: order?.payments?.cashReceived,
                    from: order?.pospointData?.defaultCurrency,
                    to: reservation?.currency,
                });
            }
        });
        const parsedPaidAmount = parseFloat(parseFloat(paid).toFixed(2));
        const parsedTotalAmount = parseFloat(parseFloat(totalPrice).toFixed(2));

        unpaid = totalPrice - paid;
        const parsedUnpaidAmount = parseFloat(parseFloat(unpaid).toFixed(2));
        if (unpaid < 0) unpaid = 0.0;
        return {
            totalPrice: isNaN(parsedTotalAmount) ? 0.0 : parsedTotalAmount,
            paid: isNaN(parsedPaidAmount) ? 0.0 : parsedPaidAmount,
            unpaid: isNaN(parsedUnpaidAmount) ? 0.0 : parsedUnpaidAmount,
        };
    }, [reservation, currencyConvert]);

    if (loading) return <TemplateCard t={t} />;

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<HotelIcon />}
                    title={t("reservation") + ` #${reservation?.uuid}`}
                    titleTypographyProps={{ variant: "h5" }}
                    action={
                        reservation?.groupId ? (
                            <Button
                                variant="outlined"
                                onClick={() => navigate(`/groups/profile/${reservation?.groupId?.split("groups_")[1]}`)}
                            >
                                {t("view_group")}
                            </Button>
                        ) : (
                            <Button variant="outlined" disabled={disableEdit} onClick={() => setOpenGroupModal(true)}>
                                {t("add_reservation_to_group")}
                            </Button>
                        )
                    }
                />

                <CardContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12}
                        marginBottom={2}
                    >
                        {reservation?.chainReservations?.length > 0 ? (
                            <ChainReservationsMenu reservations={reservation?.chainReservations} />
                        ) : (
                            <span />
                        )}{" "}
                    </Grid>

                    <Grid container spacing={2}>
                        <DetailBoxBig
                            color="#DFF4CE"
                            title={t("check_in")}
                            bigText={DateTime.fromISO(reservation?.checkin).toFormat("dd")}
                            secondary={DateTime.fromISO(reservation?.checkin).toFormat(" LLL yyyy")}
                        />
                        <DetailBoxBig
                            color="#FFF1D8"
                            title={t("check_out")}
                            bigText={DateTime.fromISO(reservation?.checkout).toFormat("dd")}
                            secondary={DateTime.fromISO(reservation?.checkout).toFormat(" LLL yyyy")}
                        />
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("LOS")}
                            bigText={diff}
                            secondary={!reservation.isDailyUse ? (diff === 1 ? t("night") : t("nights")) : t("hours")}
                        />
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("source")}
                            bigText={
                                <img
                                    height={30}
                                    width={30}
                                    src={`${
                                        otaIcons[
                                            reservation?.bookingSource === "channel_manager"
                                                ? reservation?.referer
                                                : reservation?.bookingSource
                                        ]
                                    }`}
                                />
                            }
                            secondary={reservation?.bookingSource ? t(reservation?.bookingSource) : "---"}
                        />
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("arrival")}
                            bigText={
                                reservation.isDailyUse
                                    ? reservation.checkinTime
                                    : reservation.arrivalTime || <HistoryToggleOffIcon />
                            }
                            secondary={t("time")}
                        />
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("departure")}
                            bigText={
                                reservation.isDailyUse
                                    ? reservation.checkoutTime
                                    : reservation.departureTime || <HistoryToggleOffIcon />
                            }
                            secondary={t("time")}
                        />
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("referer")}
                            bigText={
                                reservation?.bookingSource === "reception" && !!reservation?.refererData?.firstName
                                    ? reservation?.refererData?.firstName
                                    : reservation?.bookingSource !== "reception" && !!reservation.referer
                                    ? reservation.referer
                                    : "---"
                            }
                            action={
                                reservation?.bookingSource === "reception" && (
                                    <EditRefererModal
                                        refererName={reservation?.refererData?.firstName}
                                        reservationId={reservation._id}
                                        setReservationData={setReservationData}
                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("reservation_date")}
                            bigText={DateTime.fromISO(reservation?._id?.split("reservations_")[1]).toFormat("dd LLL")}
                            secondary={DateTime.fromISO(reservation?._id?.split("reservations_")[1]).toFormat(
                                "yyyy HH:mm:ss"
                            )}
                        />
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("total")}
                            bigText={guestsDetails?.totalGuests}
                            secondary={t("guests")}
                        />
                        {guestsDetails?.guestsCategoriesInfo?.map((category) => (
                            <DetailBoxBig
                                color="#F8F9FD"
                                title={t(category.name)}
                                bigText={category.number}
                                secondary={t("guests")}
                            />
                        ))}
                        {roomTypeDetails?.map((rt) => (
                            <DetailBoxBig color="#F8F9FD" title={t("rooms")} bigText={rt.number} secondary={rt.name} />
                        ))}
                        {reservation?.reservationCustomDetails?.length > 0 &&
                            reservation?.reservationCustomDetails?.map((field) => (
                                <DetailBoxBig color="#F8F9FD" title={field.type} bigText={field.value} />
                            ))}
                    </Grid>
                    <Stack direction="row" gap={2} alignItems="center" marginBottom={5}>
                        {reservation?.tagsData?.map((tag) => {
                            return <TagsChip color={tag?.color} name={t(tag?.name)} />;
                        })}
                        <TagsModal reservation={reservation} onConfirm={setReservationData} />
                    </Stack>

                    <Divider style={{ marginBottom: 10 }} />
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <DetailBoxBig
                                color="rgb(64, 72, 92, 0.2)"
                                margin={false}
                                title={t("total_amount")}
                                bigText={toCurrency(amountsInfo?.totalPrice || 0, reservation?.currency)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DetailBoxBig
                                color="rgba(19, 206, 102, 0.2)"
                                margin={false}
                                title={t("paid_amount")}
                                bigText={toCurrency(amountsInfo?.paid || 0, reservation?.currency)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DetailBoxBig
                                color="rgba(255, 73, 73, 0.2)"
                                margin={false}
                                title={t("unpaid")}
                                bigText={toCurrency(amountsInfo?.unpaid || 0, reservation?.currency)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <GroupModal
                open={openGroupModal}
                reservation={reservation}
                onClose={(groupId) => {
                    setOpenGroupModal(false);
                    if (groupId) setReservationData({ groupId });
                }}
            />
        </>
    );
};

const GroupModal = ({ open, onClose, reservation }) => {
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [groupInput, setGroupInput] = useState();
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (open) loadData();
    }, [open]);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "groups",
            responseData: "_id name",
        });

        if (response?.groups)
            setGroups(
                response.groups?.map((group) => ({
                    value: group._id,
                    label: group.name,
                }))
            );
    };
    const addGroupToReservation = async (groupId) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: {
                _id: reservation._id,
                groupId: groupId,
            },
            responseData: "_id",
        });
        if (response?.updateReservation?._id) {
            enqueueSnackbar(t("reservation_added_to_group"), { variant: "default" });
            onClose(groupId);
        }
    };
    const createGroup = async (groupName) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "createGroup",
            data: {
                name: groupName,
                clients: reservation?.clients,
                notes: reservation?.notes,
            },
            responseData: "_id name",
        });
        if (response?.createGroup?._id) {
            addGroupToReservation(response?.createGroup?._id);
        }
    };

    return (
        <Modal
            onSave={() => addGroupToReservation(selectedGroup)}
            loading={loading}
            titlelabel={t("groups")}
            maxWidth="xs"
            open={open}
            onClose={() => {
                onClose();
                setGroupInput("");
                setSelectedGroup("");
            }}
        >
            <AutoCompleteControl
                value={selectedGroup}
                options={groups || []}
                onChange={(e) => setSelectedGroup(e.target.value)}
                onInputChange={(e, input) => setGroupInput(input)}
                label={t("group")}
                noOptionsText={
                    <Button variant="contained" onClick={() => createGroup(groupInput)}>
                        {t("create_new")} {groupInput}
                    </Button>
                }
            />
            {/* <AutoCompleteControl
                label={t("select_group")}
                options={groups}
                value={selectedGroup || ""}
                onChange={(e) => setSelectedGroup(e.target.value)}
            /> */}
        </Modal>
    );
};

const DetailBoxBig = ({ title, secondary, color, bigText, margin, action }) => {
    return (
        <Card style={{ backgroundColor: color, marginLeft: margin === false ? 0 : 20, border: "none" }}>
            <CardContent style={{ padding: 15 }}>
                <Typography variant="body2">{title}</Typography>
                <Typography variant="h5">{bigText}</Typography>
                <Typography variant="subtitle2">
                    {secondary}
                    {action}
                </Typography>
            </CardContent>
        </Card>
    );
};

const TemplateCard = ({ t }) => {
    return (
        <Card>
            <CardHeader
                avatar={<HotelIcon />}
                title={t("reservation_details")}
                titleTypographyProps={{ variant: "h5" }}
            />
            <CardContent>
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                </Stack>
                <Stack direction="row" justifyContent="space-between" mt={1} spacing={4}>
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                </Stack>
                <Stack direction="row" justifyContent="space-between" mt={1} spacing={4}>
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ReservationDetailsCard;
