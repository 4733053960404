import React from "react";
import { useTranslation } from "react-i18next";
import { SwitchControl } from "../form";

const ModeSwitcher = () => {
    const { t } = useTranslation();
    const mode = localStorage.getItem("mode");
    return (
        <SwitchControl
            label={t("dark_mode")}
            value={mode && mode === "dark"}
            onChange={(e) => {
                localStorage.setItem("mode", e.target.checked ? "dark" : "light");
                window.location.reload();
            }}
        />
    );
};

export default ModeSwitcher;
