import { Card, CardContent, CardHeader, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import React from "react";
import { useTranslation } from "react-i18next";

const DashboardSalesCard = ({
    todayTotal,
    yesterdayTotal,
    trendingUp = false,
    variant = "total",
    currency = "ALL",
    loading = false,
}) => {
    const { t } = useTranslation();
    return (
        <Card style={{ height: "100%" }} elevation={0} variant="outlined">
            <CardHeader
                avatar={<AttachMoneyIcon />}
                title={t(`${variant}_sales`)}
                titleTypographyProps={{ variant: "body" }}
            />
            {loading ? (
                <SkeletonVariant />
            ) : (
                <ListItem
                    secondaryAction={
                        trendingUp ? (
                            <TrendingUpIcon fontSize="large" color="success" />
                        ) : (
                            <TrendingDownIcon fontSize="large" color="error" />
                        )
                    }
                >
                    <ListItemText
                        primary={`${variant === "gross" ? currency : ""} ${todayTotal}`}
                        primaryTypographyProps={{ variant: "h6" }}
                        secondary={`${t("yesterday")} ${variant === "gross" ? currency : ""} ${yesterdayTotal}`}
                        secondaryTypographyProps={{ variant: "body2" }}
                    />
                </ListItem>
            )}
        </Card>
    );
};

const SkeletonVariant = () => {
    return (
        <CardContent>
            <Typography variant="h6">
                <Skeleton />
            </Typography>
            <Typography variant="body2">
                <Skeleton />
            </Typography>
        </CardContent>
    );
};

export default DashboardSalesCard;
