import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const TaxList = () => {
    const [taxes, setTaxes] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "rate", header: t("rate") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "taxes",
            responseData: "_id name rate",
        });
        if (response?.taxes) setTaxes(response.taxes);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteTaxes" : "deleteTax",
            data,
        });
        if (response?.deleteTaxes === "success" || response?.deleteTax === "success") {
            setTaxes(taxes.filter((tax) => !rows.some((row) => row._id === tax._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addRecordTax = (data) => {
        if (taxes?.some((r) => r._id === data._id)) {
            setTaxes(
                taxes?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setTaxes([...taxes, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("tax_form")}
                moduleType="finance"
                data={taxes}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("taxes_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordTax }} />
        </div>
    );
};

export default TaxList;
