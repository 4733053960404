import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputControl, RadioGroupControl, SelectControl } from "../../../components/form";
import { Modal } from "../../../components/modal";
import { PospointTable } from ".";

const rules = {
    tableName: "string|required",
    tableView: "string|required",
    tableColor: "string|required",
    tableSize: "string|required",
    tableBorder: "string|required",
    tableShape: "string|required",
};

const TableForm = ({ open, onCancel, values, setValues, onSave, tableViews = [] }) => {
    const { t } = useTranslation();
    const colorOptions = [
        { value: "#7EC8E3", label: t("blue") },
        { value: "#59981A", label: t("green") },
        { value: "#EF7C8E", label: t("pink") },
        { value: "#9f86c0", label: t("violet") },
        { value: "#a98467", label: t("brown") },
    ];
    const sizeOptions = [
        { value: "big", label: t("big") },
        { value: "medium", label: t("medium") },
        { value: "small", label: t("small") },
        { value: "mini", label: t("mini") },
    ];
    const borderOptions = [
        { value: "circle", label: t("circle") },
        { value: "rectangular", label: t("rectangular") },
    ];
    const shapeOptions = [
        { value: "square", label: t("square") },
        { value: "rectangularH", label: t("rect. Horizontal") },
        { value: "rectangularV", label: t("rect. Vertical") },
    ];

    return (
        <Modal
            open={open}
            yesText={t("add_table")}
            maxWidth="md"
            onClose={onCancel}
            FormProps={{
                values,
                onValuesChange: setValues,
                rules,
                onSubmit: onSave,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <InputControl name="tableName" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl options={tableViews} name="tableView" label={t("layout_view")} />
                </Grid>
                <Grid container item xs={6} spacing={2}>
                    <Grid item xs={12}>
                        <SelectControl options={colorOptions} name="tableColor" label={t("color")} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupControl options={sizeOptions} name="tableSize" label={t("size")} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupControl options={borderOptions} name="tableBorder" label={t("table_border")} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupControl options={shapeOptions} name="tableShape" label={t("table_shape")} />
                    </Grid>
                </Grid>
                <Grid container item xs={6} justifyContent="center" alignItems="center">
                    <PospointTable table={values} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default TableForm;
