import { Button, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { ProtectedAction } from "../../../../utils";
import { useTranslation } from "react-i18next";

const SubmitButton = ({ permission, onClick, size, variant }) => {
    const { t } = useTranslation();
    return (
        <ProtectedAction permission={permission}>
            <Button
                size={size || "large"}
                style={{ marginLeft: "auto", marginRight: 0 }}
                variant={variant || "contained"}
                color="secondary"
                onClick={onClick}
            >
                {t("save")}
            </Button>
        </ProtectedAction>
    );
};

export default SubmitButton;
