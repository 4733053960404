import { Box, Button, Divider, Grid, LinearProgress, Skeleton, Stack, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PaymentsIcon from "@mui/icons-material/Payments";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PrintIcon from "@mui/icons-material/Print";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Modal } from "../../components/modal";
import { Table } from "../../components/table";
import { CancelRecordConfirm, PriceInformationCard } from "../../components/common";
import { useApi } from "../../components/hooks";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { sendFiscalInvoice } from "../../components/integrations/fiscal";
import { ProtectedAction, generateSameTaxes } from "../../utils";
import { print, printFiscal } from "../../components/pdf/printReceipt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import { SelectControl } from "../../components/form";
import { EditOrderItemsModal, EditPaymentMethodModal } from "./components";

const calculateTotalDiscount = (totalPrice = 0, discountType, discountValue) => {
    if (!discountType || !discountValue) return 0;
    const discountAmount =
        discountType === "total" ? parseFloat(discountValue) : totalPrice * parseFloat(discountValue) * 0.01;
    if (isNaN(discountAmount)) return 0;
    return discountAmount;
};

const OrderView = () => {
    const [order, setOrder] = useState({});
    const [settingsProperty, setSettingsProperty] = useState({});
    const [settingsGeneral, setSettingsGeneral] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { onOrderChange } = useOutletContext() || {};

    const columns = [
        {
            id: "product",
            header: t("product"),
            cell: ({ row: { original } }) => {
                if (!original?.productData) {
                    return t("product_not_found");
                } else return original?.productData?.name;
            },
        },
        { id: "price", header: t("price"), cell: ({ row: { original } }) => original?.price },
        { id: "quantity", header: t("quantity"), cell: ({ row: { original } }) => original?.quantity },
        {
            id: "total",
            header: t("total"),
            cell: ({ row: { original } }) => (original?.price * original?.quantity)?.toFixed(2),
        },
    ];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "order",
                    data: {
                        _id: "orders_" + params.id,
                    },
                    responseData: `
                        _id 
                        status
                        cancelReason
                        updatedInventory
                        number
                        customerData{firstName lastName}
                        createdByData{displayName}
                        disableTransfer
                        discountType
                        discountValue
                        subtotal
                        totalPrice
                        createdAt
                        createdBy
                        tableId
                        note
                        hideNoteOnReceipt
                        discountData{name discountValue discountType}
                        pospointData{
                            _id defaultCurrency name warehouses 
                            cashdeskData{fiscalBusinessId} 
                            currencyData{currency rate} 
                            courseManagement alwaysFinalize saveOptions tables{tableName _id} 
                            printOptions{
                                fiscal{printingOption printer} 
                                nonFiscal{printingOption printer} 
                                waiterReport{printingOption printer} 
                            }
                            activeMethodsData{_id name method}
                            differentTotalCurrencies
                        } 
                        payments{timestamp paymentMethod paymentMethodData{name method} paymentStatus cashReceived}
                        products{
                            productId
                            productData{name measureUnit deductionFactor warehouseIds taxData{taxId isIncluded tax{taxCategory rate} }}
                            price
                            quantity
                            note
                        } 
                        fiscalData{
                            IICRef
                            IssueDateTime
                            InvOrdNum
                            TCR
                            operator
                            nipt
                            nivf
                            nslf
                            BusinUnitCode
                            SumInvIICRefs{IIC IssueDateTime}
                        }
                        cancelFiscalData{
                            IICRef
                            IssueDateTime
                            InvOrdNum
                            TCR
                            operator
                            nipt
                            nivf
                            nslf
                            BusinUnitCode
                        }
                     `,
                },
                {
                    endpoint: "settingsproperty",
                    responseData: "_id address city zip country name",
                    data: { _id: "settings_property" },
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency rate}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency rate",
                },
            ],
        });
        if (response?.order) setOrder(response.order);
        if (response?.settingsproperty) setSettingsProperty(response.settingsproperty);
        if (response?.settingsgeneral) setSettingsGeneral(response.settingsgeneral);
        if (response?.currencies) setCurrencies(response.currencies);
    };

    const cancelFiscalOrder = async () => {
        const items = order?.products?.map((product) => {
            return {
                name: product?.productData?.name,
                price: product?.price,
                quantity: product?.quantity,
                unit: product?.productData?.measureUnit,
                taxData: product?.productData?.taxData?.map((t) => {
                    return {
                        isIncluded: t.isIncluded,
                        taxId: t.taxId,
                    };
                }),
            };
        });
        const payments = order?.payments?.paymentMethod
            ? [
                  {
                      paymentMethod: order?.payments?.paymentMethod,
                      amount: order?.payments?.cashReceived,
                  },
              ]
            : [];
        const data = {
            fetch,
            fiscalBusinessId: order?.pospointData?.cashdeskData?.fiscalBusinessId,
            items: items,
            totalPrice: order?.totalPrice,
            payments,
            paymentMethod: "ORDER",
            cancelled: true,
            existingFiscalData: order?.fiscalData,
            isFiscalCurrencyRateSpecified: false,
        };
        if (!!order?.pospointData?.currencyData) {
            data.invoiceCurrency = order?.pospointData?.currencyData?.currency;
        } else if (!!settingsGeneral?.currencyData) {
            data.invoiceCurrency = settingsGeneral?.currencyData?.currency;
        } else {
            data.invoiceCurrency = "ALL";
        }
        data.fiscalCurrency = data.invoiceCurrency;
        return await sendFiscalInvoice(data);
    };

    const finalizeOrder = async () => {
        const items = order?.products?.map((product) => {
            return {
                name: product?.productData?.name,
                price: product?.price,
                quantity: product?.quantity,
                unit: product?.productData?.measureUnit,
                taxData: product?.productData?.taxData?.map((t) => {
                    return {
                        isIncluded: t.isIncluded,
                        taxId: t.taxId,
                    };
                }),
            };
        });
        const payments = order?.payments?.paymentMethod
            ? [
                  {
                      paymentMethod: order?.payments?.paymentMethod,
                      amount: order?.payments?.cashReceived,
                  },
              ]
            : [];
        try {
            const data = {
                fetch,
                fiscalBusinessId: order?.pospointData?.cashdeskData?.fiscalBusinessId,
                items: items,
                totalPrice: order?.totalPrice,
                payments,
                cancelled: false,
                isFiscalCurrencyRateSpecified: false,
            };
            if (!!order?.pospointData?.currencyData) {
                data.invoiceCurrency = order?.pospointData?.currencyData?.currency;
            } else if (!!settingsGeneral?.currencyData) {
                data.invoiceCurrency = settingsGeneral?.currencyData?.currency;
            } else {
                data.invoiceCurrency = "ALL";
            }
            data.fiscalCurrency = data.invoiceCurrency;

            if (order?.fiscalData?.SumInvIICRefs?.length > 0) {
                data.SumInvIICRefs = order?.fiscalData?.SumInvIICRefs;
            }

            const { SameTaxes, SumInvIICRefs, ...fiscalData } = await sendFiscalInvoice(data);
            const updateOrderResponse = await fetch({
                operation: "mutation",
                endpoint: "updateOrder",
                data: {
                    _id: order?._id,
                    fiscalData: fiscalData,
                },
                responseData: `_id 
                fiscalData{
                    IICRef
                    IssueDateTime
                    InvOrdNum
                    TCR
                    operator
                    nipt
                    nivf
                    nslf
                    BusinUnitCode
                    SumInvIICRefs{IIC IssueDateTime}
                }`,
            });
            if (updateOrderResponse?.updateOrder?._id) {
                enqueueSnackbar(t("order_finalized"), { variant: "success" });
                setOrder({ ...order, ...updateOrderResponse.updateOrder });
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(t("finalize_failed"), { variant: "error" });
        }
        return;
    };

    const onOrderCancel = async ({ cancelReason, updateInventory }) => {
        const data = {
            _id: order?._id,
            cancelReason,
            updatedInventory: updateInventory,
        };

        if (order?.fiscalData?.IICRef) {
            try {
                const { SameTaxes, ...fiscalData } = await cancelFiscalOrder();
                if (fiscalData) {
                    data.cancelFiscalData = fiscalData;
                    enqueueSnackbar(t("invoice_finalized"), { variant: "success" });
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar(t("finalize_failed"), { variant: "error" });
            }
        }

        const response = await fetch({
            operation: "mutation",
            endpoint: "cancelOrder",
            data,
            responseData: "_id status cancelReason cancelFiscalData{IICRef IssueDateTime} updatedInventory",
        });
        if (response?.cancelOrder?._id) {
            enqueueSnackbar(t("order_cancelled"), { variant: "success" });
            setOrder({ ...order, ...response.cancelOrder });
            if (!updateInventory) return;
            const inventories = order?.products?.map((product, index) => {
                const sellWarehouse = product?.productData?.warehouseIds?.find((w) =>
                    order?.pospointData?.warehouses?.includes(w)
                );
                const timeStamp = new Date();
                timeStamp.setMilliseconds(timeStamp.getMilliseconds() + index);
                let totalQuantity = product?.quantity;
                if (!isNaN(parseFloat(product?.productData?.deductionFactor))) {
                    totalQuantity *= parseFloat(product?.productData?.deductionFactor);
                }
                return {
                    _id: `inventory_${product?.productId.split("products_")[1]}_${timeStamp.toJSON()}`,
                    quantity: totalQuantity,
                    warehouseId: sellWarehouse,
                };
            });
            const inventoryResponse = await fetch({
                operation: "mutation",
                endpoint: "updateProductsInventory",
                data: {
                    inventories: inventories,
                },
            });
            if (inventoryResponse?.updateProductsInventory === "success") {
                enqueueSnackbar(t("inventory_updated"), { variant: "success" });
                return;
            }
            enqueueSnackbar(t("inventory_update_failed"), { variant: "error" });
        }
    };

    const printPdf = () => {
        const orderData = order;
        orderData.customerName = !!order?.customerData
            ? (order?.customerData?.firstName || "") + " " + (order?.customerData?.lastName || "")
            : "Walkin";
        orderData.createdBy = orderData?.createdByData?.displayName || orderData?.createdBy?.split("users_")?.[1] || "";
        if (order?.fiscalData?.nivf) {
            printFiscal({
                settingsProperty,
                t,
                order: {
                    ...orderData,
                    products: orderData?.products?.map((product) => {
                        const { taxData } = product?.productData || {};
                        return { ...product, taxData };
                    }),
                },
                pospoint: order?.pospointData,
                SameTaxes: generateSameTaxes(orderData?.products),
                currencies: currencies,
            });
        } else {
            print({
                settingsProperty,
                t,
                order: orderData,
                pospoint: order?.pospointData,
                currencies: currencies,
            });
        }
    };
    const printCancelledFiscalPdf = () => {
        const orderData = JSON.parse(JSON.stringify(order));
        orderData.customerName = !!order?.customerDat
            ? order?.customerData?.firstName + " " + order?.customerData?.lastName
            : "Walkin";
        const SameTaxes = generateSameTaxes(orderData?.products);
        orderData?.products?.forEach((p) => {
            p.price = -p.price;
        });
        orderData.totalPrice *= -1;
        printFiscal({
            settingsProperty,
            t,
            order: orderData,
            pospoint: order?.pospointData,
            SameTaxes,
            currencies,
        });
    };
    const closeHandler = () => {
        if (onOrderChange) {
            onOrderChange(order);
        }
        navigate(-1);
    };

    return (
        <Modal
            open
            onClose={() => closeHandler()}
            titlelabel={t("order_details")}
            maxWidth="md"
            yesText="none"
            noText="none"
        >
            <Grid container style={{ marginBottom: 20 }}>
                <Grid item xs={12} sm={10}>
                    <Stack direction={{ xs: "column", sm: "row" }} gap={1} flexWrap="wrap">
                        <ProtectedAction permission={{ orders: { void: true } }}>
                            <CancelRecordConfirm
                                onConfirm={onOrderCancel}
                                disabled={["TRANSFERRED", "CANCELLED"].includes(order?.status)}
                                showUpdateInventory
                            />
                        </ProtectedAction>
                        {!(order?.fiscalData?.nivf && (order?.fiscalData?.SumInvIICRefs || [])?.length === 0) && (
                            <Button variant="outlined" onClick={() => finalizeOrder()}>
                                {t("finalize")}
                            </Button>
                        )}
                        <ProtectedAction permission={{ orders: { reprint: true } }}>
                            <Button variant="outlined" startIcon={<PrintIcon />} onClick={() => printPdf()}>
                                {t("print_pdf")}
                            </Button>
                        </ProtectedAction>
                        {order?.cancelFiscalData?.nivf && (
                            <Button
                                variant="outlined"
                                startIcon={<PictureAsPdfIcon />}
                                onClick={() => printCancelledFiscalPdf()}
                            >
                                {t("print_canceled_pdf")}
                            </Button>
                        )}
                        <ChangeUserModal order={order} onConfirm={(createdBy) => setOrder({ ...order, createdBy })} />

                        <ProtectedAction permission={{ my_shift: { change_payment: true } }}>
                            <EditPaymentMethodModal
                                order={order}
                                onConfirm={(newOrderData) => setOrder({ ...order, ...newOrderData })}
                            />
                        </ProtectedAction>
                        {order?.createdBy?.startsWith("API_") ? (
                            <Button
                                startIcon={<StorefrontIcon />}
                                onClick={(e) => {
                                    navigate(
                                        `/pos/sell?pos=${order?.pospointData?._id?.split("pospoints_")[1]}&order=${
                                            order?._id?.split("orders_")[1]
                                        }`
                                    );
                                }}
                                variant="outlined"
                            >
                                {t("confirm")}
                            </Button>
                        ) : null}
                    </Stack>
                </Grid>
                <Grid container item xs={12} sm={2} justifyContent="end">
                    <Typography variant="h5"># {order?.number}</Typography>
                </Grid>
            </Grid>
            {loading ? (
                <TemplateOrderDetails />
            ) : (
                <>
                    <Stack
                        style={{ marginBottom: 20 }}
                        spacing={2}
                        direction={{ xs: "column", sm: "row" }}
                        divider={<Divider orientation="vertical" flexItem />}
                    >
                        <Box>
                            <EventIcon />

                            <Typography>
                                {t("created_by")}:{" "}
                                {order?.createdBy?.startsWith("API_")
                                    ? order?.createdBy?.split("API_")[1]
                                    : order?.createdBy?.split("users_")?.[1]}
                            </Typography>
                            <Typography>
                                {t("pos")}: {order?.pospointData?.name}
                            </Typography>
                            <Typography>
                                {t("created_at")}: {DateTime.fromISO(order?.createdAt).toFormat("yyyy-LL-dd HH:mm:ss")}
                            </Typography>
                        </Box>
                        <Box>
                            <PaymentsIcon />
                            <Typography>
                                {t("payment_method")}: {t(`${order?.payments?.paymentMethodData?.name || "--"}`)}
                            </Typography>
                            <Typography>
                                {t("payment_status")}:{" "}
                                {order?.payments?.cashReceived === 0
                                    ? t("UNPAID")
                                    : t(`${order?.payments?.paymentStatus || "--"}`)}
                            </Typography>
                            <Typography>
                                {t("paid_on")}:
                                {!!order?.payments?.timestamp
                                    ? DateTime.fromISO(order?.payments?.timestamp).toFormat("yyyy-LL-dd HH:mm:ss")
                                    : "--"}
                            </Typography>
                        </Box>
                        {order?.status === "CANCELLED" && (
                            <Box>
                                <CancelIcon />
                                <Typography>
                                    {t("void_reason")}: {order?.cancelReason || ""}
                                </Typography>
                                <Typography>
                                    {t("inventory_returned")}: {order?.updatedInventory ? t("true") : t("false")}
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                    <Grid container xs={12} style={{ marginBottom: 10 }}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="h5"
                                style={{ fontWeight: 600, textTransform: "uppercase" }}
                                color={
                                    order?.status === "CANCELLED"
                                        ? "secondary"
                                        : order?.status === "PAID"
                                        ? "success"
                                        : "primary"
                                }
                            >
                                {order?.payments?.cashReceived === 0 ? t("UNPAID") : t(`${order?.status}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                            <ProtectedAction permission={{ orders: { correct: true } }}>
                                <EditOrderItemsModal
                                    items={order?.products}
                                    //disabled={order?.fiscalData?.nivf}
                                    onOrderUpdate={(newOrder) => setOrder({ ...order, ...newOrder })}
                                    orderId={order._id}
                                />
                            </ProtectedAction>
                        </Grid>
                    </Grid>
                    <Table
                        data={order?.products || []}
                        columns={columns}
                        disableHeader
                        disableSelection
                        disableDelete
                        disableFooter
                    />
                    <Grid style={{ marginTop: 20 }} container justifyContent="end">
                        <Grid item xs={12} sm={6}>
                            <PriceInformationCard
                                discountAmount={calculateTotalDiscount(
                                    order?.totalPrice,
                                    order?.discountType,
                                    order?.discountValue
                                )}
                                discountLabel={order?.discountData?.name}
                                total={order?.totalPrice?.toFixed(2)}
                                subtotal={order?.subtotal?.toFixed(2)}
                                tax={(order.totalPrice - order.subtotal)?.toFixed(2)}
                            />
                        </Grid>
                    </Grid>
                    {order.fiscalData && (
                        <Box>
                            <Typography variant="subtitle1">{t("fiscal_details")}</Typography>
                            <Divider />
                            <Typography>
                                {t("tcr")}: {t(`${order?.fiscalData?.TCR}`)}
                            </Typography>
                            <Typography>
                                {t("operator")}: {t(`${order?.fiscalData?.operator}`)}
                            </Typography>
                            <Typography>
                                {t("nslf")}: {t(`${order?.fiscalData?.nslf}`)}
                            </Typography>
                            <Typography>
                                {t("nivf")}: {t(`${order?.fiscalData?.nivf}`)}
                            </Typography>
                            <Typography>
                                {t("finalized_on")}:{" "}
                                {DateTime.fromISO(order?.fiscalData?.IssueDateTime).toFormat("yyyy-LL-dd HH:mm:ss")}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Modal>
    );
};

const TemplateOrderDetails = () => {
    return (
        <>
            <Stack
                style={{ marginBottom: 20 }}
                spacing={2}
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
            >
                <Box>
                    <EventIcon />

                    <Typography>
                        <Skeleton width={200} />
                    </Typography>
                    <Typography>
                        <Skeleton />
                    </Typography>
                    <Typography>
                        <Skeleton />
                    </Typography>
                </Box>
                <Box>
                    <PaymentsIcon />

                    <Typography>
                        <Skeleton width={200} />
                    </Typography>
                    <Typography>
                        <Skeleton />
                    </Typography>
                    <Typography>
                        <Skeleton />
                    </Typography>
                </Box>
            </Stack>
            <>
                <Skeleton width={"100%"} height={50} />
                <Skeleton width={"100%"} height={50} />
            </>
            <Grid style={{ marginTop: 20 }} container justifyContent="end">
                <Grid item xs={6}>
                    <Skeleton width={"100%"} />
                    <Skeleton width={"100%"} />
                </Grid>
            </Grid>
        </>
    );
};

const ChangeUserModal = ({ order, onConfirm }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openModal: false,
        users: [],
        selectedUser: undefined,
    });

    const { fetch, loading } = useApi();

    useEffect(() => {
        if (!state.openModal) return;
        if (state.users?.length > 0) return;
        loadUsers();
    }, [state.openModal, state.users]);

    const loadUsers = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "users",
            responseData: "_id displayName username",
        });
        setState({
            ...state,
            users: response?.users?.map((user) => {
                return { label: !!user.displayName ? user.displayName : user.username, value: user._id };
            }),
        });
    };

    const changeOrderUser = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "changeOrderUserId",
            data: { _id: order._id, newUserId: state.selectedUser },
            responseData: "createdBy",
        });
        if (response?.changeOrderUserId?.createdBy) {
            setState({ ...state, openModal: false, selectedUser: undefined });
            onConfirm(response?.changeOrderUserId?.createdBy);
        }
    };

    return (
        <>
            <Button
                startIcon={<PersonAddIcon />}
                variant="outlined"
                onClick={() => setState({ ...state, openModal: true })}
            >
                {t("change_user")}
            </Button>
            <Modal
                loading={loading}
                maxWidth="xs"
                open={state.openModal}
                onClose={() => setState({ ...state, openModal: false })}
                onSave={changeOrderUser}
                noText="none"
                yesText={t("confirm")}
                yesDisabled={loading || !state.selectedUser}
            >
                <SelectControl
                    onChange={(e) => setState({ ...state, selectedUser: e.target.value })}
                    options={state.users}
                    label={t("choose_user")}
                />
            </Modal>
        </>
    );
};

export default OrderView;
