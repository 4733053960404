import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { ClientsSearch } from "../../../components/common";
import { AutoCompleteControl, CheckboxControl, InputControl, SelectControl } from "../../../components/form";
import { Typography } from "@mui/material";

const SelectClientModal = ({
    open,
    onClose,
    onClientSelect,
    clientReservations,
    selectedCustomer,
    onReservationSelect,
    onNoteChange,
    onCheckBoxChange,
    onNumberChange,
    onRoomSelect,
    rooms,
    note,
    personsNumber,
    hideNoteOnReceipt,
}) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} titlelabel={t("client")} onClose={onClose} maxWidth="xs" yesText={"none"} noText={"close"}>
            {!!selectedCustomer && <Typography fontStyle={"italic"}>{selectedCustomer.customerName}</Typography>}

            {/* <ClientsSearch onClientSelect={(e) => onClientSelect(e)} /> */}
            <AutoCompleteControl
                label={t("choose_by_room")}
                onChange={(e) => onRoomSelect(e.target.value)}
                options={rooms}
            />
            {!!clientReservations?.length > 0 && (
                <SelectControl
                    options={clientReservations}
                    label={t("select_reservation")}
                    onChange={(e) => {
                        onReservationSelect(e.target.value);
                        onClose();
                    }}
                />
            )}
            <InputControl
                label={t("note")}
                multiline
                minRows={2}
                value={note}
                onChange={(e) => {
                    onNoteChange(e?.target?.value);
                }}
            />
            <InputControl
                label={t("number_of_persons")}
                value={personsNumber}
                onChange={(e) => {
                    onNumberChange(e?.target?.value);
                }}
            />
            <CheckboxControl
                label={t("do_not_show_note_on_receipt")}
                value={hideNoteOnReceipt}
                onChange={(e) => {
                    onCheckBoxChange(e?.target?.checked);
                }}
            />
        </Modal>
    );
};

export default SelectClientModal;
