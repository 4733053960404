import { Chip, List, ListItem, ListItemText, ListSubheader, Skeleton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxControl, SelectControl } from "../../../../components/form";
import { Modal } from "../../../../components/modal";
import { useApi } from "../../../../components/hooks";
import { useSnackbar } from "notistack";

const RoomAssignment = ({ open, action = "assign", onClose, reservation = {}, onReservationChange }) => {
    const [state, setState] = useState({
        availableRooms: [],
        unAssignRooms: [],
        assignRooms: [],
        assignTo: null,
    });
    const { t } = useTranslation();
    const groupedRooms = useMemo(() => {
        return Object.values(
            reservation?.rooms
                ?.filter((room) => room.roomId !== "UNASSIGNED")
                ?.reduce((acc, room) => {
                    if (!acc[room.roomtypeId]) {
                        acc[room.roomtypeId] = {
                            roomtypeName: room.roomtypeData?.name,
                            roomtypeId: room.roomtypeId,
                            rooms: [],
                        };
                    }
                    acc[room.roomtypeId].rooms.push(room);
                    return acc;
                }, {}) || {}
        );
    }, [reservation?.rooms]);
    const unassignedRoomtypeQuantity = useMemo(() => {
        if (!state.assignTo) return 0.0;
        return (
            reservation?.rooms?.filter((room) => room.roomId === "UNASSIGNED" && room.roomtypeId === state.assignTo)
                ?.length || 0.0
        );
    }, [reservation?.rooms, state.assignTo]);

    const reservationRoomtypesOptions = useMemo(() => {
        return Object.values(
            reservation?.rooms
                ?.filter((room) => room.roomId === "UNASSIGNED")
                ?.reduce((acc, room) => {
                    if (acc[room.roomtypeId]) return acc;
                    acc[room.roomtypeId] = { value: room?.roomtypeId, label: room.roomtypeData?.name };
                    return acc;
                }, {}) || {}
        );
    }, [reservation?.rooms]);

    const groupedAvailableRooms = useMemo(() => {
        return Object.values(
            state.availableRooms?.reduce((acc, room) => {
                if (!acc[room.roomtypeId]) {
                    acc[room.roomtypeId] = {
                        roomtypeName: room.roomTypeData?.name,
                        roomtypeId: room.roomtypeId,
                        rooms: [],
                    };
                }
                acc[room.roomtypeId].rooms.push(room);
                return acc;
            }, {}) || {}
        );
    }, [state.availableRooms]);

    useEffect(() => {
        if (!open) return;
        if (action === "unassign") return;
        loadAvailableRooms();
    }, [open, reservation, action]);

    useEffect(() => {
        if (!open) setState({ availableRooms: [], unAssignRooms: [], assignRooms: [], assignTo: null });
    }, [open]);

    const roomsApi = useApi();
    const loadAvailableRooms = async () => {
        const response = await roomsApi.fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "rooms",
                    responseData: "_id name roomtypeId roomTypeData{name}",
                },
                {
                    endpoint: "blockedRooms",
                    data: {
                        checkin: reservation?.checkin,
                        checkout: reservation?.checkout,
                    },
                    responseData: "blockedRooms blockedRoomtypesQuantity {id quantity}",
                },
            ],
        });

        if (!response?.rooms) return;
        setState({
            ...state,
            availableRooms: response?.rooms?.filter((r) => !response?.blockedRooms?.blockedRooms?.includes(r._id)),
        });
    };

    const saveApi = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const saveUnAssignRooms = async () => {
        if (!state.unAssignRooms?.length > 0) return;
        const data = {
            reservationId: reservation._id,
            rooms: state.unAssignRooms?.filter((room) => !!room),
        };
        const response = await saveApi.fetch({
            operation: "mutation",
            endpoint: "unAssignRoomsToReservation",
            data,
        });
        if (response?.unAssignRoomsToReservation === "success") {
            enqueueSnackbar(t("saved"), { variant: "default" });
            onReservationChange();
            onClose();
            return;
        }
        enqueueSnackbar(t("failed"), { variant: "error" });
    };
    const saveAssignRooms = async () => {
        const data = {
            reservationId: reservation._id,
            rooms: [
                {
                    roomtypeId: state.assignTo,
                    rooms: state.assignRooms?.filter((room) => !!room),
                },
            ],
        };
        const response = await saveApi.fetch({
            operation: "mutation",
            endpoint: "assignRoomsToReservation",
            data,
        });
        if (response?.assignRoomsToReservation === "success") {
            enqueueSnackbar(t("saved"), { variant: "default" });
            onReservationChange();
            onClose();
            return;
        }
        enqueueSnackbar(t("failed"), { variant: "error" });
    };

    return (
        <Modal
            maxWidth="xs"
            open={open}
            titlelabel={t(`${action}_rooms`)}
            onClose={onClose}
            onSave={() => {
                if (action === "assign") {
                    saveAssignRooms();
                    return;
                }
                saveUnAssignRooms();
            }}
        >
            {action === "assign" && (
                <SelectControl
                    value={state.assignTo}
                    onChange={(e) => setState({ ...state, assignTo: e.target.value })}
                    label={t("choose_roomtype_to_assign")}
                    options={reservationRoomtypesOptions}
                />
            )}
            {action === "unassign"
                ? groupedRooms?.map((roomtype) => {
                      return (
                          <List
                              dense
                              subheader={
                                  <ListSubheader disableSticky sx={{ fontSize: 18, color: "primary.main" }}>
                                      {roomtype.roomtypeName || ""}
                                  </ListSubheader>
                              }
                          >
                              {roomtype?.rooms?.map((room) => {
                                  return (
                                      <ListItem sx={{ marginX: 2 }}>
                                          <ListItemText
                                              primaryTypographyProps={{ variant: "body" }}
                                              primary={room?.roomData?.name || ""}
                                          />
                                          <CheckboxControl
                                              value={!state.unAssignRooms.includes(room.roomId)}
                                              onChange={(e) =>
                                                  setState({
                                                      ...state,
                                                      unAssignRooms: e.target.checked
                                                          ? state.unAssignRooms?.filter((r) => r !== room.roomId)
                                                          : [...state.unAssignRooms, room.roomId],
                                                  })
                                              }
                                          />
                                      </ListItem>
                                  );
                              })}
                          </List>
                      );
                  })
                : !!state.assignTo && (
                      <>
                          <Chip sx={{ marginLeft: 2 }} label={`${unassignedRoomtypeQuantity} ${t("rooms")}`} />
                          {groupedAvailableRooms?.map((roomtype) => {
                              const isMaxChecked = unassignedRoomtypeQuantity === state.assignRooms?.length;
                              return (
                                  <List
                                      dense
                                      subheader={
                                          <ListSubheader disableSticky sx={{ fontSize: 18, color: "primary.main" }}>
                                              {roomtype.roomtypeName || ""}
                                          </ListSubheader>
                                      }
                                  >
                                      {roomsApi?.loading ? (
                                          <>
                                              <Skeleton height={50} />
                                              <Skeleton height={50} />
                                              <Skeleton height={50} />
                                          </>
                                      ) : (
                                          roomtype?.rooms?.map((room) => {
                                              const isChecked = state.assignRooms?.some((r) => r.roomId === room._id);
                                              return (
                                                  <ListItem sx={{ marginX: 2 }}>
                                                      <ListItemText
                                                          primaryTypographyProps={{ variant: "body" }}
                                                          primary={room?.name || ""}
                                                      />
                                                      <CheckboxControl
                                                          disabled={isMaxChecked && !isChecked}
                                                          value={isChecked}
                                                          onChange={(e) =>
                                                              setState({
                                                                  ...state,
                                                                  assignRooms: e.target.checked
                                                                      ? [
                                                                            ...(state.assignRooms || []),
                                                                            {
                                                                                roomId: room._id,
                                                                                roomtypeId: room.roomtypeId,
                                                                            },
                                                                        ]
                                                                      : state.assignRooms?.filter(
                                                                            (r) => r.roomId !== room._id
                                                                        ),
                                                              })
                                                          }
                                                      />
                                                  </ListItem>
                                              );
                                          })
                                      )}
                                  </List>
                              );
                          })}
                      </>
                  )}
        </Modal>
    );
};

export default RoomAssignment;
