import { Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";

const NetworkStatusBar = React.memo(() => {
    const [hasInternet, setHasInternet] = useState(navigator.onLine);
    const { t } = useTranslation();

    useEffect(() => {
        const handleOnline = () => setHasInternet(true);
        const handleOffline = () => setHasInternet(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);

    return (
        <Chip
            variant="outlined"
            label={t(hasInternet ? "online" : "offline")}
            color={hasInternet ? "success" : "warning"}
            icon={hasInternet ? <WifiIcon /> : <WifiOffIcon />}
        />
    );
});

export default NetworkStatusBar;
