import { Button, Grid, LinearProgress, Paper, Stack, Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { DateRangeControl } from "../../components/dates";
import { SelectControl } from "../../components/form";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { DateTime } from "luxon";
import { RemoveModeratorSharp } from "@mui/icons-material";
import { toCurrency } from "../../utils";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    CHECKIN: "#59C086", //checked in
    CHECKOUT: "#FFC82C", //checked out
    CANCELLED: "#FF4949", //canceled
    ON_HOLD: "#C0CCDA", // on hold
};

const calculateNights = (checkin, checkout) => {
    if (!checkin || !checkout) return 0;
    return DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days;
};

const ReservationList = () => {
    const { t } = useTranslation();
    const [reservations, setReservations] = useState([]);
    const [settings, setSettings] = useState({});
    const params = useParams();
    const [filters, setFilters] = useState({
        startDate: DateTime.now().toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().plus({ days: 30 }).toFormat("yyyy-LL-dd"),
    });
    const [roomtypeOptions, setRoomtypeOptions] = useState([]);
    const columns = [
        {
            accessorKey: "clientName",
            header: t("client"),
            accessorFn: (row) => {
                const { firstName = "", lastName = "", _id } = row?.clientsData?.[0] || {};
                if (!_id) return t("client_removed");
                return (firstName || "") + " " + (lastName || "");
            },
        },
        { accessorKey: "checkin", header: t("checkin") },
        { accessorKey: "checkout", header: t("checkout") },
        {
            accessorKey: "nights",
            header: t("nights"),
            accessorFn: (row) => calculateNights(row?.checkin, row?.checkout),
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            accessorFn: (row) => toCurrency(row?.totalPrice, currency),
        },
        // { accessorKey: "amountPaid", header: t("amount_paid") },
        {
            accessorKey: "roomsData",
            header: t("rooms"),
            accessorFn: (row) => {
                const { rooms = [] } = row || {};
                const roomNames = rooms?.map((room) => {
                    if (room?.roomData?.name) return room.roomData.name;
                    return "";
                });
                return roomNames?.join(" , ");
            },
        },
        {
            accessorKey: "bookedOn",
            header: t("booked_on"),
            accessorFn: (row) => DateTime.fromISO(row._id?.split("reservations_")?.[1])?.toFormat("yyyy LLL dd") || "",
        },
        {
            accessorKey: "bookingSource",
            header: t("booking_source"),
            accessorFn: (row) => {
                if (!row?.bookingSource) return "";
                if (row?.bookingSource === "reception") return t(row.bookingSource);
                if (!!row.referer) return row.referer;
                return "";
            },
        },
        {
            accessorKey: "status",
            id: "status",
            header: t("status"),
            //accessorFn: (row) => (row?.status ? t(row.status) : ""),
            cell: ({ row: { original } }) => {
                const status = original?.status;
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={t(`${status}`)} size="small" />;
            },
        },
        {
            accessorKey: "guests",
            header: t("guests_number"),
            accessorFn: (row) =>
                row?.guests?.reduce((acc, category) => {
                    category?.guests?.forEach((guest) => {
                        acc += guest?.number || 0;
                    });
                    return acc;
                }, 0) || 0,
        },
    ];

    const statusOptions = [
        { value: "CHECKIN", label: t("checkin") },
        { value: "CHECKOUT", label: t("checkout") },
        { value: "NO_SHOW", label: t("no_show") },
        { value: "HOLD", label: t("hold") },
        { value: "APPROVED", label: t("approved") },
    ];

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "roomtypes", responseData: "_id name" },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
            ],
        });
        if (response?.roomtypes) {
            setRoomtypeOptions(response?.roomtypes?.map((rt) => ({ label: rt.name, value: rt._id })));
        }
        if (response?.settingsgeneral) setSettings(response.settingsgeneral);
    };
    const { currency = "" } = settings?.currencyData || {};

    const loadData = async () => {
        if (!(filters?.startDate && filters?.endDate)) return;
        const response = await fetch({
            operation: "query",
            endpoint: "reservationsByDates",
            data: { startDate: filters?.startDate, endDate: filters?.endDate },
            responseData:
                "_id isBlockedRooms checkin checkout bookingSource referer clientsData{_id firstName lastName} totalPrice rooms{roomtypeId roomData{name}} status guests{roomId guests{name number price extra}}",
        });
        if (response?.reservationsByDates)
            setReservations(response.reservationsByDates?.filter((reservation) => !reservation.isBlockedRooms));
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteReservations" : "deleteReservation",
            data,
        });
        if (response?.deleteReservations === "success" || response?.deleteReservation === "success") {
            setReservations(reservations.filter((reservation) => !rows.some((row) => row._id === reservation._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        if (!!params.id) return; //Skip fetching data when single client page is rendered

        loadExtraData();
    }, []);

    const filteredReservations = useMemo(() => {
        return reservations?.filter((reservation) => {
            let show = true;
            if (!!filters.status) show = reservation.status === filters.status;
            if (show && !!filters.roomtypeId) {
                show = reservation.rooms?.some((room) => room.roomtypeId === filters.roomtypeId);
            }
            return show;
        });
    }, [reservations, filters.status, filters.roomtypeId]);

    return (
        <div>
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                <Stack direction="row" gap={2} flexWrap="wrap">
                    <DateRangeControl
                        startLabel={t("start")}
                        endLabel={t("end")}
                        values={{ startDate: filters.startDate, endDate: filters.endDate }}
                        onChange={({ startDate, endDate }) => setFilters({ ...filters, startDate, endDate })}
                    />
                    <Button onClick={loadData} size="small" variant="contained">
                        {t("load_reservations")}
                    </Button>
                </Stack>
                <Grid container spacing={2} marginTop={1} alignItems="center">
                    <Grid item xs={4}>
                        <SelectControl
                            value={filters?.status || ""}
                            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                            options={statusOptions}
                            label={t("status")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectControl
                            value={filters?.roomtypeId || ""}
                            onChange={(e) => setFilters({ ...filters, roomtypeId: e.target.value })}
                            options={roomtypeOptions}
                            label={t("roomtype")}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            onClick={() => setFilters({ ...filters, roomtypeId: undefined, status: undefined })}
                            startIcon={<FilterListOffIcon />}
                            fullWidth
                            variant="outlined"
                        >
                            {t("clear_filter")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("reservations_form")}
                moduleType="frontdesk"
                data={filteredReservations}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("reservations_")[1]}`)}
                onCreateClick={() => navigate("/newreservation")}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet />
        </div>
    );
};

export default ReservationList;
