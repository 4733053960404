import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const CalendarAppBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <AppBar
            sx={{
                background:
                    "linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)",
            }}
            elevation={1}
        >
            <Toolbar>
                <Typography variant="h5">{t("calendar")}</Typography>
                <div style={{ marginLeft: "auto" }}>
                    <Button variant="contained" color="secondary" onClick={() => navigate("/newreservation")}>
                        <AddIcon />
                    </Button>
                    <Button sx={{ marginLeft: 2 }} variant="outlined" onClick={() => navigate(-1)}>
                        <CloseIcon />
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default CalendarAppBar;
