import React from "react";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Avatar,
    ButtonGroup,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PublicIcon from "@mui/icons-material/Public";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import EventIcon from "@mui/icons-material/Event";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";

const supplierContactsFields = [
    {
        label: "phone",
        value: "phone",
        icon: <PhoneIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "email",
        value: "email",
        icon: <EmailIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "address",
        value: "addressLine",
        icon: <HomeWorkIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "address",
        value: "address",
        icon: <HomeWorkIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "state",
        value: "state",
        icon: <PublicIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "country",
        value: "country",
        icon: <PublicIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "city",
        value: "city",
        icon: <LocationCityIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "zip",
        value: "postCode",
        icon: <LocationCityIcon color="primary" sx={{ fontSize: 15 }} />,
    },
    {
        label: "website",
        value: "website",
        icon: <LocationCityIcon color="primary" sx={{ fontSize: 15 }} />,
    },
];

const ClientProfileCard = ({ supplierData, loading, defaultExpanded = true }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (loading) return <TemplateCard t={t} />;

    return (
        <Accordion sx={{ marginTop: 1 }} disableGutters variant="outlined" defaultExpanded={defaultExpanded}>
            <AccordionSummary>
                <CardHeader
                    sx={{ width: "100%", padding: 0 }}
                    title={
                        supplierData?.name
                            ? `${supplierData?.name || ""}`
                            : `${supplierData?.firstName || ""} ${supplierData?.lastName || ""}`
                    }
                    titleTypographyProps={{ variant: "h5" }}
                    avatar={
                        <Avatar
                            src="https://res.cloudinary.com/strowberry-code/image/upload/v1682431706/hb/icons8-test-account-40_rpzevs.png"
                            aria-label="profile"
                        />
                    }
                    action={
                        <>
                            <ButtonGroup variant="outlined">
                                <Button
                                    onClick={() =>
                                        navigate(`/pos/suppliers/${supplierData?._id?.split("suppliers_")?.[1]}`)
                                    }
                                    color="primary"
                                >
                                    <BorderColorIcon />
                                </Button>
                            </ButtonGroup>
                        </>
                    }
                />
            </AccordionSummary>
            <AccordionDetails>
                <CardContent sx={{ padding: 0 }}>
                    <ListItem dense>
                        <ListItemText primaryTypographyProps={{ variant: "h6" }}>
                            {t("contact_information")}
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <List style={{}} dense>
                        {supplierContactsFields.map((contact) => {
                            return supplierData?.[contact?.value] ? (
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>{contact.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={t(contact.label)}
                                        primaryTypographyProps={{ variant: "subtitle2" }}
                                        secondary={supplierData?.[contact?.value]}
                                        secondaryTypographyProps={{ variant: "subtitle1" }}
                                    />
                                </ListItem>
                            ) : null;
                        })}
                    </List>
                </CardContent>
            </AccordionDetails>
        </Accordion>
    );
};

const TemplateCard = ({ t }) => {
    return (
        <Card>
            <CardHeader title={<Skeleton />} />
            <CardContent>
                <List>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </List>
            </CardContent>
            <CardContent>
                <List>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </List>
            </CardContent>
        </Card>
    );
};

export default ClientProfileCard;
