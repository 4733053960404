import { Button, ButtonGroup, Card, CardContent, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal";
import { ItemsTable } from "./components";
import { AutoCompleteControl, Form, InputControl, SelectControl, SwitchControl } from "../../components/form";
import { DateRangeControl } from "../../components/dates";
import { ActionsPanel, ActionBox, AmountsCard, CancelRecordConfirm } from "../../components/common";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useApi, useProducts } from "../../components/hooks";
import { calculateTotalTax, toCurrency } from "../../utils";
import { languageOptions, selfIssueOptions } from "../invoices/components/fiscalOptions";
import PaymentsModal from "../../components/common/PaymentsModal";
import PaymentsView from "../../components/common/PaymentsView";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { sendFiscalInvoice } from "../../components/integrations/fiscal";
import { exportToPdf } from "../invoices/components/invoicePdf";
import { QRModal } from "../invoices/components";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid

    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const BillForm = () => {
    const [values, setValues] = useState({ allowEdit: true });
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [posOptions, setPosOptions] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [cashdesks, setCashdesks] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openPaymentViewModal, setOpenPaymentViewModal] = useState(false);

    const { products } = useProducts();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { addRecord } = useOutletContext() || {};
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "settingsgeneral",
                responseData: "_id currencyData{currency}",
                data: { _id: "settings_general" },
            },
        ];
        if (params?.id !== "create") {
            endpoints.push({
                endpoint: "bill",
                data: {
                    _id: "bills_" + params?.id,
                },
                responseData: `_id cashdesk supplier serial pdfLanguage description date dueDate status number pos discountValue paymentStatus discountType invoiceCurrency isReverseCharge
                    tableData{itemId price taxData{taxId isIncluded} description quantity unit} totalPrice
                    payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp note} typeOfSelfIss paymentOption
                    fiscalData{nivf nslf BusinUnitCode operator TCR InvOrdNum IICRef IssueDateTime nipt SumInvIICRefs{IIC IssueDateTime} }`,
            });
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        let newValues = {
            invoiceCurrency: response?.settingsgeneral?.currencyData?.currency,
        };
        if (response?.bill) {
            newValues = {
                ...values,
                ...response.bill,
                allowEdit: response.bill?.fiscalData?.IICRef || response.bill?.status === "CANCELLED" ? false : true,
                invoiceCurrency: !!response.bill?.invoiceCurrency
                    ? response.bill.invoiceCurrency
                    : response?.settingsgeneral?.currencyData?.currency,
            };
        }
        setValues({ ...values, ...newValues });
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "suppliers", responseData: "_id name IDNumber IDType address city country" },
                { endpoint: "pospoints", responseData: "_id name" },
                { endpoint: "currencies", responseData: "_id currency" },
                {
                    endpoint: "paymentmethods",
                    responseData: "_id name method fiscalMethod isActive",
                },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                {
                    endpoint: "cashdesks",
                    responseData: "_id tcrCode fiscalBusinessId",
                },
            ],
        });
        if (!!response?.paymentmethods)
            setPaymentMethods(
                response.paymentmethods
                    ?.filter((p) => p.isActive)
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                            fiscalMethod: c.fiscalMethod,
                        };
                    })
            );
        if (!!response?.cashdesks)
            setCashdesks(
                response.cashdesks.map((c) => {
                    return {
                        value: c._id,
                        label: c.tcrCode,
                        fiscalBusinessId: c.fiscalBusinessId,
                    };
                })
            );
        if (!!response?.suppliers)
            setSupplierOptions(
                response.suppliers.map((supplier) => ({
                    value: supplier._id,
                    label: supplier.name,
                    supplierData: {
                        addressLine: supplier?.address,
                        country: supplier?.country,
                        IDNumber: supplier?.IDNumber,
                        IDType: supplier.IDType,
                        name: supplier.name,
                        city: supplier.city,
                    },
                }))
            );
        if (!!response?.pospoints)
            setPosOptions(response.pospoints.map((pos) => ({ value: pos._id, label: pos.name })));
        if (!!response?.currencies)
            setCurrencyOptions(
                response.currencies.map((currency) => ({ value: currency.currency, label: currency.currency }))
            );
        if (!!response?.taxes) setTaxes(response.taxes);
    };
    const printPdf = async (printOptions = {}) => {
        exportToPdf({
            values,
            t,
            fetch,
            resource: "bills",
            options: printOptions,
            itemsOptions: productOptions,
        });
    };
    const finalizeBill = async (billData, canceled = false) => {
        const items = billData?.tableData?.map((item) => {
            const itemData = productOptions?.find((i) => i._id === item.itemId) || {};
            return {
                name: itemData?.name,
                price: item.price,
                quantity: item.quantity,
                unit: item.unit,
                taxData: itemData?.taxData?.map((t) => {
                    return {
                        isIncluded: t.isIncluded,
                        taxId: t.taxId,
                    };
                }),
            };
        });
        const payments =
            billData?.payments?.map((payment) => ({
                paymentMethod: payment?.paymentMethod,
                amount: payment?.amount,
            })) || [];
        const { fiscalBusinessId } = cashdesks?.find((c) => c.value || values?.cashdesk) || {};
        return await sendFiscalInvoice({
            fetch,
            fiscalBusinessId,
            items: items,
            discount: {
                type: billData?.discountType,
                value: billData?.discountValue,
            },
            totalPrice: billData?.totalPrice,
            payments: payments,
            paymentMethod: values?.paymentOption,
            buyerData: values?.supplierData,
            cancelled: Boolean(canceled),
            existingFiscalData: values?.fiscalData,
            SumInvIICRefs: values?.fiscalData?.SumInvIICRefs || null,
            IsReverseCharge: Boolean(values?.isReverseCharge),
            typeOfSelfIss: billData.typeOfSelfIss,
            invoiceCurrency: values?.invoiceCurrency,
            fiscalCurrency: values?.invoiceCurrency,
            isFiscalCurrencyRateSpecified: false,
        });
    };

    const onBillCancel = async ({ cancelReason }) => {
        const data = {
            _id: values?._id,
            cancelReason,
        };

        if (values?.fiscalData?.IICRef) {
            try {
                const billData = {
                    tableData: values?.tableData,
                    payments: values?.payments,
                    discountType: values?.discountType,
                    discountValue: values?.discountValue,
                    totalPrice: values?.totalPrice,
                    typeOfSelfIss: values?.typeOfSelfIss,
                };
                const { SameTaxes, ...fiscalData } = await finalizeBill(
                    {
                        ...billData,
                        payments: values.payments || [],
                    },
                    true
                );
                if (fiscalData) {
                    data.cancelFiscalData = fiscalData;
                    enqueueSnackbar(t("bill_finalized"));
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar(t("finalize_failed"));
            }
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "cancelBill",
            data,
            responseData: "_id",
        });
        if (response?.cancelBill?._id) enqueueSnackbar(t("bill_cancelled"), { variant: "success" });
    };

    const saveHandler = async (finalize = false) => {
        const {
            invoiceCurrency,
            date,
            dueDate,
            supplier,
            serial,
            pdfLanguage,
            description,
            pos,
            discountValue,
            discountType,
            tableData,
            _id,
            typeOfSelfIss,
            paymentOption,
            cashdesk,
            isReverseCharge,
            payments,
        } = values;
        tableData?.forEach((item) => {
            item.quantity = parseFloat(item.quantity);
            item.price = parseFloat(item.price);
            item.taxData = item?.taxData?.map(({ taxId, isIncluded }) => ({ taxId, isIncluded }));
        });
        const totalPrice = parseFloat(amountInfo()?.totalPrice - amountInfo()?.discount);
        const data = {
            invoiceCurrency,
            supplier,
            serial,
            pdfLanguage,
            pos,
            description,
            date,
            dueDate,
            discountValue: discountValue ? parseFloat(discountValue) : null,
            discountType,
            tableData,
            totalPrice: isNaN(totalPrice) ? 0.0 : parseFloat(totalPrice.toFixed(2)),
            typeOfSelfIss,
            cashdesk,
            paymentOption,
            isReverseCharge,
            payments:
                payments?.map((payment) => {
                    return {
                        amount: payment.amount,
                        paymentMethod: payment.paymentMethod,
                        timestamp: payment.timestamp,
                        userName: payment.userName,
                        note: payment.note,
                    };
                }) || [],
        };
        if (_id) data._id = _id;
        if (finalize) {
            try {
                const { SameTaxes, ...fiscalData } = await finalizeBill({
                    ...data,
                    payments: values.payments || [],
                });
                data.status = "APPROVED";
                if (fiscalData) {
                    data.fiscalData = fiscalData;
                    if (!data?.payments?.length > 0) {
                        const { fiscalMethod } = paymentMethods?.find((m) => m.value === data?.paymentOption) || {};
                        if (
                            ["BANKNOTE", "CARD", "CHECK", "SVOUCHER", "COMPANY", "ORDER", "CASH"].includes(
                                fiscalMethod?.toUpperCase()
                            )
                        ) {
                            data.payments = [
                                {
                                    amount: data?.totalPrice,
                                    paymentMethod: data?.paymentOption,
                                    note: "",
                                },
                            ];
                        }
                    } else {
                        const correctPaymentDifference =
                            parseFloat(data?.totalPrice) -
                                data?.payments?.reduce((total, method) => {
                                    return total + method.amount;
                                }, 0.0) || 0.0;
                        if (correctPaymentDifference !== 0) {
                            const { fiscalMethod } =
                                paymentMethods?.find((m) => m.value === data.payments[0].paymentMethod) || {};
                            if (
                                ["BANKNOTE", "CARD", "CHECK", "SVOUCHER", "COMPANY", "ORDER", "CASH"].includes(
                                    fiscalMethod?.toUpperCase()
                                )
                            ) {
                                data.payments[0].amount += correctPaymentDifference;
                            }
                        }
                    }
                    enqueueSnackbar(t("bill_finalized"));
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar(t("bill_failed"));
            }
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateBill" : "createBill",
            data,
            responseData:
                "_id date dueDate description status payments{amount} paymentStatus totalPrice invoiceCurrency supplierData{name} number",
        });
        if (response?.createBill?._id || response?.updateBill?._id) {
            enqueueSnackbar(t("bill_saved"), { variant: "default" });
        }
    };
    const calculateTotalDiscount = (totalPrice = 0) => {
        if (!values.discountType || !values.discountValue) return 0;
        const discountAmount =
            values.discountType === "total"
                ? parseFloat(values.discountValue)
                : totalPrice * parseFloat(values.discountValue) * 0.01;
        if (isNaN(discountAmount)) return 0;
        return discountAmount;
    };
    const amountInfo = () => {
        let totalPrice = 0.0;
        let subtotal = 0.0;
        let discount = 0.0;
        let taxTotal = 0.0;

        values?.tableData?.forEach((item) => {
            const { totalAmount, includedAmount } = calculateTotalTax({
                taxData: item.taxData?.map((t) => ({
                    isIncluded: t.isIncluded,
                    tax: taxes.find((tax) => tax._id === t.taxId),
                })),
                price: parseFloat(item.price),
            });
            taxTotal += totalAmount * item.quantity;
            totalPrice += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
        });
        subtotal = totalPrice - taxTotal;
        discount = calculateTotalDiscount(totalPrice);

        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
            subtotal: isNaN(subtotal) ? 0.0 : subtotal,
            taxTotal: isNaN(taxTotal) ? 0.0 : taxTotal,
            discount: isNaN(discount) ? 0.0 : discount,
        };
    };
    const productOptions = useMemo(() => {
        return products.map((p) => {
            return {
                _id: p?._id,
                name: p?.name,
                price: p?.price,
                taxData: p?.taxData,
                unit: p?.measureUnit || p?.purchaseUnit || "",
            };
        });
    }, [products]);

    useEffect(() => {
        if (supplierOptions?.length) setSupplierData(values?.supplier);
    }, [values?.supplier, supplierOptions]);

    const setSupplierData = (id) => {
        const { supplierData } = supplierOptions?.find((supplier) => supplier.value === id) || {};
        setValues({ ...values, supplierData });
    };
    const updateStatus = async (newStatus) => {
        if (!values?._id || !newStatus) return;
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateBillStatus",
            data: {
                _id: values?._id,
                status: newStatus,
            },
            responseData:
                "_id date dueDate paymentStatus payments{amount} description status supplierData{name} number",
        });
        if (response?.updateBillStatus?.status) {
            enqueueSnackbar(t("status_updated"), { variant: "default" });
            setValues({
                ...values,
                status: response?.updateBillStatus?.status,
            });
        }
    };
    const addPayment = async (payment) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "addBillPayment",
            data: {
                _id: values._id,
                amount: parseFloat(payment.amount),
                paymentMethod: payment.paymentMethod,
                timestamp: new Date(payment.timestamp).toJSON(),
                note: payment.note,
            },
            responseData: "amount timestamp paymentMethod paymentMethodData{name fiscalMethod method} userName note",
        });
        if (response?.addBillPayment?.amount) {
            enqueueSnackbar(t("payment_added"), { variant: "default" });
            setValues({
                ...values,
                payments: [...(values?.payments || []), response?.addBillPayment],
            });
            setOpenModal(false);
        } else {
            enqueueSnackbar(t("add_payment_failed"), { variant: "error" });
            setOpenModal(false);
        }
    };

    const amountPaid = useMemo(() => {
        if (!values?.payments || values?.payments?.length === 0) return 0.0;
        const amount = values?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0);
        if (isNaN(amount)) return 0.0;
        return amount;
    }, [values.payments]);

    const amountUnpaid = () => {
        const amount = amountInfo().totalPrice - amountInfo().discount - amountPaid;
        if (isNaN(amount)) return amountInfo().totalPrice;
        if (amount < 0) return 0.0;
        return amount;
    };
    const displayStatus = useMemo(() => {
        if (amountUnpaid() <= 0 && values?.payments?.length > 0) {
            return "PAID";
        }
        return values?.status || "DRAFT";
    }, [values?.status, values?.payments]);

    const closeHandler = () => {
        if (values._id) {
            addRecord && addRecord(values);
        }
        navigate(-1);
    };

    return (
        <>
            <Modal
                fullScreen
                open
                titlelabel={t("bills")}
                onClose={closeHandler}
                loading={loading}
                FormProps={{ values, onValuesChange: setValues, onSubmit: () => saveHandler(false) }}
                yesText={values?.allowEdit ? t("save") : "none"}
            >
                <ActionsPanel>
                    <ActionBox width={350} title={t("status")}>
                        <ButtonGroup fullWidth disableElevation>
                            <Button
                                disabled={values?.status === "DRAFT" || !values?.allowEdit}
                                onClick={() => {
                                    updateStatus("DRAFT");
                                }}
                            >
                                {t("draft")}
                            </Button>
                            <Button
                                disabled={values?.status === "APPROVED" || !values?.allowEdit}
                                onClick={() => {
                                    updateStatus("APPROVED");
                                }}
                            >
                                {t("approved")}
                            </Button>
                        </ButtonGroup>
                        <Chip
                            sx={{ marginTop: 1, backgroundColor: statusColors[displayStatus] }}
                            label={t(displayStatus)}
                        />

                        <Stack marginY={2} direction="row" justifyContent="space-between">
                            <Typography fontWeight={700} color="green">
                                {t("paid")}: {toCurrency(amountPaid, values?.invoiceCurrency)}
                            </Typography>
                            <Typography fontWeight={700} color="red">
                                {t("unpaid")}: {toCurrency(amountUnpaid(), values?.invoiceCurrency)}
                            </Typography>
                        </Stack>
                        <CancelRecordConfirm onConfirm={onBillCancel} disabled={values?.status === "CANCELLED"} />
                    </ActionBox>
                    <ActionBox title={t("payment")}>
                        <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            onClick={() => setOpenModal(true)}
                            disabled={!values?.allowEdit}
                        >
                            {t("add_payment")}
                        </Button>
                        <Button startIcon={<OpenInNewIcon />} onClick={() => setOpenPaymentViewModal(true)}>
                            {t("view_payment")}
                        </Button>
                        <SwitchControl name={"recurring"} label={t("recurring")} />
                    </ActionBox>

                    <ActionBox title={t("print")}>
                        <Button
                            variant="outlined"
                            sx={{ marginBottom: 1 }}
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => printPdf({ type: "save" })}
                        >
                            {t("download")}
                        </Button>
                        <Button startIcon={<PrintIcon />} onClick={() => printPdf({ type: "autoprint" })}>
                            {t("print")}
                        </Button>
                    </ActionBox>
                    <ActionBox title={t("finalize")}>
                        <Button
                            variant="outlined"
                            onClick={() => saveHandler(true)}
                            disabled={values?.fiscalData?.nivf}
                            startIcon={<AccountBalanceIcon />}
                        >
                            {t("finalize")}
                        </Button>
                        {values?.fiscalData?.nivf ? (
                            <QRModal
                                IICRef={values?.fiscalData?.IICRef}
                                IssueDateTime={values?.fiscalData?.IssueDateTime}
                                nipt={values?.fiscalData?.nipt}
                                totalPrice={values?.totalPrice}
                            />
                        ) : null}
                        <SwitchControl name={"isReverseCharge"} label={t("is_reverse_charge")} />
                        <SelectControl name="cashdesk" label={t("tcr")} options={cashdesks} />
                    </ActionBox>

                    <ActionBox title={t("bill_details")} width={300}>
                        <Typography variant="h6" style={{ textAlign: "right" }}>
                            #{values?.number || "AUTO"}
                        </Typography>
                        <DateRangeControl
                            startLabel={t("date")}
                            endLabel={t("due_date")}
                            values={{ startDate: values.date || null, endDate: values.dueDate || null }}
                            onChange={({ startDate, endDate }) =>
                                setValues({ ...values, date: startDate, dueDate: endDate })
                            }
                        />
                        <SelectControl
                            disabled={!values?.allowEdit}
                            label={t("currency")}
                            name={"invoiceCurrency"}
                            options={currencyOptions}
                        />
                    </ActionBox>
                </ActionsPanel>
                <div style={{ margin: "20px 0" }}>
                    <Typography variant="h6">{t("bill_details")}</Typography>
                    <Divider />
                </div>
                <Form values={values} onValuesChange={setValues}>
                    <Grid container spacing={2}>
                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <AutoCompleteControl
                                    disabled={!values?.allowEdit}
                                    label={t("supplier")}
                                    name="supplier"
                                    options={supplierOptions}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {values?.supplierData?.IDNumber || ""}
                                        </Typography>
                                        <Typography variant="h6" component="div">
                                            {values?.supplierData?.name || ""}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {values?.supplierData?.country || ""}
                                        </Typography>
                                        <Typography variant="body2">
                                            {(values?.supplierData?.address || "") +
                                                " " +
                                                (values?.supplierData?.city || "")}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={2}></Grid>
                        <Grid container item xs={4} spacing={2}>
                            <Grid item xs={6}>
                                <InputControl disabled={!values?.allowEdit} name="serial" label={t("serial")} />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl
                                    //disabled={!values?.allowEdit}
                                    name="pdfLanguage"
                                    label={t("pdf_language")}
                                    options={languageOptions}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl
                                    disabled={!values?.allowEdit}
                                    name="pos"
                                    label={t("pos")}
                                    options={posOptions}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl
                                    name="typeOfSelfIss"
                                    label={t("self_issue_type")}
                                    options={selfIssueOptions}
                                    disabled={!values?.allowEdit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl
                                    name="paymentOption"
                                    label={t("payment_type")}
                                    options={paymentMethods}
                                    disabled={!values?.allowEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControl
                                    disabled={!values?.allowEdit}
                                    multiline
                                    minRows={3}
                                    name="description"
                                    label={t("description")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
                <div style={{ margin: "20px 0" }}>
                    <Typography variant="h6">{t("items")}</Typography>
                    <Divider />
                </div>
                <ItemsTable
                    data={values?.tableData || []}
                    options={productOptions}
                    taxesOptions={taxes}
                    onChange={(tableData) => setValues({ ...values, tableData })}
                    currency={values?.invoiceCurrency}
                    disabled={!values?.allowEdit}
                />
                <Grid mt={5} container justifyContent="flex-end">
                    <Grid item xs={6}>
                        <AmountsCard
                            total={amountInfo()?.totalPrice - amountInfo()?.discount}
                            tax={amountInfo()?.taxTotal}
                            subtotal={amountInfo()?.subtotal}
                            discount={amountInfo()?.discount}
                            discountInputValue={values?.discountValue}
                            discountType={values?.discountType}
                            onChange={(newData) => setValues({ ...values, ...newData })}
                            currency={values?.invoiceCurrency}
                        />
                    </Grid>
                </Grid>
                <PaymentsView
                    open={openPaymentViewModal}
                    data={values?.payments || []}
                    onClose={() => setOpenPaymentViewModal(false)}
                    currency={values?.invoiceCurrency}
                />
            </Modal>
            <PaymentsModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                addPayment={(data) => addPayment(data)}
                t={t}
                currency={values?.invoiceCurrency}
            />
        </>
    );
};

export default BillForm;
