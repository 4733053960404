import React, { useMemo } from "react";
import { Modal } from "../../../components/modal";
import { Alert, AlertTitle, Grid, Typography, Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../../utils";
import InvoicesTable from "./InvoicesTable";
import OrdersTable from "./OrdersTable";

const CheckoutModal = ({ reservation, onCheckoutConfirm, onClose, open, currencyConvert, limitConfirm }) => {
    const { t } = useTranslation();
    const { clientsData } = reservation || {};
    const { firstName, lastName } = clientsData?.[0] || {};

    // const amountsInfo = useMemo(() => {
    //     let totalPrice = 0.0;
    //     let paid = 0.0;
    //     let unpaid = 0.0;
    //     const invoices = reservation?.invoices?.filter((invoice) => {
    //         return !["CANCELLED", "TRANSFERRED"].includes(invoice?.status);
    //     });
    //     const orders = reservation?.orders?.filter((order) => !["CANCELLED", "TRANSFERRED"].includes(order?.status));
    //     if (invoices?.length > 0) {
    //         invoices?.forEach((invoice) => {
    //             totalPrice += invoice.totalPrice;
    //             paid += invoice?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
    //         });
    //     } else {
    //         totalPrice += reservation?.totalPrice || 0.0;
    //         paid += reservation?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
    //     }
    //     orders?.forEach((order) => {
    //         totalPrice += currencyConvert?.convertAmount({
    //             amount: order.totalPrice,
    //             from: order?.pospointData?.defaultCurrency,
    //             to: reservation?.currency,
    //         });
    //     });
    //     unpaid = totalPrice - paid;
    //     if (unpaid < 0) unpaid = 0.0;
    //     return {
    //         totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
    //         paid: isNaN(paid) ? 0.0 : paid,
    //         unpaid: isNaN(unpaid) ? 0.0 : unpaid,
    //     };
    // }, [reservation, currencyConvert]);

    //new
    const amountsInfo = useMemo(() => {
        let totalPrice = 0.0;
        let paid = 0.0;
        let unpaid = 0.0;
        const activeInvoices = reservation?.invoices?.filter((invoice) => {
            return !["CANCELLED", "TRANSFERRED"].includes(invoice?.status);
        });
        const orders = reservation?.orders?.filter((order) => !["CANCELLED", "TRANSFERRED"].includes(order?.status));
        if (reservation?.invoices?.length > 0) {
            activeInvoices?.forEach((invoice) => {
                totalPrice += currencyConvert?.convertAmount({
                    amount: invoice.totalPrice,
                    from: invoice.invoiceCurrency,
                    to: reservation?.currency,
                });

                paid +=
                    invoice?.payments?.reduce(
                        (acc, payment) =>
                            acc +
                            currencyConvert?.convertAmount({
                                amount: payment.amount,
                                from: invoice.invoiceCurrency,
                                to: reservation?.currency,
                            }),
                        0.0
                    ) || 0.0;
            });
        } else {
            totalPrice += reservation?.status === "CANCELLED" ? 0.0 : reservation?.totalPrice || 0.0;
            paid +=
                reservation?.status === "CANCELLED"
                    ? 0.0
                    : reservation?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
        }
        orders?.forEach((order) => {
            totalPrice += currencyConvert?.convertAmount({
                amount: order.totalPrice,
                from: order?.pospointData?.defaultCurrency,
                to: reservation?.currency,
            });
            if (!!order?.payments?.cashReceived && ["paid", "partly_paid"].includes(order?.payments?.paymentStatus)) {
                paid += currencyConvert?.convertAmount({
                    amount: order?.payments?.cashReceived,
                    from: order?.pospointData?.defaultCurrency,
                    to: reservation?.currency,
                });
            }
        });

        const parsedPaidAmount = parseFloat(parseFloat(paid).toFixed(2));
        const parsedTotalAmount = parseFloat(parseFloat(totalPrice).toFixed(2));

        unpaid = totalPrice - paid;
        const parsedUnpaidAmount = parseFloat(parseFloat(unpaid).toFixed(2));
        if (unpaid < 0) unpaid = 0.0;
        return {
            totalPrice: isNaN(parsedTotalAmount) ? 0.0 : parsedTotalAmount,
            paid: isNaN(parsedPaidAmount) ? 0.0 : parsedPaidAmount,
            unpaid: isNaN(parsedUnpaidAmount) ? 0.0 : parsedUnpaidAmount,
        };
    }, [reservation, currencyConvert]);

    return (
        <Modal
            maxWidth="m"
            open={open}
            onClose={onClose}
            onSave={onCheckoutConfirm}
            yesText={t("confirm")}
            noText={"none"}
            yesDisabled={amountsInfo?.unpaid > 0 && !!limitConfirm}
        >
            <Grid container xs={12} spacing={2} sx={{ marginBottom: "10px" }}>
                <Grid item xs={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">
                                {t("you_are_checking_out")} {firstName || ""} {lastName || ""}.
                            </Typography>
                            <Typography variant="subtitle2">
                                {" "}
                                {t("please_see_invoice_details_below_before_confirming")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Card style={{ backgroundColor: "rgb(64, 72, 92, 0.2)", border: "none" }}>
                        <CardContent>
                            <Typography variant="body2">{t("total")}</Typography>
                            <Typography variant="h5">
                                {toCurrency(amountsInfo?.totalPrice, reservation?.currency || "")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Card style={{ backgroundColor: "rgba(19, 206, 102, 0.2)", border: "none" }}>
                        <CardContent>
                            <Typography variant="body2">{t("paid")}</Typography>
                            <Typography variant="h5">
                                {toCurrency(amountsInfo?.paid, reservation?.currency || "")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Card style={{ backgroundColor: "rgba(255, 73, 73, 0.2)", border: "none" }}>
                        <CardContent>
                            <Typography variant="body2">{t("unpaid")}</Typography>
                            <Typography variant="h5">
                                {toCurrency(amountsInfo?.unpaid, reservation?.currency || "")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {amountsInfo?.unpaid > 0 && !!limitConfirm && (
                <Grid containter xs={12}>
                    <Grid item xs={6}>
                        <Alert severity="warning">
                            <AlertTitle>{t("unpaid_balance")}</AlertTitle>
                            {t("unpaid_reservation_limit_checkout")}
                        </Alert>
                    </Grid>
                </Grid>
            )}
            <InvoicesTable data={reservation?.invoices || []} />

            <OrdersTable data={reservation?.orders || []} />
        </Modal>
    );
};

export default CheckoutModal;
