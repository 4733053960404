import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const AvailabilityList = () => {
    const [availabilities, setAvailabilities] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        {
            accessorKey: "sendTo",
            header: t("type"),
            accessorFn: (row) => (row?.sendTo ? t(row.sendTo) : ""),
        },
        { accessorKey: "name", header: t("name") },
        { accessorKey: "startDate", header: t("start_date") },
        { accessorKey: "endDate", header: t("end_date") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "availabilities",
            responseData: "_id sendTo name startDate endDate",
        });
        if (response?.availabilities) setAvailabilities(response.availabilities);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteAvailabilities" : "deleteAvailability",
            data,
        });
        if (response?.deleteAvailabilities === "success" || response?.deleteAvailability === "success") {
            setAvailabilities(
                availabilities.filter((availability) => !rows.some((row) => row._id === availability._id))
            );
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };
    const addRecordAvailability = (data) => {
        if (availabilities?.some((r) => r._id === data._id)) {
            setAvailabilities(
                availabilities?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setAvailabilities([...availabilities, data]);
    };
    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("availabilities")}
                moduleType={"backoffice"}
                data={availabilities}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("availabilities_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordAvailability }} />
        </div>
    );
};

export default AvailabilityList;
