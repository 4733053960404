import jsPDF from "jspdf";
import { DateTime } from "luxon";
import { toCurrency } from "../../utils";
import qz from "qz-tray";

export const printRevenue = async ({
    orders,
    user,
    hotel,
    currency,
    withItems = false,
    startingBalance,
    totalPayments,
    closingBalance,
    paymentsTotalByMethodName = {},
    totalPospointsPayments = [],
    totalOpenAmounts = {},
    silentPrintWaiterShift,
    startingTimestamp,
    closingTimestamp,
    totalUserRevenue,
    t,
}) => {
    const products = {};
    const totalByCurrencies = {};

    Object.values(totalUserRevenue).forEach((item) => {
        Object.entries(item).forEach(([currency, amount]) => {
            if (totalByCurrencies[currency]) {
                totalByCurrencies[currency] += amount;
            } else {
                totalByCurrencies[currency] = amount;
            }
        });
    });

    let documentHeight =
        100 + 50 * Object.keys(paymentsTotalByMethodName || {})?.length + 20 * totalPospointsPayments?.length;

    if (withItems) {
        orders.forEach((order) => {
            order.products.forEach((product) => {
                if (products[product.productData._id])
                    products[product.productData._id].quantity += parseInt(product.quantity);
                else
                    products[product.productData._id] = {
                        name: product.productData.name,
                        quantity: parseInt(product.quantity),
                    };
            });
        });
        documentHeight += 20 * Object.keys(products).length;
    }

    let receiptData = [];
    receiptData.push({
        value: `${hotel}`,
    });
    receiptData.push({
        value: t("close_turn"),
    });
    receiptData.push({
        value: `${t("user")}: ${user}`,
    });
    receiptData.push({
        value: `${t("starting_timestamp")}: ${DateTime.fromISO(startingTimestamp).toFormat("dd-LL-yyyy HH:mm")}`,
    });
    receiptData.push({
        value: `${t("closing_timestamp")}: ${DateTime.now(closingTimestamp).toFormat("dd-LL-yyyy HH:mm")}`,
    });
    receiptData.push({
        value: `______________________________`,
    });

    receiptData.push({
        value: `${t("opening_balance")}: ${
            startingBalance?.map((currency) => toCurrency(currency.amount, currency.currency))?.join(" , ") || "--"
        } `,
    });
    if (withItems) {
        receiptData.push({
            value: `______________________________`,
        });
        Object.values(products).forEach((product) => {
            receiptData.push({
                value: `${product.name} X ${product.quantity.toString()}`,
            });
        });
        receiptData.push({
            value: `______________________________`,
        });
    }

    receiptData.push({
        value: `${t("payments")}: ${totalPayments
            ?.map((currency) => toCurrency(currency.amount, currency.currency))
            ?.join(" , ")}`,
    });

    // receiptData.push({
    //     field: "Total",
    //     value: `${startingBalance + totalPayments} ${currency}`,
    // });

    receiptData.push({
        value: `${t("total_user_revenue")}: ${Object.entries(totalByCurrencies)
            .map(([currency, amount]) => ({ currency, amount }))
            ?.map((currency) => toCurrency(currency.amount, currency.currency))
            ?.join(" , ")}`,
    });

    receiptData.push({
        value: `${t("closing_balance")}: ${closingBalance
            ?.map((currency) => toCurrency(currency.amount, currency.currency))
            ?.join(" , ")}`,
    });
    receiptData.push({
        value: `______________________________`,
    });
    receiptData.push({
        value: `${t("payment_methods")}:`,
    });
    Object.entries(paymentsTotalByMethodName || {})?.forEach(([method, amountsByCurrency = {}]) => {
        receiptData.push({
            value: `${method}: ${Object.entries(amountsByCurrency)
                ?.map(([currency, amount]) => {
                    return `${currency} ${amount?.toFixed(2)}`;
                })
                ?.join(", ")}`,
        });
    });
    receiptData.push({
        value: `${t("open_orders")}: ${Object.entries(totalOpenAmounts || {})
            ?.map(([currency, amount]) => `${currency} ${amount.toFixed(2)}`)
            ?.join(", ")}`,
    });
    receiptData.push({
        value: `______________________________`,
    });
    receiptData.push({
        value: `${t("payments_by_outlet")}:`,
    });
    totalPospointsPayments?.forEach((pos) => {
        receiptData.push({
            value: `${pos.pospointName}: ${pos?.currency} ${pos.amount.toFixed(2)}`,
        });
    });

    receiptData.push({
        value: `______________________________`,
    });
    receiptData.push({
        value: t(`signature_user`),
    });
    receiptData.push({
        value: `______________________________`,
    });
    receiptData.push({
        value: t(`signature_manager`),
    });

    var doc = new jsPDF("mm", "mm", [80, documentHeight]);

    doc.autoTable({
        theme: "plain",
        styles: { fontSize: 12, cellPadding: { top: 1 } },
        margin: { left: 5, top: 5, right: 5 },
        body: receiptData.map((item, index) => {
            if (index === 0 || index === 1) {
                item.value = { content: item.value, styles: { halign: "center", fontSize: 15, fontStyle: "bold" } };
            }
            return [item.value];
        }),
    });

    const base64Doc = doc.output("datauristring").split("data:application/pdf;filename=generated.pdf;base64,")[1];

    if (silentPrintWaiterShift) {
        if (!qz.websocket.isActive()) {
            await qz.websocket.connect();
        }
        const defaultPrinter = await qz.printers.getDefault();
        if (defaultPrinter) {
            let config = qz.configs.create(defaultPrinter, {
                size: { width: 80, height: documentHeight },
                units: "mm",
                colorType: "grayscale",
                interpolation: "nearest-neighbor",
                scaleContent: "false",
                jobName: `Waiter Shift ${DateTime.now().toFormat("yyyy LL dd HH:mm:ss")}`,
            });
            await qz.print(config, [
                {
                    type: "pixel",
                    format: "pdf",
                    flavor: "base64",
                    data: base64Doc,
                },
            ]);
        }
    } else {
        doc.autoPrint();
        doc.output("dataurlnewwindow");
    }
};
