import { Dexie } from "dexie";

const cacheDB = new Dexie("HotelbeeDB");

cacheDB.version(1).stores({
    pospoints: `&_id`,
    posplaces: `&_id`,
    posdiscounts: `&_id`,
    settings: `&_id`,
    taxes: `&_id`,
    currencies: `&_id`,
    users: `&_id`,
    waiterrevenue: `&_id, offlineCreated, offlineEdited`,
    orders: `&_id, status, offlineCreated, offlineEdited`,
    paymentmethods: `&_id`,
});

export default cacheDB;
