import { Backdrop, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { InputControl } from "../components/form";
import { DateRangeControl } from "../components/dates";
import { useApi } from "../components/hooks";
import { Table } from "../components/table";
import { DateTime } from "luxon";

const MigrationPage = () => {
    const [values, setValues] = useState({});
    const [beds24Inventory, setBeds24Inventory] = useState({});
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const runMigration = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "migrateToV3",
            data: { v2Hotel: values.v2, v3Hotel: values.v3, propertyKey: values.propertyKey },
        });
        if (response.migrateToV3 === "success") enqueueSnackbar("success", { variant: "success" });
        else enqueueSnackbar("failed", { variant: "error" });
    };
    const runV3Update = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateV3DB",
            data: { v3Hotel: values.v3DB },
        });
        if (response.updateV3DB === "success") enqueueSnackbar("success", { variant: "success" });
        else enqueueSnackbar("failed", { variant: "error" });
    };
    const runV3Fix = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "fixV3Db",
            data: { v2Hotel: values.v2DBFIX, v3Hotel: values.v3DBFIX },
        });
        if (response.fixV3Db === "success") enqueueSnackbar("success", { variant: "success" });
        else enqueueSnackbar("failed", { variant: "error" });
    };
    const runV3ExportToBeds24 = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "exportDatabaseReservationsToBeds24",
            data: { v3Hotel: values.v3DBExportToBeds24 },
        });
        if (response.exportDatabaseReservationsToBeds24 === "success")
            enqueueSnackbar("success", { variant: "success" });
        else enqueueSnackbar("failed", { variant: "error" });
    };

    const loadBeds24Inventory = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "propertyBeds24Inventory",
                    responseData: "_id name availability{date inventory}",
                    data: {
                        hotel: beds24Inventory.property,
                        startDate: beds24Inventory.startDate,
                        endDate: beds24Inventory.endDate,
                    },
                },
                {
                    endpoint: "propertyRoomsInventory",
                    responseData: "_id name availability{date inventory}",
                    data: {
                        hotel: beds24Inventory.property,
                        startDate: beds24Inventory.startDate,
                        endDate: beds24Inventory.endDate,
                    },
                },
            ],
        });
        if (response) {
            const inventory = response?.propertyRoomsInventory?.filter((rt) => {
                const { name } = response?.propertyBeds24Inventory?.find((roomtype) => roomtype._id === rt._id) || {};
                if (!name) return false;
                rt.name = name;
                return true;
            });
            setBeds24Inventory({ ...beds24Inventory, beds24: response?.propertyBeds24Inventory, inventory: inventory });
        }
    };

    const cancelReceptionBookings = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "cancelBeds24ReceptionBookings",
            data: {
                hotel: beds24Inventory.property,
            },
        });
        if (response?.cancelBeds24ReceptionBookings === "success") {
            enqueueSnackbar("success", { variant: "success" });
        } else enqueueSnackbar("failed", { variant: "error" });
    };
    const syncBeds24Inventory = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "setPropertyBeds24Inventory",
            data: {
                hotel: beds24Inventory.property,
                startDate: beds24Inventory.startDate,
                endDate: beds24Inventory.endDate,
            },
        });
        if (response?.setPropertyBeds24Inventory === "success") {
            enqueueSnackbar("success", { variant: "success" });
        } else enqueueSnackbar("failed", { variant: "error" });
    };

    return (
        <div>
            <Stack direction="row" alignItems="center" gap={2} padding={3}>
                <InputControl
                    fullWidth={false}
                    label={"v2"}
                    value={values.v2}
                    onChange={(e) => setValues({ ...values, v2: e.target.value })}
                />
                <InputControl
                    fullWidth={false}
                    label={"v3"}
                    value={values.v3}
                    onChange={(e) => setValues({ ...values, v3: e.target.value })}
                />
                <InputControl
                    fullWidth={false}
                    label={"propertyKey"}
                    value={values.propertyKey}
                    onChange={(e) => setValues({ ...values, propertyKey: e.target.value })}
                />
                <Button variant="contained" onClick={() => runMigration()}>
                    migrate
                </Button>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2} padding={3}>
                <InputControl
                    fullWidth={false}
                    label={"v3DB"}
                    value={values.v3DB}
                    onChange={(e) => setValues({ ...values, v3DB: e.target.value })}
                />
                <Button variant="contained" onClick={() => runV3Update()}>
                    update v3 db
                </Button>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2} padding={3}>
                <InputControl
                    fullWidth={false}
                    label={"v2DB"}
                    value={values.v2DBFIX}
                    onChange={(e) => setValues({ ...values, v2DBFIX: e.target.value })}
                />
                <InputControl
                    fullWidth={false}
                    label={"v2DB"}
                    value={values.v3DBFIX}
                    onChange={(e) => setValues({ ...values, v3DBFIX: e.target.value })}
                />
                <Button variant="contained" onClick={() => runV3Fix()}>
                    fix v3 db
                </Button>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2} padding={3}>
                <InputControl
                    fullWidth={false}
                    label={"v3DB"}
                    value={values.v3DBExportToBeds24}
                    onChange={(e) => setValues({ ...values, v3DBExportToBeds24: e.target.value })}
                />
                <Button variant="contained" onClick={() => runV3ExportToBeds24()}>
                    export reservations to beds24
                </Button>
            </Stack>
            <Stack padding={3}>
                <Typography variant="h6">Beds24 inventory</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputControl
                            fullWidth={false}
                            label={"property"}
                            value={beds24Inventory.property}
                            onChange={(e) => setBeds24Inventory({ ...beds24Inventory, property: e.target.value })}
                        />
                        <DateRangeControl
                            startLabel={"start"}
                            endLabel={"end"}
                            values={{ startDate: beds24Inventory.startDate, endDate: beds24Inventory.endDate }}
                            onChange={({ startDate, endDate }) =>
                                setBeds24Inventory({ ...beds24Inventory, startDate, endDate })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" onClick={() => loadBeds24Inventory()}>
                            load
                        </Button>
                        <Button variant="contained" onClick={() => cancelReceptionBookings()}>
                            cancel reception bookings
                        </Button>
                        <Button variant="contained" onClick={() => syncBeds24Inventory()}>
                            sync availability
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {beds24Inventory.inventory && (
                            <Stack direction="row" gap={4}>
                                {beds24Inventory.inventory?.map((rt) => {
                                    return (
                                        <Stack>
                                            <Typography>{rt.name}</Typography>
                                            {rt.availability?.map((date) => {
                                                return (
                                                    <Typography>
                                                        {DateTime.fromISO(date.date).toFormat("yyyy-LL-dd")}:{" "}
                                                        {date.inventory}
                                                    </Typography>
                                                );
                                            })}
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {beds24Inventory.beds24 && (
                            <Stack direction="row" gap={4}>
                                {beds24Inventory.beds24?.map((rt) => {
                                    return (
                                        <Stack>
                                            <Typography>{rt.name}</Typography>
                                            {rt.availability?.map((date) => {
                                                return (
                                                    <Typography>
                                                        {DateTime.fromISO(date.date).toFormat("yyyy-LL-dd")}:{" "}
                                                        {date.inventory}
                                                    </Typography>
                                                );
                                            })}
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Stack>
            <Backdrop sx={{ zIndex: 10000 }} open={loading}>
                <CircularProgress size={60} sx={{ color: "white" }} />
            </Backdrop>
        </div>
    );
};

export default MigrationPage;
