import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../../../utils";
import { ReportGenerator } from "../../../../components/classes";
import { DateTime } from "luxon";

const OrdersTable = ({ data = [], currencies }) => {
    const { t } = useTranslation();
    const [report, setReport] = useState(null);
    const columns = [
        {
            id: "number",
            header: t("number"),
            displayValue: (row) => row?.number,
            totalValue: () => t("total"),
        },
        {
            id: "name",
            header: t("name"),
            displayValue: (row) => {
                let rowValue = row?.customerData?.firstName + " " + row?.customerData?.lastName;
                let roomNames;
                if (row?.reservationData?.rooms?.length > 0) {
                    roomNames = row?.reservationData?.rooms
                        ?.map((data) => {
                            return data?.roomData?.name;
                        })
                        ?.join(", ");
                    rowValue += ` (${roomNames})`;
                }
                return rowValue;
            },
            totalValue: () => "",
        },
        {
            id: "date",
            header: t("date"),
            displayValue: (row) => DateTime.fromISO(row?.createdAt, { zone: "utc" })?.toFormat("dd LLL yyyy"),
            totalValue: () => "",
        },
        {
            id: "time",
            header: t("time"),
            displayValue: (row) => DateTime.fromISO(row?.createdAt, { zone: "utc" })?.toFormat("HH:mm"),
            totalValue: () => "",
        },
        {
            id: "status",
            header: t("payment_status"),
            displayValue: (row) => t(`${row?.status}`),
            totalValue: () => "",
        },
        {
            id: "pospoint",
            header: t("pospoint"),
            displayValue: (row) => row?.pospointData?.name || "",
            totalValue: () => "",
        },
        {
            id: "amount",
            header: t("amount"),
            displayValue: (row) => {
                const { currency } =
                    currencies?.find((currency) => currency._id === row?.pospointData?.defaultCurrency) || {};
                return toCurrency(row?.totalPrice, currency);
            },
            totalValue: (rows) => {
                const totalPrice = rows?.reduce((acc, row) => acc + (row?.totalPrice || 0.0), 0.0) || 0.0;

                return toCurrency(totalPrice, "");
            },
        },
        {
            id: "paymentMethod",
            header: t("payment_method"),
            displayValue: (row) => t(row?.payments?.paymentMethodData?.name || ""),
            totalValue: () => "",
        },
    ];

    useEffect(() => {
        const newRaportGenerator = new ReportGenerator({
            data,
            columns,
            displayTotal: true,
        });
        setReport(newRaportGenerator.generateTable({ title: t("orders") }));
    }, [data, currencies]);

    return report;
};

export default OrdersTable;
