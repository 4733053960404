import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PublicIcon from "@mui/icons-material/Public";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { useTranslation } from "react-i18next";

const SubscriptionPage = () => {
    const [values, setValues] = useState({
        name: "",
        country: "",
        customerId: "",
        nrRooms: 0,
        nrPosPoints: 0,
        status: "",
        email: "",
    });
    const { loading, fetch } = useApi();
    const { t } = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "subscription",
            data: {
                _id: "subscription",
            },
            responseData: "_id customerId email name country nrRooms nrPosPoints subscriptionStart status",
        });
        if (response?.subscription)
            setValues({
                ...values,
                ...response.subscription,
            });
    };

    return (
        <Form values={values}>
            <Paper elevation={0}>
                <CardHeader
                    avatar={<AccountBalanceWalletIcon />}
                    titleTypographyProps={{ variant: "h6" }}
                    title={t("subscription")}
                />
                {loading && <LinearProgress color="primary" />}
                <Grid sx={{ padding: "20px" }} container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <List>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <AccountCircleOutlinedIcon />
                                                </ListItemAvatar>
                                                <ListItemText primary={values.name} secondary={t("billing_name")} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <AlternateEmailIcon />
                                                </ListItemAvatar>
                                                <ListItemText primary={values.email} secondary={t("billing_email")} />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <List>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <PublicIcon />
                                                </ListItemAvatar>
                                                <ListItemText primary={values.country} secondary={t("country")} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <CalendarTodayIcon />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={values.subscriptionStart}
                                                    secondary={t("subscription_start")}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("nr_rooms_billed")}
                            bigText={values.nrRooms}
                            secondary={t("rooms")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DetailBoxBig
                            color="#F8F9FD"
                            title={t("nr_pospoints_billed")}
                            bigText={values.nrPosPoints}
                            secondary={t("points")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DetailBoxBig
                            color={
                                values.status == "Active"
                                    ? "#76c893"
                                    : values.status == "Overdue"
                                    ? "#e9c46a"
                                    : "#00b4d8"
                            }
                            title={t("account_status")}
                            bigText={values.status}
                            secondary={t("live")}
                        />
                    </Grid>

                    <Grid container item xs={12} justifyContent="center" style={{ marginTop: "20px" }}>
                        <Button
                            href={
                                "https://billing.stripe.com/p/login/3cs8zb8zCbP205G8ww?prefilled_email=" + values.email
                            }
                            target="_blank"
                            size="large"
                            variant="contained"
                            startIcon={<CreditCardIcon />}
                        >
                            {t("manage_subscription_and_billing")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Form>
    );
};

export default SubscriptionPage;

const DetailBoxBig = ({ title, secondary, color, bigText, margin, action }) => {
    return (
        <Card style={{ backgroundColor: color, marginLeft: margin === false ? 0 : 20, border: "none" }}>
            <CardContent style={{ padding: 15 }}>
                <Typography variant="body2">{title}</Typography>
                <Typography variant="h5">{bigText}</Typography>
                <Typography variant="subtitle2">
                    {secondary}
                    {action}
                </Typography>
            </CardContent>
        </Card>
    );
};
