import { Button, Card, CardActions, CardContent, LinearProgress, Typography, Grid } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const PosplaceList = () => {
    const navigate = useNavigate();
    const [posplaces, setPosplaces] = useState([]);
    const { t } = useTranslation();
    const columns = [
        { accessorKey: "name", header: t("name") },
        { accessorKey: "sendTo", header: t("send_to") },
        {
            id: "_id",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(`/pos/posplaces-display/${original?._id?.split("posplaces_")[1]}`);
                        }}
                        variant="outlined"
                    >
                        {t("active_orders")}
                    </Button>
                );
            },
        },
    ];
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "posplaces",
            responseData: "_id name sendTo",
        });
        if (response?.posplaces) setPosplaces(response.posplaces);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deletePosPlaces" : "deletePosPlace",
            data,
        });
        if (response?.deletePosPlaces === "success" || response?.deletePosPlace === "success") {
            setPosplaces(posplaces.filter((posplace) => !rows.some((row) => row._id === posplace._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addRecordPosPlace = (data) => {
        if (posplaces?.some((r) => r._id === data._id)) {
            setPosplaces(
                posplaces?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setPosplaces([...posplaces, data]);
    };

    return (
        <div>
            <Card style={{ marginBottom: 20 }}>
                <CardContent>
                    <img
                        src="https://res.cloudinary.com/strowberry-code/image/upload/v1642174403/hb/icons8-printer-96_zrlmcs.png"
                        alt="printer logo"
                        width={50}
                    />
                    <Typography variant="body2">
                        {t(
                            "If you are going to use printers to send kitchen tickets then you need to download an extra software for printer management. Link to download it can be found below."
                        )}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        href="https://drive.google.com/file/d/1jnn14QQIXR-ARLBf8ku5vfjMLLmFGMsY/view"
                        target="_blank"
                        variant="outlined"
                        startIcon={<CloudDownloadIcon />}
                    >
                        {t("download")}
                    </Button>
                </CardActions>
            </Card>
            <Grid container style={{ margin: "20px 0" }}>
                <Button
                    component={Link}
                    to="/pos/confirm-orders"
                    sx={{ borderRadius: "25px", marginLeft: "20px" }}
                    variant="contained"
                    color="link"
                >
                    {t("third_party_orders")}
                </Button>
            </Grid>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("posplace_form")}
                moduleType="pos"
                data={posplaces}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("posplaces_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordPosPlace }} />
        </div>
    );
};

export default PosplaceList;
