import { calculateTotalTax, applyDiscount } from "../../utils";

class InvoiceHandler2 {
    taxes;
    constructor({ date, dueDate, tableData, reservationIds, discountValue, discountType }) {
        this.date = date;
        this.dueDate = dueDate;
        this.tableData = tableData;
        this.reservationIds = reservationIds;
        this.discountValue = discountValue;
        this.discountType = discountType;
    }

    static fromExistingInvoice = ({ date, dueDate, tableData, reservationIds, discountType, discountValue }) => {
        return new InvoiceHandler2({ date, dueDate, tableData, reservationIds, discountType, discountValue });
    };

    setTaxes = (taxData) => {
        this.taxes = taxData;
    };

    convertRoomsToTableData = ({ rooms = [], guests = [] }) => {
        const items = [];
        rooms.forEach((room) => {
            const roomGuests =
                guests?.find((g) => {
                    const id = room.roomId === "UNASSIGNED" ? room.roomtypeId : room.roomId;
                    return g.roomId === id;
                })?.guests || [];

            const roomGuestsAmount =
                roomGuests?.reduce((acc, category) => acc + category.price * category.extra, 0) || 0;

            const totalPrice = room?.prices?.reduce(
                (acc, day) =>
                    acc +
                    (room?.customPrice !== undefined && room?.customPrice !== null
                        ? room?.customPrice
                        : day.price + roomGuestsAmount),
                0.0
            );
            items.push({
                itemId: room.roomtypeId,
                quantity: room?.prices?.length,
                price: totalPrice,
                taxData: room?.roomtypeData?.taxData || [],
                unit: "nights",
            });
        });
        items.forEach((item) => {
            item.price = item.price / item.quantity;
        });
        return items;
    };

    convertOrderItemsToTableData = (items) => {
        return items.map((item) => {
            return {
                itemId: item.productId,
                quantity: item.quantity,
                price: item.price,
                taxData: item?.productData?.taxData || [],
                unit: item?.productData?.measureUnit || "",
            };
        });
    };

    replaceReservationItems = (items) => {
        this.tableData = this.tableData
            ?.filter((item) => {
                return !item.itemId.startsWith("roomtypes_");
            })
            ?.concat(items);
    };
    concatItems = (items) => {
        this.tableData = this.tableData?.concat(items);
    };

    #calculateTotalPrice = () => {
        const totalPrice =
            this.tableData?.reduce((total, item) => {
                const taxInfo =
                    item?.taxData?.map((t) => {
                        return { isIncluded: t.isIncluded, tax: this.taxes.find((tax) => tax._id === t.taxId) };
                    }) || [];

                const { totalAmount, includedAmount } = calculateTotalTax({
                    taxData: taxInfo,
                    price: parseFloat(item.price),
                });
                total += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
                return total;
            }, 0.0) || 0.0;
        if (this.discountType && this.discountValue) {
            return applyDiscount(totalPrice, { type: this.discountType, value: this.discountValue });
        }
        return totalPrice;
    };

    generateInvoiceData = () => {
        return {
            date: this.date,
            dueDate: this.dueDate,
            tableData: this.tableData,
            reservationIds: this.reservationIds,
            discountValue: this.discountValue,
            discountType: this.discountType,
            totalPrice: this.#calculateTotalPrice(),
        };
    };
}

export default InvoiceHandler2;
