import React from "react";

const softwareLogo =
  "https://res.cloudinary.com/strowberry-code/image/upload/v1640015211/hb/new-logo_jkj6ii.png";

const Logo = (props) => {
  return <img {...props} alt="logo" src={softwareLogo} />;
};

export default Logo;
