import { Box, CircularProgress, ListItemButton, ListItemIcon, ListItemText, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../../../../components/form";
import { useApi } from "../../../../components/hooks";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { useNavigate } from "react-router-dom";

const ClientsSearch = () => {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [reservations, setReservations] = useState([]);

    const clientsSearchApi = useApi();
    const loadData = async (name) => {
        const response = await clientsSearchApi.fetch({
            operation: "query",
            endpoint: "searchClients",
            data: {
                name,
            },
            responseData: `_id firstName lastName`,
        });
        if (response?.searchClients) {
            setClients(
                response.searchClients?.map((client) => {
                    client.value = client._id;
                    client.label = (client.firstName || "") + " " + (client.lastName || "");
                    return client;
                })
            );
        }
    };

    useEffect(() => {
        if (!(clients?.length > 0)) return;
        loadClientsReservations();
    }, [clients]);

    const clientsReservationsSearchApi = useApi();
    const loadClientsReservations = async () => {
        const response = await clientsReservationsSearchApi.fetch({
            operation: "query",
            endpoint: "clientsRecords",
            data: {
                _ids: clients?.map((c) => c.value),
                resource: "reservations",
            },
            responseData: `reservations{_id clients checkin checkout uuid}`,
        });
        if (response?.clientsRecords) {
            setReservations(response?.clientsRecords?.reservations || []);
        }
    };

    return (
        <AutoCompleteControl
            label={t("type_to_search")}
            options={clients}
            inputProps={{
                endAdornment: (
                    <>
                        {clientsSearchApi.loading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                            <SearchIcon color="inherit" />
                        )}
                    </>
                ),
            }}
            onInputChange={(e, text) => {
                if (!!text && text.length === 3) loadData(text);
            }}
            renderOption={(props, option) => {
                const clientReservations = reservations?.filter((r) => r?.clients?.includes(option.value)) || [];
                return (
                    <>
                        <ClientRow client={option} />
                        {clientsReservationsSearchApi?.loading ? (
                            <Box paddingX={2}>
                                <Skeleton height={40} />
                            </Box>
                        ) : (
                            clientReservations?.map((reservation) => {
                                return <ClientReservationRow reservation={reservation} />;
                            })
                        )}
                    </>
                );
            }}
        />
    );
};

export default ClientsSearch;

const ClientRow = ({ client }) => {
    const navigate = useNavigate();
    return (
        <ListItemButton onClick={() => navigate(`/clients/profile/${client?.value?.split("clients_")?.[1]}`)}>
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={client.label} />
        </ListItemButton>
    );
};
const ClientReservationRow = ({ reservation }) => {
    const navigate = useNavigate();
    return (
        <ListItemButton
            sx={{ paddingLeft: 6, paddingY: 0 }}
            onClick={() => navigate(`/reservations/${reservation?._id?.split("reservations_")?.[1]}`)}
        >
            <ListItemIcon>
                <MeetingRoomIcon />
            </ListItemIcon>
            <ListItemText
                primary={`${reservation?.checkin || ""} - ${reservation?.checkout || ""}`}
                secondary={`#${reservation?.uuid || ""}`}
            />
        </ListItemButton>
    );
};
