import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputControl, SelectControl } from "../../components/form";
import { EditablePrintersTable } from "./components";
import { Modal } from "../../components/modal";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { useSnackbar } from "notistack";
import qz from "qz-tray";

const rules = {
    name: "string|required",
    sendTo: "string|required",
};

const PosplaceForm = () => {
    const [values, setValues] = useState({});
    const [printerOptions, setPrinterOptions] = useState([]);
    const { t } = useTranslation();
    const params = useParams();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { addRecordPosPlace } = useOutletContext() || {};

    const sendToOptions = [
        { value: "printer", label: t("printer") },
        { value: "activeOrders", label: t("active_order") },
    ];

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    useEffect(() => {
        _PC_Printers();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "posplace",
            data: {
                _id: "posplaces_" + params?.id,
            },
            responseData: "_id name sendTo printers{printer device}",
        });
        if (response?.posplace) setValues(response.posplace);
    };

    const _PC_Printers = async () => {
        if (!qz.websocket.isActive()) await qz.websocket.connect();
        qz.printers.find().then((printers) => {
            setPrinterOptions(
                printers.map((printer) => ({
                    value: printer,
                    label: printer,
                }))
            );
        });
    };

    const saveHandler = async () => {
        const { name, sendTo, printers, _id } = values;
        const data = { name, sendTo, printers };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePosPlace" : "createPosPlace",
            data,
            responseData: "_id name sendTo printers{printer device}",
        });
        if (response?.updatePosPlace?._id || response?.createPosPlace?._id) {
            enqueueSnackbar(t("posplace_saved"), { variant: "default" });
            addRecordPosPlace && addRecordPosPlace(response.createPosPlace || response?.updatePosPlace);
            navigate(-1);
        }
    };
    return (
        <Modal
            open
            maxWidth="xs"
            titlelabel={t("posplaces_form")}
            loading={loading}
            onClose={() => navigate(-1)}
            FormProps={{ values, onValuesChange: setValues, rules, onSubmit: saveHandler }}
            permission={{ posplaces: params?.id === "create" ? { create: true } : { edit: true } }}
        >
            <InputControl name="name" label={t("name")} />
            <SelectControl style={{ marginBottom: 20 }} name="sendTo" label={t("send_to")} options={sendToOptions} />
            {values?.sendTo === "printer" && (
                <EditablePrintersTable
                    printers={printerOptions}
                    data={values?.printers || []}
                    onChange={(printers) => setValues({ ...values, printers })}
                />
            )}
        </Modal>
    );
};

export default PosplaceForm;
