import { Box, Grid, Stack, Tab } from "@mui/material";
import React, { useMemo, useState } from "react";
import { ProductCategoriesTabs, ProductButton } from ".";
import { Modal } from "../../../components/modal";
import { ProductButtonSkeleton } from "./ProductButton";

const ProductsView = ({
    addItem,
    productCategories = [],
    productMainCategories = [],
    products = [],
    loading,
    searchedProductName,
    currency,
    clearProductSearch,
    isSmallScreen,
}) => {
    const [activeCategory, setActiveCategory] = useState(0);
    const [activeMainCategory, setMainActiveCategory] = useState(0);

    const [productVariants, setProductVariants] = useState(null);

    const posMainCategories = useMemo(() => {
        return productMainCategories?.filter((mainCategory) =>
            products?.some((p) => mainCategory?.productCategories?.includes(p.categoryId))
        );
    }, [productMainCategories, products]);

    const posCategories = useMemo(() => {
        return productCategories?.filter((category) => products?.some((p) => p.categoryId === category._id));
    }, [productCategories, products]);

    const activeCategories = useMemo(() => {
        if (!posMainCategories?.length > 0) return posCategories;
        const mainCategory = posMainCategories[activeMainCategory];
        return posCategories?.filter((c) => mainCategory?.productCategories?.includes(c._id));
    }, [activeMainCategory, posCategories]);

    const productsToDisplay =
        !!searchedProductName && searchedProductName.length > 2
            ? products.filter((product) => product.name?.toLowerCase()?.includes(searchedProductName?.toLowerCase()))
            : products.filter((product) => product.categoryId === activeCategories[activeCategory]?._id);
    return (
        <React.Fragment>
            <Stack direction={isSmallScreen ? "column" : "row"} gap={2} width="100%">
                <Box>
                    <ProductCategoriesTabs
                        value={activeMainCategory}
                        onChange={(e, value) => {
                            setMainActiveCategory(value);
                            setActiveCategory(0);
                            clearProductSearch();
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{ marginTop: isSmallScreen ? 0 : "40px" }}
                        sx={{
                            "& button.Mui-selected": {
                                borderRight: "10px solid",
                            },
                        }}
                        isSmallScreen={isSmallScreen}
                        orientation={isSmallScreen ? "horizontal" : "vertical"}
                    >
                        {posMainCategories.map((mainCategory) => (
                            <Tab
                                label={mainCategory.name}
                                sx={{
                                    backgroundColor: mainCategory.color,
                                }}
                            />
                        ))}
                    </ProductCategoriesTabs>
                </Box>
                <Box sx={{ flexWrap: "wrap", overflowX: "scroll" }}>
                    <Box>
                        <ProductCategoriesTabs
                            variant="scrollable"
                            scrollButtons="auto"
                            value={activeCategory}
                            onChange={(e, value) => {
                                setActiveCategory(value);
                                clearProductSearch();
                            }}
                            isSmallScreen={isSmallScreen}
                            sx={{
                                "& .MuiTabs-flexContainer": {
                                    flexWrap: "wrap",
                                },
                            }}
                        >
                            {activeCategories.map((category) => (
                                <Tab sx={{ marginBottom: 1 }} label={category.name} />
                            ))}
                        </ProductCategoriesTabs>
                    </Box>
                    <Box
                        style={{
                            maxHeight: "80vh",
                            overflowY: "scroll",
                            margin: isSmallScreen ? "15px 0 50px 0" : "5px 0px 0px",
                        }}
                    >
                        {loading ? (
                            <>
                                <ProductButtonSkeleton />
                                <ProductButtonSkeleton />
                                <ProductButtonSkeleton />
                            </>
                        ) : (
                            productsToDisplay.map((product) => (
                                <ProductButton
                                    onClick={(productPrice) => {
                                        if (product.variants) {
                                            setProductVariants(product.variants);
                                            return;
                                        }
                                        addItem &&
                                            addItem({
                                                itemId: product._id,
                                                name: product.name,
                                                price: productPrice,
                                                taxData: product.taxData,
                                            });
                                    }}
                                    name={product.name}
                                    price={product.price}
                                    currency={currency}
                                    product={product}
                                    isSmallScreen={isSmallScreen}
                                />
                            ))
                        )}
                    </Box>
                </Box>
            </Stack>

            <Modal
                open={productVariants !== null}
                onClose={() => setProductVariants(null)}
                yesText="none"
                noText="none"
            >
                {productVariants?.map((product) => (
                    <ProductButton
                        onClick={(productPrice) => {
                            addItem &&
                                addItem({
                                    itemId: product._id,
                                    name: product.name,
                                    price: productPrice,
                                    taxData: product.taxData,
                                });
                        }}
                        name={product.name}
                        price={product.price}
                        product={product}
                    />
                ))}
            </Modal>
        </React.Fragment>
    );
};

export default ProductsView;
