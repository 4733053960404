import React, { useEffect, useMemo, useState } from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Grid,
    LinearProgress,
    Stack,
    Typography,
} from "@mui/material";
import LuggageIcon from "@mui/icons-material/Luggage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { apiSubscription, useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { HousekeepingCard } from "./components";
import { SelectControl, SwitchControl } from "../../components/form";
import { useSnackbar } from "notistack";
import jsPDF from "jspdf";
import { DateTime } from "luxon";
import { getLoggedUserData } from "../../utils";
import { useRef } from "react";
import { Modal } from "../../components/modal";

const statusOptions = [
    {
        value: "clean",
        label: "clean",
    },
    {
        value: "inspected",
        label: "inspected",
    },
    {
        value: "dirty",
        label: "dirty",
    },
    {
        value: "occupied",
        label: "occupied",
    },
    {
        value: "roomService",
        label: "room_service",
    },
];
const HousekeepingList = () => {
    const [housekeepingsRecords, setHousekeepings] = useState([]);
    const [enableTableView, setEnableTableView] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selecedStatus, setSelectedStatus] = useState(null);

    const { t } = useTranslation();
    const tableRef = useRef(null);
    const [columns] = useState([
        { accessorKey: "room", header: t("room") },
        { accessorKey: "roomtype", header: t("roomtype") },
        { accessorKey: "status", header: t("status") },
        { accessorKey: "assignedTo", header: t("assignedTo") },
        { accessorKey: "notes", header: t("notes") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "housekeepings",
            responseData: "_id status assignedTo notes roomData{name roomTypeData{name}} userData{displayName}",
        });

        if (response?.housekeepings) {
            setHousekeepings(response.housekeepings);
        }
    };
    useEffect(() => {
        const { disconnect } = apiSubscription().openConnection(
            {
                operation: "subscription",
                endpoint: "housekeepingsRefresh",
                responseData: `updatedHousekeepings{_id status assignedTo notes roomData{name roomTypeData{name}} userData{displayName}}`,
            },
            (newData) => {
                setHousekeepings((prevResourcesState) => {
                    const housekeepingsData = prevResourcesState || [];
                    const { updatedHousekeepings } = newData?.housekeepingsRefresh || {};
                    if (!!updatedHousekeepings) {
                        updatedHousekeepings?.forEach((housekeeping) => {
                            const housekeepingIndex = housekeepingsData?.findIndex((h) => h._id === housekeeping._id);
                            if (housekeepingIndex >= 0) {
                                housekeepingsData[housekeepingIndex].status = housekeeping.status;
                            }
                        });
                    }
                    return [...housekeepingsData];
                });
            }
        );
        return () => {
            disconnect();
        };
    }, []);
    const permittedHousekeepings = useMemo(() => {
        const { role, _id } = getLoggedUserData() || {};
        const permissions = role?.permissions;
        const hasViewAllPermission = Boolean(permissions?.housekeeping?.view_all);

        return housekeepingsRecords?.filter((housekeeping) => {
            if (hasViewAllPermission) return true;
            return [_id, "", null, undefined].includes(housekeeping?.assignedTo);
        });
    }, [housekeepingsRecords]);
    const housekeepings = useMemo(() => {
        return permittedHousekeepings.map((housekeeping) => ({
            _id: housekeeping?._id,
            status: housekeeping?.status,
            assignedTo: housekeeping?.userData?.displayName || "",
            notes: housekeeping?.notes,
            room: housekeeping?.roomData?.name,
            roomtype: housekeeping?.roomData?.roomTypeData?.name,
        }));
    }, [permittedHousekeepings]);
    const changeStatus = async (id, status) => {
        const data = {
            _id: id,
            status: status,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateHousekeeping",
            data,
            responseData: "_id",
        });
        if (response?.updateHousekeeping?._id) {
            setHousekeepings(
                housekeepingsRecords.map((housekeeping) => {
                    if (housekeeping._id === id) housekeeping.status = status;
                    return housekeeping;
                })
            );
            enqueueSnackbar(t(`room_status_changed_to_${status}`), { variant: "success" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const addRecordHouseKeeping = (data) => {
        if (housekeepingsRecords?.some((r) => r._id === data._id)) {
            setHousekeepings(
                housekeepingsRecords?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setHousekeepings([...housekeepingsRecords, data]);
    };

    const exportPdf = ({ data, saveName = "" }) => {
        if (data?.length > 0) {
            const doc = new jsPDF("p", "mm", "a4");
            doc.autoTable({
                head: [[`${t("room_name")}`, `${t("room _status")}`, `${t("assigned_to")}`]],
                body: data.map((housekeeping, i) => [housekeeping.room, housekeeping.status, housekeeping.assignedTo]),
            });

            doc.save(`housekeeping_${saveName}.pdf`);
        } else enqueueSnackbar(t("no_housekeepings_with_this_status"), { variant: "warning" });
    };
    const onStatusChange = (status) => {
        setSelectedStatus(status);
    };
    const changeStatusInBulk = async () => {
        const selecedRows = tableRef?.current?.getSelectedRows();
        if (!selecedRows || selecedRows?.length === 0) {
            enqueueSnackbar(t("no_rows_selected"), { variant: "warning" });
            setOpenModal(false);
            return;
        }
        const selecedRowsIds = selecedRows?.map((row) => row._id);
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateHousekeepingsStatus",
            data: { ids: selecedRowsIds, status: selecedStatus },
        });
        if (response?.updateHousekeepingsStatus === "success") {
            enqueueSnackbar(t("status_updated_with_success"), { variant: "success" });
            setOpenModal(false);
            setHousekeepings(
                housekeepingsRecords?.map((housekeeping) => {
                    if (selecedRowsIds.includes(housekeeping._id)) {
                        housekeeping.status = selecedStatus;
                    }
                    return housekeeping;
                })
            );
        }
    };

    return (
        <div>
            <Card elevation={0} style={{ marginBottom: 20 }}>
                <CardHeader
                    titleTypographyProps={{ variant: "h6" }}
                    title={t("housekeeping")}
                    action={
                        <SwitchControl
                            value={enableTableView}
                            onChange={(e) => {
                                setEnableTableView(e.target.checked);
                            }}
                            label={t("table_view")}
                        />
                    }
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={9} spacing={1}>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <PictureAsPdfIcon />
                                    <Typography variant="h6">{t("export_pdf")}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonGroup variant="outlined">
                                    <Button
                                        onClick={() => {
                                            exportPdf({
                                                data: housekeepings?.map((housekeeping) => ({
                                                    status: t(`${housekeeping?.status}`),
                                                    assignedTo: housekeeping?.assignedTo || "",
                                                    room: housekeeping?.room,
                                                })),
                                            });
                                        }}
                                    >
                                        {t("all_rooms")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            exportPdf({
                                                data: housekeepings
                                                    ?.filter((housekeeping) => housekeeping?.status === "dirty")
                                                    ?.map((housekeeping) => ({
                                                        status: t(`${housekeeping?.status}`),
                                                        assignedTo: housekeeping?.assignedTo || "",
                                                        room: housekeeping?.room,
                                                    })),
                                                saveName: "dirty",
                                            });
                                        }}
                                    >
                                        {t("status_dirty")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            exportPdf({
                                                data: housekeepings
                                                    ?.filter((housekeeping) => housekeeping?.status === "occupied")
                                                    ?.map((housekeeping) => ({
                                                        status: t(`${housekeeping?.status}`),
                                                        assignedTo: housekeeping?.assignedTo || "",
                                                        room: housekeeping?.room,
                                                    })),
                                                saveName: "occoupied",
                                            });
                                        }}
                                    >
                                        {t("status_occupied")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            exportPdf({
                                                data: housekeepings
                                                    ?.filter((housekeeping) => housekeeping?.status === "roomService")
                                                    ?.map((housekeeping) => ({
                                                        status: t(`${housekeeping?.status}`),
                                                        assignedTo: housekeeping?.assignedTo || "",
                                                        room: housekeeping?.room,
                                                    })),
                                                saveName: "room_service",
                                            });
                                        }}
                                    >
                                        {t("room_service")}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            exportPdf({
                                                data: housekeepings
                                                    ?.filter((housekeeping) => housekeeping?.status === "inspected")
                                                    ?.map((housekeeping) => ({
                                                        status: t(`${housekeeping?.status}`),
                                                        assignedTo: housekeeping?.assignedTo || "",
                                                        room: housekeeping?.room,
                                                    })),
                                                saveName: "inspected",
                                            });
                                        }}
                                    >
                                        {t("inspected")}
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            sm={3}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Button variant="outlined" component={Link} to="/lostfounds" startIcon={<LuggageIcon />}>
                                {t("lost_and_found")}
                            </Button>

                            <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                                {DateTime.now().toFormat("yyyy-LL-dd HH:mm:ss")}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={() => setOpenModal(true)}>
                                    {t("change_status_in_bulk")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {loading && <LinearProgress color="primary" />}
            {enableTableView ? (
                <div id="table">
                    <Table
                        id="tab"
                        titleLabel={t("housekeeping")}
                        moduleType="backoffice"
                        data={housekeepings}
                        columns={columns}
                        onRowClick={(row) => navigate(`${row?._id?.split("housekeepings_rooms_")[1]}`)}
                        tableRef={tableRef}
                    />
                </div>
            ) : (
                <Grid container spacing={2}>
                    {housekeepings.map((housekeeping) => (
                        <Grid item xs={12} sm={6} md={4}>
                            <HousekeepingCard
                                t={t}
                                housekeeping={housekeeping}
                                changeStatus={(id, status) => changeStatus(id, status)}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
            <Modal
                maxWidth="xs"
                open={openModal}
                noText={"none"}
                onClose={() => {
                    setOpenModal(false);
                    setSelectedStatus(null);
                }}
                onStatusChange={onStatusChange}
                onSave={changeStatusInBulk}
            >
                <SelectControl
                    options={statusOptions}
                    label={t("status")}
                    onChange={(e) => onStatusChange(e.target.value)}
                />
            </Modal>
            <Outlet context={{ addRecordHouseKeeping }} />
        </div>
    );
};

export default HousekeepingList;
