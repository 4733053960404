import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { Modal } from "../modal";
import { useTranslation } from "react-i18next";

const DeleteRecordModal = ({ onConfirm }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openModal: false,
    });

    return (
        <>
            <Button
                startIcon={<DeleteIcon />}
                variant="outlined"
                onClick={() => setState({ ...state, openModal: true })}
                fullWidth
            >
                {t("delete")}
            </Button>
            <Modal
                maxWidth="xs"
                open={state.openModal}
                onClose={() => setState({ ...state, openModal: false })}
                onSave={() => {
                    onConfirm();
                    setState({ ...state, openModal: false });
                }}
                noText={t("cancel")}
                yesText={t("confirm")}
            >
                {t("are_you_sure_you_want_to_delete")}?
            </Modal>
        </>
    );
};
export default DeleteRecordModal;
