import { Chip } from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";
import React from "react";

const TagsChip = ({ color, name }) => {
    return (
        <Chip
            style={{
                color: color,
                backgroundColor: color + "20",
                border: "1px solid",
                borderColor: color,
                cursor: "pointer",
                fontWeight: 600,
                fontSize: 14,
            }}
            icon={<SellIcon color={color} />}
            label={name}
        />
    );
};

export default TagsChip;
