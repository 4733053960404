const applyDiscount = (amount, discount) => {
    if (amount === undefined || amount === null) throw new Error("missing amount");
    if (!discount?.type || !discount?.value) return amount;
    switch (discount.type) {
        case "total":
            return amount - discount.value;
        case "percentage":
            return amount - amount * discount.value * 0.01;
        default:
            return amount;
    }
};

export default applyDiscount;
