import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../../components/hooks";
import { Table } from "../../../components/table";

const ProductMainCategoryList = () => {
    const [productMainCategories, setProductMainCategories] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([{ accessorKey: "name", header: t("name") }]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "productmaincategories",
            responseData: "_id name",
        });
        if (response?.productmaincategories) setProductMainCategories(response.productmaincategories);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteProductMainCategories" : "deleteProductMainCategory",
            data,
        });
        if (response?.deleteProductMainCategories === "success" || response?.deleteProductMainCategory === "success") {
            setProductMainCategories(
                productMainCategories.filter(
                    (productMainCategory) => !rows.some((row) => row._id === productMainCategory._id)
                )
            );
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addRecordProductMainCategory = (data) => {
        if (productMainCategories?.some((r) => r._id === data._id)) {
            setProductMainCategories(
                productMainCategories?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setProductMainCategories([...productMainCategories, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("product_main_category_form")}
                moduleType="pos"
                data={productMainCategories}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("productmaincategories_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordProductMainCategory }} />
        </div>
    );
};

export default ProductMainCategoryList;
