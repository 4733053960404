import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutoCompleteControl, CheckboxControl, InputControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import currency from "../../assests/currency";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const currenciesOptions = currency.map((option) => option.CtryNm);

const rules = {
    country: "string|required",
    currencyname: "string|required",
    currency: "string|required",
    rate: "required",
};

const CurrencyForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordCurrency } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "currency",
            data: {
                _id: "currencies_" + params?.id,
            },
            responseData: "_id country currencyname currency rate active",
        });
        if (response?.currency) setValues(response.currency);
    };

    const handleCountryChange = (event) => {
        const country = event?.target?.value;
        const countryData = currency.find((c) => c.CtryNm === country);
        const { CcyNm, Ccy, CtryNm } = countryData || {};
        setValues({
            ...values,
            country: CtryNm,
            currencyname: CcyNm,
            currency: Ccy,
        });
    };

    const saveHandler = async () => {
        const { country, currencyname, currency, rate, active, _id } = values;
        const data = {
            country,
            currencyname,
            currency,
            rate: parseFloat(rate),
            active: Boolean(active),
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateCurrency" : "createCurrency",
            data,
            responseData: "_id country currencyname currency rate active",
        });
        if (response?.createCurrency?._id || response?.updateCurrency?._id) {
            enqueueSnackbar(t("currency_saved"), { variant: "default" });
            addRecordCurrency && addRecordCurrency(response.createCurrency || response?.updateCurrency);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("currency")}
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ currencies: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <AutoCompleteControl
                        options={currenciesOptions}
                        name="country"
                        label={t("country")}
                        onChange={handleCountryChange}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <InputControl name="currency" label={t("currency")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="currencyname" label={t("currency_name")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl type="number" name="rate" label={t("conversion_rate")} />
                </Grid>
                <Grid item xs={6}>
                    <CheckboxControl name="active" label={t("active")} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CurrencyForm;
