import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DateControl } from "../dates";
import { Form, InputControl, SelectControl } from "../form";
import { Modal } from "../modal";
import { useApi } from "../hooks";
import { ProtectedAction } from "../../utils";
const rules = { amount: "required", paymentMethod: "required" };
const PaymentsModal = ({ open, t, onClose, addPayment, currency }) => {
    const [values, setValues] = useState({});
    const [disableConfirm, setDisableConfirm] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const { fetch } = useApi();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "paymentmethods",
                    responseData: "_id name method fiscalMethod isActive",
                },
            ],
        });
        if (!!response?.paymentmethods)
            setPaymentMethods(
                response.paymentmethods
                    ?.filter((p) => p?.isActive && p?.method !== "roomCharge")
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                        };
                    })
            );
    };
    useEffect(() => loadData(), []);
    useEffect(() => {
        if (!open) return;
        setDisableConfirm(false);
    }, [open]);

    return (
        <Modal
            titlelabel={t("add_payment")}
            maxWidth="xs"
            open={open}
            onClose={() => onClose(false)}
            yesDisabled={disableConfirm}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: () => {
                    setDisableConfirm(true);
                    addPayment(values);
                },
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="amount" label={t("amount")} type="number" toCurrency={currency} />
                    <SelectControl name="paymentMethod" label={t("payment_method")} options={paymentMethods} />
                    <ProtectedAction permission={{ payments: { edit: true } }}>
                        <DateControl name="timestamp" label={t("date")} />
                    </ProtectedAction>
                    <InputControl name="note" label={t("note")} multiline minRows={2} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default PaymentsModal;
