import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Table.module.css";
import { CheckboxControl } from "../form";

const moduleColors = {
    default: "#30aabc",
    accounting: "#007B44",
    frontdesk: "#03363D",
    backoffice: "#832700",
    pos: "#86B3BB",
    users: "#DF7323",
};

const TableToolbar = ({
    columns = [],
    enableFilters,
    setEnableFilters,
    moduleType = "default",
    titleLabel = "",
    onCreateClick,
    onDeleteClick,
    onColumnsSaveClick,
    disableDelete = false,
    disableColumnsSave = false,
    exportTo,
}) => {
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [anchorColumnsMenu, setAnchorColumnsMenu] = useState(null);

    const handleWidgetsClick = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleFavoritesClick = (event) => {
        setAnchorColumnsMenu(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorMenu(null);
    };
    const handleColumnsMenuClose = () => {
        setAnchorColumnsMenu(null);
    };
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                backgroundColor: "transparent",
                color: moduleColors[moduleType] || moduleColors.default,
            }}
        >
            <div className={styles.title}>
                <Typography variant="h6">{titleLabel}</Typography>
            </div>

            <div className={styles.actionsContainer}>
                <Tooltip title={t("filter_list")}>
                    <IconButton
                        aria-label={t("filter_list")}
                        size="large"
                        onClick={() => setEnableFilters(!enableFilters)}
                    >
                        {enableFilters ? (
                            <SearchOffIcon
                                style={{
                                    color: moduleColors[moduleType] || moduleColors.default,
                                }}
                            />
                        ) : (
                            <SearchIcon
                                style={{
                                    color: moduleColors[moduleType] || moduleColors.default,
                                }}
                            />
                        )}
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("print")}>
                    <IconButton onClick={() => exportTo("pdf")} aria-label={t("print")} size="large">
                        <PrintIcon
                            style={{
                                color: moduleColors[moduleType] || moduleColors.default,
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("export")}>
                    <IconButton onClick={() => exportTo("csv")} aria-label={t("export")} size="large">
                        <DescriptionIcon
                            style={{
                                color: moduleColors[moduleType] || moduleColors.default,
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("favorite_columns")}>
                    <IconButton onClick={handleFavoritesClick} aria-label={t("favorite_columns")} size="large">
                        <ViewWeekIcon
                            style={{
                                color: moduleColors[moduleType] || moduleColors.default,
                            }}
                        />
                    </IconButton>
                </Tooltip>

                {!disableDelete && (
                    <Tooltip title={t("delete")}>
                        <IconButton
                            onClick={onDeleteClick}
                            disabled={disableDelete}
                            aria-label={t("delete")}
                            size="large"
                        >
                            <DeleteIcon
                                style={{
                                    color: disableDelete ? "#c8c8c8" : moduleColors[moduleType] || moduleColors.default,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                )}
                {onCreateClick !== undefined && (
                    <Tooltip title={t("add")}>
                        <Button
                            disableElevation
                            aria-label={t("add")}
                            onClick={onCreateClick}
                            variant="contained"
                            style={{
                                marginLeft: 5,
                                backgroundColor: moduleColors[moduleType] || moduleColors.default,
                            }}
                        >
                            <AddIcon />
                            {t("add_new")}
                        </Button>
                    </Tooltip>
                )}
            </div>
            <div className={styles.actionsContainerMobile}>
                <IconButton onClick={handleWidgetsClick} aria-owns={anchorMenu ? "widgets-menu" : null} size="large">
                    <MoreVertIcon
                        style={{
                            color: moduleColors[moduleType] || moduleColors.default,
                        }}
                    />
                </IconButton>
                <Menu id="widgets-menu" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={handleMenuClose}>
                    {onCreateClick !== undefined && (
                        <MenuItem
                            onClick={() => {
                                onCreateClick && onCreateClick();
                                handleMenuClose();
                            }}
                        >
                            <AddIcon
                                style={{
                                    color: moduleColors[moduleType] || moduleColors.default,
                                }}
                            />
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            setEnableFilters(!enableFilters);
                            handleMenuClose();
                        }}
                    >
                        {enableFilters ? (
                            <SearchOffIcon
                                style={{
                                    color: moduleColors[moduleType] || moduleColors.default,
                                }}
                            />
                        ) : (
                            <SearchIcon
                                style={{
                                    color: moduleColors[moduleType] || moduleColors.default,
                                }}
                            />
                        )}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            exportTo("pdf");
                            handleMenuClose();
                        }}
                    >
                        <PrintIcon
                            style={{
                                color: moduleColors[moduleType] || moduleColors.default,
                            }}
                        />
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            exportTo("csv");
                            handleMenuClose();
                        }}
                    >
                        <DescriptionIcon
                            style={{
                                color: moduleColors[moduleType] || moduleColors.default,
                            }}
                        />
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            handleFavoritesClick(event);
                            // handleMenuClose();
                        }}
                    >
                        <StarIcon
                            style={{
                                color: moduleColors[moduleType] || moduleColors.default,
                            }}
                        />
                    </MenuItem>
                    <MenuItem
                        disabled={disableDelete}
                        onClick={() => {
                            onDeleteClick && onDeleteClick();
                            handleMenuClose();
                        }}
                    >
                        <DeleteIcon
                            style={{
                                color: disableDelete ? "#c8c8c8" : moduleColors[moduleType] || moduleColors.default,
                            }}
                        />
                    </MenuItem>
                </Menu>
            </div>
            <Menu
                id="columns-menu"
                anchorEl={anchorColumnsMenu}
                open={Boolean(anchorColumnsMenu)}
                onClose={handleColumnsMenuClose}
            >
                {columns
                    .filter((column) => column.id !== "select")
                    .map((column) => {
                        return (
                            <MenuItem disableRipple disableTouchRipple>
                                <ListItemIcon>
                                    <CheckboxControl
                                        value={column.getIsVisible()}
                                        onChange={column.getToggleVisibilityHandler()}
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText>{t(`${column.id}`)}</ListItemText>
                            </MenuItem>
                        );
                    })}

                <Button
                    disableElevation
                    onClick={onColumnsSaveClick}
                    disabled={disableColumnsSave}
                    variant="contained"
                    sx={{ marginLeft: "15px" }}
                >
                    {t("save_columns")}
                </Button>
            </Menu>
        </Toolbar>
    );
};

export default TableToolbar;
