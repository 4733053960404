import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputControl, SwitchControl } from "../form";
import { Modal } from "../modal";

const CancelRecordConfirm = ({ onConfirm, disabled = false, showUpdateInventory = false }) => {
    const [openModal, setOpenModal] = useState(false);
    const [cancelReason, setCancelReason] = useState(null);
    const [updateInventory, setUpdateInventory] = useState(true);
    const { t } = useTranslation();
    return (
        <>
            <Button
                disabled={disabled}
                onClick={() => setOpenModal(true)}
                color="secondary"
                variant="outlined"
                startIcon={<CancelIcon />}
            >
                {t("void")}
            </Button>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                yesText={t("confirm")}
                onSave={() => {
                    onConfirm({ cancelReason, updateInventory });
                    setOpenModal(false);
                }}
                maxWidth="xs"
            >
                {showUpdateInventory && (
                    <SwitchControl
                        label={t("return_order_stock_to_inventory")}
                        value={updateInventory}
                        onChange={(e) => setUpdateInventory(e.target.checked)}
                    />
                )}
                <InputControl
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                    label={t("void_reason")}
                    multiline
                    minRows={4}
                />
            </Modal>
        </>
    );
};

export default CancelRecordConfirm;
