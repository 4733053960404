import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckboxControl, InputControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { ClientsSearch } from "../../components/common";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const rules = {
    name: "string|required",
};
const daysOptions = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday", "all"];
const mealsOptions = ["breakfast", "lunch", "dinner", "all_inclusive"];

const RateForm = () => {
    const [values, setValues] = useState({
        availableTo: "all",
    });
    const [servicesOptions, setServicesOptions] = useState([]);
    const [roomtypeOptions, setRoomtypeOptions] = useState([]);
    const [guestcategories, setGuestcategories] = useState([]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { addRecordRate } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "roomrate",
            data: {
                _id: "roomrates_" + params?.id,
            },
            responseData: `_id name meals services roomTypes maxCapacity includedCapacity minimumNights maximumNights minimumAdvance maximumAdvance 
                availableTo availableToData{firstName lastName} isBeds24Rate beds24Rate beds24RoomtypePriceId`,
        });
        if (response?.roomrate) setValues(response.roomrate);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                // {
                //     endpoint: "services",
                //     responseData: "_id name",
                // },
                {
                    endpoint: "roomtypes",
                    responseData: "_id name",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name",
                },
            ],
        });
        // if (response?.services) setServicesOptions(response.services);
        if (response?.roomtypes) {
            setRoomtypeOptions(response.roomtypes);
        }
        if (response?.guestcategories) setGuestcategories(response.guestcategories);
    };

    const saveHandler = async () => {
        const {
            name,
            category,
            meals,
            services,
            roomTypes,
            minimumNights,
            maximumNights,
            minimumAdvance,
            maximumAdvance,
            _id,
            availableTo,
            isBeds24Rate,
            beds24Rate,
            beds24RoomtypePriceId,
            maxCapacity,
            includedCapacity,
        } = values;
        const data = {
            name,
            category,
            meals,
            services,
            roomTypes,
            minimumNights: !!minimumNights ? parseInt(minimumNights) : null,
            maximumNights: !!maximumNights ? parseInt(maximumNights) : null,
            minimumAdvance: !!minimumAdvance ? parseInt(minimumAdvance) : null,
            maximumAdvance: !!maximumAdvance ? parseInt(maximumAdvance) : null,
            availableTo,
            isBeds24Rate,
            beds24RoomtypePriceId,
            beds24Rate,
            maxCapacity: isNaN(parseFloat(maxCapacity)) ? null : parseFloat(maxCapacity),
            includedCapacity: isNaN(parseFloat(includedCapacity)) ? null : parseFloat(includedCapacity),
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateRoomRate" : "createRoomRate",
            data,
            responseData: `_id name meals services roomTypes maxCapacity includedCapacity minimumNights maximumNights minimumAdvance maximumAdvance 
            availableTo availableToData{firstName lastName} isBeds24Rate beds24Rate beds24RoomtypePriceId`,
        });
        if (response?.createRoomRate?._id || response?.updateRoomRate?._id) {
            enqueueSnackbar(t("roomrate_saved"), { variant: "default" });
            addRecordRate && addRecordRate(response.createRoomRate || response?.updateRoomRate);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("room_rate")}
            fullScreen
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ roomrates: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler }}
        >
            <Grid container spacing={4} alignItems="flex-start">
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("roomrate_details")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>
                    <Grid item xs={6}>
                        <ClientsSearch
                            extraOptions={[{ value: "all", label: t("all_clients") }]}
                            onClientSelect={(data) =>
                                data.value && data.value === "all"
                                    ? setValues({ ...values, availableTo: "all" })
                                    : setValues({ ...values, availableTo: data._id, availableToData: data })
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontStyle="italic">
                            {values.availableTo && values.availableTo !== "all"
                                ? `${t("available_to")} ${values?.availableToData?.firstName} ${
                                      values?.availableToData?.lastName
                                  }`
                                : t("available_to_all_clients")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SwitchControl name="isBeds24Rate" label={t("connect_to_channels")} />
                        {!!values?.isBeds24Rate && (
                            <>
                                <InputControl name="beds24Rate" label={t("channel_rate_id")} />
                                <InputControl name="beds24RoomtypePriceId" label={t("price_id")} />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("roomtypes")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {roomtypeOptions.map((roomtype) => (
                            <CheckboxControl
                                value={values?.roomTypes?.includes(roomtype._id) || false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({
                                            ...values,
                                            roomTypes: [...(values.roomTypes || []), roomtype._id],
                                        });
                                    else
                                        setValues({
                                            ...values,
                                            roomTypes: values.roomTypes.filter((m) => m !== roomtype._id),
                                        });
                                }}
                                label={roomtype.name}
                            />
                        ))}
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("included_meals")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {mealsOptions.map((meal) => (
                            <CheckboxControl
                                value={values?.meals?.includes(meal) || false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({ ...values, meals: [...(values.meals || []), meal] });
                                    else setValues({ ...values, meals: values.meals.filter((m) => m !== meal) });
                                }}
                                label={t(meal)}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("nights")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name={"minimumNights"} label={t("minimumNights")} type="number" />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name={"maximumNights"} label={t("maximumNights")} type="number" />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name={"minimumAdvance"} label={t("minimumAdvance")} type="number" />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name={"maximumAdvance"} label={t("maximumAdvance")} type="number" />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("guest_capacity")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name={"maxCapacity"} label={t("capacity")} type="number" />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name={"includedCapacity"} label={t("included_capacity")} type="number" />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("services")}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {servicesOptions.map((service) => (
                            <CheckboxControl
                                value={values?.services?.includes(service.name) || false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        setValues({
                                            ...values,
                                            services: [...(values.services || []), service.name],
                                        });
                                    else
                                        setValues({
                                            ...values,
                                            services: values.services.filter((m) => m !== service.name),
                                        });
                                }}
                                label={service.name}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default RateForm;
