import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxControl, SelectControl } from "../form";
import { EditableTable } from "../table";

const EditableTaxesTable = ({ taxes = [], data = [], onChange }) => {
    const taxOptions = taxes.map((tax) => ({ value: tax._id, label: tax.name }));
    const { t } = useTranslation();

    const columns = [
        {
            header: t("tax"),
            cell: ({ rowIndex, row }) => {
                return (
                    <SelectControl
                        value={row?.taxId || ""}
                        onChange={(e) => {
                            const newData = JSON.parse(JSON.stringify(data));
                            newData[rowIndex] = { taxId: e.target.value };
                            onChange && onChange(newData);
                        }}
                        options={taxOptions}
                        label={t("tax")}
                    />
                );
            },
        },
        {
            header: t("is_it_included"),
            cell: ({ rowIndex, row }) => (
                <CheckboxControl
                    value={row?.isIncluded || ""}
                    onChange={(e) => {
                        const newData = JSON.parse(JSON.stringify(data));
                        newData[rowIndex].isIncluded = e.target.checked;
                        onChange && onChange(newData);
                    }}
                />
            ),
        },
    ];

    return (
        <EditableTable
            onAddRow={() => onChange([...data, {}])}
            onDeleteRow={(rowIndex) => onChange(data.filter((r, index) => index !== rowIndex))}
            columns={columns}
            data={data}
        />
    );
};

export default EditableTaxesTable;
