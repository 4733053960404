import { Button, Card, CardContent, CardHeader, Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { RoomsTable } from "./components/blockedRoomsView";

const BlockRoomReservationView = () => {
    const { t } = useTranslation();
    const params = useParams();

    const [reservation, setReservation] = useState({});

    useEffect(() => {
        loadData();
    }, []);

    const fetchReservationApi = useApi();
    const loadData = async () => {
        const response = await fetchReservationApi.fetch({
            operation: "query",
            endpoint: "reservation",
            data: {
                _id: "reservations_" + params.id,
            },
            responseData:
                "_id checkin checkout status isBlockedRooms type blockedName blockedReason blockedExpireDate rooms{roomId roomtypeId roomData{name} roomtypeData{name}} notes{note}",
        });
        if (response?.reservation) setReservation(response?.reservation);
    };

    const { convertOnHoldToNewReservation } = useOutletContext() || {};

    const navigate = useNavigate();
    const closeHandler = () => {
        navigate(-1);
    };

    const { enqueueSnackbar } = useSnackbar();
    const saveReservationApi = useApi();
    const onFreeRooms = async () => {
        const reservationId = "reservations_" + params.id;
        const response = await saveReservationApi.fetch({
            operation: "mutation",
            endpoint: "deleteReservation",
            data: {
                _id: reservationId,
            },
        });
        if (response?.deleteReservation === "success") {
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
            closeHandler();
        }
    };

    const changeStatus = async (newStatus) => {
        const reservationId = "reservations_" + params.id;
        const response = await saveReservationApi.fetch({
            operation: "mutation",
            endpoint: "updateBlockedRoomsStatus",
            data: {
                _id: reservationId,
                status: newStatus.toUpperCase(),
            },
            responseData: "_id status",
        });
        if (response?.updateBlockedRoomsStatus?._id) {
            enqueueSnackbar(t("status_updated"), { variant: "default" });
            setReservation({ ...reservation, ...response?.updateBlockedRoomsStatus });
        }
    };

    const convertToReservation = async () => {
        const reservationId = "reservations_" + params.id;
        const response = await saveReservationApi.fetch({
            operation: "mutation",
            endpoint: "deleteReservation",
            data: {
                _id: reservationId,
            },
        });
        if (response?.deleteReservation === "success") {
            if (convertOnHoldToNewReservation) {
                convertOnHoldToNewReservation({
                    checkin: reservation?.checkin,
                    checkout: reservation?.checkout,
                    roomIds: reservation?.rooms?.map((room) => room.roomId),
                });
                closeHandler();
            }
        }
    };

    return (
        <Modal
            titlelabel={<ModalTitle loading={fetchReservationApi.loading} title={t(reservation?.type)} />}
            onClose={closeHandler}
            open
            yesText={
                !reservation?.type
                    ? "none"
                    : reservation?.type === "OUT_OF_ORDER"
                    ? t("mark_as_solved")
                    : t("create_reservation")
            }
            maxWidth="md"
            onSave={() => {
                if (reservation?.type === "ON_HOLD") {
                    convertToReservation();
                } else if (reservation?.type === "OUT_OF_ORDER") {
                    changeStatus("DONE");
                }
                return;
            }}
            yesDisabled={reservation?.status === "DONE" || saveReservationApi?.loading}
            loading={saveReservationApi?.loading}
        >
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={12} sm={6}>
                    <ReservationDetails
                        onFreeRooms={onFreeRooms}
                        reservation={reservation}
                        loading={fetchReservationApi.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ReservationExtraDetails reservation={reservation} loading={fetchReservationApi.loading} />
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <RoomsTable rooms={reservation?.rooms || []} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default BlockRoomReservationView;

const ModalTitle = ({ loading, title }) => {
    if (loading) return <Skeleton width={300} height={30} />;
    return title;
};

const ReservationDetails = ({ loading, reservation, onFreeRooms }) => {
    const { t } = useTranslation();
    if (loading)
        return (
            <Stack alignItems="start">
                <Skeleton width={300} height={20} />
                <Skeleton width={300} height={20} />
                <Skeleton width={300} height={20} />
            </Stack>
        );

    return (
        <Stack alignItems="start">
            <Typography variant="subtitle1">
                {t("name")}: {reservation?.blockedName || ""}
            </Typography>
            {reservation?.type === "OUT_OF_ORDER" ? (
                <Typography variant="body1">
                    {t("reason")}: {reservation?.blockedReason || ""}
                </Typography>
            ) : (
                <>
                    <Typography variant="body1">
                        {t("hold_rooms_till")}:
                        {!!reservation?.blockedExpireDate
                            ? DateTime.fromISO(reservation?.blockedExpireDate).toFormat("dd LLL yyyy HH:mm")
                            : t("no_end_date")}
                    </Typography>
                    <Typography variant="body1">{t("created_by")}:</Typography>
                    <Button onClick={onFreeRooms} sx={{ marginTop: 2 }} variant="outlined">
                        {t("free_rooms_now")}
                    </Button>
                </>
            )}
        </Stack>
    );
};

const statusStyles = {
    ACTIVE: {
        backgroundColor: "rgba(45, 150, 255, 0.1)",
        color: "rgb(45, 150, 255)",
    },
    TO_DO: {
        backgroundColor: "rgba(43, 204, 255, 0.1)",
        color: "rgb(43, 204, 255)",
    },
    EXPIRED: {
        backgroundColor: "rgba(255, 179, 0, 0.1)",
        color: "rgb(255, 179, 0)",
    },
    IN_PROGRESS: {
        backgroundColor: "rgba(45, 150, 255, 0.1)",
        color: "rgb(45, 150, 255)",
    },
    REVIEW: {
        backgroundColor: "rgba(250,216,0, 0.1)",
        color: "rgb(250,216,0)",
    },
    DONE: {
        backgroundColor: "rgba(87, 240, 0, 0.1)",
        color: "rgb(87, 240, 0)",
    },
};

const ReservationExtraDetails = ({ loading, reservation }) => {
    const { t } = useTranslation();
    const status = useMemo(() => {
        if (reservation?.type === "OUT_OF_ORDER") return reservation?.status;
        if (!!reservation?.blockedExpireDate && DateTime.now() > DateTime.fromISO(reservation?.blockedExpireDate)) {
            return "EXPIRED";
        }
        return "ACTIVE";
    }, [reservation]);
    if (loading)
        return (
            <Stack alignItems={"end"}>
                <Chip sx={{ width: 100 }} />
                <Skeleton width={200} height={30} />
                <Card sx={{ marginTop: 2 }}>
                    <CardHeader
                        sx={{ padding: 1, paddingBottom: 0 }}
                        title={t("notes") + ":"}
                        titleTypographyProps={{ variant: "body1" }}
                    />
                    <CardContent sx={{ padding: 1, paddingTop: 0 }}>
                        <Skeleton width={300} height={20} />
                    </CardContent>
                </Card>
            </Stack>
        );

    return (
        <Stack alignItems={"end"}>
            <Chip
                label={t(status)}
                sx={{ ...statusStyles[status], fontWeight: 600, fontSize: 16, border: "1px solid" }}
            />
            <Typography variant="h6">
                {DateTime.fromISO(reservation?.checkin).toFormat("dd LLL yyyy")} -
                {DateTime.fromISO(reservation?.checkout).toFormat("dd LLL yyyy")}
            </Typography>
            <Card sx={{ marginTop: 2 }}>
                <CardHeader
                    sx={{ padding: 1, paddingBottom: 0 }}
                    title={t("notes") + ":"}
                    titleTypographyProps={{ variant: "body1" }}
                />
                <CardContent sx={{ padding: 1, paddingTop: 0 }}>
                    <Typography variant="body2">{reservation?.notes?.[0]?.note || ""}</Typography>
                </CardContent>
            </Card>
        </Stack>
    );
};
