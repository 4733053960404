import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectControl } from "../../../../components/form";
import { Modal } from "../../../../components/modal";

const TransferOrderToInvoiceModal = ({ invoicesOptions = [], transferOrderToInvoice }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openModal: false,
        selectedInvoice: null,
    });

    return (
        <>
            <Button variant="outlined" onClick={() => setState({ ...state, openModal: true })}>
                {t("transfer_to_invoice")}
            </Button>
            <Modal
                maxWidth="xs"
                open={state.openModal}
                onClose={() => setState({ ...state, openModal: false, selectedInvoice: null })}
                onSave={() => {
                    transferOrderToInvoice && transferOrderToInvoice(state?.selectedInvoice);
                    setState({ ...state, openModal: false, selectedInvoice: null });
                }}
                noText="none"
                yesText={t("confirm")}
                yesDisabled={!state.selectedInvoice}
            >
                <SelectControl
                    onChange={(e) => setState({ ...state, selectedInvoice: e.target.value })}
                    value={state?.selectedInvoice}
                    options={invoicesOptions}
                    label={t("choose_invoice")}
                />
            </Modal>
        </>
    );
};

export default TransferOrderToInvoiceModal;
