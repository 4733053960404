import { Button, CardHeader, Chip } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MergeIcon from "@mui/icons-material/Merge";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckboxControl } from "../../../components/form";
import { Table } from "../../../components/table";
import { toCurrency } from "../../../utils";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    PAID: "#59C086", //fully paid
    TRANSFERRED: "#FFC82C", //transferred to group
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const InvoicesTable = ({ data = [], createGroupInvoice, removeInvoiceFromGroup }) => {
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const columns = [
        {
            id: "select",
            header: "",
            cell: ({ row: { original } }) => (
                <CheckboxControl
                    disabled={
                        original?.status === "TRANSFERRED" ||
                        original?.reservationIds?.some((id) => id?.startsWith("groups"))
                    }
                    value={selectedInvoices?.some((inv) => {
                        if (original?._id) return inv._id === original?._id;
                        return inv._id === original.reservationIds?.[0];
                    })}
                    onChange={(e) => {
                        if (e.target.checked)
                            setSelectedInvoices([
                                ...selectedInvoices,
                                {
                                    ...original,
                                    _id: original?._id || original?.reservationIds?.[0],
                                },
                            ]);
                        else
                            setSelectedInvoices(
                                selectedInvoices?.filter((i) => {
                                    if (original._id) return i._id !== original._id;
                                    return i._id !== original.reservationIds?.[0];
                                })
                            );
                    }}
                />
            ),
        },
        {
            id: "name",
            header: t("name"),
            cell: ({ row: { original } }) =>
                (original?.clientsData?.firstName || "") + " " + (original?.clientsData?.lastName || ""),
        },
        {
            id: "rooms",
            header: t("rooms"),
            cell: ({ row: { original } }) => original?.rooms || t("group_invoice"),
        },
        // { accessorKey: "number", header: t("number") },
        { accessorKey: "date", header: t("date") },
        {
            accessorKey: "totalPrice",
            header: t("amount"),
            cell: ({ row: { original } }) => toCurrency(original?.totalPrice, original?.invoiceCurrency || ""),
        },
        {
            id: "status",
            header: "",
            cell: ({ row: { original } }) => {
                let status =
                    !original?.paymentStatus || original?.paymentStatus === "UNPAID"
                        ? original?.status
                        : original?.paymentStatus;
                if (["CANCELLED", "TRANSFERRED"].includes(original.status)) {
                    status = original.status;
                }
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={status} />;
            },
        },
        {
            id: "link",
            header: "",
            cell: ({ row: { original } }) => (
                <Button
                    startIcon={<BorderColorIcon />}
                    fullWidth
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        if (!original?._id) {
                            navigate("/finance/invoices/create", { state: { newInvoiceData: original } });
                            return;
                        }
                        if (original?._id?.startsWith("groupinvoices_")) {
                            const id = original?._id?.split("groupinvoices_")?.[1];
                            const groupId = id?.split("_")?.[0];
                            const invoiceId = id?.split("_")?.[1];
                            navigate("/finance/invoices/" + invoiceId + `?groupId=${groupId}`);
                        } else navigate("/finance/invoices/" + original?._id?.split("invoices_")?.[1]);
                    }}
                >
                    {t("edit")}
                </Button>
            ),
        },
        {
            id: "remove_group",
            header: "",
            cell: ({ row: { original } }) =>
                original?.status !== "TRANSFERRED" ? null : (
                    <Button
                        disabled={original?.status !== "TRANSFERRED"}
                        fullWidth
                        onClick={() => {
                            removeInvoiceFromGroup && removeInvoiceFromGroup(original?._id);
                        }}
                        color="secondary"
                    >
                        {t("revert_transfer")}
                    </Button>
                ),
        },
    ];

    return (
        <>
            <CardHeader
                action={
                    selectedInvoices?.length > 0 && (
                        <Button
                            onClick={() => {
                                createGroupInvoice(selectedInvoices);
                                setSelectedInvoices([]);
                            }}
                            variant="outlined"
                        >
                            {t("add_to_group_invoice")}
                        </Button>
                    )
                }
                avatar={<ReceiptIcon />}
                title={t("invoices")}
                titleTypographyProps={{ variant: "h5" }}
            />
            <Table data={data} columns={columns} disableHeader disableSelection disableDelete disableFooter />
        </>
    );
};

export default InvoicesTable;
