import { applyDiscount, generateDatesBetween } from "../../utils";

class ReservationHandler {
    constructor({
        checkin,
        checkout,
        rooms,
        roomtypes,
        guests,
        clients,
        arrivalTime,
        departureTime,
        doNotDisturb,
        discount,
        dailyAvailabilities,
        notes,
        isGroupReservation,
        groupName,
        isSimpleReservation,
        isBlockedRooms,
        guestCategories,
    }) {
        this.checkin = checkin;
        this.checkout = checkout;
        this.clients = clients;
        this.rooms = rooms;
        this.roomtypes = roomtypes;
        this.guests = guests;
        this.arrivalTime = arrivalTime;
        this.departureTime = departureTime;
        this.doNotDisturb = doNotDisturb;
        this.discount = discount;
        this.notes = notes;
        this.isGroupReservation = isGroupReservation;
        this.groupName = groupName;
        this.isSimpleReservation = isSimpleReservation;
        this.isBlockedRooms = isBlockedRooms;

        this.dailyAvailabilities = dailyAvailabilities;
        this.guestCategories = guestCategories;
    }

    static getRoomDailyPrice = (day, dailyAvailabilities, roomData) => {
        if (!roomData) throw new Error("room data missing");

        const roomtypeDayPrice = dailyAvailabilities?.roomtypes?.[roomData.roomtypeId]?.[day]?.price;
        let defaultPrice =
            roomtypeDayPrice !== undefined && roomtypeDayPrice !== null ? roomtypeDayPrice : roomData.price;

        if (roomData.rate && roomData.rate !== "standard") {
            const rateDayPrice = dailyAvailabilities?.rates?.[roomData.rate]?.[day]?.price;
            return rateDayPrice !== undefined && rateDayPrice !== null ? rateDayPrice : defaultPrice;
        }

        return defaultPrice;
    };
    static getRoomDailyCapacity = (days, dailyAvailabilities, roomData) => {
        if (!roomData) throw new Error("room data missing");
        let includedCapacity = parseInt(roomData?.includedCapacity);
        const roomCapacity = !!roomData?.capacity && roomData?.capacity?.length > 0 ? roomData?.capacity : [];
        const guestsDailyCapacity = {};
        roomCapacity?.forEach((guestCategory) => {
            const dailyPrices = {};
            days?.forEach((day) => {
                const price = parseFloat(guestCategory.price);
                dailyPrices[day] = isNaN(price) ? 0.0 : guestCategory.price;
            });
            guestsDailyCapacity[guestCategory.id] = dailyPrices;
        });

        const roomtypeDailyAvailability = dailyAvailabilities?.roomtypes?.[roomData.roomtypeId];
        if (!!roomtypeDailyAvailability) {
            const roomtypeIncludedCapacity = parseInt(roomtypeDailyAvailability?.[days[0]]?.includedCapacity);
            if (!isNaN(roomtypeIncludedCapacity)) {
                includedCapacity = roomtypeIncludedCapacity;
            }
            days?.forEach((day) => {
                const roomtypeDailyCapacity = roomtypeDailyAvailability?.[day]?.capacity;
                if (!roomtypeDailyCapacity || roomtypeDailyCapacity?.length === 0) return;
                roomtypeDailyCapacity?.forEach((guestCategory) => {
                    const dayPrice = parseFloat(guestCategory?.price);
                    if (isNaN(dayPrice)) return;
                    if (!guestsDailyCapacity[guestCategory.id]) {
                        guestsDailyCapacity[guestCategory.id] = {};
                    }
                    guestsDailyCapacity[guestCategory.id][day] = dayPrice;
                });
            });
        }

        const hasSelectedRate = Boolean(!!roomData.rate && roomData.rate !== "standard");
        if (hasSelectedRate) {
            const rateDailyAvailability = dailyAvailabilities?.rates?.[roomData.rate];
            const rateIncludedCapacity = parseInt(rateDailyAvailability?.[days[0]]?.includedCapacity);
            if (!isNaN(rateIncludedCapacity)) {
                includedCapacity = rateIncludedCapacity;
            }
            days?.forEach((day) => {
                const rateDailyCapacity = rateDailyAvailability?.[day]?.capacity;
                if (!rateDailyCapacity || rateDailyCapacity?.length === 0) return;
                rateDailyCapacity?.forEach((guestCategory) => {
                    const dayPrice = parseFloat(guestCategory?.price);
                    if (isNaN(dayPrice)) return;
                    if (!guestsDailyCapacity[guestCategory.id]) {
                        guestsDailyCapacity[guestCategory.id] = {};
                    }
                    guestsDailyCapacity[guestCategory.id][day] = dayPrice;
                });
            });
        }
        const roomAverageCapacity = [];
        Object.entries(guestsDailyCapacity)?.forEach(([categoryId, dailyPrices]) => {
            const dailyPricesAsArray = Object.values(dailyPrices);
            const averagePrice =
                (dailyPricesAsArray?.reduce((total, price) => {
                    total += price;
                    return total;
                }, 0.0) || 0.0) / dailyPricesAsArray.length;
            roomAverageCapacity.push({
                id: categoryId,
                price: isNaN(averagePrice) ? 0.0 : averagePrice,
            });
        });

        return { roomCapacity: roomAverageCapacity, includedCapacity };
    };

    static fromNewReservation = ({
        checkin,
        checkout,
        rooms = [],
        clients = [],
        arrivalTime,
        departureTime,
        doNotDisturb,
        discount,
        dailyAvailabilities = {},
        note,
        isGroupReservation,
        groupName,
        isSimpleReservation,
        isBlockedRooms,
        guestCategories,
    }) => {
        const newData = {
            checkin,
            checkout,
            clients,
            rooms: [],
            arrivalTime,
            departureTime,
            doNotDisturb,
            discount,
            dailyAvailabilities,
            notes: [],
            isGroupReservation,
            groupName,
            guests: [],
            isBlockedRooms,
            guestCategories,
        };

        if (newData?.discount?.value) newData.discount.value = parseFloat(newData.discount.value);

        const days = generateDatesBetween(checkin, checkout, "[)");

        rooms.forEach((room) => {
            const { roomCapacity, includedCapacity } = this.getRoomDailyCapacity(days, dailyAvailabilities, room);
            newData.rooms.push({
                roomId: isSimpleReservation ? "UNASSIGNED" : room._id,
                roomName: room.name,
                roomtypeId: room.roomtypeId,
                roomtypeName: room.roomtypeName,
                customPrice: room.customPrice,
                prices: isBlockedRooms
                    ? []
                    : days.map((day) => ({
                          price:
                              room.customPrice !== undefined && room.customPrice !== null
                                  ? room.customPrice
                                  : room.discount
                                  ? applyDiscount(this.getRoomDailyPrice(day, dailyAvailabilities, room), room.discount)
                                  : this.getRoomDailyPrice(day, dailyAvailabilities, room),
                          rateId: room.rate || "standard",
                          date: day,
                          discount:
                              room.customPrice !== undefined && room.customPrice !== null
                                  ? null
                                  : room.discount?.type && room.discount?.value
                                  ? room.discount
                                  : null,
                      })),
            });
            const roomGuests = room.guests;
            let freeOfChargeCapacity = includedCapacity || 0;
            if (roomGuests) {
                newData.guests.push({
                    roomId: isSimpleReservation ? room.roomtypeId : room._id,
                    guests: Object.entries(roomGuests)
                        ?.sort((a, b) => {
                            const prevGuestCategoryPriorityOrder =
                                guestCategories?.find((c) => c._id === a?.[0])?.priorityOrder || 0;
                            const nextGuestCategoryPriorityOrder =
                                guestCategories?.find((c) => c._id === b?.[0])?.priorityOrder || 0;
                            return prevGuestCategoryPriorityOrder - nextGuestCategoryPriorityOrder;
                        })
                        .map(([guestId, guestData]) => {
                            const roomGuestCapacity = roomCapacity?.find((g) => g.id === guestId);
                            if (!roomGuestCapacity) return {};
                            const extraGuests = guestData.number - freeOfChargeCapacity;
                            freeOfChargeCapacity -= guestData.number || 0;
                            if (freeOfChargeCapacity < 0) freeOfChargeCapacity = 0;
                            return {
                                name: guestData.name,
                                number: guestData.number,
                                price: roomGuestCapacity.price,
                                extra: extraGuests > 0 ? extraGuests : 0,
                            };
                        }),
                });
            }
        });

        if (note) newData.notes.push({ note });

        return new ReservationHandler(newData);
    };

    static fromNewSimpleReservation = ({
        checkin,
        checkout,
        roomtypes = [],
        clients = [],
        arrivalTime,
        departureTime,
        doNotDisturb,
        discount,
        dailyAvailabilities = {},
        note,
        isGroupReservation,
        groupName,
        isSimpleReservation,
        isBlockedRooms,
        guestCategories,
    }) => {
        const newData = {
            checkin,
            checkout,
            clients,
            rooms: [],
            roomtypes: [],
            arrivalTime,
            departureTime,
            doNotDisturb,
            discount,
            dailyAvailabilities,
            notes: [],
            isGroupReservation,
            groupName,
            guests: [],
            isSimpleReservation,
            isBlockedRooms,
            guestCategories,
        };

        if (newData?.discount?.value) newData.discount.value = parseFloat(newData.discount.value);

        const days = generateDatesBetween(checkin, checkout, "[)");

        roomtypes.forEach((rt) => {
            const { roomCapacity, includedCapacity } = this.getRoomDailyCapacity(days, dailyAvailabilities, {
                ...rt,
                roomtypeId: rt._id,
            });

            newData.roomtypes.push({
                roomId: "UNASSIGNED",
                roomtypeName: rt.name,
                roomtypeId: rt._id,
                quantity: rt.quantity,
                prices: isBlockedRooms
                    ? []
                    : days.map((day) => ({
                          price: this.getRoomDailyPrice(day, dailyAvailabilities, { ...rt, roomtypeId: rt._id }),
                          rateId: rt.rate || "standard",
                          date: day,
                          discount: null,
                      })),
            });
            const roomtypeGuests = rt.guests;
            let freeOfChargeCapacity = includedCapacity || 0;
            if (roomtypeGuests) {
                const guestDetails = {
                    roomId: rt._id,
                    guests: Object.entries(roomtypeGuests)
                        ?.sort((a, b) => {
                            const prevGuestCategoryPriorityOrder =
                                guestCategories?.find((c) => c._id === a?.[0])?.priorityOrder || 0;
                            const nextGuestCategoryPriorityOrder =
                                guestCategories?.find((c) => c._id === b?.[0])?.priorityOrder || 0;
                            return prevGuestCategoryPriorityOrder - nextGuestCategoryPriorityOrder;
                        })
                        ?.map(([guestId, guestData]) => {
                            const roomGuestCapacity = roomCapacity?.find((g) => g.id === guestId);
                            if (!roomGuestCapacity) return {};
                            const extraGuests = guestData.number - freeOfChargeCapacity;
                            freeOfChargeCapacity -= guestData.number || 0;
                            if (freeOfChargeCapacity < 0) freeOfChargeCapacity = 0;
                            return {
                                name: guestData.name,
                                number: guestData.number,
                                price: roomGuestCapacity.price,
                                extra: extraGuests > 0 ? extraGuests : 0,
                            };
                        }),
                };
                new Array(rt.quantity).fill(1).forEach((e) => newData.guests.push(guestDetails));
            }
        });
        if (note) newData.notes.push({ note });

        return new ReservationHandler(newData);
    };

    addDailyAvailabilities = (availability) => {
        this.dailyAvailabilities = availability;
        return this;
    };

    getRoomsInfo = () => {
        return this.rooms.map((room) => {
            const roomGuests = this.guests.find((g) => g.roomId === room.roomId);
            const extraGuestsCostPerDay = roomGuests?.guests
                ? roomGuests.guests.reduce((total, category) => {
                      if (isNaN(category.price)) return total;
                      total += category.extra * category.price;
                      return total;
                  }, 0.0)
                : 0;
            const roomAveragePricePerDay =
                room.prices.reduce((total, day) => total + day.price, 0.0) / room.prices.length;
            return {
                roomId: room.roomId,
                roomName: room.roomName,
                roomtypeName: room.roomtypeName,
                totalPrice:
                    room.customPrice !== undefined && room.customPrice !== null
                        ? roomAveragePricePerDay
                        : roomAveragePricePerDay + extraGuestsCostPerDay,
            };
        });
    };

    getRoomtypesInfo = () => {
        return this.roomtypes.map((rt) => {
            const roomtypeGuests = this.guests.find((g) => g.roomId === rt.roomtypeId);
            const extraGuestsCostPerDay = roomtypeGuests?.guests
                ? roomtypeGuests.guests.reduce((total, category) => {
                      if (isNaN(category.price)) return total;
                      total += category.extra * category.price;
                      return total;
                  }, 0.0)
                : 0;
            const roomtypeAveragePricePerDay =
                rt.prices.reduce((total, day) => total + day.price, 0.0) / rt.prices.length;
            return {
                quantity: rt.quantity,
                roomtypeName: rt.roomtypeName,
                totalPrice: roomtypeAveragePricePerDay + extraGuestsCostPerDay,
            };
        });
    };

    calculateTotalDiscount = (totalPrice = 0) => {
        if (!this.discount?.type || !this.discount?.value) return 0;
        const discountAmount =
            this.discount.type === "total"
                ? parseFloat(this.discount.value)
                : totalPrice * parseFloat(this.discount.value) * 0.01;
        if (isNaN(discountAmount)) return 0;
        return discountAmount;
    };

    calculateRoomtypeTotal = () => {
        const reservationTotalDays = generateDatesBetween(this.checkin, this.checkout, "[)").length;
        const totalPrice = this.getRoomtypesInfo()?.reduce((total, rt) => {
            return rt.totalPrice * reservationTotalDays * rt.quantity + total;
        }, 0.0);
        return totalPrice - this.calculateTotalDiscount(totalPrice);
    };

    generateUnassignedRooms = () => {
        return this.roomtypes?.map((rt) => new Array(rt.quantity).fill(rt))?.flat();
    };

    calculateTotal = () => {
        if (this.isSimpleReservation) return this.calculateRoomtypeTotal();
        const reservationTotalDays = generateDatesBetween(this.checkin, this.checkout, "[)").length;
        const totalPrice = this.getRoomsInfo()?.reduce((total, room) => {
            return room.totalPrice * reservationTotalDays + total;
        }, 0.0);
        return totalPrice - this.calculateTotalDiscount(totalPrice);
    };

    getReservation = () => {
        const reservationRooms = this.isSimpleReservation ? this.generateUnassignedRooms() : this.rooms;
        return {
            checkin: this.checkin,
            checkout: this.checkout,
            clients: this.clients,
            rooms: reservationRooms?.map(({ roomId, roomtypeId, prices, customPrice }) => ({
                roomId,
                roomtypeId,
                customPrice,
                prices,
            })),
            guests: this.isBlockedRooms ? [] : this.guests,
            arrivalTime: this.arrivalTime,
            departureTime: this.departureTime,
            totalPrice: this.isBlockedRooms ? 0 : this.calculateTotal(),
            discount: this.discount,
            doNotDisturb: this.doNotDisturb,
            notes: this.notes,
            groupName: this.groupName,
            isGroupReservation: this.isGroupReservation,
        };
    };
}

export default ReservationHandler;
