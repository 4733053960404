import { Paper, Stack } from "@mui/material";
import React from "react";

const ActionsPanel = ({ children }) => {
    return (
        <Paper sx={{ backgroundColor: "transparent" }} elevation={0}>
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                {children}
            </Stack>
        </Paper>
    );
};

export default ActionsPanel;
