import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const DiscountList = () => {
    const [discounts, setDiscounts] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "minimalReservations", header: t("minimal_reservations") },
        { accessorKey: "minimalAmount", header: t("minimal_amount") },
        { accessorKey: "minimalNights", header: t("minimal_nights") },
        { accessorKey: "discountValue", header: t("discount_value") },
        { accessorKey: "discountType", header: t("discount_type") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "discounts",
            responseData: "_id name minimalReservations minimalAmount minimalNights discountValue discountType",
        });
        if (response?.discounts) setDiscounts(response.discounts);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteDiscounts" : "deleteDiscount",
            data,
        });
        if (response?.deleteDiscounts === "success" || response?.deleteDiscount === "success") {
            setDiscounts(discounts.filter((discount) => !rows.some((row) => row._id === discount._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        loadData();
    }, []);
    const addRecordDiscount = (data) => {
        if (discounts?.some((r) => r._id === data._id)) {
            setDiscounts(
                discounts?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setDiscounts([...discounts, data]);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("discounts_form")}
                moduleType="backoffice"
                data={discounts}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("discounts_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordDiscount }} />
        </div>
    );
};

export default DiscountList;
