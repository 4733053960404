import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const statusOptions = [
    {
        value: "clean",
        label: "clean",
    },
    {
        value: "inspected",
        label: "inspected",
    },
    {
        value: "dirty",
        label: "dirty",
    },
    {
        value: "occupied",
        label: "occupied",
    },
    {
        value: "roomService",
        label: "room_service",
    },
];

const HousekeepingForm = () => {
    const [values, setValues] = useState({});
    const [users, setUsers] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { addRecordHouseKeeping } = useOutletContext() || {};

    const { t } = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "housekeeping",
            data: {
                _id: "housekeepings_rooms_" + params?.id,
            },
            responseData: "_id status assignedTo notes roomData{name roomTypeData{name}} userData{displayName}",
        });
        if (response?.housekeeping) setValues(response.housekeeping);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "users",
            responseData: "_id displayName",
        });
        if (response?.users)
            setUsers(
                response.users.map((user) => ({
                    value: user._id,
                    label: user?.displayName || user?._id?.split("users_")[1],
                }))
            );
    };

    const saveHandler = async () => {
        const { status, assignedTo, notes, _id } = values;
        const data = {
            status,
            assignedTo,
            notes,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateHousekeeping",
            data,
            responseData: "_id status assignedTo notes roomData{name roomTypeData{name}} userData{displayName}",
        });
        if (response?.updateHousekeeping?._id) {
            enqueueSnackbar(t("housekeeping_saved"), { variant: "default" });
            addRecordHouseKeeping && addRecordHouseKeeping(response.updateHousekeeping);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("housekeeping")}
            maxWidth="xs"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
            permission={{ housekeeping: { edit: true } }}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="body1">{t("room")}</Typography>
                    <Typography variant="h6">{t(values?.roomData?.name)}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{t("roomtype")}</Typography>
                    <Typography variant="h6">{t(values?.roomData?.roomTypeData?.name)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectControl name="status" label={t("status")} options={statusOptions} />
                    <SelectControl name="assignedTo" label={t("assignedTo")} options={users} />
                    <InputControl name="notes" label={t("notes")} multiline minRows={4} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default HousekeepingForm;
