import { Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InputControl, RadioGroupControl } from "../form";
import { toCurrency } from "../../utils";

const AmountsCard = ({
    discountInputValue,
    discountType,
    onChange,
    total = 0.0,
    tax = 0.0,
    discount = 0.0,
    subtotal = 0.0,
    currency,
    convertedCurrencies,
    disabled = false,
}) => {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent>
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <RadioGroupControl
                            options={[
                                { value: "total", label: t("total") },
                                { value: "percentage", label: t("percentage") },
                            ]}
                            disabled={disabled}
                            label={t("discount_type")}
                            value={discountType || ""}
                            onChange={(e) => {
                                onChange && onChange({ discountType: e.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl
                            value={discountInputValue || ""}
                            onChange={(e) => {
                                onChange && onChange({ discountValue: e.target.value });
                            }}
                            disabled={disabled}
                            type="number"
                            label={t("discount")}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardContent>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">{t("subtotal")}:</Typography>
                    <Typography fontWeight={600}>{toCurrency(subtotal, currency)}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">{t("discount")}:</Typography>
                    <Typography fontWeight={600}>{toCurrency(discount, currency)}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">{t("tax")}:</Typography>
                    <Typography fontWeight={600}>{toCurrency(tax, currency)}</Typography>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">{t("total")}:</Typography>
                    <Typography variant="h6">{toCurrency(total, currency)}</Typography>
                </Stack>
                {convertedCurrencies &&
                    convertedCurrencies?.length > 0 &&
                    convertedCurrencies?.map((currency) => {
                        return (
                            <Stack direction="row" justifyContent="end">
                                <Typography variant="subtitle1" fontWeight={600}>
                                    {toCurrency(currency.amount, currency.currency)}
                                </Typography>
                            </Stack>
                        );
                    })}
            </CardContent>
        </Card>
    );
};

export default AmountsCard;
