import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientDetailsInputs, ClientsSearch } from "../../../../components/common";
import { Form } from "../../../../components/form";
import { CreateReservationContext } from "./CreateReservationContext";
import BlockRoomsStep from "./BlockRoomsStep";

const rules = {
    firstName: "string|required",
    clientType: "required",
};

const SelectClientsStep = forwardRef(({}, ref) => {
    const [clientValues, setClientValues] = useState({
        clientType: "client",
    });
    const { t } = useTranslation();
    const formRef = useRef();
    const { values, setValues } = useContext(CreateReservationContext);

    useImperativeHandle(ref, () => ({
        handleAddClient() {
            if (values?.addedClients.length > 0) return true;
            const response = formRef?.current?.submit && formRef?.current?.submit();
            return response;
        },
    }));

    const addClient = (clientData) => {
        if (!clientData.identifier) clientData.identifier = Date.now();
        if (values?.addedClients?.some((c) => c.identifier === clientData.identifier)) {
            setValues({
                ...values,
                addedClients: values?.addedClients?.map((c) => {
                    if (c.identifier === clientData.identifier) return clientData;
                    return c;
                }),
            });
            return;
        }
        setValues({
            ...values,
            addedClients: [...(values?.addedClients || []), clientData],
            referer: values?.addedClients?.length > 0 ? values.referer : clientData.referer,
        });
    };
    return (
        <>
            <Grid style={{ marginBottom: 20 }} container spacing={2}>
                {values?.addedClients?.map((client) => (
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                avatar={client.clientType === "client" ? <PersonIcon /> : <GroupIcon />}
                                title={`${client.firstName} ${client.lastName || ""}`}
                                titleTypographyProps={{ variant: "h6" }}
                                action={
                                    <IconButton
                                        onClick={() => {
                                            setValues({
                                                ...values,
                                                addedClients: values?.addedClients?.filter(
                                                    (c) => c.identifier !== client.identifier
                                                ),
                                            });
                                        }}
                                        color="inherit"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Typography variant="body2">{client.email}</Typography>
                                <Typography>{client.phone}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => setClientValues(client)} fullWidth variant="outlined">
                                    {t("modify")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <BlockRoomsStep />

            <Form
                values={clientValues}
                onValuesChange={setClientValues}
                onSubmit={() => {
                    addClient && addClient(clientValues);
                    setClientValues({ clientType: "client" });
                }}
                rules={rules}
                ref={formRef}
            >
                <Card
                    style={{
                        opacity: values.isBlockedRooms ? 0 : "100%",
                        transition: "all .3s",
                    }}
                >
                    <CardHeader
                        title={t("client_details")}
                        action={
                            <Button type="submit" variant="contained">
                                {t("add_client")}
                            </Button>
                        }
                    />
                    <CardContent>
                        <ClientsSearch
                            onClientSelect={(data) => setClientValues(data)}
                            inputProps={{
                                sx: {
                                    "& fieldset": {
                                        borderColor: "primary.main",
                                        borderWidth: 2,
                                        borderRadius: 2,
                                    },
                                },
                            }}
                        />
                    </CardContent>
                    <CardContent>{ClientDetailsInputs({ t, values: clientValues })}</CardContent>
                </Card>
            </Form>
        </>
    );
});

export default SelectClientsStep;
