import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { DateControl } from "../../components/dates";

// const rules = {
//     name: "string|required",
//     businessName: "string|required",
//     nipt: "string|required",
//     businessUnitCode: "string|required",
//     password: "string|required",
// };
const options = [{ value: "regular", label: "regular" }];

const CashdeskForm = () => {
    const [values, setValues] = useState({});
    const [fiscalBusinessesOptions, setFiscalBusinessesOptions] = useState([]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "cashdesk",
            data: {
                _id: "fiscalcashdesks_" + params?.id,
            },
            responseData:
                "_id options fiscalBusinessId validFrom validTo tcrIntId tcrCode automaticOpening automaticAmount",
        });
        if (response?.cashdesk) setValues(response.cashdesk);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "businesses",
                    responseData: "_id name nipt businessUnitCode certificate password",
                },
            ],
        });
        if (!!response?.businesses) {
            setFiscalBusinessesOptions(
                response.businesses.map((fiscalbusiness) => ({
                    value: fiscalbusiness._id,
                    label: fiscalbusiness.name,
                    nipt: fiscalbusiness.nipt,
                    businessUnitCode: fiscalbusiness.businessUnitCode,
                    password: fiscalbusiness.password,
                    certificate: fiscalbusiness.certificate,
                }))
            );
        }
    };
    const registerCashDesk = async () => {
        const { nipt, businessUnitCode, certificate, password } =
            fiscalBusinessesOptions.find((business) => business.value === values.fiscalBusinessId) || {};
        const response = await fetch({
            operation: "mutation",
            endpoint: "registerCashdesk",
            data: {
                type: values.options,
                validFrom: values.validFrom,
                validTo: values.validTo,
                tcrIntId: values.tcrIntId,
                businessUnitCode: businessUnitCode,
                nipt: nipt,
                certificate: certificate?.[0]?.split(";base64,")?.[1],
                certificatePassword: password,
            },
        });
        if (response?.registerCashdesk) {
            saveHandler({ tcrCode: response.registerCashdesk });
        }
    };

    const saveHandler = async (extraData = {}) => {
        const {
            options,
            validFrom,
            validTo,
            tcrIntId,
            tcrCode,
            automaticOpening,
            automaticAmount,
            fiscalBusinessId,
            _id,
        } = values;
        const data = {
            options,
            validFrom,
            validTo,
            tcrIntId,
            tcrCode,
            automaticOpening,
            automaticAmount: parseFloat(automaticAmount),
            fiscalBusinessId,
            ...extraData,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateCashDesk" : "createCashDesk",
            data,
            responseData: "_id",
        });
        if (response?.createCashDesk?._id || response?.updateCashDesk?._id) {
            enqueueSnackbar(t("fiscal_cashdesk_saved"), { variant: "default" });
            navigate(-1);
        }
    };
    return (
        <Modal
            titlelabel={t("fiscal_cashdesk")}
            maxWidth="md"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            FormProps={{
                values,
                // rules,
                onValuesChange: setValues,
                onSubmit: () => saveHandler(),
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <InputControl name="tcrIntId" label={t("tcr_id")} />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl name="options" label={t("type")} options={options} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl disabled name="tcrCode" label={t("tcr_code")} />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl
                        name="fiscalBusinessId"
                        label={t("business_details")}
                        options={fiscalBusinessesOptions}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateControl name="validFrom" label={t("valid_from")} />
                </Grid>
                <Grid item xs={6}>
                    <DateControl name="validTo" label={t("valid_to")} />
                </Grid>
                <Grid item xs={6}>
                    <SwitchControl name="automaticOpening" label={t("automatic_opening")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="automaticAmount" label={t("automatic_amount")} type="number" />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="outlined" onClick={() => registerCashDesk()}>
                        {t("register_cashdesk")}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="outlined">{t("deactivate_cash_desk")}</Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CashdeskForm;
