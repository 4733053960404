import { Typography, Grid, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiSubscription, useApi } from "../../components/hooks";
import { kitchenReceipt, print, printFiscal } from "../../components/pdf/printReceipt";
import { generateSameTaxes } from "../../utils";
import { CheckboxControl } from "../../components/form";

const ORDER_RESPONSE_DATA = `
    _id 
    number
    customerData{firstName lastName}
    subtotal
    totalPrice
    createdAt
    createdBy
    createdByData{displayName}
    tableId
    livePrintingEnabled
    pospointId
    pospointData{
        currencyData{currency} 
        tables{tableName _id}
        saveOptions
        printOptions{
            fiscal{printingOption printer} 
            nonFiscal{printingOption printer} 
            waiterReport{printingOption printer} 
        }
        activeMethodsData{_id name method}
    } 
    payments{paymentMethod paymentStatus cashReceived}
    products{
        productId
        productData{name posplaces taxData{taxId isIncluded tax{taxCategory rate} }}
        price
        quantity
        note
        courses{courseId quantity}
    } 
    fiscalData{
        IICRef
        IssueDateTime
        InvOrdNum
        TCR
        operator
        nipt
        nivf
        nslf
        BusinUnitCode
    }
`;

const LiveOrdersPrinting = () => {
    // const [printingOrder, setPrintingOrder] = useState();
    const [state, setState] = useState({
        settingsProperty: {},
        posplaces: [],
        pospoints: [],
        activePospoints: [],
    });

    const { t } = useTranslation();
    const { fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "settingsproperty",
                    responseData: "_id address city zip country name",
                    data: { _id: "settings_property" },
                },
                {
                    endpoint: "posplaces",
                    responseData: "_id name sendTo printers{printer device}",
                },
                {
                    endpoint: "pospoints",
                    responseData: "_id name",
                },
            ],
        });
        const localStorageActivePospoints = localStorage.getItem("pospointsLivePrint") || "[]";
        setState({
            settingsProperty: response?.settingsproperty,
            posplaces: response?.posplaces,
            pospoints: response?.pospoints,
            activePospoints: JSON.parse(localStorageActivePospoints),
        });
    };
    useEffect(() => {
        if (!state.settingsProperty?._id) return;
        const { disconnect } = apiSubscription().openConnection(
            {
                operation: "subscription",
                endpoint: "liveOrdersChanges",
                responseData: `
                    newOrders{${ORDER_RESPONSE_DATA}} 
                    updatedOrders{
                        currentOrder{${ORDER_RESPONSE_DATA}}
                        previousOrder{${ORDER_RESPONSE_DATA}}
                    }
                `,
            },
            async (newData) => {
                const { newOrders, updatedOrders } = newData?.liveOrdersChanges || {};
                if (!!newOrders) {
                    for (const order of newOrders) {
                        if (order?.livePrintingEnabled) {
                            // setPrintingOrder(order);
                            await printOrder(order, null);
                            enqueueSnackbar(t("order") + ` ${order?.number || ""} ` + t("printed"), {
                                variant: "info",
                            });
                        }
                    }
                }
                if (!!updatedOrders) {
                    for (const updatedOrder of updatedOrders) {
                        if (updatedOrder?.currentOrder?.livePrintingEnabled) {
                            // setPrintingOrder(updatedOrder?.currentOrder);
                            await printOrder(updatedOrder?.currentOrder, updatedOrder?.previousOrder);
                            enqueueSnackbar(
                                t("order") + ` ${updatedOrder?.currentOrder?.number || ""} ` + t("printed"),
                                { variant: "info" }
                            );
                        }
                    }
                }
            }
        );
        return () => {
            disconnect();
        };
    }, [state]);

    const printOrder = async (order, previousOrderVersion) => {
        if (!state?.activePospoints?.includes(order?.pospointId)) {
            return;
        }
        order.createdBy = order?.createdByData?.displayName || order?.createdBy?.split("users_")?.[1] || "";
        const orderData = order;
        orderData.customerName = order?.customerData?.firstName + " " + order?.customerData?.lastName;
        const isPaid = !!order?.payments?.paymentMethod;
        if (isPaid || order?.pospointData?.saveOptions?.includes("print")) {
            if (order?.fiscalData?.nivf) {
                await printFiscal({
                    settingsProperty: state.settingsProperty,
                    t,
                    order: {
                        ...orderData,
                        products: orderData?.products?.map((product) => {
                            const { taxData } = product?.productData || {};
                            return { ...product, taxData };
                        }),
                    },
                    pospoint: order?.pospointData,
                    SameTaxes: generateSameTaxes(orderData?.products),
                });
            } else {
                await print({
                    settingsProperty: state.settingsProperty,
                    t,
                    order: orderData,
                    pospoint: order?.pospointData,
                    previousOrder: previousOrderVersion,
                });
            }
        }
        if (order?.pospointData?.saveOptions?.includes("sendToKitchen")) {
            sendToKitchen(order, previousOrderVersion);
        }
    };

    const sendToKitchen = async (order, previousOrderVersion) => {
        try {
            kitchenReceipt({
                settingsProperty: state.settingsProperty,
                posplaces: state.posplaces,
                t,
                order: {
                    ...order,
                    products: order.products?.map((product) => {
                        return {
                            ...product,
                            name: product?.productData?.name,
                            posplaces: product?.productData?.posplaces,
                        };
                    }),
                    createdByName: order.createdBy,
                },
                pospoint: order?.pospointData,
                previousOrder: previousOrderVersion,
            });
        } catch (error) {
            enqueueSnackbar(t("print_kitchen_receipt_failed"), { variant: "error" });
        }
    };

    const onPosChecked = (posId, checked) => {
        const newActivePospoints = !!checked
            ? [...state?.activePospoints, posId]
            : state?.activePospoints?.filter((p) => p !== posId);
        setState({ ...state, activePospoints: newActivePospoints });
        localStorage.setItem("pospointsLivePrint", JSON.stringify(newActivePospoints));
    };

    return (
        <div>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "60px" }}
            >
                <img
                    src="https://res.cloudinary.com/strowberry-code/image/upload/v1709824770/hb/icons8-print-80_b49vmm.png"
                    alt="print-icon"
                    style={{ marginBottom: "30px" }}
                />
                <Typography variant="h6">{t("live_mobile_printing")}</Typography>
                <Typography variant="subtitle1">{t("select_pospoints_that_receive_orders_on_this_device")}</Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" style={{ marginTop: "20px" }}>
                {state?.pospoints?.map((pos) => {
                    return (
                        <CheckboxControl
                            value={Boolean(state?.activePospoints.includes(pos._id))}
                            onChange={(e) => onPosChecked(pos._id, e.target.checked)}
                            label={pos?.name}
                            labelPlacement="end"
                        />
                    );
                })}
            </Grid>
        </div>
    );
};

export default LiveOrdersPrinting;
