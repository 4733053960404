import React from "react";
import { useTranslation } from "react-i18next";
import { SelectControl } from "../form";
import { Modal } from "../modal";

const InvoiceSelectModal = ({ invoices = [], onInvoiceSelect, open, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal yesText="none" noText="none" open={open} onClose={onClose} maxWidth="xs">
            <SelectControl
                label={t("choose_invoice")}
                options={invoices}
                onChange={(e) => {
                    if (onInvoiceSelect) {
                        onInvoiceSelect(e.target.value);
                    }
                }}
            />
        </Modal>
    );
};

export default InvoiceSelectModal;
