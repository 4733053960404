import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useApi from "./useApi";

const loadCacheProductCategories = () => {
    const categoriesDataAsString = localStorage.getItem("cacheProductCategories");
    if (!categoriesDataAsString) return undefined;
    const categoriesData = JSON.parse(categoriesDataAsString);
    const { timestamp, categories } = categoriesData || {};
    if (DateTime.now().diff(DateTime.fromISO(timestamp), "hours")?.toObject()?.hours > 1) {
        return { categories, expired: true };
    }
    return { categories, expired: false };
};

const cacheProductCategories = (categories) => {
    const categoriesData = {
        timestamp: DateTime.now().toISO(),
        categories,
    };
    localStorage.setItem("cacheProductCategories", JSON.stringify(categoriesData));
};

const useProductCategories = () => {
    const [categories, setCategories] = useState({
        productCategories: [],
        productMainCategories: [],
    });

    const { loading, fetch } = useApi();

    const loadProductCategoriesFromApi = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "productcategories", responseData: "_id name" },
                { endpoint: "productmaincategories", responseData: "_id name color productCategories" },
            ],
        });
        if (response?.productcategories) {
            setCategories({
                productMainCategories: response?.productmaincategories || [],
                productCategories: response?.productcategories || [],
            });
            cacheProductCategories({
                productMainCategories: response?.productmaincategories || [],
                productCategories: response?.productcategories || [],
            });
            return true;
        }
        return false;
    };

    const loadProductCategories = async () => {
        const { categories, expired } = loadCacheProductCategories() || {};
        if (!categories) {
            loadProductCategoriesFromApi();
            return;
        }
        if (!expired) {
            setCategories(categories);
            return;
        }
        const hasLoadedOnlineCategories = await loadProductCategoriesFromApi();
        if (hasLoadedOnlineCategories) return;
        setCategories(categories);
        return;
    };

    useEffect(() => {
        loadProductCategories();
    }, []);

    return {
        productCategories: categories?.productCategories,
        productMainCategories: categories?.productMainCategories,
        loadingProductCategories: loading,
        reloadProductCategories: loadProductCategoriesFromApi,
    };
};

export default useProductCategories;
