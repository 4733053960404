import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientDetailsInputs, ClientsSearch } from "../../../../components/common";
import { Form, InputControl, SelectControl } from "../../../../components/form";
import { Modal } from "../../../../components/modal";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import { useApi } from "../../../../components/hooks";
import { useSnackbar } from "notistack";

const rules = {
    firstName: "string|required",
    clientType: "required",
};

const RESERVATION_RESPONSE_DATA = `_id checkin checkinTime checkout checkoutTime isDailyUse bookingSource status clients uuid
clientsData{_id firstName lastName} 
rooms{roomtypeId roomId customPrice prices{price rateId date discount{type value}}} 
guests{roomId guests{name number price extra}} 
invoices{_id}`;

const NewReservationConfirm = ({ open = false, start, end, roomData, onClose, onConfirm }) => {
    const [values, setValues] = useState({
        roomPrice: null,
        invoiceCurrency: null,
        clientsData: { clientType: "client" },
        clients: [],
        currencyOptions: [],
    });

    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (open) setValues({ ...values, roomPrice: null, clientsData: { clientType: "client" }, clients: [] });
    }, [open]);

    useEffect(() => {
        console.log(1);
        loadSettingsData();
    }, []);

    const loadSettingsData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "currencies",
                    responseData: "_id currency",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "dailyReservationsCurrency",
                    data: { _id: "settings_general" },
                },
            ],
        });

        const defaultCurrency = response?.currencies?.find(
            (c) => c._id === response?.settingsgeneral?.dailyReservationsCurrency
        )?.currency;
        setValues({
            ...values,
            invoiceCurrency: defaultCurrency,
            currencyOptions: response?.currencies?.map((c) => ({ value: c.currency, label: c.currency })),
        });
    };

    const addClient = (clientData) => {
        if (!clientData.identifier) clientData.identifier = Date.now();
        if (values?.clients?.some((c) => c.identifier === clientData.identifier)) {
            setValues({
                ...values,
                clientsData: { clientType: "client" },
                clients: values?.clients?.map((c) => {
                    if (c.identifier === clientData.identifier) return clientData;
                    return c;
                }),
            });
            return;
        }
        setValues({
            ...values,
            clientsData: { clientType: "client" },
            clients: [...(values?.clients || []), clientData],
        });
    };
    const saveReservation = async () => {
        const totalPrice = parseFloat(values.roomPrice);
        const reservationData = {
            checkin: DateTime.fromISO(start).toFormat("yyyy-LL-dd"),
            checkinTime: DateTime.fromISO(start).toFormat("HH:mm"),
            checkout: DateTime.fromISO(end).toFormat("yyyy-LL-dd"),
            checkoutTime: DateTime.fromISO(end).toFormat("HH:mm"),
            rooms: [
                {
                    roomId: roomData?._id,
                    roomtypeId: roomData?.roomtypeId,
                    customPrice: null,
                    prices: [
                        {
                            price: isNaN(totalPrice) ? 0.0 : totalPrice,
                            rateId: "standard",
                            date: DateTime.fromISO(start).toFormat("yyyy-LL-dd"),
                        },
                    ],
                },
            ],
            guests: [],
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
            isDailyUse: true,
            invoiceCurrency: values.invoiceCurrency,
        };
        const clients = values.clients?.length > 0 ? values.clients : [];
        if (!!values.clientsData?.clientType && !!values?.clientsData.firstName) {
            clients.push(values.clientsData);
        }
        const existingClientsIds = clients?.filter((client) => client._id)?.map((c) => c._id) || [];
        let newClientsIds = [];
        const newClients =
            clients
                ?.filter((client) => !client._id)
                ?.map((client) => ({
                    firstName: client.firstName,
                    lastName: client.lastName,
                    clientType: client.clientType,
                    birthdate: client.birthdate,
                    referenceTitle: client.referenceTitle,
                    company: client.company,
                    country: client.country,
                    state: client.state,
                    city: client.city,
                    zip: client.zip,
                    addressLine: client.addressLine,
                    email: client.email,
                    phone: client.phone,
                    IDType: client.IDType,
                    IDNumber: client.IDNumber,
                    placeOfIssue: client.placeOfIssue,
                    dateOfIssue: client.dateOfIssue,
                    dateOfExpiry: client.dateOfExpiry,
                    nationality: client.nationality,
                    placeOfBirth: client.placeOfBirth,
                })) || [];
        if (newClients?.length > 0) {
            const clientsResponse = await fetch({
                operation: "mutation",
                endpoint: "createMultipleClients",
                data: {
                    clients: newClients,
                },
            });
            if (clientsResponse.createMultipleClients) newClientsIds = clientsResponse.createMultipleClients;
        }

        reservationData.clients = existingClientsIds.concat(newClientsIds);
        const response = await fetch({
            operation: "mutation",
            endpoint: "createReservation",
            data: reservationData,
            responseData: RESERVATION_RESPONSE_DATA,
        });
        if (response?.createReservation?._id) {
            enqueueSnackbar(t("reservation_saved"), { variant: "success" });
            onConfirm && onConfirm(response?.createReservation);
        }
    };
    return (
        <Modal
            open={open}
            maxWidth="md"
            titlelabel={t("new_reservation_confirm")}
            onClose={onClose}
            onSave={saveReservation}
        >
            <Card sx={{ marginBottom: 2 }}>
                <CardHeader title={t("reservation_details")} />
                <CardContent>
                    <Stack direction="row">
                        <ListItemText
                            primary={t("checkin")}
                            secondary={!!start ? DateTime.fromISO(start).toFormat("dd LLL HH:mm") : ""}
                            primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
                            secondaryTypographyProps={{ variant: "subtitle1", color: "primary" }}
                        />
                        <ListItemText
                            primary={t("checkout")}
                            secondary={!!end ? DateTime.fromISO(end).toFormat("dd LLL HH:mm") : ""}
                            primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
                            secondaryTypographyProps={{ variant: "subtitle1", color: "primary" }}
                        />
                        <ListItemText
                            primary={t("room") + " " + (!!roomData.name ? roomData.name : "")}
                            secondary={
                                <InputControl
                                    value={values.roomPrice}
                                    onChange={(e) => setValues({ ...values, roomPrice: e.target.value })}
                                    label={t("price")}
                                    variant="standard"
                                    fullWidth={false}
                                />
                            }
                            primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
                        />
                        <ListItemText
                            primary={t("invoice_currency")}
                            secondary={
                                <SelectControl
                                    value={values.invoiceCurrency}
                                    onChange={(e) => setValues({ ...values, invoiceCurrency: e.target.value })}
                                    label={t("currency")}
                                    variant="standard"
                                    options={values?.currencyOptions}
                                />
                            }
                            primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
                        />
                    </Stack>
                </CardContent>
            </Card>
            <Grid container spacing={2} marginBottom={2}>
                {values?.clients?.map((client) => (
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                avatar={client.clientType === "client" ? <PersonIcon /> : <GroupIcon />}
                                title={`${client.firstName} ${client.lastName || ""}`}
                                titleTypographyProps={{ variant: "h6" }}
                                action={
                                    <IconButton
                                        onClick={() => {
                                            setValues({
                                                ...values,
                                                clients: values?.clients?.filter(
                                                    (c) => c.identifier !== client.identifier
                                                ),
                                            });
                                        }}
                                        color="inherit"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Typography variant="body2">{client.email}</Typography>
                                <Typography>{client.phone}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    onClick={() => setValues({ ...values, clientsData: client })}
                                    fullWidth
                                    variant="outlined"
                                >
                                    {t("modify")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Form
                values={values.clientsData}
                onValuesChange={(data) => setValues({ ...values, clientsData: data })}
                onSubmit={() => {
                    addClient && addClient(values.clientsData);
                }}
                rules={rules}
            >
                <Card>
                    <CardHeader
                        title={t("client_details")}
                        action={
                            <Button type="submit" variant="contained">
                                {t("add_client")}
                            </Button>
                        }
                    />
                    <CardContent>
                        <ClientsSearch
                            onClientSelect={(data) => setValues({ ...values, clientsData: data })}
                            inputProps={{
                                sx: {
                                    "& fieldset": {
                                        borderColor: "primary.main",
                                        borderWidth: 2,
                                        borderRadius: 2,
                                    },
                                },
                            }}
                        />
                    </CardContent>
                    <CardContent>{ClientDetailsInputs({ t, values: values.clientsData })}</CardContent>
                </Card>
            </Form>
        </Modal>
    );
};

export default NewReservationConfirm;
