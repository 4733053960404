const otaIcons = {
    website:
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363188/hb/assets/icons8-hexagons-64_ztff3n.png",
    reception:
        "https://res.cloudinary.com/strowberry-code/image/upload/v1702564488/hb/assets/icons8-service-bell-64_altmjg.png",
    channel_manager:
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363008/hb/assets/icons8-centralized-network-48_k8g9cq.png",
    "Booking.com":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363482/hb/assets/booking.com_logo_afbf5h.png",
    Expedia:
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363085/hb/assets/icons8-expedia-48_kng9rx.png",
    "Expedia.com":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363085/hb/assets/icons8-expedia-48_kng9rx.png",
    "Expedia Affiliate Network":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363085/hb/assets/icons8-expedia-48_kng9rx.png",
    "A-Expedia":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363085/hb/assets/icons8-expedia-48_kng9rx.png",
    "A-Expedia Affiliate Network":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363085/hb/assets/icons8-expedia-48_kng9rx.png",
    "A-Hotels.com":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1704194975/hb/assets/logo-56d4178e56994_fpqudr.jpg",
    "Hotels.com": "https://res.cloudinary.com/strowberry-code/image/upload/v1704196563/hb/assets/hotels_l0ka2l.png",
    "Hostelworld.com":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1708180570/hb/assets/hostelworld_d7x15l.png",
    Ostrovok: "https://res.cloudinary.com/strowberry-code/image/upload/v1704195116/hb/assets/ostrovok_anrkkg.png",
    "American Express Travel":
        "https://res.cloudinary.com/strowberry-code/image/upload/v1705829973/hb/assets/1200px-American_Express_logo__2018_.svg_fesdzf.webp",
    imported:
        "https://res.cloudinary.com/strowberry-code/image/upload/v1706636149/hb/assets/icons8-import-100_qxkvmy.png",
    direct: "https://res.cloudinary.com/strowberry-code/image/upload/v1694363188/hb/assets/icons8-hexagons-64_ztff3n.png",
    wordpress:
        "https://res.cloudinary.com/strowberry-code/image/upload/v1694363188/hb/assets/icons8-hexagons-64_ztff3n.png",
    ebookers: "https://res.cloudinary.com/strowberry-code/image/upload/v1716289968/hb/assets/ebookers_lgju7m.png",
};
export default otaIcons;
