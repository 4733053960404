import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import PrintIcon from "@mui/icons-material/Print";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/system";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { CreateReservationContext } from "./CreateReservationContext";

const ConfirmedStep = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { values } = useContext(CreateReservationContext);
    const diff = () => {
        if (!values?.checkin || !values?.checkout) return 0;
        return DateTime.fromISO(values?.checkout).diff(DateTime.fromISO(values?.checkin), "days").toObject().days;
    };

    const selectedRooms = () => {
        if (values?.isSimpleReservation) {
            return Object.values(values.checkedRoomtypes)
                ?.filter((rt) => !!rt && !!rt.quantity)
                ?.map((rt) => ({ roomtypeName: rt.name, name: rt.quantity }));
        }
        return Object.values(values.checkedRooms)
            ?.filter((room) => !!room)
            ?.map((room) => ({ roomtypeName: room.roomtypeName, name: room.name }));
    };

    return (
        <Paper variant="outlined" style={{ padding: 5 }}>
            <ListItem style={{ backgroundColor: "rgb(19, 206, 102,0.2)" }}>
                <Avatar variant="square" sx={{ borderRadius: 1, marginRight: 1, backgroundColor: "white" }}>
                    <CheckIcon fontSize="large" color="success" />
                </Avatar>
                <ListItemText primary={t("booking_confirmation")} primaryTypographyProps={{ variant: "h6" }} />
            </ListItem>
            <CardActions sx={{ marginTop: 3 }}>
                {values?.groupId ? (
                    <Button
                        onClick={() => navigate(`/groups/profile/${values?.groupId?.split("groups_")[1]}`)}
                        startIcon={<OpenInNewIcon />}
                        variant="outlined"
                    >
                        {t("view_group")}
                    </Button>
                ) : (
                    <Button
                        onClick={() => navigate(`/reservations/${values?.reservationId?.split("reservations_")[1]}`)}
                        startIcon={<OpenInNewIcon />}
                        variant="outlined"
                    >
                        {t("view_reservation")}
                    </Button>
                )}
                <Button disabled sx={{ marginLeft: 1 }} startIcon={<AttachEmailIcon />} variant="outlined">
                    {t("email_invoice")}
                </Button>
                <Button disabled sx={{ marginLeft: 1 }} startIcon={<PrintIcon />} variant="outlined">
                    {t("print_invoice")}
                </Button>
            </CardActions>
            <CardContent>
                <Stack direction="row">
                    <ListItemText
                        primary={t("checkin")}
                        primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
                        secondary={values?.checkin && DateTime.fromISO(values?.checkin).toFormat("dd LLLL yyyy")}
                        secondaryTypographyProps={{ variant: "subtitle1", color: "primary" }}
                    />
                    <ListItemText
                        primary={t("checkout")}
                        primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
                        secondary={values?.checkout && DateTime.fromISO(values?.checkout).toFormat("dd LLLL yyyy")}
                        secondaryTypographyProps={{ variant: "subtitle1", color: "primary" }}
                    />
                    <ListItemText
                        primary={t("nights")}
                        primaryTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
                        secondary={diff()}
                        secondaryTypographyProps={{ variant: "subtitle1", color: "primary" }}
                    />
                </Stack>
            </CardContent>
            <CardHeader
                sx={{ paddingBottom: 0 }}
                title={t("clients")}
                titleTypographyProps={{ variant: "h6", color: "primary" }}
            />
            <CardContent>
                <Stack direction="row">
                    {values?.addedClients?.map((client) => (
                        <Card style={{ width: "200px", marginRight: 20 }}>
                            <CardHeader
                                sx={{ padding: 1 }}
                                title={(client.firstName || "") + " " + (client.lastName || "")}
                                titleTypographyProps={{ variant: "h6" }}
                            />
                            <CardContent sx={{ padding: 1 }}>
                                <Typography variant="body2">{client.email}</Typography>
                                <Typography variant="body2">{client.phone}</Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            </CardContent>
            <CardHeader
                sx={{ paddingBottom: 0 }}
                title={t("rooms")}
                titleTypographyProps={{ variant: "h6", color: "primary" }}
            />
            <CardContent sx={{ paddingTop: 0 }}>
                <Typography variant="subtitle1" color="primary">
                    {selectedRooms()
                        ?.map((room) => `${room.roomtypeName} ${room.name}`)
                        .join(" , ")}
                </Typography>
            </CardContent>
        </Paper>
    );
};

export default ConfirmedStep;
