import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const BedTypeList = () => {
    const [bedTypes, setBedTypes] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "capacity", header: t("capacity") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "bedtypes",
            responseData: "_id name capacity",
        });
        if (response?.bedtypes) setBedTypes(response.bedtypes);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteBedtypes" : "deleteBedtype",
            data,
        });
        if (response?.deleteBedtypes === "success" || response?.deleteBedtype === "success") {
            setBedTypes(bedTypes.filter((bedtype) => !rows.some((row) => row._id === bedtype._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };
    const addRecordBedType = (data) => {
        if (bedTypes?.some((r) => r._id === data._id)) {
            setBedTypes(
                bedTypes?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setBedTypes([...bedTypes, data]);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("bedtypes")}
                moduleType={"backoffice"}
                data={bedTypes}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("bedtypes_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecordBedType }} />
        </div>
    );
};

export default BedTypeList;
