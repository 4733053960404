import {
    Typography,
    Grid,
    LinearProgress,
    ListItemText,
    Paper,
    Stack,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Card,
    CardContent,
} from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../../components/hooks";
import { Table } from "../../../components/table";
import { DateControl } from "../../../components/dates";

const calculateNights = (checkin, checkout) => {
    if (!checkin || !checkout) return 0;
    return DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days;
};
const ArrivalsDepartures = () => {
    const { t } = useTranslation();
    const columns = [
        {
            accessorKey: "clientName",
            header: t("client"),
            accessorFn: (row) =>
                (row?.clientsData?.[0]?.firstName || "") + " " + (row?.clientsData?.[0]?.lastName || ""),
        },
        { accessorKey: "checkin", header: t("checkin") },
        { accessorKey: "checkout", header: t("checkout") },
        {
            accessorKey: "nights",
            header: t("nights"),
            accessorFn: (row) => calculateNights(row?.checkin, row?.checkout),
        },
        { accessorKey: "totalPrice", header: t("total") },
        // { accessorKey: "amountPaid", header: t("amount_paid") },
        {
            accessorKey: "roomsData",
            header: t("rooms"),
            accessorFn: (row) =>
                row?.rooms
                    ?.map((room) => {
                        return room?.roomData?.name || "";
                    })
                    ?.join(","),
        },
        {
            accessorKey: "bookedOn",
            header: t("booked_on"),
            accessorFn: (row) => DateTime.fromISO(row._id?.split("reservations_")?.[1])?.toFormat("yyyy LLL dd"),
        },
        {
            accessorKey: "bookingSource",
            header: t("booking_source"),
            accessorFn: (row) => {
                if (!row?.bookingSource) return "";
                if (row?.bookingSource === "reception") return t(row.bookingSource);
                if (!!row?.referer) return row.referer;
                return "";
            },
        },
        {
            accessorKey: "notes",
            header: t("notes"),
            accessorFn: (row) => {
                return row?.notes?.map((note) => note.note)?.join(", ");
            },
        },
        { accessorKey: "status", header: t("status") },
    ];
    const location = useLocation();
    const [tabValue, setTabValue] = useState(location?.state || "arrivals");
    const [data, setData] = useState({ arrivals: null, departures: null, stayovers: null });
    const [date, setDate] = useState();

    const { loading, fetch } = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        switch (tabValue) {
            case "arrivals":
                if (data.arrivals !== null) return;
                loadArrivals();
                break;
            case "departures":
                if (data.departures !== null) return;
                loadDepartures();
                break;
            case "stayovers":
                if (data.stayovers !== null) return;
                loadStayovers();
                break;
        }
    }, [tabValue]);

    useEffect(() => {
        if (date)
            switch (tabValue) {
                case "arrivals":
                    loadArrivals();
                    break;
                case "departures":
                    loadDepartures();
                    break;
                case "stayovers":
                    loadStayovers();
                    break;
            }
    }, [date]);

    const checkedInReservations = data?.arrivals?.filter((res) => res.status === "CHECKIN").length;
    const arrivalsRooms = data?.arrivals?.reduce((acc, res) => acc + parseInt(res?.rooms?.length), 0);
    const checkedOutReservations = data?.departures?.filter((res) => res.status === "CHECKOUT").length;
    const departuresRooms = data?.departures?.reduce((acc, res) => acc + parseInt(res?.rooms?.length), 0);
    const stayoversRooms = data?.stayovers?.reduce((acc, res) => acc + parseInt(res?.rooms?.length), 0);
    const arrivalsGuests = data?.arrivals?.reduce((acc, res) => {
        res?.guests?.forEach((room) => {
            room?.guests?.forEach((category) => {
                acc += category.number;
            });
        });
        return acc;
    }, 0);
    const departuresGuests = data?.departures?.reduce((acc, res) => {
        res?.guests?.forEach((room) => {
            room?.guests?.forEach((category) => {
                acc += category.number;
            });
        });
        return acc;
    }, 0);
    const stayoversGuests = data?.stayovers?.reduce((acc, res) => {
        res?.guests?.forEach((room) => {
            room?.guests?.forEach((category) => {
                acc += category.number;
            });
        });
        return acc;
    }, 0);

    const loadArrivals = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "arrivals",
            responseData:
                "_id checkin checkout bookingSource referer clientsData{firstName lastName} totalPrice rooms{roomData{name}} status guests{guests{number}} notes{note} isBlockedRooms",
            data: { date: date || DateTime.now().toFormat("yyyy-LL-dd") },
        });
        if (response?.arrivals)
            setData({
                ...data,
                arrivals: response.arrivals?.filter((res) => res.status !== "CANCELLED" && !res.isBlockedRooms),
            });
    };
    const loadDepartures = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "departures",
            responseData:
                "_id checkin checkout bookingSource referer clientsData{firstName lastName} totalPrice rooms{roomData{name}} status guests{guests{number}} notes{note} isBlockedRooms",
            data: { date: date || DateTime.now().toFormat("yyyy-LL-dd") },
        });
        if (response?.departures)
            setData({
                ...data,
                departures: response.departures?.filter((res) => res.status !== "CANCELLED" && !res.isBlockedRooms),
            });
    };
    const loadStayovers = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "stayovers",
            responseData:
                "_id clientsData{firstName lastName} checkin checkout bookingSource referer status totalPrice rooms{roomData{name}} guests{guests{number}} notes{note} isBlockedRooms",
            data: { date: date || DateTime.now().toFormat("yyyy-LL-dd") },
        });
        if (response?.stayovers)
            setData({
                ...data,
                stayovers: response.stayovers?.filter((res) => res.status !== "CANCELLED" && !res.isBlockedRooms),
            });
    };

    let filtersText = "";
    if (date) {
        filtersText += t("date") + ": " + date;
    } else {
        filtersText += t("date") + ": " + DateTime.now().toFormat("yyyy-LL-dd");
    }

    // const onDeleteHandler = async (rows = []) => {
    //     if (rows.length === 0) return;
    //     const data = {};
    //     if (rows.length > 1) data._ids = rows.map((row) => row._id);
    //     else data._id = rows[0]._id;
    //     const response = await fetch({
    //         operation: "mutation",
    //         endpoint: rows.length > 1 ? "deleteReservations" : "deleteReservation",
    //         data,
    //     });
    //     if (response?.deleteReservations === "success" || response?.deleteReservation === "success") {
    //         setData(reservations.filter((reservation) => !rows.some((row) => row._id === reservation._id)));
    //         enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
    //     }
    // };
    const renderTabs = () => {
        switch (tabValue) {
            case "arrivals":
                return (
                    <Card variant="outlined" style={{ marginBottom: 20 }}>
                        <CardContent>
                            <Grid container xs={12}>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("arrivals")}
                                        bigText={data?.arrivals?.length}
                                        secondary={t("reservations")}
                                        margin={false}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("checked_in")}
                                        bigText={checkedInReservations}
                                        secondary={t("reservations")}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("number_of_rooms")}
                                        bigText={arrivalsRooms}
                                        secondary={t("rooms")}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("guests")}
                                        bigText={arrivalsGuests}
                                        secondary={t("total")}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            case "departures":
                return (
                    <Card variant="outlined" style={{ marginBottom: 20 }}>
                        <CardContent>
                            <Grid container xs={12}>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("departures")}
                                        bigText={data?.departures?.length}
                                        secondary={t("reservations")}
                                        margin={false}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("checked_out")}
                                        bigText={checkedOutReservations}
                                        secondary={t("reservations")}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("number_of_rooms")}
                                        bigText={departuresRooms}
                                        secondary={t("rooms")}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("guests")}
                                        bigText={departuresGuests}
                                        secondary={t("total")}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            case "stayovers":
                return (
                    <Card variant="outlined" style={{ marginBottom: 20 }}>
                        <CardContent>
                            <Grid container xs={12}>
                                <Grid item xs={6} sm={4}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("stayovers")}
                                        bigText={data?.stayovers?.length}
                                        secondary={t("reservations")}
                                        margin={false}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("number_of_rooms")}
                                        bigText={stayoversRooms}
                                        secondary={t("rooms")}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <DetailBoxBig
                                        color="#F8F9FD"
                                        title={t("guests")}
                                        bigText={stayoversGuests}
                                        secondary={t("total")}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            default:
                return "";
        }
    };

    return (
        <div>
            <div style={{ width: 300 }}>
                <DateControl label={t("date")} onChange={(e) => setDate(e.target.value)} value={date} />
            </div>
            <ToggleButtonGroup
                value={tabValue}
                onChange={(e, value) => {
                    if (value !== null) setTabValue(value);
                }}
                fullWidth
                exclusive
                color="primary"
                sx={{ marginY: 2, backgroundColor: (theme) => theme.palette.table.backgroundColor }}
            >
                <ToggleButton value={"arrivals"} sx={{ fontWeight: "bold" }}>
                    {t("arrivals")}
                </ToggleButton>
                <ToggleButton value={"departures"} sx={{ fontWeight: "bold" }}>
                    {t("departures")}
                </ToggleButton>
                <ToggleButton value={"stayovers"} sx={{ fontWeight: "bold" }}>
                    {t("stayovers")}
                </ToggleButton>
            </ToggleButtonGroup>
            {renderTabs()}
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("reservations_list")}
                moduleType="frontdesk"
                data={!!data[tabValue] ? data[tabValue] : []}
                columns={columns}
                landscape={true}
                headerInfo={[filtersText]}
                onRowClick={(row) => navigate("/reservations/" + `${row?._id?.split("reservations_")[1]}`)}
                // onDeleteClick={onDeleteHandler}
            />
            <Outlet />
        </div>
    );
};

const DetailBoxBig = ({ title, secondary, color, bigText, margin }) => {
    return (
        <Card style={{ backgroundColor: color, marginLeft: margin === false ? 0 : 20, border: "none" }}>
            <CardContent>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="h5">{bigText}</Typography>
                <Typography variant="body1">{secondary}</Typography>
            </CardContent>
        </Card>
    );
};

export default ArrivalsDepartures;
