import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { DashboardCard } from "./components";
import HelpIcon from "@mui/icons-material/Help";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useTranslation } from "react-i18next";

const HelpDashboard = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginBottom: 80 }}>
                <Grid item xs={8}>
                    <DashboardCard
                        title={t("help_page")}
                        titleIcon={<HelpIcon style={{ color: "rgba(112, 34, 155, 0.87)" }} />}
                    >
                        <Typography component="p">
                            {t("find_articles")}{" "}
                            <a
                                href={`${t("help_url")}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "blue" }}
                            >
                                {t("here")}
                            </a>{" "}
                            {t("or_just_write_us_at")}{" "}
                            <a
                                href="mailto:support@strowberrycode.com"
                                rel="noopener noreferrer"
                                style={{ color: "blue" }}
                            >
                                support@hotelbee.co
                            </a>
                            .
                        </Typography>
                        <div className="row" style={{ margin: "20px 0" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="large"
                                startIcon={<ContactSupportIcon />}
                                style={{ margin: "auto" }}
                                href={`${t("help_url")}`}
                                target="_blank"
                            >
                                {t("support")}
                            </Button>
                        </div>
                        <Grid item xs={12} style={{ textAlign: "right" }}>
                            <Button variant="text" href="https://hotelbee.co/privacy-policy/" target="_blank">
                                {t("privacy_policy")}
                            </Button>
                            <Button variant="text" href="https://hotelbee.co/terms-conditions/" target="_blank">
                                {t("terms_conditions")}
                            </Button>
                            <Button variant="text" href="https://hotelbee.co/gdpr/" target="_blank">
                                {t("gdpr_compliance")}
                            </Button>
                        </Grid>
                    </DashboardCard>
                </Grid>
            </Grid>
        </div>
    );
};

export default HelpDashboard;
