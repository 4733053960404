import React from "react";
import { withTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const imageUrl =
  "https://res.cloudinary.com/strowberry-code/image/upload/v1609760829/hb/404_not_found_sbaxr6.png";

const NotFoundPage = ({ t }) => {
  return (
    <div>
      <Paper style={{ padding: 20, textAlign: "center" }}>
        <div style={{ textAlign: "center", marginBottom: "50px" }}>
          <img src={imageUrl} height="200px" alt="404" />
        </div>
        <Typography variant="h2" component="h3">
          {t("404_page_not_found")}
        </Typography>
        <Typography component="p">
          The page you requested doesn't exist. But you can go back at the
          homepage <Link to={"/"}>here</Link>.
        </Typography>
      </Paper>
    </div>
  );
};

export default withTranslation("translations")(NotFoundPage);
