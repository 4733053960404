import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";

const rules = {
    name: "string|required",
};
const discountTypeOptions = [
    {
        value: "total",
        label: "total",
    },
    {
        value: "percentage",
        label: "percentage",
    },
];

const DiscountForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordDiscount } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "discount",
            data: {
                _id: "discounts_" + params?.id,
            },
            responseData: "_id name minimalReservations minimalAmount minimalNights discountValue discountType",
        });
        if (response?.discount) setValues(response.discount);
    };

    const saveHandler = async () => {
        const { name, minimalReservations, minimalAmount, minimalNights, discountValue, discountType, _id } = values;
        const data = {
            name,
            minimalReservations: parseInt(minimalReservations),
            minimalAmount: parseFloat(minimalAmount),
            minimalNights: parseInt(minimalNights),
            discountValue: parseFloat(discountValue),
            discountType,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateDiscount" : "createDiscount",
            data,
            responseData: "_id name minimalReservations minimalAmount minimalNights discountValue discountType",
        });
        if (response?.createDiscount?._id || response?.updateDiscount?._id) {
            enqueueSnackbar(t("discount_saved"), { variant: "default" });
            addRecordDiscount && addRecordDiscount(response.createDiscount || response?.updateDiscount);
            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("discount")}
            maxWidth="sm"
            open={true}
            loading={loading}
            yesDisabled={loading}
            onClose={() => navigate(-1)}
            permission={{ discounts: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={6}>
                    <InputControl name="discountValue" label={t("discount_value")} type="number" />
                </Grid>
                <Grid item xs={6}>
                    <SelectControl name="discountType" label={t("discount_type")} options={discountTypeOptions} />
                </Grid>

                <Typography variant="subtitle1">{t("discount_conditions")}</Typography>
                <Grid item xs={12}>
                    <InputControl name="minimalReservations" label={t("minimal_reservations")} type="number" />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="minimalAmount" label={t("minimal_amount")} type="number" />
                </Grid>
                <Grid item xs={12}>
                    <InputControl name="minimalNights" label={t("minimal_nights")} type="number" />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DiscountForm;
