import React, { useEffect, useState } from "react";
import { ReportGenerator } from "../../components/classes";
import { Stack } from "@mui/material";
import { DateRangeControl } from "../../components/dates";
import { ReportHeader } from "./components";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { toCurrency } from "../../utils";

const REPORT_RESPONSE_DATA = `
        rooms{
            totalByRoomtype{roomtype total}
        }
        revenue{
            dailyTotalByRoomtype{roomtype total{currency amount}} 
        }
    `;
const RoomTypeSalesReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [extraData, setExtraData] = useState({
        roomtypes: [],
    });

    const [report, setReport] = useState(null);
    const { loading, fetch } = useApi();
    const columns = [
        {
            id: "name",
            header: t("name"),
            displayValue: (row) => {
                return row.name;
            },
            totalValue: () => "",
        },
        {
            id: "roomsOccupied",
            header: t("nights"),
            displayValue: (row) => {
                return row.roomsOccupied || "0";
            },
            totalValue: (rows) => rows?.reduce((acc, row) => acc + (row.roomsOccupied || 0), 0),
        },
        {
            id: "totalRevenue",
            header: t("total"),
            displayValue: (row) => {
                return currenciesAsString(Object.values(row.totalRevenue || {}));
            },
            totalValue: (rows) => {
                const grossAmountByCurrencies = {};
                rows?.filter((row) => !row?.isTotal)?.forEach((row) =>
                    Object.values(row?.totalRevenue || {})?.forEach((amount) => {
                        if (!grossAmountByCurrencies[amount.currency]) {
                            grossAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                        }
                        grossAmountByCurrencies[amount.currency].amount += amount.amount;
                    })
                );
                return currenciesAsString(Object.values(grossAmountByCurrencies));
            },
        },
        {
            id: "averageRevenue",
            header: t("average_revenue"),
            displayValue: (row) => {
                if (!row.roomsOccupied) return "0";
                return currenciesAsString(
                    Object.values(row.totalRevenue || {})?.map((currencyAmount) => {
                        return { ...currencyAmount, amount: currencyAmount.amount / row.roomsOccupied };
                    })
                );
            },
            totalValue: (rows) => {
                const soldUnits = rows?.reduce((acc, row) => acc + (row.roomsOccupied || 0), 0);
                const grossAmountByCurrencies = {};
                rows?.filter((row) => !row?.isTotal)?.forEach((row) =>
                    Object.values(row?.totalRevenue || {})?.forEach((amount) => {
                        if (!grossAmountByCurrencies[amount.currency]) {
                            grossAmountByCurrencies[amount.currency] = { currency: amount.currency, amount: 0.0 };
                        }
                        grossAmountByCurrencies[amount.currency].amount += amount.amount;
                    })
                );

                const averageRate = Object.values(grossAmountByCurrencies)?.map((currencyAmount) => {
                    const grossADR = currencyAmount.amount / soldUnits;
                    return { ...currencyAmount, amount: isNaN(grossADR) ? 0 : grossADR };
                });
                return currenciesAsString(averageRate);
            },
        },
    ];
    const currenciesAsString = (amountsByCurrencies = []) => {
        if (amountsByCurrencies?.length === 0) return "0.00";
        return amountsByCurrencies
            ?.map((currencyAmount) => {
                return toCurrency(currencyAmount.amount, currencyAmount.currency);
            })
            ?.join(" , ");
    };
    useEffect(() => {
        loadExtraData();
    }, []);
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomtypes",
                    responseData: "_id name taxData{taxId isIncluded tax{rate taxCategory}}",
                },
            ],
        });
        if (!!response?.roomtypes)
            setExtraData({
                roomtypes: response.roomtypes,
            });
    };
    const loadData = async () => {
        if (!params.get("startDate") || !params.get("endDate")) return;
        const response = await fetch({
            operation: "query",
            endpoint: "datesGeneralManagerReport",
            responseData: REPORT_RESPONSE_DATA,
            data: { startDate: params.get("startDate"), endDate: params.get("endDate") },
        });
        if (!response?.datesGeneralManagerReport) return;

        const roomTypeData = extraData?.roomtypes?.reduce((acc, roomtype) => {
            acc[roomtype._id] = { name: roomtype.name };
            return acc;
        }, {});

        response?.datesGeneralManagerReport?.forEach((day) => {
            day?.rooms?.totalByRoomtype?.forEach((roomtype) => {
                if (!roomTypeData[roomtype.roomtype]) return;
                if (!roomTypeData[roomtype.roomtype]?.roomsOccupied) {
                    roomTypeData[roomtype.roomtype].roomsOccupied = 0;
                }
                roomTypeData[roomtype.roomtype].roomsOccupied += roomtype.total || 0;
            });
            day?.revenue?.dailyTotalByRoomtype?.forEach((roomtype) => {
                if (!roomTypeData[roomtype.roomtype]) return;
                if (!roomTypeData[roomtype.roomtype]?.totalRevenue) {
                    roomTypeData[roomtype.roomtype].totalRevenue = {};
                }
                roomtype?.total?.forEach((amount) => {
                    if (!roomTypeData[roomtype.roomtype].totalRevenue[amount.currency]) {
                        roomTypeData[roomtype.roomtype].totalRevenue[amount.currency] = {
                            currency: amount.currency,
                            amount: 0.0,
                        };
                    }
                    const grossAmount = parseFloat(amount.amount);
                    roomTypeData[roomtype.roomtype].totalRevenue[amount.currency].amount += isNaN(grossAmount)
                        ? 0
                        : grossAmount;
                });
            });
        });

        const newRaportGenerator = new ReportGenerator({
            data: Object.values(roomTypeData),
            columns,
            displayTotal: true,
        });
        setReport(newRaportGenerator.generateTable({ title: t("roomtype_sales_report"), headerInfo: [filtersText] }));
    };
    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");

        return data;
    };

    let filtersText = "";
    const data = { startDate: params.get("startDate"), endDate: params.get("endDate") };
    if (!!data.startDate) filtersText += t("start_date") + ": " + data.startDate;
    if (!!data.endDate) filtersText += ", " + t("end_date") + ": " + data.endDate;

    return (
        <div>
            <ReportHeader
                title={t("roomtype_sales_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                </Stack>
            </ReportHeader>
            {report}
        </div>
    );
};

export default RoomTypeSalesReport;
