import { createClient } from "graphql-ws";
import { getSubdomain } from "../../utils";
import { cacheData } from "./cache/cacheData";
import { getQueryBody } from "./queryGraphQL";

//const API_URL = "ws://localhost:4000/subscriptions";
//const API_URL = "wss://jezcorporation.com/api/subscriptions";
//const API_URL = "wss://app.hotelbee.co/api/subscriptions";

const developmentURL = "ws://localhost:4000/subscriptions";
const productionURL = "wss://jezcorporation.com/api/subscriptions";

const API_URL = process.env.NODE_ENV === "production" ? productionURL : developmentURL;

const apiSubscription = () => {
    const token = localStorage.getItem("token");

    const wsClient = createClient({
        url: API_URL,
        connectionParams: {
            hotel: getSubdomain(),
            token: !!token ? token : undefined,
        },
    });

    /**
     *
     * @param {Object} options
     * @param {string} options.operation - operation name: query | mutation
     * @param {string} options.endpoint - endpoint: Ex createUser
     * @param {Object} options.data - endpoint arguments
     * @param {string} options.responseData - response fields: Ex "_id name description ..."
     */

    const openConnection = (options = {}, onNewData, onError) => {
        const { query } = getQueryBody(options);
        const unsubscribe = wsClient.subscribe(
            {
                query,
            },
            {
                next: (data) => {
                    onNewData && onNewData(data?.data);
                    cacheData(data?.data);
                },
                error: (err) => {
                    console.log(err);
                },
                complete: () => {
                    console.log("Connection closed");
                },
            }
        );
        const disconnect = () => {
            unsubscribe();
            wsClient.dispose();
        };
        return { disconnect };
    };
    return { openConnection };
};

export default apiSubscription;
