import {
    Button,
    ButtonBase,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputControl, SwitchControl } from "../../../components/form";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useApi } from "../../../components/hooks";
import { useSnackbar } from "notistack";

const ChannelManagerForm = () => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        active: true,
        properties: [{}],
        beds24RoomtypeQuantity: [],
    });
    const [roomtypes, setRoomtypes] = useState([]);

    const changePropertyDetails = (newValue, index) => {
        const prevProperties = JSON.parse(JSON.stringify(state.properties));
        if (!prevProperties[index]) {
            prevProperties[index] = {};
        }
        prevProperties[index] = { ...prevProperties[index], ...newValue };
        setState({ ...state, properties: prevProperties });
    };

    const deleteProperty = (index) => {
        const prevProperties = JSON.parse(JSON.stringify(state.properties));
        prevProperties[index] = undefined;
        setState({ ...state, properties: prevProperties.filter((property) => !!property) });
    };

    const changeRoomtypeQuantity = (roomtypeId, newQuantity) => {
        const prevBeds24RoomtypeQuantity = JSON.parse(JSON.stringify(state.beds24RoomtypeQuantity));
        const indexOfExistingRoomtype = prevBeds24RoomtypeQuantity.findIndex((rt) => rt._id === roomtypeId);
        if (indexOfExistingRoomtype === -1) {
            prevBeds24RoomtypeQuantity.push({ _id: roomtypeId, quantity: newQuantity });
        } else {
            prevBeds24RoomtypeQuantity[indexOfExistingRoomtype].quantity = newQuantity;
        }
        setState({ ...state, beds24RoomtypeQuantity: prevBeds24RoomtypeQuantity });
    };

    useEffect(() => {
        loadIntegrationRecord();
    }, []);

    const { fetch, loading } = useApi();
    const loadIntegrationRecord = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "beds24IntegrationsData",
                    responseData: `active propertyKey propertyName propertyAddress propertyCity propertyZip propertyDescription
                        extraProperties{propertyKey propertyName propertyAddress propertyCity propertyZip propertyDescription}
                        beds24RoomtypeQuantity{_id quantity}
                    `,
                },
                {
                    endpoint: "roomtypes",
                    responseData: "_id name",
                },
            ],
        });
        if (response?.beds24IntegrationsData) {
            const {
                active,
                extraProperties,
                beds24RoomtypeQuantity,
                propertyKey,
                propertyName,
                propertyAddress,
                propertyCity,
                propertyZip,
                propertyDescription,
            } = response?.beds24IntegrationsData || {};
            const values = {
                active: Boolean(active),
                properties: [
                    { propertyKey, propertyName, propertyAddress, propertyCity, propertyZip, propertyDescription },
                ].concat(extraProperties || []),
                beds24RoomtypeQuantity: beds24RoomtypeQuantity || [],
            };
            setState(values);
        }
        if (response?.roomtypes) setRoomtypes(response.roomtypes);
    };
    const { enqueueSnackbar } = useSnackbar();
    const saveHandler = async () => {
        const properties = JSON.parse(JSON.stringify(state.properties));
        const mainProperty = properties.shift();
        const data = {
            active: state.active,
            propertyKey: mainProperty.propertyKey,
            propertyName: mainProperty.propertyName,
            propertyAddress: mainProperty.propertyAddress,
            propertyCity: mainProperty.propertyCity,
            propertyZip: mainProperty.propertyZip,
            propertyDescription: mainProperty.propertyDescription,
            extraProperties: properties,
            beds24RoomtypeQuantity: state.beds24RoomtypeQuantity
                .map((rt) => {
                    return { _id: rt._id, quantity: parseInt(rt.quantity) };
                })
                ?.filter((rt) => !!rt._id && !isNaN(rt.quantity)),
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "saveBeds24IntegrationsData",
            data,
            responseData: `_id`,
        });
        if (response?.saveBeds24IntegrationsData?._id) {
            enqueueSnackbar(t("saved"));
        }
    };

    return (
        <Paper style={{ minHeight: "calc(100vh - 120px)", padding: "20px" }}>
            <Stack direction="row" gap={4} alignItems="center">
                <Typography variant="h5">{t("channel_manager")}</Typography>
                <SwitchControl
                    value={state.active}
                    onChange={(e) => setState({ ...state, active: e.target.checked })}
                />
            </Stack>
            <Divider />
            <Stack direction="row" marginY={2} gap={4} alignItems="center" flexWrap="wrap">
                {state.properties.map((property, index) => {
                    return (
                        <Card sx={{ maxWidth: 400 }}>
                            <CardHeader
                                title={t("property_details")}
                                titleTypographyProps={{ variant: "h6" }}
                                action={
                                    <IconButton onClick={() => deleteProperty(index)}>
                                        <DeleteIcon color="primary" />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <InputControl
                                    label={t("name")}
                                    value={property.propertyName || ""}
                                    onChange={(e) => changePropertyDetails({ propertyName: e.target.value }, index)}
                                />
                                <InputControl
                                    label={t("property_key")}
                                    value={property.propertyKey || ""}
                                    onChange={(e) => changePropertyDetails({ propertyKey: e.target.value }, index)}
                                />
                                <InputControl
                                    label={t("address")}
                                    value={property.propertyAddress || ""}
                                    onChange={(e) => changePropertyDetails({ propertyAddress: e.target.value }, index)}
                                />
                                <InputControl
                                    label={t("city")}
                                    value={property.propertyCity || ""}
                                    onChange={(e) => changePropertyDetails({ propertyCity: e.target.value }, index)}
                                />
                                <InputControl
                                    label={t("zip")}
                                    value={property.propertyZip || ""}
                                    onChange={(e) => changePropertyDetails({ propertyZip: e.target.value }, index)}
                                />
                                <InputControl
                                    label={t("description")}
                                    value={property.propertyDescription || ""}
                                    onChange={(e) =>
                                        changePropertyDetails({ propertyDescription: e.target.value }, index)
                                    }
                                />
                            </CardContent>
                        </Card>
                    );
                })}
                <ButtonBase
                    sx={{ height: 100 }}
                    onClick={() => setState({ ...state, properties: [...state.properties, {}] })}
                >
                    <AddIcon style={{ fontSize: 100 }} color="primary" />
                </ButtonBase>
            </Stack>
            <Typography variant="h6">{t("roomtypes_quantity")}</Typography>
            <Divider />
            <Stack direction="row" marginTop={2} gap={4} alignItems="center" flexWrap="wrap">
                {roomtypes?.map((rt) => {
                    const { quantity } =
                        state.beds24RoomtypeQuantity?.find((roomtype) => roomtype._id === rt._id) || {};
                    return (
                        <Card sx={{ maxWidth: 400 }}>
                            <CardHeader title={rt.name} titleTypographyProps={{ variant: "h6" }} />
                            <CardContent>
                                <InputControl
                                    value={quantity}
                                    onChange={(e) => changeRoomtypeQuantity(rt._id, e.target.value)}
                                    label={t("quantity")}
                                    type="number"
                                />
                            </CardContent>
                        </Card>
                    );
                })}
            </Stack>
            <Stack justifyContent="end" direction="row">
                <Button size="large" variant="contained" color="secondary" onClick={() => saveHandler()}>
                    {t("save")}
                </Button>
            </Stack>
        </Paper>
    );
};

export default ChannelManagerForm;
