import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReportGenerator } from "../../components/classes";
import { useApi, useProducts } from "../../components/hooks";
import { ReportHeader } from "./components";

const ProductsInventoryReport = () => {
    const { t } = useTranslation();
    // const [params, setParams] = useSearchParams();

    const [report, setReport] = useState(null);
    const { loading, fetch } = useApi();
    const { products } = useProducts();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "productsInventories", responseData: `_id warehouseId quantity` },
                {
                    endpoint: "warehouses",
                    responseData: "_id name",
                },
            ],
        });

        const columns = [
            {
                id: "product",
                header: t("product"),
                displayValue: (row) => {
                    return row?.name || "";
                },
            },
            ...response?.warehouses?.map((warehouse) => {
                return {
                    id: warehouse.name,
                    header: warehouse.name,
                    displayValue: (row) => {
                        const quantity = row?.warehouses?.[warehouse._id];
                        if (!!quantity) return quantity.toFixed(2);
                        return "--";
                    },
                };
            }),
            {
                id: "total",
                header: t("total"),
                displayValue: (row) => {
                    const quantity = Object.values(row?.warehouses || {})?.reduce((total, warehouseQuantity) => {
                        total += warehouseQuantity || 0.0;
                        return total;
                    });
                    if (!!quantity) return quantity.toFixed(2);
                    return "--";
                },
            },
        ];

        if (!response?.productsInventories) return;
        const newRaportGenerator = new ReportGenerator({
            data: Object.values(
                response?.productsInventories?.reduce((acc, product) => {
                    const productId = product?._id?.split("inventory_")?.[1]?.split("_")?.[0];
                    if (!productId || !product.warehouseId) return acc;
                    const { name } = products.find((p) => p._id === "products_" + productId) || {};
                    if (!name) return acc;
                    if (!acc[productId]) {
                        acc[productId] = {
                            _id: productId,
                            name,
                            warehouses: {},
                        };
                    }
                    if (!acc[productId].warehouses[product.warehouseId]) {
                        acc[productId].warehouses[product.warehouseId] = 0.0;
                    }
                    acc[productId].warehouses[product.warehouseId] += product.quantity || 0.0;
                    return acc;
                }, {}) || {}
            ),
            columns,
            displayTotal: false,
        });
        setReport(newRaportGenerator.generateTable({ title: t("products") }));
    };

    return (
        <div>
            <ReportHeader
                title={t("products_report")}
                loading={loading}
                disableRunInfo={t("loading_products")}
                disableRun={!products?.length > 0}
                onReportRun={() => {
                    loadData();
                }}
            ></ReportHeader>
            {report}
        </div>
    );
};

export default ProductsInventoryReport;
