import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputControl, SelectControl, SwitchControl } from "../../components/form";
import { Modal } from "../../components/modal";
import { useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { paymentOptions } from "../invoices/components/fiscalOptions";

const rules = {
    name: "string|required",
    fiscalMethod: "string|required",
};
const methodOptions = [
    { value: "cash", label: "cash" },
    { value: "card", label: "card" },
    { value: "bank", label: "bank" },
    { value: "account", label: "account" },
    { value: "roomCharge", label: "room_charge" },
];

const PaymentMethodForm = () => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const { addRecordPaymentMethod } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "paymentmethod",
            data: {
                _id: "paymentmethods_" + params?.id,
            },
            responseData: "_id name isActive description fiscalMethod method",
        });
        if (response?.paymentmethod) setValues(response.paymentmethod);
    };

    const saveHandler = async () => {
        const { name, isActive, description, fiscalMethod, method, _id } = values;
        const data = {
            name,
            isActive: Boolean(isActive),
            description,
            method,
            fiscalMethod,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePaymentMethod" : "createPaymentMethod",
            data,
            responseData: "_id name isActive description fiscalMethod method",
        });
        if (response?.createPaymentMethod?._id || response?.updatePaymentMethod?._id) {
            enqueueSnackbar(t("payment_method_saved"), { variant: "default" });
            addRecordPaymentMethod &&
                addRecordPaymentMethod(response.createPaymentMethod || response?.updatePaymentMethod);

            navigate(-1);
        }
    };

    return (
        <Modal
            titlelabel={t("payment_method")}
            maxWidth="sm"
            open={true}
            loading={loading}
            onClose={() => navigate(-1)}
            yesDisabled={loading}
            permission={{ payments: params?.id === "create" ? { create: true } : { edit: true } }}
            FormProps={{
                values,
                rules,
                onValuesChange: setValues,
                onSubmit: saveHandler,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SwitchControl name="isActive" label={t("is_active")} />
                </Grid>
                <Grid item xs={4}>
                    <InputControl name="name" label={t("name")} />
                </Grid>
                <Grid item xs={4}>
                    <SelectControl name="method" label={t("method")} options={methodOptions} />
                </Grid>
                <Grid item xs={4}>
                    <SelectControl name="fiscalMethod" label={t("fiscal_method")} options={paymentOptions} />
                </Grid>
                <Grid item xs={12}>
                    <InputControl multiline rows={3} name="description" label={t("description")} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default PaymentMethodForm;
